import { Tile } from './Tile'
import { Person } from './Person'
import { IActor } from '@typings/tile'

export class Actor extends Person {
    constructor(props: IActor) {
        super(props)

        const _computePropValue = this._makeComputePropValue(props)

        this.format = _computePropValue('format', Tile.FORMAT.PORTRAIT)
        this.title = props.title
        this.thumbnail = props.thumbnail
    }

    isActor() {
        return true
    }
}
