import { ContentNode, EmiRequest } from 'models'
import nodeIds from '../config/nodeIds'
import Constants from 'api-constants'

const emiRequests = Constants.emiRequests
const {
    DEFAULT_COACH_CONTINUE_WATCHING_MOVIES,
    DEFAULT_COACH_CONTINUE_WATCHING_TVSHOWS,
    DEFAULT_COACH_GET_LIVE_NOW_ALL,
} = Constants.requestIds

export default {
    programmingId: 0,
    navMap: {
        [nodeIds.home]: new ContentNode({
            id: nodeIds.home,
            labels: { default: 'Home' },
            intlKey: { tag: nodeIds.home },
            translateLabel: true,
            parent: nodeIds.root,
            body: {
                type: 'list',
                data: [
                    DEFAULT_COACH_CONTINUE_WATCHING_MOVIES,
                    DEFAULT_COACH_CONTINUE_WATCHING_TVSHOWS,
                    DEFAULT_COACH_GET_LIVE_NOW_ALL,
                ].map((id) => ({ id })),
            },
            highlight: { data: [] },
            // no PPZ for now as it's too unpredictable
            // 200 channels => 200 tiles in the PPZ, if we decide to display live now programs.
            // If we use a VOD pre-request (e.g favourites, continue watching) the thumbnails don't match any of
            // the PPZ tile formats (duo, tertio, quattro formaggi etc...)
        }),
    },
    requestMap: {
        [DEFAULT_COACH_CONTINUE_WATCHING_MOVIES]: new EmiRequest({
            id: DEFAULT_COACH_CONTINUE_WATCHING_MOVIES,
            value: emiRequests.CONTINUE_WATCHING_VOD_MOVIES,
            labels: { default: '' },
            intlKey: { tag: DEFAULT_COACH_CONTINUE_WATCHING_MOVIES },
            translateLabel: true,
            args: [],
        }),
        [DEFAULT_COACH_CONTINUE_WATCHING_TVSHOWS]: new EmiRequest({
            id: DEFAULT_COACH_CONTINUE_WATCHING_TVSHOWS,
            value: emiRequests.CONTINUE_WATCHING_VOD_TVSHOWS,
            labels: { default: '' },
            intlKey: { tag: DEFAULT_COACH_CONTINUE_WATCHING_TVSHOWS },
            translateLabel: true,
            args: [],
        }),
        [DEFAULT_COACH_GET_LIVE_NOW_ALL]: new EmiRequest({
            id: DEFAULT_COACH_GET_LIVE_NOW_ALL,
            value: emiRequests.GET_LIVE_NOW_ALL,
            labels: { default: '' },
            intlKey: { tag: DEFAULT_COACH_GET_LIVE_NOW_ALL },
            translateLabel: true,
            args: [],
        }),
    },
}
