import { NavigationNode, ContentNode, EmiRequest } from 'models'
import nodeIds from '../config/nodeIds'
import Constants from 'api-constants'

const emiRequests = Constants.emiRequests
const customRequests = Constants.customRequests

const {
    DEFAULT_VOD_CONTINUE_WATCHING_ALL,
    DEFAULT_VOD_GET_FAVORITES,
    DEFAULT_VOD_GET_PURCHASED_TVODS,
    DEFAULT_VOD_GET_PURCHASED_TVODS_RENTAL_ONLY,
    DEFAULT_VOD_GET_PURCHASED_TVODS_PURCHASE_ONLY,
} = Constants.requestIds

const {
    VOD_CONTINUE_WATCHING,
    VOD_FAVORITES,
    VOD_PURCHASED,
    VOD_PURCHASED_RENTAL_ONLY,
    VOD_PURCHASED_PURCHASE_ONLY,
} = Constants.nodeIds
export default {
    programmingId: 0,
    navMap: {
        [nodeIds.vod]: new NavigationNode({
            id: nodeIds.vod,
            labels: { default: 'VOD' },
            intlKey: { tag: nodeIds.vod },
            translateLabel: true,
            parent: nodeIds.root,
            children: [
                VOD_CONTINUE_WATCHING,
                VOD_FAVORITES,
                VOD_PURCHASED,
                VOD_PURCHASED_RENTAL_ONLY,
                VOD_PURCHASED_PURCHASE_ONLY,
            ],
        }),

        [VOD_CONTINUE_WATCHING]: new ContentNode({
            id: VOD_CONTINUE_WATCHING,
            parent: 'root-vod',
            translateLabel: true,
            intlKey: { tag: VOD_CONTINUE_WATCHING },
            body: {
                type: 'wall',
                data: [{ id: DEFAULT_VOD_CONTINUE_WATCHING_ALL }],
            },
            highlight: { data: [] },
        }),

        [VOD_FAVORITES]: new ContentNode({
            id: VOD_FAVORITES,
            parent: 'root-vod',
            translateLabel: true,
            intlKey: { tag: VOD_FAVORITES },
            body: {
                type: 'wall',
                data: [{ id: DEFAULT_VOD_GET_FAVORITES }],
            },
            highlight: { data: [] },
        }),

        [VOD_PURCHASED]: new ContentNode({
            id: VOD_PURCHASED,
            parent: 'root-vod',
            translateLabel: true,
            intlKey: { tag: VOD_PURCHASED },
            body: {
                type: 'wall',
                data: [{ id: DEFAULT_VOD_GET_PURCHASED_TVODS }],
            },
            highlight: { data: [] },
        }),
        [VOD_PURCHASED_RENTAL_ONLY]: new ContentNode({
            id: VOD_PURCHASED_RENTAL_ONLY,
            parent: 'root-vod',
            translateLabel: true,
            intlKey: { tag: VOD_PURCHASED_RENTAL_ONLY },
            body: {
                type: 'wall',
                data: [{ id: DEFAULT_VOD_GET_PURCHASED_TVODS_RENTAL_ONLY }],
            },
            highlight: { data: [] },
        }),
        [VOD_PURCHASED_PURCHASE_ONLY]: new ContentNode({
            id: VOD_PURCHASED_PURCHASE_ONLY,
            parent: 'root-vod',
            translateLabel: true,
            intlKey: { tag: VOD_PURCHASED_PURCHASE_ONLY },
            body: {
                type: 'wall',
                data: [{ id: DEFAULT_VOD_GET_PURCHASED_TVODS_PURCHASE_ONLY }],
            },
            highlight: { data: [] },
        }),
    },

    requestMap: {
        [DEFAULT_VOD_CONTINUE_WATCHING_ALL]: new EmiRequest({
            id: DEFAULT_VOD_CONTINUE_WATCHING_ALL,
            value: emiRequests.CONTINUE_WATCHING_VOD_ALL,
            labels: { default: '' },
            args: [],
        }),
        [DEFAULT_VOD_GET_FAVORITES]: new EmiRequest({
            id: DEFAULT_VOD_GET_FAVORITES,
            value: emiRequests.GET_FAVORITES_ALL,
            labels: { default: '' },
            args: [],
        }),
        [DEFAULT_VOD_GET_PURCHASED_TVODS]: new EmiRequest({
            id: DEFAULT_VOD_GET_PURCHASED_TVODS,
            value: customRequests.GET_PURCHASED_TVODS,
            labels: { default: '' },
            args: [],
        }),
        [DEFAULT_VOD_GET_PURCHASED_TVODS_RENTAL_ONLY]: new EmiRequest({
            id: DEFAULT_VOD_GET_PURCHASED_TVODS_RENTAL_ONLY,
            value: customRequests.GET_PURCHASED_TVODS_RENTAL_ONLY,
            labels: { default: '' },
            args: [],
        }),
        [DEFAULT_VOD_GET_PURCHASED_TVODS_PURCHASE_ONLY]: new EmiRequest({
            id: DEFAULT_VOD_GET_PURCHASED_TVODS_PURCHASE_ONLY,
            value: customRequests.GET_PURCHASED_TVODS_PURCHASE_ONLY,
            labels: { default: '' },
            args: [],
        }),
    },
}
