import config from 'services/proxy/config'
import Constants from 'api-constants'

const { deeplinkTypes } = config

const DEEPLINK_TO_EXTRACT = {
    [Constants.platform.androidTvStb]: {
        deeplinkType: deeplinkTypes.ANDROID_TV,
        deeplinkFallback: deeplinkTypes.WEB,
    },
    [Constants.platform.androidTv]: {
        deeplinkType: deeplinkTypes.ANDROID_TV,
        deeplinkFallback: deeplinkTypes.WEB,
    },
    [Constants.platform.amazonTv]: {
        deeplinkType: deeplinkTypes.FIRE_TV,
        deeplinkFallback: deeplinkTypes.WEB,
    },
    [Constants.platform.android]: {
        deeplinkType: deeplinkTypes.ANDROID,
        deeplinkFallback: deeplinkTypes.WEB,
    },
    [Constants.platform.ios]: {
        deeplinkType: deeplinkTypes.IOS,
        deeplinkFallback: deeplinkTypes.WEB,
    },
    [Constants.platform.tvos]: {
        deeplinkType: deeplinkTypes.TVOS,
        deeplinkFallback: deeplinkTypes.WEB,
    },
    [Constants.platform.smartTvOs]: {
        deeplinkType: deeplinkTypes.WEB,
    },
    [Constants.platform.chrome]: {
        deeplinkType: deeplinkTypes.WEB,
    },
    [Constants.platform.firefox]: {
        deeplinkType: deeplinkTypes.WEB,
    },
    [Constants.platform.edge]: {
        deeplinkType: deeplinkTypes.WEB,
    },
    [Constants.platform.edgeChromium]: {
        deeplinkType: deeplinkTypes.WEB,
    },
    [Constants.platform.safari]: {
        deeplinkType: deeplinkTypes.WEB,
    },
    [Constants.platform.playstation]: {
        deeplinkType: deeplinkTypes.WEB,
    },
    [Constants.platform.xbox]: {
        deeplinkType: deeplinkTypes.WEB,
    },
    [Constants.platform.chromecast]: {
        deeplinkType: deeplinkTypes.WEB,
    },
}

/**
 * Returns VOD streams per streamType
 * @param {Array} deeplink Coming from IFS proxy
 * @param {deeplinkTypes} deeplinkType Deeplink to parse
 * @return {Object}
 */
export const parseDeeplink = (deeplink = [], deeplinkType) => {
    return deeplink.find((deeplink) => {
        return deeplink && deeplink.type === deeplinkType && !!deeplink.uri
    })
}

/**
 * Returns deeplinks
 * @param {Array} streams Coming from IFS proxy
 * @param {Constants.platform} platform type of platform
 * @return {Object}
 */
export const deeplinksFactory = (deeplinks = [], platform = Constants.platform.androidTvStb) => {
    const { deeplinkType = deeplinkTypes.WEB, deeplinkFallback } =
        DEEPLINK_TO_EXTRACT[platform] || {}

    let deeplink = parseDeeplink(deeplinks, deeplinkType)
    if (deeplink) {
        return {
            fallback: false,
            platformDeeplinkType: deeplinkType,
            command: deeplink.package_name || '',
            param: deeplink.uri,
        }
    }

    deeplink = parseDeeplink(deeplinks, deeplinkFallback)
    if (deeplink) {
        return {
            fallback: true,
            platformDeeplinkType: deeplinkType,
            command: deeplink.package_name || '',
            param: deeplink.uri,
        }
    }

    if (!deeplink) {
        return {}
    }
}
