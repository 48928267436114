import {onMount} from 'solid-js'
import {useConfig} from './config/useConfig'

export interface ConfigProps {
    children: any
}

export const GenericWrapper = (props: ConfigProps) => {
    const {getConfig} = useConfig()

    onMount(() => {
        document.title = getConfig().title
        const meta = document.createElement('meta')
        meta.name = 'description'
        meta.content = getConfig().title
        document.head.appendChild(meta)
    })

    return <>{props.children}</>
}
