import {useContext} from 'solid-js'
import {IconProviderBehavior} from './IconProvider'
import {IconContext} from './IconContext'

export function useIcon() {
    const contextValue: IconProviderBehavior = useContext(IconContext)
    if (!contextValue) {
        throw new Error(
            'could not find redux context value; please ensure the component is wrapped in a <ReduxProvider>'
        )
    }
    return contextValue
}
