import { of, throwError } from 'rxjs'
import { mergeMap } from 'rxjs/operators'
import VoltError from 'VoltError'
import Fetch from '../fetch'
import HuaweiTypes from '../HuaweiTypes'
import DataHelper from 'framework/helpers/data'
import { resolveBackendRatingId } from '../Helpers'

export default class SettingsApi extends Fetch {
    constructor(config, otherApis = {}) {
        super(config, otherApis)
        this.passwordApi = otherApis.passwordApi
    }

    /**
     * Updates the Parental Control level of the user
     *
     * @param {String|Number} level Parental Level
     * @param {String} profileId Profile ID
     * @return {Observable}
     */
    updatePcLevel(level, profileId) {
        if (!level) {
            this.logger.error('[UPDATE PC LEVEL] Level to set is empty')
            return throwError(new VoltError(VoltError.codes.INVALID_REQUEST_PAYLOAD))
        }

        if (!profileId) {
            this.logger.error('[UPDATE PC LEVEL] Profile ID is empty')
            profileId = DataHelper.getInstance().getData(DataHelper.STORE_KEY.SUBSCRIBER_ID)
        }
        const ratingId = resolveBackendRatingId(level)

        return this.fetch({
            url: `${DataHelper.getInstance().getData(
                DataHelper.STORE_KEY.BACKEND_API_URL
            )}/VSP/V3/ModifyProfile`,
            method: 'POST',
            body: {
                profile: {
                    ID: profileId,
                    ratingID: ratingId,
                },
            },
            log: `UPDATE PC LEVEL FOR ${profileId} ON ${level} Level / Rating Id ${ratingId}`,
        }).pipe(mergeMap(() => of(true)))
    }

    /**
     * Updates the user's pin code
     *
     * @param {String} type Type to update, either PURCHASE or PARENTAL (cf PINApi.TYPE)
     * @param {String|Number} newPin New pin code to set for the user
     * @param {String|Number} oldPin Current user's pin
     * @return {Observable}
     */
    updatePin(type, newPin, oldPin) {
        if (!type) {
            this.logger.error('[UPDATE PIN] Pin type is empty')
            return throwError(new VoltError(VoltError.codes.INVALID_REQUEST_PAYLOAD))
        }
        if (!newPin || !oldPin) {
            this.logger.error('[UPDATE PIN] Current pin or new pin is empty')
            return throwError(new VoltError(VoltError.codes.INVALID_REQUEST_PAYLOAD))
        }
        return this.passwordApi.changePassword({
            password: oldPin,
            newPassword: newPin,
            passwordType: type,
        })
    }

    /**
     * Modify user password
     * @param {Object} arg
     * @param {String} arg.password Current Password
     * @param {String} arg.newPassword New Password
     * @return {Observable<Boolean>} true if the password is verified
     */
    updatePassword({ password, newPassword }) {
        if (!password || !newPassword) {
            this.logger.error(
                '[CHANGE PASSWORD] Password, new password or confirmation password is empty'
            )
            return throwError(new VoltError(VoltError.codes.INVALID_REQUEST_PAYLOAD))
        }
        return this.passwordApi.changePassword({
            password,
            newPassword,
            passwordType: HuaweiTypes.passwordType.SUBSCRIBER_LOGIN,
        })
    }

    /**
     * Checks if a given password is valid (can be used before performing specific action like Reset PIN)
     * @param {Object} arg
     * @param {String} arg.password Password
     * @param {String} arg.passwordType Password type
     * @return {Observable<Boolean>} true if the password is verified
     */
    verifyPassword({ password }) {
        if (!password) {
            this.logger.error('[VERIFY PASSWORD] Password is empty')
            return throwError(new VoltError(VoltError.codes.INVALID_REQUEST_PAYLOAD))
        }
        return this.passwordApi.checkPassword({
            password,
            passwordType: HuaweiTypes.passwordType.SUBSCRIBER_LOGIN,
        })
    }

    /**
     * Checks if a given password is valid (can be used before performing specific action like Reset PIN)
     * @param {Object} arg
     * @param {String} arg.languageCode Should be two digit
     * @return {Observable<Boolean>} true if the password is verified
     */
    updateAccountLocale({ languageCode }) {
        return of(true)
    }
}
