export default {
    matchInfo: {
        id: 'b2sd9w5gzps3y89rid9cd8kd0',
        coverageLevel: '15',
        date: '2022-09-06Z',
        time: '19:00:00Z',
        localDate: '2022-09-06',
        localTime: '21:00:00',
        week: '1',
        numberOfPeriods: 2,
        periodLength: 45,
        lastUpdated: '2022-09-06T19:35:43Z',
        description: 'PSG vs Juventus',
        sport: {
            id: '289u5typ3vp4ifwh5thalohmq',
            name: 'Soccer',
        },
        ruleset: {
            id: '79plas4983031idr6vw83nuel',
            name: 'Men',
        },
        competition: {
            id: '4oogyu6o156iphvdvphwpck10',
            name: 'UEFA Champions League',
            competitionCode: 'UCL',
            competitionFormat: 'International cup',
            country: {
                id: '7yiwjcnhat6jo3f901h266cwl',
                name: 'Europe',
            },
        },
        tournamentCalendar: {
            id: '8t8ofk94zy6ksx1spnfecvpck',
            startDate: '2022-06-21Z',
            endDate: '2023-06-10Z',
            name: '2022/2023',
        },
        stage: {
            id: '8tug861ibh72j04p3ge06e80k',
            formatId: '86p6pz5rcsdbz4cklvfwtzrv9',
            startDate: '2022-09-06Z',
            endDate: '2022-11-02Z',
            name: 'Group Stage',
        },
        series: {
            id: '8u9nt1s77ssd5dp52cdumdwyc',
            formatId: 'cmb6lscxiyticnqnsarymfkr9',
            name: 'Group H',
        },
        contestant: [
            {
                id: '2b3mar72yy8d6uvat1ka6tn3r',
                name: 'PSG',
                shortName: 'PSG',
                officialName: 'Paris Saint-Germain FC',
                code: 'PSG',
                position: 'home',
                country: {
                    id: '7gww28djs405rfga69smki84o',
                    name: 'France',
                },
            },
            {
                id: 'bqbbqm98ud8obe45ds9ohgyrd',
                name: 'Juventus',
                shortName: 'Juventus',
                officialName: 'Juventus FC',
                code: 'JUV',
                position: 'away',
                country: {
                    id: '25f2cmb2r8mk5rj92tzer6kvv',
                    name: 'Italy',
                },
            },
        ],
        venue: {
            id: 'efcoydloex7y04q29pac9s67c',
            neutral: 'no',
            longName: 'Parc des Princes',
            shortName: 'Parc des Princes',
        },
    },
    liveData: {
        matchDetails: {
            matchTime: 35,
            periodId: 1,
            matchStatus: 'Playing',
            period: [
                {
                    id: 1,
                    start: '2022-09-06T19:00:27Z',
                },
            ],
            scores: {
                ft: {
                    home: 2,
                    away: 0,
                },
                total: {
                    home: 2,
                    away: 0,
                },
            },
        },
        event: [
            {
                id: 2456728713,
                eventId: 2,
                typeId: 32,
                periodId: 1,
                timeMin: 0,
                timeSec: 0,
                contestantId: '2b3mar72yy8d6uvat1ka6tn3r',
                outcome: 1,
                x: 0.0,
                y: 0.0,
                timeStamp: '2022-09-06T19:00:27.660Z',
                lastModified: '2022-09-06T19:00:30Z',
                qualifier: [
                    {
                        id: 3762909387,
                        qualifierId: 127,
                        value: 'Right to Left',
                    },
                ],
            },
            {
                id: 2456728707,
                eventId: 2,
                typeId: 32,
                periodId: 1,
                timeMin: 0,
                timeSec: 0,
                contestantId: 'bqbbqm98ud8obe45ds9ohgyrd',
                outcome: 1,
                x: 0.0,
                y: 0.0,
                timeStamp: '2022-09-06T19:00:27.660Z',
                lastModified: '2022-09-06T19:00:27Z',
                qualifier: [
                    {
                        id: 3762909333,
                        qualifierId: 127,
                        value: 'Left to Right',
                    },
                ],
            },
            {
                id: 2456732419,
                eventId: 93,
                typeId: 16,
                periodId: 1,
                timeMin: 4,
                timeSec: 56,
                contestantId: '2b3mar72yy8d6uvat1ka6tn3r',
                playerId: '5e9ilgrz3tzg9kd1gk3yvrahh',
                playerName: 'K. Mbappé',
                outcome: 1,
                x: 89.5,
                y: 68.0,
                timeStamp: '2022-09-06T19:05:24.240Z',
                lastModified: '2022-09-06T19:06:11Z',
                qualifier: [
                    {
                        id: 3762932737,
                        qualifierId: 55,
                        value: '92',
                    },
                    {
                        id: 3762932735,
                        qualifierId: 154,
                    },
                    {
                        id: 3762932733,
                        qualifierId: 29,
                    },
                    {
                        id: 3762932731,
                        qualifierId: 80,
                    },
                    {
                        id: 3762930045,
                        qualifierId: 56,
                        value: 'Center',
                    },
                    {
                        id: 3762930463,
                        qualifierId: 20,
                    },
                    {
                        id: 3762930471,
                        qualifierId: 375,
                        value: '04:55.592',
                    },
                    {
                        id: 3762930041,
                        qualifierId: 22,
                    },
                    {
                        id: 3762930043,
                        qualifierId: 64,
                    },
                    {
                        id: 3762930467,
                        qualifierId: 374,
                        value: '2022-09-06 20:05:23.252',
                    },
                    {
                        id: 3762932747,
                        qualifierId: 328,
                    },
                    {
                        id: 3762932745,
                        qualifierId: 108,
                    },
                    {
                        id: 3762932743,
                        qualifierId: 103,
                        value: '7.6',
                    },
                    {
                        id: 3762932751,
                        qualifierId: 230,
                        value: '99.1',
                    },
                    {
                        id: 3762932741,
                        qualifierId: 102,
                        value: '47.1',
                    },
                    {
                        id: 3762932749,
                        qualifierId: 136,
                    },
                    {
                        id: 3762932753,
                        qualifierId: 231,
                        value: '53.4',
                    },
                ],
            },
            {
                id: 2456741901,
                eventId: 137,
                typeId: 15,
                periodId: 1,
                timeMin: 18,
                timeSec: 20,
                contestantId: 'bqbbqm98ud8obe45ds9ohgyrd',
                playerId: '35r56n0i4sbdh7n5arbt7db6d',
                playerName: 'A. Milik',
                outcome: 1,
                x: 92.7,
                y: 52.6,
                timeStamp: '2022-09-06T19:18:47.836Z',
                lastModified: '2022-09-06T19:19:57Z',
                qualifier: [
                    {
                        id: 3762982211,
                        qualifierId: 55,
                        value: '135',
                    },
                    {
                        id: 3762982219,
                        qualifierId: 146,
                        value: '99.3',
                    },
                    {
                        id: 3762982205,
                        qualifierId: 79,
                    },
                    {
                        id: 3762982207,
                        qualifierId: 29,
                    },
                    {
                        id: 3762980947,
                        qualifierId: 22,
                    },
                    {
                        id: 3762982209,
                        qualifierId: 154,
                    },
                    {
                        id: 3762980949,
                        qualifierId: 17,
                    },
                    {
                        id: 3762982221,
                        qualifierId: 147,
                        value: '50',
                    },
                    {
                        id: 3762982003,
                        qualifierId: 233,
                        value: '220',
                    },
                    {
                        id: 3762982215,
                        qualifierId: 102,
                        value: '50.0',
                    },
                    {
                        id: 3762981993,
                        qualifierId: 328,
                    },
                    {
                        id: 3762982217,
                        qualifierId: 103,
                        value: '24.7',
                    },
                    {
                        id: 3762980951,
                        qualifierId: 56,
                        value: 'Center',
                    },
                    {
                        id: 3762981981,
                        qualifierId: 15,
                    },
                    {
                        id: 3762985347,
                        qualifierId: 214,
                    },
                ],
            },
            {
                id: 2456744169,
                eventId: 257,
                typeId: 16,
                periodId: 1,
                timeMin: 21,
                timeSec: 50,
                contestantId: '2b3mar72yy8d6uvat1ka6tn3r',
                playerId: '5e9ilgrz3tzg9kd1gk3yvrahh',
                playerName: 'K. Mbappé',
                outcome: 1,
                x: 85.9,
                y: 43.9,
                timeStamp: '2022-09-06T19:22:18.407Z',
                lastModified: '2022-09-06T19:23:43Z',
                qualifier: [
                    {
                        id: 3762994229,
                        qualifierId: 20,
                    },
                    {
                        id: 3762994241,
                        qualifierId: 375,
                        value: '21:49.824',
                    },
                    {
                        id: 3762992899,
                        qualifierId: 17,
                    },
                    {
                        id: 3762996267,
                        qualifierId: 29,
                    },
                    {
                        id: 3762996265,
                        qualifierId: 76,
                    },
                    {
                        id: 3762996279,
                        qualifierId: 214,
                    },
                    {
                        id: 3762996271,
                        qualifierId: 55,
                        value: '256',
                    },
                    {
                        id: 3762996277,
                        qualifierId: 103,
                        value: '5.1',
                    },
                    {
                        id: 3762996275,
                        qualifierId: 102,
                        value: '52.6',
                    },
                    {
                        id: 3762996269,
                        qualifierId: 154,
                    },
                    {
                        id: 3762992897,
                        qualifierId: 22,
                    },
                    {
                        id: 3762996287,
                        qualifierId: 231,
                        value: '46.4',
                    },
                    {
                        id: 3762994235,
                        qualifierId: 374,
                        value: '2022-09-06 20:22:17.484',
                    },
                    {
                        id: 3762996285,
                        qualifierId: 230,
                        value: '98.1',
                    },
                    {
                        id: 3762996283,
                        qualifierId: 328,
                    },
                    {
                        id: 3762996281,
                        qualifierId: 108,
                    },
                    {
                        id: 3762992901,
                        qualifierId: 56,
                        value: 'Center',
                    },
                ],
            },
            {
                id: 2456745701,
                eventId: 175,
                typeId: 17,
                periodId: 1,
                timeMin: 24,
                timeSec: 49,
                contestantId: 'bqbbqm98ud8obe45ds9ohgyrd',
                playerId: 'd196vatyvq6ycn1q7d477d2i1',
                playerName: 'Bremer',
                outcome: 1,
                x: 0.0,
                y: 0.0,
                timeStamp: '2022-09-06T19:25:17.623Z',
                lastModified: '2022-09-06T19:25:20Z',
                qualifier: [
                    {
                        id: 3763000851,
                        qualifierId: 13,
                        value: '243',
                    },
                    {
                        id: 3763000841,
                        qualifierId: 31,
                    },
                    {
                        id: 3763000845,
                        qualifierId: 392,
                    },
                ],
            },
            {
                id: 2456745827,
                eventId: 276,
                typeId: 17,
                periodId: 1,
                timeMin: 24,
                timeSec: 54,
                contestantId: '2b3mar72yy8d6uvat1ka6tn3r',
                playerId: '9adbv1dl7dp7vze3b9sarerbp',
                playerName: 'Sergio Ramos',
                outcome: 1,
                x: 0.0,
                y: 0.0,
                timeStamp: '2022-09-06T19:25:22.230Z',
                lastModified: '2022-09-06T19:26:15Z',
                qualifier: [
                    {
                        id: 3763001465,
                        qualifierId: 35,
                    },
                    {
                        id: 3763001463,
                        qualifierId: 31,
                    },
                ],
            },
        ],
    },
}
