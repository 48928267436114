import { parse } from 'iso8601-duration'

/**
 * Parse an ISO-8601 duration in milliseconds.
 * Return undefined if parsing fails.
 *
 * @see https://en.wikipedia.org/wiki/ISO_8601#Durations
 *
 * @param {String} time an ISO-8601 duration
 * @return {Number|undefined} Duration in milliseconds if parse succeed
 */
const iso8601ToMs = time => {
    try {
        let ms = parse(time)
        return (
            (ms.seconds + ((ms.days * 24 + ms.hours) * 60 + ms.minutes) * 60) *
            1000
        )
    } catch (e) {
        return
    }
}

export default iso8601ToMs
