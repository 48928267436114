import { ISoccerContestant } from '@typings/opta'
import { Abstract } from 'models/Abstract'

export class SoccerContestant extends Abstract {
    name: string
    shortName: string
    id: string
    logoUrl: string
    position: string

    constructor(props: ISoccerContestant) {
        super()
        this.name = props.name
        this.shortName = props.shortName
        this.id = props.id
        this.logoUrl = props.logoUrl
        this.position = props.position
    }
}
