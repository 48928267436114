import { of } from 'rxjs'
import { BeforeMatch, FirstHalf, HalfTime, SecondHalf, FinalTime } from './MatchStatsDump'

/**
 * Ordering of Dump file
 */
const _EVENT_CAPTURE_ORDERING = {
    0: BeforeMatch,
    1: FirstHalf,
    2: HalfTime,
    3: SecondHalf,
    4: FinalTime,
}

/**
 * Internal constants: Maximum DUMP
 */
const _MAX_EVENT_DUMP = Object.keys(_EVENT_CAPTURE_ORDERING).length

/**
 * Renew the exercise per GAME
 */
let _CACHE_EVENT_PER_GAME = {}

const addEventToCache = (gameId, event) =>
    (_CACHE_EVENT_PER_GAME = {
        ..._CACHE_EVENT_PER_GAME,
        [gameId]: event,
    })

const getNextEvent = (lastEvent) => {
    if (lastEvent === undefined) return 0
    if (lastEvent >= _MAX_EVENT_DUMP) return _MAX_EVENT_DUMP
    return lastEvent + 1
}

export const fetchLiveSimulator_MatchStats = (gameId) => {
    const lastEvent = _CACHE_EVENT_PER_GAME[gameId]
    const nextEvent = getNextEvent(lastEvent)
    const result = _EVENT_CAPTURE_ORDERING[nextEvent]
    addEventToCache(gameId, nextEvent)
    return of({ response: result })
}
