import { Person } from 'models'
import { ISoccerSquadPerson } from '@typings/opta'

export class SoccerSquadPerson extends Person {
    matchName: string
    position: string
    personHeight: string
    type: string
    status: string
    weight: string
    foot: string
    shirtNumber: string
    knownName: string
    teamId: string
    nationality: string
    dateOfBirth: string

    constructor(props: ISoccerSquadPerson) {
        super(props)
        this.id = props.id
        this.matchName = props.matchName
        this.position = props.position
        this.personHeight = props.height
        this.type = props.type
        this.status = props.status
        this.weight = props.weight
        this.foot = props.foot
        this.shirtNumber = props.shirtNumber
        this.knownName = props.knownName
        this.teamId = props.contestantId
        this.format = 'landscape'
        this.ratio = 1
        this.title = `${this.firstName} ${this.lastName}`
        this.nationality = props.nationality
        this.dateOfBirth = props.dateOfBirth
        this.personHeight = props.height
        this.weight = props.weight
    }

    isSoccerSquadPerson() {
        return true
    }

    static PLAYER_POSITIONS = {
        goalKeeper: 'Goalkeeper',
        defender: 'Defender',
        midfielder: 'Midfielder',
        attacker: 'Attacker',
        unknown: 'Unknown',
    }

    static PLAYER_FOOTS = {
        left: 'left',
        right: 'right',
        both: 'both',
    }

    static PERSON_TYPES = {
        player: 'player',
        referee: 'referee',
        coach: 'coach',
        staff: 'staff',
        assistantCoach: 'assistant coach',
    }

    static PERSON_STATUS = {
        active: 'active',
        retired: 'retired',
        died: 'died',
    }
}
