export default {
    highlightNumberOfBarkers: {
        carousel: 3,
        info: 1,
        duo: 2,
        duoHalf: 2,
        tertio: 3,
        tertioHalf: 3,
        quatro: 4,
        quatroHalf: 4,
        barker: 1,
        fullscreen: 1,
    },

    // ratio = height / width
    barkerRatio: {
        carousel: 342 / 607,

        duo: 342 / 727,
        duoHalf: 342 / 727,

        tertio: 342 / 607,
        tertioHalf: 342 / 607,

        quatro: 342 / 361,
        quatroHalf: 342 / 361,

        barker: {
            landscape: 342 / 1345,
            portrait: 342 / 727,
        },

        info: {
            landscape: 390 / 1610,
            portrait: 390 / 520,
        },
        fullscreen: {
            landscape: 1920 / 1080,
        },
        'dvr-storage': {
            landscape: 390 / 1610,
            portrait: 390 / 520,
        },
        'wallet-info': {
            landscape: 390 / 1610,
            portrait: 390 / 520,
        },
    },

    // ! needs to be confirmed ! (temporarily set at 2 months)
    // in milliseconds. When process fails to retrieve EMI programmation it tries to
    // fallback to a previously stored programmation. This one should not be older than this
    // age limit.
    programmingAgeLimit: 60 * 24 * 60 * 60 * 1000,

    applicationType: {
        internalLink: 13,
        androidApplication: 3,
    },
}
