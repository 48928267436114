import Constants from 'api-constants'

import Fetch from '../fetch'
import defaultConfig from './defaultConfig'

const { emiModules } = Constants

export default class HomeModule extends Fetch {
    constructor(config) {
        super(config, emiModules.HOME, defaultConfig)
    }

    /**
     * @param {String} population Targeted population
     * @returns {AjaxObservable}
     */
    getConfig = (population) => this.getEmiConfig(population)
}
