export const getComputedStyle = (element: any) => {
    var maxHeight = 0;
    var childElements = element?.children;

    if(childElements) {
        for (var i = 0; i < childElements?.length; i++) {
            var computedStyles = window.getComputedStyle(childElements[i]);

            var totalHeight =
                parseFloat(computedStyles.height) +
                parseFloat(computedStyles.marginTop) +
                parseFloat(computedStyles.marginBottom) + 
                parseFloat(computedStyles.paddingTop) + 
                parseFloat(computedStyles.paddingBottom);
                
            maxHeight = Math.max(maxHeight, totalHeight);
        }
    }

    return maxHeight;
}
