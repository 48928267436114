import commonConfig from './common.js'
import prodConfig from './production'
import stagingConfig from './staging'
import qaConfig from './qa'
import internalProdConfig from './internalProd'
import devConfig from './development'
import { changeEnv } from './env.js'

const apiConfig = (() => {
    let env = 'development'
    try {
        env = changeEnv()
    } catch (e) {
        throw new Error('No environment specified')
    }

    switch (env) {
        case 'development':
            return devConfig
        case 'qa':
            return qaConfig
        case 'staging':
            return stagingConfig
        case 'preproduction':
            return internalProdConfig
        case 'production':
            return prodConfig
        default:
            throw new Error('unknown env: ' + env)
    }
})()

const allApiConfig = {
    development: devConfig,
    qa: qaConfig,
    staging: stagingConfig,
    preproduction: internalProdConfig,
    production: prodConfig,
}
export default { ...commonConfig, ...apiConfig, allApiConfig }
