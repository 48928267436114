import {IconContext} from './IconContext'

export interface IconProviderProps {
    icons: {[k: string]: any}
    children: any
}

export const IconProvider = (props: IconProviderProps) => {
    const getIcon = (icon: string) => {
        return props.icons[icon]
    }

    return <IconContext.Provider value={{get: getIcon}}>{props.children}</IconContext.Provider>
}

export interface IconProviderBehavior {
    get: (icon: string) => any
}
