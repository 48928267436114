//import VoltError from 'VoltError'

import VoltError from 'VoltError'

export const ERROR_BACKEND_MAPPING = {
    'access token expired': () => VoltError.codes.PAYMENT_TOKEN_ERROR,

    'auth-100': () => VoltError.codes.LOGIN_INVALID_CREDENTIALS,

    1: (_, description = '') => {
        if (description.startsWith('IPTV associated DN not found'))
            return VoltError.codes.ACCOUNT_NOT_FOUND

        if (description.startsWith('Pending transaction'))
            return VoltError.codes.PURCHASE_FAILED_DUE_EXISTING_ENTITLEMENT

        if (description.startsWith('Invalid BMS product id provided'))
            return VoltError.codes.PURCHASE_FAILED_DUE_TO_MISSING_ASSET

        return VoltError.codes.PURCHASE_FAILED
    },

    100: (_, description = '') => {
        if (description.startsWith('Customer details not found'))
            return VoltError.codes.INVALID_ACCOUNT_ID

        if (description.startsWith('You can only generate a new PIN every 1 minute'))
            return VoltError.codes.PIN_GENERATION_LIMIT

        if (description.startsWith('No template found'))
            return VoltError.codes.HTTP_500_INTERNAL_SERVER_ERROR

        if (
            description.startsWith(
                'The Mobile number entered is not a valid number please try again'
            )
        )
            return VoltError.codes.PHONE_NUMBER_UNKNOWN_BY_OPERATOR

        // Service Specific errors
        if (description.startsWith('Failure at GetAssociateDNDetailsCRM'))
            return VoltError.codes.ACCOUNT_NOT_FOUND

        if (description.startsWith('Failure at IPTVRequestValidLMSDetailsCRM'))
            return VoltError.codes.PURCHASE_FAILED
    },

    101: (_, description = '') => {
        if (description.startsWith('IPTV Account with UHD does not exist under this customer'))
            return VoltError.codes.ACCOUNT_NOT_FOUND

        if (description.startsWith('The pin you have entered is wrong'))
            return VoltError.codes.INVALID_PIN

        if (description.startsWith('The incoming request is from a different client'))
            return VoltError.codes.INVALID_PIN

        if (description.startsWith('Failure in LMS ManageOrder'))
            return VoltError.codes.PURCHASE_FAILED

        // Real error The pin is expired, please generate a new one
        if (description.startsWith('The pin is expired')) return VoltError.codes.PIN_EXPIRED
    },

    102: (_, description = '') => {
        if (description.startsWith('Exception in LMS ManageOrder'))
            return VoltError.codes.PURCHASE_FAILED

        return VoltError.codes.HTTP_500_INTERNAL_SERVER_ERROR
    },

    'Service Unavailable': () => VoltError.codes.SERVICE_NOT_AVAILABLE,

    // Service Specific errors
    110: () => VoltError.codes.SUBSCRIPTION_ACTIVATION_FAILED,

    // platform error: [3003] Maximum 1 product can be opted for Ooredoo ONE - Filipino | 3003
    3003: () => VoltError.codes.PURCHASE_FAILED,

    1101: (_, description = '') => {
        //  1101 Mandatory Parameter Missing
        return VoltError.codes.PURCHASE_FAILED_DUE_INVALID_PARAMETERS
    },

    1105: (_, description = '') => {
        // 1105 Invalid Channel
        return VoltError.codes.PURCHASE_FAILED_DUE_INVALID_PARAMETERS
    },

    1106: (_, description = '') => {
        // Invalid Order Type
        return VoltError.codes.PURCHASE_FAILED_DUE_INVALID_PARAMETERS
    },

    1107: (_, description = '') => {
        // 1107 Missing reservation ID
        return VoltError.codes.PURCHASE_FAILED
    },

    1108: (_, description = '') => {
        if (description.startsWith('Sufficient credits not available')) {
            return VoltError.codes.INSUFFICIENT_FUNDS
        }

        return VoltError.codes.INSUFFICIENT_FUNDS
    },
}
