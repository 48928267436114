import Constants from 'api-constants'

const { TVOD, SVOD, FVOD, AVOD, PVOD, NVOD, CUPTV } = Constants.purchaseType
const {
    movie,
    episode,
    season,
    series,
    collection,
    episodicShow,
    orphanSeason,
    orphanEpisode,
    vodCatchup,
    unknown,
} = Constants.programType

export default {
    vodTypes: {
        [movie]: 0,
        [series]: 1,
        [season]: 2,
        [episode]: 3,
        [collection]: 4,
        [episodicShow]: 5,
        [orphanSeason]: 6,
        [orphanEpisode]: 7,
        [vodCatchup]: 8,
        [unknown]: 100,
    },

    purchaseTypes: {
        1: TVOD,
        2: CUPTV,
        3: SVOD,
        4: NVOD,
        5: PVOD,
        6: AVOD,
        7: FVOD,
    },

    productStatus: {
        activated: 'activated',
        deactivated: 'deactivated',
        suspended: 'suspended',
    },

    deeplinkTypes: {
        ANDROID_TV: 0,
        ANDROID: 1,
        IOS: 2,
        TVOS: 3,
        WEB: 4,
        FIRE_TV: 5,
    },

    streamTypes: {
        MAIN: 0,
        TRAILER: 1,
        RECORDING: 2,
        ADVERTISING: 3,
        ANDROID: 4,
        SUBTITLE: 5,
        YOUTUBE_TRAILER: 7,
        HLS: 8,
        DASH: 9,
    },

    avOutput: {
        NONE: 0,

        // Video Format
        V_ADAPTIVE: 1,
        V_SD: 2,
        V_HD: 4,
        V_3D: 8,
        V_UHD: 16,
        V_4K: 32,

        // Audio Format
        A_STEREO: 64,
        A_DOLBY: 128,
        A_HD_5_1: 256,
        A_HD_7_1: 512,
        A_MONO: 1024,
        A_DTS: 2048,
    },

    eligibility: {
        NONE: 0,
        OTT: 1,
        IPTV: 2,
        IPTV_HD: 4,
        IPTV_SD: 8,
    },
}
