import Fetch from './fetch'
import { forkJoin, of, throwError } from 'rxjs'
import { mergeMap, map, catchError } from 'rxjs/operators'
import { youtubeContentFactory } from './factories/youtubeContent'
import { resolveCategory } from './helpers'
import youtubeConfig from './config'
import VoltError from 'VoltError'
import { IGetContentByCategory, IGetContentByPlaylist, IYoutubeResponse } from './types'

const endpoint = {
    video: 'videos',
    playlist: 'playlists',
    playlistItems: 'playlistItems',
}

export default class YoutubeApi extends Fetch {
    /** Retrieves Youtube Content(s) of a given category */
    getContentByCategory = ({ category, page = 0, limit = 15 }: IGetContentByCategory) => {
        const youtubeVideoCategoryId = resolveCategory(category)
        if (youtubeVideoCategoryId === youtubeConfig.youtubeCategoryID.YOUTUBE_ID_UNKNOWN) {
            // LOG here : Youtube video category unknown. Return empty result
            return of({ contents: [], done: true })
        }

        const { apiUrl, enableStatusYoutubeVideo } = youtubeConfig
        return this.fetch({
            endpoint: apiUrl + endpoint.video,
            page: page,
            limit: limit,
            params: {
                videoCategoryId: youtubeVideoCategoryId,
                chart: 'mostPopular',
            },
            withStatus: enableStatusYoutubeVideo,
            log: `YOUTUBE_BY_CATEGORY_${youtubeVideoCategoryId}`,
        }).pipe(
            map((result) => {
                const contents = result.items.map((x: IYoutubeResponse) =>
                    youtubeContentFactory(x, this.config.platform)
                )
                return {
                    contents,
                    done: this.isContentRetrievalDone(page, result.nextPageToken),
                }
            }),
            catchError((err) => {
                // We know these codes are errors but it is to avoid disruptive pop-up in the UI.
                // Logger is enough to identify those use cases. Especially if the content is no more supported by Google
                if (
                    err.code === VoltError.codes.HTTP_400_BAD_REQUEST.code ||
                    err.code === VoltError.codes.HTTP_401_UNAUTHORIZED.code ||
                    err.code === VoltError.codes.HTTP_403_FORBIDDEN.code ||
                    err.code === VoltError.codes.HTTP_404.code ||
                    err.code === VoltError.codes.INVALID_CONTENT_PAGE.code ||
                    err.code === VoltError.codes.UNAUTHORIZED_CONTENT.code
                ) {
                    return of({ contents: [], done: true })
                }
                return throwError(err)
            })
        )
    }

    /** Retrieves Youtube Content(s) of a given category */
    getContentByPlaylist = ({ playlistID, page = 0, limit = 15 }: IGetContentByPlaylist) => {
        const { apiUrl, filterPrivateVideo } = youtubeConfig
        const { usePlaylistFirstItem = true } = this.config.youtube

        const retrievePlaylist = page === 0 && usePlaylistFirstItem
        return forkJoin([
            // Playlist should be retrieved only while retrieved the first page
            retrievePlaylist
                ? this.fetch({
                      endpoint: apiUrl + endpoint.playlist,
                      page: page,
                      params: {
                          id: playlistID,
                      },
                  })
                : of([]),
            // Playlist items are always retrieved
            this.fetch({
                endpoint: apiUrl + endpoint.playlistItems,
                page: page,
                limit: retrievePlaylist ? (limit > 0 ? limit - 1 : 15) : limit, // Need to remove the item retrieve by Playlist
                params: {
                    playlistId: playlistID,
                },
                withStatus: filterPrivateVideo,
                log: `YOUTUBE_PLAYLIST_${playlistID}`,
            }),
        ]).pipe(
            mergeMap(([playlist, playlistItems]) => {
                const contents = [...(playlist.items || []), ...(playlistItems.items || [])]
                    .map((x: IYoutubeResponse) => youtubeContentFactory(x, this.config.platform))
                    // There are no filtering API from Youtube, unfortunately we need for filter here after parsing
                    .filter(
                        (contents) =>
                            !filterPrivateVideo || (filterPrivateVideo && !contents.isPrivateVideo)
                    )

                return of({
                    contents,
                    done: this.isContentRetrievalDone(page, playlistItems.nextPageToken),
                })
            }),
            catchError((err) => {
                // We know these codes are errors but it is to avoid disruptive pop-up in the UI.
                // Logger is enough to identify those use cases. Especially if the content is no more supported by Google
                if (
                    err.code === VoltError.codes.HTTP_400_BAD_REQUEST.code ||
                    err.code === VoltError.codes.HTTP_401_UNAUTHORIZED.code ||
                    err.code === VoltError.codes.HTTP_403_FORBIDDEN.code ||
                    err.code === VoltError.codes.HTTP_404.code ||
                    err.code === VoltError.codes.INVALID_CONTENT_PAGE.code ||
                    err.code === VoltError.codes.UNAUTHORIZED_CONTENT.code
                ) {
                    return of({ contents: [], done: true })
                }
                return throwError(err)
            })
        )
    }
}
