import {useIcon} from '../icon/useIcon'
import {Show} from 'solid-js'
import './login.css'
import {useConfig} from '../config/useConfig'
import { useI18n } from '../../i18n';

interface LoginProps {
    onLogin: () => void
    failed: boolean
}

export const Login = (props: LoginProps) => {
    const {getConfig} = useConfig()
    const {getI18n} = useI18n()
    const icons = useIcon()
    return (
        <div
            style={{
                display: 'flex',
                'justify-content': 'center',
                'align-items': 'center',
                height: '100vh',
                'background-image': `url(${icons.get('LoginBackdrop')})`,
                'background-size': 'cover',
            }}
        >

            <div class="login-content">
                {/* <div class="login-content-heading">
                    <img class="logo-image" src={`${icons.get('logo')}`} />
                </div> */}
                <div class="client_logo_loading_logout_screen"></div>
                {/*TODO: Check for errors in state*/}
                {/* Commented below code as we don't want it to be displayed (We are showing the toast instead of it) */}
                {/* <Show when={props.failed}>
                    <div class="login-content-errors">Unfortunately something went wrong.</div>
                </Show> */}
                <div class="login-content-info">{getI18n()?.formatMessage({id: 'LOGIN_MSG'})}</div>
                <button class="login-content-button" onClick={props.onLogin}>
                    {getI18n()?.formatMessage({id: 'LOG_IN'})}
                </button>
                <Show when={getConfig().allowGuest}>
                    <button class="login-content-button" onClick={() => console.log('Guest Continue clicked')}>
                        {getI18n()?.formatMessage({id: 'CONTINUE_AS_GUEST'})}
                    </button>
                </Show>
                <div class="login-content-footer">
                    {/*TODO: feed this from config?*/}
                    <Show when={false}>
                        {getI18n()?.formatMessage({id: 'NEW_TO'})}{getConfig()?.client}? <a href="#"> {getI18n()?.formatMessage({id: 'CREATE_ACCOUNT'})}</a>
                    </Show>
                </div>
            </div>
        </div>
    )
}
