import {ConfigContext} from './ConfigContext'
import {createSignal, onMount, Show} from 'solid-js'
import merge from 'lodash.merge'

export interface ConfigProps {
    defaultConfig: any
    children: any
}

export const ConfigProvider = (props: ConfigProps) => {
    const [config, setConfig] = createSignal(undefined)
    onMount(() => {
        const conf = merge(
            props?.defaultConfig ?? {},
            JSON.parse(localStorage.getItem('appConfig') ?? '{}')?.[props.defaultConfig.client] || {}
        )
        setConfig(conf)
    })

    return (
        <ConfigContext.Provider value={{getConfig: () => config()}}>
            <Show when={config()}>{props.children}</Show>
        </ConfigContext.Provider>
    )
}

export interface ConfigProviderBehavior {
    getConfig: () => any
}
