import { Channel } from './Channel'

/**
 * A MosaicChannel
 */
export class MosaicChannel extends Channel {
    /**
     * @return {Boolean}
     */
    isMosaicChannel() {
        return true
    }
}
