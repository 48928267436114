import Fetch from '../fetch'
import DataHelper from 'framework/helpers/data'
import { mergeMap } from 'rxjs/operators'
import { of } from 'rxjs'

export default class PasswordApi extends Fetch {
    constructor(config, otherApis = {}) {
        super(config, otherApis)
    }

    changePassword = ({ password, newPassword, passwordType }) =>
        this.fetch({
            url: `${DataHelper.getInstance().getData(
                DataHelper.STORE_KEY.BACKEND_API_URL
            )}/VSP/V3/ModifyPassword`,
            method: 'POST',
            body: {
                oldPwd: password,
                newPwd: newPassword,
                confirmPwd: newPassword,
                type: passwordType,
            },
            log: `CHANGE PASSWORD FOR ${passwordType} TYPE`,
        }).pipe(
            mergeMap(({ response } = {}) => {
                if (!response) return of(false)
                return of(true)
            })
        )

    checkPassword = ({ password, passwordType }) =>
        this.fetch({
            url: `${DataHelper.getInstance().getData(
                DataHelper.STORE_KEY.BACKEND_API_URL
            )}/VSP/V3/CheckPassword`,
            method: 'POST',
            body: {
                password,
                type: passwordType,
            },
            log: `VERIFY PASSWORD FOR ${passwordType} TYPE`,
        }).pipe(
            mergeMap(({ response } = {}) => {
                if (!response) return of(false)
                return of(true)
            })
        )

    resetPassword = ({ profileId, passwordType }) =>
        this.fetch({
            url: `${DataHelper.getInstance().getData(
                DataHelper.STORE_KEY.BACKEND_API_URL
            )}/VSP/V3/ResetPassword`,
            method: 'POST',
            body: {
                profileID: profileId,
                type: passwordType,
            },
            log: `RESET PASSWORD FOR ${profileId} WITH ${passwordType} TYPE`,
        }).pipe(
            mergeMap(({ response } = {}) => {
                if (!response) return of(false)
                return of(true)
            })
        )
}
