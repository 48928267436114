export type ParsedUrl = {
    protocol?: string
    host?: string
    port?: string
    pathname?: string
    query?: string
}

export const parseUrl = (url?: string): ParsedUrl => {
    if (!url) {
        return {
            protocol: undefined,
            host: undefined,
            pathname: undefined,
        }
    }

    const regex =
        // eslint-disable-next-line no-useless-escape
        /(?<protocol>^http[s]?\:\/\/)(?<host>[^:\/?#]*)(?:\:(?<port>[0-9]+))?(?<pathname>[\/]{0,1}[^?#]*)(?<query>\?[^#]*|)(#.*|)$/

    const { groups } = (regex.exec(url) ?? {
        groups: {
            protocol: undefined,
            host: undefined,
            port: undefined,
            pathname: undefined,
            query: undefined,
        },
    }) as { groups: ParsedUrl }

    return groups
}
