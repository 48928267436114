import { BackendDevice } from 'models'
import HuaweiTypes from '../HuaweiTypes'
import { DEVICE_DEFAULT_CONFIG } from '../Config'

export const backendDeviceFactory = (backendDevice) => {
    const {
        status = HuaweiTypes.deviceStatus.ACTIVATED,
        ID,
        name,
        terminalVendor,
        deviceType,
        deviceModel,
        physicalDeviceID,
        lastLoginTime,
    } = backendDevice || {}

    let deviceName =
        name ||
        terminalVendor ||
        (DEVICE_DEFAULT_CONFIG[deviceModel] &&
            DEVICE_DEFAULT_CONFIG[deviceModel].defaultDisplayedName) ||
        'Unknown device'

    return new BackendDevice({
        id: ID,
        deviceUUID: physicalDeviceID,
        name: deviceName,
        deviceType: deviceType,
        lastLoginTime: HuaweiTypes.parseInt(lastLoginTime),
        active: status === HuaweiTypes.deviceStatus.ACTIVATED,
    })
}
