import { useContext } from "solid-js";
import ReduxContext from "../components/ReduxContext";

export function useReduxContext() {
  const contextValue: any = useContext(ReduxContext);
  if (!contextValue) {
    throw new Error(
      "could not find redux context value; please ensure the component is wrapped in a <ReduxProvider>",
    );
  }
  return contextValue;
}
