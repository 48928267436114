/**
 * Purpose of this DefaultStorage class is to be compliant with the ReactNative
 * AsyncStorage module, since VoltApi is used on WebBrowser and on ReactNative.
 *
 * This class is a simple wrapper to :
 * - Uses promise notation for getter/setter
 * - Expose multiGet, multiSet and multiRemove methods
 */
export default class DefaultStorage {
    getItem(key: string) {
        return Promise.resolve(localStorage.getItem(key))
    }

    setItem(key: string, value: string) {
        localStorage.setItem(key, value)
        return Promise.resolve()
    }

    removeItem(key: string) {
        localStorage.removeItem(key)
        return Promise.resolve()
    }

    multiGet(keys: string[]) {
        return Promise.resolve(keys.map((k) => [k, localStorage.getItem(k)]))
    }

    multiSet(keyValuePairs: string[][]) {
        keyValuePairs.forEach(([k, v]) => {
            localStorage.setItem(k, v)
        })

        return Promise.resolve()
    }

    multiRemove(keys: string[]) {
        keys.forEach((k) => localStorage.removeItem(k))
        return Promise.resolve()
    }
}
