import { throwError } from 'rxjs'
import { ajax } from 'rxjs/ajax'
import { map, catchError, timeoutWith } from 'rxjs/operators'
import { serialize } from 'helpers/index.js'
import PagingManager from './helpers/pagingManager'
import Constants from 'api-constants'
import VoltError from 'VoltError'
import MockLogger from 'MockLogger'
import { Config, Logger } from '@typings/generic'
import { IFetchArgs } from './types'

/**
 * Fetching class of Youtube API
 *
 * This is a wrapping and minimalist fetching class to google API.
 * Yet this class allows to perform a HTTP 'GET' of Youtube Content.
 *
 * @todo
 * There are a Google API SDK and related React packages which can do this
 * by getting the Firebase configuration file to perform complex integration
 * like authentication (Oauth2 to retrieve channels and subscriptions), posting content, etc...
 * Those SDK only perform the HTTP request and do not manage the intelligence
 * around (paging management, ...)
 *
 * But as our need is yet minimalist (Get Videos and playlists), this is why this fetching
 * class has been introduced.
 * But it can be changed in the feature if we manage API which requires
 */

export default class Fetch {
    config: Config
    pagingManager: PagingManager
    logger: Logger
    constructor(config: Config) {
        this.config = config
        this.pagingManager = new PagingManager()
        this.logger = (config.logger || MockLogger).createChildInstance('youtube')
    }

    static HTTP_SETTINGS = {
        SUCCESS_CODE: 200,
        RESPONSE_TYPE: 'json',
    }

    static DEFAULT_PARAMS = {
        part: 'snippet',
        regionCode: 'US',
    }

    /**
     * Fetching method. Perform GET of Youtube Contents
     */
    fetch = ({ endpoint, page, limit, params, withStatus = false, log = '' }: IFetchArgs) => {
        const { DEFAULT_PARAMS, HTTP_SETTINGS } = Fetch
        const { apiKey, regionCode, maxPageAllowed } = this.config.youtube

        if (page >= maxPageAllowed) {
            return throwError(
                new VoltError(VoltError.codes.UNAUTHORIZED_CONTENT, {
                    extraLog: 'Page Unauthorized to be retrieved',
                })
            )
        }

        const url =
            endpoint +
            serialize({
                part: DEFAULT_PARAMS.part + (withStatus ? ',status' : ''),
                key: apiKey,
                regionCode: regionCode,
                ...params,
            })
        let pageToken = undefined

        if (page > 0) {
            pageToken = this.pagingManager.getPageToken(url, page)
            if (pageToken === undefined) {
                return throwError(
                    new VoltError(VoltError.codes.INVALID_CONTENT_PAGE, {
                        extraLog: 'Unable to get the youtube page token to retrieve next page',
                    })
                )
            }
        } else {
            this.pagingManager.clearPageToken()
        }

        const method = 'GET'
        this.logger.debug(`[${log}] HttpRequest ${url}`, { method })

        return ajax({
            url:
                url +
                serialize(
                    {
                        maxResults: limit,
                        pageToken: pageToken,
                    },
                    '&'
                ), // Do not assign URL to these two parameters to manage paging properly
            async: true,
            crossDomain: true,
            method,
            responseType: HTTP_SETTINGS.RESPONSE_TYPE,
        }).pipe(
            timeoutWith(
                this.config.timeout?.youtube || Constants.fallbackTimeout.youtube,
                throwError(new VoltError(VoltError.codes.REQUEST_TIMEOUT))
            ),
            catchError((err) => {
                const voltError = this._parseError(err && err.response)
                this.logger.error(`[${log}] HttpRequest ${url}`, { url, method: 'GET', error: err })

                return throwError(voltError)
            }),
            map((body) => {
                this.logger.trace(`[${log}] HttpRequest response`, body.response)

                const { HTTP_SETTINGS } = Fetch
                if (body.status === HTTP_SETTINGS.SUCCESS_CODE) {
                    this.pagingManager.saveNextPageToken(url, page, body.response.nextPageToken)

                    return body.response
                }

                throw new VoltError(VoltError.codes.UNKNOWN_API_ERROR, {
                    extraLog: String(body.status),
                })
            })
        )
    }

    /**
     * This methods determines if all content has been retrieved using paging
     * This method take into account the maximum page allowed by our configuration
     */
    isContentRetrievalDone = (currentPage: number, nextPageToken: string) => {
        const { maxPageAllowed } = this.config.youtube
        const isNextPage = nextPageToken !== undefined
        if (isNextPage && currentPage + 1 >= maxPageAllowed) {
            // ADD Log here: Next page available for not allowed. Mark content as retrieved'
            return true
        }
        return !isNextPage
    }

    /** Parsing Error */
    _parseError = (response: { error: { code: number; message: string } }) => {
        const { error } = response
        const { code, message = '' } = error
        switch (code) {
            case 400:
                return new VoltError(VoltError.codes.HTTP_400_BAD_REQUEST, {
                    extraLog: `platform error: ${code} : ${message}`,
                })
            case 401:
                return new VoltError(VoltError.codes.HTTP_401_UNAUTHORIZED, {
                    extraLog: `platform error: ${code} : ${message}`,
                })
            case 403:
                return new VoltError(VoltError.codes.HTTP_403_FORBIDDEN, {
                    extraLog: `platform error: ${code} : ${message}`,
                })
            case 404:
                return new VoltError(VoltError.codes.HTTP_404, {
                    extraLog: `platform error: ${code} : ${message}`,
                })
            default:
                return new VoltError(VoltError.codes.UNKNOWN_API_ERROR, {
                    extraLog: `platform error: ${code} : ${message}`,
                })
        }
    }
}
