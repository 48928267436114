import { ITvService } from '@typings/tile'
import { Abstract } from './Abstract'

/**
 * @class TvService
 * @property {String} name Payment Method Name
 * @property {String} serviceId The is or the Service
 * @property {String} serviceType Type of the service
 * @property {String} parentAccountId The ID of the account where the service belongs to
 * @property {Boolean} purchaseEligible Indicate if the service is eligible to purchase
 */

export class TvService extends Abstract {
    name: string
    serviceId: string
    serviceType: string
    parentAccountId: string
    purchaseEligible: boolean

    constructor(props: ITvService) {
        super()
        this.name = props.name
        this.serviceId = props.serviceId
        this.serviceType = props.serviceType
        this.parentAccountId = props.parentAccountId
        this.purchaseEligible = props.purchaseEligible
    }
}
