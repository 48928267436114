import { ISubscriptionDetail } from '@typings/tile'
import { Abstract } from './Abstract'

/**
 * An SubscriptionDetail refers to In App Purchase Products for external Store (Google, Apple, Roku, Amazon)
 * @property {String} subscriptionId Refers to subscription Id provided by the proxy API (corresponding to productID)
 * @property {String} entitlementId Related metadata associated to subscription Id
 */
export class SubscriptionDetail extends Abstract {
    subscriptionId: string
    entitlementId: string

    constructor(props: ISubscriptionDetail) {
        super()

        this.subscriptionId = props.subscriptionId
        this.entitlementId = props.entitlementId
    }
}
