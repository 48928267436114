import { IntlShape, createIntl, createIntlCache } from '@formatjs/intl';
import { createEffect, createSignal } from 'solid-js';
import { I18nProviderProps } from './I18nProviderProps';
import { I18nProviderContext } from './I18nProviderContext';
import { useSelector } from '../store';

export const I18nProvider = (props: I18nProviderProps) => {
    const cache = createIntlCache();
    const locale = useSelector((state: any) => state?.language?.locale)

    const [intl, setIntl] = createSignal<any>(createIntl({
        locale: locale() ?? 'en',
        messages: props.getMessages(locale())
    }, cache));


    createEffect(() => {
        const intlInstance = createIntl({
            locale: locale() ?? 'en',
            messages: props.getMessages(locale())
        }, cache);
        setIntl(intlInstance);
    });

    return (
        <I18nProviderContext.Provider value={{getI18n: () => intl()}}>
            {props.children}
        </I18nProviderContext.Provider>
    );
}

export interface I18nProviderBehavior {
    getI18n: () => IntlShape
}