import en from '../client/__translations__/en.json'
import ar from '../client/__translations__/ar.json'
import zh from '../client/__translations__/zh.json'

export const getLanguage = (locale: string) => {
    switch (locale) {
        case 'en':
            return en
        case 'ar':
            return ar
        case 'zh':
            return zh
        default:
            return en
    }
}
