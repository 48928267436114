import { render } from 'solid-js/web'
import config from './config'
import { configureStoreFactory } from '@ifs/volt-store'
import voltApi from './client/client-api/clientapi'
import icons from './client/client-assets'
import { AppBootstrap, ToastPosition } from '@volt-libs/foundation'
import '@volt-libs/components/styles'
import '@volt-libs/foundation/styles'
import './index.css'
import { Navigate, RouteDefinition } from '@solidjs/router'
import { formatUrl, setLogoButton } from './utils/setLogoButtonColor'
import axios from 'axios'
import { createEffect, lazy } from 'solid-js'
import { getLanguage } from './utils/getLanguage'

const root = document.getElementById('root')

const { UNKNOWN_ERROR, UNKNOWN_API_ERROR, LOGIN_REQUIRED, PLAYER_ENTITLEMENT_REVOKED, INVALID_PIN, RECORDING_NO_QUOTA_SUBSCRIPTION, USER_SESSION_NOT_FOUND_OR_TIMEOUT } = voltApi.VoltError.codes
const ignoredNotification = [
    // Filter out LOGIN_REQUIRED -> shall be handled by to restart starter Kit
    LOGIN_REQUIRED.code,
    // Filter out PLAYER_ENTITLEMENT_REVOKED because this error triggers a purchasing channel flow (channel not entitled)
    PLAYER_ENTITLEMENT_REVOKED.code,
    // Filter out generic errors (not précise enough to display coherent information message)
    config.env !== 'development' && UNKNOWN_ERROR.code,
    config.env !== 'development' && UNKNOWN_API_ERROR.code,
    INVALID_PIN.code,
    RECORDING_NO_QUOTA_SUBSCRIPTION.code,
    USER_SESSION_NOT_FOUND_OR_TIMEOUT.code
]

const APP_ROUTES: RouteDefinition[] = [
    {
        path: '/',
        children: [
            {
                path: '/',
                component: () => <Navigate href={'/home'} />,
            },
            {
                path: '/oauthredirect',
                component: lazy(() => import('@volt-libs/foundation').then(module => ({ default: module.AuthRedirect }))),
            },
            {
                path: '/:parentNode',
                component: lazy(() => import('./App')),
                children: [
                    {
                        path: ['/', '/:childNode'],
                        component: lazy(() => import('./components/layout/Layout')),
                        children: [
                            {
                                path: '/',
                                component: lazy(() => import('./components/mainContent/MainContent')),
                            },
                        ],
                    },
                    {
                        path: ['/collection'],
                        component: lazy(() => import('./components/layout/Layout')),
                        children: [
                            {
                                path: '/:childNode',
                                component: lazy(() => import('./components/collection/Collection')),
                            },
                        ],
                    },
                ],
            },
            {
                path: '/user-search',
                component: lazy(() => import('./App')),
                children: [
                    {
                        path: '/',
                        component: lazy(() => import('./components/layout/Layout')),
                        children: [
                            {
                                path: '/',
                                component: lazy(() => import('./pages/searchPage/SearchPage')),
                            },
                        ],
                    },
                ],
            },
            {
                path: '/user-settings',
                component: lazy(() => import('./App')),
                children: [
                    {
                        path: '/',
                        component: lazy(() => import('./components/layout/Layout')),
                        children: [
                            {
                                path: '/',
                                component: lazy(() => import('./pages/userSettings/UserSettings')),
                            },
                        ],
                    },
                ],
            },
            {
                path: '/player/:id',
                component: lazy(() => import('./App')),
                children: [
                    {
                        path: '/',
                        component: lazy(() => import('./components/player/Player')),
                    },
                ],
            },
            {
                path: '/view-more/:requestId',
                component: lazy(() => import('./App')),
                children: [
                    {
                        path: '/',
                        component: lazy(() => import('./components/layout/Layout')),
                        children: [
                            {
                                path: '/',
                                component: lazy(() => import('./components/viewMore/ViewMore')),
                            },
                        ],
                    },
                ],
            },
            {
                path: '/view-more-recorings/:requestId',
                component: lazy(() => import('./App')),
                children: [
                    {
                        path: '/',
                        component: lazy(() => import('./components/layout/Layout')),
                        children: [
                            {
                                path: '/',
                                component: lazy(() => import('./pages/recordingPage/layouts/ViewMore')),
                            },
                        ],
                    },
                ],
            },
            {
                path: '*',
                component: () => <Navigate href={'/home'} />,
            },
        ],
    },
]

/*** Routes not for production ***/
const APP_ROUTES_DEV_ONLY = [
    {
        path: '/settings',
        component: lazy(() => import('./pages/settings/Settings')),
    },
]

render((): any => {
    const population: any = localStorage.getItem('EMI_POPULATION') || config?.urls?.emi?.defaultPopulation

    createEffect(() => {
        const url = formatUrl(config?.urls?.emi?.baseUrl, config?.urls?.emi?.homePath, population)
        axios
            .get(`${url}/revision.json`)
            .then(res => axios.get(`${url}/${res.data.revision}.json`))
            .then(r => setLogoButton(r.data))
            .catch(e => console.error(e))
    })

    return (
        <AppBootstrap
            ignoredNotification={ignoredNotification}
            defaultConfig={config}
            storeOptions={{
                voltApi,
                configureStoreFactory,
            }}
            toastPosition={ToastPosition.TOP_CENTER}
            icons={icons}
            routes={[...APP_ROUTES ,...APP_ROUTES_DEV_ONLY]}
            getLanguage={getLanguage}
        />
    )
}, root!)
