import { of, forkJoin, throwError } from 'rxjs'
import { mergeMap, catchError } from 'rxjs/operators'
import Constants from 'api-constants'
import Fetch from '../fetch'
import ConfigHelper from 'framework/helpers/config'
import DataHelper from 'framework/helpers/data'
import VoltError from 'VoltError'
import HuaweiTypes from '../HuaweiTypes'
import { PlaybackAttributes } from 'models'
import PlayerActivityApi from './PlayerActivityApi'

/**
 * Default keep alive interval in ms if value not provided by the backend
 */
const DEFAULT_KEEP_ALIVE_INTERVAL_MS = 300000

/**
 * Keep alive caching duration in ms to prevent to do a query in the backend to retrieve this value
 */
const KEEP_ALIVE_CACHING_INTERVAL_DURATION_MS = 806400000

/**
 * Deals with Playback requirements (session creation, tracking etc)
 */
export default class PlayerApi extends Fetch {
    constructor(config, otherApis = {}) {
        super(config, otherApis)
        this.config = config
        this.playerActivityApi = new PlayerActivityApi(config, otherApis)
        this.recordingsApi = otherApis.recordingsApi
    }

    /**
     * Mapping Name of the service -> Backend endpoint
     */
    static PLAY_SERVICE = {
        CHANNEL: 'PlayChannel',
        VOD: 'PlayVOD',
        PVR: 'PlayPVR',
    }

    /**
     * Get the url a associated Live manifest
     *
     * @throws {Error} if not mediaFile matches
     *
     * @param {Object} args
     * @param {String} args.platformChannelId
     * @param {String} args.playbackUrl
     * @param {String} args.drmProvider
     * @return {Observable<SessionData>}
     */
    getLiveStreamUrl({
        platformChannelId,
        playbackId,
        programId,
        drmProvider,
        useTimeshiftedLiveStream = false,
    } = {}) {
        const playerActivity = useTimeshiftedLiveStream
            ? PlayerActivityApi.CONTENT_TYPE.LIVE_TIMESHIFTED
            : PlayerActivityApi.CONTENT_TYPE.LIVE
        const businessType = useTimeshiftedLiveStream
            ? HuaweiTypes.businessType.PLTV
            : HuaweiTypes.businessType.BTV

        this.logger.info(`Getting Manifest URL ${playerActivity} from ${businessType}`)

        this.playerActivityApi.setPlaybackAttributes(playerActivity, {
            channelId: platformChannelId,
            programId,
            playbackId,
        })

        return this.playContent(PlayerApi.PLAY_SERVICE.CHANNEL, {
            channelId: platformChannelId,
            playbackId,
            programId,
            businessType,
            drmProvider,
        })
    }

    /**
     * Get manifest url of a RollingBuffer (catchup/replay)
     *
     * @throws {Error} if not mediaFile matches
     *
     * @param {Object} options
     * @param {Object} options.playbackId Start Over Url
     * @param {Object} options.rollingBufferTime Program Start Time in ms
     * @param {Object} options.rollingBufferEnd Program Start Time in ms
     * @param {Object} options.programId Platform Program identifier
     * @param {Object} [options.channelId] Platform Channel identifier
     */
    getRollingBufferStreamUrl({
        playbackId,
        channelId,
        programId,
        rollingBufferTime,
        rollingBufferEnd,
    }) {
        this.playerActivityApi.setPlaybackAttributes(PlayerActivityApi.CONTENT_TYPE.CATCHUP, {
            channelId,
            programId,
            playbackId,
        })

        return this.playContent(PlayerApi.PLAY_SERVICE.CHANNEL, {
            channelId: channelId,
            playbackId,
            programId,
            businessType: HuaweiTypes.businessType.CUTV,
        })
    }

    /**
     * Get manifest url for a StartOver
     *
     * @param {Object} options
     * @param {Object} options.channelId Platform Channel identifier
     * @param {Object} options.programId Platform Program identifier
     */
    getStartOverStreamUrl({ playbackId, channelId, programId, rollingBufferTime }) {
        this.playerActivityApi.setPlaybackAttributes(PlayerActivityApi.CONTENT_TYPE.STARTOVER, {
            channelId,
            programId,
            playbackId,
        })

        return this.playContent(PlayerApi.PLAY_SERVICE.CHANNEL, {
            channelId: channelId,
            playbackId,
            programId,
            businessType: HuaweiTypes.businessType.IR,
        })
    }

    /**
     * Get the url associated Vod manifest
     * @param {Object} args
     * @param {String} args.playbackContentId can be the id of the trailer or that of the main playback
     * @param {String} args.titleId Id of the title/offer - only used for vubiquity
     * @param {Object} args.screenDimensions Screen width and height
     * @param {Boolean} args.isTrailer
     * @param {Array} args.subscriptionIds subscription ids from Program
     * @param {Object} args.streams
     * @param {String} [args.gpsPosition] For mobile devices, latitude and longitude concatenated (e.g.: '51.507351;-0.127758')
     * @param {Number} [args.initialOffset] Value of the bookmark when the playback is launched
     * @return {Observable<SessionData>}
     */
    getVodStreamUrl({ playbackContentId, titleId, isTrailer, streams }) {
        const stream = (streams || []).find((stream) => stream.uri === playbackContentId)
        const playbackId = (stream && stream.playback_id) || playbackContentId

        this.playerActivityApi.setPlaybackAttributes(PlayerActivityApi.CONTENT_TYPE.VOD, {
            programId: titleId,
            playbackId: playbackId,
        })

        return this.playContent(PlayerApi.PLAY_SERVICE.VOD, {
            programId: titleId,
            playbackId: playbackId,
            isTrailer,
        })
    }

    /**
     * Gets the manifest url for playing a Dvr stream
     *
     * @param {Object} args
     * @param {String|Number} args.recordingId Dvr ID
     * @returns {Observable<SessionData>}
     */
    getDvrStreamUrl({ recordingId }) {
        return this.recordingsApi.getEventRecordingByIdV6(recordingId).pipe(
            mergeMap((dvr) => {
                this.playerActivityApi.setPlaybackAttributes(PlayerActivityApi.CONTENT_TYPE.DVR, {
                    programId: recordingId,
                    playbackId: dvr.playbackId,
                })

                return this.playContent(PlayerApi.PLAY_SERVICE.PVR, {
                    programId: recordingId,
                    playbackId: dvr.playbackId,
                })
            })
        )
    }

    /**
     * Get the url a associated Live manifest
     *
     * @throws {Error} if not mediaFile matches
     *
     * @param {Object} args
     * @param {String} args.platformChannelId
     * @param {String} args.playbackUrl
     * @param {String} args.drmProvider
     * @return {Observable<SessionData>}
     */
    _getLiveTvStream(
        businessType = HuaweiTypes.businessType.BTV,
        { platformChannelId, playbackId, programId, drmProvider } = {}
    ) {
        return this.playContent(PlayerApi.PLAY_SERVICE.CHANNEL, {
            channelId: platformChannelId,
            playbackId,
            programId,
            businessType,
            drmProvider,
        })
    }

    /**
     * Send Play request to the backend
     * @param {String} service Request endpoint
     * @param {Object} body Request body
     * @param {Object} args
     * @returns
     */
    playContent(
        service,
        {
            channelId,
            playbackId,
            programId,
            drmProvider = 'native',
            isTrailer = false,
            businessType = undefined,
        } = {}
    ) {
        switch (service) {
            case PlayerApi.PLAY_SERVICE.CHANNEL:
                this.logger.info(
                    `Playing Live businessType=${businessType}::ChannelId:${channelId}::playbackId=${playbackId}::programId=${programId}...`
                )
                if (!playbackId || !channelId) {
                    this.logger.error(
                        `[PLAY] Missing channelId=${channelId} or playbackId=${playbackId}`
                    )
                    return throwError(
                        new VoltError(VoltError.codes.PLAYER_CONTENT_ID_ERROR, {
                            extraLog: `[PLAY] Missing channelID=${programId} or mediaID=${channelId}`,
                            backend: this.backendName,
                        })
                    )
                } else if (
                    ![HuaweiTypes.businessType.BTV, HuaweiTypes.businessType.PLTV].includes(
                        businessType
                    ) &&
                    !programId
                ) {
                    this.logger.error(`[PLAY] Missing programId=${programId}`)
                    return throwError(
                        new VoltError(VoltError.codes.PLAYER_CONTENT_ID_ERROR, {
                            extraLog: `[PLAY] Missing programId=${programId}`,
                            backend: this.backendName,
                        })
                    )
                }
                break
            default:
                if (!playbackId || !playbackId) {
                    this.logger.error(
                        `[PLAY] Missing programId=${programId} or playbackId=${playbackId}`
                    )
                    return throwError(
                        new VoltError(VoltError.codes.PLAYER_CONTENT_ID_ERROR, {
                            extraLog: `[PLAY] Missing channelID=${programId} or mediaID=${playbackId}`,
                            backend: this.backendName,
                        })
                    )
                }
                break
        }

        let body
        switch (service) {
            case PlayerApi.PLAY_SERVICE.VOD:
                body = {
                    VODID: programId,
                    mediaID: playbackId,
                }
                break
            case PlayerApi.PLAY_SERVICE.PVR:
                body = {
                    planID: programId,
                    fileID: playbackId,
                }
                break
            case PlayerApi.PLAY_SERVICE.CHANNEL:
            default:
                body = {
                    businessType: businessType,
                    channelID: channelId,
                    mediaID: playbackId,
                }
                if (businessType !== HuaweiTypes.businessType.BTV) {
                    body = {
                        ...body,
                        playbillID: programId,
                    }
                }
                break
        }

        const { useHttpsStream = true } = ConfigHelper.getInstance().getConfig('huawei')
        body = {
            ...body,
            isHTTPS: useHttpsStream ? '1' : '0',
            isReturnProduct: 1,
        }

        return forkJoin({
            playbackResponse: this.fetch({
                url: `${DataHelper.getInstance().getData(
                    DataHelper.STORE_KEY.BACKEND_API_URL
                )}/VSP/V3/${service}`,
                method: 'POST',
                body,
                allowReloginWhenSessionEnded: true,
                forceReloginWhenSessionEnded: true,
                log: `[PLAY ${service}][programId=${programId}][playbackId=${playbackId}][HTTPS=${useHttpsStream}]`,
            }),
            heartbeatMs: this._getKeepAliveInterval(),
        }).pipe(
            mergeMap(({ playbackResponse = {}, heartbeatMs = 300000 }) => {
                const { playURL, authorizeResult, bookmark } = playbackResponse.response || {}
                if (!playURL) {
                    this.logger.error('Missing playURL from the backend')
                    return throwError(
                        new VoltError(VoltError.codes.PLAYER_SESSION_ERROR_INVALID_STREAM, {
                            extraLog: 'Missing playURL from the backend',
                            backend: this.backendName,
                        })
                    )
                }
                const { licenseServerUrl, licenseServerDevice, certificateServerUrl } =
                    this.getDrmServerAttributes()

                const isProtectedContent =
                    authorizeResult &&
                    authorizeResult.triggers &&
                    authorizeResult.triggers.length > 0
                if (isProtectedContent) {
                    if (!licenseServerDevice) {
                        this.logger.error(
                            'Cannot playback protected content as Device VUID is empty'
                        )
                        return throwError(
                            new VoltError(VoltError.codes.PLAYER_DEVICE_NOT_FOUND, {
                                extraLog: 'Missing Device VUID from the backend',
                                backend: this.backendName,
                            })
                        )
                    }
                    if (!licenseServerUrl) {
                        this.logger.error(
                            'Cannot playback protected content as Device licenseURL is empty'
                        )
                        return throwError(
                            new VoltError(VoltError.codes.PLAYER_ENTITLEMENT_NO_DRM_FOUND, {
                                extraLog: 'Missing licenseURL from the backend',
                                backend: this.backendName,
                            })
                        )
                    }
                }

                let offset
                if (!bookmark) {
                    offset = bookmark * 1000
                }

                return of(
                    new PlaybackAttributes({
                        url: playURL,
                        assetId: programId,
                        keepAlive: heartbeatMs,
                        sessionId: 'FAKE_SESSION_ID_FOR_REPORTING',
                        isTimeshiftedLive: businessType === HuaweiTypes.businessType.PLTV,
                        isRollingBuffer: businessType === HuaweiTypes.businessType.CUTV,
                        isStartOver: businessType === HuaweiTypes.businessType.IR,
                        isTrailer,
                        offset,
                        drm: {
                            licenseUrl: licenseServerUrl,
                            certificate: certificateServerUrl,
                        },
                        drmType: (() => {
                            const { platform } = this.config
                            switch (platform) {
                                case Constants.platform.ios:
                                case Constants.platform.tvos:
                                case Constants.platform.safari:
                                    return PlaybackAttributes.DRM_TYPE.FAIRPLAY
                                case Constants.platform.smartTvOs:
                                case Constants.platform.edge:
                                case Constants.platform.edgeChromium:
                                    return PlaybackAttributes.DRM_TYPE.PLAYREADY
                                case Constants.platform.android:
                                case Constants.platform.androidTvStb:
                                case Constants.platform.androidTv:
                                case Constants.platform.amazonTv:
                                case Constants.platform.chrome:
                                case Constants.platform.firefox:
                                case Constants.platform.chromecast:
                                default:
                                    return PlaybackAttributes.DRM_TYPE.WIDEVINE
                            }
                        })(),
                        drmProvider,
                    })
                )
            })
        )
    }

    /**
     * @param {Object} options
     * @param {String} options.sessionId
     * @param {Number} options.timeOffset
     * @param {String} options.reason A constant from Constants.session.endReasons.*
     * @return {Observable} Emit and complete once the end session request ends
     */
    notifyEndSessionEvent({
        sessionId,
        timeOffset,
        reason = Constants.session.endReasons.unknown,
    }) {
        return this.playerActivityApi.reportPlaybackActivity(
            PlayerActivityApi.ACTIVITY_TYPE.ACTION_STOP
        )
    }

    notifyStartSessionEvent({ sessionId, timeOffset }) {
        return this.playerActivityApi.reportPlaybackActivity(
            PlayerActivityApi.ACTIVITY_TYPE.ACTION_START
        )
    }

    keepAlive({ sessionId, timeOffset }) {
        return this.playerActivityApi.reportPlaybackHeartbeat()
    }

    /**
     * Get Drm Server attributes
     * @returns {Object} { licenseServerUrl, licenseServerDevice, certificateServerUrl}
     */
    getDrmServerAttributes() {
        return {
            licenseServerUrl: DataHelper.getInstance().getData(
                DataHelper.STORE_KEY.DRM_LICENSE_SERVER_URL
            ),
            licenseServerDevice: DataHelper.getInstance().getData(
                DataHelper.STORE_KEY.DRM_USER_DEVICE_ID
            ),
            certificateServerUrl: DataHelper.getInstance().getData(
                DataHelper.STORE_KEY.FAIRPLAY_CERTIFICATE_SERVER_URL
            ),
        }
    }

    /**
     * This function retrieves from the backend the keep alive interval in ms
     * @returns {Number} ms
     */
    _getKeepAliveInterval() {
        const playbackInterval = this._getKeepAliveIntervalFromCache()
        if (playbackInterval) {
            return of(playbackInterval)
        }

        return this.fetch({
            url: `${DataHelper.getInstance().getData(
                DataHelper.STORE_KEY.BACKEND_API_URL
            )}/VSP/V3/QueryCustomizeConfig`,
            method: 'POST',
            body: {
                queryType: 2,
            },
            log: `[GET Heartbeat interval of program playback]`,
        }).pipe(
            mergeMap(({ response = {} } = {}) => {
                const { playHeartBitInterval = 300 } = response.configurations || {}
                this.logger.info(`Received playHeartBitInterval=${playHeartBitInterval}`)

                const playHeartBitIntervalMs = playHeartBitInterval * 1000
                this._cacheKeepAliveInterval(playHeartBitIntervalMs)
                return of(playHeartBitIntervalMs)
            }),
            catchError(() => {
                this.logger.warn(
                    `Error while retrieving the playHeartBitInterval. Use default value`
                )
                return of(DEFAULT_KEEP_ALIVE_INTERVAL_MS)
            })
        )
    }

    /**
     * Caches keep alive interval in ms
     * @param {Number} intervalMs
     */
    _cacheKeepAliveInterval(intervalMs) {
        this._keepAliveIntervalmS = intervalMs
        this._lastCacheKeepAliveInterval = Date.now()
    }

    /**
     * Gets keep alive  internval from cache to save backend call
     * @returns {Number} intervalMs
     */
    _getKeepAliveIntervalFromCache() {
        if (
            !this._lastCacheKeepAliveInterval ||
            Date.now() > this._lastCacheKeepAliveInterval + KEEP_ALIVE_CACHING_INTERVAL_DURATION_MS
        ) {
            return undefined
        }

        return this._keepAliveIntervalmS
    }

    // -----------------------------------------------------------
    // ----------                STUB                   ----------
    // -----------------------------------------------------------
    notifyPauseSessionEvent({ sessionId, timeOffset }) {
        return of(null)
    }

    notifySeekSessionEvent({ sessionId, timeOffset, previousTimeOffset }) {
        return of(null)
    }
    // -----------------------------------------------------------
    // ----------          END OF STUB                  ----------
    // -----------------------------------------------------------
}
