import config from 'services/proxy/config'
const { vodTypes } = config

export const transformVodTypes = (types) => {
    const _types = Array.isArray(types)
        ? types
        : typeof types === 'string'
        ? types.split(',')
        : [types]

    return (_types || [])
        .map((i) => (isNaN(Number(i)) ? vodTypes[i] : i))
        .filter((x) => x !== undefined)
}

/**
 * @param {String} requiredVersion Ex: "3.1.15"
 * @param {Object} currentProxyRevision "{ major: '3', minor: '1', patch: '9' }
 * @returns {Boolean}
 */
export const hasMinimumVersion = (requiredVersion, currentProxyRevision) => {
    if (!requiredVersion)
        throw new Error(`requiredVersion is needed in the method 'hasMinimumVersion'`)
    if (typeof requiredVersion !== 'object')
        throw new Error(`requiredVersion should be object in the method 'hasMinimumVersion'`)

    // Unfortunately to prevent any problem in real time if proxy version is not provided then we consider that proxy version is OK
    if (undefined === currentProxyRevision || typeof currentProxyRevision !== 'object') return true

    let { major: proxyMajor, minor: proxyMinor, patch: proxyPatch } = currentProxyRevision || {}
    if (typeof proxyMajor === 'string') {
        proxyMajor = parseInt(proxyMajor, 10)
    }
    if (typeof proxyMinor === 'string') {
        proxyMinor = parseInt(proxyMinor, 10)
    }
    if (typeof proxyPatch === 'string') {
        const patchVers = proxyPatch.split(/[a-zA-Z]/)[0]
        proxyPatch = parseInt(patchVers, 10)
    }

    let { major: requiredMajor, minor: requiredMinor, patch: requiredPatch } = requiredVersion || {}
    if (typeof requiredMajor === 'string') {
        requiredMajor = parseInt(requiredMajor, 10)
    }
    if (typeof requiredMinor === 'string') {
        requiredMinor = parseInt(requiredMinor, 10)
    }
    if (typeof proxyPatch === 'string') {
        const patchVers = requiredPatch.split(/[a-zA-Z]/)[0]
        requiredPatch = parseInt(patchVers, 10)
    }

    if (proxyMajor === requiredMajor) {
        if (proxyMinor === requiredMinor) {
            return proxyPatch >= requiredPatch
        } else {
            return proxyMinor > requiredMinor
        }
    } else {
        return proxyMajor > requiredMajor
    }
}
