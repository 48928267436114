const logger = {
    context: () => {},
    setContext: () => {},
    resetContext: () => {},
    createChildInstance: () => logger,
    trace: () => {},
    debug: () => {},
    log: () => {},
    info: () => {},
    warn: () => {},
    error: () => {},
}

export default logger
