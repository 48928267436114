import { IApplication } from '@typings/tile'
import { Tile } from './Tile'

/**
 * Class Application description
 * This class is used mainly for non-mobile products (stb) is simply listing what the application manager package sends back
 * The package's details can be found here: https://npm.ifeelsmart.net/-/web/detail/@ifs/react-native-app-manager#ifsreact-native-app-manager
 * @property {String} packageName package name of the app
 * @property {String} activityName activity name of the app (Can be "")
 * @property {String} versionName version friendly name of the app
 * @property {Number} versionCode technical version id of the app
 * @property {Number} firstInstallTimeMsec initial installation date
 * @property {Number} lastUpdateTimeMsec last reinstall date of app
 * @property {Number} lastLaunchTimeMsec last launch date of app
 * @property {String} appName label to display to the user (activity label ou package label, Can be "")
 * @property {String} category friendly name, already translated, of category of the app (Can be "")
 * @property {String} icon local path of a cached version of icon image to display (can be null)
 * @property {String} banner local path of a cached version of banner (android TV) image to display (can be null)
 * @property {String} apkDir application root directory
 * @property {Number} size memory space used by this app
 * @property {Boolean} enable indicates if app is enable or disable
 * @property {Number} kind 0: package, 1: launcher Activity, 2: leanback activity (see AppManagerAPI.ATTR_KIND_PACKAGE)
 */
export class Application extends Tile {
    packageName: string
    activityName: string
    versionName: string
    versionCode: number
    firstInstallTimeMsec: number
    lastUpdateTimeMsec: number
    lastLaunchTimeMsec: number
    appName: string
    category: string
    icon: string
    banner: string
    apkDir: string
    size: number
    enable: boolean
    kind: number
    isGame: boolean

    constructor(props: IApplication) {
        super(props)

        this.packageName = props.packageName
        this.activityName = props.activityName
        this.versionName = props.versionName
        this.versionCode = props.versionCode
        this.firstInstallTimeMsec = props.firstInstallTimeMsec
        this.lastUpdateTimeMsec = props.lastUpdateTimeMsec
        this.lastLaunchTimeMsec = props.lastLaunchTimeMsec
        this.appName = props.appName
        this.category = props.category
        this.icon = props.icon
        this.banner = props.banner
        this.apkDir = props.apkDir
        this.size = props.size
        this.enable = props.enable
        this.kind = props.kind

        const computePropValue = this._makeComputePropValue(props)
        this.isGame = computePropValue('isGame', false)
    }

    isApp() {
        return true
    }
}
