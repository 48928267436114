import { uniqBy } from 'lodash'
import HuaweiTypes from '../HuaweiTypes'

/**
 * Continue watching factory for Huawei backend bookmarks
 *
 * Note: as Huawei backend doesn't provide information about program's bookmarks updating date but
 * returns all bookmarks by updating date descending sorting we have to store bookmark position in a
 * dedicated property `sortingPosition` which uses then at the store side
 *
 * @param {Array<string>} bookmarks
 * @returns {ContinueWatching}
 */
export const bookmarksFactory = (bookmarks = []) => {
    const continueWatching = bookmarks.reduce(
        (acc, bookmark, index) => {
            const { VOD, playbill, npvrBookmark, bookmarkType } = bookmark || {}
            let bookmarkData = {}
            if (VOD && VOD.bookmark && VOD.bookmark.rangeTime) {
                bookmarkData = {
                    id: VOD.ID || VOD.id,
                    position: HuaweiTypes.parseInt(VOD.bookmark.rangeTime),
                    sortingPosition: index,
                }
            } else if (playbill && playbill.bookmark) {
                bookmarkData = {
                    id: playbill.ID || playbill.id,
                    position: HuaweiTypes.parseInt(playbill.bookmark),
                    sortingPosition: index,
                }
            } else if (npvrBookmark && npvrBookmark.rangeTime) {
                bookmarkData = {
                    id: npvrBookmark.itemID,
                    position: HuaweiTypes.parseInt(npvrBookmark.rangeTime),
                    sortingPosition: index,
                }
            }

            if (bookmarkType === HuaweiTypes.bookmarkType.PROGRAM) {
                acc.catchups.push(bookmarkData)
            }

            if (bookmarkType === HuaweiTypes.bookmarkType.NPVR) {
                acc.dvrs.push(bookmarkData)
            }

            if (
                bookmarkType !== HuaweiTypes.bookmarkType.PROGRAM &&
                bookmarkType !== HuaweiTypes.bookmarkType.NPVR
            ) {
                acc.programs.push(bookmarkData)
            }

            return acc
        },
        { programs: [], catchups: [], dvrs: [] }
    )

    return {
        programs: uniqBy(continueWatching.programs, 'id'),
        catchups: uniqBy(continueWatching.catchups, 'id'),
        dvrs: uniqBy(continueWatching.dvrs, 'id'),
    }
}

/**
 * @typedef {Object} ContinueWatching continue watching object
 * @property {Array<Bookmark>} programs vod bookmarks
 * @property {Array<Bookmark>} catchups catchup bookmarks
 */

/**
 * @typedef {Object} Bookmark common bookmark structure
 * @property {string} id content id
 * @property {number} position content watching position, ms
 */
