import {FirebaseApp, getApps, initializeApp} from 'firebase/app'
import {Analytics, AnalyticsCallOptions, getAnalytics, logEvent, setDefaultEventParameters} from 'firebase/analytics'

export interface FireBaseConfig {
    enabled: boolean
    apiKey: string
    authDomain: string
    projectId: string
    storageBucket: string
    messagingSenderId: string
    appId: string
    measurementId: string
}

export class Firebase {
    private static _instance: Firebase
    public analytics?: Analytics
    public app?: FirebaseApp

    private constructor(firebaseConfig: FireBaseConfig) {
        if (firebaseConfig?.enabled) {
            if (getApps()?.length === 0) {
                this.app = initializeApp({
                    apiKey: firebaseConfig.apiKey,
                    authDomain: firebaseConfig.authDomain,
                    projectId: firebaseConfig.projectId,
                    storageBucket: firebaseConfig.storageBucket,
                    messagingSenderId: firebaseConfig.messagingSenderId,
                    appId: firebaseConfig.appId,
                    measurementId: firebaseConfig.measurementId,
                })
            } else {
                // assume 1 app for now
                this.app = getApps()[0]
            }
            this.analytics = getAnalytics(this.app)
        }
    }

    public static getInstance(firebaseConfig: FireBaseConfig) {
        if (!Firebase._instance) {
            Firebase._instance = new Firebase(firebaseConfig)
            return Firebase._instance
        }
        return Firebase._instance
    }

    public emit(
        eventName: string,
        eventParams?: {[key: string]: any} | undefined,
        options?: AnalyticsCallOptions | undefined
    ) {
        this.analytics && logEvent(this.analytics, eventName, eventParams, options)
    }

    public _setDefaultEventParameters(args: any) {
        return setDefaultEventParameters(args)
    }
}
