import Config from '../config'
import { Youtube } from 'models/Youtube'
import Constants from 'api-constants'
import { ValueOf } from '@typings/generic'

/** Mapping function which converts an IFS String which reflects a category by an associated supported ID used by Youtube Most Popular category */
export const resolveCategory = (category: ValueOf<typeof Constants.emiRequests>) => {
    switch (category) {
        case Constants.emiRequests.GET_YOUTUBE_CATEGORY_FILM_AND_ANIMATION:
            return Config.youtubeCategoryID.YOUTUBE_ID_FILM_AND_ANIMATION
        case Constants.emiRequests.GET_YOUTUBE_CATEGORY_AUTO_AND_VEHICULES:
            return Config.youtubeCategoryID.YOUTUBE_ID_AUTO_AND_VEHICULES
        case Constants.emiRequests.GET_YOUTUBE_CATEGORY_PETS_AND_ANIMALS:
            return Config.youtubeCategoryID.YOUTUBE_ID_PETS_AND_ANIMAL
        case Constants.emiRequests.GET_YOUTUBE_CATEGORY_MUSIC:
            return Config.youtubeCategoryID.YOUTUBE_ID_MUSIC
        case Constants.emiRequests.GET_YOUTUBE_CATEGORY_SPORTS:
            return Config.youtubeCategoryID.YOUTUBE_ID_SPORTS
        case Constants.emiRequests.GET_YOUTUBE_CATEGORY_SHORT_MOVIES:
            return Config.youtubeCategoryID.YOUTUBE_ID_SHORT_MOVIES
        case Constants.emiRequests.GET_YOUTUBE_CATEGORY_TRAVEL_AND_EVENTS:
            return Config.youtubeCategoryID.YOUTUBE_ID_TRAVEL_AND_EVENTS
        case Constants.emiRequests.GET_YOUTUBE_CATEGORY_GAMING:
            return Config.youtubeCategoryID.YOUTUBE_ID_GAMING
        case Constants.emiRequests.GET_YOUTUBE_CATEGORY_VIDEO_BLOGGING:
            return Config.youtubeCategoryID.YOUTUBE_ID_VIDEO_BLOGGING
        case Constants.emiRequests.GET_YOUTUBE_CATEGORY_PEOPLE_AND_BLOGS:
            return Config.youtubeCategoryID.YOUTUBE_ID_PEOPLE_AND_BLOGS
        case Constants.emiRequests.GET_YOUTUBE_CATEGORY_COMEDY:
            return Config.youtubeCategoryID.YOUTUBE_ID_COMEDY
        case Constants.emiRequests.GET_YOUTUBE_CATEGORY_ENTERTAINMENT:
            return Config.youtubeCategoryID.YOUTUBE_ID_ENTERTAINMENT
        case Constants.emiRequests.GET_YOUTUBE_CATEGORY_NEWS_AND_POLITICS:
            return Config.youtubeCategoryID.YOUTUBE_ID_NEWS_AND_POLITICS
        case Constants.emiRequests.GET_YOUTUBE_CATEGORY_HOW_TO_AND_STYLE:
            return Config.youtubeCategoryID.YOUTUBE_ID_HOW_TO_AND_STYLE
        case Constants.emiRequests.GET_YOUTUBE_CATEGORY_EDUCATION:
            return Config.youtubeCategoryID.YOUTUBE_ID_EDUCATION
        case Constants.emiRequests.GET_YOUTUBE_CATEGORY_SCIENCE_AND_TECHNOLOGY:
            return Config.youtubeCategoryID.YOUTUBE_ID_SCIENCE_AND_TECHNOLOGY
        case Constants.emiRequests.GET_YOUTUBE_CATEGORY_NON_PROFITS_AND_ACTIVISM:
            return Config.youtubeCategoryID.YOUTUBE_ID_NON_PROFITS_AND_ACTIVISM
        default:
            return Config.youtubeCategoryID.YOUTUBE_ID_UNKNOWN
    }
}

/**
 * Conversion function which returns the type of the response to parse
 * according to youtube kind field
 *
 * @param {String} youtubeKind coming from youtube response : response.kind
 */
export const resolveContentType = (youtubeKind: string) => {
    switch (youtubeKind) {
        case Config.kind.video:
            return Youtube.CONTENT_TYPE.YOUTUBE_VIDEO
        case Config.kind.playlist:
            return Youtube.CONTENT_TYPE.YOUTUBE_PLAYLIST
        case Config.kind.playlistItem:
            return Youtube.CONTENT_TYPE.YOUTUBE_PLAYLIST_ITEM
        case Config.kind.channel:
            return Youtube.CONTENT_TYPE.YOUTUBE_CHANNEL
        case Config.kind.subscription:
            return Youtube.CONTENT_TYPE.YOUTUBE_SUBSCRIPTION
        case Config.kind.unknown:
        default:
            return Youtube.CONTENT_TYPE.YOUTUBE_VIDEO
    }
}

/**
 * Conversion function which returns the type of the response to parse
 * according to youtube kind field
 *
 * @param {String} contentType coming from youtube response : response.kind
 */
export const resolveYoutubeGenre = (contentType: string) => {
    switch (contentType) {
        case Youtube.CONTENT_TYPE.YOUTUBE_VIDEO:
        case Youtube.CONTENT_TYPE.YOUTUBE_PLAYLIST_ITEM:
            return Config.genres.video
        case Youtube.CONTENT_TYPE.YOUTUBE_PLAYLIST:
            return Config.genres.playlist
        case Youtube.CONTENT_TYPE.YOUTUBE_CHANNEL:
            return Config.genres.channel
        default:
            return Config.genres.video
    }
}

/**
 * Resolve the Deeplink URI to let Android to resolve which package to launch
 * based on the URL of the content (Youtube has different package name
 * for Amazon Fire and Android per example)
 * @param {String} contentType {@link Youtube.CONTENT_TYPE}
 * @param {String} contentID ID of the content
 */
export const resolveDeeplinkURL = (contentType: string, contentID: string) => {
    switch (contentType) {
        case Youtube.CONTENT_TYPE.YOUTUBE_VIDEO:
        case Youtube.CONTENT_TYPE.YOUTUBE_PLAYLIST_ITEM: // A Playlist item is seen as a video
            return Config.youtube_base_url.video + contentID
        case Youtube.CONTENT_TYPE.YOUTUBE_PLAYLIST:
            return Config.youtube_base_url.playlist + contentID
        case Youtube.CONTENT_TYPE.YOUTUBE_CHANNEL:
            return Config.youtube_base_url.video + contentID
        default:
            return Config.youtube_base_url.video
    }
}

/**
 * Resolve the Command (TBC And implemented later)
 * Youtube Android package name differs according to the platform
 * There is no need to provide to package name of the application for launching an android intent.
 * The URI of the content is resolved by Android according to a scheme to resolve in real time the package targeted
 * Except if the application is not installed. URI cannot lead Android to fallback to Youtube Play Store
 * @Todo : So Package name could be mandatory to provide for intent launching but this will be resolved later
 */
export const resolveYoutubeCommand = (
    platform: ValueOf<typeof Constants.platform> = Constants.platform.androidTv
) => {
    switch (platform) {
        case Constants.platform.amazonTv:
            return Config.youtubeCommandName.amazonFire

        case Constants.platform.android:
            return Config.youtubeCommandName.android

        case Constants.platform.androidTvStb:
        case Constants.platform.androidTv:
            return Config.youtubeCommandName.androidtv

        default:
            return Config.youtubeCommandName.web
    }
}
