import { serialize } from 'helpers/index.js'
import Constants from 'api-constants'

/**
 * Extract DRM Configuration from Huawei Login Parameters
 * @param {Object} loginStatus Login Parameters from Huawei Backend
 * @param {String} drmProvider
 * @param {String} drmType could be 'widevine|playready|fairplay'
 * @return {Object} { licenseServerUrl, licenseServerDevice, certificateServerUrl }
 */
export const DrmConfigFactory = (
    loginStatus = {},
    platform = Constants.platform.android,
    drmProvider = 'verimatrix'
) => {
    const { CA, ca, caDeviceInfo = [] } = loginStatus || {}
    let { VUID } = loginStatus || {}
    if (!VUID && caDeviceInfo.length > 0) {
        const caDevice = caDeviceInfo.find((x) => !!x.VUID)
        if (caDevice) {
            VUID = caDevice.VUID
        }
    }

    const CAInfo = CA || ca
    if (!CAInfo) return { licenseServerUrl: undefined, licenseServerDevice: VUID }
    if (!CAInfo[drmProvider]) return { licenseServerUrl: undefined, licenseServerDevice: VUID }

    let licenseServerUrl
    let certificateServerUrl
    const drmObject = CAInfo[drmProvider]

    switch (platform) {
        case Constants.platform.ios:
        case Constants.platform.tvos:
        case Constants.platform.safari:
            {
                const url = drmObject.multiRights_fairplay || drmObject.multiRightsFairplay
                if (typeof url === 'object') {
                    licenseServerUrl = url.LA_URL_HTTPS || url.laURL
                    certificateServerUrl = url.certificateServerUrl
                } else if (typeof url === 'string') {
                    licenseServerUrl = url
                }
            }
            break

        case Constants.platform.edge:
        case Constants.platform.edgeChromium:
        case Constants.platform.playstation:
        case Constants.platform.xbox:
        case Constants.platform.smartTvOs:
            {
                const url = drmObject.multiRights_playready || drmObject.multiRightsPlayready
                if (typeof url === 'object') {
                    licenseServerUrl = url.LA_URL_HTTPS || url.laURL
                } else if (typeof url === 'string') {
                    licenseServerUrl = url
                }
            }
            break

        case Constants.platform.android:
        case Constants.platform.androidTvStb:
        case Constants.platform.androidTv:
        case Constants.platform.amazonTv:
        case Constants.platform.chrome:
        case Constants.platform.firefox:
        case Constants.platform.chromecast:
        default:
            {
                const url = drmObject.multirights_widevine || drmObject.multiRightsWidevine
                if (typeof url === 'object') {
                    licenseServerUrl = url.LA_URL_HTTPS || url.laURL
                } else if (typeof url === 'string') {
                    licenseServerUrl = url
                }
            }
            break
    }

    return {
        licenseServerUrl: licenseServerUrl
            ? `${licenseServerUrl}${serialize({
                  deviceId: VUID,
              })}`
            : undefined,
        licenseServerDevice: VUID,
        certificateServerUrl: certificateServerUrl
            ? `${certificateServerUrl}${serialize({
                  deviceId: VUID,
              })}`
            : undefined,
    }
}
