import { Abstract } from './Abstract'
import { IStream } from '@typings/tile'

/**
 * @class Stream
 * @property {String} id Stream identifier
 * @property {Array<String>} playInfos Array of urls to reach to start playback
 * @property {Array<String>} products Array of product identifier related to this stream
 * @property {Boolean} canWatch
 */

export class Stream extends Abstract {
    id: string
    playInfos: string[]
    products: string[]
    canWatch: boolean

    constructor(props: IStream) {
        super()

        this.id = props.id
        this.playInfos = props.playInfos
        this.products = props.products
        this.canWatch = props.canWatch
    }

    /** Return true if Stream could be watched */
    isWatchable() {
        return !!this.canWatch
    }
}
