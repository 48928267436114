import { Youtube, Tile } from 'models'
import {
    resolveContentType,
    resolveDeeplinkURL,
    resolveYoutubeCommand,
    resolveYoutubeGenre,
} from '../helpers'
import Config from '../config'
import Constants from 'api-constants'
import { ValueOf } from '@typings/generic'
import { IYoutubeResponse } from '../types'
import { IYouTube } from '@typings/tile'

export const youtubeContentFactory = (
    youtubeItem: IYoutubeResponse,
    platform: ValueOf<typeof Constants.platform> = Constants.platform.androidTv
) => {
    const content: IYouTube = {
        channelID: '',
        channelTitle: '',
        publicationDate: '',
        isPrivateVideo: false,
        embeddable: false,
        madeForKids: false,
        command: '',
        param: '',
        id: '',
        title: '',
    }

    content.youtubeType = resolveContentType(youtubeItem.kind)
    content.id = youtubeItem.id

    if (youtubeItem.snippet) {
        const elt = youtubeItem.snippet
        content.title = elt.title
        content.description = // For performance, strings are huge
            elt.description && elt.description.length > Config.descriptionMaxString
                ? elt.description.substr(0, Config.descriptionMaxString) + '...'
                : elt.description
        content.channelID = elt.channelId
        content.channelTitle = elt.channelTitle
        content.publicationDate = elt.publishedAt && elt.publishedAt.split('T')[0] // For display user, not necessary to convert as already ISO
        if (elt.thumbnails) {
            content.thumbnail =
                (elt.thumbnails.medium && elt.thumbnails.medium.url) ||
                (elt.thumbnails.high && elt.thumbnails.high.url)

            content.backdrop =
                (elt.thumbnails.maxres && elt.thumbnails.maxres.url) ||
                (elt.thumbnails.standard && elt.thumbnails.standard.url)
        }

        const contentID = (
            content.youtubeType === Youtube.CONTENT_TYPE.YOUTUBE_PLAYLIST_ITEM
                ? elt.resourceId && elt.resourceId.videoId
                : content.id
        ) as string
        content.param = resolveDeeplinkURL(content.youtubeType, contentID)
    }

    if (youtubeItem.status) {
        content.isPrivateVideo = Boolean(
            youtubeItem.status.privacyStatus && youtubeItem.status.privacyStatus === 'private'
        )
        content.embeddable = youtubeItem.status.embeddable
        content.madeForKids = youtubeItem.status.madeForKids
    }

    return new Youtube({
        ...content,
        format: Tile.FORMAT.LANDSCAPE,
        genre: resolveYoutubeGenre(content.youtubeType),
        command: resolveYoutubeCommand(platform),
    })
}
