import { VirtualContent } from 'models/VirtualContent'

/**
 * Return an instance of virtualContent from given raw data from EMI
 * @param {Object} c
 * @return {VirtualContent}
 */
export const virtualContentFactory = (c) => {
    return new VirtualContent({
        ...c,
        id: 'virtualContent_' + c.id,
        thumbnail: c.media && c.media.thumbnails && c.media.thumbnails[0],
        shortDescription: c.short_description,
        description: c.long_description,
        title: c.name,
        format: 'landscape',
    })
}
