export default (props = {}) => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"{...props}>
<path d="M4 4C4 3.44772 4.44772 3 5 3H9C9.55228 3 10 3.44772 10 4C10 4.55228 9.55228 5 9 5H5C4.44772 5 4 4.55228 4 4Z" fill="#03F182"/>
<path d="M12 4C12 3.44772 12.4477 3 13 3H19C19.5523 3 20 3.44772 20 4C20 4.55228 19.5523 5 19 5H13C12.4477 5 12 4.55228 12 4Z" fill="#03F182"/>
<path d="M18 7C17.4477 7 17 7.44772 17 8C17 8.55228 17.4477 9 18 9H21C21.5523 9 22 8.55228 22 8C22 7.44772 21.5523 7 21 7H18Z" fill="#03F182"/>
<path d="M3 8C3 7.44772 3.44772 7 4 7H14C14.5523 7 15 7.44772 15 8C15 8.55228 14.5523 9 14 9H4C3.44772 9 3 8.55228 3 8Z" fill="#03F182"/>
<path d="M8 11C7.44772 11 7 11.4477 7 12C7 12.5523 7.44772 13 8 13H21C21.5523 13 22 12.5523 22 12C22 11.4477 21.5523 11 21 11H8Z" fill="#03F182"/>
<path d="M2 12C2 11.4477 2.44772 11 3 11H4C4.55228 11 5 11.4477 5 12C5 12.5523 4.55228 13 4 13H3C2.44772 13 2 12.5523 2 12Z" fill="#03F182"/>
<path d="M3 15C2.44772 15 2 15.4477 2 16C2 16.5523 2.44772 17 3 17H19C19.5523 17 20 16.5523 20 16C20 15.4477 19.5523 15 19 15H3Z" fill="#03F182"/>
<path d="M13 20C13 19.4477 13.4477 19 14 19H20C20.5523 19 21 19.4477 21 20C21 20.5523 20.5523 21 20 21H14C13.4477 21 13 20.5523 13 20Z" fill="#03F182"/>
<path d="M5 19C4.44772 19 4 19.4477 4 20C4 20.5523 4.44772 21 5 21H10C10.5523 21 11 20.5523 11 20C11 19.4477 10.5523 19 10 19H5Z" fill="#03F182"/>
</svg>
