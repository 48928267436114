import { useConfig } from "../components"

interface isRestrictedProps {
    tile: any
    userPcRestrictions: any
}
export const isRestricted = (props: isRestrictedProps) => {
    // const userPcRestrictions = {...props.userPcRestrictions, userPcLevel: props.userPcRestrictions.userPcLevel + 1}
    const { getConfig } = useConfig()

    return props.tile?.isRestricted(props.userPcRestrictions) || (getConfig()?.adultRating && props.tile?.ratingId === getConfig()?.adultRating)
}
