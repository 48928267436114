import { IChannelsRevision } from '@typings/platform'
import { Abstract } from './Abstract'
import { CHANNELS_REVISION_STATUS } from 'enums'

export class ChannelsRevision extends Abstract {
    status: `${CHANNELS_REVISION_STATUS}`
    hasChannelListUpdate?: boolean
    hasEPGUpdate?: boolean

    channelListRevision?: number
    shortLongOverlapDay?: number
    channelEpgRevision?: Record<
        string,
        {
            shortTermRevision: number
            longTermRevision: number
        }
    >

    constructor(props: IChannelsRevision) {
        super()

        this.status = props.status
        this.hasChannelListUpdate = !!props?.hasChannelListUpdate
        this.hasEPGUpdate = !!props?.hasEPGUpdate

        this.channelListRevision = props.channelListRevision
        this.shortLongOverlapDay = props.shortLongOverlapDay
        this.channelEpgRevision = props.channelEpgRevision
    }
}
