import { ISidecarSubtitle } from '@typings/tile'
import { Abstract } from './Abstract'

/**
 * Definitions : Sidecar Subtitles
 * @property {String} uri Web link for the subtitles file
 * @property {String} description Description
 * @property {String} language ISO language code (en, es, fr, etc..)
 * @property {String} subtitleType Type of file (srt, ttml, vtt, unknown) @link SidecarSubtitle.SUBTITLE_TYPE
 *
 * === Sidecar Subtitles ===
 * This subtitle type refers to an uploaded external text file
 * that is delivered to the player as a separate file from the video manifest.
 *
 * === Embedded Subtitles ===
 * Unlike sidecar subtitles that are delivered separately from the video
 * manifest, embedded subtitles are stitched into the video's HLS manifest.
 * This type of subtitle is more widely supported across endpoint players.
 */

export class SidecarSubtitle extends Abstract {
    uri: string
    description: string
    language: string
    subtitleType: string

    constructor(props: ISidecarSubtitle) {
        super()
        const computePropValue = this._makeComputePropValue(props)

        this.uri = props.uri
        this.description = props.description
        this.language = computePropValue('language', '')
        this.subtitleType = computePropValue('subtitleType', SidecarSubtitle.SUBTITLE_TYPE.UNKNOWN)
    }

    static SUBTITLE_TYPE = {
        SRT: 'srt',
        TTML: 'ttml',
        VTT: 'vtt',
        UNKNOWN: 'unknown',
    }
}
