import { of } from 'rxjs'
import Fetch from '../fetch'

/**
 * Stub class to use while implementation for some clients is not done yet
 */
export default class RecoApi extends Fetch {
    /**
     * @returns {Observable<Array>}
     */
    getAllReco() {
        return of([])
    }
}
