import { uniq } from 'lodash'

import { Vod } from './Vod'
import { ISeason } from '@typings/tile'
import Constants from 'api-constants'

/**
 * @class Season extends Tile & Vod model
 * @property {String|Number} serieId Proxy identifier of related serie
 * @property {String|Number} seriePlatformId Platform identifier of related serie
 * @property {Number} childrenCount Number of episodes
 * @property {Number} number Season number
 * @property {Array<String>} episodeIds Season episdoes ids
 * @property {Boolean} pending pending state
 */

export class Season extends Vod {
    serieId: string | number
    seriePlatformId: string | number
    childrenCount: number
    number: number
    episodeIds: string[]

    constructor(props: ISeason) {
        super(props)
        this.serieId = props.serieId
        this.seriePlatformId = props.seriePlatformId
        this.childrenCount = props.childrenCount
        this.number = props.number
        this.vodType = Constants.programType.season

        const computePropValue = this._makeComputePropValue(props)

        this.episodeIds = computePropValue('episodeIds', [])

        // true when episodes are being fetched
        this.pending = computePropValue('pending', false)
    }

    isSeason() {
        return true
    }

    getPropsToMerge() {
        return ['episodeIds', ...super.getPropsToMerge()]
    }

    /** Override parent method to apply an `uniq` on merged `episodeIds` */
    _mergePropsForUpdate(currentProps: this, newProps: this, bypassMerge: string[] = []) {
        // Keep original merge behaviour
        const mergedProps = super._mergePropsForUpdate(currentProps, newProps, bypassMerge)

        // Apply lodash `uniq` function on episodeIds properties
        mergedProps.episodeIds = mergedProps.episodeIds && uniq(mergedProps.episodeIds)

        return mergedProps
    }
}
