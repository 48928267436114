import { isWeb } from '@volt-libs/foundation'

const conf = {
    env: 'staging',
    supportedLanguages: ['en', 'ar'],
    urls: {
        authUrl: 'https://edspoc.ott.ooredoo.qa:33428',
        apiUrl: 'http://vsc2poc.ott.ooredoo.qa:33200',
        apigeeUrl: 'https://uat-02.apigw.test.apigee.ooredoo.qa',
        signUpUrl: 'https://www.ooredoo.qa/web/en/otv-guide/',
        proxyUrl: 'https://ooredoo-api-stg.ifeelsmart.net/maculosa/v3.1',
        emi: {
            baseUrl: 'https://ooredoo-api-stg.ifeelsmart.net/emi',
            homePath: `3.0/{population}/${isWeb ? 1 : 3}`,
            defaultPopulation: 'uat',
        },
        reportVulnerabilityUrl: 'https://www.ooredoo.qa/web/en/otv-guide/',
        helpAndFaqUrl: 'https://www.ooredoo.qa/web/en/otv-guide/',
        termsAndConditions: 'https://www.ooredoo.qa/web/en/general-terms-and-conditions-for-consumer-services/',
        mobileAppTermsAndConditions: 'https://www.ooredoo.qa/web/en/otv-guide/',
        mobilePrivacyPolicy: "https://www.ooredoo.qa/web/en/otv-guide/",
        store: {
            applePhone: 'https://apps.apple.com/sg/app/starhub-tv/id586220439',
            appleTablet: 'http://starhub.com/tvplus-pro-help',
            googlePhone: 'https://play.google.com/store/apps/details?id=com.starhub.itv&hl=en_GB&gl=US',
            googleTablet: 'http://starhub.com/tvplus-pro-help',
            chromeBrowser: 'https://www.google.com/chrome/',
        },
        socialMedia: {
            facebook: "https://www.facebook.com/",
            messanger: "https://www.messenger.com/",
            instagram: "https://instagram.com/",
            youtube: "https://www.youtube.com/",
            twitter: "https://twitter.com/",
        }
    },
    clientId: 'KIbxyh8sTA',
    clientSecret: 'Q106hE3iwWqPIGFNbn3rVlHPGAQl7z7SiUN3ebtsylYax9JnrrI6B6Shz3OuLEYl',
    allowGuest: false,
    proxyVersion: 3,
    voltPlayer: {
        drmConfig: {},
        mediaEngine: 'shaka',
    },
    ooredoo: {
        clientId: 'KIbxyh8sTA',
        userAgent: 'OoredooApigw',
        clientKey: 'pJNAdQ0nWVoHeAdnlTjG9jS8Trg3yQjbJ7iImnaKJaqUvx87',
        clientSecret: 'Q106hE3iwWqPIGFNbn3rVlHPGAQl7z7SiUN3ebtsylYax9JnrrI6B6Shz3OuLEYl',
        lmsEnvironment: 'OQ_NG_RES',
        phoneNumberVisibleDigits: 4, // to get obfuscated phone numbers
        // For debug purposes (TODO: to be removed later)
        // To be removed for official delivery
        // forceIptvAccountNumber: '2110060837641',
    },
    huawei: {
        // Parameter to use V2 API for login as it enables feature like Silent Login for STB
        useLoginApiV2: true,
        // useVspHttpsUrl: This parameter allows to use the VSP HTTPs URL returned by the load balancer
        useVspHttpsUrl: true,
        // This parameter is to force manual management of the cookies for Authorization. Can be used only for react native applications. Brower are not compatible
        forceSessionCookieHeader: false,
        // This parameter if for forcing the fetch of the products from IFS proxy is equals to true. Otherwise Huawei backend is directly used
        useProxyProducts: false,
        // When starting a playback session, indicate if the manifest URL should be HTTP or HTTPs
        useHttpsStream: true,
        // This paramater is used to force an error when the 'authToken' from the login is not receiced. authToken is used for auto relogin after reboot
        loginErrorWhenAuthTokenIsMissing: false,
        // Defined the currency of TVOD Products
        TvodCurrency: 'QAR',
        // Defined the currency of the Packs
        SubscriptionCurrency: 'Credit',
        // This parameter reduces by a factor the refresh heartbeat for increasing the refreshing rate
        heartbeatNextIntervalReducedBy: 2,
        // Nb of attempts to recover a session using login from token method before stopping
        maxRecoverFromRefreshHeartbeatFailure: 3,
        // This paramter is a debug mode for forcing the mac address
        forceMacAddressPattern: false,
        // This paramter is a debug mode for allowing login attempts if serial number is missing
        allowLoginRequestIfSerialNumberMissing: false,
        // Purchase using user password rather than purchase pin
        purchaseUsingUserPassword: true,
        // Timeout for /EDS/V3/LoginRoute request related to operator network connection checking, ms
        operatorConnectionCheckTimeout: 10000,
    },
    firebaseConfig: {
        enabled: true,
        apiKey: "AIzaSyCd8IRNiIk9RpOnQpDy7l1mRpz2Dd7eCNg",
        authDomain: "ooredoo-tv-fb0fb.firebaseapp.com",
        projectId: "ooredoo-tv-fb0fb",
        storageBucket: "ooredoo-tv-fb0fb.appspot.com",
        messagingSenderId: "489446849034",
        appId: "1:489446849034:web:d9f2579436e282e857fdf3",
        measurementId: "G-130K3FQXFX"
    },
    agama: {
        // Agama analytics status
        enable: true,
        //Console URI
        _configuration:"emp_service=https://aggregation.castling.uk/report;report_interval=240;id_report_interval=4800;operator_id=Ooredoo_0x5E01_VLTN;",
         // player name
        playerName: 'Shaka Player',
        playerVersion: '4.3.8-nmp-2',
        caSystem: 'verimatrix',
        cdn: 'Huawei',
        //DEBUG for development and INFO for Prod.
        verbose:'Agama.LogLevel.DEBUG'
    },
}

export default conf
