const config = {
    title: 'Ooredoo TV+',
    client: 'ooredoo',
    clientName: 'Ooredoo 2024',
    defaultLocale: 'en',
    supportedLanguages: ['en', 'ar'],
    rtlLanguages: ['he', 'ar'],
    authenticationType: 'iptv',
    subscriptionUrl: '',
    isNewRelicDisabled: true,
    searchLeft: true,
    epg: {
        numOfDaysFuture: 6,
        numOfDaysPast: 2,
    },
    emiModules: {
        home: {
            isEMIUniverse: true,
            name: 'home',
        },
        vod: {
            isEMIUniverse: true,
            name: 'vod',
        },
        sport: {
            isEMIUniverse: true,
            name: 'sport',
        },
        // kids: {
        //     isEMIUniverse: true,
        //     name: 'kids',
        // },
        mytv: {
            isEMIUniverse: true,
            name: 'mytv',
        },
        theme: {
            isEMIUniverse: false,
            name: 'theme',
        }
    },
    bootRequests: {
        lastChannelsLcn: {
            requestAtBoot: false,
            waitAtBoot: false,
        },
        recordings: {
            requestAtBoot: true,
            waitAtBoot: false,
        },
        recordingsFavorites: {
            requestAtBoot: true,
            waitAtBoot: false,
        },
        reminders: {
            requestAtBoot: true,
            waitAtBoot: false,
        },
        continueWatching: {
            requestAtBoot: true,
            waitAtBoot: true,
        },
        channelApps: {
            requestAtBoot: false,
            waitAtBoot: false,
        },
        products: {
            requestAtBoot: true,
            waitAtBoot: true,
        },
        liveNow: {
            requestAtBoot: false,
            waitAtBoot: false,
        },
        vodNodes: {
            requestAtBoot: false,
            waitAtBoot: false,
        },
        recommendation: {
            requestAtBoot: false,
            waitAtBoot: false,
        },
        vodFavorites: {
            requestAtBoot: true,
            waitAtBoot: false,
        },
        channels: {
            requestAtBoot: true,
            waitAtBoot: true,
        },
    },
    nagra: {
        tenantId: 'nagra',
        metadataProvider: 'GLOBAL',
        rollingBufferDuration: 24,
        fetchOnlySubscribedProducts: true,
    },

    forgotParentalPinType: "message",
    audioLanguages: ['en', 'ar'],
    subtitleLanguages: ['none', 'en', 'ar'],
    defaultAudioLocale: 'en',
    defaultSubtitleLocale: 'en',
    forgotParentalPinMessage: "If you forgot you PIN. Please SMS 'MPIN' followed by the 8 digits of your landline number '115'",

    searchContentTypes: ['VOD', 'EPG_LIVE', 'EPG_PAST', 'EPG_FUTURE', 'CHANNEL'],
    localSearchContentTypes: ['VOD', 'EPG_LIVE', 'EPG_PAST', 'EPG_FUTURE', 'CHANNEL'],

    voltPlayer: {
        drmConfig: {
        },
        mediaEngine: 'shaka',
    },
    hasPortalScreen: true,
    emiModulesToUniverseRoot: {
        root: 'root',
        home: 'root-coach',
        vod: 'root-vod',
        sport: 'root-sport',
        // kids: 'root-kids',
        mytv: 'root-mytv'
    },

    universeRootToEMIModule: {
        root: 'root',
        'root-coach': 'home',
        'root-vod': 'vod',
        'root-sport': 'sport',
        // 'root-kids': 'kids',
        'root-mytv': 'mytv'
    },
    enableGenresFilterNode: true,
    enableMultiLocalesMetadatas: true,
    contentNodePageSize: 3,
    emiScreenAliases: {
        "home": "root-coach",
        "vod":"root-vod",
        "live-tv": "root-epg",
        "sports": "root-sport",
        "subscriptions": "root-packages",
        'mytv': 'root-mytv',
    },
    screenPathAliases: {
        "root-coach": "home",
        "root-vod": "vod",
        "root-epg": "live-tv",
        "root-sport": "sports",
        "root-packages": "subscriptions",
        'root-mytv': 'mytv',
    },
    // show apps added in EMI requests
    showApps: true,

    // EMI provides layout/format info for items/rows
    supportedLayoutsFromEMi: ['vod', 'predefined', 'app'],

    // MAC-638 - default node ordering in descending order pending feature from EMI
    proxy: {
        defaultNodeOrdering: 'node_pos_desc',
        defaultCategoryOrdering: 'node_pos_desc', // Hack as feature not support by EMI and starhub very demanding
        enableFetchProductTitles: false,
    },
    customErrorMsg: {
        MAX_DEVICES_REACHED: {
            title: 'PBL-212-Max-Device-Reached',
            description:
                'Maximum permissible device count for your account reached. Please, manage device count from your other device ( like STB)',
            subDescription: '',
        },
    },
    epgGrid: {
        flatEpg: false, // flat or classic grid
        epgFlatDefaultSlotDurationMin: 30, // half hour
        epgVisibleSlots: 5, // N slots visible in EPG
        epgVisibleLineCount: 7, // number of visible line in flat EPG
        epgMaxConcurrentRequests: 3,
        epgPrefetchBatchSize: 20, // The number of prefetched channels in the EPG (current day only)
        epgHorizontalDurationHours: 4,
        epgPlaceHolderDuration: 2, // in hours
    },
    epgChannelsBatchSize: 15,
    MAX_DEVICES_REACHED: {
        title: 'PBL-212-Max-Device-Reached',
        description:
            'Maximum permissible device count for your account reached. Please, manage device count from your other device ( like STB)',
        subDescription: '',
    },
    // Dynamic Content FIREBASE_COMMON_CONFIG
    analytics: {
        // This configuration allowyou to disable Analytics
        disabled: false,
        // This value force to get user consent beforce enabling Analytics.
        // Not required for all country (Example: Required in US/Europe, not really in Asia)
        // UI to be implemented for getting user consent
        userConsentRequired: false,
        // For device sampling, percentage of devices allowed for analytics
        percentageEnabled: 100,
        // Force User Personnal data collection even if user did not provide his consent
        forcePersonalUserDataCollection: true,
        // Session of analytics. By default 30 minutes before closing a session
        // sessionDurationMs: 30 * 60 * 1000,
        // Define the list of supported event (Native and Custom)
        supportedEvents: [
            // -- Events enabled by default --
            'screen',
            'login',
            'sign_up',
            'app_open',
            'starter_kit',
            'select_content',
            'purchase',
            'search',
            // --- Custom Events ---
            'bootstrap_data',
            'logout',
            'unsubscribe',
            'activate_subscription',
            'playback_start',
            'playback_pause',
            'playback_resume',
            'playback_stop',
            'playback_error',
            'emi',
            // Additionnal errors from the UI
            'app_error',
            'favorites_add_content',
            'favorites_remove_content',
            'add_reminder',
            'remove_reminder',
            'setting_update_parental_control',
            'setting_update_pin',
            'setting_reset_pin',
            'setting_update_password',
        ],
    },
    theme: {
        focusColor: "#ebebebff",
        focusTextColor: "#171a30ff",
        textColor: "#ebebebff",
        backgroundColor: "#171a30ff",
        secondaryBackgroundColor: "#222439ff",
        backgroundBlurColor: "#ebebeb0d",
        interactiveBackgroundColor: "#ebebeb1a",
        primaryBackgroundColor: '#171a30ff',
        fontFamily: 'Rubik'
    },
    navigationMenuIgnoreList: ['root-recordings', 'root-packages', 'root-offer', 'root-replay'],
    pcLevels: {
        QT_G: 1,
        QT_PG: 7,
        QT_PG13: 13,
        QT_PG15: 15,
        QT_R: 17,
        QT_NR: 49,
        UNRATED: 49,
    },

    // Special rating level for which censorship pin is needed rather than adult pin
    censoredLevel: 20,

    agama: {
        // Agama analytics status
        enable: true,
        //Console URI
        _configuration:"emp_service=https://aggregation.castling.uk/report;report_interval=240;id_report_interval=4800;operator_id=Ooredoo_0x5E01_VLTN;",
         // player name
        playerName: 'Shaka Player',
        playerVersion: '4.3.8-nmp-2',
        caSystem: 'verimatrix',
        cdn: 'Huawei',
        //DEBUG for development and INFO for deployment.
        verbose:'Agama.LogLevel.DEBUG'
    },

    // userPcLevels and content pcLevels (see above) are not the same.
    // You'll find below a map associating each userPcLevel with a label.
    // Not in volt-trad as it's very client specific
    userPcLevels: [
        {
            backendRatingId: '5',
            title: { en: 'All', ar: 'All' },
            description: {
                en:
                    'All Content Available.',
                ar:
                    'كل المحتوى متاح.'
            },
            level: 50,
        },
        {
            backendRatingId: '0',
            title: { en: 'G', ar: 'G' },
            description: {
                en:
                    'Nothing that would offend parents for viewing by children.',
                ar:
                    'لا شيء من شأنه أن يسيء إلى الآباء لمشاهدتها من قبل الأطفال.'
            },
            level: 1,
        },
        {
            backendRatingId: '1',
            title: { en: 'PG', ar: 'PG'  },
            description: {
                en: 'Parents urged to give "parental guidance"',
                ar: 'حث الآباء على تقديم "إرشادات الوالدين"'
            },
            level: 7,
        },
        {
            backendRatingId: '2',
            title: { en: 'PG-13', ar: 'PG-13'  },
            description: {
                en: 'Some material may be inappropriate for children under 13',
                ar: 'قد تكون بعض المواد غير مناسبة للأطفال دون سن 13 عاما'
            },
            level: 13,
        },
        {
            backendRatingId: '3',
            title: { en: 'PG-15', ar: 'PG-15'  },
            description: {
                en:
                    'Some material may be inappropriate for children under 15',
                ar:
                    'قد تكون بعض المواد غير مناسبة للأطفال دون سن 15 عاما'
            },
            level: 15,
        },
        {
            backendRatingId: '4',
            title: { en: 'R', ar: 'R'  },
            description: {
                en:
                    'Under 17 requires accompanying parent or adult guardian',
                ar:
                    'يتطلب الأطفال دون سن 17 عاما مرافقة أحد الوالدين أو وصي بالغ'
            },
            level: 17,
        }
    ],
    settings: [{
        title: { en: "Settings", ar: 'اعدادات' },
        haveChildren: true,
        disabled: false,
        id: 'mainSettings',
        childrens: [
            {
                title: { en: "Ooredoo TV Settings", ar: 'إعدادات Ooredoo TV' },
                id: "tvAppSettings",
                haveChildren: true,
                disabled: false,
                childrens: [
                    {
                        title: { en: "Overscan", ar: 'المسح الزائد' },
                        disabled: true,
                        id: "overscan",
                        haveChildren: false
                    },
                    {
                        title: { en: "Audio & subtitles", ar: 'الصوت والترجمات' },
                        disabled: true,
                        id: "audiosubtitle",
                        haveChildren: false
                    },
                    {
                        title: { en: "Menu language", ar: 'لغة القائمة' },
                        disabled: false,
                        id: "menulanguage",
                        haveChildren: false
                    },
                    {
                        title: { en: "Channel filtering", ar: 'تصفية القناة' },
                        disabled: true,
                        id: "channelFiltering",
                        haveChildren: false
                    },
                    {
                        title: { en: "Parental control", ar: 'الرقابة الأبوية' },
                        disabled: false,
                        id: "parentalControl",
                        haveChildren: true,
                        childrens: [
                            {
                                title: { en: "Parental level", ar: 'مستوى الوالدين' },
                                disabled: false,
                                id: "parentalLevel"
                            },
                            {
                                title: { en: "Parental code", ar: 'رمز الوالدين' },
                                disabled: false,
                                id: "parentalCode"
                            },
                            {
                                title: { en: "Reset parental code", ar: 'إعادة تعيين رمز الوالدين' },
                                disabled: false,
                                id: "resetParentalCode"
                            },
                            {
                                title: { en: "Restrict by channels", ar: 'التقييد حسب القنوات' },
                                disabled: false,
                                id: "restrictedByChannel"
                            }
                        ]
                    },
                    {
                        title: { en: "Power management", ar: 'إدارة الطاقة' },
                        disabled: true,
                        id: "powerManagement",
                        haveChildren: false
                    },
                    {
                        title: { en: "Devices management", ar: 'إدارة الأجهزة' },
                        disabled: false,
                        id: "deviceManagement",
                        haveChildren: false
                    },
                    {
                        title: { en: "About", ar: 'عن' },
                        disabled: false,
                        id: "about",
                        haveChildren: false
                    },
                    {
                        title: { en: "Term & conditions", ar: 'الشروط والأحكام' },
                        disabled: false,
                        id: "termsconditions",
                        haveChildren: false
                    },
                    {
                        title: { en: "Help & FAQs", ar: 'المساعدة والأسئلة الشائعة' },
                        disabled: false,
                        id: "helpfaqs",
                        haveChildren: false
                    },
                    {
                        title: { en: "Enviroment", ar: "Enviroment" },
                        disabled: false,
                        id: "enviroment",
                        haveChildren: true,
                        childrens: [
                            {
                                title: { en: "Development", ar: "Development" },
                                disabled: false,
                                id: "development"
                            },
                            {
                                title: { en: "QA", ar: "QA" },
                                disabled: false,
                                id: "qa"
                            },
                            {
                                title: { en: "Staging", ar: "Staging" },
                                disabled: false,
                                id: "staging"
                            },
                            {
                                title: { en: "PreProduction", ar: "PreProduction" },
                                disabled: false,
                                id: "preproduction"
                            },
                            {
                                title: { en: "Production", ar: "Production" },
                                disabled: false,
                                id: "production"
                            }
                        ]
                    },
                    {
                        title: { en: "Log out", ar: 'تسجيل الخروج' },
                        disabled: false,
                        id: "logOut",
                        haveChildren: false
                    },
                ]
            },
            {
                title: { en: "System Settings", ar: 'إعدادات النظام' },
                id: "systemSettings",
                haveChildren: true,
                disabled: true,
                childrens: [
                    //Add as give for TV App Settings
                ]
            },
            {
                title: { en: "My account details", ar: 'تفاصيل حسابي' },
                id: "myAccountDetails",
                haveChildren: true,
                disabled: true,
                childrens: [
                    //Add as give for TV App Settings
                ]
            }
        ]
    }
    ],
    disable_R21_option:false,
    posterRatio: '2/3'
}

export default config
