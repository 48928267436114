import { createEffect, createSignal, on, Show } from 'solid-js'
import { ThemeContext } from './ThemeContext'
import { EmiTheme } from './emiTheme'
import { useSelector } from '../../store'
import tinycolor from "tinycolor2";
import { useConfig } from '../config/useConfig'

export interface ThemeProps {
    children: any
}

export const ThemeProvider = (props: ThemeProps) => {
    const { getConfig } = useConfig()
    const [theme, setTheme] = createSignal<EmiTheme>({})
    const emiTheme = useSelector((s: any) => s.emi.theme)

    createEffect(() => {
        const interactiveBackgroundColor = emiTheme().colors?.interactiveBackgroundColor
        const backgroundColor = emiTheme().colors?.backgroundColor
        const backgroundBlurColor = emiTheme().colors?.backgroundBlurColor

        document.documentElement.style.setProperty('--font-family', getConfig()?.theme.fontFamily);
        
        if (emiTheme().colors?.focusColor) {
            document.documentElement.style.setProperty('--focus-background-color', emiTheme().colors?.focusColor);
            document.documentElement.style.setProperty('--focus-border-color', emiTheme().colors?.focusColor);
            document.documentElement.style.setProperty('--focus-complementary-color', tinycolor(emiTheme().colors?.focusColor ?? getConfig()?.theme.focusColor).complement().toHexString());
        }
        if (emiTheme().colors?.textColor) {
            document.documentElement.style.setProperty('--active-text-color', emiTheme().colors?.textColor);
        }
        if (interactiveBackgroundColor) {
            const colorOpacity = tinycolor(interactiveBackgroundColor).getAlpha()
            document.documentElement.style.setProperty('--interactive-background-color', interactiveBackgroundColor);
            document.documentElement.style.setProperty('--interactive-background-color-lighten', tinycolor(interactiveBackgroundColor).setAlpha(colorOpacity / 2));
        }
        if(backgroundBlurColor) {
            document.documentElement.style.setProperty('--background-blur-Color', emiTheme().colors?.backgroundBlurColor);
        }
        document.documentElement.style.setProperty('--focus-text-color', emiTheme().colors?.focusTextColor ?? getConfig()?.theme.focusTextColor);
        document.documentElement.style.setProperty('--secondary-background-color', tinycolor(emiTheme().colors?.secondaryBackgroundColor ?? getConfig()?.theme.secondaryBackgroundColor).toRgbString());
        document.documentElement.style.setProperty('--secondary-background-color-RGBA', tinycolor(emiTheme().colors?.secondaryBackgroundColor ?? getConfig()?.theme.secondaryBackgroundColor).setAlpha(0));
        
        document.documentElement.style.setProperty('--primary-background', emiTheme()?.colors?.backgroundColor ?? getConfig()?.theme.backgroundColor);
        if(backgroundColor){
            document.documentElement.style.setProperty('--primary-background-color', backgroundColor)
        }


        if (emiTheme()?.graphicAssets) {
            if (emiTheme().graphicAssets?.backgroundImage) {
                document.documentElement.style.setProperty('--primary-background', "url('" + emiTheme().graphicAssets?.backgroundImage + "')");
            }
            document.documentElement.style.setProperty('--app-logo', "url('" + emiTheme()?.graphicAssets?.logo + "')");
        }

        document.documentElement.style.setProperty('--poster-image-aspect-ratio', getConfig()?.posterRatio ?? 2/3)
    })

    createEffect(
        on(() => emiTheme(), () => {
            setTheme(emiTheme())
        })
    )

    return (
        <ThemeContext.Provider value={{ getTheme: () => theme() }}>
            <Show when={theme()}>{props.children}</Show>
        </ThemeContext.Provider>
    )
}

export interface ThemeProviderBehavior {
    getTheme: () => EmiTheme
}
