export const getAvailableRecordings = (data: any, programs: any, timestamp: any) => {
    return data.events.map((e: any) => {
            return programs?.[e]
        }).filter((e: any) => e.status === "completed" || e.endTime < timestamp ).sort((a: any, b: any) => b.startTime - a.startTime)
}

export const getAllRecordings = (data: any, programs: any, timestamp: any) => {
    return data.events.map((e: any) => {
            return programs?.[e]
        }).filter((e: any) => e.status !== "scheduled" ).sort((a: any, b: any) => b.startTime - a.startTime)
}

export const getContinueWatchingRecording = (data: any, programs: any) => {
    return data.events.map((e: any) => {
            return programs?.[e]
        }).filter((e: any) => e?.bookmark)
}

export const getFavoritesRecordings = (data: any, programs: any) => {
    return data.favorites.map((e: any) => {
            if(e !== null) return programs?.[e]
        }).filter(Boolean)
}

export const getIsRecordingFavorites = (data: any, favorites: any) => {
    return favorites.favorites.includes(data)
}

export const getSchedules = (data: any, programs: any, timestamp: any) => {
    return data.events.map((e: any) => {
            return programs?.[e]
        })
        .filter((e: any) => {
            return (e.status === 'scheduled' || e.status === 'ongoing') && e.endTime > timestamp
        })
}

export const getDvrStorage = (profile: any) => {
    if (profile.dvrEnabled) {
        return {
            total: profile?.dvrQuota,
            used: profile?.dvrQuota - profile?.dvrQuotaRemaining,
            dvrQuotaProtected: profile.dvrQuotaProtected,
            unit: profile.dvrQuotaUnit,
        }
    } else {
        return {
            total: 0,
            used: 0,
            dvrQuotaProtected: 0,
            unit: 'hour',
        }
    }
}
