import { Offer, IapProduct, PaymentMethod } from 'models'

export const offerFactory = (data, productPrice, productCurrency) => {
    let {
        platform_id,
        title,
        description,
        type: offerType,
        status = 'Active',
        periods = [],
        payment_method = [],
        iap = [],
    } = data || {}
    const { type = '', quantity, cycle, prices = [] } = periods && periods[0]

    // TODO Will be done by the Proxy and THEN needs to be removed
    if (payment_method.includes('Both')) {
        payment_method = [
            ...payment_method,
            PaymentMethod.PAYMENT_METHOD_TYPE.PAY_ON_BILL,
            PaymentMethod.PAYMENT_METHOD_TYPE.CREDIT_CARD,
        ]
    }
    // End of TODO

    // --- TO BE OPTIMIZE LATER WITH PRODUCTION DATAS ---
    // Proxy provide several prices but Billing Plan model contains only one (For each language).
    // Get only the first price in the array for the moment
    // And if there is no price in the billing Plan, take price from the product
    const pricing =
        (Array.isArray(prices) &&
            prices.length > 0 &&
            prices[0].amount &&
            parseFloat(prices[0].amount)) ||
        productPrice
    const currency =
        (Array.isArray(prices) && prices.length > 0 && prices[0].currency) || productCurrency

    let periodUnit
    switch (type.toLowerCase()) {
        case 'year':
            periodUnit = Offer.UNIT.YEAR
            break
        case 'month':
            periodUnit = Offer.UNIT.MONTH
            break
        case 'day':
            periodUnit = Offer.UNIT.DAY
            break
        default:
            periodUnit = type.toLowerCase()
            break
    }

    const iapProducts = iap.reduce((acc, iapOffer) => {
        const { platform_id, store, type } = iapOffer || {}
        acc.push(
            new IapProduct({
                id: platform_id,
                appStore: store,
                type,
            })
        )
        return acc
    }, [])

    return new Offer({
        offerId: platform_id,
        title: title || description,
        description,
        offerType,
        isActive: status === 'Active',
        cycles: cycle || 0,
        period: quantity,
        periodUnit,
        price: pricing,
        originalPrice: pricing,
        currency: currency,
        allowedPaymentMethods: payment_method,
        iapProducts,
    })
}
