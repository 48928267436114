import { Vod } from './Vod'
import { IEpisode } from '@typings/tile'
import Constants from 'api-constants'

/**
 * Episode format of Tile regarding part of season
 * @property {Number} format Tile format
 * @property {Number} number Episode number
 * @property {String|Number} seasonNumber Season number
 * @property {String|Number} seriesTitle Series title
 * @property {String|Number} seasonId Proxy identifier of related episode season
 * @property {String|Number} seasonPlatformId Platform identifier of related episode season
 * @property {String|Number} seriesPlatformId Platform ID of the series the episode belongs to
 * @property {String} seriesThumbnail Thumbnail of the series the episode belongs to
 * @property {String} seriesBackdrop Backdrop of the series the episode belongs to
 */

export class Episode extends Vod {
    number: number
    seasonNumber: number
    seriesTitle: string
    seasonId: string | number
    seasonPlatformId: string | number
    seriesPlatformId: string | number
    seriesThumbnail: string
    seriesBackdrop: string

    constructor(props: IEpisode) {
        super(props)
        this.number = props.number
        this.seasonNumber = props.seasonNumber
        this.seriesTitle = props.seriesTitle
        this.seasonId = props.seasonId
        this.seasonPlatformId = props.seasonPlatformId
        this.seriesPlatformId = props.seriesPlatformId
        this.seriesThumbnail = props.seriesThumbnail
        this.seriesBackdrop = props.seriesBackdrop
        this.vodType = Constants.programType.episode

        this.thumbnail =
            props.thumbnail || props.backdrop || this.seriesBackdrop || this.seriesThumbnail

        this.format = 'landscape'
    }

    isEpisode() {
        return true
    }
}
