import Constants from 'api-constants'

export default {
    /**
     * Subscriber identity type corresponding to userID transferred by the client. If the client does not transfer the value of this parameter, the default value 0 is used.
     * If userID is not empty and authType is set to 6 or 9, set this parameter to 1.The options are as follows:
     * 0: subscriber ID. In this case, only the subscriber password (excluding the super profile password) is verified.
     * 1: loginName of a profile. In this case, only the profile password or token is verified.
     * 2: logical device ID. In this case, only the logical device password is verified.
     * 3: guest user. The value of the userID parameter for a guest user is the same as the value of the tenant configuration item GuestID.
     */
    /**
     * Please do not change the string value to number otherwise it will cause bugs
     */
    userType: {
        SUBSCRIBER_ID: '0',
        LOGIN_NAME: '1',
        LOGICAL_DEVICE_ID: '2',
        GUEST_USER: '3',
        STB_SN: '4', // STB Serial Number
    },

    /**
     * Type of profiles to be queried.
     * When the value of type is 4, the password, mobilePhone, email, and birthday fields are not returned.
     * The options are as follows:
     * 0: current profile
     * 1: all profiles
     * 2: super profile
     * 4: all profiles in the system (precise query based on the ID of a profile)
     * NOTE
     * All user profiles in the system can be queried based on the profile ID only when the QueryOtherProfileByIDEnable parameter of the VSP is set to support.
     * For details about how to set system parameters, see the Envision Video Platform Product Documentation.
     */
    profileType: {
        CURRENT_PROFILE: 0,
        ALL_PROFILES: 1,
        SUPER_PROFILE: 2,
        ALL_PROFILE_IN_SYSTEM: 4,
    },

    /**
     * Authentication method.
        Notice:
        When authType is set to 1 or 2 (used for password-free login), userID is mandatory, and userType is set to 1. Set authType to 1 and enter the password of loginName/User Profile to log in to the VSP to obtain authToken generated by the VSP. The authToken parameter has a validity period. Within the validity period, set authToken to 2 and transfer authToken in the request to implement password-free login.
        When authType is set to 2 or 5, authToken is mandatory.
        When authType is set to 6, authToken is mandatory and userType is 1.
        When authType is set to 10, authToken and clientPassword are mandatory.
        When the value of authType is 12, the subnetID parameter in the authToken and AuthenticateTolerant objects is mandatory.
        The options are as follows:
        0: password-based authentication. The password can be a subscriber password, device password, profile password, or a password allocated by a third-party authentication system.
        1: User name- and password-based authentication for a password-free login for the first time.
        2: Password-free login authentication (The password is not required for the first login and authentication is required for the second login.)
        3: authentication of password-free login on a mobile network (On a mobile network, the system authenticates a subscriber by checking whether the subscribers IP address is valid.)
        4: authentication of login using an SMS verification code
        5: password-free SSO login authentication (The token or code carried in the subscriber request is verified by the SSO system. The DT-EU SSO user ID is optional.)
        6: Login of a registered Facebook ID (Only registered Facebook IDs can be used to log in.)
        7: Unified authentication for China Telecom e-surfing platform (used only when China Telecom e-surfing platform is connected)
        8: Verification code-based login (used in QR code scanning and Chromecast login scenarios. Subscribers who are not in the login state need to obtain verification codes, and subscribers who have logged in are bound to verification codes.)
        9: Login of a registered Google ID (Only registered Google IDs can be used to log in.)
        10: Authentication of login using an third-party SMS verification code (performed by the third-party SSO server).
        11: Different from the enumerated value 4, login authentication using an SMS verification code will return a authToken value for password-free login.
        12: China Mobile unified authentication: The client sends a China Mobile unified authentication token. The platform queries subscriber information and performs authentication on the China Mobile unified authentication platform based on the token.
     */
    authType: {
        PASSWORD_BASED: 0,
        USERNAME_PASSWORD: 1,
        PASSWORD_FREE_LOGIN: 2,
        PASSWORD_FREE_LOGIN_MOBILE: 3,
        LOGIN_SMS_CODE: 4,
        PASSWORD_FREE_SSO_LOGIN: 5,
        LOGIN_FACEBOOK: 6,
        UNIFIED_CHINA_TELECOM: 12,
        QR_CODE: 8,
        GOOGLE_ID: 9,
        LOGIN_SMS_CODE_3RD_PARTY: 10,
        LOGIN_SMS_CODE_TOKEN: 11,
        UNIFIED_CHINA_MOBILE: 12,
    },

    authTypeV2: {
        PASSWORD_BASED: 0, //  password-based authentication. The password can be a subscriber password, device password, profile password, or a password allocated by a third-party authentication system
        TOKEN_BASED: 1, // token-based authentication. (This scenario is used only at the Turkcell site.)
    },

    /**
     * CA device type.
        The options are as follows:
        1: DTV hard card STB
        2: DTV soft card STB
        3: IPTV STB
        4: OTT STB
        5: HLS OTT client (HLS private encryption, only support the ViewRight currently)
        6: Playready OTT client
        7: Widevine OTT client
        8: Fairplay OTT client
        9: ChinaDRM client
     */
    CADeviceType: {
        DTV_HARD_CARD_STB: 1,
        DTV_SOFT_CARD_STB: 2,
        IPTV_STB: 3,
        OTT_STB: 4,
        HLS_OTT_CLIENT: 5,
        PLAYREADY_OTT_CLIENT: 6,
        WIDEWINE_OTT_CLIENT: 7,
        FAIRPLAY_OTT_CLIENT: 8,
        CHINA_DRM_CLIENT: 9,
    },

    /** @type {DeviceType} */
    deviceType: {
        IPTV_STB: 0,
        IPTV_PC_CLIENT: 1,
        OTT_DEVICE: 2,
        MOBILE_DEVICE: 3,
        ALL: 4,
        OTT_STB: 5,
        OTT_NON_STB: 6,
        SOLO_DEVICE: 9,
    },

    /**
     * Logout refers to the state that a Profile is logged out while the STB enters the status of switching the Profile.
     * In this case, only device sessions exist.
     * Power-off will delete both subscriber sessions and device sessions.
     */
    logoutType: {
        LOGOUT: 0,
        POWER_OFF: 1,
    },

    /**
     * Enumeration array of the poster type. If the request does not contain this parameter, the VSP will not filter posters.
     */
    posterType: {
        thumbnail: 0,
        poster: 1,
        still: 2,
        icon: 3,
        title_image: 4,
        ad: 5,
        draft: 6,
        background: 7,
        channel: 9,
        channel_black_white: 10,
        channel_name: 12,
        secondary: 13,
        logoBrightBg: 14,
        logoDarkBg: 15,
        mainWide: 17,
        secondaryWide: 18,
        instantRestartLogo: 19,
        channelFallbackWide: 20,
    },

    /**
     * Network access mode of an OTT device. The options are as follows: 1: WLAN, 2: Cellular
     */
    connectType: {
        WLAN: 1,
        CELLULAR: 1,
    },

    /**
     * Mobile network access type of an OTT device whose network access mode is Cellular.
     * This parameter is valid when connectType is set to 2. It is recommended that the device report this parameter to the Report component.
     */
    cellularConnectType: {
        _4G: 1,
        _5G: 2,
    },

    /**
     * Domain to which the subscriber belongs. Use commas (,) to separate multiple values. The options are as follows:
        0: IPTV
        1: MTV
        2: WebTV
     */
    bizdomain: {
        IPTV: 0,
        MTV: 1,
        WebTV: 2,
    },

    /**
     * Image format that is supported by the client. The options are as follows:
     *  0: The current image in .gif, .png, or .jpg format is returned. (Discarded. By default, images in all formats are returned. This parameter is reserved for compatibility with existing sites.)
     *  1: Only the image in WebP format is returned. (Discarded. By default, images in all formats are returned. This parameter is reserved for compatibility with existing sites.)
     *  2: Images in all formats are returned.
     *  3: The current image in .gif, .png, or .jpg format is returned.
     *  4: The current image in .gif, .png, or .jpg format is returned.
     */
    supportWebpImgFormat: {
        PNG_JPG_GIF: 0,
        ONLY_WEBPG: 1,
        ALL_FORMAT: 2,
    },

    /**
     * 	Indicates whether to automatically register a mobile number if the mobile number is not registered during authentication of login using an SMS verification code.The options are as follows:
     *  0: Non-automatic registration.
     *  1: Automatic registration.
     */
    autoRegister: {
        disabled: 0,
        enabled: 1,
    },

    /**
     * Indicates whether a subscriber allows the VSP to collect the subscriber's behavior data.
     * The options are as follows:
     * 0: opt in
     * 1: opt out
     */
    opt: {
        OPT_IN: 0,
        OPT_OUT: 1,
    },

    /**
     * @type {PasswordTypes}
     * Please do not change the string value to number otherwise it will cause bugs
     */
    passwordType: {
        COMMON_PROFILE: '0',
        SUBSCRIPTION: '1',
        SUBSCRIBER_LOGIN: '2',
        SUPER_PROFILE: '3',
        PARENTAL_CONTROL: '4',
    },

    /** @type {DeviceStatus} */
    deviceStatus: {
        ACTIVATED: '1',
        SUSPENDED: '0',
    },

    contentTypes: {
        VOD: 'VOD',
        CHANNEL: 'CHANNEL',
        VAS: 'VAS',
        PROGRAM: 'PROGRAM',
        NPVR: 'NPVR',
        CPVR: 'CPVR',
        VIDEO_VOD: 'VIDEO_VOD',
        AUDIO_VOD: 'AUDIO_VOD',
        VIDEO_CHANNEL: 'VIDEO_CHANNEL',
        AUDIO_CHANNEL: 'AUDIO_CHANNEL',
        SUBJECT: 'SUBJECT',
        CHANNEL_ASSET: 'CHANNEL_ASSET',
    },

    /** @type {FavoritesSortOptions} */
    favoritesSortOptions: {
        FAVO_TIME_ASC: 'FAVO_TIME:ASC',
        FAVO_TIME_DESC: 'FAVO_TIME:DESC',
        SORT_NO_ASC: 'SORT_NO:ASC',
        SORT_NO_DESC: 'SORT_NO:DESC',
        CHAN_NO_ASC: 'CHAN_NO:ASC',
        CHAN_NO_DESC: 'CHAN_NO:DESC',
    },

    /** @type {BookmarkType} */
    bookmarkType: {
        VOD: 'VOD',
        PROGRAM: 'PROGRAM',
        NPVR: 'NPVR',
        CPVR: 'CPVR',
        SERIES: 'SERIES',
    },

    /** @type {BookmarksSortOptions} */
    bookmarksSortOptions: {
        UPDATE_TIME_ASC: 'UPDATE_TIME:ASC',
        UPDATE_TIME_DESC: 'UPDATE_TIME:DESC',
    },

    /** @type {PolicyTypes} */
    policyTypes: {
        PlaybillBased: 'PlaybillBased',
        TimeBased: 'TimeBased',
        Series: 'Series',
        Period: 'Period',
    },

    /** @type {servicePayType} */
    servicePayType: {
        CASH: 'CASH', // by cash
        PREPAY: 'PREPAY', // prepaid
        OSES: 'OSES', // by bank card or credit card (for the TM site)
        MOBILE: 'MOBILE', // by mobile account (for the TM site)
        STREAMYX_BILL: 'STREAMYX_BILL', // By Streamyx Bill (for the TM site)
        VOUCHER: 'VOUCHER', // by HESA voucher (for the TM site)
        MIG: 'MIG', // HESA MIG payment mode (for the TM site)
        VOD_VOUCHER: 'VOD_VOUCHER', //VOD voucher payment mode supported for STB subscribers (for the TM site)
        WECHAT: 'WECHAT', // WeChat
        ALIPAY: 'ALIPAY', //Alipay
        ANDPAY: 'ANDPAY', // CMCC payment
    },

    /** @type {productType}*/
    productType: {
        ORDER_BY_CYCLE: 'ORDER_BY_CYCLE', // duration-based
        PPV: 'PPV', // times-based
        ALL: 'ALL', // all products
    },

    /** @type {productDurationType} */
    productDurationType: {
        TIME_BASED: '1',
        DURATION_BASED: '0',
    },

    /** @type {orderState} */
    orderState: {
        subscribed: '0',
        unsubscribed: '1',
    },

    /** @type {chargeMode} */
    chargeMode: {
        monthly: '0',
        multiday: '10',
        multimonth: '13',
        daily: '18',
        weekly: '19',
        multiweek: '20',
        yearly: '21',
    },

    /** @type {StorageTypes} */
    storageTypes: {
        CPVR: 'CPVR',
        NPVR: 'NPVR',
    },

    /** @type {PVRStatus} */
    PVRStatus: {
        WAIT_RECORD: 'WAIT_RECORD',
        RECORDING: 'RECORDING',
        SUCCESS: 'SUCCESS',
        PART_SUCCESS: 'PART_SUCCESS',
        FAIL: 'FAIL',
        OTHER: 'OTHER',
    },

    PVRStatusMapping: {
        /**
         * To record (No recording file in the PVR task is recorded.)
         */
        WAIT_RECORD: Constants.recordingStatus.scheduled,
        /**
         * Recording success (All recording files have been recorded and at least one of the recording files is in success state.)
         */
        SUCCESS: Constants.recordingStatus.completed,
        /**
         * Partial recording success (All recording files have been recorded, none of the recording files is in success state, and at least one of them is in partial success state.)
         */
        PART_SUCCESS: Constants.recordingStatus.failed,
        /**
         * Partial recording success (All recording files have been recorded, none of the recording files is in success state, and at least one of them is in partial success state.)
         */
        RECORDING: Constants.recordingStatus.ongoing,
        /**
         * Recording failure (All recording files have been recorded and none of the recording files is in success state.
         */
        FAIL: Constants.recordingStatus.failed,
        /**
         * Others (All recording files in the PVR task have been processed. Due to asynchronous processing, the status has not been updated.)
         */
        OTHER: Constants.recordingStatus.failed,
    },

    PVRStatusMappingV2: {
        /**
         * TODO for now mapping this status to failed, until we implement this status
         */
        '-3': Constants.recordingStatus.failed, // to be deleted
        '-2': Constants.recordingStatus.scheduled, // to be delivered --> Not sure about the meaning of this status but map it
        '-1': Constants.recordingStatus.scheduled, // not recorded
        0: Constants.recordingStatus.completed, // successfully recorded
        1: Constants.recordingStatus.ongoing, // being recorded
        2: Constants.recordingStatus.failed, // recording failed
        /**
         * Relying on V6 API map it to failure
         */
        3: Constants.recordingStatus.failed, // partially complete
    },

    /** @type {LockType} */
    lockType: {
        CHANNEL: 'CHANNEL',
        SUBJECT: 'SUBJECT',
        VOD: 'VOD',
        PROGRAM: 'PROGRAM',
        SERIES: 'SERIES',
        GENRE: 'GENRE',
        VAS: 'VAS',
    },

    /** @type {businessType} */
    businessType: {
        BTV: 'BTV', // live TV service
        PLTV: 'PLTV', // network TSTV service
        CPLTV: 'CPLTV', // Local TSTV
        CUTV: 'CUTV', // Catch-up TV service
        PREVIEW: 'PREVIEW', // live TV program preview
        IR: 'IR', // instant restart service
    },

    /** @type {pvrDefinition} */
    pvrDefinition: {
        _SD: '0',
        _HD: '1',
        _4K: '2',
        _720p: '4',
        _1080p: '5',
        _8K: '6',
        _2K: '7',
    },

    /** @type {priceType} */
    priceType: {
        CONTENT: 2,
        CATEGORY: 3,
    },

    /** @type {SubscriptionType} */
    subscriptionVerificationType: {
        NO_VERIFICATION: 0,
        SUPER_PROFILE_PASSWORD: 1,
        SUBSCRIPTION_PASSWORD: 2,
        SMS_VERIFICATION: 3,
        SUBSCRIBER_PASSWORD: 4,
    },

    /** @type {spaceMode} */
    spaceMode: {
        MB: 1,
        DURATION: 2,
    },

    /**
     * @type {String}
     * Ooredoo specific to me move if multiple integration with Huawei
     */
    ooredooProductCustomFields: {
        GENRE: 'PackGenre',
        PRODUCT_GENERATION: 'PackType',
    },
    ooredooProductGeneration: {
        NEW_GENERATION: 'ONG',
        OLD_GENENERATION: 'Legacy',
    },

    ooredooFamilyRole: {
        DAD: 1,
        MOM: 2,
        SON: 3,
        DAUGHTER: 4,
        GRANDPA: 5,
        GRANDMA: 6,
        MAID: 7,
        DRIVER: 8,
        OTHER: 9,
    },

    /**
     * Default password to be used as PIN Password
     */
    defaultPassword: '1111',

    /**
     * Tool to cast String to Int
     * @param {String|Number} val
     * @returns {Number}
     */
    parseInt(val) {
        if (!val) return undefined
        return typeof val === 'string' ? parseInt(val) : val
    },

    /**
     * Tool to cast String to Int
     * @param {String|Boolean} val
     * @returns {Number}
     */
    parseIBoolean(val) {
        if (!val) return undefined
        return typeof val === 'string' ? val !== '0' : val !== 0
    },

    /**
     * Tool to cast profile role to isKid flag
     * @param {Number} val
     * @returns {Boolean}
     */
    parseIsKidRole(val) {
        const KidsProfile = [
            this.ooredooFamilyRole.SON,
            this.ooredooFamilyRole.DAUGHTER,
            // Ooredoo: For vianeos application, profile is created as a Kids profile
            this.ooredooFamilyRole.OTHER,
        ]
        return KidsProfile.includes(val)
    },

    /**
     * Parse Ooredoo Custom Field and extract if the pack is Next Generation Pack
     * Ooredoo specific
     * @param {Object} customFields
     */
    parseProductGenField(customFields) {
        if (!customFields) return []
        const _KEY = this.ooredooProductCustomFields.PRODUCT_GENERATION
        if (Array.isArray(customFields)) {
            const result = customFields.find((x) => x.key === _KEY)
            if (result) return Array.isArray(result.values) ? result.values : []
        }

        return (Array.isArray(customFields[_KEY]) && customFields[_KEY]) || []
    },

    /**
     * Parse Ooredoo Custom Field and extract if the pack is Next Generation Pack
     * Ooredoo specific
     * @param {Object} customFields
     */
    isNewGenProduct(customFields) {
        const isNextGenProduct = this.parseProductGenField(customFields).includes(
            this.ooredooProductGeneration.NEW_GENERATION
        )
        return isNextGenProduct
    },

    /**
     * Parse Ooredoo Custom Field and extract if the pack is Next Generation Pack
     * Ooredoo specific
     * @param {Object} customFields
     */
    isOldGenProduct(customFields) {
        const isOldGenProduct = this.parseProductGenField(customFields).includes(
            this.ooredooProductGeneration.OLD_GENENERATION
        )
        return isOldGenProduct
    },
}

/**
 * @typedef {Object} PVRStatus Recording status
 * @property {string} WAIT_RECORD No subtask is executed `(Start time - beginOffset) of a subtask > Current time`.
 * @property {string} RECORDING At least one of the subtasks is being executed `(Start time - beginOffset) of a subtask >= Current time; (End time + endOffset) > Current time`.
 * @property {string} SUCCESS All subtasks have been executed and at least one of the recording files is in success state.
 * @property {string} PART_SUCCESS All subtasks have been executed, none of the recording files is in success state, and at least one of them is in partial success state.
 * @property {string} FAIL All subtasks have been executed and none of the recording files is in success state.
 * @property {string} OTHER The recording files are not in any of the preceding states.
 */

/**
 * @typedef {Object} BookmarkType Bookmark type
 * @property {string} VOD VOD bookmark
 * @property {string} PROGRAM Catch-up TV bookmark
 * @property {string} NPVR nPVR bookmark
 * @property {string} CPVR cPVR bookmark
 * @property {string} SERIES series bookmark
 */

/**
 * @typedef {Object} DeviceType Device type group
 * @property {number} IPTV_STB IPTV STB
 * @property {number} IPTV_PC_CLIENT IPTV PC client
 * @property {number} OTT_DEVICE OTT device, including the PC plug-in, iOS, and Android devices
 * @property {number} MOBILE_DEVICE Mobile device for the MTV solution
 * @property {number} ALL All
 * @property {number} OTT_STB OTT STB
 * @property {number} OTT_NON_STB OTT non-STB
 * @property {number} SOLO_DEVICE Solo Device
 */

/**
 * @typedef {Object} PasswordTypes Password type
 * @property {number} COMMON_PROFILE common profiles password
 * @property {number} SUBSCRIPTION subscription password
 * @property {number} SUBSCRIBER_LOGIN subscriber login password
 * @property {number} SUPER_PROFILE super profiles password
 * @property {number} PARENTAL_CONTROL parental control password
 */

/**
 * @typedef {Object} PolicyTypes Recording policy type
 * @property {string} PlaybillBased program-based recording (including subtasks of a series task)
 * @property {string} TimeBased time segment-based recording (including subtasks of a periodic task)
 * @property {string} Series Series recording
 * @property {string} Period Periodic recording
 */

/**
 * @typedef {Object} BookmarksSortOptions Sorting mode
 * @property {string} UPDATE_TIME_ASC by update time in ascending order
 * @property {string} UPDATE_TIME_DESC by update time in descending order
 */

/**
 * @typedef {Object} FavoritesSortOptions Mode of sorting content in the favorites
 * @property {string} FAVO_TIME_ASC by time when content is added to favorites in ascending order
 * @property {string} FAVO_TIME_DESC by time when content is added to favorites in descending order
 * @property {string} SORT_NO_ASC by sequence number of content items in favorites in ascending order
 * @property {string} SORT_NO_DESC by sequence number of content items in favorites in descending order
 * @property {string} CHAN_NO_ASC by channel number in ascending order
 * @property {string} CHAN_NO_DESC by channel number in descending order
 */

/**
 * @typedef {Object} StorageTypes Physical media for storing recording
 * @property {String} CPVR STB side
 * @property {String} NPVR network side
 */

/**
 * @typedef {Object} DeviceStatus Device status
 * @property {string} ACTIVATED Activated
 * @property {string} SUSPENDED Suspended
 */

/**
 * @typedef {Object} LockType Type of a locked object
 * @property CHANNEL channel
 * @property SUBJECT category
 * @property VOD VOD
 * @property PROGRAM channel program
 * @property SERIES series
 * @property GENRE genre
 * @property VAS VAS content
 */

/**
 * @typedef {Object} SubscriptionType Subscription verification type
 * @property {Number} NO_VERIFICATION No verification.
 * @property {Number} SUPER_PROFILE_PASSWORD Subscription using the super profile password
 * @property {Number} SUBSCRIPTION_PASSWORD Subscription using the subscription password
 * @property {Number} SMS_VERIFICATION SMS verification code
 * @property {Number} SUBSCRIBER_PASSWORD by subscriber password
 */

/**
 * @typedef {Object} spaceMode Mode by which the nPVR service is calculated. If the value is 1, the unit is MB. If the value is 2, the unit is second.
 * @property {Number} MB MB
 * @property {Number} DURATION Duration
 */
