
export const selectState = (state: any): any => state

export const selectPlayer = (state: any) => state.player

export const selectPrograms = (state: any) => state.programs

export const selectNextAssetProgram = (state: any) => {
    const playerState = selectPlayer(state)
    const programs = selectPrograms(state)

    if (!playerState?.programId) return
    const currentProgram = programs[playerState.programId]
    if (!currentProgram) return

    const currentSeason = programs[currentProgram.seasonPlatformId]
    if (!currentSeason || !currentSeason.seriePlatformId) return

    const serie = programs[currentSeason.seriePlatformId]
    if (!serie) return

    const seasons = serie.seasonIds.map((seasonId:any) => programs[seasonId]).filter(Boolean)
    if (!seasons) return

    const currentProgramIndex = currentSeason.episodeIds.indexOf(currentProgram.id)
    if (currentProgramIndex !== -1) {
        const nextEpisodeIdToWatch = currentSeason.episodeIds[currentProgramIndex + 1]
        return programs[nextEpisodeIdToWatch]
    }
}