import ConfigHelper from 'framework/helpers/config'
import { Platform } from 'models'

/**
 * Helper which define if the TV app can access protected headers
 */
export const canAccessProtectedHeaders = () => {
    const { platform } = ConfigHelper.getInstance().getConfig()
    const myPlatform = new Platform(platform)

    return !myPlatform.isBrowser?.()
}
