const Platforms = require('config/platforms').default
const Models = require('models')
const { default: PasswordApi } = require('services/huawei/PasswordApi')
const { default: OoredooApi } = require('services/ooredoo')

const Constants = require('api-constants').default
const VoltError = require('VoltError').default
const AbstractApi = require('../Api').default

const ProxySearchApi = require('services/proxy/SearchApi').default
const PinApi = require('services/huawei/PinApi').default
const RecoApi = require('services/huawei/RecoApi').default
const ConfigurationApi = require('services/huawei/ConfigurationApi').default

class VoltApi extends AbstractApi {
    constructor(config) {
        super(config)
        this.searchApi = new ProxySearchApi(this.config, { metaApi: this.metaApi })
        this.passwordApi = new PasswordApi(this.config)
        this.pinApi = new PinApi(this.config, { passwordApi: this.passwordApi })
        this.platformApi = new OoredooApi(this.config, {
            metaApi: this.metaApi,
            searchApi: this.searchApi,
            pinApi: this.pinApi,
            commonApi: this.commonApi,
        })
        this.recoApi = new RecoApi(this.config)
    }
}

module.exports = {
    PLATFORM_NAME: Platforms.HUAWEI,
    VoltApi,
    ConfigurationApi,
    Models,
    Constants,
    PIN_TYPE: PinApi.TYPE,
    PIN_SIZE: 4,
    OTP_SIZE: 4,
    VoltError,
}
