const analyticsFirebaseInstance = {
    isStub: true,
    setAnalyticsCollectionEnabled: () => {
        Promise.resolve()
    },
    resetAnalyticsData: () => {
        Promise.resolve()
    },
    logScreenView: () => {
        Promise.resolve()
    },
    logEvent: () => {
        Promise.resolve()
    },
    logAppOpen: () => {
        Promise.resolve()
    },
    logLogin: () => {
        Promise.resolve()
    },
    logSignUp: () => {
        Promise.resolve()
    },
    logTutorialBegin: () => {
        Promise.resolve()
    },
    logTutorialComplete: () => {
        Promise.resolve()
    },
    logSelectContent: () => {
        Promise.resolve()
    },
    logSelectItem: () => {
        Promise.resolve()
    },
    logSelectPromotion: () => {
        Promise.resolve()
    },
    logPurchase: () => {
        Promise.resolve()
    },
    logSearch: () => {
        Promise.resolve()
    },
    setUserId: () => {
        Promise.resolve()
    },
    setUserProperty: () => {
        Promise.resolve()
    },
    setUserProperties: () => {
        Promise.resolve()
    },
    setDefaultEventParameters: () => {
        Promise.resolve()
    },
    setSessionTimeoutDuration: () => {
        Promise.resolve()
    },
}

export default analyticsFirebaseInstance
