export class AgamaAnalytics {
    private static instance: any
    public static getInstance(agamaAnalyticsConfig: any, version: any, name: any, deviceData: any, deviceType: any,platformtype:any) {
        if (!AgamaAnalytics.instance) {
            var args = {
                appName: name ? name : "",
                appVersion: version ? version : "",
                configString: agamaAnalyticsConfig._configuration,
                verbose: agamaAnalyticsConfig.verbose,
                userAccountId: localStorage.getItem('CURRENT_PROFILE_ID'),
                env: deviceType,
                player: agamaAnalyticsConfig.playerName,
                playerVersion: agamaAnalyticsConfig.playerVersion,
                deviceId: deviceData?.uniqueDeviceId,
                envArgs: {
                    // only pc or tv
                    deviceType: platformtype,
                    userAccountId: localStorage.getItem('CURRENT_PROFILE_ID'),
                    player: agamaAnalyticsConfig.playerName,
                    playerVersion: agamaAnalyticsConfig.playerVersion
                },
            };
            AgamaAnalytics.instance = new AgamaStats.EmpStats(args);
            AgamaAnalytics.instance.setUserAccountId(localStorage.getItem('CURRENT_PROFILE_ID'))
            setDeviceMetadata(AgamaAnalytics.instance, deviceData, deviceType)
        }
        return AgamaAnalytics.instance
    }
}
const setDeviceMetadata = (instance: any, deviceData: any, deviceType: any) => {
    instance.getEmpClient().setDeviceMetadata(DeviceMetadata.DEVICE_BROWSER, deviceData?.deviceName)
    instance.getEmpClient().setDeviceMetadata(DeviceMetadata.DEVICE_BROWSER_VERSION, deviceData?.model)
    instance.getEmpClient().setDeviceMetadata(DeviceMetadata.DEVICE_OS, deviceData?.deviceOS)
    instance.getEmpClient().setDeviceMetadata(DeviceMetadata.DEVICE_TYPE, deviceType)
    instance.getEmpClient().setDeviceMetadata(DeviceMetadata.USER_ACCOUNT_ID, localStorage.getItem('CURRENT_PROFILE_ID'))
}

export enum DeviceMetadata {
    PLAYER = 'PLAYER',
    PLAYER_VERSION = 'PLAYER_VERSION',
    DEVICE_BROWSER = 'DEVICE_BROWSER',
    DEVICE_BROWSER_VERSION = 'DEVICE_BROWSER_VERSION',
    DEVICE_OS = 'DEVICE_OS',
    DEVICE_OS_VERSION = 'DEVICE_OS_VERSION',
    DEVICE_ID = 'DEVICE_ID',
    DEVICE_TYPE = 'DEVICE_TYPE',
    DEVICE_MANUFACTURER = 'DEVICE_MANUFACTURER',
    DEVICE_MODEL = 'DEVICE_MODEL',
    DEVICE_IP = 'DEVICE_IP',
    // DEVICE_LATITUDE = 'DEVICE_LATITUDE',
    // DEVICE_LONGITUDE = 'DEVICE_LONGITUDE',
    EMPCLIENT_INTEGRATION_VERSION = 'EMPCLIENT_INTEGRATION_VERSION',
    EMPCLIENT_INTEGRATION_BUILDDATE = 'EMPCLIENT_INTEGRATION_BUILDDATE',
    DATA_CONNECTION_TYPE = 'DATA_CONNECTION_TYPE',
    APPLICATION = 'APPLICATION',
    APPLICATION_VERSION = 'APPLICATION_VERSION',
    USER_ACCOUNT_ID = 'USER_ACCOUNT_ID',
}