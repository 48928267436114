import { useIcon } from '../icon/useIcon'
import { Toast, ToastPosition, ToastType } from '../toast/Toast'
import { createEffect, createSignal, on, onCleanup, Show } from 'solid-js'
import './logintvform.css'
import {useConfig} from '../config/useConfig'
import {FocusGroupProvider, FocusableProvider} from '../focus/index'
import {useSpatialNavigation} from '../../spatialNavigation'
import {onMount} from 'solid-js'
import {isPlatform} from '../../utils'
import {useI18n} from '../../i18n'
import { useSelector } from '../../store'

interface LoginTVFormProps {
    onLoginTVForm: (username: string, password: string) => void
    username: string
    password: string
    failed: boolean
}

export const LoginTVForm = (props: LoginTVFormProps) => {
    const {getI18n} = useI18n()
    const {getConfig} = useConfig()

    const icons = useIcon()
    const {setFocus} = useSpatialNavigation()

    const [usernameOrEmail, setUsernameOrEmail] = createSignal(props.username);
    const [formPassword, setFormPassword] = createSignal(props.password);
    const [error, setError] = createSignal('');
    const [showToast, setShowToast] = createSignal(false)
    const [usernameRef, setUsernameRef] = createSignal<any>(null)
    const locale = useSelector((state: any) => state?.language?.locale)

    const handleUsernameOrEmailChange = (e: {target: {value: any}}) => {
        setUsernameOrEmail(e.target.value)
    }

    const handlePasswordChange = (e: {target: {value: any}}) => {
        setFormPassword(e.target.value)
    }

    createEffect(
        on(
            () => usernameRef(),
            () => {
                if (usernameRef() && !usernameOrEmail()) {
                    document.getElementById("usernameOrEmail")?.focus()
                }
            })
    )

    const handleRemoteControlInput = (event:any) => {
        if(event?.keyCode === 37){
          event.preventDefault();
          moveCursorLeft(event?.target)
        }
        else if(event.keyCode === 39){
            moveCursorRight(event?.target)
        }
        else if(event.key === "Enter"){
            event?.target?.blur();
        }
      };

    onMount(() => {
        for(var i = 0; i<=9; i++){
            window?.tizen?.tvinputdevice.registerKey(String(i));
        }

        // Add an event listener for keydown events
        window.addEventListener('keydown', handleRemoteControlInput);

        if (!isPlatform('laptop')) {
            if (document.getElementById("usernameOrEmail") && !usernameOrEmail()) {
                setFocus(`usernameOrEmail`)
                document.getElementById('usernameOrEmail')?.focus()
            }else {
                setFocus('loginformbutton')
            }
        }
    })

    // Cleanup to remove the event listener when the component is unmounted
    onCleanup(() => {
        window.removeEventListener('keydown', handleRemoteControlInput);
        setUsernameOrEmail('')
        setFormPassword('')
    });

    /*
  Move cursor left by click on left arrow from virtual keyboard
  */
  const moveCursorLeft = (inputElement:any) => {
    const currentPosition = inputElement?.selectionStart;
    if (currentPosition > 0) {
      inputElement?.setSelectionRange(currentPosition - 1, currentPosition - 1);
    }
  }

  /*
  Move cursor right by click on right arrow from virtual keyboard
  */
  const moveCursorRight = (inputElement:any) => {
      const currentPosition = inputElement?.selectionStart;
      const textLength = inputElement?.value?.length;
      if (currentPosition < textLength) {
        inputElement?.setSelectionRange(currentPosition + 1, currentPosition + 1);
      }
    }


    createEffect(() => {
        if (showToast()) {
            const timeoutId = setTimeout(() => {
                setShowToast(false)
            }, 3000)

            // Cleanup function to clear timeout
            return () => clearTimeout(timeoutId)
        }
    })

    const handleSubmit = () => {
        // Validate username or email
        if (!usernameOrEmail()) {
            setError('Username or email is required');
            setShowToast(true);
            setFocus("loginformbutton");
            return;
        }

        // Validate password
        const passwordError = validatePassword(formPassword())
        if (passwordError) {
            setError(passwordError)
            setShowToast(true)
            return
        }

        // Perform login (Here you might make an API call to validate credentials)
        props?.onLoginTVForm(usernameOrEmail(), formPassword())
        setError('')
    }

    const validatePassword = (formPassword: string): string => {
        if (!formPassword) {
            return 'Password is required'
        }
        // if (formPassword.length < 8) {
        //     return 'Password must be at least 8 characters long';
        // }
        // if (!/[A-Z]/.test(formPassword)) {
        //     return 'Password must contain at least one uppercase letter';
        // }
        // if (!/[a-z]/.test(formPassword)) {
        //     return 'Password must contain at least one lowercase letter';
        // }
        // if (!/[^a-zA-Z0-9]/.test(formPassword)) {
        //   return 'Password must contain at least one special character';
        // }
        return '' // No error
    }

    return (
        <FocusGroupProvider id={`logintvform-screen`} focusKey={`logintvform-screen`} onFocus={() => {}}>
            <div
                style={{
                    display: 'flex',
                    'justify-content': 'center',
                    'align-items': 'center',
                    height: '100vh',
                    'background-image': `url(${icons.get('LoginBackdrop')})`,
                    'background-size': 'cover',
                    direction: `${getConfig().rtlLanguages.includes(locale()) ? 'rtl' : 'ltr'}`
                }}
            >
                <div class="login-content-tvform">
                    <div class="client_logo_loading_logout_screen"></div>

                    <div>
                        <label class="usernameOrEmail" for="usernameOrEmail">
                            {getI18n()?.formatMessage({id: 'USERNAME_OR_EMAIL'})}
                        </label>
                        <FocusableProvider
                            focusKey={`usernameOrEmail`}
                            onFocus={() => {
                                document.getElementById('usernameOrEmail')?.focus()
                            }}
                            onBlur={() => {
                                document.getElementById('usernameOrEmail')?.blur()
                            }}
                        >
                            {(focused: any) => (
                                <input
                                    ref={(e) => { setUsernameRef(e) }}
                                    type="text"
                                    id="usernameOrEmail"
                                    value={usernameOrEmail()}
                                    onInput={handleUsernameOrEmailChange}
                                    required
                                    class={`login-input ${focused ? 'login-input-focus' : ''}`}
                                />
                            )}
                        </FocusableProvider>
                    </div>

                    <div>
                        <label class="formPassword" for="formPassword">
                            {getI18n()?.formatMessage({id: 'PASSWORD'})}
                        </label>
                        <FocusableProvider
                            focusKey={`formPassword`}
                            onFocus={() => {
                                document.getElementById('formPassword')?.focus()
                            }}
                            onBlur={() => {
                                document.getElementById('formPassword')?.blur()
                            }}
                        >
                            {(focused: any) => (
                                <input
                                    type="password"
                                    id="formPassword"
                                    value={formPassword()}
                                    onInput={handlePasswordChange}
                                    class={`login-input ${focused ? 'login-input-focus' : ''}`}
                                    required
                                />
                            )}
                        </FocusableProvider>
                    </div>

                    <Show when={props.failed && !error()}>{setShowToast(true)}</Show>

                    <Show when={(showToast() && !props.failed && error()) || (showToast() && error())}>
                        <Toast
                            onClose={() => setShowToast(false)}
                            title="Error"
                            description={error()}
                            type={ToastType.ERROR}
                            position={ToastPosition.TOP_CENTER}
                        />
                    </Show>

                    <Show when={showToast() && props.failed && !error()}>
                        <Toast
                            onClose={() => setShowToast(false)}
                            title="Error"
                            description="Invalid Credentials, Please try again."
                            type={ToastType.ERROR}
                            position={ToastPosition.TOP_CENTER}
                        />
                    </Show>

                    <FocusableProvider
                        id={'loginformbutton'}
                        focusKey={`loginformbutton`}
                        onEnterPress={() => document.getElementById('loginbutton')?.click()}
                        onFocus={() => {
                            document.getElementById('loginbutton')?.focus()
                        }}
                    >
                        {(focused: any) => (
                            <button
                                id="loginbutton"
                                class={`login-content-button-tv ${focused ? 'login-content-button-tv-focus' : ''}`}
                                tabIndex={focused ? 0 : -1}
                                type="submit"
                                onClick={handleSubmit}
                            >
                                {getI18n()?.formatMessage({id: 'LOG_IN'})}
                            </button>
                        )}
                    </FocusableProvider>
                </div>
            </div>
        </FocusGroupProvider>
    )
}
