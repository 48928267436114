import { IOAuthDeviceFlowStatus } from '@typings/oauth'
import VoltError from 'VoltError'
import { Abstract } from './Abstract'

/**
 * This class represent the Data Model for Device Code Authorization
 * Documentation : https://tools.ietf.org/html/draft-ietf-oauth-device-flow-15#page-7
 * ------------------------------ STANDARD PART ------------------------------
 * @property {String} deviceCode The device verification code [REQUIRED]
 * @property {String} userCode The end-user verification code [REQUIRED]
 * @property {String} verificationUri The end-user verification URI on the authorization server. [REQUIRED]
 * The URI should be short and easy to remember as end users will be asked to manually type it into their user-agent.
 * @property {String} verificationUriComplete A verification URI that includes the "user_code" (or other information with
 * the same function as the "user_code"), designed for non-textual transmission. [OPTIONAL]
 * @property {Number} expiresInSecs The lifetime in seconds of the "device_code" and "user_code" [REQUIRED]
 * @property {Number} pollingIntervalSecs The minimum amount of time in seconds that the client SHOULD wait between polling
 * requests to the token endpoint.  If no value is provided, clients MUST use 5 as the default. [OPTIONAL]
 * Default value = 5 secs requirement from  RFC https://tools.ietf.org/html/draft-ietf-oauth-device-flow-15#page-7
 * ------------------------------ INTERNAL DATA ------------------------------
 * @property {String} status Not part of the standard, internal status {@Link OAUTH_STATUS}
 * @property {String} displayType {@Link DISPLAY_TYPE} User Code (Standard) or EMAIL (NOT PART OF THE OAUTH STANDARD Specific XL Axiata)
 * @property {String} email Email of the user obfuscated. NOT PART OF THE OAUTH STANDARD (Specific XL Axiata)
 * @property {Boolean} isDebugMode Is Debug mode activated (For internal purpose because yet Amdocs does not provide a front page for authentication)
 * @property {OBJECT} debugProperties Debug information, stored as key = propName, value = propValue. SHOULD NOT BE USED IN PRODUCTION
 */
export class OAuthDeviceFlowStatus extends Abstract {
    status: string
    deviceCode?: string
    userCode?: string
    verificationUri: string
    verificationUriComplete: string
    expiresInSecs?: number
    pollingIntervalSecs: number
    email?: string
    displayType: string
    error: VoltError
    isDebugMode: boolean
    debugProperties: object

    constructor(props: IOAuthDeviceFlowStatus) {
        super()
        this.status = props.status || OAuthDeviceFlowStatus.OAUTH_STATUS.NOT_STARTED

        /**
         * NOTE: ALL THESE METADATAS SHOULD BE CONSIDERED ONLY WHILE STATUS IS EQUALS
         *       TO 'OAuthDeviceFlowStatus.OAUTH_STATUS.PENDING_LOGIN_SECONDARY_DEVICE' !!!!
         */

        const computePropValue = this._makeComputePropValue(props)

        this.deviceCode = props.deviceCode
        this.userCode = props.userCode
        this.verificationUri = computePropValue('verificationUri', props.verificationUriComplete)
        this.verificationUriComplete = computePropValue(
            'verificationUriComplete',
            props.verificationUri
        )
        this.expiresInSecs = props.expiresInSecs
        /** Default value = 5 secs requirement from  RFC https://tools.ietf.org/html/draft-ietf-oauth-device-flow-15#page-7*/
        this.pollingIntervalSecs = computePropValue('pollingIntervalSecs', 5)
        this.email = props.email
        this.displayType = props.displayType || OAuthDeviceFlowStatus.DISPLAY_TYPE.USER_CODE
        this.error = computePropValue('error', {})
        this.isDebugMode = computePropValue('isDebugMode', false)
        this.debugProperties = computePropValue('debugProperties', {})
    }

    static DISPLAY_TYPE = {
        // Standard mode. Authentication with secondary device providing a QR Code, URL and User code
        USER_CODE: 'DISPLAY_TYPE_USER_CODE',
        // Not a standard, but some client want to invite the user to check the email address with contains login informations
        EMAIL: 'DISPLAY_TYPE_EMAIL',
    }

    static OAUTH_STATUS = {
        // Means that process for authentication has not started
        NOT_STARTED: 'NOT_STARTED',
        // Means that request has been sent to backend, waiting for answer
        STARTING: 'STARTING',
        // Means that user is authenticated with secondary device
        LOGIN_SECONDARY_DEVICE: 'LOGIN_SECONDARY_DEVICE',
        // Means that user is authenticated with secondary device but need to select the TV service for finishing login (Not standard)
        LOGIN_SECONDARY_DEVICE_REQUIRE_LOGIN_TV_SERVICE:
            'LOGIN_SECONDARY_DEVICE_REQUIRE_LOGIN_TV_SERVICE',
        // Means that process for authentication has started, waiting for login with secondary device
        PENDING_LOGIN_SECONDARY_DEVICE: 'PENDING_LOGIN_SECONDARY_DEVICE',
        // Means that process for authentication has finished due to delay for login with secondary device
        TIMEOUT_LOGIN_SECONDARY_DEVICE: 'TIMEOUT_LOGIN_SECONDARY_DEVICE',
        // Means that user from IFS UI has canceled the flow pressing Back or Skip per example
        CANCELED_BY_USER: 'CANCELED_BY_USER',
        // Generic error, generally we invite the user to redo the authentication with a Retry button
        ERROR: 'ERROR',
        // Cannot perform authentication because Device not registered in the backend. Invite the user to call the customer service (Not standard)
        ERROR_DEVICE_NOT_REGISTERED: 'ERROR_DEVICE_NOT_REGISTERED',
    }

    /**
     * Should be called when slow Down event occurs. Should NOT be called otherwise
     * Doc: https://tools.ietf.org/html/draft-ietf-oauth-device-flow-15#page-11
     *      the authorization request is still pending and polling should continue, but the interval MUST
     *      be increased by 5 seconds for this and all subsequent requests
     */
    setSlowDown = () => {
        if (!this.pollingIntervalSecs) this.pollingIntervalSecs = 5
        this.pollingIntervalSecs = this.pollingIntervalSecs + 5
    }
}
