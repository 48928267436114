import './loginlanguage.css';
import {useIcon} from '../icon/useIcon';
import {FocusGroupProvider, FocusableProvider} from '../focus/index';
import { useSpatialNavigation } from '../../spatialNavigation';
import { For, onMount} from 'solid-js';
import { getLanguageLabel, isPlatform } from '../../utils';
import { useConfig } from '../config/useConfig';
import { useDispatch } from '../../store';
import { setLocale } from '@ifs/volt-store/lib/actions'

interface LoginLanguageProps {
    onLanguageSelectionComplete: () => void
}

export const LoginLanguage = (props: LoginLanguageProps) => {
    const icons = useIcon()
    const { getConfig } = useConfig()
    const {setFocus} = useSpatialNavigation()
    const dispatch = useDispatch()
    
    onMount(() => {
        if(!isPlatform('laptop')) {
            setFocus('en')
        }
    })

    const handleLanguageSelection = (languageId: string) => {
        dispatch(setLocale(languageId))
        props.onLanguageSelectionComplete()
    }

    return (
            <FocusGroupProvider
                id={`multi-language-screen`}
                focusKey={`multi-language-screen`}
            >
                <div
                    style={{
                        display: 'flex',
                        'justify-content': 'center',
                        'align-items': 'center',
                        height: '100vh',
                        'background-image': `url(${icons.get('LoginBackdrop')})`,
                        'background-size': 'cover',
                    }}
                >
                <div class="multi-language">
                    <div class="client_logo_loading_logout_screen"></div>
                    <h2 class = "screen-heading">Menu Language</h2>
                    <div class="login-content-info">Please choose the default menu language below</div>
                    <div class="login-content-info">(can still be changed later in the optional)</div>

                    <For each={getConfig()?.supportedLanguages}>
                        {language => (
                            <FocusableProvider
                                id={language}
                                focusKey={language}
                                onEnterPress={() => {
                                    handleLanguageSelection(language)
                                }}
                            >
                            {(focused: any) => (
                                                
                                <button 
                                    class={`login-content-button-tv ${focused ? 'login-content-button-tv-focus' : ''}`}  
                                    onClick={() => {
                                        handleLanguageSelection(language)
                                    }}
                                >
                                    {getLanguageLabel(language)}
                                </button>
                            
                            )}
                            </FocusableProvider>
                        )}
                    </For>
                </div>
            </div>
            </FocusGroupProvider>
    );
}
  
