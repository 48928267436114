/**
 * Takes a string as parameter and returns the string without spaces and
 * transformed to lower case.
 * For example " H elLo" -> "hello"
 *
 * @param   {?string} string string to transform
 *
 * @returns {?string} string transformed or null when the value is not defined
 */
function removeSpaceAndTransformToLowerCase(string) {
    return string ? string.replace(/ /g, '').toLowerCase() : null
}

export default removeSpaceAndTransformToLowerCase
