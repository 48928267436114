import { isEqual } from "lodash"
import { profileSelector } from './profileSelector'
import { useConfig } from "../../components"

export const selectState = (state: any): any => state

export const selectPlayer = (state: any) => state.player

export const selectPrograms = (state: any) => state.programs

export const selectProductsState = (state: any) => state.products

export const selectChannelsStore = (state: any) => state.channels

export const selectAuthState = (state: any) => state.auth

export const selectAssetProgram = (state: any) => {
    const playerState = selectPlayer(state)
    const programs = selectPrograms(state)

    if (playerState.livePlay || !playerState.programId)
        return undefined

    return programs[playerState.programId]
}

export const selectProducts = (state: any) => {
    const productsState = selectProductsState(state)

    return productsState.data
}

export const selectChannelsData = (state: any) => {
    const channels = selectChannelsStore(state)

    return channels.data
}

export const selectChannelFilteringCriteria = (state: any) => {
    const channels = selectChannelsStore(state)

    return channels.filteringCriteria
}


export const selectKidsChannels = (state: any) => {
    const channels = selectChannelsData(state)

    return Object.values(channels).filter((channel: any) => channel?.isKidsChannel)
}

export const selectAllProfiles = (state: any) => {
    const authState = selectAuthState(state)

    return authState.profiles
}

// export const getUserProfile = (profiles = []) =>
//     profiles?.find((profile: any) => profile?.selectedProfile) ||
//     profiles?.find((profile: any) => profile?.isDefaultProfile) ||
//     profiles?.find(
//         (profile: any) => profile?.profileType === Models?.Profile?.PROFILE_TYPE?.SUPER_PROFILE,
//     ) ||
//     profiles?.[0]

// export const selectProfile = (state: any) => {
//     const profiles = selectAllProfiles(state)

//     return profileSelector(profiles)
// }

export const selectIsKidProfile = (state: any) => {
    const profile: any = profileSelector(state)

    return profile?.isKidProfile
}

export const getProductsFromChannel = (channel: any, products: any) =>
    channel.productIds.reduce((acc: any, productId: any) => {
        const product = products[productId]

        if (product && product.isSubscription()) acc.push(product)

        return acc
    }, [])

export const isChannelWatchable = (channel: any, products: any) =>
    (channel.isVirtualChannel() && !!channel.videoUrl) ||
    (!channel.isVirtualChannel() && channel.productIds.length === 0) ||
    getProductsFromChannel(channel, products).some((product: any) => product.isEntitledNow())

export const selectChannelsState = (state: any) => {
    const channels: any = selectChannelsData(state)
    const products: any = selectProducts(state)
    const filteringCriteria: any = selectChannelFilteringCriteria(state)
    const kidsChannels: any = selectKidsChannels(state)
    const isKidProfile: any = selectIsKidProfile(state)

    return Object.values(channels).reduce((acc: any, channel: any) => {
        if (isKidProfile) {
            if (kidsChannels?.length > 0) {
                if (channel.isKidsChannel) return { ...acc, [channel.id]: channel }

                return acc
            }

            return isChannelWatchable(channel, products)
                ? { ...acc, [channel.id]: channel }
                : acc
        }
        if (
            !channel.channelFilter ||
            !filteringCriteria ||
            !filteringCriteria.length ||
            filteringCriteria.includes(channel.channelFilter)
        ) return { ...acc, [channel.id]: channel }

        return acc
    }, {})
}

export const selectAssetChannel = (state: any) => {
    const playerState: any = selectPlayer(state)
    const program: any = selectAssetProgram(state)
    const channels: any = selectChannelsState(state)

    const channelId = program
        ? program.channelId
            ? program.channelId
            : program.id
        : playerState.channelId
            ? playerState.channelId
            : playerState.programId

    if (channelId || program) return channels[channelId]

    return undefined
}

export const selectAssetChannelIsWatchable = (state: any) => {
    const products: any = selectProducts(state)
    const channel: any = selectAssetChannel(state)

    return channel && isChannelWatchable(channel, products)
}

export const selectLastPlaybackUrlRequest = (state: any) => {
    const playerState = selectPlayer(state)

    return playerState?.lastPlaybackUrlRequest
}

export const selectAsset = (state: any) => {
    const playerState = selectPlayer(state)
    const program = selectAssetProgram(state)
    const channelIsWatchable = selectAssetChannelIsWatchable(state)
    const channel = selectPlayer(state)
    const lastPlaybackUrlRequest = selectLastPlaybackUrlRequest(state)

    const { getConfig } = useConfig()

    // This ternary should be removed once `disablePlayerControl` is activated on mobile project:
    // const isRequestVerified = getConfig().storeNavigation?.disablePlayerControl
    //     ? isEqual(lastPlaybackUrlRequest, playerState.sourceRequest)
    //     : true

    // if ((channel || program) && isRequestVerified) {
    if ((channel || program)) {
        return {
            stream: {
                url: playerState.streamUrl,
                backdropUrl: playerState.backdropUrl,
                assetId: playerState.assetId,
                isRollingBuffer: playerState.isRollingBuffer,
                isStartOver: playerState.isStartOver,
                isTrailer: playerState.isTrailer,
                programId: program?.id || playerState.programId,
                channelId: channel?.id || playerState.channelId,
            },
            video: {
                startAt: program?.startTime || 0,
                endAt: program?.endTime || program?.duration,
                offset:
                    playerState.isTrailer || lastPlaybackUrlRequest?.resetBookmarkBeforePlayback
                        ? 0
                        : playerState.offset || program?.bookmark,
            },
            drm: playerState.drm,
            keepAlive: playerState.keepAlive,
            subtitles: playerState.subtitles,
            channel,
            program,
            canWatch: program?.isVod?.() || channelIsWatchable,
        }
    }
    return {
        stream: {},
        video: {},
        drm: {},
    }
}