import { sortedIndexBy } from 'lodash'
import { Live } from 'models'
import { timestampToIsoDateTimeString } from 'helpers/date'
import Utils from '@ifs/volt-utils'

/**
 * Used for filtering out overlapping epg programs and for plugging gaps in a channel's epg schedule by
 * inserting fake "filler" programs.
 *
 * When two epg programs have overlapping broadcast times, the latest program is discarded while the earliest is retained.
 *
 * It is imperative that the input programs list is sorted in chronological order (ascending)
 *
 * @param {Array<String>} programIds The list of program ids to transform
 * @param {Object} programsMap A hash mapping program id to program data (could be subject to modifications)
 * @param {String|Number} channelId The id of the channel the programs belong to
 * @param {Number} startTimestamp The timestamp of the beginning of the date range
 * @param {Number} endTimestamp The timestamp of the end of the date range
 * @returns {Array<String>} The list of ids of all the programs retained and of the fillers
 */
export const plugEpgHoles = (programIds, programsMap, channelId, startTimestamp, endTimestamp) => {
    const _programIds = programIds.reduce((acc, id) => {
        const currentProgram = programsMap[id]
        if (!currentProgram) return acc

        const previousProgram = acc[acc.length - 1] && programsMap[acc[acc.length - 1]]

        if (!previousProgram || currentProgram.startTime === previousProgram.endTime) {
            acc.push(id)
        } else if (currentProgram.startTime > previousProgram.endTime) {
            const fillerId = 'filler-' + channelId + '-' + currentProgram.startTime

            programsMap[fillerId] = new Live({
                id: fillerId,
                startTime: previousProgram.endTime,
                endTime: currentProgram.startTime,
                channelId,
                isFillerProgram: true,
            })

            acc.push(fillerId)
            acc.push(id)
        } else delete programsMap[id] // the program overlaps with the previous program and is discarded

        return acc
    }, [])

    if (_programIds.length) {
        // the last program of the day
        const last = programsMap[_programIds[_programIds.length - 1]]
        const first = programsMap[_programIds[0]]

        // if the last program doesn't reach the end of the day, a filler is inserted
        if (last && last.endTime < endTimestamp) {
            const fillerId = 'filler-' + channelId + '-' + endTimestamp
            programsMap[fillerId] = new Live({
                id: fillerId,
                startTime: last.endTime,
                endTime: endTimestamp,
                channelId,
                isFillerProgram: true,
            })

            _programIds.push(fillerId)
        }

        // if the first program begins after startTimestamp, a filler is inserted
        if (first && first.startTime > startTimestamp) {
            const fillerId = 'filler-' + channelId + '-' + startTimestamp
            programsMap[fillerId] = new Live({
                id: fillerId,
                startTime: startTimestamp,
                endTime: first.startTime,
                channelId,
                isFillerProgram: true,
            })

            _programIds.unshift(fillerId)
        }
    }

    return _programIds
}

/**
 * Function to calibrate a timestamp to the minute instead of its seconds
 * @returns timestamp to the minute
 */
export const getTimestampToTheMinute = (inputTimestamp) => {
    const date = inputTimestamp ? new Date(inputTimestamp) : new Date()
    date.setSeconds(0, 0) // sets the seconds and milliseconds to 0
    return date.getTime()
}

/**
 * Converts input values often used in proxy epg requests into ajax parameters understood by the proxy endpoint
 *
 * @param {Object} params
 * @param {String|Number|Array<String|Number>} params.channelIds channel ID or list of channel IDs
 * @param {String|Number|Array<String|Number>} [params.platformChannelIds]  Platform channel ID or list (alternative if channelIds no provided)
 * @param {EpgDateRangeFilters} boundaries Specifies the broadcast time boundaries
 * @returns {ProxyEpgRequestParams}
 */
export const formatEpgRequestParams = ({
    proxyVersion,
    channelIds,
    platformChannelIds,
    boundaries = {},
}) => {
    const params = {}

    if (channelIds) {
        if (Array.isArray(channelIds)) {
            const channelsField = proxyVersion >= 3 ? 'in_channel_id' : 'in_channel'
            params[channelsField] = channelIds.join(',')
        } else {
            const channelField = proxyVersion >= 3 ? 'channel_id' : 'channel'
            params[channelField] = channelIds
        }
    } else if (platformChannelIds) {
        if (Array.isArray(platformChannelIds)) {
            params['in_channel_platform_id'] = platformChannelIds.join(',')
        } else {
            params['channel_platform_id'] = platformChannelIds
        }
    }

    Object.keys(boundaries).forEach((filter) => {
        const boundary = boundaries[filter]
        params[filter] =
            proxyVersion >= 3
                ? (boundary - (boundary % 1000)) / 1000 //remove last three digits
                : timestampToIsoDateTimeString(boundary, {
                      utc: true,
                      withTimezone: false,
                  })
    })

    return params
}

/**
 * Computes the timestamp at midnight of each day in a given date range
 *
 * @param {Number} startTime unix timestamp (ms)
 * @param {Number} endTime unix timestamp (ms)
 * @returns {Array<Number>} array of timestamps in decreasing order
 */
export const computeDayTimestampsFromDateRange = (startTime, endTime) => {
    if (!Number.isFinite(startTime) || !Number.isFinite(endTime)) return []

    const timestamps = []
    let t = Utils.date.getStartTime(startTime)

    while (t < endTime) {
        timestamps.unshift(t)
        t += Utils.date.toMs.fromDays(1)
    }

    return timestamps
}

/**
 * Orders a list of Live programs by day and in chronological order
 * @param {Object} params
 * @param {Array<Live>} params.programs List of {@link Live} programs to order
 * @param {Number} params.startTime Timestamp used to find the lower boundary of the date range
 * (the day the timestamp belongs to is the lower boundary)
 * @param {Number} params.endTime Timestamp used to find the upper boundary of the date range
 * (the day the timestamp belongs to is the upper boundary)
 * @returns {Object<Number, Array<Live>>} Live programs ordered by day and in chronological order
 */
export const orderEpgProgramsByDay = ({ programs, startTime, endTime }) => {
    const timestamps = computeDayTimestampsFromDateRange(startTime, endTime)
    const upperLimit = Utils.date.getEndTime(endTime)

    return programs.reduce((acc, program) => {
        if (program.startTime > upperLimit) return acc

        const timestamp = timestamps.find((ts) => program.startTime >= ts)

        if (timestamp) {
            if (!acc[timestamp]) {
                acc[timestamp] = []
            }

            const insertionIndex = sortedIndexBy(acc[timestamp], program, (p) => {
                return p.startTime
            })

            acc[timestamp].splice(insertionIndex, 0, program)
        }

        const timestampAtProgramEnd = timestamps.find((ts) => program.endTime >= ts)

        // Programs that end on a given day but started the previous day are also added to the list
        if (timestampAtProgramEnd && timestamp !== timestampAtProgramEnd) {
            if (!acc[timestampAtProgramEnd]) {
                acc[timestampAtProgramEnd] = [program]
            } else {
                const insertionIndex = sortedIndexBy(acc[timestampAtProgramEnd], program, (p) => {
                    return p.startTime
                })

                acc[timestampAtProgramEnd].splice(insertionIndex, 0, program)
            }
        }

        return acc
    }, {})
}

/**
 * @typedef {Object} EpgDateRangeFilters
 * @property {Number} [gt_begin] StartTime must be greater than this timestamp value
 * @property {Number} [gte_begin] StartTime must be greater than or equal to this timestamp value
 * @property {Number} [lt_begin] StartTime must be less than this timestamp value
 * @property {Number} [lte_begin] StartTime must less than or equal to this timestamp value
 * @property {Number} [gt_end] EndTime must be greater than this timestamp value
 * @property {Number} [gte_end] EndTime must be greater than or equal to this timestamp value
 * @property {Number} [lt_end] EndTime must be less than this timestamp value
 * @property {Number} [lte_end] EndTime must less than or equal to this timestamp value
 */

/**
 * @typedef {Object} ProxyEpgRequestParams
 * @property {String} [in_channel] The list of comma seperated channel IDs
 * @property {String} [channel] channel ID
 * @property {String} gt_begin the lower end of the date range filter (in ISO datetime format)
 * @property {String} lt_begin the higher end of the date range filter (in ISO datetime format)
 */
