import { IRequestFilter, RequestFilterFilter, RequestFilterSort } from '@typings/emi'
import { Abstract } from './Abstract'
import { HashMap } from '../..'

/**
 * This class is an adaptor of request coming from EMI
 * thats needs be used
 */
export class RequestFilter extends Abstract {
    filter: RequestFilterFilter[]
    sort: RequestFilterSort[]
    limit: number | undefined

    constructor(props: IRequestFilter) {
        super()

        this.filter = Array.isArray(props.filter) ? props.filter : []
        this.sort = Array.isArray(props.sort) ? props.sort : []
        const limit =
            typeof props.limit === 'number'
                ? props.limit
                : typeof props.limit === 'string'
                ? parseInt(props.limit, 10)
                : undefined
        this.limit = typeof limit === 'undefined' ? undefined : limit
    }

    /**
     * This method returns the query parameters to provide to the proxy api
     */
    getProxyParams(): HashMap<string, string> {
        let param = {}
        if (this.filter && this.filter.length > 0) {
            param = this.filter.reduce<{ [key: string]: string }>((acc, filter) => {
                if (typeof filter !== 'object') return acc
                const { field, type, value } = filter
                if (field && type && value) {
                    if (RequestFilter.COMPARATOR[type] !== undefined) {
                        const key = RequestFilter.COMPARATOR[type] ? `${type}_${field}` : field
                        acc[key] = Array.isArray(value) ? value.join(',') : value
                    }
                }
                return acc
            }, {})
        }
        if (this.sort && this.sort.length > 0) {
            const order = this.sort
                .map((sort) => {
                    if (typeof sort !== 'object' || !sort.field) return undefined
                    if (sort.dir === RequestFilter.SORTING_DIRECTION.DESCENDANT) {
                        return `${sort.field}_${RequestFilter.SORTING_DIRECTION.DESCENDANT}`
                    }
                    return sort.field
                })
                .filter(Boolean)
                .join(',')
            param = {
                ...param,
                order,
            }
        }
        if (this.limit) {
            param = {
                ...param,
                limit: this.limit,
            }
        }

        return param
    }

    /** Generate UUID to unify the request */
    getRequestFilterUuid() {
        const params = this.getProxyParams()
        return Object.keys(params)
            .map((key) => `_${key}_${params[key]}`)
            .join('')
    }

    /**
     * Try to not use this method. Pending uniformization.
     */
    getCategories(
        categoryKey = [RequestFilter.FILTERING_KEY.CATEGORIES, RequestFilter.FILTERING_KEY.CATEGORY]
    ) {
        const categoryFilter = (this.filter || []).find(
            (filter) => filter && categoryKey.includes(filter.field)
        )
        if (categoryFilter) {
            return Array.isArray(categoryFilter.value)
                ? categoryFilter.value
                : (categoryFilter.value || '').split(',')
        }
        return []
    }

    /**
     * Try to not use this method. Pending uniformization.
     */
    getRatingIds() {
        const ratingFilter = (this.filter || []).find(
            (filter) => filter && RequestFilter.FILTERING_KEY.RATING_ID === filter.field
        )
        if (ratingFilter) {
            return Array.isArray(ratingFilter.value)
                ? ratingFilter.value
                : (ratingFilter.value || '').split(',')
        }
        return []
    }

    static COMPARATOR = {
        eq: '', // Equal -- On .key == val
        gt: 'gt', // Great than -- On .key > val
        lt: 'lt', // Less than -- On .key < val
        gte: 'gte', // Great than equal -- On .key >= val
        lte: 'lte', // Less than equal -- On .key <= val
        like: 'like', // Live -- On .key TSQL LIKE val
        ilike: 'ilike', // Insensitive like Live -- On .key TSQL LIKE val
        in: 'in', // in --- On .key in [val1,val2,val3]
        notin: 'notin', // notin --- On .key not in [val1,val2,val3]
    }

    static SORTING_DIRECTION = {
        ASCENDANT: 'asc',
        DESCENDANT: 'desc',
    }

    /**
     * TODO: To be removed later, need uniformization
     * with Proxy v1 & v3 and the support of same keys
     * Temporary used for work around
     */
    static FILTERING_KEY = {
        RATING_ID: 'rating_id',
        CATEGORIES: 'categories',
        CATEGORY: 'category',
        // TO BE REMOVED, PENDING MACULOSA SUPPORT
        CHANNEL_CATEGORIES: 'channel_categories',
        CHANNEL_CATEGORY: 'channel_category',
    }
}
