/**
 * Parse channels Playback IDs
 * @param {Array<Object>} channelDynamicProp
 * @returns
 */
export const parseChannelPlaybackIds = (channelDynamicProp = []) => {
    if (!channelDynamicProp && !channelDynamicProp.length) return {}
    return channelDynamicProp.reduce((acc, channelProps = {}) => {
        const { ID, physicalChannelsDynamicProperties = [] } = channelProps || {}
        if (ID && physicalChannelsDynamicProperties.length > 0) {
            acc[ID] = physicalChannelsDynamicProperties[0].ID
        }
        return acc
    }, {})
}
