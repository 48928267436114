import { Tile } from './Tile'
import Constants from '@typings/constants'
import { IChannel, IMulticam } from '@typings/tile'
import { SOURCE_MODE } from 'enums'
import { HashMap, ValueOf } from '@typings/generic'

/**
 * @property {Boolean} isBlockedChannel indicates if channel is restricted or not
 * @property {String|Number} platformId Platform (Client) Channel identifier
 * @property {String|Number} technicalId Technical Id of the channel, not always defined
 * @property {String} name
 * @property {Array} [programs=[]]
 * @property {String} logo
 * @property {String} largeLogo
 * @property {String} smallLogo
 * @property {Boolean} [recordable=false]
 * @property {Boolean} [timeshiftOnDemand=false] allow timeshift by restarting the LIVE content in timeshift Mode;
 * This right is different than regular timeshift which is available from the LIVE steam
 * @property {Boolean} shareable
 * @property {Boolean} startOver
 * @property {Boolean} catchup
 * @property {Array<String>} parentIds
 * @property {String} type
 * @property {Number} position
 * @property {Boolean} isAlternateBlackout
 * @property {String} blackoutUrlComplement
 * @property {Boolean} geoLocationEnabled
 * @property {Boolean} adNetwork
 * @property {String} nationalAds
 * @property {Array<String>} network
 * @property {Array<String>|String} productIds
 * @property {Boolean} canWatch
 * @property {String} folder
 * @property {Boolean} [favorite=false]
 * @property {String} favoriteId
 * @property {Number} channelNumber
 * @property {Number} rollingBufferCount
 * @property {Number} playbackId Can be used on some backend to handle player session (Nagra)
 * @property {Number} playbackUrl
 * @property {String|null} [rollingBufferId=null]
 * @property {Number} [rollingBufferDuration=0] In hours
 * @property {Boolean} [rollingBufferRetrieved=false]
 * @property {Boolean} [rollingBufferPending=false]
 * @property {Array<String>} [genres=[]] Genres of the channel
 * @property {Array<String>} [programsGenres=[]] Genres of the channel's live programs
 * @property {Boolean} [pending=false] Indicates whether program data is being fetched
 * @property {String} [drmProvider='native'] Indicates type of DRM for this channel (native means supported by the backend operator)
 * @property {Date} [availabilityStart=undefined] Availability Date (Not provided by all the backends)
 * @property {Date} [availabilityEnd=undefined] Availability Date (Not provided by all the backends)
 * @property {Boolean} [iptvChannel=undefined] Flag indicating that the channel is an IPTV Channel (Not provided by all the backends)
 * @property {Boolean} [ottChannel=undefined] Flag indicating that the channel is an OTT Channel (Not provided by all the backends)
 * @property {String} [ratingId] Rating ID for future use as there is not parental control per channel but per program on current product (Not provided by all the backends)
 * @property {Number} [pcValue] PC Value for future use as there is not parental control per channel but per program on current product (Not provided by all the backends)
 * @property {Boolean} [adEnable=false] Channel supports Ads (used for integration with the smartlib today)
 * @property {String} [channelFilter=''] Use for channel list filtering locally in the store
 * @property {Array<Channel.SOURCE_MODE>} [sourceMode=[]] This is a source of the Channel, use for filtering via a setting, either for Censored/Uncensored Channels
 * @property {Boolean} [isKidsChannel=false] Indicates if the channel is exclusive for kids
 * @property {analyticsProperties} analytics
 */
export class Channel extends Tile {
    isBlockedChannel: boolean
    platformId: string | number
    name: string
    logo: string
    largeLogo: string
    smallLogo: string
    timeshiftOnDemand: boolean
    shareable: boolean
    startOver: boolean
    catchup: boolean
    parentIds: string[]
    type: string
    position: number
    isAlternateBlackout: boolean
    blackoutUrlComplement: string
    geoLocationEnabled: boolean
    adNetwork: boolean
    nationalAds: string
    network: string[]
    canWatch: boolean
    folder: string
    favoriteId: string
    channelNumber: number
    rollingBufferCount: number
    ratingId: string
    pcValue: number
    programs: any[]
    recordable: boolean
    productIds: string[] | string
    favorite: boolean
    rollingBufferId: string | null
    rollingBufferDuration: number
    rollingBufferRetrieved: boolean
    rollingBufferPending: boolean
    genres: string[]
    programsGenres: string[]
    pending: boolean
    platformProperties: HashMap<string, (string | number)[]>
    playbackId: number
    playbackUrl: string
    drmProvider: string
    eligibility: string[]
    availabilityStart: string
    availabilityEnd: string
    adEnabled: boolean
    analytics: {
        parameters: string
    }
    modelType: ValueOf<typeof Constants.programType>
    channelFilter: string
    isKidsChannel: boolean
    sourceMode: `${SOURCE_MODE}`[]
    sourceId: number | string
    technicalId: number | string
    multiCamRef: IMulticam[]

    constructor(props: IChannel) {
        super(props)

        this.isBlockedChannel = props.isBlockedChannel
        this.platformId = props.platformId
        this.technicalId = props.technicalId
        this.name = props.name
        this.logo = props.logo
        this.largeLogo = props.largeLogo
        this.smallLogo = props.smallLogo
        this.timeshiftOnDemand = props.timeshiftOnDemand
        this.shareable = props.shareable
        this.startOver = props.startOver
        this.catchup = props.catchup
        this.parentIds = props.parentIds
        this.type = props.type
        this.position = props.position
        this.isAlternateBlackout = props.isAlternateBlackout
        this.blackoutUrlComplement = props.blackoutUrlComplement
        this.geoLocationEnabled = props.geoLocationEnabled
        this.adNetwork = props.adNetwork
        this.nationalAds = props.nationalAds
        this.network = props.network
        this.canWatch = props.canWatch
        this.folder = props.folder
        this.favoriteId = props.favoriteId
        this.channelNumber = props.channelNumber
        this.rollingBufferCount = props.rollingBufferCount
        this.ratingId = props.ratingId
        this.pcValue = props.pcValue

        const computePropValue = this._makeComputePropValue(props)

        this.programs = computePropValue('programs', [])
        this.recordable = computePropValue('recordable', false)
        this.productIds = computePropValue('productIds', [])
        this.favorite = computePropValue('favorite', false)
        this.rollingBufferId = computePropValue('rollingBufferId', null)
        this.rollingBufferDuration = computePropValue('rollingBufferDuration', 0)
        this.rollingBufferRetrieved = computePropValue('rollingBufferRetrieved', false)
        this.rollingBufferPending = computePropValue('rollingBufferPending', false)
        this.genres = computePropValue('genres', [])
        this.programsGenres = computePropValue('programsGenres', [])
        this.pending = computePropValue('pending', false)
        /**
         * Contains miscellaneous properties provided by the platform.
         *
         * Key could be assimilated as Property Name (or "type" for Partner)
         * Value is always an array containing 1 to several values
         *
         * Mainly implemented for Partner, but could be usefull for another platform (#ouPas).
         */
        this.platformProperties = computePropValue('platformProperties', {})
        this.playbackId = computePropValue('playbackId', '')
        this.playbackUrl = computePropValue('playbackUrl', '')
        this.productIds = Array.isArray(this.productIds) ? this.productIds : [this.productIds]
        this.drmProvider = computePropValue('drmProvider', Channel.DRM_PROVIDER.NATIVE)
        this.eligibility = computePropValue('eligibility', [])
        this.availabilityStart = computePropValue(
            'availabilityStart',
            Channel.FAKE_AVAILABILITY_START_DATE
        )
        this.availabilityEnd = computePropValue(
            'availabilityEnd',
            Channel.FAKE_AVAILABILITY_END_DATE
        )
        this.adEnabled = computePropValue('adEnabled', false)
        this.analytics = computePropValue('analytics', {})
        this.format = 'landscape'
        this.modelType = 'channel'
        this.channelFilter = computePropValue('channelFilter', '')
        this.isKidsChannel = computePropValue('isKidsChannel', false)
        this.sourceMode = computePropValue('sourceMode', [])
        this.sourceId = computePropValue('sourceId', null)

        /**
         * Don't display muticam items which has missing keys
         */
        this.multiCamRef = []
        if (props.multiCamRef && props.multiCamRef.length > 0) {
            props.multiCamRef.map((item) => {
                if (item.platform_id && item.cam_label && item.uri && item.drmId) {
                    this.multiCamRef.push(item)
                }
            })
        }
    }

    //  If information not provided fake availability to prevent any upper code on this argument
    static FAKE_AVAILABILITY_START_DATE = new Date(Date.now())
    static FAKE_AVAILABILITY_END_DATE = new Date(Date.now() + 315360000000)

    static DRM_PROVIDER = {
        // Native means the drm is supported natively by the operator backend
        NATIVE: 'native',
        // Remaining DRM means external DRM and needs to be loaded using external servers
        MEDIA_CORP: 'mediacorp',
    }

    static SOURCE_MODE = {
        CENSORED_CHANNEL: 'censored',
        UNCENSORED_CHANNEL: 'uncensored',
    }

    /**
     * @return {Boolean}
     */
    isWatchable() {
        return !!this.canWatch
    }

    /**
     * @return {Boolean} Hardcoded: `true`
     */
    isChannel() {
        return true
    }

    /**
     * @return {Boolean}
     */
    isFavorite() {
        return !!this.favorite
    }

    /**
     * @return {Boolean}
     */
    isRecordable() {
        return !!this.recordable
    }

    /**
     * @return {Boolean}
     */
    isStartOverPermitted() {
        return this.rollingBufferDuration > 0
    }

    /**
     * Checks if the channel has replay content
     * @returns {Boolean}
     */
    isReplayAvailable() {
        return this.rollingBufferDuration > 0
    }

    /**
     * @return {Boolean}
     */
    isVirtualChannel() {
        return false
    }

    /**
     * @return {Boolean}
     */
    isMosaicChannel() {
        return false
    }

    /**
     * @returns {Number} The timestamp (in ms) of the cutoff point of the rolling buffer (i.e the start)
     */
    getRollingBufferCutOff() {
        return Date.now() - this.rollingBufferDuration * 60 * 60 * 1000
    }

    /**
     * Check into known platformProperties to match a property name which contains the provided value.
     *
     * @example
     * console.log(channel.platformProperties); // { color: ['red', 'green', 'blue'] }
     * channel.hasPlatformPropertyValue('color', 'green') // true
     * channel.hasPlatformPropertyValue('color', 'purple') // false
     * channel.hasPlatformPropertyValue('flavor', 'chocolate') // false
     *
     * @param {String} name the property name to look into
     * @param {*} value the property value to match/to find
     * @returns {Boolean}
     */
    hasPlatformPropertyValue(name: string, value: string | number) {
        if (!this.platformProperties[name]) return false

        const values = this.platformProperties[name]

        // let's assume that value is a primitive (ie: not an Array or an Object), otherwise
        // comparison will never work (tips: use of JSON.stringify if one day it's necessary)
        return values.some((v) => v === value)
    }
}
