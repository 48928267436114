import sortBy from 'lodash.sortby'
import {COLLECTIONS, EPG_GENRES} from '@ifs/volt-store/lib/constants'

export const getGuideDataSelector = (s: any) => {
    const channelsData: Record<any, any> = s.channels.data
    const productsData: any = s.products.data
    const channelsFilteringCriteria: any = s.channels.filteringCriteria
    const kidsChannels: any = s.channels?.filter?.((channel: any) => channel?.isKidsChannel)
    const isKidProfile: any = s.profile?.isKidProfile

    if (channelsData) {
        const channelsState = Object.values(channelsData).reduce((acc, channel) => {
            if (isKidProfile) {
                if (kidsChannels?.length > 0) {
                    if (channel.isKidsChannel) {
                        // isChannelWatchable(channel, products) might be added in future
                        return {...acc, [channel.id]: channel}
                    }
                    return acc
                }
                return isChannelWatchable(channel, productsData) ? {...acc, [channel.id]: channel} : acc
            }
            if (
                !channel.channelFilter ||
                !channelsFilteringCriteria ||
                !channelsFilteringCriteria.length ||
                channelsFilteringCriteria.includes(channel.channelFilter)
            ) {
                return {...acc, [channel.id]: channel}
            }
            return acc
        }, {})

        if (channelsState) {
            const channelList = sortBy(Object.values(channelsState), (x: any) => x.channelNumber)
            const genre = s.epg.genre
            const favoriteChannels = channelList?.filter?.((channel: any) => channel.favorite)
            
            let filteredChannelList
            if (!genre) {
                filteredChannelList = channelList?.filter((channel: any) => !channel.isMosaicChannel())
            } else if (genre === EPG_GENRES.FAVORITES) {
                filteredChannelList = favoriteChannels
            } else if (genre === EPG_GENRES.SUBSCRIBED) {
                filteredChannelList = favoriteChannels?.filter((channel: any) => isChannelWatchable(channel, productsData))
            } else {
                filteredChannelList = channelList.filter(
                    (channel: any) => channel.genres.includes(genre) && !channel.isMosaicChannel()
                )
            }

            const filteredChannelIds = filteredChannelList?.map(({id}: any) => id)

            const collection = s.collections[COLLECTIONS.liveNow]
            let selectLiveNow
            if (collection) {
                selectLiveNow = collection.createPopulatedCopy((programId: any) => {
                    const p = s.programs[programId]
                    return isLiveContent(p) ? p : channelsState[programId]
                })
            }

            return {
                ...selectLiveNow,
                children: selectLiveNow?.children?.filter((liveNowProgram: any) => {
                    return (
                        (liveNowProgram.channelId && filteredChannelIds?.includes(liveNowProgram.channelId)) ||
                        (liveNowProgram.id && filteredChannelIds?.includes(liveNowProgram.id))
                    )
                }),
            }
        } else {
            return {}
        }
    } else {
        return {}
    }
}

const isLiveContent = (program: any) => {
    return program?.isLive?.() || program?.isVirtualContent?.()
}
export const isChannelWatchable = (channel: any, products: any) =>
    (channel.isVirtualChannel() && !!channel.videoUrl) ||
    (!channel.isVirtualChannel() && channel.productIds.length === 0) ||
    getProductsFromChannel(channel, products).some((product: any) => product.isEntitledNow())

export const getProductsFromChannel = (channel: any, products: any) =>
    channel.productIds.reduce((acc: any, productId: any) => {
        const product = products[productId]

        if (product && product.isSubscription()) {
            acc.push(product)
        }
        return acc
    }, [])
