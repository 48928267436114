import DataHelper from 'framework/helpers/data'
import { forkJoin, of, throwError } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'
import VoltError from 'VoltError'
import { dvrFactory, dvrFactoryV2, dvrQuotaFactory } from '../Factories'
import Fetch from '../fetch'
import HuaweiTypes from '../HuaweiTypes'
import { DvrSeries } from 'models'

export default class RecordingsApi extends Fetch {
    //#region GET METHODS
    /**
     * Get all recordings
     *
     * NOTE: using Huawei V2 API for being able to retrieve series episodes in one request
     *
     * @returns {Observable<Array>}
     */
    getEventRecordings = ({ count = 20 } = {}) => {
        let offset = 0
        return this.recursiveFetch(
            (_, count, offset) => {
                return this.fetch({
                    url: `${DataHelper.getInstance().getData(
                        DataHelper.STORE_KEY.BACKEND_API_URL
                    )}/EPG/JSON/QueryPVR`,
                    method: 'POST',
                    body: {
                        count,
                        offset,
                        expandSubTask: 2,
                    },
                    log: `GET ALL PVRs`,
                })
            },
            { count, offset, responseResource: 'pvrlist' }
        ).pipe(
            mergeMap((pvrs = []) => {
                if (!pvrs.length) return of({})
                return of(
                    pvrs
                        .map((pvr) => dvrFactoryV2({ dvr: pvr, config: this.config }))
                        .filter(Boolean)
                )
            })
        )
    }

    /**
     * @returns {Observable<Array>}
     */
    getSeriesRecordings() {
        return of([])
    }

    /**
     * Get recordings by ids
     *
     * For Huawei service we don't need to additionally check if the content for DVR is series because
     * `getEventRecordings()` returns all the DVRs. So for the store side in `refreshOngoingRecordingsEpic()`
     * we can save extra API call for series DVRs by returning an empty array if `isSeries === true`
     *
     * Also we can save some bandwidth by calling `getEventRecordingById()` if there is the only one element
     * in the `ids` array
     * @returns {Observable<Array>}
     */
    getRecordingsData = ({ ids, isSeries }) => {
        if (isSeries) return of([])
        if (ids.length === 1) return this._getEventRecordingById(ids[0])
        return this.getEventRecordings().pipe(
            map((recordings) => recordings.filter(({ id }) => ids.includes(id)))
        )
    }

    //#endregion

    //#region SET METHODS
    /**
     * Set event-based recording
     * @returns {Observable}
     */
    setEventRecording = ({ id, platformChannelId } = {}) =>
        this.fetch({
            url: `${DataHelper.getInstance().getData(
                DataHelper.STORE_KEY.BACKEND_API_URL
            )}/VSP/V3/AddPVR`,
            method: 'POST',
            body: {
                PVR: {
                    storageType: HuaweiTypes.storageTypes.NPVR,
                    policyType: HuaweiTypes.policyTypes.PlaybillBased,
                    channelID: platformChannelId,
                    playbillID: id,
                },
            },
            log: `CREATE AN EVENT RECORDING TASK`,
        }).pipe(mergeMap(({ response } = {}) => this._getEventRecordingById(response.PVRID)))

    /**
     * Set series recording
     * @returns {Observable}
     */
    setSeriesRecording = ({ id, platformChannelId }) =>
        this.fetch({
            url: `${DataHelper.getInstance().getData(
                DataHelper.STORE_KEY.BACKEND_API_URL
            )}/VSP/V3/AddPeriodicPVR`,
            method: 'POST',
            body: {
                PVR: {
                    storageType: HuaweiTypes.storageTypes.NPVR,
                    policyType: HuaweiTypes.policyTypes.Series,
                    channelID: platformChannelId,
                    seriesID: id,
                },
            },
            log: `CREATE A SERIES RECORDING TASK`,
        }).pipe(
            mergeMap(({ response } = {}) =>
                forkJoin([
                    this._getEventRecordingById(response.periodicPVRID),
                    this._getPvrSeriesEpisodes({ pvrId: response.periodicPVRID }),
                ]).pipe(
                    mergeMap(([parentPvrTask = {}, subPvrTasks = []]) => {
                        return of({
                            series: new DvrSeries({
                                id: parentPvrTask.seriesId,
                                originalAssetTitle: parentPvrTask.title,
                            }),
                            episodes: subPvrTasks.pvrlist.map((pvr) =>
                                dvrFactoryV2({ dvr: pvr, config: this.config })
                            ),
                        })
                    })
                )
            )
        )

    //#endregion

    //#region EDIT METHODS
    /**
     * Cancel recording
     * @returns {Observable}
     */
    // cancelRecording = ({ id, isSeries, seriesId } = {}) => {
    //     let body = isSeries ? { periodicPVRIDs: [seriesId] } : { singlePVRIDs: [id] }
    //     return this.fetch({
    //         url: `${DataHelper.getInstance().getData(
    //             DataHelper.STORE_KEY.BACKEND_API_URL
    //         )}/VSP/V3/CancelPVRByID`,
    //         method: 'POST',
    //         body,
    //         log: `CANCEL THE RECORDING`,
    //     }).pipe(mergeMap(({ response } = {}) => of(true)))
    // }

    /**
     * Cancel recording
     * @returns {Observable}
     */
    //As cancel recording not working so used delete recording in cancel recording. And commented old code
    cancelRecording = ({ id, isSeries } = {}) => {
        let body = isSeries ? { periodicPVRIDs: [id] } : { singlePVRIDs: [id] }
        return this.fetch({
            url: `${DataHelper.getInstance().getData(
                DataHelper.STORE_KEY.BACKEND_API_URL
            )}/VSP/V3/DeletePVRByID`,
            method: 'POST',
            body,
            log: `CANCEL THE RECORDING`,
        }).pipe(mergeMap(({ response } = {}) => of(true)))
    }

    /**
     * Delete recording
     * @returns {Observable}
     */
    deleteRecording = ({ id, isSeries } = {}) => {
        let body = isSeries ? { periodicPVRIDs: [id] } : { singlePVRIDs: [id] }
        return this.fetch({
            url: `${DataHelper.getInstance().getData(
                DataHelper.STORE_KEY.BACKEND_API_URL
            )}/VSP/V3/DeletePVRByID`,
            method: 'POST',
            body,
            log: `DELETE THE RECORDING`,
        }).pipe(mergeMap(({ response } = {}) => of(true)))
    }

    /**
     * @returns {Observable}
     */
    modifyRecordingType = () => {
        return throwError(new VoltError(VoltError.codes.MISSING_API_METHOD_IMPLEMENTATION))
    }

    //#endregion

    //#region TOOLBOX
    /**
     * Returns recording data by its id
     *
     * NOTE: using Huawei V2 API for being able to retrieve series episodes in one request
     *
     * @param {string} recordingId
     * @returns
     */
    _getEventRecordingById = (recordingId) => {
        return this.fetch({
            url: `${DataHelper.getInstance().getData(
                DataHelper.STORE_KEY.BACKEND_API_URL
            )}/EPG/JSON/QueryPVRById`,
            method: 'POST',
            body: {
                pvrIds: recordingId,
            },
            log: `GET PVR BY ID`,
        }).pipe(
            mergeMap(({ response } = {}) => {
                if (!response || !response.pvrlist || !response.pvrlist.length) return of({})
                return of(dvrFactoryV2({ dvr: response.pvrlist[0], config: this.config }))
            })
        )
    }

    /**
     * Returns recording data by its id
     *
     * NOTE: using Huawei V6 API for being able to retrieve file ID for a playback
     *
     * @param {string} recordingId
     * @returns
     */
    getEventRecordingByIdV6 = (recordingId) => {
        return this.fetch({
            url: `${DataHelper.getInstance().getData(
                DataHelper.STORE_KEY.BACKEND_API_URL
            )}/VSP/V3/QueryPVRByID`,
            method: 'POST',
            body: {
                ID: recordingId,
            },
            log: `GET PVR BY ID`,
        }).pipe(
            mergeMap(({ response } = {}) => {
                if (!response || !response.PVR) return of({})
                return of(dvrFactory(response.PVR))
            })
        )
    }

    /**
     * Returns parent's pvr task sub-tasks (episodes pvr tasks)
     *
     * NOTE: using Huawei V2 API
     *
     * @param {object} args
     * @param {string} args.pvrId
     * @returns
     */
    _getPvrSeriesEpisodes = ({ pvrId }) => {
        return this.fetch({
            url: `${DataHelper.getInstance().getData(
                DataHelper.STORE_KEY.BACKEND_API_URL
            )}/EPG/JSON/QuerySubPVR`,
            method: 'POST',
            body: {
                periodPVRId: pvrId,
            },
            log: `GET PVR SERIES SUB-TASKS`,
        }).pipe(mergeMap(({ response } = {}) => of(response)))
    }

    /**
     * Get recording storage space
     * @returns {Observable}
     */
    getStorage() {
        return this.fetch({
            url: `${DataHelper.getInstance().getData(
                DataHelper.STORE_KEY.BACKEND_API_URL
            )}/VSP/V3/QueryPVRSpace`,
            method: 'POST',
            body: {
                storageType: HuaweiTypes.storageTypes.NPVR,
            },
            log: `GET PVR SPACE`,
        }).pipe(
            mergeMap(({ response } = {}) => {
                if (!response || !response.NPVRStorage)
                    return throwError('Unable to retrieve pvr space')
                return of(dvrQuotaFactory(response.NPVRStorage))
            }),
            catchError((error) => {
                this.logger.error(`[GET PVR SPACE] ${error}`)
                return of(
                    dvrQuotaFactory({
                        totalSize: 0,
                        usedSize: 0,
                    })
                )
            })
        )
    }
    //#endregion
}
