import {FocusableGroup} from '../../spatialNavigation'
import {isPlatform} from '../../utils'

interface FocusGroupProviderProps {
    focusKey: string
    children: any
    id?: string
    onFocus?: any
    saveLastFocusedChild?: boolean,
    class?: string,
    isFocusBoundary?: boolean
    preferredChildFocusKey?: string,
    style?: any,
    onArrowPress?: any,
}

export const FocusGroupProvider = (props: FocusGroupProviderProps) => {
    if (isPlatform('laptop')) {
        if(props.class){
            return (
            <div class={props.class}>
                {props.children}
            </div>)
        }else{  
            return props.children
        }
    } else {
        return (
            <FocusableGroup
                as="div"
                focusKey={props.focusKey}
                trackChildren={true}
                id={props.id}
                onFocus={props.onFocus}
                class={props.class}
                saveLastFocusedChild={props.saveLastFocusedChild ?? false}
                isFocusBoundary = {props.isFocusBoundary}
                preferredChildFocusKey ={props?.preferredChildFocusKey}
                style={props?.style}
                onArrowPress={props?.onArrowPress}
            >
                {({hasFocusedChild}) => <>{props.children}</>}
            </FocusableGroup>
        )
    }
}