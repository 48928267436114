import Fetch from '../fetch'
import VoltError from 'VoltError'

const themeProgrammationMetadata = ['emiId', 'type', 'fromTimestamp', 'fromDate']
//Note:03.25.2021:Matthieu:themeCustomMetadata is a temprorary hack, to ensure back compatibility
// with previous version of emi theme (ie without dynamic properties)
const themeCustomMetadata = ['default_locale', 'focusColor']

export default class ThemeModule extends Fetch {
    /**
     * @param {String} population Targeted population
     * @returns {AjaxObservable}
     */
    getConfig = (population) => this.getEmiConfig(population)

    //Todo move this is function in a helper and add unit test
    convertRGBToHex = (color) => {
        if (!color) return undefined
        const rgb = color.split(',')
        if (rgb.length === 1) {
            //this is not a rgb color
            return color
        }
        if (rgb.length > 3) {
            //remove alpha
            rgb.pop()
        }

        const hex =
            '#' +
            rgb
                .map((x) => {
                    x = parseInt(x, 10).toString(16) //Convert to a base16 string
                    return x.length === 1 ? '0' + x : x //Add zero if we get only one character
                })
                .join('')
        return hex
    }

    parseEmiConfig = (fullConfig) => {
        if (!fullConfig) {
            throw new VoltError(VoltError.codes.MISSING_EMI_CONFIG, {
                message: `No EMI configuration was found`,
            })
        }
        const moduleData = fullConfig.moduleData

        let programmingsConfig =
            moduleData &&
            moduleData.themes.reduce((acc, theme) => {
                const filteredThemeValues = Object.keys(theme)
                    .filter(
                        (key) =>
                            !themeProgrammationMetadata.includes(key) &&
                            !themeCustomMetadata.includes(key)
                    )
                    .reduce((filteredTheme, key) => {
                        filteredTheme[key] = theme[key]
                        return filteredTheme
                    }, {})
                //convert timestamp to ms
                acc[theme.fromTimestamp * 1000] = {
                    theme: {
                        ...filteredThemeValues,
                        locale: theme.default_locale,
                        focusColor: this.convertRGBToHex(theme.focusColor),
                        // Todo there should not be any specific handling of emi properties here in store
                        backgroundColor: this.convertRGBToHex(theme.backgroundColor),
                    },
                    // useful to expose the programming identifier which leads to this config
                    programmingId: theme.emiId,
                }
                return acc
            }, {})

        // When EMI file is reset then an empty EMI module file is created. Fake an empty programming to avoid infinite spinner at boot heyhey
        if (!programmingsConfig) {
            programmingsConfig = {
                [Date.now()]: {
                    theme: {},
                    programmingId: 99999999,
                },
            }
        }

        return {
            programmings: programmingsConfig,
            polling: fullConfig && fullConfig.config,
            revision: fullConfig && fullConfig.revision,
        }
    }

    _makeConfigParser = (ageLimit) => (config) => {
        return this.parseEmiConfig(config)
    }
}
