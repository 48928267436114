import {ComponentProps, createEffect} from 'solid-js'
import {AppProviders} from './AppProviders'
import {RouteDefinition} from '@solidjs/router'
import {StoreOptions} from '../store'
import {ToastPosition} from './toast/Toast'

interface AppBootstrapProps extends ComponentProps<any> {
    storeOptions: StoreOptions
    defaultConfig: any
    children?: any
    routes: RouteDefinition[]
    icons?: {[k: string]: any}
    toastPosition: ToastPosition
    ignoredNotification: string[]
    getLanguage:(locale: string) => {}

}

export const AppBootstrap = (props: AppBootstrapProps) => {
    createEffect(() => {})
    return (
        <AppProviders
            ignoredNotification={props.ignoredNotification}
            storeOptions={props.storeOptions}
            defaultConfig={props.defaultConfig}
            routes={props.routes}
            icons={props.icons}
            toastPosition={props.toastPosition}
            getLanguage={props.getLanguage}
        >
            {props.children}
        </AppProviders>
    )
}
