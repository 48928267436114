export const IMAGE_TYPE = {
    LOGO: 0,
    LOGO_SMALL: 1,
    LOGO_MEDIUM: 2,
    LOGO_BIG: 3,
    THUMBNAIL: 4,
    BACKDROP: 5,
    BARKER: 6,
    LOGO_SUBSCRIBED: 10,
    LOGO_NONSUBSCRIBED: 11,
}

/**
 * Rretrieve first picture of a given type
 * @param {Array<Object<string, string>>} pictures array retrieved from proxy
 * @param {IMAGES} type to search for
 */
export const getImage = (pictures = [], type) => {
    const images = pictures.filter((pic) => pic.type === type)
    return images && images.length ? images[0].url : ''
}
