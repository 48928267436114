import Fetch from '../fetch'
import VoltError from 'VoltError'

const parameterProgrammationMetadata = ['emiId', 'type', 'fromTimestamp', 'fromDate']

export default class ParameterModule extends Fetch {
    /**
     * @param {String} population Targeted population
     * @returns {AjaxObservable}
     */
    getConfig = (population) => this.getEmiConfig(population)

    parseEmiConfig = (fullConfig) => {
        if (!fullConfig) {
            throw new VoltError(VoltError.codes.MISSING_EMI_CONFIG, {
                message: `No EMI configuration was found`,
            })
        }
        const moduleData = fullConfig.moduleData

        let programmingsConfig =
            moduleData &&
            moduleData.parameters.reduce((acc, parameter) => {
                const filteredParameterValues = Object.keys(parameter)
                    .filter((key) => !parameterProgrammationMetadata.includes(key))
                    .reduce((filteredParameter, key) => {
                        filteredParameter[key] = parameter[key]
                        return filteredParameter
                    }, {})
                //convert timestamp to ms
                acc[parameter.fromTimestamp * 1000] = {
                    parameter: {
                        ...filteredParameterValues,
                    },
                    // useful to expose the programming identifier which leads to this config
                    programmingId: parameter.emiId,
                }
                return acc
            }, {})

        // When EMI file is reset then an empty EMI module file is created. Fake an empty programming to avoid infinite spinner at boot
        if (!programmingsConfig) {
            programmingsConfig = {
                [Date.now()]: {
                    parameter: {},
                    programmingId: 99999999,
                },
            }
        }

        return {
            programmings: programmingsConfig,
            polling: fullConfig && fullConfig.config,
            revision: fullConfig && fullConfig.revision,
        }
    }

    _makeConfigParser = (ageLimit) => (config) => {
        return this.parseEmiConfig(config)
    }
}
