import { ITile } from '@typings/tile'
import { FORMAT } from 'enums'
import { Abstract } from './Abstract'

export class Tile extends Abstract {
    id: string
    title: string
    description?: string
    proxyId?: number
    /**
     * @description width / height
     */
    ratio?: number
    backdrop: string
    hasOverloadedBackdrop: boolean
    thumbnail: string
    hasOverloadedThumbnail: boolean
    format: `${FORMAT}`
    permitOutOfHome: boolean

    constructor(props: ITile) {
        super()

        this.id = `${props.id}`
        this.title = props.title
        this.description = props.description
        this.proxyId = props.proxyId
        this.ratio = props.ratio

        const _computePropValue = this._makeComputePropValue(props)

        this.backdrop = _computePropValue(
            'backdrop',
            props.largeLogo || props.logo // Note: large logo is only filled for Channel
        )
        this.hasOverloadedBackdrop = _computePropValue('hasOverloadedBackdrop', false)
        this.thumbnail = _computePropValue(
            'thumbnail',
            props.largeLogo || props.logo // Note: large logo is only filled for Channel
        )
        this.hasOverloadedThumbnail = _computePropValue('hasOverloadedThumbnail', false)
        this.format = _computePropValue('format', Tile.FORMAT.PORTRAIT)
        this.permitOutOfHome = _computePropValue('permitOutOfHome', true)
    }

    static FORMAT = FORMAT

    isLandscape() {
        return false
    }

    isPortrait() {
        return false
    }

    isProgram() {
        return false
    }

    isProduct() {
        return false
    }

    isChannel() {
        return false
    }

    isVod() {
        return false
    }

    isDvr() {
        return false
    }

    isDvrSeries() {
        return false
    }

    isMovie() {
        return false
    }

    isTvShow() {
        return false
    }

    isSeason() {
        return false
    }

    isEpisode() {
        return false
    }

    isOrphanEpisode() {
        return false
    }

    isLive() {
        return false
    }

    isPastLive() {
        return false
    }

    isLiveTvShow() {
        return false
    }

    isRestricted() {
        return false
    }

    isApp() {
        return false
    }

    isDeeplink() {
        return false
    }

    isYoutube() {
        return false
    }

    isInternalLink() {
        return false
    }

    isVirtualContent() {
        return false
    }

    isChannelAppProgram() {
        return false
    }

    isActor() {
        return false
    }

    isSoccerSquadPerson() {
        return false
    }

    isPerson() {
        return false
    }

    isSoccerFixture() {
        return false
    }
}
