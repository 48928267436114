import Fetch from '../fetch'
import Constants from 'api-constants'
import { of, throwError } from 'rxjs'
import { has } from 'lodash'
import { mergeMap, switchMap, catchError, tap, retryWhen } from 'rxjs/operators'
import ConfigHelper from 'framework/helpers/config'
import DataHelper from 'framework/helpers/data'
import VoltError from 'VoltError'
import * as Factories from '../Factories'
import HuaweiTypes from '../HuaweiTypes'
import TokenHelper from 'framework/helpers/token'
import CookiesHandler from '../cookies'
import { serialize } from 'helpers/index.js'
import { Profile } from 'models'
import {
    resolveDeviceAttributes,
    useApiLoginV2,
    encryptPassword,
    generateAuthenticator,
    getLanguageMappingApiV2,
    resolveBackendRatingId,
    resolveFamilyRole,
} from '../Helpers'
import retryStrategy from 'helpers/retryStrategy'

/**
 * To send heartbeat request just after the login to get the next delay value
 */
const HEARTBEAT_LOGIN_DELAY_AFTER_LOGIN_MS = 1000

/**
 * Huawei authentication APIs
 */
export default class AuthApi extends Fetch {
    constructor(config, otherApis = {}) {
        super(config, otherApis)
        this.heartbeatHelper = new TokenHelper(
            this.refreshLoginHeartbeat.bind(this, { forceReloginInCaseOfFailure: false }),
            DataHelper.STORE_KEY.SESSION_TOKEN,
            this.logger
        )
        this.deviceApi = otherApis.deviceApi
        Fetch.setAuthApi(this)
        this._isSilentRelogin = false
    }

    /**
     * Retrieves the profile by using authentication token in cache
     * Called systematically by the UI at boot for seeking after authentication skipping authentication screen
     * If the token is missing or invalid, then throw an error to force the UI for authentication
     * @param {Object} data
     * @param {String} [data.language]
     * @param {Object} [data.deviceData] optional device data (brand, model, serial number, casn, etc...)
     * @returns {Observable<Profile>|Observable<Array<Profile>>}
     */
    getProfile({ deviceData, language } = {}) {
        if (!this._getHasAlreadyLoginOnce()) {
            this.logger.info('No login from boot, fetch profile from login...')

            return this._authenticateFromCache({ deviceData, language }).pipe(
                mergeMap(() => this.fetchProfile(true))
            )
        }

        return this.fetchProfile()
    }

    /**
     * Fetch the profiles
     * @param {Boolean} fromCache fetch all the profiles information, light profiles otherwise
     * @returns {Observable<Array<Profile>>}
     */
    fetchProfile(fromCache = false) {
        const profilesFromCache = this._getProfilesFromCache()
        return (
            fromCache && profilesFromCache.length ? of(profilesFromCache) : this._queryProfiles()
        ).pipe(
            mergeMap((profiles = []) => of(this._updateSelectedProfile(profiles))),
            catchError((error) => {
                if (!fromCache && profilesFromCache.length) {
                    this.logger.warn(
                        `Cannot fetch profile use, profile from cache (already fetched from Login Authenticate)`
                    )

                    return of(this._updateSelectedProfile(profilesFromCache))
                }

                return throwError(error)
            })
        )
    }

    /**
     * Generic switch method to create&switch to new profile or just switch to profile
     * @param {Object} data
     * @param {Profile} data.profile user profile
     * @param {String} data.password Password
     * @param {String} data.encryptedPassword Encrypted password (Only Huawei V2)
     * @param {Boolean} isLocalProfile flag to chose logic to create account or just to switch to another account
     * @returns {Observable<Array<Profile>>}
     */
    switchProfile({
        profile,
        password = HuaweiTypes.defaultPassword,
        encryptedPassword,
        isLocalProfile = false,
    }) {
        if (!profile) {
            return throwError(new VoltError(VoltError.codes.INVALID_REQUEST_PAYLOAD))
        }
        const _encryptedPassword = has(profile, 'namedProperties.encryptedPassword')
            ? profile.namedProperties.encryptedPassword
            : encryptedPassword
        return (
            isLocalProfile
                ? this.createDummyKidsProfile({
                      profile,
                      password,
                  })
                : this._switchProfile({
                      profileId: profile.profileId,
                      password,
                      encryptedPassword: _encryptedPassword,
                  })
        ).pipe(
            switchMap(() => this.fetchProfile()),
            catchError((error) => {
                if (error.code === VoltError.codes.UNKNOWN_API_ERROR.code) {
                    return throwError(
                        new VoltError(VoltError.codes.USER_CANNOT_PROFILE_SWITCH, {
                            inheritedError: error,
                        })
                    )
                }

                return throwError(error)
            })
        )
    }

    /**
     * Switch User profile
     * @param {String} profileId Profile ID
     * @param {String} [password] Password
     * @param {String} [encryptedPassword] Encrypted password (Only Huawei V2)
     * @returns {Observable<Array<Profile>>}
     */
    _switchProfile({ profileId, password, encryptedPassword }) {
        this.logger.info(`Switch profile to profileId ${profileId}...`)

        let body = {
            profileID: profileId,
        }
        if (encryptedPassword) {
            body = {
                ...body,
                password: encryptedPassword,
            }
        } else if (password) {
            const useLoginV2 = useApiLoginV2()
            body = {
                ...body,
                password: useLoginV2 ? encryptPassword(password) : password,
            }
        }

        return this.fetch({
            url: `${DataHelper.getInstance().getData(
                DataHelper.STORE_KEY.BACKEND_API_URL
            )}/VSP/V3/SwitchProfile`,
            method: 'POST',
            body,
            log: 'SWITCH PROFILE',
        }).pipe(
            mergeMap(() =>
                // Save the last selected profile ID to switch to this profile after reboot
                // This is replicated from the current behaviour of the legacy application
                DataHelper.getInstance().storeData(
                    [profileId && [DataHelper.STORE_KEY.CURRENT_PROFILE_ID, profileId]].filter(
                        Boolean
                    )
                )
            ),
            catchError((error) => {
                if (error.code === VoltError.codes.UNKNOWN_API_ERROR.code) {
                    return throwError(
                        new VoltError(VoltError.codes.USER_CANNOT_PROFILE_SWITCH, {
                            inheritedError: error,
                        })
                    )
                }

                return throwError(error)
            })
        )
    }

    /**
     * Switch to local dummy kids profile with creating
     * @param {String} data.password Password
     * @param {Profile} data.profile user profiles
     * @returns {Observable<Array<Profile>>}
     */
    createDummyKidsProfile({ profile, password }) {
        this.logger.info(`Creating and switching to local fake kids profile`)

        /**
         * Generate a random Profile ID
         */
        const subscriberId = DataHelper.getInstance().getData(DataHelper.STORE_KEY.SUBSCRIBER_ID)
        profile.profileId = `${subscriberId}_${profile.profileId}_${Math.floor(
            Math.random() * 9999
        )}`

        return this.createProfile({
            profile,
            password,
        }).pipe(mergeMap(() => this._switchProfile({ profileId: profile.profileId, password })))
    }

    /**
     * Create new profile by profileID
     * @param {Object} data
     * @param {Profile} data.profile user profile
     * @param {String} data.password Password
     * @returns {Observable<Boolean>}
     */
    createProfile({ profile, password = HuaweiTypes.defaultPassword }) {
        if (!profile) {
            return throwError(new VoltError(VoltError.codes.INVALID_REQUEST_PAYLOAD))
        }

        const useLoginV2 = useApiLoginV2()

        if (useLoginV2) {
            const myEncryptedPassword = encryptPassword(password)
            return this.fetch({
                url: `${DataHelper.getInstance().getData(
                    DataHelper.STORE_KEY.BACKEND_API_URL
                )}/EPG/JSON/AddProfile`,
                method: 'POST',
                body: {
                    identityid: profile.profileId,
                    name: profile.username,
                    password: myEncryptedPassword,
                    levels: resolveBackendRatingId(profile.pcLevel),
                    familyRole: resolveFamilyRole(profile.isKidProfile),
                },
                log: 'CREATE PROFILE API V2',
            }).pipe(mergeMap(() => of(true)))
        }

        const subscriberPassword = DataHelper.getInstance().getData(
            DataHelper.STORE_KEY.SUBSCRIBER_ID2
        )
        return this.fetch({
            url: `${DataHelper.getInstance().getData(
                DataHelper.STORE_KEY.BACKEND_API_URL
            )}/VSP/V3/AddProfile`,
            method: 'POST',
            body: {
                profile: {
                    ID: profile.profileId,
                    familyRole: resolveFamilyRole(profile.isKidProfile),
                    ratingID: resolveBackendRatingId(profile.pcLevel),
                    profileType:
                        profile.profileType === Profile.PROFILE_TYPE.COMMON_PROFILE ? 1 : 0,
                    name: profile.username,
                    lang: profile.defaultLanguage || 'en',
                    password,
                },
                verifyType: '1',
                correlator: subscriberPassword,
            },
            log: 'CREATE PROFILE API V6',
        }).pipe(mergeMap(() => of(true)))
    }

    /**
     * Delete Profiles by their ids
     * @param {Object} data
     * @param {String} data.profileId profile id to delete
     * @param {Profile.PROFILE_TYPE} data.profileType type of profile model
     * @returns {Observable<Boolean>}
     */
    deleteProfile({ profileId, profileType }) {
        if (!profileId || !profileType || profileType === Profile.PROFILE_TYPE.SUPER_PROFILE) {
            return throwError(new VoltError(VoltError.codes.INVALID_REQUEST_PAYLOAD))
        }

        const useLoginV2 = useApiLoginV2()

        if (useLoginV2) {
            return this.fetch({
                url: `${DataHelper.getInstance().getData(
                    DataHelper.STORE_KEY.BACKEND_API_URL
                )}/EPG/JSON/DelProfile`,
                method: 'POST',
                body: {
                    id: profileId,
                },
                log: 'DELETE PROFILES',
            }).pipe(
                mergeMap(() => of(true)),
                catchError((error) => throwError(error))
            )
        }

        return throwError(
            new VoltError(VoltError.codes.FEATURE_NOT_AVAILABLE, {
                extraLog: '[deleteProfile] Delete profile by id not available on API v6',
            })
        )
    }

    /**
     * Username/Password Login
     * @param {Object} data
     * @param {String} data.username
     * @param {String} data.password
     * @param {String} [data.language]
     * @param {Object} [data.deviceData] optional device data (brand, model, serial number, casn, etc...)
     * @returns {Observable<String>} Constants.authenticationStatus values
     */
    login({
        username,
        password,
        language,
        deviceData,
        loginType = Constants.loginType.USERNAME_AND_PASSWORD,
    } = {}) {
        let userType = HuaweiTypes.userType.SUBSCRIBER_ID
        if (this.config.platform === Constants.platform.androidTvStb) {
            userType = HuaweiTypes.userType.SUBSCRIBER_ID
        } else {
            switch (loginType) {
                case Constants.loginType.PHONE_NUMBER:
                case Constants.loginType.ALTERNATIVE_USERNAME_AND_PASSWORD:
                    userType = HuaweiTypes.userType.SUBSCRIBER_ID
                    break
                default:
                case Constants.loginType.USERNAME_AND_PASSWORD:
                    userType = HuaweiTypes.userType.LOGIN_NAME
                    break
            }
        }

        return this._loginHuawei({
            userType,
            firstLogin: true,
            subscriberId: username,
            subscriberPassword: password,
            subscriberLanguage: language,
            deviceData,
        }).pipe(
            retryWhen(
                retryStrategy({
                    maxRetryAttempts: 1,
                    delayMs: 1000,
                    errorCode: VoltError.codes.MAX_DEVICES_REACHED.code,
                    callback: this.deviceApi.deleteDevice.bind(this.deviceApi, { username }),
                    /**
                     * Device Deletion is allowed only for Non STB Devices
                     */
                    platforms: Object.values(Constants.platform)
                        .filter((x) => x !== Constants.platform.androidTvStb)
                        .filter(Boolean),
                    config: this.config,
                    logger: this.logger,
                })
            )
        )
    }

    /**
     * Login using serial Number
     * @param {Object} data
     * @param {String} data.secureId
     * @param {String} data.language
     * @param {Object} [data.deviceData]
     * @returns {Observable}
     */
    loginWithSecureId({ secureId, language, deviceData = {} } = {}) {
        const useLoginV2 = useApiLoginV2()
        if (useLoginV2) {
            // On STB device (this method is invoked only on STB), there is no trigger of device deletion in case of Max device reached
            return this._loginHuawei({
                firstLogin: true,
                userType: HuaweiTypes.userType.STB_SN,
                subscriberId: deviceData.serialNumber || secureId,
                subscriberPassword: HuaweiTypes.defaultPassword,
                subscriberLanguage: language,
                deviceData,
            })
        }

        this.logger.warn('Login With STB Serial number not available on API v6')

        return throwError(
            new VoltError(VoltError.codes.FEATURE_NOT_AVAILABLE, {
                extraLog:
                    '[loginWithSecureId] Login With STB Serial number not available on API v6',
            })
        )
    }

    /**
     * Logout user
     * @returns {Observable<>} true
     */
    logout() {
        this.logger.info(`Logging out...`)

        return this.fetch({
            url: `${DataHelper.getInstance().getData(
                DataHelper.STORE_KEY.BACKEND_API_URL
            )}/VSP/V3/Logout`,
            method: 'POST',
            body: {
                type: HuaweiTypes.logoutType.LOGOUT,
            },
            log: 'LOG OUT',
            allowReloginWhenSessionEnded: false,
        }).pipe(
            mergeMap(() => {
                this.logger.info(`Logged out successfully...Clear cache.`)
                this.heartbeatHelper.stopTokenRefreshTimer()
                this._setHasLogin(false) // Clear this flag to avoid refetching profile from cache
                CookiesHandler.clearCookie() // clear cookies as well
                return DataHelper.getInstance().clearData(this._getStoredKeys())
            }),
            catchError((error) => {
                this.logger.warn('Error during logout... wipe cache and return success', error)
                this.heartbeatHelper.stopTokenRefreshTimer()
                this._setHasLogin(false) // Clear this flag to avoid refetching profile from cache
                CookiesHandler.clearCookie() // clear cookies as well
                return DataHelper.getInstance().clearData(this._getStoredKeys())
            })
        )
    }

    /**
     * Huawei Login
     * @param {Boolean} [firstLogin=true] If first login then redo the authentication from Scratch, otherwise try it from cache
     * @param {HuaweiTypes.userType.STB_SN|HuaweiTypes.userType.SUBSCRIBER_ID} userType STB Login vs. Username/Password login
     * @param {Object} data
     * @param {String} data.subscriberId
     * @param {String} [data.subscriberPassword]
     * @param {String} [data.subscriberLanguage]
     * @param {Boolean} [data.renewServerUrl=true] Used for renewing the server URL
     * @param {Object} [data.deviceData] optional device data (brand, model, serial number, casn, etc...)
     * @returns {Observable<String>} Constants.authenticationStatus values
     */
    _loginHuawei({
        firstLogin = true,
        userType,
        subscriberId,
        subscriberPassword,
        subscriberLanguage,
        deviceData,
        renewServerUrl = true,
    } = {}) {
        const hasBackendUrl = !!DataHelper.getInstance().getData(
            DataHelper.STORE_KEY.BACKEND_API_URL
        )
        if (!renewServerUrl && !hasBackendUrl) {
            this.logger.warn('Force to renew the URL')
            renewServerUrl = true
        }

        const useLoginV2 = useApiLoginV2()
        let subscriberAuthToken
        if (!userType) {
            userType = HuaweiTypes.userType.SUBSCRIBER_ID
        }

        if (!useLoginV2 && userType === HuaweiTypes.userType.STB_SN) {
            this.logger.warn(
                'HuaweiTypes.userType.STB_SN not available on API v6, fallback to HuaweiTypes.userType.SUBSCRIBER_ID'
            )
            userType = HuaweiTypes.userType.SUBSCRIBER_ID
        }
        if (firstLogin) {
            /**
             * For now only Username/Password Login is supported
             */
            if (!subscriberId || !subscriberPassword) {
                return throwError(
                    new VoltError(VoltError.codes.LOGIN_INVALID_CREDENTIALS, {
                        extraLog: 'Empty Username or/and Password',
                    })
                )
            }
        } else {
            subscriberId =
                subscriberId || DataHelper.getInstance().getData(DataHelper.STORE_KEY.SUBSCRIBER_ID)
            if (!subscriberId) {
                // If we do not have the subscriber Id in storage, redo the authentication process
                return throwError(
                    new VoltError(VoltError.codes.LOGIN_REQUIRED, {
                        extraLog: 'Missing subscriber ID from cache, need to initiate First Login',
                    })
                )
            }

            if (userType === HuaweiTypes.userType.LOGIN_NAME) {
                /**
                 * Try authentication from Cache
                 */
                subscriberAuthToken = DataHelper.getInstance().getData(
                    DataHelper.STORE_KEY.SESSION_TOKEN
                )
                if (!subscriberAuthToken && !useLoginV2) {
                    // If we do not have an access token in storage, redo the authentication process
                    return throwError(
                        new VoltError(VoltError.codes.LOGIN_REQUIRED, {
                            extraLog: 'No token found from cache, need to initiate First Login',
                        })
                    )
                }
            } else if (
                userType === HuaweiTypes.userType.SUBSCRIBER_ID ||
                userType === HuaweiTypes.userType.STB_SN
            ) {
                subscriberPassword =
                    subscriberPassword ||
                    DataHelper.getInstance().getData(DataHelper.STORE_KEY.SUBSCRIBER_ID2)
                if (!subscriberPassword) {
                    // If we do not have the subscriber Id in storage, redo the authentication process
                    return throwError(
                        new VoltError(VoltError.codes.LOGIN_REQUIRED, {
                            extraLog:
                                'Missing subscriber Password from cache, need to initiate First Login',
                        })
                    )
                }
            }
        }

        let authenticationType
        switch (userType) {
            case HuaweiTypes.userType.LOGIN_NAME:
                if (useLoginV2) {
                    authenticationType =
                        /*firstLogin
                        ? HuaweiTypes.authTypeV2.TOKEN_BASED
                        :*/ HuaweiTypes.authTypeV2.PASSWORD_BASED
                } else {
                    authenticationType = firstLogin
                        ? HuaweiTypes.authType.USERNAME_PASSWORD
                        : HuaweiTypes.authType.PASSWORD_FREE_LOGIN
                }
                break

            case HuaweiTypes.userType.SUBSCRIBER_ID:
            case HuaweiTypes.userType.STB_SN:
            default:
                authenticationType = HuaweiTypes.authType.PASSWORD_BASED
                break
        }

        return (renewServerUrl ? this.getBackendUrl(subscriberId) : of(undefined)).pipe(
            mergeMap(() =>
                useLoginV2
                    ? this.getLoginParamsV2(subscriberId)
                    : renewServerUrl
                    ? this.getLoginParamsV6(subscriberId)
                    : of(undefined)
            ),
            mergeMap(() =>
                useLoginV2
                    ? this._loginV2(userType, authenticationType, {
                          subscriberId,
                          subscriberPassword,
                          subscriberLanguage,
                          subscriberAuthToken,
                          deviceData,
                      })
                    : this._loginV6(userType, authenticationType, {
                          subscriberId,
                          subscriberPassword,
                          subscriberLanguage,
                          subscriberAuthToken,
                          deviceData,
                      })
            ),
            mergeMap(() => {
                const profilesFromCache = this._getProfilesFromCache()
                /**
                 * If there are multi profiles, the login is not finished until we select explicitly the profile
                 * Otherwise the login fails
                 */
                if (profilesFromCache && profilesFromCache.length >= 2) {
                    this.logger.info(
                        'Multi-profile available for this account, need to switch profile to finish the authentication'
                    )
                    const myProfile = this._selectProfile(profilesFromCache)
                    if (myProfile) {
                        this.logger.info(`Select Profile ${myProfile.profileId}`)
                        return this._switchProfile({
                            profileId: myProfile.profileId,
                            /**
                             * On API V2, it seems that the encrypted Password from Profile is enough for profile switch
                             */
                            encryptedPassword: myProfile.namedProperties.encryptedPassword,
                            /**
                             * Concern API V6: password should not the subscriber password but profile password;
                             * To be reworked later but better than nothing
                             */
                            password: subscriberPassword,
                        })
                    } else {
                        this.logger.warn('No eligible profile found, this SHOULD NOT BE HAPPEN')
                    }
                }

                return of(true)
            }),
            mergeMap(() => of(Constants.authenticationStatus.SUCCEEDED))
        )
    }

    /**
     * Huawei Login or Re login
     * @param {HuaweiTypes.authType} authType
     * @param {Object} data
     * @param {String} data.subscriberId (Similar to username. Used at first login)
     * @param {String} data.subscriberPassword Password
     * @param {String} [data.subscriberAuthToken] Subscriber Auth token (Used for re login from cache)
     * @param {String} [data.subscriberLanguage] Language
     * @param {Object} [data.deviceData] optional device data (brand, model, serial number, casn, etc...)
     * @returns {Observable<String>} Constants.authenticationStatus values
     */
    _loginV6(
        userType = HuaweiTypes.userType.SUBSCRIBER_ID,
        authType = HuaweiTypes.authType.USERNAME_PASSWORD,
        {
            subscriberId,
            subscriberPassword,
            subscriberLanguage,
            subscriberAuthToken,
            deviceData,
        } = {}
    ) {
        let { deviceId, deviceName, deviceModel, CADeviceType, serialNumber, macAddress } =
            resolveDeviceAttributes({
                deviceData,
            })
        if (!deviceId) {
            deviceId = DataHelper.getInstance().getData(DataHelper.STORE_KEY.BACKEND_USER_DEVICE_ID)
        }
        if (!deviceName) {
            deviceName = DataHelper.getInstance().getData(
                DataHelper.STORE_KEY.BACKEND_USER_DEVICE_NAME
            )
        }
        if (!subscriberLanguage) {
            subscriberLanguage =
                DataHelper.getInstance().getData(DataHelper.STORE_KEY.LANGUAGE_LOGIN) || 'en'
        }

        if (!deviceId || !deviceModel || !CADeviceType) {
            return throwError(
                new VoltError(VoltError.codes.AUTH_ERROR_METADATAS_DEVICE, {
                    extraLog: `No authenticateDevice available from the device`,
                    backend: this.backendName,
                })
            )
        }

        let authenticateBasic = {
            authType,
            userType,
            userID: subscriberId,
            clientPasswd: subscriberPassword,
            isSupportWebpImgFormat:
                ConfigHelper.getInstance().getConfig('huawei').supportWebpImgFormat ||
                HuaweiTypes.supportWebpImgFormat.PNG_JPG_GIF,
            needPosterTypes: [
                HuaweiTypes.posterType.thumbnail,
                HuaweiTypes.posterType.poster,
                HuaweiTypes.posterType.still,
                HuaweiTypes.posterType.icon,
                HuaweiTypes.posterType.title_image,
                HuaweiTypes.posterType.ad,
                HuaweiTypes.posterType.draft,
                HuaweiTypes.posterType.background,
                HuaweiTypes.posterType.channel,
                HuaweiTypes.posterType.channel_black_white,
            ],
            lang: subscriberLanguage,
            /**
             * Force UTC 0 to get UTC0 for subsequent APIs like DVR
             */
            timeZone: 'GMT+00:00',
        }
        if (subscriberAuthToken) {
            authenticateBasic = {
                ...authenticateBasic,
                authToken: subscriberAuthToken,
            }
        }

        this.logger.info(
            `Logging in using authType: ${authType}... :: :: deviceName: ${deviceName} :: deviceModel: ${deviceModel}`
        )
        this.logger.debug(`... :: deviceId: ${deviceId}`)
        const CADeviceID = deviceId

        return this.fetch({
            url: `${DataHelper.getInstance().getData(
                DataHelper.STORE_KEY.BACKEND_API_URL
            )}/VSP/V3/Authenticate`,
            method: 'POST',
            body: {
                authenticateBasic,
                authenticateDevice: {
                    deviceModel,
                    terminalVendor: deviceName,
                    physicalDeviceID: deviceId,
                    terminalID: deviceId,
                    CADeviceInfos: [
                        {
                            CADeviceType,
                            CADeviceID,
                        },
                    ],
                    // 'authTerminalID' parameter is really important as it allows to get authToken after a login
                    // which is the criteria for silent subsequent login
                    authTerminalID: deviceId,
                },
            },
            allowReloginWhenSessionEnded: false,
            log: 'LOGIN AUTHENTICATE V6',
        }).pipe(
            mergeMap(({ response } = {}) => {
                const loginStatus = Factories.LoginV6StatusFactory(response)
                if (!loginStatus.jSessionID) {
                    return throwError(
                        new VoltError(VoltError.codes.AUTH_MISSING_COOKIE, {
                            extraLog: `Cookie is missing from the backend, cannot login`,
                            backend: this.backendName,
                        })
                    )
                }

                const businessType = Factories.parseBusinessType(loginStatus)
                this.logger.info(`User belongs to ${businessType} business`)

                this._cacheLoginStatus(loginStatus)
                if (userType === HuaweiTypes.userType.LOGIN_NAME && !loginStatus.authToken) {
                    this.logger.warn(
                        `Missing authToken from Login response. Do throw an error but user will have to redo the authentication after reboot/app restart !!!`
                    )

                    const { loginErrorWhenAuthTokenIsMissing = false } =
                        ConfigHelper.getInstance().getConfig('huawei')
                    if (loginErrorWhenAuthTokenIsMissing) {
                        return throwError(
                            new VoltError(VoltError.codes.TOKEN_RETRIEVAL_ERROR, {
                                extraLog: `AuthToken is missing from the response throw an error to prevent any login`,
                                backend: this.backendName,
                            })
                        )
                    }
                }
                const drmConfig = Factories.DrmConfigFactory(response, this.config.platform)
                this.logger.debug(`Drm Configuration is:`, drmConfig)

                this._setHasLogin()

                return DataHelper.getInstance()
                    .storeData(
                        [
                            [DataHelper.STORE_KEY.BACKEND_USER_DEVICE_ID, CADeviceID],
                            [DataHelper.STORE_KEY.BACKEND_USER_DEVICE_NAME, deviceName],
                            serialNumber && [DataHelper.STORE_KEY.SERIAL_NUMBER, serialNumber],
                            macAddress && [DataHelper.STORE_KEY.MAC_ADDRESS, macAddress],
                            [DataHelper.STORE_KEY.LANGUAGE_LOGIN, subscriberLanguage],
                            [DataHelper.STORE_KEY.SUBSCRIBER_TYPE, userType],

                            // Use subscriber ID from the response because we can login using Serial Number for STB Silent login and it that case it is different
                            [DataHelper.STORE_KEY.SUBSCRIBER_ID, loginStatus.subscriberID],
                            [DataHelper.STORE_KEY.SUBSCRIBER_ID2, subscriberPassword],
                            [DataHelper.STORE_KEY.BUSINESS_TYPE, businessType],
                            [
                                DataHelper.STORE_KEY.CHANNEL_NAMESPACE,
                                loginStatus.channelNamespace ? loginStatus.channelNamespace : '',
                            ],
                            loginStatus.userGroup && [
                                DataHelper.STORE_KEY.USER_GROUP,
                                loginStatus.userGroup,
                            ],
                            loginStatus.authToken && [
                                DataHelper.STORE_KEY.SESSION_TOKEN,
                                loginStatus.authToken,
                            ],
                            [
                                DataHelper.STORE_KEY.SESSION_COOKIE,
                                `JSESSIONID=${loginStatus.jSessionID}`,
                            ],
                            drmConfig.licenseServerUrl && [
                                DataHelper.STORE_KEY.DRM_LICENSE_SERVER_URL,
                                drmConfig.licenseServerUrl,
                            ],
                            drmConfig.certificateServerUrl && [
                                DataHelper.STORE_KEY.FAIRPLAY_CERTIFICATE_SERVER_URL,
                                drmConfig.certificateServerUrl,
                            ],
                            drmConfig.licenseServerDevice && [
                                DataHelper.STORE_KEY.DRM_USER_DEVICE_ID,
                                drmConfig.licenseServerDevice,
                            ],
                        ].filter(Boolean)
                    )
                    .pipe(
                        switchMap(() => of(loginStatus)),
                        tap(() => {
                            /**
                             * Send heartbeat request quickly after the login to get the next delay value
                             */
                            this.heartbeatHelper.startTokenRefreshTimer(
                                HEARTBEAT_LOGIN_DELAY_AFTER_LOGIN_MS
                            )
                        })
                    )
            })
        )
    }

    /**
     * Huawei Login or Re login
     * @param {HuaweiTypes.authType} authType
     * @param {Object} data
     * @param {String} data.subscriberId (Similar to username. Used at first login)
     * @param {String} data.subscriberPassword Password
     * @param {String} [data.subscriberLanguage] Language
     * @param {Object} [data.deviceData] optional device data (brand, model, serial number, casn, etc...)
     * @returns {Observable<String>} Constants.authenticationStatus values
     */
    _loginV2(
        userType = HuaweiTypes.userType.SUBSCRIBER_ID,
        authType = HuaweiTypes.authType.USERNAME_PASSWORD,
        { subscriberId, subscriberPassword, subscriberLanguage, deviceData } = {}
    ) {
        let {
            deviceId,
            deviceName,
            deviceModel,
            CADeviceTypeApiV2,
            serialNumber,
            macAddress,
            ipAddress,
        } = resolveDeviceAttributes({
            deviceData,
        })
        if (!deviceId) {
            deviceId = DataHelper.getInstance().getData(DataHelper.STORE_KEY.BACKEND_USER_DEVICE_ID)
        }
        if (!serialNumber) {
            serialNumber = DataHelper.getInstance().getData(DataHelper.STORE_KEY.SERIAL_NUMBER)
        }
        if (!macAddress) {
            macAddress = DataHelper.getInstance().getData(DataHelper.STORE_KEY.MAC_ADDRESS)
        }

        if (!deviceName) {
            deviceName = DataHelper.getInstance().getData(
                DataHelper.STORE_KEY.BACKEND_USER_DEVICE_NAME
            )
        }
        if (!subscriberLanguage) {
            subscriberLanguage =
                DataHelper.getInstance().getData(DataHelper.STORE_KEY.LANGUAGE_LOGIN) || 'en'
        }

        if (!deviceId || !deviceModel || !CADeviceTypeApiV2) {
            return throwError(
                new VoltError(VoltError.codes.AUTH_ERROR_METADATAS_DEVICE, {
                    extraLog: `No authenticateDevice available from the device`,
                    backend: this.backendName,
                })
            )
        }

        if (this.config.platform === Constants.platform.androidTvStb && !macAddress) {
            if (!macAddress) {
                return throwError(
                    new VoltError(VoltError.codes.MISSING_DEVICE_PERMISSION, {
                        extraLog: `Missing mac address cannot login on STB device`,
                        backend: this.backendName,
                    })
                )
            }

            const { allowLoginRequestIfSerialNumberMissing = false } =
                ConfigHelper.getInstance().getConfig('huawei')
            if (!serialNumber && !allowLoginRequestIfSerialNumberMissing) {
                return throwError(
                    new VoltError(VoltError.codes.MISSING_DEVICE_PERMISSION, {
                        extraLog: `Missing Serial number permission cannot login on STB device`,
                        backend: this.backendName,
                    })
                )
            }
        }

        const encryptToken = this._getLoginParameters().enctytoken
        if (!encryptToken) {
            return throwError(
                new VoltError(VoltError.codes.TOKEN_RETRIEVAL_ERROR, {
                    extraLog: `Unable to retrieve encryptToken`,
                    backend: this.backendName,
                })
            )
        }

        let caDeviceId
        let terminalId
        let mac
        if (this.config.platform === Constants.platform.androidTvStb) {
            /**
             * According to Ooredoo:
             * The scenario must be first UC#1(STB Silent Login) and if UC#1 fails, then second UC#2 (Login via PIN 1111 i.e SUBSCRIBER_ID)
             * IN UC#2, terminal ID should be the same with userid, caDeviceId should be Mac Address which is used for ethernet.
             * caDeviceId will be always Mac Adress(Ethernet) regardless it is UC#1 or UC#2.
             */
            if (userType === HuaweiTypes.userType.SUBSCRIBER_ID) {
                terminalId = subscriberId
            } else {
                /*
                 * userType === HuaweiTypes.userType.STB_SN
                 */
                terminalId = serialNumber || deviceId
                /**
                 * While login is STB Serial Number login, The subscriber ID is always equals to serialNumber
                 */
                subscriberId = serialNumber
            }
            mac = macAddress
            caDeviceId = macAddress
        } else {
            /**
             * On non STB device, all the following value should be equal to the deviceId
             */
            mac = deviceId
            terminalId = deviceId
            caDeviceId = deviceId
        }

        let body = {
            /**
             * Unique user ID used to log in to the client. The platform can obtain the subscriber that this user belongs to.
             */
            userid: subscriberId,
            /**
             * MAC address of a terminal. The value identifies device physical address and is used to register the device with the CA.
             • For an STB, the value is the MAC address of the STB.
             • For an OTT device, the value is the value of caDeviceId of CA client plug-in.
             • If a subscriber logs in to the client and goes to the self-service page, the subscriber login is not required to bind to the device and the request does not need to contain this parameter.
             */
            mac,
            /**
             * Terminal ID. The parameter is defined by the terminal and not used as the actual device ID.
             * NOTE: The value must be the same as that of TerminalID in authenticator.
             */
            terminalid: terminalId,
            /**
             * Model of a terminal.
             * If the subscriber login is required to bind to the device, the request must contain this parameter.
             * If a subscriber logs in to the client and goes to the self-service page, the request does not need to contain this parameter.
             * The MEM stores the specific terminal models, based on which the MEM determines the domain to which a terminal belongs.
             • If the terminal is an STB, the value is specified by the STB vendors and must be unique, for example, EC2118.
             • For an OTT terminal, the value is the actual model of the terminal
             */
            terminaltype: deviceModel,
            /**
             * Force UTC 0 to get UTC0 for subsequent APIs like DVR
             */
            timezone: 'GMT+00:00',
            /**
             * Terminal vendor model.
             * For example, terimalType of iPhone is OTT, and terminalVendor of iPhone is iPhone 4S.
             */
            terminalvendor: deviceName,
            /**
             * User identification type corresponding to the input userid value.The options are as follows:
                • 0: subscriber ID or logical device ID. In this case, only the subscriber's password (excluding the super profile password) is verified.
                • 1: loginName of a profile (For details about loginName, see section "2.63 MultiProfile"). In this case, only the profile's password is verified.
                • 2: logical device ID. Only the logical device's password is verified.
                • 3: guest user. In an authentication request of a guest user, the userId and authenticator parameters are optional. If the client carries the userId and authenticator parameters in such a request, the MEM uses the values of the userId and authenticator parameters sent by the client.
                • 4: STB SN. In this case, only the subscriber's password is verified. 
                The default value is 0. The MEM checks whether the type of the user ID is subscriber ID first. If it is not subscriber ID, the MEM checks whether the type is logic device ID.
             */
            userType: userType,
            /**
             * CA device information.
             * If the request does not contain this parameter, the default device is the Verimatrix CA.
             * * The default value of caDeviceId is the MAC address of the device (mac).
             * • The value of caDeviceType is determined by the MEM based on the terminal model.
             */
            caDeviceInfo: {
                caDeviceType: CADeviceTypeApiV2,
                caDeviceId,
            },
            /* If authType is set to 0, this parameter specifies the password based on which authentication is performed.
                ▪ If the authentication password is managed by the platform, this parameter specifies the authenticator generated from the password.
                  For details about the authenticator algorithm, see section "14.1.2 Authenticator Algorithm."
                ▪ If the authentication password is allocated by a third-party authentication system, this parameter specifies the authentication password allocated by the third-party client. (An example is Turkcell LDAP authentication.)
                ▪ If the third-party client connects to the MEM over mobile networks such as 2G and 3G and no password needs to be entered for the connection, this parameter is optional. (An example is Turkcell Radius authentication.)
                • If authType is set to 1, this parameter specifies the authentication token. For details, see the definition of authToken returned by the Authenticate interface. This scenario is used only at the Turkcell site.
            */
            authenticator: generateAuthenticator({
                userId: subscriberId,
                password: subscriberPassword,
                encryptToken: encryptToken,
                terminalId: terminalId,
                addressIp: ipAddress,
                macAddress: macAddress,
            }),
            /**
             * Language used for the content on the current client.
             * Multiple languages are supported. The GetLanguage interface can be used to query supported languages.
             * The options of this parameter are the values of id returned by this interface.
             * In the bypass state, the MEM sets the language for the content based on the parameter.
             */
            locale: getLanguageMappingApiV2(subscriberLanguage),
        }

        if (this.config.platform === Constants.platform.androidTvStb) {
            body = {
                ...body,
                stbSN: serialNumber,
            }
        }

        this.logger.info(
            `Logging in using authType: ${authType}... :: :: deviceName: ${deviceName} :: deviceModel: ${deviceModel}`
        )
        this.logger.debug(
            `... :: terminalId: ${deviceId} :: mac: ${mac} :: caDeviceId: ${caDeviceId}`
        )
        return this.fetch({
            url: `${DataHelper.getInstance().getData(
                DataHelper.STORE_KEY.BACKEND_API_URL
            )}/EPG/JSON/Authenticate`,
            method: 'POST',
            body,
            allowReloginWhenSessionEnded: false,
            log: 'LOGIN AUTHENTICATE V2',
        }).pipe(
            mergeMap(({ response } = {}) => {
                const loginStatus = Factories.LoginV2StatusFactory(response)
                this._cacheLoginStatus(loginStatus)

                const businessType = Factories.parseBusinessType(loginStatus)
                this.logger.info(`User belongs to ${businessType} business`)

                const drmConfig = Factories.DrmConfigFactory(response, this.config.platform)
                this.logger.debug(`Drm Configuration is:`, drmConfig)

                this._setHasLogin()

                return DataHelper.getInstance()
                    .storeData(
                        [
                            [DataHelper.STORE_KEY.BACKEND_USER_DEVICE_ID, caDeviceId],
                            [DataHelper.STORE_KEY.BACKEND_USER_DEVICE_NAME, deviceName],
                            serialNumber && [DataHelper.STORE_KEY.SERIAL_NUMBER, serialNumber],
                            macAddress && [DataHelper.STORE_KEY.MAC_ADDRESS, macAddress],
                            [DataHelper.STORE_KEY.LANGUAGE_LOGIN, subscriberLanguage],
                            [DataHelper.STORE_KEY.SUBSCRIBER_TYPE, userType],
                            // Use subscriber ID from the response because we can login using Serial Number for STB Silent login and it that case it is different
                            [DataHelper.STORE_KEY.SUBSCRIBER_ID, loginStatus.subscriberID],
                            [DataHelper.STORE_KEY.SUBSCRIBER_ID2, subscriberPassword],
                            [DataHelper.STORE_KEY.BUSINESS_TYPE, businessType],
                            [
                                DataHelper.STORE_KEY.CHANNEL_NAMESPACE,
                                loginStatus.channelNamespace ? loginStatus.channelNamespace : '',
                            ],
                            loginStatus.userGroup && [
                                DataHelper.STORE_KEY.USER_GROUP,
                                loginStatus.userGroup,
                            ],
                            loginStatus.authToken && [
                                DataHelper.STORE_KEY.SESSION_TOKEN,
                                loginStatus.authToken,
                            ],
                            [
                                DataHelper.STORE_KEY.SESSION_COOKIE,
                                `JSESSIONID=${loginStatus.jSessionID}`,
                            ],
                            drmConfig.licenseServerUrl && [
                                DataHelper.STORE_KEY.DRM_LICENSE_SERVER_URL,
                                drmConfig.licenseServerUrl,
                            ],
                            drmConfig.certificateServerUrl && [
                                DataHelper.STORE_KEY.FAIRPLAY_CERTIFICATE_SERVER_URL,
                                drmConfig.certificateServerUrl,
                            ],
                            drmConfig.licenseServerDevice && [
                                DataHelper.STORE_KEY.DRM_USER_DEVICE_ID,
                                drmConfig.licenseServerDevice,
                            ],
                        ].filter(Boolean)
                    )
                    .pipe(
                        switchMap(() => of(loginStatus)),
                        tap(() => {
                            /**
                             * Send heartbeat request quickly after the login to get the next delay value
                             */
                            this.heartbeatHelper.startTokenRefreshTimer(
                                HEARTBEAT_LOGIN_DELAY_AFTER_LOGIN_MS
                            )
                        })
                    )
            })
        )
    }

    /**
     * Fetch user profiles
     * @returns {Observable<Array<Profile>>}
     */
    _queryProfiles() {
        this.logger.info(`Query profiles...`)

        return this.recursiveFetch(
            (_, count, offset) => {
                return this.fetch({
                    url: `${DataHelper.getInstance().getData(
                        DataHelper.STORE_KEY.BACKEND_API_URL
                    )}/VSP/V3/QueryProfile`,
                    method: 'POST',
                    body: {
                        type: HuaweiTypes.profileType.ALL_PROFILES,
                        offset,
                        count,
                    },
                    log: 'QUERY PROFILE',
                })
            },
            {
                limit: 0,
                count: 50,
                responseResource: 'profiles',
            }
        ).pipe(
            mergeMap((huaweiProfiles) => {
                return of(
                    Factories.profileFactory(
                        huaweiProfiles,
                        DataHelper.getInstance().getData(DataHelper.STORE_KEY.BUSINESS_TYPE)
                    )
                )
            })
        )
    }

    /**
     * Retrieve backend URL from a load balancer
     * @param {String} subscriberId Subscriber ID (i.e Username)
     * @returns {Observable<String>} Backend URL
     */
    getBackendUrl(subscriberId) {
        const edsServer = this.config.urls.authUrl
        let apiUrl = this.config.urls.apiUrl
        if (!edsServer) {
            this.logger.warn(`EDS Server not configured, use VSP URL directly`)
            return DataHelper.getInstance()
                .storeData([[DataHelper.STORE_KEY.BACKEND_API_URL, apiUrl]])
                .pipe(switchMap(() => of(apiUrl)))
        }

        this.logger.info(`Retrieving the VSP Url from the EDS / Loading balancer...`)
        return this.fetch({
            url: `${edsServer}/EDS/jsp/AuthenticationURL${serialize({
                Action: 'Login',
                UserID: subscriberId,
                return_type: 2,
            })}`,
            method: 'POST',
            body: {
                subscriberID: subscriberId,
            },
            allowReloginWhenSessionEnded: false,
            // cookiesLessQuery: true,
            log: 'LOGIN ROUTE',
        }).pipe(
            mergeMap(({ response: { epghttpsurl, epgurl } } = {}) => {
                const { useVspHttpsUrl = false } = ConfigHelper.getInstance().getConfig('huawei')

                apiUrl = epgurl || epghttpsurl
                if (useVspHttpsUrl && epghttpsurl) {
                    apiUrl = epghttpsurl
                    this.logger.info(`VSP HTTPS URL used: ${epghttpsurl}`)
                } else {
                    this.logger.info(`VSP URL used: ${epgurl}`)
                }

                return DataHelper.getInstance()
                    .storeData([[DataHelper.STORE_KEY.BACKEND_API_URL, apiUrl]])
                    .pipe(switchMap(() => of(apiUrl)))
            }),
            catchError((error) => {
                if (error.code === VoltError.codes.REQUEST_TIMEOUT.code) {
                    this.logger.warn(
                        `Timeout while trying to contact the EDS, meaning that Operator server is not accessible. Do try to use a VSP URL fallback. Throw timeout error`
                    )
                    return throwError(error)
                }
                const vspCachedUrl = DataHelper.getInstance().getData(
                    DataHelper.STORE_KEY.BACKEND_API_URL
                )
                if (vspCachedUrl) {
                    this.logger.warn(
                        `There is a previous cached VSP URL ${vspCachedUrl} reuse it`,
                        error
                    )
                    return of(vspCachedUrl)
                }
                this.logger.warn(
                    `Error while trying to retrieve the VSP URL using LoginRoute, fallback using VSP default URL ${apiUrl}`,
                    error
                )
                return DataHelper.getInstance()
                    .storeData([[DataHelper.STORE_KEY.BACKEND_API_URL, apiUrl]])
                    .pipe(switchMap(() => of(apiUrl)))
            })
        )
    }

    hasSilentLoginInProgress() {
        return this.heartbeatHelper._isRefreshingToken || this._isSilentRelogin
    }

    /**
     * Retrieve backend Params
     * @param {String} subscriberId Subscriber ID (i.e Username)
     * @returns {Observable<Object>} Backend Parameters
     */
    getLoginParamsV6(subscriberId) {
        this.logger.info(`Retrieving the Login Parameters...`)

        return this.fetch({
            url: `${DataHelper.getInstance().getData(
                DataHelper.STORE_KEY.BACKEND_API_URL
            )}/VSP/V3/Login`,
            method: 'POST',
            body: {
                subscriberID: subscriberId,
            },
            allowReloginWhenSessionEnded: false,
            cookiesLessQuery: true,
            log: 'LOGIN PARAMS V6',
        }).pipe(
            mergeMap(({ response } = {}) => {
                const loginParams = Factories.LoginV6ParametersFactory(response)
                this._cacheLoginParameters(loginParams)
                return of(loginParams)
            }),
            catchError((error) => {
                if (error.code === VoltError.codes.REQUEST_TIMEOUT.code) {
                    this.logger.warn(
                        `Timeout while trying to contact the VSP (Login param), meaning that Operator server is not accessible. Do try to move to next step (Login). Throw timeout error`
                    )
                    return throwError(error)
                }

                this.logger.warn(
                    'Error while retrieving the login Parameters, no need for now to fail all the login proceed. Stub used',
                    error
                )
                return of(Factories.LoginV6ParametersFactory({})) // Returns empty parameters
            })
        )
    }

    /**
     * Retrieve backend Params
     * @param {String} subscriberId Subscriber ID (i.e Username)
     * @returns {Observable<Object>} Backend Parameters
     */
    getLoginParamsV2(subscriberId) {
        this.logger.info(`Retrieving the Login Parameters...`)

        return this.fetch({
            url: `${DataHelper.getInstance().getData(
                DataHelper.STORE_KEY.BACKEND_API_URL
            )}/EPG/JSON/Login${serialize({
                UserID: subscriberId,
            })}`,
            method: 'POST',
            allowReloginWhenSessionEnded: false,
            cookiesLessQuery: true,
            log: 'LOGIN PARAMS V2',
        }).pipe(
            mergeMap(({ response } = {}) => {
                const loginParams = Factories.LoginV2ParametersFactory(response)
                this._cacheLoginParameters(loginParams)
                return of(loginParams)
            }),
            catchError((error) => {
                if (error.code === VoltError.codes.REQUEST_TIMEOUT.code) {
                    this.logger.warn(
                        `Timeout while trying to contact the VSP (Login param), meaning that Operator server is not accessible. Do try to move to next step (Login). Throw timeout error`
                    )
                    return throwError(error)
                }

                this.logger.warn(
                    'Error while retrieving the login Parameters, As the encryption token is needed for login. SHould fail',
                    error
                )
                return throwError(error)
            })
        )
    }

    /**
     * This function follows the nomenclature from VOLT API.
     * This function does not do the authentication but only check if storage contains
     * Token cached, then refresh the access token if the expirations occurred
     * Throw en error if there is not authentication token
     * @param {Object} data
     * @param {String} [data.language]
     * @param {Object} [data.deviceData] optional device data (brand, model, serial number, casn, etc...)
     * @returns {Observable<Boolean>}
     */
    _authenticateFromCache({ deviceData, language } = {}) {
        return DataHelper.getInstance()
            .readAllData() // readData(this._getStoredKeys())
            .pipe(
                mergeMap(() => {
                    /**
                     * Try login from cache
                     */
                    return this._loginHuawei({
                        firstLogin: false,
                        userType: DataHelper.getInstance().getData(
                            DataHelper.STORE_KEY.SUBSCRIBER_TYPE
                        ),
                        subscriberId: DataHelper.getInstance().getData(
                            DataHelper.STORE_KEY.SUBSCRIBER_ID
                        ),
                        subscriberPassword: DataHelper.getInstance().getData(
                            DataHelper.STORE_KEY.SUBSCRIBER_ID2
                        ),
                        subscriberLanguage: language,
                        deviceData,
                    })
                })
            )
    }

    /**
     * Send Login Heartbeat (Similar to Refresh token)
     * @returns {Observable<>} true = token
     */
    refreshLoginHeartbeat({ forceReloginInCaseOfFailure = false }) {
        this.logger.info(`Sending Login heartbeat...`)

        return this.fetch({
            url: `${DataHelper.getInstance().getData(
                DataHelper.STORE_KEY.BACKEND_API_URL
            )}/VSP/V3/OnLineHeartbeat`,
            method: 'POST',
            allowReloginWhenSessionEnded: false, // Do not allow a relogin on refresh session
            log: 'LOGIN HEARTBEAT',
        }).pipe(
            mergeMap(({ response } = {}) => {
                this._resetRefreshHeartbeatFailure()

                const loginHeartbeat = Factories.LoginHeartbeatFactory(response)
                this._cacheLoginHeartbeat(loginHeartbeat)

                const { heartbeatNextIntervalReducedBy = 1 } =
                    ConfigHelper.getInstance().getConfig('huawei')
                const nextCallIntervalMs =
                    (loginHeartbeat.nextCallIntervalMs &&
                        loginHeartbeat.nextCallIntervalMs / heartbeatNextIntervalReducedBy) ||
                    undefined
                this.logger.info(
                    `Rearming next Heartbeat in nextCallInterval: ${nextCallIntervalMs} ms...Dividor applied by ${heartbeatNextIntervalReducedBy} => Original: ${loginHeartbeat.nextCallIntervalMs} ms`
                )

                this.heartbeatHelper.startTokenRefreshTimer(nextCallIntervalMs)

                return of(true)
            }),
            catchError((refreshTokenError) => {
                this._incrementRefreshHeartbeatFailure()
                this.logger.error('Error while refreshing the Heartbeat message', refreshTokenError)
                if (
                    refreshTokenError.code ===
                        VoltError.codes.USER_SESSION_NOT_FOUND_OR_TIMEOUT.code &&
                    this._canRecoverLoginFromCache()
                ) {
                    if (!this._isRefreshHeartbeatMaxFailure() || forceReloginInCaseOfFailure) {
                        this.logger.warn('Trying to recover using token login...')
                        return this._tryToRecoverSessionUsingRelogin().pipe(
                            catchError(() => throwError(refreshTokenError))
                        )
                    } else {
                        this.logger.warn(
                            'Maximum failure reached. Do not try to recover from tokin login'
                        )
                    }
                }
                return throwError(refreshTokenError)
            })
        )
    }

    /**
     * This method id called when the heartbeat refresh fails, and we try to recover the session using a login behind the scenes
     * @returns {Observable<any>}
     */
    _tryToRecoverSessionUsingRelogin({ renewServerUrl = false } = {}) {
        this.logger.warn('Try to Recover Session using Authenticate method...')
        this._isSilentRelogin = true
        return this._loginHuawei({
            firstLogin: false,
            userType: DataHelper.getInstance().getData(DataHelper.STORE_KEY.SUBSCRIBER_TYPE),
            subscriberId: DataHelper.getInstance().getData(DataHelper.STORE_KEY.SUBSCRIBER_ID),
            subscriberPassword: DataHelper.getInstance().getData(
                DataHelper.STORE_KEY.SUBSCRIBER_ID2
            ),
            renewServerUrl,
        }).pipe(
            switchMap(() => {
                this.logger.info('Able to recover session from Authenticate from authToken')
                this._isSilentRelogin = false
                return of(true)
            }),
            catchError((error) => {
                this.logger.warn('Unable to recover from authenticate from cache method', error)
                this._isSilentRelogin = false
                return throwError(error)
            })
        )
    }

    // -----------------------------------------------------------
    // ----------              TOOLBOX                  ----------
    // -----------------------------------------------------------
    /**
     * Return list of keys stored in local memory
     * @returns {Array<String>}
     */
    _getStoredKeys() {
        return [
            DataHelper.STORE_KEY.BACKEND_API_URL,
            DataHelper.STORE_KEY.SUBSCRIBER_TYPE,
            DataHelper.STORE_KEY.SUBSCRIBER_ID,
            DataHelper.STORE_KEY.SUBSCRIBER_ID2,
            DataHelper.STORE_KEY.CURRENT_PROFILE_ID,
            DataHelper.STORE_KEY.BACKEND_USER_DEVICE_ID,
            DataHelper.STORE_KEY.BACKEND_USER_DEVICE_NAME,
            DataHelper.STORE_KEY.SERIAL_NUMBER,
            DataHelper.STORE_KEY.MAC_ADDRESS,
            DataHelper.STORE_KEY.SESSION_TOKEN,
            DataHelper.STORE_KEY.SESSION_COOKIE,
            DataHelper.STORE_KEY.DRM_LICENSE_SERVER_URL,
            DataHelper.STORE_KEY.FAIRPLAY_CERTIFICATE_SERVER_URL,
            DataHelper.STORE_KEY.DRM_USER_DEVICE_ID,
            DataHelper.STORE_KEY.LANGUAGE_LOGIN,
            DataHelper.STORE_KEY.BUSINESS_TYPE,
            DataHelper.STORE_KEY.CHANNEL_NAMESPACE,
            DataHelper.STORE_KEY.USER_GROUP,
        ].filter(Boolean)
    }

    /**
     * Cache Login status retrieved at login
     * @param {LoginStatus} loginStatus
     */
    _cacheLoginStatus(loginStatus = {}) {
        this._loginStatus = loginStatus
    }
    /**
     * Retrieve login status
     * @returns {LoginStatus} login status
     */
    _getLoginStatus() {
        return this._loginStatus || {}
    }

    /**
     * Cache Login Heartbeat retrieved at login
     * @param {LoginHeartbeat} login Heartbeat
     */
    _cacheLoginHeartbeat(loginHeartbeat = {}) {
        this._loginHeartbeat = loginHeartbeat
    }
    /**
     * Retrieve login Heartbeat
     * @returns {LoginHeartbeat} login Heartbeat
     */
    _getLoginHeartbeat() {
        return this._loginHeartbeat || {}
    }

    /**
     * Cache Login parameters retrieved at login
     * @param {LoginParameters} loginParameters
     */
    _cacheLoginParameters(loginParameters) {
        this._loginParameters = loginParameters
    }
    /**
     * Retrieve login parameters
     * @returns {LoginParameters} loginParameters
     */
    _getLoginParameters() {
        return this._loginParameters || {}
    }

    /**
     * Retrieve login status
     * @returns {LoginStatus} login status
     */
    _getProfilesFromCache() {
        const huaweiProfiles = this._getLoginStatus().profiles
        return huaweiProfiles && huaweiProfiles.length
            ? Factories.profileFactory(
                  huaweiProfiles,
                  DataHelper.getInstance().getData(DataHelper.STORE_KEY.BUSINESS_TYPE)
              )
            : []
    }

    /**
     * Function which indicates if the user can login from cache using huawei authType
     * @returns {Boolean} true if the user can uses the cache for a login from huawei
     */
    _canRecoverLoginFromCache() {
        const { canRecoverLoginFromCache = true } = ConfigHelper.getInstance().getConfig('huawei')

        return (
            this._getHasAlreadyLoginOnce() &&
            (canRecoverLoginFromCache ||
                !!DataHelper.getInstance().getData(DataHelper.STORE_KEY.SESSION_TOKEN))
        )
    }

    /**
     * Increment failure for refreshing the Heartbeat
     * Used for limiting the attempt to recover the session from cache
     */
    _incrementRefreshHeartbeatFailure() {
        this._refreshHeartbeatFailure = (this._refreshHeartbeatFailure || 0) + 1
    }

    /**
     * Reset failure for refreshing the Heartbeat
     * Used for limiting the attempt to recover the session from cache
     */
    _resetRefreshHeartbeatFailure() {
        this._refreshHeartbeatFailure = 0
    }

    /**
     * Get refresh heart beat failure
     */
    _isRefreshHeartbeatMaxFailure() {
        const { maxRecoverFromRefreshHeartbeatFailure = 3 } =
            ConfigHelper.getInstance().getConfig('huawei')
        return this._refreshHeartbeatFailure > maxRecoverFromRefreshHeartbeatFailure
    }

    /**
     * This method select user profile from a list of profile
     * @warn temporary pending multi profile functionnality
     * @param {Array<Profiles>} profiles
     * @returns profile
     */
    _selectProfile(profiles) {
        let myProfile
        if (profiles.length > 1) {
            const currentProfileId = DataHelper.getInstance().getData(
                DataHelper.STORE_KEY.CURRENT_PROFILE_ID
            )

            this.logger.info('Multiple profiles found... Try to select the default one')

            /**
             * STEP.1 - Extract current profile
             */
            if (currentProfileId) {
                myProfile = profiles.find((profile) => profile.profileId === currentProfileId)
                if (myProfile) {
                    this.logger.info(`User profile found ${currentProfileId}`)
                    return myProfile
                }
            }

            /**
             * STEP.2 - Extract the Default profile
             */
            myProfile = profiles.find((profile) => profile.isDefaultProfile)
            if (myProfile) {
                this.logger.info('Default Profile found')
                return myProfile
            }

            /**
             * STEP.3 - Use Admin profile if not found
             */
            myProfile = profiles.find(
                (profile) => profile.profileType === Profile.PROFILE_TYPE.SUPER_PROFILE
            )
            if (myProfile) {
                this.logger.info('Admin Profile found')
                return myProfile
            }

            this.logger.info('No default Profile found')
            myProfile = profiles[0]
        } else if (profiles.length > 0) {
            myProfile = profiles[0]
        } else {
            this.logger.error('Error: No profile available')
        }

        return myProfile
    }

    /**
     * Flag which profile is selected by the user
     * @param {Array<Profiles>} profiles List of All Profiles
     * @param {Array<Profiles>} selectedProfile The profile selected by User
     * @returns {Array<Profiles>} profiles
     */
    _updateSelectedProfile(profiles = []) {
        if (!profiles || profiles.length === 0) {
            return profiles
        }

        const selectedProfile = this._selectProfile(profiles)

        return profiles.reduce((acc, profile) => {
            return [
                ...acc,
                profile.update({
                    selectedProfile:
                        profile &&
                        selectedProfile &&
                        profile.profileId === selectedProfile.profileId,
                }),
            ]
        }, [])
    }

    static _HAS_ALREADY_LOGIN_ONCE = false
    _setHasLogin(hasLogin = true) {
        AuthApi._HAS_ALREADY_LOGIN_ONCE = hasLogin
    }
    _getHasAlreadyLoginOnce() {
        return AuthApi._HAS_ALREADY_LOGIN_ONCE
    }

    // -----------------------------------------------------------
    // ----------                STUB                   ----------
    // -----------------------------------------------------------
    initGuestMode() {
        return of(true)
    }

    loginTvService({ serviceId }) {
        return of(true)
    }

    getIsOutOfHome() {
        return of(false)
    }

    getIsSuspended() {
        return of(false)
    }

    isBackendAvailable = () => {
        return of(true)
    }
    // -----------------------------------------------------------
    // ----------           END OF STUB                 ----------
    // -----------------------------------------------------------

    // -----------------------------------------------------------
    // ----------         FEATURE NOT SUPPORTED         ----------
    // -----------------------------------------------------------
    signUp(authFactor, data) {
        return throwError(
            new VoltError(VoltError.codes.FEATURE_NOT_AVAILABLE, {
                extraLog: '[signUp] Feature yet not implemented',
            })
        )
    }

    getTvServices() {
        return throwError(
            new VoltError(VoltError.codes.FEATURE_NOT_AVAILABLE, {
                extraLog: '[getTvServices] Feature yet not implemented',
            })
        )
    }

    validateOTP(authType, data) {
        return throwError(
            new VoltError(VoltError.codes.FEATURE_NOT_AVAILABLE, {
                extraLog: '[validateOTP] Feature yet not implemented',
            })
        )
    }

    loginWithSecondaryDevice = ({ deviceId, forceHeaders, deviceData, forceBody }) => {
        return throwError(
            new VoltError(VoltError.codes.FEATURE_NOT_AVAILABLE, {
                extraLog: '[loginWithSecondaryDevice] Feature yet not implemented',
            })
        )
    }

    isLoginWithSecondaryDevice = ({ forcePayload, isUsingParamPayload = false }) => {
        return throwError(
            new VoltError(VoltError.codes.FEATURE_NOT_AVAILABLE, {
                extraLog: '[isLoginWithSecondaryDevice] Feature yet not implemented',
            })
        )
    }
    // -----------------------------------------------------------
    // ----------     END FEATURE NOT SUPPORTED         ----------
    // -----------------------------------------------------------
}
