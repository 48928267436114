import {
    Analytics,
    AnalyticsCallOptions,
    setDefaultEventParameters,
    setUserId,
    setUserProperties,
} from 'firebase/analytics'

interface AnalyticsStoreWrapperInterface {
    isStub: boolean
    setAnalyticsCollectionEnabled: () => Promise<boolean>
    resetAnalyticsData: () => Promise<void>
    logScreenView: (eventParams: any) => Promise<void>
    logAppOpen: () => Promise<void>
    logLogin: (eventParams: any) => Promise<void>
    logSignUp: (eventParams: any) => Promise<void>
    logTutorialBegin: () => Promise<void>
    logTutorialComplete: () => Promise<void>
    logSelectContent: (eventParams: any) => Promise<void>
    logSelectItem: (eventParams: any) => Promise<void>
    logSelectPromotion: (eventParams: any) => Promise<void>
    logPurchase: (eventParams: any) => Promise<void>
    logSearch: (eventParams: {search_term: String}) => Promise<void>
    setUserId: (uuid: string) => Promise<void>
    setUserProperty: (key: string, value: string) => Promise<void>
    setUserProperties: (properties: {[key: string]: any}) => Promise<void>
    setDefaultEventParameters: (args: any) => Promise<void>
    setSessionTimeoutDuration: (milliseconds: number) => Promise<void>
    logEvent: (
        eventName: string,
        eventParams?: {[key: string]: any} | undefined,
        options?: AnalyticsCallOptions | undefined
    ) => Promise<void>
}

export class AnalyticsStoreWrapper implements AnalyticsStoreWrapperInterface {
    analytics: Analytics
    emitEvent: (
        eventName: string,
        eventParams?: {[key: string]: any} | undefined,
        options?: AnalyticsCallOptions | undefined
    ) => void
    enabled: boolean
    isStub: boolean = false

    constructor(
        analytics: Analytics,
        enabled: boolean,
        emitEvent: (
            eventName: string,
            eventParams?: {[key: string]: any} | undefined,
            options?: AnalyticsCallOptions | undefined
        ) => void,
        isStub: boolean
    ) {
        this.enabled = enabled
        this.analytics = analytics
        this.emitEvent = emitEvent
        this.isStub = isStub
    }

    logEvent(
        eventName: string,
        eventParams?: {[key: string]: any} | undefined,
        options?: AnalyticsCallOptions | undefined
    ): Promise<void> {
        return this.withPromise(eventName, eventParams, options)
    }

    logAppOpen(): Promise<void> {
        return this.withPromise('app_open')
    }

    logLogin(eventParams: {method: string}): Promise<void> {
        return this.withPromise('login', eventParams)
    }

    logPurchase(eventParams: any): Promise<void> {
        return this.withPromise('purchase', eventParams)
    }

    logScreenView(eventParams: any): Promise<void> {
        return this.withPromise('screen_view', eventParams)
    }

    logSearch(eventParams: any): Promise<void> {
        return this.withPromise('search', eventParams)
    }

    logSelectContent(eventParams: any): Promise<void> {
        return this.withPromise('select_content', eventParams)
    }

    logSelectItem(eventParams: any): Promise<void> {
        return this.withPromise('select_item', eventParams)
    }

    logSelectPromotion(eventParams: any): Promise<void> {
        return this.withPromise('select_promotion', eventParams)
    }

    logSignUp(eventParams: {method: string}): Promise<void> {
        return this.withPromise('sign_up', eventParams)
    }

    logTutorialBegin(): Promise<void> {
        return this.withPromise('tutorial_begin')
    }

    logTutorialComplete(): Promise<void> {
        return this.withPromise('tutorial_complete')
    }

    resetAnalyticsData(): Promise<void> {
        return Promise.resolve(undefined)
    }

    setAnalyticsCollectionEnabled(): Promise<boolean> {
        return Promise.resolve(this.enabled)
    }

    setDefaultEventParameters(args: any): Promise<void> {
        return Promise.resolve(setDefaultEventParameters(args))
    }

    setSessionTimeoutDuration(milliseconds: number): Promise<void> {
        return Promise.resolve(undefined)
    }

    setUserId(uuid: string): Promise<void> {
        return Promise.resolve(setUserId(this.analytics, uuid))
    }

    setUserProperties(properties: any): Promise<void> {
        return Promise.resolve(setUserProperties(this.analytics, properties))
    }

    setUserProperty(key: string, value: string): Promise<void> {
        return Promise.resolve(setUserProperties(this.analytics, {[key]: value}))
    }

    withPromise(
        eventName: string,
        eventParams?: {[key: string]: any} | undefined,
        options?: AnalyticsCallOptions | undefined
    ): Promise<void> {
        return Promise.resolve(this.emitEvent(eventName, eventParams, options))
    }
}
