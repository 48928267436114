import { Live, ProgramDetails } from 'models'
import { removeKeys, capFirstLetterEveryWord } from 'helpers/utils'
import { IMAGE_TYPE, getImage } from './helpers'
import { castingFactory, producerFactory, directorFactory } from './casting'

export const liveFactory = (program, clientConfig = {}) => {
    const { proxyVersion } = clientConfig

    program.channelId = program.channel_id

    if (program.runtime) {
        program.duration = program.runtime * 1000
    }

    if (program.broadcast_datetime) {
        try {
            if (Number.isFinite(program.broadcast_datetime)) {
                program.startTime = program.broadcast_datetime * 1000
            } else {
                program.startTime = new Date(program.broadcast_datetime).getTime()
            }
        } catch (e) {
            program.startTime = null
        }
    }

    if (program.broadcast_end_datetime) {
        if (Number.isFinite(program.broadcast_end_datetime)) {
            program.endTime = program.broadcast_end_datetime * 1000
        } else {
            program.endTime = new Date(program.broadcast_end_datetime).getTime()
        }
    } else if (program.startTime && program.duration) {
        program.endTime = program.startTime + program.duration
    }

    if (program.original_broadcast_datetime) {
        if (Number.isFinite(program.original_broadcast_datetime)) {
            program.originalBroadcastTime = program.original_broadcast_datetime * 1000
        } else {
            program.originalBroadcastTime = new Date(program.original_broadcast_datetime).getTime()
        }
    }

    program.sourceId = program.source_id
    program.platformId = program.platform_id
    // TODO Seems not populated by Maculosa and not documented either
    program.externalContentId = program.program_source_id

    if (program.episode_number) program.episodeNumber = parseInt(program.episode_number, 10)
    if (program.subtitle) program.episodeTitle = program.subtitle
    if (program.serie_title) program.serieTitle = program.serie_title

    // season_number & season_id: empty = not an Episode, filled = Episode (often with -1: no specific season, just grouped together)
    program.seasonNumber = program.season_number
    program.season = program.season_id
    program.serieId = program.serie_id
    program.eventType = program.category

    program.ratingId = program.rating_id || program.rating // Proxy v3 field is rating
    program.pcValue = (clientConfig.pcLevels || {})[program.ratingId] || 0

    if (program.pictures) {
        if (Array.isArray(program.pictures.thumbnails))
            program.thumbnail = program.pictures.thumbnails[0]
        if (Array.isArray(program.pictures.backdrops))
            program.backdrop = program.pictures.backdrops[0]
    }

    if (program.category) {
        program.categoryAsString = program.category
        program.categories = program.category
            .toLowerCase()
            .split(/[,]/)
            .map(capFirstLetterEveryWord)
            .filter((x) => !!x)
    }

    program.details = new ProgramDetails({})

    const casting = program.casts || program.cast

    if (casting) {
        program.details.cast = castingFactory(casting, proxyVersion)
    }

    if (program.producers) {
        program.details.producers = producerFactory(program.producers, proxyVersion)
    }
    if (program.directors) {
        program.details.directors = directorFactory(program.directors, proxyVersion)
    }

    if (clientConfig && clientConfig.proxyVersion >= 3) {
        program.thumbnail = getImage(program.pictures, IMAGE_TYPE.THUMBNAIL)
        program.backdrop = getImage(program.pictures, IMAGE_TYPE.BACKDROP)
        program.categoryAsString = program.genres && program.genres.join(',')
        program.categories = program.genres
        program.endTime = program.end * 1000
        program.startTime = program.start * 1000
        program.eventType = program.genres && program.genres.join(',')
    }

    if (program.rights) {
        if (program.rights.record) {
            program.recordable = program.rights.record.capable
        }
    }

    program.optaDetails = {
        optaFixtureId: program.opta_fixture_id,
        optaSport: program.opta_sport,
        optaTeamId: program.opta_team_id,
        optaCompetitionCode: program.opta_competition_code,
    }
    // Debug mode; force game ID
    if (
        program.optaDetails.optaFixtureId &&
        clientConfig.opta &&
        clientConfig.opta.forceFixtureId
    ) {
        program.optaDetails.optaFixtureId = clientConfig.opta.forceFixtureId
    }

    program.scheduleType = program.schedule_type

    if (program.revision) {
        program.revision = program.revision * 1000
    }

    if (program.support_multicam) {
        program.supportMulticam = program.support_multicam
    }

    removeKeys(program, { clearUndefined: true })

    return new Live({
        ...program,
        id: program.platformId,
        proxyId: program.id,
    })
}
