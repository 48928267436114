import { uniq } from 'lodash'

import { Vod } from './Vod'
import { ITvShow } from '@typings/tile'
import Constants from 'api-constants'

export class TvShow extends Vod {
    childrenCount: number
    lastViewedEpisode: string | number
    seasonIds: (string | number)[]
    episodesList: string[]
    hasSeasonsTree: boolean

    constructor(props: ITvShow) {
        super(props)

        this.childrenCount = props.childrenCount

        // id of the last viewed episode (or, depending on the backend, the furthest bookmarked episode)
        this.lastViewedEpisode = props.lastViewedEpisode

        const computePropValue = this._makeComputePropValue(props)
        this.seasonIds = computePropValue('seasonIds', [])
        this.episodesList = computePropValue('episodesList', [])

        // true when seasons are being fetched
        this.pending = computePropValue('pending', false)

        this.hasSeasonsTree = computePropValue('hasSeasonsTree', false)

        this.vodType = Constants.programType.series
    }

    isTvShow() {
        return true
    }

    getPropsToMerge() {
        return ['seasonIds', ...super.getPropsToMerge()]
    }

    /** Override parent method to apply an `uniq` on merged `seasonIds` */
    _mergePropsForUpdate(currentProps: this, newProps: this, bypassMerge = []) {
        // Keep original merge behaviour
        const mergedProps = super._mergePropsForUpdate(currentProps, newProps, bypassMerge)

        // Apply lodash `uniq` function on seasonIds properties
        mergedProps.seasonIds = mergedProps.seasonIds && uniq(mergedProps.seasonIds)

        return mergedProps
    }
}
