import { DvrSeries } from 'models'
import { dvrFactoryV2 } from './dvrFactoryV2'

export const dvrSeriesFactory = ({ dvr, config }) => {
    if (!dvr) return

    return new DvrSeries({
        id: dvr.periodPVRTaskId,
        recordingId: dvr.periodPVRTaskId,
        seriesId: dvr.seriesID,
        originalAssetTitle: dvr.periodPVRTaskName,
        children: dvr.pvrList.map((pvr) => dvrFactoryV2({ dvr: pvr, config })),
    })
}
