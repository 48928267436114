/**
 * Serialize given parameters to product GET parameters.
 *
 * Filter out undefined values, encode remaining key/value and join them with a &
 *
 * @example
 * serialize({ foo: 'bar', action: 'gimme$$'}) // produce ?foo=bar&action=gimme%24%24
 *
 * @param {Object} obj Key/Value hash where key is paramName and value is paramValue
 * @param {String} [delimiter=?] Define the delimiter appended after the endpoint
 * @return {String} serialized parameters
 */
export const serialize = (obj, delimiter = '?') => {
    return (
        delimiter +
        Object.keys(obj)
            .filter((queryKey) => obj[queryKey] !== undefined)
            .map(
                (queryKey) => `${encodeURIComponent(queryKey)}=${encodeURIComponent(obj[queryKey])}`
            )
            .join('&')
    )
}
