export default {
    matchInfo: {
        id: '7c2f1r3fe7code6ijoi1xzh1w',
        coverageLevel: '15',
        date: '2022-08-28Z',
        time: '15:30:00Z',
        localDate: '2022-08-28',
        localTime: '16:30:00',
        week: '4',
        numberOfPeriods: 2,
        periodLength: 45,
        lastUpdated: '2022-08-28T17:04:50Z',
        description: 'Nottingham Forest vs Tottenham Hotspur',
        sport: {
            id: '289u5typ3vp4ifwh5thalohmq',
            name: 'Soccer',
        },
        ruleset: {
            id: '79plas4983031idr6vw83nuel',
            name: 'Men',
        },
        competition: {
            id: '2kwbbcootiqqgmrzs6o5inle5',
            name: 'Premier League',
            competitionCode: 'EPL',
            competitionFormat: 'Domestic league',
            country: {
                id: '1fk5l4hkqk12i7zske6mcqju6',
                name: 'England',
            },
        },
        tournamentCalendar: {
            id: '80foo89mm28qjvyhjzlpwj28k',
            startDate: '2022-08-05Z',
            endDate: '2023-05-28Z',
            name: '2022/2023',
        },
        stage: {
            id: '80qbeanalyj5cvxikkq351iqc',
            formatId: 'e2q01r9o9jwiq1fls93d1sslx',
            startDate: '2022-08-05Z',
            endDate: '2023-05-28Z',
            name: 'Regular Season',
        },
        contestant: [
            {
                id: '1qtaiy11gswx327s0vkibf70n',
                name: 'Nottingham Forest',
                shortName: 'Nottm Forest',
                officialName: 'Nottingham Forest FC',
                code: 'NFO',
                position: 'home',
                country: {
                    id: '1fk5l4hkqk12i7zske6mcqju6',
                    name: 'England',
                },
            },
            {
                id: '22doj4sgsocqpxw45h607udje',
                name: 'Tottenham Hotspur',
                shortName: 'Tottenham',
                officialName: 'Tottenham Hotspur FC',
                code: 'TOT',
                position: 'away',
                country: {
                    id: '1fk5l4hkqk12i7zske6mcqju6',
                    name: 'England',
                },
            },
        ],
        venue: {
            id: '6ccyj8bk3iv2mc01xe5rtch3r',
            neutral: 'no',
            longName: 'The City Ground',
            shortName: 'The City Ground',
        },
    },
    liveData: {
        matchDetails: {
            matchTime: 78,
            periodId: 2,
            matchStatus: 'Playing',
            period: [
                {
                    id: 1,
                    start: '2022-08-28T15:30:17Z',
                    end: '2022-08-28T16:16:23Z',
                    lengthMin: 46,
                    lengthSec: 6,
                },
                {
                    id: 2,
                    start: '2022-08-28T16:31:24Z',
                },
            ],
            scores: {
                ht: {
                    home: 0,
                    away: 1,
                },
                ft: {
                    home: 0,
                    away: 1,
                },
                total: {
                    home: 0,
                    away: 1,
                },
            },
        },
        goal: [
            {
                contestantId: '22doj4sgsocqpxw45h607udje',
                periodId: 1,
                timeMin: 5,
                timeMinSec: '4:58',
                lastUpdated: '2022-08-28T15:36:23Z',
                timestamp: '2022-08-28T15:35:15Z',
                type: 'G',
                scorerId: 'b9g4qurpll4wizajo95c406hh',
                scorerName: 'H. Kane',
                assistPlayerId: 'dlf31m2lrfl0biop1demgvh7t',
                assistPlayerName: 'D. Kulusevski',
                optaEventId: '2452502131',
                homeScore: 0,
                awayScore: 1,
            },
        ],
        missedPen: [
            {
                contestantId: '22doj4sgsocqpxw45h607udje',
                periodId: 2,
                timeMin: 56,
                timeMinSec: '55:32',
                lastUpdated: '2022-08-28T16:42:34Z',
                timestamp: '2022-08-28T16:41:57Z',
                type: 'PM',
                playerId: 'b9g4qurpll4wizajo95c406hh',
                playerName: 'H. Kane',
                optaEventId: '2452561675',
            },
            {
                contestantId: '1qtaiy11gswx327s0vkibf70n',
                periodId: 2,
                timeMin: 56,
                timeMinSec: '55:32',
                lastUpdated: '2022-08-28T16:42:34Z',
                type: 'PS',
                playerId: '5z8ycnd0smdkumi5fgtzf7c9h',
                playerName: 'D. Henderson',
                optaEventId: '2452561675',
            },
        ],
        card: [
            {
                contestantId: '1qtaiy11gswx327s0vkibf70n',
                periodId: 2,
                timeMin: 54,
                timeMinSec: '53:10',
                lastUpdated: '2022-08-28T16:41:15Z',
                timestamp: '2022-08-28T16:39:34Z',
                type: 'YC',
                playerId: 'av7e2wk5ub7qnh3sqcvssvh79',
                playerName: 'S. Cook',
                optaEventId: '2452560733',
                cardReason: 'Handball',
            },
            {
                contestantId: '1qtaiy11gswx327s0vkibf70n',
                periodId: 2,
                timeMin: 58,
                timeMinSec: '57:14',
                lastUpdated: '2022-08-28T16:43:44Z',
                timestamp: '2022-08-28T16:43:38Z',
                type: 'YC',
                playerId: '1tuivl8zdnsaz8vbed2z582qx',
                playerName: 'J. Worrall',
                optaEventId: '2452563465',
                cardReason: 'Foul',
            },
            {
                contestantId: '1qtaiy11gswx327s0vkibf70n',
                periodId: 2,
                timeMin: 61,
                timeMinSec: '60:04',
                lastUpdated: '2022-08-28T16:46:35Z',
                timestamp: '2022-08-28T16:46:29Z',
                type: 'YC',
                playerId: '12cgiy0t282ywdbr5j78hrxsl',
                playerName: 'S. McKenna',
                optaEventId: '2452566523',
                cardReason: 'Foul',
            },
            {
                contestantId: '1qtaiy11gswx327s0vkibf70n',
                periodId: 2,
                timeMin: 73,
                timeMinSec: '72:18',
                lastUpdated: '2022-08-28T16:58:50Z',
                timestamp: '2022-08-28T16:58:42Z',
                type: 'YC',
                playerId: 'dcq0nu7mmecab5djjwg0n8ktm',
                playerName: "L. O'Brien",
                optaEventId: '2452576759',
                cardReason: 'Foul',
            },
        ],
        substitute: [
            {
                contestantId: '1qtaiy11gswx327s0vkibf70n',
                periodId: 2,
                timeMin: 69,
                timeMinSec: '68:49',
                lastUpdated: '2022-08-28T16:55:21Z',
                timestamp: '2022-08-28T16:55:13Z',
                playerOnId: '3ztcz2dgfniqv141w7xsky7zd',
                playerOnName: 'E. Dennis',
                playerOffId: '5f72vvw62zdlyrj3mwux2udat',
                playerOffName: 'J. Lingard',
                subReason: 'Tactical',
            },
            {
                contestantId: '22doj4sgsocqpxw45h607udje',
                periodId: 2,
                timeMin: 74,
                timeMinSec: '73:02',
                lastUpdated: '2022-08-28T16:59:34Z',
                timestamp: '2022-08-28T16:59:26Z',
                playerOnId: 'cag3gzjcv8erx3zpp2lidzcvd',
                playerOnName: 'R. Sessegnon',
                playerOffId: '2gsq05yyxwe4f8t79j6d49q6t',
                playerOffName: 'I. Perišić',
                subReason: 'Tactical',
            },
            {
                contestantId: '22doj4sgsocqpxw45h607udje',
                periodId: 2,
                timeMin: 74,
                timeMinSec: '73:23',
                lastUpdated: '2022-08-28T16:59:56Z',
                timestamp: '2022-08-28T16:59:47Z',
                playerOnId: 'c5zmr0m17riujelm4up44etih',
                playerOnName: 'Richarlison',
                playerOffId: '12ekwo6gbza410blv4b4r7ydx',
                playerOffName: 'Son Heung-Min',
                subReason: 'Tactical',
            },
            {
                contestantId: '1qtaiy11gswx327s0vkibf70n',
                periodId: 2,
                timeMin: 75,
                timeMinSec: '74:23',
                lastUpdated: '2022-08-28T17:00:54Z',
                timestamp: '2022-08-28T17:00:48Z',
                playerOnId: '19nlw3sa0khwz0el4wqc7zrp',
                playerOnName: 'R. Freuler',
                playerOffId: 'dcq0nu7mmecab5djjwg0n8ktm',
                playerOffName: "L. O'Brien",
                subReason: 'Tactical',
            },
            {
                contestantId: '1qtaiy11gswx327s0vkibf70n',
                periodId: 2,
                timeMin: 75,
                timeMinSec: '74:28',
                lastUpdated: '2022-08-28T17:01:02Z',
                timestamp: '2022-08-28T17:00:52Z',
                playerOnId: 'a6m9jcx0e4l6krv1u0pbfuoyd',
                playerOnName: 'C. Kouyaté',
                playerOffId: 'ece7xph4emgawvh7mr56319ex',
                playerOffName: 'R. Yates',
                subReason: 'Tactical',
            },
            {
                contestantId: '1qtaiy11gswx327s0vkibf70n',
                periodId: 2,
                timeMin: 75,
                timeMinSec: '74:44',
                lastUpdated: '2022-08-28T17:01:15Z',
                timestamp: '2022-08-28T17:01:08Z',
                playerOnId: '55eiicx10oqok1601fqyovt1x',
                playerOnName: 'T. Awoniyi',
                playerOffId: 'av7e2wk5ub7qnh3sqcvssvh79',
                playerOffName: 'S. Cook',
                subReason: 'Tactical',
            },
        ],
        VAR: [
            {
                contestantId: '1qtaiy11gswx327s0vkibf70n',
                periodId: 2,
                timeMin: 55,
                timeMinSec: '54:46',
                lastUpdated: '2022-08-28T16:41:20Z',
                timestamp: '2022-08-28T16:41:10Z',
                type: 'Card upgrade',
                decision: 'Confirmed',
                outcome: 'No change',
                playerId: 'av7e2wk5ub7qnh3sqcvssvh79',
                playerName: 'S. Cook',
                optaEventId: '2452560939',
            },
        ],
        lineUp: [
            {
                contestantId: '1qtaiy11gswx327s0vkibf70n',
                formationUsed: '3421',
                teamOfficial: {
                    id: '7qb4jzx7wrt2syeio63jpjtgp',
                    firstName: 'Steven David',
                    lastName: 'Cooper',
                    type: 'manager',
                },
                stat: [
                    {
                        fh: '320',
                        sh: '148',
                        type: 'openPlayPass',
                        value: '468',
                    },
                    {
                        fh: '0',
                        sh: '2',
                        type: 'accurateKeeperThrows',
                        value: '2',
                    },
                    {
                        fh: '6',
                        sh: '3',
                        type: 'totalAttAssist',
                        value: '9',
                    },
                    {
                        fh: '4',
                        sh: '2',
                        type: 'shotOffTarget',
                        value: '6',
                    },
                    {
                        fh: '1',
                        sh: '0',
                        type: 'freekickCross',
                        value: '1',
                    },
                    {
                        fh: '13',
                        sh: '7',
                        type: 'totalCross',
                        value: '20',
                    },
                    {
                        fh: '6',
                        sh: '0',
                        type: 'attemptsObox',
                        value: '6',
                    },
                    {
                        fh: '8',
                        sh: '3',
                        type: 'crosses18yard',
                        value: '11',
                    },
                    {
                        fh: '11',
                        sh: '6',
                        type: 'totalCrossNocorner',
                        value: '17',
                    },
                    {
                        fh: '24',
                        sh: '12',
                        type: 'longPassOwnToOppSuccess',
                        value: '36',
                    },
                    {
                        fh: '173',
                        sh: '71',
                        type: 'accurateFwdZonePass',
                        value: '244',
                    },
                    {
                        fh: '1',
                        sh: '2',
                        type: 'attHdTotal',
                        value: '3',
                    },
                    {
                        fh: '81',
                        sh: '35',
                        type: 'possLostAll',
                        value: '116',
                    },
                    {
                        fh: '0',
                        sh: '1',
                        type: 'totalHighClaim',
                        value: '1',
                    },
                    {
                        fh: '1',
                        sh: '0',
                        type: 'lastManTackle',
                        value: '1',
                    },
                    {
                        fh: '0',
                        sh: '1',
                        type: 'goodHighClaim',
                        value: '1',
                    },
                    {
                        fh: '3',
                        sh: '2',
                        type: 'attIboxMiss',
                        value: '5',
                    },
                    {
                        fh: '270',
                        sh: '127',
                        type: 'successfulOpenPlayPass',
                        value: '397',
                    },
                    {
                        fh: '81',
                        sh: '35',
                        type: 'possLostCtrl',
                        value: '116',
                    },
                    {
                        fh: '0',
                        sh: '4',
                        type: 'totalYellowCard',
                        value: '4',
                    },
                    {
                        fh: '4',
                        sh: '2',
                        type: 'effectiveClearance',
                        value: '6',
                    },
                    {
                        fh: '5',
                        sh: '4',
                        type: 'putThrough',
                        value: '9',
                    },
                    {
                        fh: '1',
                        sh: '1',
                        type: 'attCorner',
                        value: '2',
                    },
                    {
                        fh: '1',
                        sh: '0',
                        type: 'goalsConcededObox',
                        value: '1',
                    },
                    {
                        fh: '4',
                        sh: '1',
                        type: 'blockedScoringAtt',
                        value: '5',
                    },
                    {
                        fh: '43',
                        sh: '24',
                        type: 'finalThirdEntries',
                        value: '67',
                    },
                    {
                        fh: '4',
                        sh: '3',
                        type: 'successfulPutThrough',
                        value: '7',
                    },
                    {
                        fh: '0',
                        sh: '1',
                        type: 'contentiousDecision',
                        value: '1',
                    },
                    {
                        fh: '9',
                        sh: '6',
                        type: 'possWonDef3rd',
                        value: '15',
                    },
                    {
                        fh: '329',
                        sh: '162',
                        type: 'totalPass',
                        value: '491',
                    },
                    {
                        fh: '11',
                        sh: '3',
                        type: 'accurateThrows',
                        value: '14',
                    },
                    {
                        fh: '10',
                        sh: '6',
                        type: 'totalTackle',
                        value: '16',
                    },
                    {
                        fh: '2',
                        sh: '2',
                        type: 'totalLayoffs',
                        value: '4',
                    },
                    {
                        fh: '0',
                        sh: '1',
                        type: 'attCmissRight',
                        value: '1',
                    },
                    {
                        fh: '0',
                        sh: '1',
                        type: 'sixYardBlock',
                        value: '1',
                    },
                    {
                        fh: '1',
                        sh: '0',
                        type: 'goalsConceded',
                        value: '1',
                    },
                    {
                        fh: '43',
                        sh: '19',
                        type: 'longPassOwnToOpp',
                        value: '62',
                    },
                    {
                        fh: '1',
                        sh: '0',
                        type: 'attBxRight',
                        value: '1',
                    },
                    {
                        fh: '2',
                        sh: '1',
                        type: 'cornerTaken',
                        value: '3',
                    },
                    {
                        fh: '1',
                        sh: '1',
                        type: 'attCmissLeft',
                        value: '2',
                    },
                    {
                        fh: '6',
                        sh: '3',
                        type: 'attAssistOpenplay',
                        value: '9',
                    },
                    {
                        fh: '1',
                        sh: '0',
                        type: 'attOboxTarget',
                        value: '1',
                    },
                    {
                        fh: '4',
                        sh: '0',
                        type: 'attOboxBlocked',
                        value: '4',
                    },
                    {
                        fh: '54',
                        sh: '23',
                        type: 'passesLeft',
                        value: '77',
                    },
                    {
                        fh: '1',
                        sh: '3',
                        type: 'wonContest',
                        value: '4',
                    },
                    {
                        fh: '33',
                        sh: '20',
                        type: 'totalLongBalls',
                        value: '53',
                    },
                    {
                        fh: '6',
                        sh: '1',
                        type: 'attRfTotal',
                        value: '7',
                    },
                    {
                        fh: '5',
                        sh: '5',
                        type: 'interceptionWon',
                        value: '10',
                    },
                    {
                        fh: '1',
                        sh: '0',
                        type: 'attLfTarget',
                        value: '1',
                    },
                    {
                        fh: '7',
                        sh: '3',
                        type: 'totalLaunches',
                        value: '10',
                    },
                    {
                        fh: '0',
                        sh: '2',
                        type: 'saves',
                        value: '2',
                    },
                    {
                        fh: '1',
                        sh: '0',
                        type: 'totalOffside',
                        value: '1',
                    },
                    {
                        fh: '9',
                        sh: '3',
                        type: 'totalScoringAtt',
                        value: '12',
                    },
                    {
                        fh: '2',
                        sh: '2',
                        type: 'attBxCentre',
                        value: '4',
                    },
                    {
                        fh: '2',
                        sh: '0',
                        type: 'attMissHigh',
                        value: '2',
                    },
                    {
                        fh: '2',
                        sh: '0',
                        type: 'attLfTotal',
                        value: '2',
                    },
                    {
                        fh: '7',
                        sh: '5',
                        type: 'aerialWon',
                        value: '12',
                    },
                    {
                        fh: '105',
                        sh: '46',
                        type: 'leftsidePass',
                        value: '151',
                    },
                    {
                        fh: '1',
                        sh: '3',
                        type: 'outfielderBlock',
                        value: '4',
                    },
                    {
                        fh: '8',
                        sh: '4',
                        type: 'aerialLost',
                        value: '12',
                    },
                    {
                        fh: '8',
                        sh: '2',
                        type: 'attOpenplay',
                        value: '10',
                    },
                    {
                        fh: '0',
                        sh: '1',
                        type: 'divingSave',
                        value: '1',
                    },
                    {
                        fh: '3',
                        sh: '3',
                        type: 'attemptsIbox',
                        value: '6',
                    },
                    {
                        fh: '0',
                        sh: '2',
                        type: 'attemptedTackleFoul',
                        value: '2',
                    },
                    {
                        fh: '1',
                        sh: '1',
                        type: 'totalKeeperSweeper',
                        value: '2',
                    },
                    {
                        fh: '0',
                        sh: '1',
                        type: 'accurateLaunches',
                        value: '1',
                    },
                    {
                        fh: '3',
                        sh: '2',
                        type: 'offtargetAttAssist',
                        value: '5',
                    },
                    {
                        fh: '2',
                        sh: '0',
                        type: 'fouledFinalThird',
                        value: '2',
                    },
                    {
                        fh: '1',
                        sh: '1',
                        type: 'dispossessed',
                        value: '2',
                    },
                    {
                        fh: '9',
                        sh: '1',
                        type: 'possWonAtt3rd',
                        value: '10',
                    },
                    {
                        fh: '6',
                        sh: '5',
                        type: 'totalContest',
                        value: '11',
                    },
                    {
                        fh: '1',
                        sh: '6',
                        type: 'attemptsConcededIbox',
                        value: '7',
                    },
                    {
                        fh: '21',
                        sh: '10',
                        type: 'touchesInOppBox',
                        value: '31',
                    },
                    {
                        fh: '47',
                        sh: '19',
                        type: 'backwardPass',
                        value: '66',
                    },
                    {
                        fh: '2',
                        sh: '1',
                        type: 'wonCorners',
                        value: '3',
                    },
                    {
                        fh: '1',
                        sh: '1',
                        type: 'attHdMiss',
                        value: '2',
                    },
                    {
                        fh: '1',
                        sh: '1',
                        type: 'accurateKeeperSweeper',
                        value: '2',
                    },
                    {
                        fh: '1',
                        sh: '3',
                        type: 'accurateGoalKicks',
                        value: '4',
                    },
                    {
                        fh: '5',
                        sh: '4',
                        type: 'wonTackle',
                        value: '9',
                    },
                    {
                        fh: '2',
                        sh: '2',
                        type: 'accurateLayoffs',
                        value: '4',
                    },
                    {
                        fh: '0',
                        sh: '1',
                        type: 'attIboxBlocked',
                        value: '1',
                    },
                    {
                        fh: '21',
                        sh: '21',
                        type: 'duelWon',
                        value: '42',
                    },
                    {
                        fh: '0',
                        sh: '4',
                        type: 'subsMade',
                        value: '4',
                    },
                    {
                        fh: '0',
                        sh: '1',
                        type: 'lostCorners',
                        value: '1',
                    },
                    {
                        fh: '2',
                        sh: '0',
                        type: 'effectiveHeadClearance',
                        value: '2',
                    },
                    {
                        fh: '2',
                        sh: '3',
                        type: 'crosses18yardplus',
                        value: '5',
                    },
                    {
                        fh: '43',
                        sh: '18',
                        type: 'passesRight',
                        value: '61',
                    },
                    {
                        fh: '2',
                        sh: '6',
                        type: 'fkFoulLost',
                        value: '8',
                    },
                    {
                        fh: '1',
                        sh: '1',
                        type: 'handBall',
                        value: '2',
                    },
                    {
                        fh: '0',
                        sh: '2',
                        type: 'savedIbox',
                        value: '2',
                    },
                    {
                        fh: '234',
                        sh: '93',
                        type: 'totalFwdZonePass',
                        value: '327',
                    },
                    {
                        fh: '1',
                        sh: '1',
                        type: 'accurateCrossNocorner',
                        value: '2',
                    },
                    {
                        fh: '1',
                        sh: '1',
                        type: 'attMissRight',
                        value: '2',
                    },
                    {
                        fh: '2',
                        sh: '1',
                        type: 'totalCornersIntobox',
                        value: '3',
                    },
                    {
                        fh: '104',
                        sh: '71',
                        type: 'accurateBackZonePass',
                        value: '175',
                    },
                    {
                        fh: '3',
                        sh: '1',
                        type: 'ontargetAttAssist',
                        value: '4',
                    },
                    {
                        fh: '12',
                        sh: '3',
                        type: 'totalThrows',
                        value: '15',
                    },
                    {
                        fh: '4',
                        sh: '2',
                        type: 'attemptsConcededObox',
                        value: '6',
                    },
                    {
                        fh: '1',
                        sh: '2',
                        type: 'accurateCross',
                        value: '3',
                    },
                    {
                        fh: '16',
                        sh: '5',
                        type: 'possWonMid3rd',
                        value: '21',
                    },
                    {
                        fh: '34',
                        sh: '14',
                        type: 'ballRecovery',
                        value: '48',
                    },
                    {
                        fh: '17',
                        sh: '13',
                        type: 'duelLost',
                        value: '30',
                    },
                    {
                        fh: '412',
                        sh: '223',
                        type: 'touches',
                        value: '635',
                    },
                    {
                        fh: '3',
                        sh: '4',
                        type: 'goalKicks',
                        value: '7',
                    },
                    {
                        fh: '0',
                        sh: '1',
                        type: 'penaltySave',
                        value: '1',
                    },
                    {
                        fh: '8',
                        sh: '5',
                        type: 'unsuccessfulTouch',
                        value: '13',
                    },
                    {
                        fh: '1',
                        sh: '0',
                        type: 'attOboxMiss',
                        value: '1',
                    },
                    {
                        fh: '126',
                        sh: '48',
                        type: 'totalFinalThirdPasses',
                        value: '174',
                    },
                    {
                        fh: '89',
                        sh: '47',
                        type: 'rightsidePass',
                        value: '136',
                    },
                    {
                        fh: '276',
                        sh: '140',
                        type: 'accuratePass',
                        value: '416',
                    },
                    {
                        fh: '1',
                        sh: '1',
                        type: 'attMissLeft',
                        value: '2',
                    },
                    {
                        fh: '4',
                        sh: '2',
                        type: 'totalClearance',
                        value: '6',
                    },
                    {
                        fh: '0',
                        sh: '1',
                        type: 'penaltyFaced',
                        value: '1',
                    },
                    {
                        fh: '2',
                        sh: '1',
                        type: 'challengeLost',
                        value: '3',
                    },
                    {
                        fh: '25',
                        sh: '9',
                        type: 'penAreaEntries',
                        value: '34',
                    },
                    {
                        fh: '108',
                        sh: '76',
                        type: 'totalBackZonePass',
                        value: '184',
                    },
                    {
                        fh: '88',
                        sh: '50',
                        type: 'fwdPass',
                        value: '138',
                    },
                    {
                        fh: '10',
                        sh: '8',
                        type: 'accurateChippedPass',
                        value: '18',
                    },
                    {
                        fh: '92',
                        sh: '35',
                        type: 'successfulFinalThirdPasses',
                        value: '127',
                    },
                    {
                        fh: '3',
                        sh: '7',
                        type: 'fkFoulWon',
                        value: '10',
                    },
                    {
                        fh: '58.8',
                        sh: '50.7',
                        type: 'possessionPercentage',
                        value: '55.8',
                    },
                    {
                        fh: '5',
                        sh: '5',
                        type: 'interception',
                        value: '10',
                    },
                    {
                        fh: '0',
                        sh: '1',
                        type: 'accurateCornersIntobox',
                        value: '1',
                    },
                    {
                        fh: '1',
                        sh: '0',
                        type: 'ontargetScoringAtt',
                        value: '1',
                    },
                    {
                        fh: '21',
                        sh: '12',
                        type: 'totalChippedPass',
                        value: '33',
                    },
                    {
                        fh: '0',
                        sh: '1',
                        type: 'interceptionsInBox',
                        value: '1',
                    },
                    {
                        fh: '14',
                        sh: '11',
                        type: 'accurateLongBalls',
                        value: '25',
                    },
                    {
                        fh: '0',
                        sh: '2',
                        type: 'keeperThrows',
                        value: '2',
                    },
                    {
                        fh: '0',
                        sh: '1',
                        type: 'penaltyConceded',
                        value: '1',
                    },
                    {
                        fh: '1',
                        sh: '0',
                        type: 'attSvLowCentre',
                        value: '1',
                    },
                    {
                        fh: '6',
                        sh: '0',
                        type: 'attObxCentre',
                        value: '6',
                    },
                    {
                        fh: '2',
                        sh: '0',
                        type: 'headClearance',
                        value: '2',
                    },
                    {
                        fh: '5',
                        sh: '4',
                        type: 'blockedPass',
                        value: '9',
                    },
                    {
                        type: 'formationUsed',
                        value: '3421',
                    },
                ],
                kit: {
                    id: '4782',
                    colour1: '#FF0000',
                    colour2: '#FFFFFF',
                    type: 'home',
                },
            },
            {
                contestantId: '22doj4sgsocqpxw45h607udje',
                formationUsed: '3421',
                teamOfficial: {
                    id: '2t14kex330qljt5vlmssvo6ad',
                    firstName: 'Antonio',
                    lastName: 'Conte Cavaliere',
                    type: 'manager',
                },
                stat: [
                    {
                        fh: '65',
                        sh: '45',
                        type: 'leftsidePass',
                        value: '110',
                    },
                    {
                        fh: '8',
                        sh: '4',
                        type: 'aerialWon',
                        value: '12',
                    },
                    {
                        fh: '1',
                        sh: '1',
                        type: 'shotFastbreak',
                        value: '2',
                    },
                    {
                        fh: '5',
                        sh: '8',
                        type: 'totalScoringAtt',
                        value: '13',
                    },
                    {
                        fh: '0',
                        sh: '1',
                        type: 'attBxCentre',
                        value: '1',
                    },
                    {
                        fh: '1',
                        sh: '0',
                        type: 'attMissHigh',
                        value: '1',
                    },
                    {
                        fh: '1',
                        sh: '4',
                        type: 'attLfTotal',
                        value: '5',
                    },
                    {
                        fh: '1',
                        sh: '0',
                        type: 'savedObox',
                        value: '1',
                    },
                    {
                        fh: '0',
                        sh: '1',
                        type: 'attRfTarget',
                        value: '1',
                    },
                    {
                        fh: '1',
                        sh: '0',
                        type: 'divingSave',
                        value: '1',
                    },
                    {
                        fh: '1',
                        sh: '0',
                        type: 'attCmissHigh',
                        value: '1',
                    },
                    {
                        fh: '1',
                        sh: '6',
                        type: 'attemptsIbox',
                        value: '7',
                    },
                    {
                        fh: '2',
                        sh: '1',
                        type: 'attemptedTackleFoul',
                        value: '3',
                    },
                    {
                        fh: '0',
                        sh: '1',
                        type: 'penaltyWon',
                        value: '1',
                    },
                    {
                        fh: '0',
                        sh: '1',
                        type: 'totalKeeperSweeper',
                        value: '1',
                    },
                    {
                        fh: '1',
                        sh: '0',
                        type: 'goalAssist',
                        value: '1',
                    },
                    {
                        fh: '5',
                        sh: '7',
                        type: 'attOpenplay',
                        value: '12',
                    },
                    {
                        fh: '4',
                        sh: '1',
                        type: 'outfielderBlock',
                        value: '5',
                    },
                    {
                        fh: '1',
                        sh: '0',
                        type: 'goalsOpenplay',
                        value: '1',
                    },
                    {
                        fh: '7',
                        sh: '5',
                        type: 'aerialLost',
                        value: '12',
                    },
                    {
                        fh: '0',
                        sh: '1',
                        type: 'attSvLowLeft',
                        value: '1',
                    },
                    {
                        fh: '1',
                        sh: '2',
                        type: 'attOboxBlocked',
                        value: '3',
                    },
                    {
                        fh: '0',
                        sh: '1',
                        type: 'cornerTaken',
                        value: '1',
                    },
                    {
                        fh: '0',
                        sh: '4',
                        type: 'attBxRight',
                        value: '4',
                    },
                    {
                        fh: '2',
                        sh: '6',
                        type: 'attAssistOpenplay',
                        value: '8',
                    },
                    {
                        fh: '26',
                        sh: '15',
                        type: 'longPassOwnToOpp',
                        value: '41',
                    },
                    {
                        fh: '0',
                        sh: '1',
                        type: 'attLfTarget',
                        value: '1',
                    },
                    {
                        fh: '1',
                        sh: '0',
                        type: 'saves',
                        value: '1',
                    },
                    {
                        fh: '4',
                        sh: '3',
                        type: 'totalLaunches',
                        value: '7',
                    },
                    {
                        fh: '0',
                        sh: '1',
                        type: 'accurateFlickOn',
                        value: '1',
                    },
                    {
                        fh: '1',
                        sh: '2',
                        type: 'totalOffside',
                        value: '3',
                    },
                    {
                        fh: '4',
                        sh: '4',
                        type: 'attRfTotal',
                        value: '8',
                    },
                    {
                        fh: '0',
                        sh: '1',
                        type: 'accurateThroughBall',
                        value: '1',
                    },
                    {
                        fh: '11',
                        sh: '3',
                        type: 'interceptionWon',
                        value: '14',
                    },
                    {
                        fh: '2',
                        sh: '1',
                        type: 'wonContest',
                        value: '3',
                    },
                    {
                        fh: '25',
                        sh: '15',
                        type: 'totalLongBalls',
                        value: '40',
                    },
                    {
                        fh: '10',
                        sh: '12',
                        type: 'passesLeft',
                        value: '22',
                    },
                    {
                        fh: '1',
                        sh: '0',
                        type: 'successfulPutThrough',
                        value: '1',
                    },
                    {
                        fh: '0',
                        sh: '1',
                        type: 'bigChanceMissed',
                        value: '1',
                    },
                    {
                        fh: '1',
                        sh: '3',
                        type: 'blockedScoringAtt',
                        value: '4',
                    },
                    {
                        fh: '12',
                        sh: '17',
                        type: 'finalThirdEntries',
                        value: '29',
                    },
                    {
                        fh: '5',
                        sh: '3',
                        type: 'putThrough',
                        value: '8',
                    },
                    {
                        fh: '0',
                        sh: '1',
                        type: 'attMissHighLeft',
                        value: '1',
                    },
                    {
                        fh: '6',
                        sh: '3',
                        type: 'totalTackle',
                        value: '9',
                    },
                    {
                        fh: '1',
                        sh: '1',
                        type: 'attCmissRight',
                        value: '2',
                    },
                    {
                        fh: '232',
                        sh: '159',
                        type: 'totalPass',
                        value: '391',
                    },
                    {
                        fh: '7',
                        sh: '5',
                        type: 'accurateThrows',
                        value: '12',
                    },
                    {
                        fh: '24',
                        sh: '10',
                        type: 'possWonDef3rd',
                        value: '34',
                    },
                    {
                        fh: '2',
                        sh: '2',
                        type: 'crosses18yard',
                        value: '4',
                    },
                    {
                        fh: '4',
                        sh: '2',
                        type: 'attemptsObox',
                        value: '6',
                    },
                    {
                        fh: '3',
                        sh: '6',
                        type: 'totalAttAssist',
                        value: '9',
                    },
                    {
                        fh: '3',
                        sh: '3',
                        type: 'shotOffTarget',
                        value: '6',
                    },
                    {
                        fh: '4',
                        sh: '4',
                        type: 'totalCross',
                        value: '8',
                    },
                    {
                        fh: '224',
                        sh: '150',
                        type: 'openPlayPass',
                        value: '374',
                    },
                    {
                        fh: '2',
                        sh: '3',
                        type: 'accurateKeeperThrows',
                        value: '5',
                    },
                    {
                        fh: '1',
                        sh: '3',
                        type: 'attIboxMiss',
                        value: '4',
                    },
                    {
                        fh: '178',
                        sh: '128',
                        type: 'successfulOpenPlayPass',
                        value: '306',
                    },
                    {
                        fh: '69',
                        sh: '40',
                        type: 'possLostCtrl',
                        value: '109',
                    },
                    {
                        fh: '16',
                        sh: '5',
                        type: 'effectiveClearance',
                        value: '21',
                    },
                    {
                        fh: '69',
                        sh: '40',
                        type: 'possLostAll',
                        value: '109',
                    },
                    {
                        fh: '1',
                        sh: '0',
                        type: 'goals',
                        value: '1',
                    },
                    {
                        fh: '0',
                        sh: '1',
                        type: 'totalHighClaim',
                        value: '1',
                    },
                    {
                        fh: '1',
                        sh: '1',
                        type: 'totalFastbreak',
                        value: '2',
                    },
                    {
                        fh: '0',
                        sh: '1',
                        type: 'goodHighClaim',
                        value: '1',
                    },
                    {
                        fh: '49',
                        sh: '50',
                        type: 'accurateFwdZonePass',
                        value: '99',
                    },
                    {
                        fh: '0',
                        sh: '1',
                        type: 'totalThroughBall',
                        value: '1',
                    },
                    {
                        fh: '4',
                        sh: '3',
                        type: 'totalCrossNocorner',
                        value: '7',
                    },
                    {
                        fh: '16',
                        sh: '10',
                        type: 'longPassOwnToOppSuccess',
                        value: '26',
                    },
                    {
                        fh: '4',
                        sh: '3',
                        type: 'keeperThrows',
                        value: '7',
                    },
                    {
                        fh: '0',
                        sh: '1',
                        type: 'attCmissHighLeft',
                        value: '1',
                    },
                    {
                        fh: '17',
                        sh: '13',
                        type: 'totalChippedPass',
                        value: '30',
                    },
                    {
                        fh: '9',
                        sh: '6',
                        type: 'accurateLongBalls',
                        value: '15',
                    },
                    {
                        fh: '2',
                        sh: '0',
                        type: 'interceptionsInBox',
                        value: '2',
                    },
                    {
                        fh: '0',
                        sh: '1',
                        type: 'attPenTarget',
                        value: '1',
                    },
                    {
                        fh: '1',
                        sh: '2',
                        type: 'ontargetScoringAtt',
                        value: '3',
                    },
                    {
                        fh: '11',
                        sh: '3',
                        type: 'interception',
                        value: '14',
                    },
                    {
                        fh: '5',
                        sh: '3',
                        type: 'blockedPass',
                        value: '8',
                    },
                    {
                        fh: '7',
                        sh: '2',
                        type: 'headClearance',
                        value: '9',
                    },
                    {
                        fh: '1',
                        sh: '0',
                        type: 'attGoalLowLeft',
                        value: '1',
                    },
                    {
                        fh: '1',
                        sh: '0',
                        type: 'attOboxGoal',
                        value: '1',
                    },
                    {
                        fh: '0',
                        sh: '1',
                        type: 'attSvLowCentre',
                        value: '1',
                    },
                    {
                        fh: '4',
                        sh: '2',
                        type: 'attObxCentre',
                        value: '6',
                    },
                    {
                        fh: '1',
                        sh: '0',
                        type: 'punches',
                        value: '1',
                    },
                    {
                        fh: '0',
                        sh: '1',
                        type: 'attMissLeft',
                        value: '1',
                    },
                    {
                        fh: '1',
                        sh: '0',
                        type: 'forwardGoals',
                        value: '1',
                    },
                    {
                        fh: '55',
                        sh: '47',
                        type: 'rightsidePass',
                        value: '102',
                    },
                    {
                        fh: '34',
                        sh: '28',
                        type: 'totalFinalThirdPasses',
                        value: '62',
                    },
                    {
                        fh: '2',
                        sh: '0',
                        type: 'attOboxMiss',
                        value: '2',
                    },
                    {
                        fh: '184',
                        sh: '135',
                        type: 'accuratePass',
                        value: '319',
                    },
                    {
                        fh: '6',
                        sh: '3',
                        type: 'unsuccessfulTouch',
                        value: '9',
                    },
                    {
                        fh: '0',
                        sh: '1',
                        type: 'blockedCross',
                        value: '1',
                    },
                    {
                        fh: '324',
                        sh: '214',
                        type: 'touches',
                        value: '538',
                    },
                    {
                        fh: '5',
                        sh: '2',
                        type: 'goalKicks',
                        value: '7',
                    },
                    {
                        fh: '41.2',
                        sh: '49.3',
                        type: 'possessionPercentage',
                        value: '44.2',
                    },
                    {
                        fh: '1',
                        sh: '5',
                        type: 'fkFoulWon',
                        value: '6',
                    },
                    {
                        fh: '166',
                        sh: '102',
                        type: 'totalBackZonePass',
                        value: '268',
                    },
                    {
                        fh: '3',
                        sh: '6',
                        type: 'penAreaEntries',
                        value: '9',
                    },
                    {
                        fh: '6',
                        sh: '5',
                        type: 'accurateChippedPass',
                        value: '11',
                    },
                    {
                        fh: '79',
                        sh: '50',
                        type: 'fwdPass',
                        value: '129',
                    },
                    {
                        fh: '23',
                        sh: '23',
                        type: 'successfulFinalThirdPasses',
                        value: '46',
                    },
                    {
                        fh: '16',
                        sh: '5',
                        type: 'totalClearance',
                        value: '21',
                    },
                    {
                        fh: '1',
                        sh: '3',
                        type: 'challengeLost',
                        value: '4',
                    },
                    {
                        fh: '70',
                        sh: '61',
                        type: 'totalFwdZonePass',
                        value: '131',
                    },
                    {
                        fh: '2',
                        sh: '1',
                        type: 'accurateCrossNocorner',
                        value: '3',
                    },
                    {
                        fh: '4',
                        sh: '8',
                        type: 'fkFoulLost',
                        value: '12',
                    },
                    {
                        fh: '1',
                        sh: '1',
                        type: 'handBall',
                        value: '2',
                    },
                    {
                        fh: '1',
                        sh: '1',
                        type: 'attFastbreak',
                        value: '2',
                    },
                    {
                        fh: '2',
                        sh: '1',
                        type: 'lostCorners',
                        value: '3',
                    },
                    {
                        fh: '7',
                        sh: '2',
                        type: 'effectiveHeadClearance',
                        value: '9',
                    },
                    {
                        fh: '0',
                        sh: '2',
                        type: 'subsMade',
                        value: '2',
                    },
                    {
                        fh: '2',
                        sh: '1',
                        type: 'crosses18yardplus',
                        value: '3',
                    },
                    {
                        fh: '16',
                        sh: '21',
                        type: 'passesRight',
                        value: '37',
                    },
                    {
                        fh: '1',
                        sh: '1',
                        type: 'totalFlickOn',
                        value: '2',
                    },
                    {
                        fh: '2',
                        sh: '1',
                        type: 'accurateCross',
                        value: '3',
                    },
                    {
                        fh: '36',
                        sh: '18',
                        type: 'ballRecovery',
                        value: '54',
                    },
                    {
                        fh: '7',
                        sh: '3',
                        type: 'possWonMid3rd',
                        value: '10',
                    },
                    {
                        fh: '21',
                        sh: '21',
                        type: 'duelLost',
                        value: '42',
                    },
                    {
                        fh: '1',
                        sh: '1',
                        type: 'attBxLeft',
                        value: '2',
                    },
                    {
                        fh: '7',
                        sh: '7',
                        type: 'totalThrows',
                        value: '14',
                    },
                    {
                        fh: '6',
                        sh: '0',
                        type: 'attemptsConcededObox',
                        value: '6',
                    },
                    {
                        fh: '2',
                        sh: '3',
                        type: 'ontargetAttAssist',
                        value: '5',
                    },
                    {
                        fh: '2',
                        sh: '1',
                        type: 'attMissRight',
                        value: '3',
                    },
                    {
                        fh: '0',
                        sh: '1',
                        type: 'totalCornersIntobox',
                        value: '1',
                    },
                    {
                        fh: '137',
                        sh: '86',
                        type: 'accurateBackZonePass',
                        value: '223',
                    },
                    {
                        type: 'firstHalfGoals',
                        value: '1',
                    },
                    {
                        fh: '3',
                        sh: '3',
                        type: 'attemptsConcededIbox',
                        value: '6',
                    },
                    {
                        fh: '8',
                        sh: '10',
                        type: 'touchesInOppBox',
                        value: '18',
                    },
                    {
                        fh: '33',
                        sh: '17',
                        type: 'backwardPass',
                        value: '50',
                    },
                    {
                        fh: '0',
                        sh: '1',
                        type: 'wonCorners',
                        value: '1',
                    },
                    {
                        fh: '1',
                        sh: '0',
                        type: 'goalAssistOpenplay',
                        value: '1',
                    },
                    {
                        fh: '1',
                        sh: '0',
                        type: 'attRfGoal',
                        value: '1',
                    },
                    {
                        fh: '7',
                        sh: '3',
                        type: 'dispossessed',
                        value: '10',
                    },
                    {
                        fh: '1',
                        sh: '1',
                        type: 'possWonAtt3rd',
                        value: '2',
                    },
                    {
                        fh: '5',
                        sh: '4',
                        type: 'totalContest',
                        value: '9',
                    },
                    {
                        fh: '0',
                        sh: '1',
                        type: 'effectiveBlockedCross',
                        value: '1',
                    },
                    {
                        fh: '1',
                        sh: '3',
                        type: 'offtargetAttAssist',
                        value: '4',
                    },
                    {
                        fh: '0',
                        sh: '1',
                        type: 'fouledFinalThird',
                        value: '1',
                    },
                    {
                        fh: '0',
                        sh: '2',
                        type: 'attIboxTarget',
                        value: '2',
                    },
                    {
                        fh: '3',
                        sh: '1',
                        type: 'wonTackle',
                        value: '4',
                    },
                    {
                        fh: '0',
                        sh: '1',
                        type: 'attIboxBlocked',
                        value: '1',
                    },
                    {
                        fh: '17',
                        sh: '13',
                        type: 'duelWon',
                        value: '30',
                    },
                    {
                        fh: '3',
                        sh: '2',
                        type: 'accurateGoalKicks',
                        value: '5',
                    },
                    {
                        fh: '0',
                        sh: '1',
                        type: 'accurateKeeperSweeper',
                        value: '1',
                    },
                    {
                        type: 'formationUsed',
                        value: '3421',
                    },
                ],
                kit: {
                    id: '4',
                    colour1: '#FFFFFF',
                    colour2: '#132257',
                    type: 'home',
                },
            },
        ],
        matchDetailsExtra: {
            matchOfficial: [
                {
                    id: '3oe7osb8o3cy0dgex37asng0l',
                    type: 'Main',
                    firstName: 'Craig',
                    lastName: 'Pawson',
                },
                {
                    id: 'f1g3337l5j65736o54oswmxud',
                    type: 'Lineman 1',
                    firstName: 'Ian',
                    lastName: 'Hussin',
                },
                {
                    id: '9fqj6o3vtcziwj0xpzy4e6j85',
                    type: 'Lineman 2',
                    firstName: 'Scott',
                    lastName: 'Ledger',
                },
                {
                    id: '6l3ie5htdymg13u2ui69wo251',
                    type: 'Fourth official',
                    firstName: 'Darren',
                    lastName: 'England',
                },
            ],
        },
    },
}
