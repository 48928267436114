import { ISettingsNode, SettingsNodeTypes } from '@typings/node'
import { Node } from './Node'

/**
 * A SettingsNode is a 'leaf' node associated to a settings page.
 */

export class SettingsNode extends Node {
    type?: `${SettingsNodeTypes}`

    constructor(props: ISettingsNode) {
        super(props)

        if (Object.values(SettingsNode.TYPES).includes(props.type)) {
            this.type = props.type
        }
    }

    static TYPES = {
        MENU: 'MENU', // Used on VoltMobile, act like the "Home Settings" to present all kind of settings
        PIN: 'PIN',
        PC_LEVEL: 'PC_LEVEL',
        PLAYBACK_LANGUAGE: 'PLAYBACK_LANGUAGE',
        APP_LANGUAGE: 'APP_LANGUAGE',
    }

    /** Checks if the Node is a SettingsNode (always `true`) */
    isSettingsNode() {
        return true
    }
}
