/**
 * Manual cookie handler
 */
export default class CookiesHandler {
    static _COOKIES = {}

    static parseCookies(responseHeaders = {}) {
        Object.keys(responseHeaders).forEach((x) => {
            if (x && x.toLowerCase() === 'set-cookie') {
                let allCookies = responseHeaders[x]
                if (allCookies && typeof allCookies === 'string' && allCookies.length > 0) {
                    allCookies.split(',').forEach((cookie) => {
                        const extractCookie = cookie.split(';')[0].trim()
                        if (extractCookie) {
                            const key = extractCookie.split('=')[0]
                            CookiesHandler._COOKIES[key] = extractCookie
                        }
                    })
                }
            }
        })
    }

    static getCookies() {
        /**
         * https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Cookie
         * <cookie-list>
         * A list of name-value pairs in the form of <cookie-name>=<cookie-value>.
         * Pairs in the list are separated by a semicolon and a space ('; ').
         */
        return Object.values(CookiesHandler._COOKIES).join(';')
    }

    static clearCookie() {
        CookiesHandler._COOKIES = {}
    }
}
