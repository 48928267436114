import { of } from 'rxjs'
import { map, mergeMap, catchError } from 'rxjs/operators'
import { bookmarksFactory } from '../Factories'
import Fetch from '../fetch'
import { resolveHuaweiBookmarkType } from '../Helpers'
import HuaweiTypes from '../HuaweiTypes'

export default class ProgramApi extends Fetch {
    constructor(config, otherApis = {}) {
        super(config, otherApis)
        this.userPreferencesApi = otherApis.userPreferencesApi
    }

    /**
     * Get programs informations (stream data, product data) from the given
     * list of `titleIds`
     *
     * @param {Array<String>} titleIds Title identifiers to retrieve
     * @return {Observable<Object.<string, ProgramData>>} Programs data, keyed by program `titleId`
     */
    getPrograms(titleIds) {
        return of({})
    }

    /**
     * *Note:* Content PlatformId can be retrieve only if user is allowed to watch content.
     *
     * @param {String} titleId
     * @param {Boolean} [isRecording=false]
     * @param {String} [userAgent]
     * @return {ProgramPlatformData|null} Programs platform data or null
     */
    getProgramPlatformId(streamId, isRecording = false, userAgent) {
        const data = {
            playBackPlatformId: streamId,
            duration: null,
            channel: null,
        }
        return of(data)
    }
    /**
     * Set a bookmark for a specific Title identifier.
     *
     * **Important**
     * For backward compatibility this method also handle `setBookmark(titleId, bookmark)` signature.
     * This usage is deprecated and will be removed later.
     *
     * @param {Object} options
     * @param {String} options.titleId Title platform identifier
     * @param {Number} options.bookmark In seconds
     * @param {String} options.programType one of `Constants.programType.*`
     * @returns {Observable<Boolean>} Observable which emit a boolean, true = bookmark supported, false = bookmark not supported
     */
    setBookmark({ titleId, recordingId, bookmark, programType }) {
        let itemId
        const bookmarkType = resolveHuaweiBookmarkType(programType)
        switch (bookmarkType) {
            case HuaweiTypes.bookmarkType.SERIES:
            case HuaweiTypes.bookmarkType.VOD:
                itemId = titleId
                break

            case HuaweiTypes.bookmarkType.PROGRAM:
                itemId = titleId
                break

            case HuaweiTypes.bookmarkType.NPVR:
                itemId = recordingId
                break

            default:
                itemId = titleId
                break
        }
        return this.userPreferencesApi
            .createBookmark({
                itemId,
                rangeTime: bookmark,
                bookmarkType,
            })
            .pipe(
                mergeMap(() => of(true)),
                catchError((error) => {
                    // Avoid to generate recurring notification as bookmark is set every 30 secondes during the playback
                    this.logger.error(ProgramApi.TAG, `Cannot set a bookmark due to error`, error)
                    return of(false)
                })
            )
    }

    /**
     * Retrieves meta data of Live Series
     *
     * @param {Array<String>} seriesIds The series' identifiers
     * @returns {Observable<Array<LiveTvShow>>} A list of instances of {@link LiveTvShow}
     */
    getLiveSeriesData(seriesIds) {
        return of([])
    }

    /**
     * Determines the list of {@link EmiRequest} that can be used for fetching related content for different data types
     * @param {Program} program The {@link Program} for which related content is wanted
     * @returns {Observable<Object<String,EmiRequest>>} A map assigning an {@link EmiRequest} for each supported data type of related content
     */
    getRelatedRequests(program) {
        return of({})
    }

    /**
     * @returns {Observable<ProgramListResponse>}
     */
    getRelated() {
        return of({ programs: [], done: true })
    }

    /**
     * @returns {Observable<ProgramListResponse>}
     */
    getTrending() {
        return of({ programs: [], done: true })
    }

    /**
     * @returns {Observable<ProgramListResponse>}
     */
    getRecommended() {
        return of({ programs: [], done: true })
    }

    /**
     * @return {Observable<ContinueWatchingData>} Continue watching data
     */
    getContinueWatching() {
        return this.userPreferencesApi.getAllBookmarks().pipe(
            map((result) => {
                const bookmarks = result || []
                this.logger.info(ProgramApi.TAG, `Received ${bookmarks.length} bookmarks...`)

                return bookmarksFactory(bookmarks)
            })
        )
    }

    /**
     * @return {Observable<Boolean>}
     */
    getDetails() {
        return of(false)
    }
}
