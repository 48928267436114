/**
 * This class is a controller class used to handle Youtube paging requests
 * Each page has a 'prevPageToken' or 'nextPageToken' to be used in the
 * next request using 'pageToken' parameter.
 * This class store in cache these tokens to make them available for next
 * requests without consuming network bandwidth and Youtube API Quotas
 */
export default class PagingManager {
    pagingCache: Map<string, any>
    constructor() {
        this.pagingCache = new Map()
    }

    /**
     * Save the token necessary to parse the next page
     * @param {String} url Full URL of the youtube request (Use to discriminate)
     * @param {Number} pageNumber The page number (0 based) of the current request performed
     * @param {String} pageToken The token necessary to parse the next page of the youtube request
     */
    saveNextPageToken = (url: string, pageNumber: number, pageToken: string) => {
        if (!this.pagingCache.has(url)) {
            this.pagingCache.set(url, new Map())
        }

        // Save next page token, so we have to increment it
        this.pagingCache.get(url).set(pageNumber + 1, pageToken)
    }

    /**
     * Get the Token to use to retrieve a Page
     * @param {String} url Full URL of the youtube request
     * @param {Number} pageNumber The page number (0 based) of the current request performed
     * @return {String} Page Token is existing, undefined otherwise
     */
    getPageToken = (url: string, pageNumber: number): string => {
        return this.pagingCache.has(url) ? this.pagingCache.get(url).get(pageNumber) : undefined
    }

    /** Clear all token related to a specific URL */
    clearPageToken = (url?: string) => {
        if (url && this.pagingCache.has(url)) {
            this.pagingCache.delete(url)
        }
    }
}
