import DataHelper from 'framework/helpers/data'
import { of } from 'rxjs'
import { mergeMap } from 'rxjs/operators'
import { backendDeviceFactory } from '../Factories'
import Fetch from '../fetch'
import HuaweiTypes from '../HuaweiTypes'
import { useApiLoginV2, getDeviceConfig } from '../Helpers'

/**
 * Deal with Devices action (add/remove/get)
 */
export default class DeviceApi extends Fetch {
    constructor(config, otherApis = {}) {
        super(config, otherApis)
    }

    _getOldestDeviceIdForDeletion = (deviceList = []) => {
        /**
         * Algorithm should be like this;
         * 1. The devices whose deviceType=0 cannot be replaced. This devices will never get the error message like “You have reached the maximum number of devices”
         * 2. The devices whose devicetype=2 or 3 can be replaced.
         * 3. Devicetype 2 can be replaced with only the oldest devicetype 2. (FIFO for devicetype 2)
         * 4. Devicetype 3 can be replaced with only the oldest devicetype 3. (FIFO for devicetype 3)
         */
        const { deviceType, deviceDeletionAllowed = true } = getDeviceConfig()
        if (!deviceList.length) return ''
        const oldestDevice = deviceList
            .filter((device) => {
                return (
                    deviceDeletionAllowed &&
                    deviceType &&
                    parseInt(device?.deviceType, 10) === parseInt(deviceType, 10)
                )
            })
            .filter(Boolean)
            .reduce(
                (previousDevice, currentDevice) =>
                    previousDevice.lastLoginTime < currentDevice.lastLoginTime
                        ? previousDevice
                        : currentDevice,
                []
            )
        /* Here we shoul use the device ID not the device UUID */
        return oldestDevice?.id
    }

    /**
     * Modifies the user's device, specifically device name
     *
     * @param {object} args
     * @param {object} args.deviceNewName New device name for modification
     * @param {object} args.deviceId The device id
     * @param {object} args.deviceType The device type according to Huawei device types
     * @returns Observable
     */
    modifyDevice = ({ deviceNewName, deviceId, deviceType }) => {
        return this.fetch({
            url: `${DataHelper.getInstance().getData(
                DataHelper.STORE_KEY.BACKEND_API_URL
            )}/VSP/V3/ModifyDeviceInfo`,
            body: {
                device: {
                    ID: deviceId,
                    name: deviceNewName,
                    deviceType,
                },
            },
            method: 'POST',
            log: `MODIFY DEVICE ${deviceId}`,
        }).pipe(mergeMap(({ response } = {}) => of(response)))
    }

    /**
     * Get the account's device list containing
     * @param {Boolean} includeInactiveDevices Returns also inactive device
     * @param {Array<HuaweiTypes.deviceType>} deviceTypes List of devices
     * @returns {Observable<BackendDevice>}
     */
    getDeviceList({ username, deviceTypes } = {}) {
        const subscriberId =
            username || DataHelper.getInstance().getData(DataHelper.STORE_KEY.SUBSCRIBER_ID)
        const myDeviceTypes =
            (deviceTypes && (Array.isArray(deviceTypes) ? deviceTypes.join(';') : [deviceTypes])) ||
            Object.values(HuaweiTypes.deviceType).join(';')

        return this.fetch({
            url: `${DataHelper.getInstance().getData(
                DataHelper.STORE_KEY.BACKEND_API_URL
            )}/VSP/V3/QueryDeviceList`,
            body: {
                subscriberID: subscriberId,
                deviceType: myDeviceTypes,
            },
            method: 'POST',
            allowReloginWhenSessionEnded: false, // As this API is used when user is not loggued in
            log: 'GET DEVICE LIST',
        }).pipe(
            mergeMap(({ response = {} } = {}) =>
                of((response.devices || []).map((device) => backendDeviceFactory(device)))
            )
        )
    }

    /**
     * Retrieve the device of the user
     * @returns {Observable<BackendDevice>}
     */
    getUserDevice() {
        const userDeviceId = DataHelper.getInstance().getData(
            DataHelper.STORE_KEY.BACKEND_USER_DEVICE_ID
        )
        return this.getDeviceList().pipe(
            mergeMap((deviceList) => {
                const myDevice = (deviceList || []).find(
                    (device) => device && device.deviceUUID === userDeviceId
                )
                return of(myDevice)
            })
        )
    }

    /**
     * Register a device in the backend
     * @param {Object} data
     * @param {Object} [data.screenDimensions]
     * @param {Object} [data.deviceName]
     * @returns {Observable<Boolean>}
     */
    addDevice({ screenDimensions, deviceName }) {
        return of(true)
    }

    /**
     * Delete a device in the backend. But for Huawei there is no delete device method,
     * we can only replace it with the user's current device
     *
     * Allowed to delete only OTT device, including the PC plug-in, iOS, and Android devices and
     * mobile device for the MTV solution
     * @param {Object} data
     * @param {Object} [data.username] Username or Subscriber ID
     * @param {Object} [data.backendDeviceId]
     * @returns {Observable<Boolean>}
     */
    deleteDevice({ username, backendDeviceId = '' } = {}) {
        const subscriberId =
            username || DataHelper.getInstance().getData(DataHelper.STORE_KEY.SUBSCRIBER_ID)
        const userDeviceId = DataHelper.getInstance().getData(
            DataHelper.STORE_KEY.BACKEND_USER_DEVICE_ID
        )

        /**
         * Algorithm should be like this;
         * 1. The devices whose deviceType=0 cannot be replaced. This devices will never get the error message like “You have reached the maximum number of devices”
         * 2. The devices whose devicetype=2 or 3 can be replaced.
         * 3. Devicetype 2 can be replaced with only the oldest devicetype 2. (FIFO for devicetype 2)
         * 4. Devicetype 3 can be replaced with only the oldest devicetype 3. (FIFO for devicetype 3)
         */
        const { deviceType } = getDeviceConfig()

        return (
            !backendDeviceId
                ? this.getDeviceList({ username, deviceTypes: [deviceType] }).pipe(
                      mergeMap((deviceList) => of(this._getOldestDeviceIdForDeletion(deviceList)))
                  )
                : of(null)
        ).pipe(
            mergeMap((oldestDeviceId = '') => {
                if (oldestDeviceId) backendDeviceId = oldestDeviceId
                if (useApiLoginV2()) {
                    /**
                     * According to Huawei if we use API V2 for Login, we should use the same API for device management
                     */
                    return this.fetch({
                        url: `${DataHelper.getInstance().getData(
                            DataHelper.STORE_KEY.BACKEND_API_URL
                        )}/EPG/JSON/ReplaceDevice`,
                        body: {
                            userid: subscriberId,
                            orgDeviceId: backendDeviceId,
                            destDeviceId: userDeviceId,
                        },
                        method: 'POST',
                        allowReloginWhenSessionEnded: false, // As this API is used when user is not FULLY loggued in
                        log: `REPLACE DEVICE (API V2) WITH ID: ${backendDeviceId} ON ${userDeviceId}`,
                    }).pipe(mergeMap(() => of(true)))
                }
                return this.fetch({
                    url: `${DataHelper.getInstance().getData(
                        DataHelper.STORE_KEY.BACKEND_API_URL
                    )}/VSP/V3/ReplaceDevice`,
                    body: {
                        subscriberID: subscriberId,
                        orgDeviceID: backendDeviceId,
                        destDeviceID: userDeviceId,
                    },
                    method: 'POST',
                    allowReloginWhenSessionEnded: false, // As this API is used when user is not loggued in
                    log: `REPLACE DEVICE (API V6) WITH ID: ${backendDeviceId} ON ${userDeviceId}`,
                }).pipe(mergeMap(() => of(true)))
            })
        )
    }

    /**
     * This method verifies if the device exist in the backend
     * @param {String} deviceId Backend device ID
     * @returns {Observable<Boolean>} boolean indicating is the device exists or not
     */
    isExistingDevice({ deviceId } = {}) {
        return this.getDeviceList().pipe(
            mergeMap((deviceList) => {
                const myDevice = (deviceList || []).find(
                    (device) => device && device.id === deviceId
                )
                return of(!!myDevice)
            })
        )
    }

    /**
     * This method is called at the initialization to initialize the device service
     * In some backend it is the moment to create a device and initialize the list of the devices
     * @param {Object} deviceProperties
     * @param {Object} screenDimensions
     * @returns
     */
    initializeUserDevice({ deviceProperties, screenDimensions } = {}) {
        return of(true)
    }

    /**
     * This method sends keepAlive request to the backend
     */
    keepAliveDevice() {
        return of(true)
    }
}
