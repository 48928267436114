import { IUserWallet } from '@typings/platform'
import { Abstract } from './Abstract'

/**
 * @class User Wallet
 * @property {string} walletName wallet name
 * @property {number} earnedCredits total credits
 * @property {number} purchasedCredits purchased credits
 * @property {number} usedCredits used credits
 * @property {number} reservedCredits reserved credits
 * @property {number} availableCredits available credits for spending
 * @property {string} currency currency
 */

export class UserWallet extends Abstract {
    walletName: string
    totalCredits: number
    purchasedCredits: number
    usedCredits: number
    reservedCredits: number
    availableCredits: number
    phoneNumbersList: object
    currency: string

    constructor(props: IUserWallet) {
        super()

        this.walletName = props.walletName
        this.totalCredits = props.totalCredits
        this.purchasedCredits = props.purchasedCredits
        this.usedCredits = props.usedCredits
        this.reservedCredits = props.reservedCredits
        this.availableCredits = props.availableCredits
        this.phoneNumbersList = {}
        this.currency = props.currency
    }
}
