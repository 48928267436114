import { of, forkJoin } from 'rxjs'
import { catchError, mergeMap, tap } from 'rxjs/operators'
import { remindersFactory } from '../Factories'
import Fetch from '../fetch'
import HuaweiTypes from '../HuaweiTypes'
import DataHelper from 'framework/helpers/data'
import ConfigHelper from 'framework/helpers/config'
import { CachedIdsListHelper } from 'framework/helpers/cacheIdList'
import { getDeviceModel } from '../Helpers'
import * as Factories from '../Factories'
import { Channel } from 'models'

/**
 * Deals with Channels (thanks captain obvious)
 */
export default class ChannelApi extends Fetch {
    constructor(config, otherApis = {}) {
        super(config, otherApis)
        this.config = config
        this.metaApi = otherApis.metaApi
        this.userPreferencesApi = otherApis.userPreferencesApi
    }

    /**
     * Get all channels data from Platform (auto-paginated)
     *
     * @param {Object} [opts]
     * @param {string} [opts.market] market of the user, specific to WOW
     * @param {Channel.SOURCE_MODE} [opts.sourceMode=Channel.SOURCE_MODE.CENSORED_CHANNEL] subMarket of the user, specific to WOW
     * @return {Observable<Array<ApiChannelData>>}
     */
    getChannelsData({
        pageSize = 100,
        market,
        sourceMode = Channel.SOURCE_MODE.CENSORED_CHANNEL,
    } = {}) {
        return of([])
    }

    /**
     * Get channels
     * @param {Object} [opts]
     * @param {Channel.SOURCE_MODE} [opts.sourceMode=Channel.SOURCE_MODE.CENSORED_CHANNEL] subMarket of the user, specific to WOW
     * @return {Observable<ChannelsData>}
     */
    getChannels({ sourceMode = Channel.SOURCE_MODE.CENSORED_CHANNEL } = {}) {
        const { rollingBufferDuration: defaultRollingBufferDuration = 48 } =
            ConfigHelper.getInstance().getConfig('huawei')

        return this._getChannelNamespace().pipe(
            mergeMap((channelNamespace) => {
                return forkJoin({
                    proxyResult: this.metaApi.getChannels({
                        /**
                         * For ooredoo, market is reused for hacking the channel namespace
                         * TODO: Better to return this from user profile
                         */
                        market: channelNamespace,
                        /**
                         * Todo: There will be a CR in coming future to get channels by level on censorship and control it from the UI
                         * As this CR is POST MVP, force a get "censored" channel.
                         * Will be moved outisde the API
                         */
                        sourceMode,
                    }),
                    playbackIds: this._getChannelPlaybackIds(channelNamespace),
                }).pipe(
                    mergeMap(({ proxyResult = {}, playbackIds = {} }) => {
                        if (proxyResult && !!Object.keys(proxyResult).length) {
                            return of(
                                Object.values(proxyResult).reduce((acc, channel) => {
                                    let rollingBufferDuration = 0
                                    if (channel.startOver || channel.catchup) {
                                        rollingBufferDuration = channel.rollingBufferDuration
                                            ? channel.rollingBufferDuration
                                            : defaultRollingBufferDuration
                                    }
                                    const playbackId =
                                        channel.platformId && playbackIds[channel.platformId]
                                            ? playbackIds[channel.platformId]
                                            : channel.playbackId
                                    acc[channel.id] = channel.update({
                                        playbackId,
                                        canWatch: true,
                                        rollingBufferDuration,
                                        rollingBufferRetrieved: true,
                                        rollingBufferPending: false,
                                        isBlockedChannel: false,
                                    })
                                    return acc
                                }, proxyResult)
                            )
                        }
                        return of({})
                    })
                )
            })
        )
    }

    /**
     * Add a reminder
     *
     * @param {string} profileId - profile id
     * @param {string} eventId - event id
     * @param {string} channelId - channel id
     * @param {string} startTime - lower time boundary
     * @param {string} endTime - upper time boundary
     * @return {Observable}
     */
    addReminder(profileId, eventId, channelId, startTime, endTime) {
        return this.userPreferencesApi.createReminder({
            contentId: eventId,
            contentType: HuaweiTypes.contentTypes.PROGRAM,
            reminderTime: startTime,
        })
    }

    /**
     * Delete a reminder
     *
     * @param {string} profileId - profile id
     * @param {string} eventId - event id
     * @return {Observable}
     */
    removeReminder(profileId, eventId) {
        return this.userPreferencesApi.deleteReminder({
            contentIds: [eventId],
            contentTypes: [HuaweiTypes.contentTypes.PROGRAM],
        })
    }

    /**
     * Retrieve reminders
     * @return {Observable}
     */
    getReminderList() {
        return this.userPreferencesApi.getAllReminders().pipe(
            mergeMap((reminders = []) => {
                if (!reminders.length) return of({ reminders: [] })
                return of({
                    reminders: reminders.map((reminder) => remindersFactory(reminder.channel)),
                })
            })
        )
    }

    /**
     * Get RollingBuffer data for a specific channelId
     *
     * @param {String|Number} channelId
     * @return {Observable<ApiRollingBufferData>}
     */
    getRollingBuffer() {
        return of({})
    }

    /**
     * Get favorites channels of the current user
     * @return {Observable} Observable which emit an Array of Platform Channel identifiers
     */
    getFavoritesChannel() {
        return this.userPreferencesApi
            .getAllFavorites({
                contentTypes: [HuaweiTypes.contentTypes.CHANNEL],
            })
            .pipe(
                mergeMap((favoriteChannels = []) => {
                    if (!favoriteChannels.length) return of([])
                    return of(
                        favoriteChannels.map((x) => x && x.channel && x.channel.ID).filter(Boolean)
                    )
                })
            )
    }

    /**
     * Set a channel as favorite for the current user
     *
     * @param {string} platformChannelId
     * @return {Observable}
     */
    setFavoriteChannel(platformChannelId) {
        return this.userPreferencesApi
            .addContentToFavorites({
                contentId: platformChannelId,
                contentType: HuaweiTypes.contentTypes.CHANNEL,
            })
            .pipe(mergeMap(() => of(true)))
    }

    /**
     * Remove a channel as favorite for the current user
     *
     * @param {string} channelId
     * @return {Observable}
     */
    removeFavoriteChannel(channelId) {
        return this.userPreferencesApi.removeContentFromFavorites({
            contentIds: [channelId],
            contentTypes: [HuaweiTypes.contentTypes.CHANNEL],
        })
    }

    /**
     * Retrieve currently broadcasted Live programs from metaApi
     *
     * @deprecated Could not works with available parameters on Maculosa startTimeStamp and endTimeStamp refers to the "begin" (ie broadcast time) and act as boundaries
     *
     * @param {Number} channelId Proxy channel identifier
     * @return {Observable} Observable, see return of {@link ProxyApi#getChannelsData}
     */
    getOnTvNow() {
        return of({})
    }

    /**
     * Gets restricted channel IDs
     *
     * @returns {Observable<Array<String>>} returns an array of restricted channel IDs
     */
    getChannelsRestriction() {
        return this.userPreferencesApi
            .getLocks(CachedIdsListHelper.LISTS_NAMES.RESTRICTED_CHANNELS, [
                HuaweiTypes.contentTypes.CHANNEL,
            ])
            .pipe(
                mergeMap((restrictedChannels) => {
                    return of(restrictedChannels.map(({ channel }) => channel.ID))
                }),
                catchError(() => of([]))
            )
    }

    /**
     * Sets channel as restricted
     *
     * @param {Array<String>} channelIds - channel IDs
     * @param {Boolean} isLock - define if channel should be restricted or not
     * @returns {Boolean} returns true if setting is successful
     */
    setChannelRestriction(channelIds, isLock) {
        return isLock
            ? this.userPreferencesApi
                  .setLock(
                      {
                          contentId: channelIds[0],
                          contentType: HuaweiTypes.contentTypes.CHANNEL,
                      },
                      CachedIdsListHelper.LISTS_NAMES.RESTRICTED_CHANNELS
                  )
                  .pipe(
                      mergeMap(() => of(true)),
                      catchError(() => of(false))
                  )
            : this.userPreferencesApi
                  .deleteLock(
                      {
                          contentId: channelIds,
                          contentType: [HuaweiTypes.contentTypes.CHANNEL],
                      },
                      CachedIdsListHelper.LISTS_NAMES.RESTRICTED_CHANNELS
                  )
                  .pipe(
                      mergeMap(() => of(true)),
                      catchError(() => of(false))
                  )
    }

    /**
     * Get channels data by merging Proxy & Platform data that could be retrieved.
     *
     * @return {Observable<ChannelsData>}
     */
    _getChannelNamespace() {
        const channelNamespace = DataHelper.getInstance().getData(
            DataHelper.STORE_KEY.CHANNEL_NAMESPACE
        )
        if (channelNamespace) {
            this.logger.info(`Channel Namspace already available from Cache : ${channelNamespace}`)
            return of(channelNamespace)
        }

        const userGroup = DataHelper.getInstance().getData(DataHelper.STORE_KEY.USER_GROUP)
        return this.fetch({
            url: `${DataHelper.getInstance().getData(
                DataHelper.STORE_KEY.BACKEND_API_URL
            )}/EPG/JSON/QueryChannelNamespace`,
            body: {
                terminalType: getDeviceModel(),
                usergroup: userGroup,
            },
            method: 'POST',
            log: 'GET DEVICE LIST',
        }).pipe(
            mergeMap(({ response } = {}) => {
                /* Template
                    {
                    "channelNamespace": "19",
                    "channelNamespaceName": "OAndroidSTB",
                    "retcode": "0"
                } */
                return of(response.channelNamespace)
            }),
            tap((channelNamespace) => {
                DataHelper.getInstance().storeData(
                    [
                        channelNamespace && [
                            DataHelper.STORE_KEY.CHANNEL_NAMESPACE,
                            channelNamespace,
                        ],
                    ].filter(Boolean)
                )
            })
        )
    }

    /**
     * Get channels data by merging Proxy & Platform data that could be retrieved.
     *
     * @return {Observable<ChannelsData>}
     */
    _getChannelPlaybackIds(channelNamespace) {
        return (!channelNamespace ? this._getChannelNamespace() : of(channelNamespace)).pipe(
            mergeMap((channelNamespace) =>
                this.fetch({
                    url: `${DataHelper.getInstance().getData(
                        DataHelper.STORE_KEY.BACKEND_API_URL
                    )}/VSP/V3/QueryAllChannelDynamicProperties`,
                    method: 'POST',
                    body: {
                        isReturnAllMedia: '0',
                        channelNamespace: channelNamespace,
                    },
                    log: 'QUERY ALL CHANNEL DYNAMICS PROPERTIES',
                }).pipe(
                    mergeMap(({ response } = {}) =>
                        of(Factories.parseChannelPlaybackIds(response.channelDynamaicProp))
                    )
                )
            ),
            catchError(() => {
                this.logger.warn(
                    'Cannot retrieve the channel playback Ids from Huawei, do not fail'
                )
                return of({})
            })
        )
    }
}
