import { Abstract } from 'models/Abstract'
import { IDvrQuota } from '@typings/tile'

export class DvrQuota extends Abstract {
    dvrQuota: string | number
    dvrQuotaRemaining: string | number
    unit: string

    constructor(props: IDvrQuota) {
        super()
        this.dvrQuota = props.dvrQuota
        this.dvrQuotaRemaining = props.dvrQuotaRemaining
        this.unit = props.unit
    }
}
