import {useContext} from 'solid-js'
import {ThemeContext} from './ThemeContext'
import {ThemeProviderBehavior} from './ThemeProvider'

export function useTheme() {
    const contextValue: ThemeProviderBehavior = useContext(ThemeContext)
    if (!contextValue) {
        throw new Error(
            'could not find redux context value; please ensure the component is wrapped in a <ReduxProvider>'
        )
    }
    return contextValue
}
