import Fetch from '../../fetch'
import DataHelper from 'framework/helpers/data'
import { of } from 'rxjs'
import { mergeMap, catchError } from 'rxjs/operators'
import HuaweiTypes from '../../HuaweiTypes'
import ConfigHelper from 'framework/helpers/config'

export default class PlayerActivityApi extends Fetch {
    static CONTENT_TYPE = {
        LIVE: 'LIVE',
        LIVE_TIMESHIFTED: 'LIVE_TIMESHIFTED',
        CATCHUP: 'CATCHUP',
        STARTOVER: 'STARTOVER',
        VOD: 'VOD',
        DVR: 'DVR',
    }

    static ACTIVITY_TYPE = {
        ACTION_START: 'ACTION_START',
        ACTION_STOP: 'ACTION_STOP',
    }

    static HEARTBEAT_ENDPOINT = {
        CHANNEL: 'PlayChannelHeartbeat',
        VOD: 'PlayVODHeartbeat',
        DVR: 'PlayNPVRHeartbeat',
    }

    static REPORT_ENDPOINT = {
        CHANNEL: 'ReportChannelAction',
        VOD: 'ReportVOD',
        DVR: 'ReportPVR',
    }

    setPlaybackAttributes(
        contentType = PlayerActivityApi.CONTENT_TYPE.LIVE,
        { channelId, programId, playbackId } = {}
    ) {
        this._playbackStartTime = Date.now()
        this._contentType = contentType
        this._channelId = channelId
        this._programId = programId
        this._playbackId = playbackId
    }

    reportPlaybackHeartbeat() {
        if (!this._contentType || !this._playbackId) {
            this.logger.error(
                '[PLAYBACK HEARTBEAT] Not sent because empty contentId or contentType.'
            )
            return of(false)
        }

        let body
        let endpoint
        switch (this._contentType) {
            case PlayerActivityApi.CONTENT_TYPE.LIVE:
            case PlayerActivityApi.CONTENT_TYPE.LIVE_TIMESHIFTED:
                endpoint = PlayerActivityApi.HEARTBEAT_ENDPOINT.CHANNEL
                body = {
                    channelID: this._channelId,
                    mediaID: this._playbackId,
                }
                break
            case PlayerActivityApi.CONTENT_TYPE.CATCHUP:
            case PlayerActivityApi.CONTENT_TYPE.STARTOVER:
                endpoint = PlayerActivityApi.HEARTBEAT_ENDPOINT.CHANNEL
                body = {
                    channelID: this._channelId,
                    mediaID: this._playbackId,
                    playbillID: this._programId,
                }
                break

            case PlayerActivityApi.CONTENT_TYPE.VOD:
                endpoint = PlayerActivityApi.HEARTBEAT_ENDPOINT.VOD
                body = {
                    VODID: this._programId,
                    mediaID: this._playbackId,
                }
                break

            case PlayerActivityApi.CONTENT_TYPE.DVR:
                endpoint = PlayerActivityApi.HEARTBEAT_ENDPOINT.DVR
                body = {
                    planID: this._programId,
                    fileID: this._playbackId,
                }
                break

            default:
                this.logger.error('[PLAYBACK HEARTBEAT] INVALID contentType.')
                return of(false)
        }

        return this._reportPlaybackHearbeat(endpoint, body)
    }

    /**
     * Sends user playback activity
     * @param {PlayerActivityApi.ACTIVITY_TYPE} activityType - the type the reporting event
     * @returns {Observable<Boolean>} returns true if the request was successful
     */
    reportPlaybackActivity(activityType) {
        this.logger.info(
            `[REPORT PLAYBACK ACTIVITY] sending request for "${activityType}" event...`
        )
        if (!Object.values(PlayerActivityApi.ACTIVITY_TYPE).includes(activityType)) {
            this.logger.error(
                `[REPORT PLAYBACK ACTIVITY] Not sent because action not supported ${activityType}`
            )
            return of(false)
        }
        if (!this._contentType || !this._playbackId) {
            this.logger.error(
                '[REPORT PLAYBACK ACTIVITY] Not sent because empty contentId or contentType.'
            )
            return of(false)
        }

        let body
        let endpoint
        const {
            disableLiveReporting = true,
            disableStartOverReporting = true,
            disableDvrReporting = true,
            disableTimeshiftReporting = true,
        } = ConfigHelper.getInstance().getConfig('huawei')

        switch (this._contentType) {
            case PlayerActivityApi.CONTENT_TYPE.LIVE:
                if (disableLiveReporting) {
                    this.logger.info('[REPORT PLAYBACK ACTIVITY][BTV] Disabled')
                    return of(false)
                }
                endpoint = PlayerActivityApi.REPORT_ENDPOINT.CHANNEL
                body = {
                    businessType: HuaweiTypes.businessType.BTV,
                    channelID: this._channelId,
                    mediaID: this._playbackId,
                }
                break
            case PlayerActivityApi.CONTENT_TYPE.LIVE_TIMESHIFTED:
                if (disableTimeshiftReporting) {
                    this.logger.info('[REPORT PLAYBACK ACTIVITY][PLTV] Disabled')
                    return of(false)
                }
                endpoint = PlayerActivityApi.REPORT_ENDPOINT.CHANNEL
                body = {
                    businessType: HuaweiTypes.businessType.PLTV,
                    channelID: this._channelId,
                    mediaID: this._playbackId,
                }
                break
            case PlayerActivityApi.CONTENT_TYPE.CATCHUP:
                endpoint = PlayerActivityApi.REPORT_ENDPOINT.CHANNEL
                body = {
                    businessType: HuaweiTypes.businessType.CUTV,
                    channelID: this._channelId,
                    mediaID: this._playbackId,
                    playbillID: this._programId,
                }
                break
            case PlayerActivityApi.CONTENT_TYPE.STARTOVER:
                if (disableStartOverReporting) {
                    this.logger.info('[REPORT PLAYBACK ACTIVITY][STARTOVER] Disabled')
                    return of(false)
                }
                endpoint = PlayerActivityApi.REPORT_ENDPOINT.CHANNEL
                body = {
                    businessType: HuaweiTypes.businessType.CUTV, // As there is no dedicate type for reporting, should use CUTV
                    channelID: this._channelId,
                    mediaID: this._playbackId,
                    playbillID: this._programId,
                }
                break

            case PlayerActivityApi.CONTENT_TYPE.VOD:
                endpoint = PlayerActivityApi.REPORT_ENDPOINT.VOD
                body = {
                    VODID: this._programId,
                    mediaID: this._playbackId,
                }
                break

            case PlayerActivityApi.CONTENT_TYPE.DVR:
                if (disableDvrReporting) {
                    this.logger.info('[REPORT PLAYBACK ACTIVITY][DVR] Disabled')
                    return of(false)
                }
                endpoint = PlayerActivityApi.REPORT_ENDPOINT.DVR
                body = {
                    planID: this._programId,
                    fileID: this._playbackId,
                }
                break

            default:
                this.logger.error('[REPORT PLAYBACK ACTIVITY] INVALID contentType.')
                return of(false)
        }

        switch (activityType) {
            case PlayerActivityApi.ACTIVITY_TYPE.ACTION_START:
                body = {
                    ...body,
                    action: '0',
                }
                break

            case PlayerActivityApi.ACTIVITY_TYPE.ACTION_STOP:
            default:
                body = {
                    ...body,
                    action: '1',
                    startTime: this._playbackStartTime,
                }
                break
        }

        return this._reportPlaybackAction(endpoint, body)
    }

    /**
     * Send Playback reporting
     * @param {String} endpoint Request endpoint
     * @param {String} body Request Body
     * @returns {Observable<Boolean>}
     */
    _reportPlaybackAction(endpoint, body) {
        if (!endpoint) return of(false)

        this.logger.info(`[REPORT PLAYBACK] ${endpoint} with param=${JSON.stringify(body)}`)
        return this.fetch({
            url: `${DataHelper.getInstance().getData(
                DataHelper.STORE_KEY.BACKEND_API_URL
            )}/VSP/V3/${endpoint}`,
            method: 'POST',
            body,
            log: `[Report ${endpoint}]`,
        }).pipe(
            mergeMap(() => of(true)),
            catchError(() => {
                this.logger.warn(`Cannot send ${endpoint}`)
                return of(false)
            })
        )
    }

    /**
     * Send Playback Hearbeat
     * @param {String} endpoint Request endpoint
     * @param {String} body Request Body
     * @returns {Observable<Boolean>}
     */
    _reportPlaybackHearbeat(endpoint, body) {
        if (!endpoint) return of(false)

        this.logger.info(`[PLAYBACK HEARTBEAT] ${endpoint} with param=${JSON.stringify(body)}`)
        return this.fetch({
            url: `${DataHelper.getInstance().getData(
                DataHelper.STORE_KEY.BACKEND_API_URL
            )}/VSP/V3/${endpoint}`,
            method: 'POST',
            body,
            log: `[${endpoint}]`,
        }).pipe(
            mergeMap(() => of(true)),
            catchError(() => {
                this.logger.warn(`Cannot send ${endpoint} message`)
                return of(false)
            })
        )
    }
}
