import { IEPGNode } from '@typings/node'
import { Node } from './Node'

import { addDays, getStartTime } from 'helpers/date'

/**
 * A Node found in the EPG subset of the navigation tree and associated with a specific date (given as the number of days from the present date)
 * @property {Number} daysFromToday The number of days between the date associated by the EPGNode and 'Date.now()' (this value should remain constant but the date itself could change)
 * @property {Boolean} isExtendedDate If true, the EPGNode is not displayed by default.
 */

export class EPGNode extends Node {
    daysFromToday: number
    isExtendedDate: boolean
    children: object
    defaultEntryFilter: number
    entryFilter: number

    constructor(props: IEPGNode) {
        super(props)

        const computePropValue = this._makeComputePropValue(props)

        this.daysFromToday = props.daysFromToday
        this.isExtendedDate = props.isExtendedDate
        this.children = props.children
        this.defaultEntryFilter = computePropValue('defaultEntryFilter', 0)
        this.entryFilter = computePropValue('entryFilter', this.defaultEntryFilter, Number.isFinite)
    }

    /**
     * Checks if the Node is a EPGNode (always `true`)
     * */
    isEPGNode() {
        return true
    }

    /** The timestamp of the date associated with the EPGNode. It is computed using `daysFromToday` and `Date.now()` and always resolves to midnight UTC. */
    getTimestamp() {
        if (!Number.isFinite(this.daysFromToday)) return null
        return getStartTime(addDays(this.daysFromToday, Date.now()))
    }
}
