const DefaultStorage = require('framework/helpers/DefaultStorage').default
const EncryptionHelper = require('framework/helpers/encryption').default
const ConfigHelper = require('framework/helpers/config').default
const MockLogger = require('../MockLogger').default
const CommonApi = require('services/common').default
const MetaApi = require('services/proxy').default
const DataHelper = require('framework/helpers/data').default
const EmiApi = require('services/emi').default
const YoutubeApi = require('services/youtube').default
const analyticsFirebaseApi = require('services/analyticsFirebase').default
const OptaApi = require('services/opta').default

export default class Api {
    constructor(config) {
        config = {
            persistentStorage: new DefaultStorage(),
            encryptionHelper: EncryptionHelper.getInstance(), // As singleton, same instance is accessible everywhere
            logger: MockLogger,
            ...config,
        }

        if (!config.platform) {
            throw new Error(`[VOLT-API][CONFIG] platform is mandatory`)
        }

        if (config.encryption) {
            const { enabled = true, passphrase } = config.encryption
            EncryptionHelper.getInstance().enableEncryption(enabled)
            passphrase && EncryptionHelper.getInstance().setPassphrase(passphrase)
        }

        // For easy configuration access
        ConfigHelper.getInstance().setConfig(config)
        this.config = config

        // TMP: React native application override DefaultStorage class by using AsyncStorage as localStorage is available only on browsers
        this.dataHelper = DataHelper.getInstance()
        this.dataHelper.setStorageHelper(config.persistentStorage)

        // The service above are native for all APIs implementation
        this.commonApi = CommonApi.getInstance()
        this.metaApi = new MetaApi(this.config, { commonApi: this.commonApi })
        this.emiApi = new EmiApi(this.config, this.metaApi)
        this.youtubeApi = new YoutubeApi(this.config)
        this.optaApi = new OptaApi(this.config)
        // Analytics preloaded using injection through the config. If not injected, stub is preloaded.
        this.analyticsFirebaseApi = new analyticsFirebaseApi(this.config)
    }
}
