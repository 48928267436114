import {Focusable, FocusableGroup} from '../../spatialNavigation'
import {isPlatform} from '../../utils'

interface FocusGroupProviderProps {
    focusKey: string
    children: any
    id?: string
    onFocus?: any
    onBlur?: any
    saveLastFocusedChild?: boolean
    onEnterPress?: any
    onArrowPress?: any,
    class?: string,
    style?:any
    onClick?: any,
    focusable?: boolean
}

export const FocusableProvider = (props: FocusGroupProviderProps) => {
    if (isPlatform('laptop')) {
        if(props.class){
            return (
            <div class={props.class} onClick={props.onClick}>
                {props.children(false)}
            </div>)
        }else{
            return props.children(false)
        }
    } else {
        return (
            <Focusable
                focusable={props?.focusable ?? true}
                as="div"
                focusKey={props.focusKey}
                trackChildren={true}
                id={props.id}
                onFocus={props.onFocus}
                onEnterPress={props.onEnterPress}
                onArrowPress={props.onArrowPress}
                class={props.class}
                onBlur={props.onBlur}
                style={props.style}
                onClick={props.onClick}
            >
                {({focused}) => <>{props.children(focused())}</>}
            </Focusable>
        )
    }
}