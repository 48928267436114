/**
 * Return a JS Timestamp (in millisonds) matching the 00:00:00 (midnight)
 * time of :
 * - The given timestamp parameter.
 * - The current date if timestamp parameter is omit.
 *
 * @param {String|Date|Number} [timestamp=Date.now()] any value allowed by Date() constructor
 * @returns {Number} JS Timestamp (in milliseconds)
 */
export const getStartTime = (timestamp = Date.now()) => {
    const date = new Date(timestamp)

    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0).getTime()
}

/**
 * Convert provided timestamp to an ISO-8601 string.
 *
 * @param {String|Date|Number} timestamp any value allowed by Date() constructor
 * @param {Object} [options] formatting options
 * @param {Object} [options.utc=false] true: date is UTC, false : date is based on local timezone
 * @param {Object} [options.utc=withTimezone] true: date owns timezone info, false: date does not own timezone info
 * @returns {String} ISO-8601 date string
 */
export const timestampToIsoDateTimeString = (
    timestamp,
    options = { utc: false, withTimezone: true }
) => {
    const { utc, withTimezone } = options
    const date = new Date(timestamp)
    const pad = (v) => v.toString().padStart(2, '0')

    if (utc) {
        // Use native method & remove the milliseconds part
        let datetime = date.toISOString().replace(/\.[0-9]{3}/, '')

        if (withTimezone === false) {
            datetime = datetime.replace(/Z|(\+[0-9]{2}:[0-9]{2})/, '')
        }

        return datetime
    }

    const year = date.getFullYear()
    const month = pad(date.getMonth() + 1)
    const day = pad(date.getDate())
    const hours = pad(date.getHours())
    const minutes = pad(date.getMinutes())
    const seconds = pad(date.getSeconds())

    let datetime = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`

    if (withTimezone) {
        // getTimezoneOffset returns -120 for a CEST timezone (GMT+0200)
        // so let's invert the sign
        const tzOffset = (date.getTimezoneOffset() / 60) * -1
        let timezone

        if (tzOffset === 0) {
            timezone = 'Z'
        } else {
            timezone = tzOffset < 0 ? '-' : '+'
            const absTzOffset = Math.abs(tzOffset)
            timezone +=
                absTzOffset % 1 === 0
                    ? pad(absTzOffset) + ':00'
                    : pad(Math.floor(absTzOffset)) + ':' + Math.round((absTzOffset % 1) * 60)
        }

        datetime = `${datetime}${timezone}`
    }

    return datetime
}

/**
 * add n days to a given timestamp
 * @method addDays
 * @param  {number} days - the number of days to add
 * @param  {number} timestamp - the timestamp
 * @return {number} - the new timestamp
 */
export const addDays = (days, timestamp) => {
    const result = new Date(timestamp)
    result.setDate(result.getDate() + days)
    return result.getTime()
}

/**
 * return the delay between now and a later Date
 * @method getDelayFromNowtoLaterDate
 * @param  {Date} days - the number of days to add
 * @param  {number} timestamp - timestamp can be usefull to have timestamp before the expiration time
 */
export const getDelayFromNowtoLaterDate = (laterDate, timestamp) => {
    let renewUserAccessTokenAt = laterDate - Date.now()
    if (timestamp) {
        renewUserAccessTokenAt -= timestamp
    }

    const delay = Math.max(0, renewUserAccessTokenAt) // Be sure this is not a negative value

    return delay
}
