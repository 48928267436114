export default (props = {}) => <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"{...props}>
<g id="Player / Subtitles">
<g id="Vector">
<path d="M7.58366 14.0833C6.98535 14.0833 6.50033 14.5684 6.50033 15.1667C6.50033 15.765 6.98535 16.25 7.58366 16.25H8.66699C9.2653 16.25 9.75033 15.765 9.75033 15.1667C9.75033 14.5684 9.2653 14.0833 8.66699 14.0833H7.58366Z" fill="#E01042"/>
<path d="M10.8337 15.1667C10.8337 14.5684 11.3187 14.0833 11.917 14.0833H18.417C19.0153 14.0833 19.5003 14.5684 19.5003 15.1667C19.5003 15.765 19.0153 16.25 18.417 16.25H11.917C11.3187 16.25 10.8337 15.765 10.8337 15.1667Z" fill="#E01042"/>
<path d="M7.58366 10.8333C6.98535 10.8333 6.50033 11.3184 6.50033 11.9167C6.50033 12.515 6.98535 13 7.58366 13H13.0003C13.5986 13 14.0837 12.515 14.0837 11.9167C14.0837 11.3184 13.5986 10.8333 13.0003 10.8333H7.58366Z" fill="#E01042"/>
<path d="M15.167 11.9167C15.167 11.3184 15.652 10.8333 16.2503 10.8333H18.417C19.0153 10.8333 19.5003 11.3184 19.5003 11.9167C19.5003 12.515 19.0153 13 18.417 13H16.2503C15.652 13 15.167 12.515 15.167 11.9167Z" fill="#E01042"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.58366 3.25C4.59212 3.25 2.16699 5.67512 2.16699 8.66667V15.1667C2.16699 18.1582 4.59212 20.5833 7.58366 20.5833V22.5256C7.58366 23.9734 9.33401 24.6984 10.3577 23.6747L13.4491 20.5833H18.417C21.4085 20.5833 23.8337 18.1582 23.8337 15.1667V8.66667C23.8337 5.67512 21.4085 3.25 18.417 3.25H7.58366ZM13.0011 18.4167H18.417C20.2119 18.4167 21.667 16.9616 21.667 15.1667V8.66667C21.667 6.87174 20.2119 5.41667 18.417 5.41667H7.58366C5.78873 5.41667 4.33366 6.87174 4.33366 8.66667V15.1667C4.33366 16.9616 5.78873 18.4167 7.58366 18.4167H8.66699C9.2653 18.4167 9.75033 18.9017 9.75033 19.5V21.2179L12.2343 18.734C12.4458 18.5224 12.7238 18.4167 13.0011 18.4167C13.0013 18.4167 13.0008 18.4167 13.0011 18.4167Z" fill="#E01042"/>
</g>
</g>
</svg>
