import UniverseModule from '../universe'
import { parseEmiAppdockConfig } from '../factories'

export default class AppdockModule extends UniverseModule {
    /**
     * @param {String} population Targeted population
     * @returns {AjaxObservable}
     */
    getConfig = (population) => this.getEmiConfig(population)

    _makeConfigParser = (ageLimit) => (config) => {
        return parseEmiAppdockConfig(config, ageLimit, this.config)
    }
}
