import { createEffect, createSignal, on } from 'solid-js'
import { useSelector } from '../../store'
import { ToastType } from '../toast/Toast'
import { useToast } from '../toast/useToast'
import { useConfig } from '../config/useConfig'
import { isPlatform } from '../../utils'
import { useI18n } from '../../i18n'
import {getErrorMessage} from './ErrorMessages'

export interface ErrorHandlingProps {
    children?: any
    ignoredNotification: string[]
    errorCodes: Record<string, any>
}

export const ErrorHandling = (props: ErrorHandlingProps) => {
    const toast = useToast()
    const { getConfig } = useConfig()
    const { getI18n } = useI18n()

    const notificationsLength = useSelector((s: any) => s.notifications?.queue.length)
    const notifications = useSelector((s: any) => s.notifications?.queue)
    const [shownNotificationsIds, setShownNotificationsIds] = createSignal<any[]>([])

    createEffect(
        on([() => notifications(), () => notificationsLength()], () => {
            notifications().forEach((n: any) => {
                const tag = Array.isArray(n.message.tag) ? n.message?.tag : [n.message?.tag]
                if (
                    !shownNotificationsIds().includes(n.id) &&
                    !tag?.find((t: string) => props.ignoredNotification.includes(t))
                ) {
                    const customMessage = getConfig().customErrorMsg[tag[0]];
                    const notificationMsg: string = tag.join(', ')
                    const title = customMessage?.title ?? n.type ?? n.message.type

                    if(customMessage?.description ?? notificationMsg === "pinCodeUpdatedSuccess")//skip error message
                        return;
                    
                    toast.show({
                        title: title ==='info'? '':title,
                        description: getErrorMessage(getI18n,customMessage?.description ?? notificationMsg,n),
                        subDescription: customMessage?.subDescription ?? (props.errorCodes[tag[0]]?.description ==='Exceeded quota' ? '' : props.errorCodes[tag[0]]?.description),
                        type: ToastType.PLAIN,
                        sticky: isPlatform("laptop") ? (title ==='info' ? false : true) : false,
                    })
                    setShownNotificationsIds([...shownNotificationsIds(), n.id])
                }
            })
        })
    )

    return <>{props.children}</>
}
