import { Reminder } from 'models'

export const remindersFactory = ({ currentPlaybill, ID } = {}) => {
    if (!currentPlaybill) return {}
    return new Reminder({
        id: currentPlaybill.id,
        availabilityStart: currentPlaybill.startTime,
        availabilityEnd: currentPlaybill.endTime,
        eventId: ID,
        channelId: currentPlaybill.channelID,
    })
}
