import { IContentNode, GridBody, GridHighlight } from '@typings/node'
import { Node } from './Node'

/**
 * A ContentNode is a 'leaf' node associated with data formatted following the 'Generic Universe' structure (Highlight & Body)
 */

export class ContentNode extends Node {
    highlight: GridHighlight
    body: GridBody
    constructor(props: IContentNode) {
        super(props)

        const _computePropValue = this._makeComputePropValue(props)

        this.highlight = _computePropValue('highlight', { data: [] })
        this.body = _computePropValue('body', { data: [] })
    }

    /** Checks if the Node is a ContentNode (always `true`) */
    isContentNode() {
        return true
    }
}
