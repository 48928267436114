import { HashMap } from '@typings/generic'
import { Player, Score, Team } from '@typings/opta'
import { MatchStatsResponse, ShortContestantResponse } from '../responses'
import { has, keyBy, isUndefined, get } from 'lodash'
import { soccerMatchStatsFactory } from 'services/opta/factories'

type OptaMapping = { args: [string, string]; result: (...args: [number, number]) => number }

const teamMapping = {
    teamId: 'id',
    name: 'officialName',
    code: 'code',
    position: 'position',
    country: 'country.name',
}

const sumMap = {
    posAcc: 'possessionPercentage',
    totalScore: 'totalScoringAtt',
    totalCorners: 'wonCorners',
    foulsAcc: 'fkFoulLost',
}

const attMap: { [key: string]: OptaMapping | string } = {
    shotAcc: {
        args: ['ontargetScoringAtt', 'totalScoringAtt'],
        result: (onTarget: number, total: number) => {
            const accuracy = Math.floor((onTarget / total) * 100)
            return isNaN(accuracy) ? 0 : accuracy
        },
    },
    offTarget: 'shotOffTarget',
    onTarget: 'ontargetScoringAtt',
    blocked: 'blockedScoringAtt',
}

const pasMap: { [key: string]: OptaMapping | string } = {
    pasAcc: {
        args: ['accuratePass', 'totalPass'],
        result: (acc: number, total: number) => {
            const accuracy = Math.round((acc / total) * 100)
            return isNaN(accuracy) ? 0 : accuracy
        },
    },
    total: 'totalPass',
    goodPass: 'accuratePass',
    totalCross: 'totalCross',
    goodCross: 'accurateCross',
}

const defMap = {
    intercenptions: 'interceptionWon',
    blockedGoals: 'outfielderBlock',
    clearances: 'effectiveClearance',
    saves: 'saves',
}

const disMap = {
    wonFouls: 'fkFoulWon',
    yellowCards: 'totalYellowCard',
    redCards: 'totalRefCard',
}

const isOptaMapping = (value: OptaMapping | string): value is OptaMapping =>
    (value as OptaMapping).result !== undefined

const mapGameStats = (
    mapping: { [key: string]: OptaMapping | string },
    data: HashMap<string, { value: string }>
): HashMap<string, number> =>
    Object.keys(mapping).reduce(
        (acc, key) => ({
            ...acc,
            [key]: isOptaMapping(mapping[key])
                ? (mapping[key] as OptaMapping).result(
                      ...((mapping[key] as OptaMapping).args.map((arg) =>
                          has(data, arg) ? Number(data[arg].value) : 0
                      ) as [number, number])
                  )
                : Number(
                      has(data, mapping[key] as string) ? data[mapping[key] as string].value : 0
                  ),
        }),
        {}
    )

const findByType = (i: { type: string; value: string }[], type: string) =>
    i.find((i) => i.type === type)

const transformMatchStats = (data: MatchStatsResponse) => {
    const { matchInfo, liveData } = data
    const { matchDetails } = liveData
    return soccerMatchStatsFactory({
        id: matchInfo.id,
        teams: matchInfo.contestant.map((i) =>
            (Object.keys(teamMapping) as Array<keyof typeof teamMapping>).reduce((acc, val) => {
                if (teamMapping[val].includes('.')) {
                    return {
                        ...acc,
                        [val]: get(i, teamMapping[val].split('.')),
                    }
                }
                return { ...acc, [val]: i[teamMapping[val] as keyof ShortContestantResponse] }
            }, {} as Team)
        ) as Team[],
        status: matchDetails?.matchStatus || 'Fixture',
        winner: matchDetails?.winner || '',
        minutesPlayed: matchDetails?.matchLengthMin || 0,
        secondsPlayed: matchDetails?.matchLengthSec || 0,
        date:
            has(matchInfo, ['date']) && has(matchInfo, ['time'])
                ? matchInfo.date + matchInfo.time
                : '',
        scores: has(matchDetails, ['scores'])
            ? (matchDetails?.scores.total as Score)
            : ({} as Score),
        lineUp: has(liveData, 'lineUp')
            ? liveData.lineUp.map((team) => ({
                  code: keyBy(matchInfo.contestant, 'id')[team.contestantId].code,
                  coach: `${team.teamOfficial.firstName} ${team.teamOfficial.lastName}`,
                  players: has(team, 'player')
                      ? team.player.reduce<Player[]>(
                            (acc, val) => [
                                ...acc,
                                {
                                    name: val.matchName,
                                    shirtNumber: val.shirtNumber,
                                    position: val.position,
                                    subPosition: val.subPosition || undefined,
                                    hasOff: !isUndefined(findByType(val.stat, 'totalSubOff')),
                                    hasOn: !isUndefined(findByType(val.stat, 'totalSubOn')),
                                    goals: findByType(val.stat, 'goals')
                                        ? Number(findByType(val.stat, 'goals')?.value)
                                        : undefined,
                                    yellowCard: !isUndefined(findByType(val.stat, 'yellowCard')),
                                    redCard: !isUndefined(findByType(val.stat, 'redCard')),
                                },
                            ],
                            []
                        )
                      : [],
              }))
            : [],
        gameStats: has(liveData, 'lineUp')
            ? liveData.lineUp
                  ?.map((val) => ({
                      teamId: val.contestantId,
                      stats: keyBy(val.stat, 'type'),
                  }))
                  .map((i) => ({
                      teamId: i.teamId,
                      summary: mapGameStats(sumMap, i.stats),
                      attacking: mapGameStats(attMap, i.stats),
                      passing: mapGameStats(pasMap, i.stats),
                      defence: mapGameStats(defMap, i.stats),
                      discipline: mapGameStats(disMap, i.stats),
                  }))
            : [],
    })
}

const resolveLegacyId = (id: string, type?: string, withLegacyIds = false) => {
    if (!withLegacyIds || id.length > 8) {
        switch (type) {
            case 'GameID':
                return { id: `/${id}`, legacy: false }
            case 'CalendarID': {
                return { id, legacy: true, field: 'tmcl' }
            }
            default:
                return { id: `/${id}`, legacy: false }
        }
    }

    const extractNums = (str: string) => str.match(/\d+/g)?.join('')

    switch (type) {
        case 'GameID':
            return {
                id: 'urn:perform:opta:fixture:' + extractNums(id),
                legacy: true,
            }
        case 'CalendarID':
            return {
                id: 'urn:perform:optacore:tournamentcalendar:' + extractNums(id),
                legacy: true,
            }
        default:
            return { id, legacy: true }
    }
}

const isValidDate = (d: number | Date) => isFinite(+d)

export { mapGameStats, resolveLegacyId, transformMatchStats, isValidDate }
