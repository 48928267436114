import { IPortalNode, PortalLink } from '@typings/node'
import { Node } from './Node'

/**
 * A PortalNode is a 'leaf' node with a collection of links (see {@link PortalLink}) to other nodes.
 * It is similar to a {@link NavigationNode} but differs in that each link is associated with an image/tile.
 */

export class PortalNode extends Node {
    links: PortalLink[]

    constructor(props: IPortalNode) {
        super(props)

        const computePropValue = this._makeComputePropValue(props)

        this.links = computePropValue('links', [])
    }

    /**
     * Checks if the Node is a PortalNode (always `true`)
     * */
    isPortalNode() {
        return true
    }
}
