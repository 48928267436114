import { Channel } from 'models/Channel'
import { IMAGE_TYPE, getImage } from './helpers'
import proxyConfig from '../config'
import Constants from 'api-constants'
import { eligibilityFactory } from './eligibility'
import { vodStreamFactory as streamFactory } from 'services/proxy/factories/stream'

/**
 * Return an instance of Channel from given raw data from Proxy
 * @param {Object} c
 * @return {Channel}
 */
export const channelFactory = (c, clientConfig = {}) => {
    if (c.pictures) {
        c.logo =
            (c.pictures.logos_medium && c.pictures.logos_medium[0]) ||
            (c.pictures.logos_big && c.pictures.logos_big[0]) ||
            (c.pictures.logos_small && c.pictures.logos_small[0]) ||
            null

        c.largeLogo =
            (c.pictures.logos_big && c.pictures.logos_big[0]) ||
            (c.pictures.logos_medium && c.pictures.logos_medium[0]) ||
            (c.pictures.logos_small && c.pictures.logos_small[0]) ||
            null

        c.smallLogo =
            (c.pictures.logos_small && c.pictures.logos_small[0]) ||
            (c.pictures.logos_medium && c.pictures.logos_medium[0]) ||
            (c.pictures.logos_big && c.pictures.logos_big[0]) ||
            null
    }

    const {
        recordable,
        timeshift_on_demand: timeshiftOnDemand,
        start_over: startOver,
        catchup,
        shareable,
        /* record_duration,*/
        catchup_duration,
    } = c.rights || {}

    if (clientConfig && clientConfig.proxyVersion >= 3) {
        c.productIds = c.subscription_id
        c.name = c.title
        const smallLogo = getImage(c.pictures, IMAGE_TYPE.LOGO_SMALL)
        const mediumLogo = getImage(c.pictures, IMAGE_TYPE.LOGO_MEDIUM)
        const largeLogo = getImage(c.pictures, IMAGE_TYPE.LOGO_BIG)
        c.logo = mediumLogo || largeLogo || smallLogo
        c.largeLogo = largeLogo || mediumLogo || smallLogo
        c.smallLogo = smallLogo || mediumLogo || largeLogo
    } else {
        // subscription_id could be "null", in this case we should use "undefined" as value
        // to ensure channel won't be instantiated with a [null] productIds
        c.productIds = c.subscription_id ? c.subscription_id.split(',') : undefined
    }

    const eligibility = c.eligibility
        ? eligibilityFactory(c.eligibility)
        : // The following flag are depreacted but kept to be compatible with proxy instances on the field (to be removed later)
          [
              c.iptv ? Constants.eligibility.IPTV : undefined,
              c.ott ? Constants.eligibility.OTT : undefined,
          ].filter(Boolean)

    if (catchup_duration) {
        c.rollingBufferDuration = catchup_duration / 3600
        c.rollingBufferPending = false
        c.rollingBufferRetrieved = true
    }

    let playbackUrl = c.playback_url

    if (c?.streams?.length) {
        const { platform } = clientConfig

        const isAppleDevice = [
            Constants.platform.ios,
            Constants.platform.tvos,
            Constants.platform.safari,
        ].includes(platform)

        if (isAppleDevice) {
            playbackUrl = streamFactory(c.streams, proxyConfig.streamTypes.HLS).uri
        } else {
            playbackUrl = streamFactory(c.streams, proxyConfig.streamTypes.DASH).uri
        }

        if (!playbackUrl) {
            playbackUrl = streamFactory(c.streams, proxyConfig.streamTypes.MAIN).uri
        }

        if (!playbackUrl) {
            playbackUrl = c.playback_url
        }
    }

    return new Channel({
        shareable,
        startOver,
        catchup,
        recordable,
        availabilityStart: c.availability_start ? new Date(c.availability_start * 1000) : undefined,
        availabilityEnd: c.availability_end ? new Date(c.availability_end * 1000) : undefined,
        // props above can be overridden by 'channels' as per initial design c could come either from proxy or external backend (don't take risk to refactor)
        ...c,
        timeshiftOnDemand,
        sourceId: c.source_id,
        platformId: c.platform_id || c.source_id,
        technicalId: c.technical_id || c.platform_id || c.source_id, // Introduced mainly because of starhub migration issue
        programs: c.programs || [],
        title: c.name,
        channelNumber: c.number,
        channelFilter: c.channel_filter,
        isKidsChannel: c.kids_channel,
        sourceMode: Array.isArray(c.source_mode) ? c.source_mode : undefined,
        playbackUrl,
        playbackId: c.playback_id,
        startOverUrl: c.startover_url,
        drmProvider: c.drm_provider,
        adEnabled: c.ad_support,
        analytics: c.analytics,
        eligibility,
        ratingId: c.rating_id,
        pcValue: (clientConfig.pcLevels || {})[c.rating_id] || 0,
        multiCamRef: c.multi_cam_ref,
    })
}
