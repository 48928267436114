/* eslint-disable @typescript-eslint/no-explicit-any */
import CryptoJS from 'generated-libs/crypto-js'

import { ENCTYPE } from 'enums'

/**
 * Encryption Helper.
 * To generate a passphrase
 * Go to https://www.allkeysgenerator.com/Random/Security-Encryption-Key-Generator.aspx
 * to generate a new encryption key. Choose AES256
 */

export default class EncryptionHelper {
    /**
     * Singleton Instance
     */
    // eslint-disable-next-line no-use-before-define
    private static _instance: EncryptionHelper
    /**
     * By default enable encryption
     */
    static _encryptionEnabled = false
    // Keep a default passphrase but disable encryption by default
    // Go to https://www.allkeysgenerator.com/Random/Security-Encryption-Key-Generator.aspx
    // to generate a new encryption key. Choose AES256
    static _defaultPassphrase = 'Zr4u7x!A%C*F-JaNdRgUkXp2s5v8y/B?'

    /**
     * Encryption type
     */
    static ENCTYPE = ENCTYPE
    static passphrase: string

    //-----------------------------------------------------
    //-----                 SINGLETON                 -----
    //-----------------------------------------------------

    // eslint-disable-next-line no-useless-constructor, @typescript-eslint/no-empty-function
    private constructor() {}

    /**
     * Use to retrieve the instance of this class
     * @returns instance
     */
    static getInstance() {
        if (!this._instance) {
            this._instance = new this()
        }
        return this._instance
    }

    /**
     * Encrypt the message using passphrase/secret.
     * This method transforms first the message into a proprietary json format
     * @param {String|Array|Object} data Data to be encrypted
     * @param {String} encryptionType  Encryption type - only AES supported yet (@link EncryptionHelper.ENCTYPE)
     * @returns {String} Cipher Text
     */
    encrypt(data: string | Array<any> | object, encryptionType?: `${ENCTYPE}`) {
        if (!this.isEncryptionEnabled() || !data) {
            return data
        }

        let cipherText
        switch (encryptionType) {
            case EncryptionHelper.ENCTYPE.AES: // Only AES is supported for the moment
            default:
                cipherText = CryptoJS.AES.encrypt(
                    CryptoJS.enc.Utf8.parse(JSON.stringify(this._transcode2Json(data))),
                    this._getPassphrase()
                ).toString()
        }
        return cipherText
    }

    /**
     * Decrypt Message
     * @param {String} cipherText Encrypted message
     * @returns {Object} { data, encryptionType, dataType, decrypted }
     * {String} data            Descrypted data
     * {String} encryptionType  (@link EncryptionHelper.ENCTYPE)
     * {String} dataType        typeof data
     * {Boolean} decrypted      indicate if the data has been decrypted or not
     */
    decrypt(cipherText: string) {
        if (!this.isEncryptionEnabled() || !cipherText) {
            return { data: cipherText, decrypted: false }
        }

        const bytes = CryptoJS.AES.decrypt(cipherText, this._getPassphrase())
        try {
            const text = bytes.toString(CryptoJS.enc.Utf8)
            const decryptedObject = JSON.parse(text)
            const { data, encryptionType, dataType } = decryptedObject || {}
            return { data, encryptionType, dataType, decrypted: true }
        } catch (e) {
            // By using try catch for decoding JSON, the error means the data is not a json ! Meaning it is a clear message
            // To be honest we should throw an error here, but as this helper will be used to decrypt local storage data storage in clear
            // already in production
            return { data: cipherText, decrypted: false }
        }
    }

    /**
     * Enable encryption
     * @param {Boolean} enabled Enable or not the Encryption
     */
    enableEncryption(enabled = true) {
        EncryptionHelper._encryptionEnabled = enabled
    }

    /**
     * @return {Boolean} true if enabled, false otherwise
     */
    isEncryptionEnabled() {
        return EncryptionHelper._encryptionEnabled
    }

    /**
     * Passphrase setter
     * @param {String} passphrase Passphrase/Secret Key
     */
    setPassphrase(passphrase: string) {
        EncryptionHelper.passphrase = passphrase
    }

    /**
     * Passphrase getter
     * _defaultPassphrase
     * @returns {String} passphrase
     */
    _getPassphrase() {
        return EncryptionHelper.passphrase || EncryptionHelper._defaultPassphrase
    }

    /**
     * Convert any kind of data (String, Object, Array) into a JSON string to prepare encryption
     * @param {String|Array|Object} data
     * @param {String} encryptionType Encryption type - only AES supported yet (@link EncryptionHelper.ENCTYPE)
     * @returns {Object} { encryptionType, type, data }
     */
    _transcode2Json(
        data: string | Array<any> | object,
        encryptionType = EncryptionHelper.ENCTYPE.AES
    ) {
        const cipherText = data
            ? typeof data === 'object'
                ? JSON.stringify(data)
                : data
            : undefined
        return {
            encryptionType: encryptionType,
            data: cipherText,
            dataType: typeof data,
        }
    }
}
