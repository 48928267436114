import { catchError, map, mergeMap } from 'rxjs/operators'
import { throwError } from 'rxjs'
import ConfigHelper from 'framework/helpers/config'
import { uniqBy } from 'lodash'
import { MatchEvent, Tournament } from 'models'
import { transformMatchStats } from './helpers'
import Fetch from './fetch'
import { Observable, of } from 'rxjs'
import {
    soccerFixtureFactory,
    soccerSquadPersonFactory,
    soccerTournamentTeamRanking,
    soccerPlayersRanking,
    soccerMatchEventsFactory,
} from './factories'
import DataHelper from 'framework/helpers/data'
import { fetchLiveSimulator_MatchStats, fetchLiveSimulator_MatchEvents } from './simulator'
import VoltError from 'VoltError'
import { Config, HashMap, ValueOf } from '@typings/generic'
import { ITournament } from '@typings/opta'
import {
    CalendarsResponse,
    MatchEventsResponse,
    MatchStatsResponse,
    MatchesResponse,
    RankingResponse,
    SquadResponse,
} from './responses'
import Constants from '@typings/constants'
import { SoccerFixture } from 'services/opta/models'

type OptaCompetitionCodeValues = ValueOf<typeof Constants.optaCompetitionCode>

export default class OptaApi extends Fetch {
    constructor(config: Config) {
        super(config)
        this.config = config
    }

    /**
     * Configuration required for accessing to API rights
     */
    _outletToken = ConfigHelper.getInstance().getConfig('opta').outletToken
    /**
     * Use for simulating LIVE events
     */
    _liveSimulatorEnabled = ConfigHelper.getInstance().getConfig('opta').liveSimulatorEnabled

    /**
     * Property for storing tournaments data and tournaments calendars
     */
    _cachedTournaments: HashMap<string, ITournament> = {}

    /**
     * Add tournament to cache object
     * @param {Array<ITournament>} tournaments array of tournaments
     */
    _addTournamentsToCache = (tournaments: ITournament[]) => {
        if (tournaments.length) {
            this._cachedTournaments = tournaments.reduce<Record<string, ITournament>>(
                (acc, tournament) => {
                    acc[tournament.competitionCode] = tournament
                    return acc
                },
                {
                    ...this._cachedTournaments,
                }
            )
        }
    }

    /**
     * Get tournament data by competition code from caching object
     * @param {string} competitionCode code of the competition
     * @returns {Array<ITournament>} array of tournaments
     */
    _getTournamentFromCacheByCode = (
        competitionCode: OptaCompetitionCodeValues
    ): Array<ITournament> => {
        if (!this._cachedTournaments[competitionCode]) return []
        return [this._cachedTournaments[competitionCode]]
    }

    /**
     * Get Tournament Calendars
     * @param {Constants.optaCompetitionCode} competitionCode Code of the competition (English Premier League, FIFA World Cup, German Bundesliga, UEFA Champions League)
     * @returns {Observable{Array<ITournament>}}
     */
    getTournamentCalendars = (
        competitionCode: OptaCompetitionCodeValues
    ): Observable<Array<ITournament>> => {
        const tournamentFromCache = this._getTournamentFromCacheByCode(competitionCode)
        if (tournamentFromCache.length) return of(tournamentFromCache)

        return this.fetch<CalendarsResponse>({
            endpoint: `/soccerdata/tournamentcalendar/${this._outletToken}/active/authorized`,
            params: {
                stages: 'yes',
            },
            method: 'GET',
        }).pipe(
            map(({ response }) => {
                const tournaments = response.competition
                    .filter(
                        (i) =>
                            i.competitionCode === competitionCode || competitionCode === undefined
                    )
                    .map(
                        (comp) =>
                            new Tournament({
                                ...comp,
                                tournamentCalendar: comp.tournamentCalendar.map((c) => ({
                                    active: c.active === 'yes',
                                    id: c.id,
                                    startDate: c.startDate,
                                    endDate: c.endDate,
                                })),
                            })
                    )
                this._addTournamentsToCache(tournaments)
                return tournaments
            })
        )
    }

    /**
     * Get match stats by ID
     * @param {String} gameId uuid
     * @returns {Observable<IMatchStats>}
     */
    getMatchStats = ({ gameId }: { gameId: string }) => {
        if (this._liveSimulatorEnabled) {
            this.logger.warn(
                '[DEBUG] Match Stats simulator enabled. If you see this comment in OFFICIAL delivery, this is a mistake !!!'
            )
        }
        return (
            this._liveSimulatorEnabled
                ? fetchLiveSimulator_MatchStats(gameId)
                : this.fetch<MatchStatsResponse>({
                      id: gameId,
                      type: 'GameID',
                      endpoint: `/soccerdata/matchstats/${this._outletToken}`,
                      params: {
                          // _fld: TODO: fetch by complex params
                          // 'main.id,main.con.con.id,main.con.con.co,main.con.con.ofna,main.con.con.po,main.con.con.cou.na,lida.made.mast,lida.made.wi,lida.made.malemi,lida.made.malese,lida.made.sco.tot.ho,lida.made.sco.tot.aw,lida.liup.coid,lida.liup.pla.sta.va,lida.liup.pla.sta.ty',
                          detailed: 'yes',
                      },
                      method: 'GET',
                  })
        ).pipe(map(({ response: data = {} }) => transformMatchStats(data)))
    }

    /**
     * Get all live matches by CalendarID
     */
    getLiveMatchStats = ({ tournamentId }: { tournamentId: string }) =>
        this.fetch<MatchesResponse>({
            endpoint: `/soccerdata/match/${this._outletToken}/`,
            params: {
                live: 'yes',
                lineups: 'yes',
                tmcl: tournamentId,
            },
            method: 'GET',
        }).pipe(
            map(({ response }) =>
                response.match
                    .filter((m) => m?.liveData?.matchDetails?.matchStatus === 'Playing')
                    .map((m) => transformMatchStats(m))
            )
        )

    /**
     * Gets team squad info
     * @param {Object} args
     * @param {String} args.teamId The contestant UUID
     * @returns {Observable<Object>}
     */
    getTeamSquad = ({ teamId }: { teamId: string }) =>
        this.fetch<SquadResponse>({
            endpoint: `/soccerdata/squads/${this._outletToken}/`,
            params: {
                ctst: teamId,
                detailed: 'yes',
            },
            method: 'GET',
        }).pipe(
            mergeMap(({ response: { squad = [] } }) => {
                if (squad.length && squad[0].person && squad[0].person.length)
                    return of({
                        soccerSquadPersons: squad[0].person.map((person) =>
                            soccerSquadPersonFactory(person)
                        ),
                        done: true,
                    })
                return of({ soccerSquadPersons: [], done: true })
            })
        )

    /**
     * Get array of MatchEvents
     * @param {Object} args
     * @param {String} args.gameId  uuid as the GameID
     * @returns {Observable<Object>}
     */
    getMatchEvents = ({ gameId }: { gameId: string }) => {
        if (this._liveSimulatorEnabled) {
            this.logger.warn(
                '[DEBUG] Match Events simulator enabled. If you see this comment in OFFICIAL delivery, this is a mistake !!!'
            )
        }
        return (
            this._liveSimulatorEnabled
                ? fetchLiveSimulator_MatchEvents(gameId)
                : this.fetch({
                      id: gameId,
                      type: 'GameID',
                      endpoint: `/soccerdata/matchevent/${this._outletToken}`,
                      params: { type: '6,15,16,17,18,30,32,58' },
                      method: 'GET',
                  })
        ).pipe(
            mergeMap(
                ({
                    response: {
                        liveData: {
                            event,
                            matchDetails: { matchStatus = 'Fixture' },
                        },
                    },
                }: {
                    response: MatchEventsResponse
                }) => {
                    if (event?.length) {
                        return of({
                            // keep unique events by joining typeId and timestamp and filtering from non null events
                            events: uniqBy(soccerMatchEventsFactory(event), (event: MatchEvent) =>
                                [event.typeId, event.timestamp].join()
                            ),
                            matchStatus,
                            done: true,
                        })
                    }
                    return of({ events: [], matchStatus, done: true })
                }
            ),
            catchError<
                Error | { events: MatchEvent[]; matchStatus: string; done: boolean },
                Observable<Error | { events: MatchEvent[]; matchStatus: string; done: boolean }>
            >((error) => {
                if (error.code === VoltError.codes.OPTA_FEED_NO_DATA.code) {
                    // According to opta this error is thrown and this is an expected behaviour as
                    // data will be available when there are line-ups pushed.
                    this.logger.warn(
                        'Match Events: OPTA_FEED_NO_DATA waiting line up to get access to match events'
                    )
                    return of({ events: [], matchStatus: 'Fixture', done: true })
                }
                return throwError(error)
            })
        )
    }

    /**
     * Gets fixtures by match day, team id, or competition id
     * @param {Object} args
     * @param {String} [args.matchDay=''] the day of the match (actually week)
     * @param {String} [args.teamId=''] the id of a team to retieve fixtures only for specified team
     * @param {String} [args.competitionId='2kwbbcootiqqgmrzs6o5inle5'] the id of the competition (EPL etc)
     * @param {Number} [args.page=1] number of page with data (paging starts from 1 for OPTA)
     * @param {Number} [args.tournamentCalendarId='80foo89mm28qjvyhjzlpwj28k'] id of the tournament calendar
     * @returns {Observable<GetFixtureResponse>}
     */
    getFixtures = ({
        matchDay = '',
        teamId = '',
        competitionCode = 'EPL',
        page = 0,
        limit = 30,
    }: {
        matchDay?: string
        teamId?: string
        competitionCode?: OptaCompetitionCodeValues
        page?: number
        limit?: number
    }): Observable<{ programs: SoccerFixture[]; done: boolean }> => {
        page += 1
        return this.getTournamentCalendars(competitionCode).pipe(
            mergeMap((tournament) => {
                return this.recursiveFetch<MatchStatsResponse>(
                    (_, page, limit) =>
                        this.fetch({
                            endpoint: `/soccerdata/match/${this._outletToken}/`,
                            retrieveResponseHeaders: true,
                            params: {
                                ctst: teamId,
                                week: matchDay,
                                comp: tournament[0].id,
                                tmcl: tournament[0].tournamentCalendar[0].id,
                                live: 'yes',
                                _pgNm: page,
                                _pgSz: limit,
                                _ordSrt: 'asc',
                                _fld: 'main.id,main.we,main.con.con.id,main.con.con.po,main.con.con.na,main.com.na,main.da,main.ti,main.con.con.shna,lida.made.sco.tot.ho,lida.made.sco.tot.aw,lida.made.mast',
                            },
                            method: 'GET',
                        }),
                    { limit, page, responseResource: 'match' }
                ).pipe(
                    mergeMap((allFixtures) => {
                        if (!allFixtures.length) return of({ programs: [], done: true })
                        return of({
                            programs: allFixtures.map((fixture) => soccerFixtureFactory(fixture)),
                            done: true,
                        })
                    }),
                    catchError(() => of({ programs: [], done: true }))
                )
            })
        )
    }

    /**
     * Get scores for match by its ID
     * @param {Object} args
     * @param {string} args.gameId fixture id
     * @returns {MatchScores}
     */
    getMatchScores = ({ gameId }: { gameId: string }) =>
        this.fetch<MatchStatsResponse>({
            id: gameId,
            type: 'GameID',
            endpoint: `/soccerdata/matchstats/${this._outletToken}`,
            params: {
                _fld: 'lida.made.sco.tot.ho,lida.made.sco.tot.aw',
            },
            method: 'GET',
        }).pipe(
            mergeMap(
                ({
                    response: {
                        liveData: { matchDetails },
                    },
                }) => {
                    if (!matchDetails?.scores?.total) return of({})
                    return of(matchDetails?.scores?.total)
                }
            ),
            catchError(() => of({}))
        )

    /**
     * Get all fixtures in `playing` status
     * @returns {Array<SoccerFixture>}
     */
    getPlayingMatches = () =>
        this.fetch<MatchesResponse>({
            endpoint: `/soccerdata/match/${this._outletToken}`,
            params: {
                live: 'yes',
                status: 'playing',
            },
            method: 'GET',
        }).pipe(
            map(({ response: { match } }) => {
                if (!match.length) return []
                return match.map((match) => soccerFixtureFactory(match))
            }),
            catchError(() => of([]))
        )

    /**
     * Set the parameter in local storage which determines should game scores be displayed
     * @param {Boolean} displayScores detrmines show scores or not
     */
    displayGameScores(displayScores: boolean) {
        return DataHelper.getInstance().storeData([
            [DataHelper.STORE_KEY.DISPLAY_GAME_SCORES, JSON.stringify(displayScores)],
        ])
    }

    /**
     * Get the parameter from local storage which determines should game scores be displayed
     * @returns {Observable<Boolean>}
     */
    isGameScoresDisplayed(): Observable<boolean> {
        return of(
            JSON.parse(
                (DataHelper.getInstance().getData(
                    DataHelper.STORE_KEY.DISPLAY_GAME_SCORES
                ) as string) || '{}'
            ) || false
        )
    }

    getTeamRankings({ competitionCode = 'EPL' }: { competitionCode: OptaCompetitionCodeValues }) {
        return this.getTournamentCalendars(competitionCode).pipe(
            mergeMap((tournament) => {
                return this.fetch({
                    id: tournament[0].tournamentCalendar[0].id,
                    type: 'CalendarID',
                    params: {
                        type: 'total',
                    },
                    endpoint: `/soccerdata/standings/${this._outletToken}`,
                    method: 'GET',
                }).pipe(
                    mergeMap(({ response: data }) => {
                        if (!data) return of({ done: false })
                        return of({
                            data: soccerTournamentTeamRanking({ data }),
                            done: true,
                        })
                    }),
                    catchError(() => of({ done: false }))
                )
            })
        )
    }

    getAssistRankings({
        competitionCode = 'EPL',
        maxPlayerRanking = 20,
    }: {
        competitionCode: OptaCompetitionCodeValues
        maxPlayerRanking: number
    }) {
        return this.getTournamentCalendars(competitionCode).pipe(
            mergeMap((tournament) => {
                return this.fetch<RankingResponse>({
                    id: tournament[0].tournamentCalendar[0].id,
                    type: 'CalendarID',
                    endpoint: `/soccerdata/rankings/${this._outletToken}`,
                    method: 'GET',
                }).pipe(
                    mergeMap(({ response: data }) => {
                        if (!data) return of({ done: false })
                        return of({
                            data: soccerPlayersRanking({
                                data,
                                field: 'totalAssists',
                                maxPlayerRanking,
                            }),
                            done: true,
                        })
                    }),
                    catchError(() => of({ done: false }))
                )
            })
        )
    }

    getScorerRankings({
        competitionCode = 'EPL',
        maxPlayerRanking = 20,
    }: {
        competitionCode: OptaCompetitionCodeValues
        maxPlayerRanking: number
    }) {
        return this.getTournamentCalendars(competitionCode).pipe(
            mergeMap((tournament) => {
                return this.fetch<RankingResponse>({
                    id: tournament[0].tournamentCalendar[0].id,
                    type: 'CalendarID',
                    endpoint: `/soccerdata/rankings/${this._outletToken}`,
                    method: 'GET',
                }).pipe(
                    mergeMap(({ response: data }) => {
                        if (!data) return of({ done: false })
                        return of({
                            data: soccerPlayersRanking({
                                data,
                                field: 'totalGoals',
                                maxPlayerRanking,
                            }),
                            done: true,
                        })
                    }),
                    catchError(() => of({ done: false }))
                )
            })
        )
    }
}

/**
 * @typedef {Object} GetFixtureResponse
 * @property {Array<Object>} programs array of fixtures
 * @property {Boolean} done defines if request is done
 */

/**
 * @typedef {Object} MatchScores
 * @property {Number} away away team score
 * @property {Number} home home team score
 * */
