import { ISubscription, SubscriptionTitle } from '@typings/tile'
import { Product } from './Product'
import Constants from 'api-constants'
import { HashMap } from '../..'
import { ACTIVATION_MODE, ACTIVATION_STATUS, PRODUCT_GENERATION, SERVICE_TYPE } from 'enums'

/**
 * A subscription product that can be associated with either a list of channels or Titles or both
 * @property {Array<String|Number>} [channels=[]] List of platform identifiers of channels packaged with the Subscription
 * @property {SubscriptionTitles} [titles={}] List of Titles packaged with the Subscription, indexed by TitleId
 * @property {Boolean} [displayable=true] A subscription by default is displayable in a Tile, but on specific occasion some product SHALL not be displayed
 * @property {String} launchCommand to launch the application when Subscription.SERVICE_TYPE.PARTNER_APP or Subscription.SERVICE_TYPE.PARTNER_APP_EXTERNAL
 * @property {String} launchUri to launch the application when Subscription.SERVICE_TYPE.PARTNER_APP or Subscription.SERVICE_TYPE.PARTNER_APP_EXTERNAL
 * @property {String} [serviceType=Subscription.SERVICE_TYPE.IN_APP] The type of service for the subscription (In App Content, Application, Bundle, etc..)
 * @property {Boolean} activationStatus For some backend which provides OTT Subscriptions (means external like Netflix)
 * This field means after a purchase, a user do need to activate it before to use it (used now only for Market One)
 * @property {String} activationMode if activationStatus=ACTIVATION_REQUIRED, then this field indicates how the user can activate
 * his subscription (Either by email or Deeplink or Redeem Code) {@Link ACTIVATION_MODE}
 * @property {String} activationUri (Android) if activationStatus=ACTIVATION_REQUIRED this parameter contains the activation URL or Deeplink
 * @property {String} webActivationUri (WEB) if activationStatus=ACTIVATION_REQUIRED this parameter contains the activation URL or Deeplink
 * @property {String} activationCode if activationStatus=ACTIVATION_REQUIRED this parameter contains the activation Code
 * @property {String} activationId Id in the backend to perform activation
 * @property {String} [productGeneration=Subscription.PRODUCT_GENERATION.UNDEFINED] Depending on the project, the product can be categorized by New Generation, Legacy Product (Ex: Ooredoo)
 */

export class Subscription extends Product {
    displayable: boolean
    channels: (string | number)[]
    titles: HashMap<string | number, SubscriptionTitle>
    serviceType: `${SERVICE_TYPE}`
    launchCommand: string
    launchUri: string
    activationStatus: `${ACTIVATION_STATUS}`
    activationMode: `${ACTIVATION_MODE}`
    activationUri: string
    webActivationUri: string
    activationCode: string
    activationId: string
    modelType: string
    transactionAlternativeId: string
    billingDate: number
    productGeneration: `${PRODUCT_GENERATION}`
    subscriptionStatus?: string

    constructor(props: ISubscription) {
        super(props)

        const computePropValue = this._makeComputePropValue(props)

        this.displayable = computePropValue('displayable', true)

        this.channels = computePropValue('channels', [])
        this.titles = computePropValue('titles', {})
        this.subscriptionStatus = props.subscriptionStatus

        /**
         * The type of service for the subscription (In App Content, Application, Bundle, etc..)
         */
        this.serviceType = computePropValue('serviceType', Subscription.SERVICE_TYPE.IN_APP)
        /** Command and URI to launch the application when Subscription.SERVICE_TYPE.PARTNER_APP or Subscription.SERVICE_TYPE.PARTNER_APP_EXTERNAL */
        this.launchCommand = computePropValue('launchCommand', '')
        this.launchUri = computePropValue('launchUri', '')
        /**
         * Note : the three following field regarding subscription activation could be moved in dedicated class
         * But I did do it to avoid to create a class named activation or activation Link and introduce confusion...
         * Let me know if I should create a dedicated object and which name to used : SubscriptionActivation ?
         */
        this.activationStatus = computePropValue(
            'activationStatus',
            Subscription.ACTIVATION_STATUS.ACTIVATION_NOT_REQUIRED
        )
        this.activationMode = computePropValue(
            'activationMode',
            Subscription.ACTIVATION_MODE.UNDEFINED
        )
        /** Activation URI for Android applicaiton */
        this.activationUri = computePropValue('activationUri', '')

        this.productGeneration = computePropValue(
            'productGeneration',
            Subscription.PRODUCT_GENERATION.UNDEFINED
        )

        /** Activation URI for Web applicaiton */
        this.webActivationUri = computePropValue('webActivationUri', '')
        this.activationCode = computePropValue('activationCode', '')
        this.activationId = computePropValue('activationId', '')
        this.modelType = Constants.programType.subscription
        this.transactionAlternativeId = props.transactionAlternativeId
        this.billingDate = props.billingDate
    }

    static SERVICE_TYPE = SERVICE_TYPE

    static ACTIVATION_MODE = ACTIVATION_MODE

    static ACTIVATION_STATUS = ACTIVATION_STATUS

    /**
     * Depending on the project, the product can be categorized by New Generation, Legacy Product (Ex: Ooredoo)
     */
    static PRODUCT_GENERATION = PRODUCT_GENERATION

    getPropsToMerge(): string[] {
        return ['titles', ...super.getPropsToMerge()]
    }

    /**
     * Indicates whether the product is a Subscription
     * Always returns true
     */
    isSubscription(): boolean {
        return true
    }

    /**
     * By default a Subscription is displayable in a Tile, but something for some project/backend the subscription is hidden
     *
     * By displayable it means displayable in a Tile, not during the product/offer selection before a purchase within a list
     */
    isDisplayable(): boolean {
        return this.displayable
    }
}
