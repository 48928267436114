import {useContext} from 'solid-js'
import {I18nProviderContext} from './I18nProviderContext'

export const useI18n = () => {
    const context = useContext(I18nProviderContext)
    if (!context) {
        throw new Error(
            'could not find redux context value; please ensure the component is wrapped in a <ReduxProvider>'
        )
    }
    return context
}
