export default (props = {}) => <svg width="201" height="60" viewBox="0 0 201 60" fill="none" xmlns="http://www.w3.org/2000/svg"{...props}>
<rect x="0.5" width="200" height="60" rx="10.2" fill="black" fill-opacity="0.7"/>
<rect x="1.1" y="0.6" width="198.8" height="58.8" rx="9.6" stroke="#A6A6A6" stroke-opacity="0.3" stroke-width="1.2"/>
<path d="M112.271 43.6141C109.818 43.6141 108.024 42.4013 107.817 40.5541L104.964 40.554C105.144 43.9231 107.933 46.0545 112.064 46.0545C116.479 46.0545 119.255 43.8719 119.255 40.387C119.255 37.6624 117.667 36.1272 113.845 35.2234L111.793 34.7195C109.392 34.1263 108.411 33.3514 108.411 32.0345C108.411 30.3822 109.947 29.2469 112.193 29.2469C114.438 29.2469 115.975 30.369 116.144 32.2279H118.958C118.867 29.014 116.168 26.8065 112.232 26.8065C108.255 26.8065 105.455 29.014 105.455 32.2279C105.455 34.8236 107.043 36.4232 110.463 37.2494L112.877 37.8309C115.291 38.4242 116.311 39.2899 116.311 40.7357C116.311 42.4012 114.62 43.6141 112.271 43.6141Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M56.605 40.7094H63.7051L65.4087 45.744H68.4673L61.7422 27.117H58.6177L51.8926 45.744H54.8999L56.605 40.7094ZM62.9683 38.3861H57.3403L60.1163 30.2152H60.1939L62.9683 38.3861Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M77.3238 45.8861C80.7325 45.8861 82.9913 43.1747 82.9913 38.9545C82.9913 34.746 80.7192 32.0214 77.2725 32.0213C76.402 31.9933 75.5402 32.2017 74.7788 32.6245C74.0173 33.0472 73.3847 33.6685 72.9483 34.4222H72.897V32.1634H70.1987V50.2367H72.9863V43.5101H73.0508C73.4681 44.2674 74.0902 44.8919 74.8459 45.3122C75.6015 45.7324 76.4603 45.9313 77.3238 45.8861ZM76.5371 34.3973C78.7051 34.3973 80.126 36.205 80.126 38.9545C80.126 41.7289 78.7051 43.5233 76.5371 43.5233C74.4073 43.5233 72.9746 41.6908 72.9746 38.9545C72.9746 36.243 74.4073 34.3973 76.5371 34.3973Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M97.9385 38.9545C97.9385 43.1747 95.6797 45.8861 92.271 45.8861C91.4076 45.9313 90.5488 45.7323 89.7931 45.3121C89.0374 44.8919 88.4153 44.2674 87.9981 43.5101H87.9336V50.2367H85.146V32.1634H87.8442V34.4222H87.8955C88.3319 33.6685 88.9645 33.0472 89.726 32.6245C90.4874 32.2017 91.3492 31.9933 92.2197 32.0213C95.6665 32.0213 97.9385 34.7459 97.9385 38.9545ZM95.0733 38.9545C95.0733 36.205 93.6524 34.3973 91.4844 34.3973C89.3545 34.3973 87.9219 36.243 87.9219 38.9545C87.9219 41.6908 89.3545 43.5233 91.4844 43.5233C93.6524 43.5233 95.0733 41.7289 95.0733 38.9545Z" fill="white"/>
<path d="M125.271 28.9497V32.1636H127.854V34.3711H125.271V41.8579C125.271 43.021 125.788 43.563 126.923 43.563C127.23 43.5576 127.536 43.5361 127.84 43.4985V45.6928C127.33 45.7882 126.811 45.8314 126.292 45.8217C123.542 45.8217 122.47 44.789 122.47 42.1552V34.3711H120.496V32.1636H122.47V28.9497H125.271Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M135.79 31.9966C131.866 31.9966 129.349 34.6816 129.349 38.9546C129.349 43.2392 131.841 45.9126 135.79 45.9126C139.741 45.9126 142.232 43.2393 142.232 38.9546C142.232 34.6816 139.728 31.9966 135.79 31.9966ZM135.79 34.2935C138.049 34.2935 139.392 36.0234 139.392 38.9546C139.392 41.8974 138.049 43.6143 135.79 43.6143C133.531 43.6143 132.189 41.8974 132.189 38.9546C132.189 36.0366 133.531 34.2935 135.79 34.2935Z" fill="white"/>
<path d="M144.531 32.1635H147.189V34.475H147.254C147.434 33.753 147.857 33.115 148.452 32.6682C149.047 32.2214 149.777 31.993 150.521 32.0213C150.842 32.0202 151.162 32.0551 151.476 32.1254V34.7328C151.07 34.6089 150.647 34.552 150.223 34.5643C149.818 34.5479 149.414 34.6193 149.04 34.7736C148.665 34.928 148.328 35.1616 148.052 35.4584C147.776 35.7552 147.568 36.1083 147.441 36.4933C147.314 36.8783 147.272 37.2861 147.318 37.6888V45.744H144.531L144.531 32.1635Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M158.48 45.9126C161.552 45.9126 163.953 44.2207 164.328 41.7554L161.681 41.7554C161.434 42.369 160.995 42.8863 160.43 43.2305C159.865 43.5747 159.204 43.7272 158.545 43.6655C158.044 43.684 157.544 43.5955 157.079 43.4061C156.615 43.2166 156.196 42.9305 155.85 42.5667C155.505 42.2028 155.24 41.7697 155.075 41.2959C154.91 40.8221 154.847 40.3186 154.892 39.8188V39.6504H164.483V38.6953C164.483 34.5776 162.121 31.9966 158.363 31.9966C154.543 31.9966 152.078 34.7593 152.078 39.019C152.078 43.2656 154.53 45.9126 158.48 45.9126ZM161.694 37.7021H154.905C154.904 37.2482 154.992 36.7985 155.166 36.379C155.339 35.9596 155.594 35.5786 155.916 35.2582C156.237 34.9377 156.619 34.6841 157.039 34.512C157.459 34.3399 157.909 34.2527 158.363 34.2554C158.813 34.2457 159.26 34.3291 159.677 34.5005C160.093 34.6718 160.469 34.9273 160.782 35.2509C161.095 35.5746 161.337 35.9594 161.494 36.3812C161.651 36.803 161.719 37.2527 161.694 37.7021Z" fill="white"/>
<path d="M34.9797 13.0803C35.125 14.9767 34.5253 16.8549 33.308 18.3163C32.7251 19.0412 31.9853 19.6245 31.1443 20.0221C30.3033 20.4198 29.383 20.6214 28.4528 20.6118C28.3935 19.6999 28.5144 18.7852 28.8088 17.92C29.1032 17.0549 29.5652 16.2562 30.1684 15.5697C31.4021 14.1645 33.12 13.2757 34.9797 13.0803Z" fill="white"/>
<path d="M38.3717 26.8826C37.7536 27.9715 37.4211 29.1991 37.405 30.4511C37.4065 31.8594 37.8225 33.2361 38.6012 34.4095C39.3799 35.583 40.4867 36.5012 41.7838 37.0498C41.2738 38.7065 40.5051 40.2721 39.5062 41.6886C38.1646 43.6954 36.758 45.6561 34.5254 45.6923C33.4636 45.7168 32.7469 45.4115 32 45.0933C31.221 44.7615 30.4092 44.4157 29.1389 44.4157C27.7917 44.4157 26.9436 44.7726 26.1256 45.1169C25.4187 45.4144 24.7343 45.7024 23.7698 45.7424C21.6436 45.8211 20.0187 43.6005 18.6284 41.6124C15.8495 37.5525 13.6856 30.1709 16.5866 25.1483C17.2563 23.9449 18.2263 22.9354 19.4021 22.2183C20.5779 21.5013 21.9194 21.101 23.296 21.0565C24.5018 21.0317 25.659 21.4965 26.6734 21.904C27.4493 22.2156 28.1417 22.4937 28.7087 22.4937C29.2071 22.4937 29.8802 22.2266 30.6647 21.9152C31.9003 21.4248 33.4123 20.8247 34.9531 20.9865C36.1339 21.0234 37.2898 21.3352 38.329 21.897C39.3683 22.4588 40.2622 23.2553 40.9398 24.223C39.8732 24.8788 38.9897 25.7937 38.3717 26.8826Z" fill="white"/>
<path d="M56.9907 13.0965C57.5751 13.0546 58.1616 13.1429 58.7077 13.355C59.2539 13.5672 59.7462 13.8979 60.1491 14.3233C60.552 14.7488 60.8554 15.2583 61.0375 15.8152C61.2196 16.3721 61.2758 16.9625 61.2021 17.5438C61.2021 20.4032 59.6567 22.0468 56.9907 22.0468H53.7578V13.0965H56.9907ZM55.1479 20.781H56.8354C57.253 20.806 57.671 20.7375 58.0588 20.5806C58.4466 20.4237 58.7945 20.1822 59.0773 19.8739C59.36 19.5655 59.5704 19.198 59.6932 18.7981C59.816 18.3982 59.8481 17.9759 59.7871 17.562C59.8437 17.1498 59.8083 16.7301 59.6837 16.3331C59.559 15.9361 59.348 15.5716 59.0659 15.2657C58.7838 14.9598 58.4376 14.7201 58.052 14.5638C57.6663 14.4074 57.2509 14.3383 56.8354 14.3613H55.1479V20.781Z" fill="white"/>
<path d="M62.7724 18.6665C62.73 18.2226 62.7808 17.7748 62.9215 17.3517C63.0623 16.9286 63.2899 16.5396 63.5898 16.2096C63.8897 15.8796 64.2553 15.616 64.6631 15.4356C65.0709 15.2552 65.5118 15.162 65.9578 15.162C66.4037 15.162 66.8446 15.2552 67.2524 15.4356C67.6602 15.616 68.0258 15.8796 68.3257 16.2096C68.6256 16.5396 68.8532 16.9286 68.994 17.3517C69.1347 17.7748 69.1855 18.2226 69.1431 18.6665C69.1863 19.1109 69.1362 19.5593 68.9958 19.9831C68.8554 20.4069 68.6279 20.7966 68.3279 21.1273C68.0279 21.4579 67.6621 21.7221 67.2539 21.9029C66.8457 22.0837 66.4042 22.1771 65.9578 22.1771C65.5113 22.1771 65.0698 22.0837 64.6616 21.9029C64.2534 21.7221 63.8876 21.4579 63.5876 21.1273C63.2876 20.7966 63.0601 20.4069 62.9197 19.9831C62.7794 19.5593 62.7292 19.1109 62.7724 18.6665ZM67.7719 18.6665C67.7719 17.2024 67.1142 16.3462 65.9599 16.3462C64.8013 16.3462 64.1494 17.2024 64.1494 18.6665C64.1494 20.1424 64.8013 20.992 65.9599 20.992C67.1143 20.992 67.7719 20.1365 67.7719 18.6665Z" fill="white"/>
<path d="M77.6113 22.0466H76.2285L74.8325 17.072H74.7271L73.3369 22.0466H71.9673L70.1055 15.2922H71.4575L72.6675 20.4462H72.7671L74.1558 15.2922H75.4346L76.8232 20.4462H76.9287L78.1328 15.2922H79.4658L77.6113 22.0466Z" fill="white"/>
<path d="M81.0317 15.2922H82.3149V16.3652H82.4145C82.5835 15.9799 82.8685 15.6568 83.2298 15.4411C83.5911 15.2254 84.0108 15.1278 84.4302 15.1619C84.7588 15.1372 85.0888 15.1867 85.3957 15.3069C85.7026 15.427 85.9785 15.6147 86.2031 15.8559C86.4276 16.0972 86.595 16.3859 86.6928 16.7006C86.7906 17.0153 86.8163 17.348 86.7681 17.6741V22.0466H85.4351V18.0088C85.4351 16.9234 84.9634 16.3836 83.9775 16.3836C83.7544 16.3732 83.5316 16.4111 83.3246 16.4949C83.1175 16.5786 82.931 16.7062 82.7778 16.8687C82.6246 17.0313 82.5084 17.2251 82.4371 17.4368C82.3658 17.6485 82.3411 17.8731 82.3647 18.0952V22.0466H81.0317L81.0317 15.2922Z" fill="white"/>
<path d="M88.8921 12.6555H90.2251V22.0466H88.8921V12.6555Z" fill="white"/>
<path d="M92.0777 18.6666C92.0353 18.2227 92.0861 17.7748 92.2269 17.3517C92.3677 16.9286 92.5954 16.5395 92.8953 16.2095C93.1953 15.8796 93.5609 15.6159 93.9687 15.4355C94.3765 15.2551 94.8175 15.1619 95.2634 15.1619C95.7093 15.1619 96.1504 15.2551 96.5582 15.4355C96.966 15.6159 97.3316 15.8796 97.6315 16.2095C97.9314 16.5395 98.1591 16.9286 98.2999 17.3517C98.4407 17.7748 98.4915 18.2227 98.4491 18.6666C98.4923 19.1109 98.4421 19.5594 98.3016 19.9832C98.1612 20.407 97.9337 20.7967 97.6337 21.1273C97.3336 21.4579 96.9678 21.7221 96.5596 21.9029C96.1514 22.0837 95.7099 22.1771 95.2634 22.1771C94.817 22.1771 94.3755 22.0837 93.9672 21.9029C93.559 21.7221 93.1932 21.4579 92.8932 21.1273C92.5931 20.7967 92.3656 20.407 92.2252 19.9832C92.0847 19.5594 92.0345 19.1109 92.0777 18.6666ZM97.0772 18.6666C97.0772 17.2025 96.4195 16.3463 95.2652 16.3463C94.1065 16.3463 93.4547 17.2025 93.4547 18.6666C93.4547 20.1424 94.1066 20.992 95.2652 20.992C96.4195 20.992 97.0772 20.1365 97.0772 18.6666Z" fill="white"/>
<path d="M99.8528 20.1365C99.8528 18.9207 100.758 18.2198 102.365 18.1201L104.195 18.0147V17.4317C104.195 16.7183 103.723 16.3155 102.812 16.3155C102.068 16.3155 101.552 16.5887 101.404 17.0662H100.114C100.25 15.906 101.341 15.1619 102.873 15.1619C104.567 15.1619 105.522 16.0049 105.522 17.4317V22.0467H104.239V21.0974H104.133C103.919 21.4379 103.618 21.7155 103.262 21.9018C102.906 22.0881 102.506 22.1765 102.104 22.1579C101.821 22.1874 101.534 22.1572 101.263 22.0691C100.992 21.9811 100.742 21.8372 100.53 21.6467C100.318 21.4563 100.148 21.2235 100.031 20.9634C99.9147 20.7033 99.8539 20.4216 99.8528 20.1365ZM104.195 19.5593V18.9947L102.545 19.1001C101.615 19.1624 101.193 19.4788 101.193 20.0742C101.193 20.6821 101.72 21.0359 102.446 21.0359C102.658 21.0574 102.873 21.036 103.077 20.9728C103.281 20.9097 103.47 20.8062 103.633 20.6684C103.796 20.5306 103.93 20.3615 104.027 20.1709C104.123 19.9804 104.18 19.7724 104.195 19.5593Z" fill="white"/>
<path d="M107.274 18.6665C107.274 16.5323 108.371 15.1802 110.077 15.1802C110.5 15.1607 110.918 15.2619 111.285 15.4717C111.652 15.6815 111.951 15.9914 112.149 16.3652H112.248V12.6555H113.581V22.0466H112.304V20.9795H112.198C111.986 21.3507 111.676 21.6568 111.302 21.8645C110.928 22.0722 110.505 22.1737 110.077 22.1579C108.359 22.158 107.274 20.8059 107.274 18.6665ZM108.651 18.6665C108.651 20.0991 109.326 20.9612 110.455 20.9612C111.579 20.9612 112.273 20.0867 112.273 18.6724C112.273 17.2647 111.572 16.3777 110.455 16.3777C109.333 16.3777 108.651 17.2456 108.651 18.6665Z" fill="white"/>
<path d="M119.096 18.6665C119.054 18.2226 119.105 17.7748 119.246 17.3517C119.386 16.9286 119.614 16.5396 119.914 16.2096C120.214 15.8796 120.579 15.616 120.987 15.4356C121.395 15.2552 121.836 15.162 122.282 15.162C122.728 15.162 123.169 15.2552 123.576 15.4356C123.984 15.616 124.35 15.8796 124.65 16.2096C124.95 16.5396 125.177 16.9286 125.318 17.3517C125.459 17.7748 125.51 18.2226 125.467 18.6665C125.51 19.1109 125.46 19.5593 125.32 19.9831C125.179 20.4069 124.952 20.7966 124.652 21.1273C124.352 21.4579 123.986 21.7221 123.578 21.9029C123.17 22.0837 122.728 22.1771 122.282 22.1771C121.835 22.1771 121.394 22.0837 120.986 21.9029C120.577 21.7221 120.212 21.4579 119.912 21.1273C119.612 20.7966 119.384 20.4069 119.244 19.9831C119.103 19.5593 119.053 19.1109 119.096 18.6665ZM124.096 18.6665C124.096 17.2024 123.438 16.3462 122.284 16.3462C121.125 16.3462 120.473 17.2024 120.473 18.6665C120.473 20.1424 121.125 20.992 122.284 20.992C123.438 20.992 124.096 20.1365 124.096 18.6665Z" fill="white"/>
<path d="M127.256 15.2922H128.539V16.3652H128.638C128.807 15.9799 129.092 15.6568 129.454 15.4411C129.815 15.2254 130.235 15.1278 130.654 15.1619C130.983 15.1372 131.313 15.1867 131.62 15.3069C131.926 15.427 132.202 15.6147 132.427 15.8559C132.651 16.0972 132.819 16.3859 132.917 16.7006C133.014 17.0153 133.04 17.348 132.992 17.6741V22.0466H131.659V18.0088C131.659 16.9234 131.187 16.3836 130.201 16.3836C129.978 16.3732 129.756 16.4111 129.548 16.4949C129.341 16.5786 129.155 16.7062 129.002 16.8687C128.848 17.0313 128.732 17.2251 128.661 17.4368C128.59 17.6485 128.565 17.8731 128.589 18.0952V22.0466H127.256V15.2922Z" fill="white"/>
<path d="M140.524 13.6106V15.323H141.988V16.4458H140.524V19.919C140.524 20.6265 140.816 20.9363 141.479 20.9363C141.649 20.9358 141.819 20.9255 141.988 20.9055V22.0159C141.748 22.0587 141.506 22.0815 141.262 22.084C139.78 22.084 139.19 21.5625 139.19 20.2603V16.4458H138.117V15.323H139.19V13.6106H140.524Z" fill="white"/>
<path d="M143.808 12.6555H145.13V16.3777H145.235C145.412 15.9887 145.705 15.6638 146.074 15.4473C146.442 15.2308 146.869 15.1332 147.295 15.1677C147.622 15.1499 147.948 15.2045 148.252 15.3276C148.555 15.4507 148.828 15.6392 149.05 15.8797C149.272 16.1202 149.438 16.4068 149.537 16.719C149.636 17.0312 149.664 17.3613 149.621 17.6858V22.0466H148.286V18.0146C148.286 16.9358 147.784 16.3894 146.842 16.3894C146.613 16.3706 146.383 16.4021 146.167 16.4817C145.951 16.5613 145.756 16.687 145.594 16.8501C145.432 17.0132 145.307 17.2097 145.229 17.426C145.151 17.6422 145.121 17.8728 145.141 18.1018V22.0466H143.808L143.808 12.6555Z" fill="white"/>
<path d="M157.393 20.2229C157.212 20.8402 156.82 21.3742 156.285 21.7314C155.75 22.0886 155.106 22.2464 154.467 22.177C154.022 22.1888 153.579 22.1035 153.171 21.9272C152.762 21.7509 152.396 21.4878 152.099 21.1561C151.803 20.8244 151.581 20.4321 151.451 20.0063C151.321 19.5806 151.286 19.1317 151.346 18.6907C151.287 18.2484 151.324 17.7985 151.453 17.3715C151.583 16.9444 151.803 16.5503 152.098 16.2157C152.394 15.8811 152.757 15.6138 153.165 15.432C153.573 15.2502 154.014 15.1581 154.461 15.1619C156.34 15.1619 157.474 16.4459 157.474 18.5669V19.032H152.704V19.1067C152.683 19.3546 152.715 19.6041 152.796 19.8392C152.877 20.0743 153.006 20.2899 153.176 20.4721C153.345 20.6543 153.551 20.799 153.779 20.8971C154.008 20.9952 154.254 21.0445 154.503 21.0417C154.822 21.08 155.145 21.0226 155.431 20.8768C155.717 20.731 155.954 20.5033 156.11 20.2229L157.393 20.2229ZM152.704 18.0462H156.116C156.133 17.8195 156.102 17.5918 156.025 17.3777C155.949 17.1636 155.829 16.9679 155.672 16.8031C155.516 16.6383 155.326 16.5081 155.116 16.4208C154.906 16.3336 154.681 16.2912 154.453 16.2964C154.223 16.2935 153.994 16.3368 153.78 16.4237C153.567 16.5106 153.373 16.6394 153.21 16.8025C153.047 16.9656 152.918 17.1596 152.831 17.3732C152.745 17.5868 152.701 17.8156 152.704 18.0462Z" fill="white"/>
</svg>
