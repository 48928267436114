import { sortBy } from 'lodash'

import { NavigationNode, ContentNode, EmiRequest } from 'models'
import config from 'services/emi/config'
import Constants from 'api-constants'
import nodeIds from 'services/emi/config/nodeIds'

import { IMAGE_TYPE, getImage } from './helpers'

const buildContentNode = (node, data, image, requestMap) => {
    return new ContentNode({
        ...data,
        highlight: {
            type: 'info',
            numOfBarkers: 1,
            ratio: config.barkerRatio.info.landscape,
            ratioPortrait: config.barkerRatio.info.portrait,
            data: [],
            infoBarker: image,
        },
        body: {
            type: 'list',
            data: node.children.map((x) => {
                const requestId = Constants.requestIds.VOD_NODE + x.id
                requestMap[requestId] = new EmiRequest({
                    value: Constants.customRequests.GET_VOD_NODE_CONTENT,
                    args: [x.id],
                    id: requestId,
                    labels: { default: x.title },
                })
                return { id: requestId }
            }),
        },
    })
}
export const flattenTree = (nodes) => {
    const flatten = (node, parent, nodesMap, requestMap) => {
        const data = {
            parent: parent || node.parent || undefined,
            id: node.id,
            labels: { default: node.title },
            intlKey: node.intlKey,
        }

        if (node.children) {
            node.children = sortBy(node.children, ['position'])

            if (node.children[0].children) {
                nodesMap[node.id] = new NavigationNode({
                    ...data,
                    children: node.children.map((x) => x.id),
                })
                node.children.forEach((c) => flatten(c, node.id, nodesMap, requestMap))
            } else {
                const image =
                    node.pictures && node.pictures.barker ? node.pictures.barker[0] : undefined
                nodesMap[node.id] = buildContentNode(node, data, image, requestMap)
            }
        }
    }

    const nodesMap = {}
    const requestMap = {}

    flatten(nodes, null, nodesMap, requestMap)

    return { navMap: nodesMap, requestMap }
}

export const buildNodesMap = (nodes) => {
    const nodesMap = {}
    const requestMap = {}

    const nodesById = nodes.reduce((acc, node) => {
        acc[node.id] = node
        return acc
    }, {})

    const rootNodeId = nodes[0].id
    nodes.forEach((node, index) => {
        const data = {
            parent: node.parent_id === rootNodeId ? 'root-vod' : node.parent_id,
            id: node.id,
            labels: { default: node.title },
            intlKey: node.intlKey,
        }

        if (node.children) {
            if (nodesById[node.children[0].id] && nodesById[node.children[0].id].children) {
                node.children = sortBy(node.children, ['position'])
                const nodeId = index === 0 ? nodeIds.vod : node.id
                const nodesMetadata =
                    index === 0
                        ? {
                              id: nodeIds.vod,
                              intlKey: { tag: nodeIds.vod },
                              labels: { default: undefined },
                              parent: nodeIds.root,
                          }
                        : data
                nodesMap[nodeId] = new NavigationNode({
                    ...nodesMetadata,
                    children: node.children.map((x) => x.id),
                })
            } else {
                node.children = sortBy(node.children, ['position'])
                const image = getImage(node.pictures, IMAGE_TYPE.BARKER)
                nodesMap[node.id] = nodesMap[node.id] = buildContentNode(
                    node,
                    data,
                    image,
                    requestMap
                )
            }
        }
    })

    return { navMap: nodesMap, requestMap }
}
