import Constants from 'api-constants'
import config from 'services/proxy/config'
const { eligibility } = config

/**
 * Returns eligibility from Proxy bit fields
 * @param {Number} value (bit fields)
 * @return {Array<Constants.eligibility>}
 */
export const eligibilityFactory = (value) => {
    let res = []
    if (value & eligibility.OTT) res.push(Constants.eligibility.OTT)
    if (value & eligibility.IPTV) res.push(Constants.eligibility.IPTV)
    if (value & eligibility.IPTV_HD) res.push(Constants.eligibility.IPTV_HD)
    if (value & eligibility.IPTV_SD) res.push(Constants.eligibility.IPTV_SD)

    return res
}
