import { Profile, DvrQuota } from 'models'
import HuaweiTypes from '../HuaweiTypes'

export const dvrQuotaFactory = (quota = {}) => {
    let totalSize = quota.totalSize ? HuaweiTypes.parseInt(quota.totalSize) : 0
    let usedSize = quota.usedSize ? HuaweiTypes.parseInt(quota.usedSize) : 0
    const spaceMode = quota.spaceMode
        ? HuaweiTypes.parseInt(quota.spaceMode)
        : HuaweiTypes.spaceMode.MB

    let unit = Profile.DVR_QUOTA_UNIT.MB
    switch (spaceMode) {
        case HuaweiTypes.spaceMode.MB:
            unit = Profile.DVR_QUOTA_UNIT.MB
            break
        case HuaweiTypes.spaceMode.DURATION:
        default: // Convert second to hours
            unit = Profile.DVR_QUOTA_UNIT.HOUR
            usedSize = usedSize / 3600
            break
    }

    return new DvrQuota({
        dvrQuota: totalSize,
        dvrQuotaRemaining: totalSize > usedSize ? totalSize - usedSize : 0,
        unit,
    })
}
