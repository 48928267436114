import {For} from 'solid-js'
import {clearOauthData, loginTvService, logout} from '@ifs/volt-store/lib/actions'
import {useIcon} from '../icon/useIcon'
import {useDispatch} from '../../store'
import {getDeviceData} from '../../utils'

interface SubscriptionSelctorProps {
    services: any
}
export const SubscriptionSelector = (props: SubscriptionSelctorProps) => {
    const icons = useIcon()
    const dispatch = useDispatch()

    const CloseIcon = icons.get('Close')
    const BackgroundImage = icons.get('BackgroundImage')

    const onServiceSelection = (service: any) => {
        dispatch(loginTvService({serviceId: service.serviceId, deviceData: getDeviceData()}))
    }

    const onCancel = () => {
        window.location.href = '/'
        // localStorage.clear() - Do not clear all the storage, this is not a good way to logout as there are some key that should remain
        dispatch(logout())
        dispatch(clearOauthData())
    }

    return (
        <>
            <div class="selectorWrapper">
                <BackgroundImage class="selectorBackground" />
                <div class="selectorModalWrapper">
                    <div class="selectorHeader">
                        <div class="selectTv">Select your TV id</div>
                        <CloseIcon onClick={onCancel} class="selectorCrossIcon" />
                    </div>
                    <div class="selectorSubTitle">
                        You have multiple TV subscriptions, select a subscription to proceed
                    </div>
                    <For each={props?.services}>
                        {service => (
                            <div
                                onClick={() => {
                                    onServiceSelection(service)
                                }}
                                class="selectorTVID"
                            >
                                {service?.serviceId}
                            </div>
                        )}
                    </For>
                </div>
            </div>
        </>
    )
}
