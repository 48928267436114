import {
    AuthorizationError,
    AuthorizationNotifier,
    AuthorizationRequest,
    AuthorizationResponse,
    AuthorizationServiceConfiguration,
    BaseTokenRequestHandler,
    BasicQueryStringUtils,
    FetchRequestor,
    GRANT_TYPE_AUTHORIZATION_CODE,
    GRANT_TYPE_REFRESH_TOKEN,
    RedirectRequestHandler,
    StringMap,
    TokenRequest,
    TokenResponse,
} from '@openid/appauth'
import {LocationLike} from '@openid/appauth/src/types'

// We are overwriting QueryStringUtils util because
// internally it always checks for "hash" instead of "search" from the location
export class NoHashQueryStringUtils extends BasicQueryStringUtils {
    parse(input: LocationLike) {
        return super.parse(input, false)
    }
}
export interface AuthorizerOptions {
    allowGuest: boolean
    clientId: string
    clientSecret: string
    redirectUri: string
    scope: string
    grant_type: string
    authorityConfiguration: {
        authorization_endpoint: string
        token_endpoint: string
        revocation_endpoint: string
        registration_endpoint: string
    }
    authorizationType: AuthorizationType
    responseType?: string
    state?: string
    extras?: StringMap
    internal?: StringMap
}

export enum AuthorizationType {
    REDIRECT = 'redirect',
    TOKEN = 'token',
}

const REDIRECT_URL_STORAGE_NAME = 'volt_oidc_dummy_redirect'
export class AuthorizationHandler {
    private readonly notifier: AuthorizationNotifier
    private authorizationHandler: RedirectRequestHandler
    private tokenHandler: BaseTokenRequestHandler

    // state
    private configuration?: AuthorizationServiceConfiguration
    private code?: string
    private readonly authorizationType?: AuthorizationType
    private tokenResponse?: TokenResponse
    private request?: AuthorizationRequest
    private response?: AuthorizationResponse
    private options: AuthorizerOptions
    public onSuccessTokenResponse: (tokenResponse: TokenResponse | void) => any
    public onFailureTokenResponse: (error: AuthorizationError | null) => any

    constructor(options: AuthorizerOptions) {
        if (options === undefined) throw 'Missing Mandatory Options'
        this.authorizationType = options.authorizationType
        this.options = options

        this.tokenHandler = new BaseTokenRequestHandler(new FetchRequestor())
        this.notifier = new AuthorizationNotifier()
        this.authorizationHandler = new RedirectRequestHandler(undefined, new NoHashQueryStringUtils())
        // set notifier to deliver responses
        this.authorizationHandler.setAuthorizationNotifier(this.notifier)

        // set a listener to listen for authorization responses
        this.notifier.setAuthorizationListener((request, response, error) => {
            this.listenerCallback(request, response, error)
                ?.then((tokenResponse: TokenResponse | void) => {
                    return this.onSuccessTokenResponse(tokenResponse)
                })
                .catch(e => {
                    return this.onFailureTokenResponse(e)
                })
        })

        //Initialize Configuration from constructor if we have the known authority configuration
        this.configuration = new AuthorizationServiceConfiguration(options.authorityConfiguration)
        this.onSuccessTokenResponse = () => Error('onSuccessTokenResponse Not Implemented')
        this.onFailureTokenResponse = () => Error('onFailureTokenResponse Not Implemented')
    }

    listenerCallback(
        request: AuthorizationRequest,
        response: AuthorizationResponse | null,
        error: AuthorizationError | null
    ) {
        if (response) {
            this.request = request
            this.response = response

            return this.tokenSignIn()
        } else {
            return Promise.reject(error)
        }
    }

    async fetchConfiguration(openIdConnectUrl: string): Promise<AuthorizationServiceConfiguration> {
        // get the well known configuration from the provider on demand
        return AuthorizationServiceConfiguration.fetchFromIssuer(openIdConnectUrl)
            .then(response => {
                this.configuration = response
                return response
            })
            .catch(error => {
                return error
            })
    }

    signIn() {
        switch (this.authorizationType) {
            case AuthorizationType.REDIRECT:
                return this.redirectSignIn()
            case AuthorizationType.TOKEN:
                return this.tokenSignIn()
            default:
                return this.redirectSignIn()
        }
    }

    public setCbRedirectUrl() {
        sessionStorage.setItem(REDIRECT_URL_STORAGE_NAME, window.location.pathname)
    }

    public getCbRedirectUrl() {
        return sessionStorage.getItem(REDIRECT_URL_STORAGE_NAME)
    }

    private redirectSignIn() {
        this.setCbRedirectUrl()
        let request = new AuthorizationRequest({
            client_id: this.options.clientId,
            redirect_uri: this.options.redirectUri,
            scope: this.options.scope,
            response_type: AuthorizationRequest.RESPONSE_TYPE_CODE,
            state: undefined,
            extras: {prompt: 'login', access_type: 'offline'},
        })

        if (this.configuration) {
            this.authorizationHandler.performAuthorizationRequest(this.configuration, request)
        }
    }

    private async tokenSignIn() {
        let request: TokenRequest | null = null
        let extras: StringMap = {}

        extras.code_verifier = this.request?.internal?.['code_verifier'] || ''
        extras.client_secret = this.options.clientSecret || ''

        if (this.response?.code) {
            this.code = this.response.code
            if (this.request && this.request.internal) {
            }
            // use the code to make the token request.
            request = new TokenRequest({
                client_id: this.options.clientId,
                redirect_uri: this.options.redirectUri,
                grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
                code: this.code,
                refresh_token: undefined,
                extras: extras,
            })
        } else if (this.tokenResponse) {
            // use the token response to make a request for an access token
            request = new TokenRequest({
                client_id: this.options.clientId,
                redirect_uri: this.options.redirectUri,
                grant_type: GRANT_TYPE_REFRESH_TOKEN,
                code: undefined,
                refresh_token: this.tokenResponse.refreshToken,
                extras: extras,
            })
        }

        if (request && this.configuration) {
            return this.tokenHandler
                .performTokenRequest(this.configuration, request)
                .then(response => {
                    if (this.tokenResponse) {
                        // copy over new fields
                        this.tokenResponse.accessToken = response.accessToken
                        this.tokenResponse.issuedAt = response.issuedAt
                        this.tokenResponse.expiresIn = response.expiresIn
                        this.tokenResponse.tokenType = response.tokenType
                        this.tokenResponse.scope = response.scope
                    } else {
                        this.tokenResponse = response
                    }
                    // unset code, so we can do refresh token exchanges subsequently
                    this.code = undefined
                    return response
                })
                .catch(e => {
                    return Promise.reject('Failed to perform token request: ' + e)
                })
        } else {
            return Promise.reject('Request or Configuration missing')
        }
    }

    checkForAuthorizationResponse() {
        this.authorizationHandler.completeAuthorizationRequestIfPossible()
    }

    refreshToken() {}

    signOut() {}
}
