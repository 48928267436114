import Fetch from '../fetch'
import Constants from 'api-constants'
import { of, forkJoin, throwError } from 'rxjs'
import { mergeMap, switchMap } from 'rxjs/operators'
import VoltError from 'VoltError'
import {
    updateProductsEntitlements,
    productsFactory,
    huaweiProductsFactory,
    huaweiSubscribedProductsFactory,
    hasEntitledProducts,
} from '../Factories'
import ConfigHelper from 'framework/helpers/config'
import DataHelper from 'framework/helpers/data'
import HuaweiTypes from '../HuaweiTypes'

/**
 * Deal with Purchase (data retrieval, purchase and subscription actions)
 */
export default class PurchaseApi extends Fetch {
    constructor(config, otherApis = {}) {
        super(config, otherApis)
        this.metaApi = otherApis.metaApi
    }

    /**
     * Retrieves TVOD products
     * @param {Object} args
     * @param {Number} args.limit The maximum number of products retrieved per request
     * @param {Number} args.page The page number **Important:** 0-based value, first page is `0`
     *
     * @returns {Observable<TVODProductsList>}
     */
    getPurchasedTVODProducts({ limit, page }) {
        return this._getUserProductEntitlements(Constants.productTypes.TRANSACTIONAL).pipe(
            mergeMap((userEntitlements) => {
                const entitledProductIds = (userEntitlements || []).map(
                    (entitlement) => entitlement.productID
                )
                if (!entitledProductIds.length) {
                    return of([])
                }
                return this._getProducts(
                    Constants.productTypes.TRANSACTIONAL,
                    entitledProductIds
                ).pipe(
                    mergeMap((huaweiTVODProducts) => {
                        return of({
                            isComplete: true,
                            products: updateProductsEntitlements(
                                huaweiTVODProducts,
                                userEntitlements
                            ),
                        })
                    })
                )
            })
        )
    }

    /**
     * Retrieves all purchased TVOD products
     *
     * @param {Number} [pageSize=15] The page size of each api request
     *
     * @returns {Observable<TVODProductsList>}
     */
    getAllPurchasedTVODProducts(limit = 15) {
        return this.getPurchasedTVODProducts({ limit })
    }

    /**
     * Retrieves all Subscription products
     *
     * @param {Number} [pageSize=15] The page size of each api request
     *
     * @returns {Observable<SubscriptionsList>}
     */
    getSubscriptions(pageSize = 50) {
        return forkJoin([
            // GET Subscriptions
            this._getProducts(Constants.productTypes.SUBSCRIPTION),
            // THEN user entitlements
            this._getUserProductEntitlements(Constants.productTypes.SUBSCRIPTION),
        ]).pipe(
            mergeMap(([products, userEntitlements]) =>
                of({
                    products: updateProductsEntitlements(products, userEntitlements),
                    isComplete: true,
                })
            )
        )
    }

    /**
     * Retrieves Subscriptions
     * @returns {Observable<SubscriptionsList>}
     */
    getSubscriptionsData(productIds) {
        const { useProxyProducts = false } = ConfigHelper.getInstance().getConfig('huawei')
        return this._getProducts(Constants.productTypes.SUBSCRIPTION, productIds).pipe(
            switchMap((products) => {
                const hasEntitledProduct = hasEntitledProducts(products)
                if (!hasEntitledProduct && !useProxyProducts) return of(products)

                return this._getUserProductEntitlements(Constants.productTypes.SUBSCRIPTION).pipe(
                    switchMap((userEntitlements) =>
                        of(updateProductsEntitlements(products, userEntitlements))
                    )
                )
            })
        )
    }

    /**
     * Retrieves all Subscription products (Using Nagra Products)
     *
     * @param {Number} [limit=50] The page size of each api request
     * @returns {Observable<SubscriptionsList>}
     */
    _getProducts(productType = Constants.productTypes.SUBSCRIPTION, productIds, limit = 50) {
        let myProductsIds = (
            productIds && Array.isArray(productIds) ? productIds : [productIds]
        ).filter(Boolean)
        const { useProxyProducts = false } = ConfigHelper.getInstance().getConfig('huawei')
        if (useProxyProducts) {
            return this._getProductsFromProxy(productType, myProductsIds, limit)
        }

        return this._getHuaweiProducts(productType, myProductsIds, limit).pipe(
            mergeMap((products = []) => {
                return of(products.map((product) => productsFactory(product)))
            })
        )
    }

    /**
     * Retrieves Products entitlements
     * @param {Number} count Page size
     * @returns {Observable<Array<HuaweiSubscribedProduct>>}
     */
    _getUserProductEntitlements(productType = Constants.productTypes.SUBSCRIPTION, count = 50) {
        let huaweiProductType
        switch (productType) {
            case Constants.productTypes.SUBSCRIPTION:
                huaweiProductType = HuaweiTypes.productDurationType.DURATION_BASED
                break
            case Constants.productTypes.TRANSACTIONAL:
                huaweiProductType = HuaweiTypes.productDurationType.TIME_BASED
                break
            case Constants.productTypes.ALL:
            default:
                break
        }

        let params = {}
        if (huaweiProductType) {
            params = {
                productType: huaweiProductType,
                /**
                 * This field is really important as if the profile is not specificied, the entitlemend of sub profile will be returned.
                 * Example (Kids profile). Meaning that product will be seen as not entitled although they are.
                 * Based on Huawei documentation, if we request to get the entitlement of SUPER PROFLE (ADMIN), then all entitlement are retrieved.
                 *
                 * Note: If the value is -1, all the products subscribed to by the subscriber are obtained.
                 */
                profileID: '-1',
            }
        }

        const offset = 0
        return this.recursiveFetch(
            (_, count, offset) => {
                return this.fetch({
                    url: `${DataHelper.getInstance().getData(
                        DataHelper.STORE_KEY.BACKEND_API_URL
                    )}/VSP/V3/QuerySubscription`,
                    method: 'POST',
                    body: {
                        ...params,
                        count,
                        offset,
                    },
                    log: `GET SUBSCRIBED ${productType} PRODUCTS`,
                })
            },
            { count, offset, responseResource: 'products' }
        ).pipe(
            mergeMap((entitlement) => {
                const result = (entitlement || []).map((x) => huaweiSubscribedProductsFactory(x))
                return of(result)
            })
        )
    }

    /**
     * Retrieves Contents entitlements
     * @param {Number} count Page size
     * @returns {Observable<Array<String>>}
     */
    _getUserTVODProductEntitlements(count = 50) {
        const offset = 0
        return this.recursiveFetch(
            (_, count, offset) => {
                return this.fetch({
                    url: `${DataHelper.getInstance().getData(
                        DataHelper.STORE_KEY.BACKEND_API_URL
                    )}/VSP/V3/QueryMyContent`,
                    method: 'POST',
                    body: {
                        contentType: [
                            HuaweiTypes.contentTypes.VOD,
                            HuaweiTypes.contentTypes.VIDEO_VOD,
                            HuaweiTypes.contentTypes.AUDIO_VOD,
                        ].join(','),
                        count,
                        offset,
                    },
                    log: `QUERY MY TVOD PURCHASE`,
                })
            },
            { count, offset, responseResource: 'contents' }
        ).pipe(
            mergeMap((contentEntitlements) => {
                const result = (contentEntitlements || []).map(
                    (x) => x && x.content && x.content.ID
                )
                return of(result)
            })
        )
    }

    /**
     * Retrieves Products whether from the PROXY or from Nagra
     * @param {'subscription'|'transactional'} productType
     * @param {Number} limit Page size
     * @param {Array<String>|<String>} productIds
     * @returns {Observable<Array<Product>>}
     */
    _getProductsFromProxy(
        productType = Constants.productTypes.SUBSCRIPTION,
        productIds,
        limit = 50
    ) {
        return productIds
            ? this.metaApi.getProductsById(productType, {
                  ids: productIds,
              })
            : this.metaApi.getAllProducts(productType, {
                  limit,
              })
    }

    /**
     * Retrieves Products whether from the PROXY or from Nagra
     * @param {'subscription'|'transactional'} productType
     * @param {Number} limit Page size
     * @param {Array<String>|<String>} productIds
     * @returns {Observable<Array<HuaweiProduct>>}
     */
    _getHuaweiProducts(productType = Constants.productTypes.SUBSCRIPTION, productIds, count = 50) {
        let huaweiProductType
        switch (productType) {
            case Constants.productTypes.SUBSCRIPTION:
                huaweiProductType = HuaweiTypes.productType.ORDER_BY_CYCLE
                break
            case Constants.productTypes.TRANSACTIONAL:
                huaweiProductType = HuaweiTypes.productType.PPV
                break
            case Constants.productTypes.ALL:
            default:
                huaweiProductType = HuaweiTypes.productType.ALL
                break
        }
        let params
        if (productIds && productIds.length > 0) {
            params = {
                productType: huaweiProductType,
                queryType: 'BY_IDLIST',
                productIds: productIds,
            }
        } else {
            params = {
                productType: huaweiProductType,
                // Synchronize also hidden package when fetching all the products to manage the entitlements
                // and from the package will not be displayed through a dedicated displayable prop isOnlinePurchase
                isEnableOnlinePurchase: 'YES',
                queryType: 'ALL',
            }
        }

        const offset = 0
        return this.recursiveFetch(
            (_, count, offset) => {
                return this.fetch({
                    url: `${DataHelper.getInstance().getData(
                        DataHelper.STORE_KEY.BACKEND_API_URL
                    )}/VSP/V3/QueryProduct`,
                    method: 'POST',
                    body: {
                        count,
                        offset,
                        ...params,
                    },
                    log: `[QUERY PRODUCTS][${huaweiProductType}]`,
                })
            },
            { count, offset, responseResource: 'productList' }
        ).pipe(
            mergeMap((huaweiProducts) => {
                const result = (huaweiProducts || []).map((x) => huaweiProductsFactory(x))
                return of(result)
            })
        )
    }

    /**
     * Retrieves Huawei Product from content
     * @param {String} content id
     * @param {'subscription'|'transactional'|undefined} [productType]
     * @returns {Observable<Array<HuaweiProduct>>}
     */
    _getHuaweiProductsFromContent(contentId, productType) {
        let huaweiProductType
        switch (productType) {
            case Constants.productTypes.SUBSCRIPTION:
                huaweiProductType = HuaweiTypes.productType.ORDER_BY_CYCLE
                break
            case Constants.productTypes.TRANSACTIONAL:
                huaweiProductType = HuaweiTypes.productType.PPV
                break
            case Constants.productTypes.ALL:
            default:
                huaweiProductType = HuaweiTypes.productType.ALL
                break
        }

        return this.fetch({
            url: `${DataHelper.getInstance().getData(
                DataHelper.STORE_KEY.BACKEND_API_URL
            )}/VSP/V3/QueryProductByContent`,
            method: 'POST',
            body: {
                ID: contentId,
                productType: huaweiProductType,
                /**
                 * 1: reserved
                 * 2: content
                 * 3: category
                 */
                IDType: '2',
            },
            log: `[QUERY PRODUCTS FROM CONTENT ${contentId}][${huaweiProductType}]`,
        }).pipe(
            mergeMap(({ response = {} } = {}) => {
                const { productList } = response
                return of((productList || []).map((x) => huaweiProductsFactory(x)))
            }),
            mergeMap((products) => {
                return of(products.map((product) => productsFactory(product)))
            })
        )
    }

    /**
     * Retrieves Huawei Product from content
     * @param {String} content id
     * @param {'subscription'|'transactional'|undefined} [productType]
     * @returns {Observable<Array<HuaweiProduct>>}
     */
    _getTVODProductFromTitle(contentId, productType = Constants.productTypes.TRANSACTIONAL) {
        return this._getHuaweiProductsFromContent(contentId, productType).pipe(
            switchMap((products = []) => {
                const hasEntitledProduct = hasEntitledProducts(products)
                if (!hasEntitledProduct) return of(products)

                return this._getUserProductEntitlements(productType).pipe(
                    switchMap((userEntitlements) =>
                        of(updateProductsEntitlements(products, userEntitlements))
                    )
                )
            })
        )
    }

    /**
     * From the titleId, retrieve property of related Content (ex: isHD)
     * and the associated product identifiers.
     *
     * @param {Object} args
     * @param {String} args.id Title identifier
     *
     * @returns {Observable<Array<TVODProduct|Subscription>>} A list of {@link TVODProduct} or {@link Subscription} products
     */
    getProductsFromTitle({ subscriptionIds = [], isTvod, id: contentId, streams }) {
        return isTvod
            ? this._getTVODProductFromTitle(contentId).pipe(
                  mergeMap((result = []) =>
                      of(
                          (result || [])
                              .map(
                                  (product) =>
                                      product &&
                                      product.update({
                                          contents: (streams || []).map((stream) => {
                                              return { id: stream.uri || stream.playback_id }
                                          }),
                                      })
                              )
                              .filter(Boolean)
                      )
                  )
              )
            : this.getSubscriptionsData(subscriptionIds).pipe(
                  mergeMap((result = []) => {
                      const updatedResult = (result || [])
                          .map((product) => {
                              const contents = (streams || []).reduce((acc, stream) => {
                                  if (stream && stream.uri) {
                                      acc[stream.uri] = {
                                          id: stream.uri,
                                          isHD:
                                              product.titles &&
                                              product.titles[contentId] &&
                                              product.titles[contentId].isHD,
                                      }
                                  }
                                  return acc
                              }, {})

                              return (
                                  product &&
                                  product.update({
                                      titles: {
                                          [contentId]: {
                                              id: contentId,
                                              contents: {
                                                  ...contents,
                                              },
                                          },
                                      },
                                  })
                              )
                          })
                          .filter(Boolean)

                      return of(updatedResult)
                  })
              )
    }

    getTVODProductsData(productIds) {
        return forkJoin([
            this._getProducts(Constants.productTypes.TRANSACTIONAL, productIds),
            this._getUserProductEntitlements(Constants.productTypes.TRANSACTIONAL),
        ]).pipe(
            mergeMap(([products, entitlements]) => {
                return of(updateProductsEntitlements(products, entitlements))
            })
        )
    }

    /**
     * Request to purchase a TVOD product
     * @param {String} productId
     * @param {String} offerId
     * @param {Object} purchaseData
     *
     * @returns {Observable}
     */
    purchaseTVOD(productId, offerId, purchaseData) {
        // Force default pin to '1111' is not specified
        const { contentId } = purchaseData || {}
        if (!productId || !contentId) {
            return throwError(
                new VoltError(VoltError.codes.PURCHASE_FAILED_DUE_TO_MISSING_ASSET, {
                    extraLog: 'Missing product or program Id, cannot purchase',
                })
            )
        }
        return this.fetch({
            url: `${DataHelper.getInstance().getData(
                DataHelper.STORE_KEY.BACKEND_API_URL
            )}/VSP/V3/SubscribeProduct`,
            method: 'POST',
            body: {
                subscribe: {
                    productID: productId,
                    payment: { servicePayType: HuaweiTypes.servicePayType.CASH },
                    isAutoExtend: '0',
                    priceObjects: [
                        {
                            ID: contentId,
                            type: HuaweiTypes.priceType.CONTENT,
                            contentType: HuaweiTypes.contentTypes.VOD,
                        },
                    ],
                },
                type: HuaweiTypes.subscriptionVerificationType.NO_VERIFICATION,
            },
            log: `RENT TVOD - Product ${productId} - Content ${contentId}`,
        })
    }

    /**
     * Request to purchase a subscription product
     * @param {String} productId
     * @param {String} offerId
     *
     * @returns {Observable}
     */
    subscribe(productId) {
        if (!productId) {
            return throwError(
                new VoltError(VoltError.codes.PURCHASE_FAILED_DUE_TO_MISSING_ASSET, {
                    extraLog: 'Missing product Id, cannot purchase',
                })
            )
        }
        return this.fetch({
            url: `${DataHelper.getInstance().getData(
                DataHelper.STORE_KEY.BACKEND_API_URL
            )}/VSP/V3/SubscribeProduct`,
            method: 'POST',
            body: {
                subscribe: {
                    productID: productId,
                    payment: { servicePayType: HuaweiTypes.servicePayType.CASH },
                    isAutoExtend: '1',
                },
                type: HuaweiTypes.subscriptionVerificationType.NO_VERIFICATION,
            },
            log: `SUBSCRIBE PACK - Product ${productId}`,
        })
    }

    // --------------------- FEATURE NOT SUPPORTED ---------------------
    /**
     * Request to unsubscribe a subscription product
     * @param {String} productId
     * @param {String} offerId
     * @param {String|Number} transactionId
     *
     * @returns {Observable}
     */
    unsubscribe(productId, offerId, transactionId) {
        return throwError(
            new VoltError(VoltError.codes.FEATURE_NOT_AVAILABLE, {
                extraLog: '[unsubscribe] Feature not supported',
            })
        )
    }

    /**
     * This method needs to be called after the purchase of a subscription.
     * Some external OTT Package could require an activation to be available (like Netflix)
     * This API allows to regenerate Activation CODE or trigger a mail to send to the user
     * to follow activation procedure (Email, Redeem Code/Deeplink to launch in or application)
     * @param {String} productId
     * @param {String|Number} transactionId
     *
     * @returns {Observable}
     */
    activateSubscription({ productId, activationId }) {
        return throwError(
            new VoltError(VoltError.codes.FEATURE_NOT_AVAILABLE, {
                extraLog: '[activateSubscription] Feature not supported',
            })
        )
    }

    /**
     * Request to purchase a subscription product
     * @param {String} productId
     * @param {String} offerId
     * @param {Object} purchaseData
     * @param {Object} [purchaseData.profile] User Profile (not used)
     * @param {Object} [purchaseData.paymentMethod] Payment method to use for Purchase (e.g Pay on bill, Credit Card, etc..)
     * @param {Object} [purchaseData.billingPlan] Billing Plan to use for Purchase (Buy for 1 month, 6 month, 1 year and pay every Month or for a Year, etc..)
     * @param {String} [purchaseData.iapReceipt] (Optionally) iapReceipt is used to validate app store purchase. MarketONE has decide to reuse the purchase API to simplify the design
     * @param {String} [purchaseData.iapAppstore] (Optionally) iapAppStore is used to indicate which store is used (GOOGLE, APPLE, AMAZON)
     *
     * @returns {Observable}
     */
    validateAppStorePurchase(productId, offerId, purchaseData, iapReceipt, iapAppstore) {
        return throwError(
            new VoltError(VoltError.codes.FEATURE_NOT_AVAILABLE, {
                extraLog: '[activateSubscription] Feature not supported',
            })
        )
    }

    /**
     * Redeem a voucher code
     *
     * @param {Object} options
     * @param {String} options.code value of voucherCode
     * @returns {Observable<Boolean>} returns boolean
     */
    redeemVoucherCode = ({ code }) => {
        return of(false)
    }

    /**
     * Add payment method for an account
     *
     * @param {Object} options
     * @param {string} options.paymentMethodId the payment method id got from a credit card iframe
     * @returns Observable
     */
    addPaymentMethod = ({ paymentMethodId }) =>
        throwError(new VoltError(VoltError.codes.FEATURE_NOT_AVAILABLE))

    /**
     * Delete payment method for an account
     *
     * @param {Object} options
     * @param {string} options.paymentMethodId the payment method id got from a credit card iframe
     * @returns Observable
     */
    deletePaymentMethod = ({ paymentMethodId }) =>
        throwError(new VoltError(VoltError.codes.FEATURE_NOT_AVAILABLE))

    sendPurchaseOTP() {
        return throwError(new VoltError(VoltError.codes.FEATURE_NOT_AVAILABLE))
    }
    validatePurchaseOTP() {
        return throwError(new VoltError(VoltError.codes.FEATURE_NOT_AVAILABLE))
    }
    getUserWallet() {
        return throwError(new VoltError(VoltError.codes.FEATURE_NOT_AVAILABLE))
    }
    /// ------------------------------------------------------------------------
    /// -----------------------------End of STUB -------------------------------
    /// ------------------------------------------------------------------------
}
