import { getBrowserName } from './platform'

const isSafari = (voltApi: any) => {
    return getBrowserName(voltApi) === voltApi.Constants.platform.safari
}

export const getManifestType = (voltApi: any): string => {
    return isSafari(voltApi) ? 'application/x-mpegURL' : 'application/dash+xml'
}

export const getProfileName = (voltApi: any): string => {
    return isSafari(voltApi) ? 'com.apple.fairplay' : 'com.widevine.alpha'
}

export const getDRMSystem = (voltApi: any): string => {
    return isSafari(voltApi) ? 'nagra-ssp-fps' : 'nagra-ssp'
}

export const getShakaServerKey = (voltApi: any): string => {
    // This is not an accurate function, it is based on the information provided by client, dynamic change is needed.
    switch (getBrowserName(voltApi)) {
        case voltApi.Constants.platform.smartTvOs:
            return 'com.microsoft.playready'
        case voltApi.Constants.platform.safari:
        case voltApi.Constants.platform.edge:
            return 'com.applefairplay'
        default:
            return 'com.widevine.alpha'
    }
}
