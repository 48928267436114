import { IChannelAppChannel } from '@typings/tile'
import { Abstract } from './Abstract'

/**
 * Class ChannelAppChannel description
 * Preview : https://developer.android.com/training/tv/discovery
 * @property {String} channelId : Channel ID
 * @property {String} channelName : Name of the Channel
 * @property {String} packageName : Application package name
 * @property {String} applicationIcon : URI to access to the Icon of the application in the system
 * @property {String} applicationLogo : URI to access to the Image of the application in the system
 * @property {String} applicationName : Application Name
 * @property {Number} [defaultChannel] : Similar to Default Channel. A value of 1 indicates the channel is included in
 * the channel list that applications use to browse channels, a value of 0 indicates the channel is not included in the list.
 */

export class ChannelAppChannel extends Abstract {
    id: string
    channelId: string
    packageName: string
    channelName: string
    applicationIcon: string
    applicationLogo: string
    applicationName: string
    defaultChannel: number

    constructor(props: IChannelAppChannel) {
        super()

        const computePropValue = this._makeComputePropValue(props)

        this.id = props.packageName + props.channelId
        this.channelId = props.channelId
        this.packageName = props.packageName
        this.channelName = props.channelName
        this.applicationIcon = props.applicationIcon
        this.applicationLogo = props.applicationLogo
        this.applicationName = props.applicationName
        this.defaultChannel = computePropValue('defaultChannel', 0)
    }
}
