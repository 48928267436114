import { For, JSXElement, Match, Show, Switch, createSignal, createEffect, onMount, Setter } from 'solid-js'
import { useSelector, useDispatch } from '../../store'
import { useIcon } from '../icon/useIcon'
import { isPlatform } from '../../utils'
import { unsetReminder, snoozeReminder } from '@ifs/volt-store/lib/actions'
import { useSpatialNavigation } from '../../spatialNavigation'
import { useToast } from '../toast/useToast'
import { FocusGroupProvider, FocusableProvider } from '../focus/index';
import { generateRandomString } from '../../utils/index';
import { useI18n } from '../../i18n'
import { useConfig } from '../config/useConfig'

export enum ToastType {
    PLAIN = 'toast-plain',
    ERROR = 'toast-error',
    WARNING = 'toast-warning',
    SUCCESS = 'toast-success',
    INFO = 'toast-info',
    REMINDER = 'toast-reminder',
}

export enum ToastPosition {
    TOP_LEFT = 'top-left',
    TOP_RIGHT = 'top-right',
    BOTTOM_LEFT = 'bottom-left',
    BOTTOM_RIGHT = 'bottom-right',
    TOP_CENTER = 'top-center',
}

export interface ToastProps {
    title: string
    description: string
    subDescription?: string
    type: ToastType
    actions?: JSXElement[]
    index?: number
    onClose?: () => void
    className?: string
    id?: number
    life?: number
    position?: ToastPosition
    sticky?: boolean
    link?: string
    programId?: string
    channelId?: string
    eventId?: string
    pendingTime?: string
    handlePlay?: any
    onCancelReminder?: any
    autoClosed?: boolean
    onReminderHandleFocus?: any
    deleteReminder?: any
    updateLocalShowedReminder?: any
    setAutoClosed?: Setter<boolean>
}

const ActionBtn = (props: any) => {
    return (
        <FocusableProvider
            id={props?.primaryKey}
            focusKey={props?.primaryKey}
            onEnterPress={props?.action}
            onFocus={() => {
                var element = document.querySelector(".toast-program-details");
                if (element) {
                    element.scrollTo({ top: 0, behavior: 'smooth' });
                }
            }}
        >
            {(focused: any) => (
                <div class={`action-btn ${focused ? 'action-btn-focused' : ''}`} onClick={props?.action}>
                    {props?.icon}
                    {props?.value ? props?.value : ''}
                </div>
            )}
        </FocusableProvider>
    )
}

export const Toast = (props: ToastProps) => {
    const dispatch = useDispatch()
    const icons = useIcon()
    const toast = useToast()
    const { getI18n } = useI18n()
    const { getConfig } = useConfig()

    const { getCurrentFocusKey, setFocus } = useSpatialNavigation()

    const programsState = useSelector((s: any) => s.programs)
    const reminders = useSelector((s: any) => s.notifications?.reminders)
    const channelsState = useSelector((s: any) => s.channels)
    // const randomStringForFocusKey = generateRandomString(5)
    const [program, setProgram] = createSignal<any>(undefined)
    const [showReminder, setShowReminder] = createSignal(false)
    const [nextSnoozeTime, setNextSnoozeTime] = createSignal<any>(60000)

    const calculateSpace = (index: number) => {
        return `${index * 80 + 15}px`
    }

    const CloseIcon = icons.get('CloseCross')
    const SuccessIcon = icons.get('Success')
    const WarningIcon = icons.get('Warning')
    const InfoIcon = icons.get('Information')
    const ErrorIcon = icons.get('Error')
    const PlayIcon = icons.get('Play')
    const BellSnoozeIcon = icons.get('bellSnooze')
    const BellRingsOffIcon = icons.get('bellRingsOff')
    const StarhubQrcode = icons.get('starhubQrcode')

    const getStyle = (): string => {
        switch (props?.position) {
            case ToastPosition.TOP_CENTER:
            case ToastPosition.TOP_RIGHT:
            case ToastPosition.TOP_LEFT: {
                return 'top'
            }
            case ToastPosition.BOTTOM_RIGHT:
            case ToastPosition.BOTTOM_LEFT: {
                return 'bottom'
            }
            default:
                return 'bottom'
        }
    }

    onMount(() => {
        if (!isPlatform('laptop')) {
            let reminderData: any = localStorage.getItem("shownReminders")
            if(reminderData && JSON.parse(reminderData)?.length > 0){
                setFocus("reminder_watch_episode" + props?.eventId)
            }
        }
    })

    createEffect(() => {
        if (props?.eventId) {
            setProgram(programsState()?.[props?.eventId])
        }
    })

    const onSnoozeReminder = () => {
        dispatch(snoozeReminder(props?.eventId))
        showSnoozeReminder()
        // setFocus("tile_details_update_favourite_VOD")
        if(!isPlatform("laptop")){
            props?.deleteReminder?.(props?.eventId)
            props?.onReminderHandleFocus?.(props?.eventId)
        }
        props?.onClose?.()
    }

    createEffect(() => {
        if(props?.autoClosed && props?.setAutoClosed) {
            if(snoozeReminderAvailbility(program()?.startTime)) {
                onSnoozeReminder()
            }
            props?.setAutoClosed(false)
        }
    })

    const showSnoozeReminder = () => {
        let reminder = reminders().find(key => key.eventId === props?.eventId);
        if (reminder?.reminderTime != props?.pendingTime) {
            let pendingTime = calculateReminderTime(reminder.reminderTime)
            setNextSnoozeTime(pendingTime > 60000 ? pendingTime : 60000)
            setShowReminder(true)
            if(!isPlatform("laptop")){
                props?.deleteReminder?.(props?.eventId)
                props?.onReminderHandleFocus?.(props?.eventId)
            }
        } else {
            // no snooze time recived setting 1 min
            setNextSnoozeTime(60000)
            setShowReminder(true);
            if(!isPlatform("laptop")){
                props?.deleteReminder?.(props?.eventId)
                props?.onReminderHandleFocus?.(props?.eventId)
            }
        }
        if (showReminder()) {
            showToast()
        }
        //setFocus(currentFocusKey)
    }

    const showToast = () => {
        let data: any = localStorage.getItem("shownReminders")
        let remindersData: any = (sessionStorage.getItem("reminders"))
        if (!JSON.parse(data)?.includes(props?.eventId) && !JSON.parse(remindersData)?.includes(props.eventId)){
            setTimeout(() => {
                    !isPlatform("laptop") && props?.updateLocalShowedReminder?.(props?.eventId)
                    toast.show({
                        title: 'Reminder',
                        description: 'Reminder',
                        type: ToastType.REMINDER,
                        sticky: false,
                        programId: props?.programId,
                        eventId: props?.eventId,
                        channelId: props?.channelId,
                        pendingTime: props?.pendingTime,
                        handlePlay: props?.handlePlay,
                        onCancelReminder: onCancelReminder,
                        onReminderHandleFocus: props?.onReminderHandleFocus,
                        deleteReminder: props?.deleteReminder,
                        updateLocalShowedReminder: props?.updateLocalShowedReminder,
                        life: 50000
                    })
            }, nextSnoozeTime())
        }
    }

    const onCancelReminder = () => {
        props?.onCancelReminder(props?.eventId)
        props?.onClose?.()
    }

    const snoozeReminderAvailbility = (startTime: any) => {
        return new Date().getTime() < new Date(startTime).getTime() ? true : false
    }
    const calculateReminderTime = (reminderTime: any) => {
        return new Date(reminderTime).getTime() - new Date().getTime()
    }
    const calculatePendingTime = () => {
        let dateDiff = (program()?.startTime - new Date().getTime()) / 1000
        return Math.round(dateDiff / 60)
    }

    return (
        <div>
            <Show when={program()?.id && props?.type === ToastType.REMINDER}>
                <FocusGroupProvider
                    id={`toast-screen`}
                    focusKey={`toast-screen`}
                >
                    <div class={`toast-icon-main-wrapper ${props?.className} ${props?.type} ${props?.position || ToastPosition.BOTTOM_LEFT}`} style={{ [getStyle()]: calculateSpace(props?.index || 0) }}>
                        <div class="toast-icon-details-reminder-wrapper">
                            <div class="toast-program-icon">
                                <img class="reminder-image" src={program()?.thumbnail} />
                            </div>
                            <div class="toast-program-details">
                                <div class="reminder-title">{program()?.title}</div>
                                <div class="reminder-description">
                                {calculatePendingTime() < 0 ? `This program has started ${calculatePendingTime() * -1} minutes ago`
                                :
                                `The program starts in ${calculatePendingTime()} minutes`
                                }
                                </div>
                                <div class="actions">
                                    <ActionBtn
                                        value={getI18n()?.formatMessage({ id: 'PLAY' })}
                                        primaryKey={
                                            "reminder_watch_episode" + props?.eventId
                                        }
                                        action={() => {
                                            dispatch(unsetReminder({ eventId: props?.eventId }))
                                            props?.handlePlay(program(),channelsState()?.data?.[program().channelId])
                                            props?.onClose?.()
                                        }}
                                        icon={<PlayIcon />}
                                    />
                                    <Show when={snoozeReminderAvailbility(program()?.startTime)}>
                                        <ActionBtn
                                            value={getI18n()?.formatMessage({ id: 'SNOOZE' })}
                                            primaryKey={
                                                "snooze_reminder" + props?.eventId
                                            }
                                            action={() => {
                                                onSnoozeReminder()
                                            }}
                                            icon={<BellSnoozeIcon />}
                                        />
                                    </Show>
                                    <ActionBtn
                                        value={getI18n()?.formatMessage({ id: 'STOP' })}
                                        primaryKey={
                                            "stop_reminder" + props?.eventId
                                        }
                                        action={() => {
                                            onCancelReminder()
                                        }}
                                        icon={<BellRingsOffIcon />}
                                    />
                                </div>

                            </div>
                        </div>
                    </div>
                </FocusGroupProvider>
            </Show>

            <Show when={props?.type != ToastType.REMINDER}>
                <Switch>
                    <Match when={props?.description === 'noRecordingQuota'}>
                        <div class={`toast-wrapper ${props?.className} ${props?.type} ${props?.position || ToastPosition.BOTTOM_LEFT}`} style={{ [getStyle()]: calculateSpace(props?.index || 0) }}>
                            <div class="toast-details-wrapper-with-image">
                                <div class="toast-description">{getI18n()?.formatMessage({ id: 'TOAST_QRCODE_DESCRIPTION' })}</div>
                                <div class="toast-image"><img src={StarhubQrcode} /></div>
                                <div class="toast-link">{getI18n()?.formatMessage({ id: 'OR_VISIT' })} <a target='blank' href={getConfig()?.toastQRCodeLink}>{getConfig()?.toastQRCodeLink}</a></div>
                            </div>
                            <div class="toast-actions">
                                <For each={props?.actions}>
                                    {action => {
                                        return action
                                    }}
                                </For>
                                <Show when={!props?.actions}>
                                    <div class={isPlatform('laptop') ? "toast-close-icon-wrapper" : "toast-close-icon-wrapperTV"} id="btnCloseToast" onClick={() => props?.onClose?.()}>
                                        <CloseIcon class="webstv-icon-new" />
                                    </div>
                                </Show>
                            </div>
                        </div>

                    </Match>
                    <Match when={props?.subDescription != 'noRecordingQuota'}>
                        <div class={`toast-wrapper ${props?.className} ${props?.type} ${props?.position || ToastPosition.BOTTOM_LEFT}`} style={{ [getStyle()]: calculateSpace(props?.index || 0) }}>
                            <div class="toast-icon-details-wrapper">
                                <div class="toast-icon-wrapper">
                                    <Switch fallback={<SuccessIcon class="toast-icon" />}>
                                        <Match when={props?.type === ToastType.PLAIN}>
                                            <SuccessIcon class="toast-icon" />
                                        </Match>
                                        <Match when={props?.type === ToastType.SUCCESS}>
                                            <SuccessIcon class="toast-icon" />
                                        </Match>
                                        <Match when={props?.type === ToastType.INFO}>
                                            <InfoIcon class="toast-icon" />
                                        </Match>
                                        <Match when={props?.type === ToastType.WARNING}>
                                            <WarningIcon class="toast-icon" />
                                        </Match>
                                        <Match when={props?.type === ToastType.ERROR}>
                                            <ErrorIcon class="toast-icon" />
                                        </Match>
                                    </Switch>
                                </div>
                                <div class="toast-details-wrapper">
                                    <div class="toast-title">{props?.title}</div>
                                    <div class="toast-description">{props?.description}</div>
                                    <div class="toast-description">{props?.subDescription ?? ''}</div>
                                    <div class="toast-description"><a target='blank' href={props?.link ?? ''}>{props?.link ?? ''}</a></div>
                                </div>
                            </div>

                            <div class="toast-actions">
                                <For each={props?.actions}>
                                    {action => {
                                        return action
                                    }}
                                </For>
                                <Show when={!props?.actions}>
                                    <div class={isPlatform('laptop') ? "toast-close-icon-wrapper" : "toast-close-icon-wrapperTV"} id="btnCloseToast" onClick={() => props?.onClose?.()}>
                                        <CloseIcon class="webstv-icon-new" />
                                    </div>
                                </Show>
                            </div>
                        </div>
                    </Match>
                </Switch>
            </Show>
        </div>
    )
}
