/**
 * @class Login parameters returned by Port/VSP/V3/Login
 */
export class LoginParametersV6 {
    constructor(props) {
        /**
         * @type {String}
         * Access address of the VSC server in http://<VSPip>:<VSPhttpport> format.
         * If the URL is in IP address format, the IP address supports both IPv4 and IPv6. The VSP returns a corresponding VSC IP address based on the type of the network address the client uses to connect to the VSP.
         * If both the domain name and IP address are configured on the VSC server, the domain name is returned first.
         * NOTE:The vspURL parameter cannot be used in any scenario, so do not use it on user devices. The parameter will be obtained when the LoginRoute interface is invoked for login.
         */
        this.vspURL = props.vspURL

        /**
         * @type {String}
         * 	HTTPS address of the VSC server. This parameter is returned only when the VSC server supports HTTPS. The format is https://*<VSPip>*:*<VSPhttpsport>*.
         * In the format, <VSPip> and <VSPhttpsport> respectively indicate the IP address and port number of the VSC server.
         * If the URL is in IP address format, the IP address supports both IPv4 and IPv6. The VSP returns a corresponding VSC IP address based on the type of the network address the client uses to connect to the VSP.
         * If both the domain name and IP address are configured on the VSC server, the domain name is returned first.
         * If the client interacts with the VSC server using HTTPS, the HTTPS port number can be obtained through this parameter.
         * Whether the VSC server supports HTTPS is configurable on the Envision Video Platform.
         * NOTE: The vspHttpsURL parameter cannot be used in any scenario, so do not use it on user devices. The parameter will be obtained when the LoginRoute interface is invoked for login.
         */
        this.vspHttpsURL = props.vspHttpsURL

        /**
         * @type {String}
         * Domain name of the NTP server.
         * Format: http://IP address or Domain name:Port/Service name
         * The IP address supports both IPv4 and IPv6. The VSP returns a corresponding UPG IP address based on the type of the network address the client uses to connect to the VSP.
         */
        this.NTPDomain = props.NTPDomain

        this.NTPDomainBackup = props.NTPDomainBackup

        this.terminalParm = props.terminalParm

        /**
         * @type {String}
         * ID of the regional node carrier to which the subscriber belongs.
         * The VSP obtains the regional node ID based on the domain name of the VSC accessed by the user device and returns the regional node ID.
         */
        this.subnetID = props.subnetID

        /**
         * @type {String}
         * 	Domain name of the UPG.
         * Format: http://IP address or Domain name:Port/Service name
         * The IP address supports both IPv4 and IPv6. The VSP returns a corresponding UPG IP address based on the type of the network address the client uses to connect to the VSP.
         * If the EDS must be used for the device upgrade, the service name is EDS. Otherwise, the service name is UPGRADE.
         */
        this.upgradeDomain = props.upgradeDomain

        /**
         * @type {String}
         * 	Address to which the root certificate is delivered. This parameter is returned only when the VSC server supports the root certificate. The address format is similar to http://ip or domain:port/getrootceraddrVSP/CA/iptv_ca.der
         * In the format, ip and port respectively indicate the IP address and port number of the VSC server.
         * If the URL is in IP address format, the IP address supports both IPv4 and IPv6. The VSP returns a corresponding VSC IP address based on the type of the network address the client uses to connect to the VSP.
         * If the client wants to update the root certificate, it obtains the URL for downloading the root certificate. The parameter is invalid if the client does not support HTTPS.
         */
        this.rootCerAddr = props.rootCerAddr

        /**
         * @type {String}
         * 	Domain name of the TMS.
         * Format: http://IP address or Domain name:Port/Service name
         * The IP address supports both IPv4 and IPv6. The VSP returns a corresponding TMS IP address based on the type of the network address the client uses to connect to the VSP.
         * NOTE: When the domain name is blank, no device is connected to the TMS.
         */
        this.mgmtDomain = props.mgmtDomain

        /**
         * @type {Object}
         * Subscriber-related system parameters.
         */
        this.parameters = props.parameters
    }
}
