import { ITVODProduct, ProductContent } from '@typings/tile'
import { Product } from './Product'

/**
 * A TVOD (Transactional VOD) product which gives access to one or more contents of a single title
 * @property {String} id content identifier
 * @property {Boolean} isHD indicates whether the content is in HD
 * */

/**
 * @typedef {Object} TVODProductProps
 * @property {String} programId Identifier of the VOD associated with the product
 * @property {Array<ProductContent>} contents List of data related to the contents this product gives access to
 */
export class TVODProduct extends Product {
    programId: string
    contents: ProductContent[]
    name: string
    providerId: string
    constructor(props: ITVODProduct) {
        super(props)

        this.programId = props.programId

        const computePropValue = this._makeComputePropValue(props)

        this.contents = computePropValue('contents', [])
        this.name = computePropValue('name', '')
        this.providerId = computePropValue('providerId', '')
    }

    /**
     * Indicates whether the product is a TVOD
     * Always returns true
     */
    isTVOD(): boolean {
        return true
    }
}
