/**
 * Returns VOD streams per streamType
 * @param {Array} streams Coming from IFS proxy
 * @param {streamTypes} streamType Stream to parse
 * @return {Object}
 */
export const vodStreamFactory = (streams = [], streamType) => {
    return streams.find((s) => {
        return s && s.type === streamType && !!s.uri
    })
}
