/**
 * Takes a string as parameter and returns the string without spaces and the
 * first letter capitalized
 * For example : " hello" -> "Hello"
 *
 * @param   {?string} string string to transform
 *
 * @returns {?string} string transformed or null when the value is not defined
 */
function upperCaseTheFirstLetter(string) {
    return string
        ? string
              .trim()
              .charAt(0)
              .toUpperCase() + string.slice(1)
        : null
}

export default upperCaseTheFirstLetter
