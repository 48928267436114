
import { useConfig } from "@volt-libs/foundation";
import tinycolor from "tinycolor2";
import config from '../config'

export const formatUrl = (baseurl: string, homepath: string, defaultpopulation: string) => {
    if (homepath) {
        const arr = homepath.split('/')
        const res = baseurl + '/' + arr[0] + '/' + defaultpopulation + '/' + arr[2] + '/theme'
        return res
    }
}

export const setLogoButton = (value: any) => {
    if (value) {
        let response = value.replace(/^[^{]*/g, '')
        response = response.replace(/[^}]*$/g, '')
        response = JSON.parse(response)
        const logo = response?.moduleData?.themes[0]?.graphicAssets?.logo
        if (logo) {
            document.documentElement.style.setProperty('--app-logo', "url('" + logo + "')")
        }
        const focusColor = response?.moduleData?.themes[0]?.colors?.focusColor ?? config?.theme.focusColor
        const textColor = response?.moduleData?.themes[0]?.colors?.textColor ?? config?.theme.textColor
        const interactiveBackgroundColor = response?.moduleData?.themes[0]?.colors?.interactiveBackgroundColor ?? config?.theme.interactiveBackgroundColor
        const primaryBackgroundColor = response?.moduleData?.themes[0]?.colors?.backgroundColor ?? config?.theme.backgroundColor;
        const backgroundBlurColor = response?.moduleData?.themes[0]?.colors?.backgroundBlurColor ?? config?.theme.backgroundBlurColor;
        const colorOpacity = tinycolor(interactiveBackgroundColor).getAlpha()
        document.documentElement.style.setProperty('--focus-background-color', focusColor);
        document.documentElement.style.setProperty('--focus-border-color', focusColor);
        document.documentElement.style.setProperty('--focus-complementary-color', tinycolor(focusColor).complement().toHexString());
        document.documentElement.style.setProperty('--active-text-color', textColor);
        document.documentElement.style.setProperty('--interactive-background-color', interactiveBackgroundColor);
        document.documentElement.style.setProperty('--interactive-background-color-lighten', tinycolor(interactiveBackgroundColor).setAlpha(colorOpacity / 2));
        document.documentElement.style.setProperty('--primary-background-color', primaryBackgroundColor);
        document.documentElement.style.setProperty('--background-blur-Color', backgroundBlurColor);

    }
}