import { throwError } from 'rxjs'
import { catchError, delay, mergeMap } from 'rxjs/operators'
import VoltError from 'VoltError'

/**
 * Generic retry strategy for `retryWhen()`
 *
 * You can set `errorCode` to check and your observable from which you call `retryWhen()`
 * will be called again for a `maxRetryAttempts` times
 * @param {Object} options
 * @param {Number} [options.maxRetryAttempts=3] Max attempts to recall your observable
 * @param {Number} [options.delayMs=5000] Delay between calls
 * @param {String} options.errorCode Error code to check for retry
 * @param {Function} options.callback Function to call if raised error is equal to `options.errorCode` should return observable
 * @param {Array<String>} [options.platforms] List of platforms supported
 * @param {Object} options.config Config
 * @param {Object} options.logger Logger
 * @returns Observable
 */
const retryStrategy =
    ({ maxRetryAttempts = 3, delayMs = 5000, errorCode, callback, platforms, config, logger }) =>
    (attempts) => {
        return attempts.pipe(
            mergeMap((error, attemptIndex) => {
                if (!errorCode || !callback) {
                    logger.warn('Error code or callback for retry strategy not set')
                    return throwError(error)
                }
                if (platforms && platforms.length > 0 && !platforms.includes(config.platform)) {
                    logger.warn('Retry not allowed on this platform')
                    return throwError(error)
                }

                const retryAttempt = attemptIndex + 1
                logger.info(
                    `Got error ${error.code}, ${retryAttempt} retry of ${maxRetryAttempts}...`
                )
                if (retryAttempt > maxRetryAttempts) {
                    logger.warn('Max attempts for retry strategy reached')
                    return throwError(error)
                }

                if (error.code === errorCode) {
                    logger.warn(`Retry detected for this error ${errorCode}`)
                    return callback().pipe(
                        catchError((callbackError) => {
                            return throwError(
                                callbackError.code === VoltError.codes.UNKNOWN_API_ERROR.code
                                    ? error
                                    : callbackError
                            )
                        })
                    )
                }

                logger.warn(`No retry strategy for ${error.code}`)
                return throwError(error)
            }),
            delay(delayMs)
        )
    }

export default retryStrategy
