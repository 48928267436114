import { createEffect, createSignal, onMount, Show, onCleanup, on, Setter, } from 'solid-js'
import { AuthorizationContext } from './AuthorizationContext'
import { AuthorizationHandler, AuthorizationType, AuthorizerOptions } from './authorizationHandler'
import { LoadingScreen, Spinner, useConfig } from '../components'
import { profileSelector, useDispatch, useSelector } from '../store'
import { fetchProfile, authenticate, logout } from '@ifs/volt-store/lib/actions'
import { getDeviceInfo, isPlatform, LocalStorageKeys } from '../utils'
import { Login } from '../components/login/login'
import { LoginTVTab } from '../components/logintvtab/logintvtab'
import { LoginTVForm } from '../components/logintvform/logintvform'
import { LoginLanguage } from '../components/loginlanguage/loginlanguage';
import { getDeviceData } from '../utils';
import * as voltAPI from '@ifs/volt-api/build/ifeelsmart.bundle.js'
import { isWeb } from '../utils/isWeb'

interface AuthorizationProviderProps {
    voltApi: any
    children: any
}
export const AuthorizationProvider = (props: AuthorizationProviderProps) => {
    let progressInterval:any;
    const [authorizationHandler, setAuthorizationHandler] = createSignal<AuthorizationHandler>()
    const { getConfig } = useConfig()
    const dispatch = useDispatch()
    const profile = useSelector(profileSelector)
    const authenticationStatus = useSelector((s: { auth: { authenticationStatus: string } }) => s.auth.authenticationStatus)
   
    const authConfig: AuthorizerOptions = {
        allowGuest: getConfig().allowGuest,
        clientId: getConfig().clientId,
        clientSecret: getConfig().clientSecret,
        redirectUri: window.location.origin + '/oauthredirect',
        authorizationType: AuthorizationType.REDIRECT,
        responseType: AuthorizationType.TOKEN,
        grant_type: getConfig().grant_type,
        scope: '',
        authorityConfiguration: {
            authorization_endpoint: getConfig().urls.authUrl + getConfig().OAuthParam?.endpoint?.auth,
            token_endpoint: getConfig().OAuthParam?.endpoint?.fullTokenUrl
                ? getConfig().OAuthParam?.endpoint?.fullTokenUrl
                : getConfig().urls.authUrl + getConfig().OAuthParam?.endpoint?.token,
            revocation_endpoint: '',
            registration_endpoint: '',
        },
    }

    const isAuthPending = useSelector((state: { auth: { isPending: any } }) => state.auth.isPending)
    const isLogoutPending = useSelector((state: { auth: { isLogoutPending: any } }) => state.auth.isLogoutPending)
    const isLogoutError = useSelector((state: { auth: { isLogoutError: any } }) => state.auth.isLogoutError)

    const [waitForProfile, setWaitForProfile] = createSignal(false)
    const [profileFailed, setProfileFailed] = createSignal(false)
    const [showLoginTVLang, setShowLoginTVLang] = createSignal(true);
    const [showLoginTVTab, setShowLoginTVTab] = createSignal(false);
    const [showLoginTVForm, setShowLoginTVForm] = createSignal(false);
    const [username, setUsername] = createSignal('');
    const [password, setPassword] = createSignal('');
    const [isLoggingOut, setIsLoggingOut] = createSignal(false)

    const unsupportedDeviceTypes = ['smartphone', 'tablet', 'phablet']
    const isDeviceUnSupported = unsupportedDeviceTypes.includes(getDeviceInfo().device?.type ?? 's')

    createEffect(() => {
        if (profile()) {
            setWaitForProfile(false)
        }
    })

    createEffect(
        on([() => isLogoutPending(), () => isLogoutError()], () => {
            if(isLogoutPending()) {
                setIsLoggingOut(true)
            }

            if(isLoggingOut() && !isLogoutPending()) {
                setWaitForProfile(true)
                setProfileFailed(false)
                setShowLoginTVLang(true)
                setShowLoginTVTab(false)
                setShowLoginTVForm(false)
                setUsername('')
                setPassword('')
            }
        })
    )

    function handleLoginTVTab() {
        setShowLoginTVTab(true);
    }

    function handleLoginSettingsTVTab() {
        setShowLoginTVTab(true);
    }

    const handleTvLogin = (username: string, password: string) => {
        setUsername(username);
        setPassword(password);
        const deviceData = getDeviceData();
        const deviceid = isWeb ? 1 : 3;

        dispatch(authenticate({
            loginType: voltAPI.Constants.loginType.USERNAME_AND_PASSWORD,
            username,
            password,
            deviceData: {
                ...deviceData,
                deviceId: deviceid,
            },
        }))
    }

    createEffect(
        () => {
            if (authenticationStatus() === voltAPI.Constants.authenticationStatus.FAILED
            ) {
                setShowLoginTVForm(false)
            }
        }
    )

    const backKeyHandler = (event: any) => {
        if (event.keyCode == 461 || event?.keyCode == 10009) {
            if (!showLoginTVTab() && !showLoginTVForm() && showLoginTVLang()) {
                setShowLoginTVLang(true)
            } else if (
                showLoginTVTab() && !showLoginTVForm() && showLoginTVLang()
            ) {
                setShowLoginTVTab(false)
            }
        }
    }

    const getAuthKey = () => {
        return getConfig()?.authenticationType === 'iptv' ? LocalStorageKeys.SESSION_COOKIE : LocalStorageKeys.OIDC_OAUTH_TOKEN
    }

    onMount(() => {
        window.addEventListener('keydown', backKeyHandler);
    })


    onCleanup(() => {
        window.removeEventListener('keydown', backKeyHandler);
        clearInterval(progressInterval)
    })



    onMount(async () => {
        if(getConfig()?.authenticationType === 'oauth') {
            const _authzHandler = new AuthorizationHandler(authConfig)
            setAuthorizationHandler(_authzHandler)
        }
        if (window.location.pathname !== '/oauthredirect' && !isDeviceUnSupported) {
            if (!authConfig.allowGuest && localStorage.getItem(getAuthKey()) === null) {
                setWaitForProfile(true)
            }
            if (!profile() && localStorage.getItem(getAuthKey()) !== null) {
                setWaitForProfile(true)
                dispatch(
                    fetchProfile({
                        onSuccess: () => {
                            setProfileFailed(false)
                        },
                        onError: () => {
                            setProfileFailed(true)
                            dispatch(logout())
                        },
                    })
                )
            }
    }
    })

    return (
        <AuthorizationContext.Provider value={{ authorizationHandler: () => authorizationHandler() as any }}>
            <Show when={
                    ((localStorage.getItem(LocalStorageKeys.OIDC_OAUTH_TOKEN) === null && waitForProfile()) ||
                        authenticationStatus() === props.voltApi.Constants.authenticationStatus.FAILED ||
                        profileFailed()) &&
                    window.location.pathname !== '/settings' && getConfig()?.authenticationType === 'oauth' && !isAuthPending()
                }
            >   
                <Login
                    onLogin={() => authorizationHandler()?.signIn()}
                    failed={authenticationStatus() === props.voltApi.Constants.authenticationStatus.FAILED}
                />
            </Show>
            <Show when={
                    ((localStorage.getItem(LocalStorageKeys.OIDC_OAUTH_TOKEN) === null && waitForProfile()) ||
                        authenticationStatus() === props.voltApi.Constants.authenticationStatus.FAILED ||
                        profileFailed()) &&
                    window.location.pathname !== '/settings' && getConfig()?.authenticationType === 'iptv' && !isAuthPending()
                }
            >  
                <Show when={!showLoginTVLang() && !showLoginTVTab() && !showLoginTVForm()}>
                    <LoginLanguage
                        onLanguageSelectionComplete={()=> setShowLoginTVLang(true)}
                    />
                </Show>
                <Show when={showLoginTVLang() && !showLoginTVTab() && !showLoginTVForm()}>
                    <LoginTVTab
                        onLoginTVTab={handleLoginTVTab}
                        onLoginSettingsTVTab={handleLoginSettingsTVTab}
                        failed={authenticationStatus() === props.voltApi.Constants.authenticationStatus.FAILED}
                    />
                </Show>
                <Show when={showLoginTVLang() && showLoginTVTab() && !showLoginTVForm()}>
                    <LoginTVForm
                        onLoginTVForm={handleTvLogin}
                        username={username()}
                        password={password()}
                        failed={authenticationStatus() === props.voltApi.Constants.authenticationStatus.FAILED}
                    />      
                </Show>
            </Show>

            <Show
                when={
                    (!isAuthPending() &&
                        !waitForProfile() &&
                        !profileFailed() &&
                        authenticationStatus() !== props.voltApi.Constants.authenticationStatus.FAILED) ||
                    window.location.pathname === '/settings'
                }
                fallback={<LoadingScreen />}
            >
                {props.children}
            </Show>
        </AuthorizationContext.Provider>
    )
}

export interface AuthorizationProviderBehavior {
    authorizationHandler?: () => AuthorizationHandler
}
