import { Profile } from 'models'
import Constants from 'api-constants'
import HuaweiTypes from '../HuaweiTypes'
import { resolvePcLevel } from '../Helpers'
import { convertLanguageMappingApiV2 } from '../Helpers'
import ConfigHelper from 'framework/helpers/config'

/**
 * Extract user Business Type
 * @param {String} [userGroup] (Ooredoo specific) Used to defined type of customer among B2C/B2B/B2B
 * @param {String} [userTag] (Ooredoo specific) Used to defined type of customer among B2C/B2B/B2BC
 * @return {Constants.businessType
 */
export const parseBusinessType = ({ userGroup, userTag } = {}) => {
    /**
     * Ooredoo Logic, Eventually to move outside if we have a customer with Huawei Backend
     * Authenticate response returns the data “usergroup” and “usertag”
     * If usergroup=6 and usertag=Nonbusiness, then the customer type is B2C
     * If usergroup=6 and usertag=Business, then the customer type is B2B2C
     * If usergroup=7 or 9, regardless usertag, then the customer type is B2B.
     */
    switch (userGroup) {
        case '6':
        case 6:
            return (userTag || '').toLowerCase() === 'business'
                ? Constants.businessType.B2B2C
                : Constants.businessType.B2C
        case '7':
        case '9':
        case 7:
        case 9:
            return Constants.businessType.B2B
        default:
            return Constants.businessType.B2C
    }
}

/**
 * Create internal account from Backend metadatas
 * @param {Array} huaweiProfiles
 * @param {String} [businessType=Constants.businessType.B2C] Type of business user is tied among B2C/B2B/B2BC
 * @returns {Array<Profile>}
 */
export const profileFactory = (huaweiProfiles, businessType = Constants.businessType.B2C) => {
    if (!huaweiProfiles || huaweiProfiles.length === 0) {
        return []
    }

    const profiles = huaweiProfiles.map((huaweiProfile) => {
        const {
            // --------------------------------------------------
            // - Common field between API v2 and v6
            // --------------------------------------------------
            name,
            loginName,
            lang,
            email,
            purchaseEnable,
            familyRole,
            profileType: huaweiProfileType,
            // --------------------------------------------------
            // - Name of the Field for Huawei v6
            // --------------------------------------------------
            ID, // Similar to 'id' for API v2
            subscriberID, // Similar to 'subscriberId' for API v2
            ratingID, // Similar to 'levels' for API v2
            isDefaultProfile: isDefaultProfileV6, // Not similar on Huawei v2
            // --------------------------------------------------
            // - Name of the Field for Huawei v2
            // --------------------------------------------------
            id, // Similar to 'ID' for API v6
            subscriberId, // Similar to 'subscriberID' for API v6
            levels, // Similar to 'ratingID' for API v6
            deviceid: isDefaultProfileV2, // If this parameter is set, it indicates that the profile is the default profile of the device.
            password: encryptedPassword, // Available only on API v2
        } = huaweiProfile || {}

        const userSubscriberId = subscriberID || subscriberId
        const profileId = ID || id

        const pcLevel =
            (ratingID && resolvePcLevel(ratingID)) ||
            (levels && resolvePcLevel(levels)) ||
            ConfigHelper.getInstance().getConfig().defaultUserPcLevel

        const isDefaultProfile =
            (isDefaultProfileV6 && HuaweiTypes.parseIBoolean(isDefaultProfileV6)) ||
            !!isDefaultProfileV2

        const defaultLanguage = isNaN(lang) ? lang : convertLanguageMappingApiV2(lang)

        let profileType
        switch (HuaweiTypes.parseInt(huaweiProfileType)) {
            case 0:
                profileType = Profile.PROFILE_TYPE.SUPER_PROFILE
                break
            case 1:
            default:
                profileType = Profile.PROFILE_TYPE.COMMON_PROFILE
                break
        }

        const defaultPopulation =
            ConfigHelper.getInstance().getConfig('urls').emi?.defaultPopulation

        let emiPopulation
        switch (businessType) {
            case Constants.businessType.B2B2C:
                emiPopulation = `${defaultPopulation}_b2b2c`
                break
            case Constants.businessType.B2B:
                emiPopulation = `${defaultPopulation}_b2b`
                break
            case Constants.businessType.B2C:
            default:
                // By defining undefined here, the default population from the config is used
                emiPopulation = undefined
                break
        }

        return new Profile({
            status: Profile.STATUS.ACTIVATED,
            userId: userSubscriberId,
            username: name || loginName || userSubscriberId,
            customerId: userSubscriberId,
            profileId,
            email,
            defaultLanguage,
            profileType,
            isDefaultProfile,
            isKidProfile: HuaweiTypes.parseIsKidRole(Number(familyRole)),
            pcLevel,
            businessType,
            dvrEnabled: true,
            dvrQuotaUnit: Profile.DVR_QUOTA_UNIT.MB,
            /**
             * Props not supported, to be used later
             */
            purchaseEnabled: purchaseEnable ? HuaweiTypes.parseIBoolean(purchaseEnable) : true,
            namedProperties: {
                encryptedPassword,
                isLocalProfile: false,
                /**
                 * On ooredoo, the population is contextual for b2b2c and b2b profile
                 * For b2c, let the default one from the config
                 */
                Population: emiPopulation,
            },
        })
    })
    return profiles
}
