export default (props = {}) => <svg xmlns="http://www.w3.org/2000/svg" width="240" height="80" viewBox="0 0 240 80" fill="none"{...props}>
    <path d="M79.1194 23.3919C78.7937 22.4189 77.9556 21.8753 77.2781 21.5647C76.5436 21.2541 75.6539 21.016 74.7125 20.8504C72.8918 20.545 70.5383 20.4363 68.2365 20.4208C65.8882 20.4052 62.1278 20.6019 59.5312 20.752L60.7106 3.68557L42.8706 0L44.7016 23.5732C46.4241 23.2367 47.9086 23.0038 48.8914 22.9261C48.8914 22.9261 51.3328 10.9894 54.1467 10.1509C56.4381 9.45726 59.0605 20.2965 59.4537 21.9633L59.5054 22.1703C59.5054 22.1703 76.7401 20.7055 77.7798 23.8372C79.0212 27.5485 63.6744 32.8647 63.6744 32.8647C63.6744 32.8647 73.4711 59.8076 70.1504 61.3657C68.2779 62.2561 61.4864 52.3123 57.7415 46.5821C56.2726 44.2941 55.269 42.6739 55.269 42.6739C53.1225 45.1741 50.1173 48.3368 46.8482 51.5255L48.2758 70L56.6449 62.6495L57.576 48.927C58.4967 50.3039 59.5209 51.8154 60.5657 53.2958C62.2727 55.7287 64.0882 58.1564 65.671 59.9164C66.4676 60.7859 67.2279 61.5573 67.921 62.0646C68.2728 62.3233 68.6711 62.5563 69.1004 62.6961C69.5245 62.8358 70.1348 62.929 70.7556 62.6442C72.0073 62.0594 72.2039 60.5945 72.2504 59.7714C72.3073 58.7362 72.1728 57.4162 71.9349 56.0133C71.4848 53.2181 70.5487 49.6465 69.5141 46.1317C68.009 41.0279 66.3021 36.0326 65.4899 33.7136C66.9537 33.1494 69.4003 32.1814 71.8056 31.0219C73.6676 30.1264 75.5918 29.0498 76.9832 27.9265C78.1781 26.9689 79.7661 25.3331 79.1194 23.3919Z" fill="white"/>
    <path d="M28.4082 29.9246C28.1858 33.4445 43.8016 33.6877 45.4465 33.4963C45.4465 33.4963 25.3565 62.3596 27.5755 65.7035C28.8893 67.6706 38.6498 59.5437 46.8431 51.5307L44.6964 23.5732C38.2929 24.8207 28.5634 27.59 28.4082 29.9246Z" fill="white"/>
    <path d="M89.7076 48.9839C90.7627 49.7034 92.3507 50.423 94.58 50.423C97.6991 50.423 100.146 48.6733 100.146 45.8833C100.146 43.1044 97.9667 41.947 96.0687 40.9389C94.6045 40.1611 93.3077 39.4723 93.3077 38.1964C93.3077 37.1404 94.3163 36.5865 95.5887 36.5865C96.887 36.5865 98.0612 37.0214 99.2871 37.6943L99.3336 35.1009C98.4646 34.6195 97.125 34.2106 95.4439 34.2106C92.1283 34.2106 90.2817 36.2035 90.2817 38.5588C90.2817 39.7803 90.6852 40.6966 91.311 41.3902C92.1109 42.292 93.2441 42.889 94.2938 43.442C95.7626 44.2158 97.068 44.9035 97.068 46.2197C97.068 47.5449 95.6767 48.047 94.4559 48.047C92.9662 48.047 91.7456 47.4465 90.8817 46.846L89.7076 48.9839Z" fill="white"/>
    <path d="M117.897 36.7832H113.579V50.1848H110.651V36.7832H106.063V34.3555H118.596L117.897 36.7832Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M131.662 46.2197L133.223 50.1848H136.317L129.954 34.283H127.845L121.699 50.1848H124.554L126.019 46.2197H131.662ZM128.879 38.2481C128.998 38.7813 129.189 39.4283 129.479 40.2203L130.751 43.8438H126.914L128.279 40.1685C128.543 39.4231 128.76 38.802 128.859 38.2481H128.879Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M150.485 48.8004L150.482 48.7957C148.606 45.6514 147.686 44.1105 147.159 43.9421H145.814V50.1848H142.906V34.3555H147.179C151.286 34.3555 152.822 36.4001 152.822 38.8226C152.822 40.9347 151.384 42.353 149.895 42.9793V43.0051C150.533 43.3681 151.851 45.566 153.836 48.875C154.087 49.2941 154.35 49.7315 154.622 50.1848H151.312C151.02 49.6972 150.747 49.2397 150.492 48.8119L150.485 48.8004ZM147.252 36.8091H145.814V41.7318H147.277C148.767 41.7318 149.895 40.6033 149.895 39.1591C149.895 37.865 149.057 36.8091 147.252 36.8091Z" fill="white"/>
    <path d="M171.294 43.4141V50.1848H174.195V34.3555H171.294V40.8932H163.638V34.3555H160.731V50.1848H163.638V43.4141H171.294Z" fill="white"/>
    <path d="M193.292 48.6733C192.19 49.7758 190.556 50.4281 188.202 50.4281C185.968 50.4281 184.338 49.9001 183.283 48.87C182.156 47.7881 181.727 46.1317 181.727 44.3769V34.3555H184.633V44.1802C184.633 45.3346 184.943 46.2715 185.569 46.9651C186.169 47.6122 187.106 47.9486 188.259 47.9486C189.604 47.9486 190.535 47.5914 191.14 46.9651C191.74 46.3181 191.978 45.4277 191.978 44.32V34.3555H194.88V44.2268C194.875 46.0333 194.368 47.5914 193.292 48.6733Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M201.966 50.1848H206.984C210.966 50.1848 212.596 47.6898 212.596 45.4329C212.596 43.559 211.349 42.0216 209.596 41.6128V41.5868C210.895 40.9398 211.566 39.6613 211.566 38.2947C211.566 36.3742 210.103 34.3555 207.005 34.3555H201.966V50.1848ZM204.869 43.3623H206.839C208.613 43.3623 209.622 44.2061 209.622 45.4277C209.622 46.8719 208.66 47.7364 206.715 47.7364H204.869V43.3623ZM204.869 36.8091H206.286C207.894 36.8091 208.665 37.6476 208.66 38.8485C208.66 40.1013 207.626 40.9398 206.596 40.9398H204.869V36.8091Z" fill="white"/>
</svg>