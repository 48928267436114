import { Program } from './Program'
import Constants from 'api-constants'
import { IDvr } from '@typings/tile'

const status = Constants.recordingStatus

/**
 * A recording event
 * @property {String|Number} channelId Proxy ID of the recording's channel
 * @property {String|Number} platformChannelId Platform ID of the recording's channel
 * @property {Number} duration Recording's runtime in milliseconds
 * @property {Number} startTime Timestamp of the recording event's broadcast date
 * @property {Number} endTime Timestamp of the end of the recording event's broadcast
 * @property {Array<String|Number>} eventIds Platform IDs of all EPG events linked to the recording
 * @property {String|Number} titleId Platform ID of the Title linked to the recording
 * @property {Boolean} isDvrEpisode Indicates whether the recording is an episode of a series or if it is a standalone event
 * @property {String} status The recording's status (one of Constants.recordingStatus)
 * @property {Array<String>} genres List of genres
 * @property {String|Boolean} seriesId ID of the DvrSeries it belongs to (relevant for Dvr episodes only)
 * @property {String|Boolean} recordingId ID of the recording, provided only for Kaltura platform
 * @property {String} seriesName Name of the series, provided when it's a series episode
 * @property {String} [playbackUrl] Depending on the backend, the playback url can be retrieved as part of DVR Metadatas (rather than the get session)
 * @property {String} [playbackId] (Not available for all project) Id necessary for starting playback
 * @property {String} [chromecastPlaybackId] Chromecast Playback Url
 * @property {String} [chromecastPlaybackUrl] Chromecast Playback Id
 */
export class Dvr extends Program {
    channelId: string | number
    platformChannelId: string | number
    startTime: number
    endTime: number
    status: string
    titleId: string | number
    isDvrEpisode: boolean
    seriesId: string | boolean
    recordingId: string | boolean
    seriesName: string
    seasonNumber: string | number
    episodeNumber: string | number
    episodeTitle: string
    eventIds: (string | number)[]
    genres: string[]
    modelType: string
    playbackUrl: string
    isProtected: boolean
    playbackId?: string
    chromecastPlaybackId?: string
    chromecastPlaybackUrl?: string

    constructor(props: IDvr) {
        super(props)

        this.channelId = props.channelId
        this.platformChannelId = props.platformChannelId
        this.duration = props.duration
        this.startTime = props.startTime
        this.endTime = props.endTime
        this.status = props.status
        this.titleId = props.titleId
        this.isDvrEpisode = props.isDvrEpisode
        this.seriesId = props.seriesId
        /** @type {String|Number}
         * Provided only for Kaltura platform
         */
        this.recordingId = props.recordingId
        this.seriesName = props.seriesName
        this.playbackId = props.playbackId
        this.format = 'landscape'

        /**
         * Parental control rating id (provided by the proxy)
         * @type {String}
         */
        this.ratingId = props.ratingId
        this.seasonNumber = props.seasonNumber
        this.episodeNumber = props.episodeNumber
        this.episodeTitle = props.episodeTitle

        const computePropValue = this._makeComputePropValue(props)

        this.isProtected = computePropValue('isProtected', false)
        this.eventIds = computePropValue('eventIds', [])
        this.genres = computePropValue('genres', [])
        this.playbackUrl = computePropValue('playbackUrl', '')
        this.modelType = Constants.programType.dvr

        this.chromecastPlaybackId = props.chromecastPlaybackId
        this.chromecastPlaybackUrl = computePropValue('chromecastPlaybackUrl', '')
    }

    /**
     * @returns {Boolean} returns true
     */
    isDvr() {
        return true
    }

    /**
     * Indicates whether the recording is completed (event is in the past)
     * @returns {Boolean}
     */
    isCompleted() {
        return this.status === status.completed && this.endTime < Date.now()
    }

    /**
     * Indicates whether the recording is ongoing (event is currently broadcasting)
     * @returns {Boolean}
     */
    isOngoing() {
        return (
            (this.status === status.ongoing || this.status === status.scheduled) &&
            // We do not check the endTime because there's a ~10 minute lag before a "finished" ongoing DVR transitions to "completed".
            // During this transition, we consider the DVR to be ongoing
            this.startTime <= Date.now()
        )
    }

    /**
     * Indicates whether the recording is scheduled (event to be broadcast in the future)
     * @returns {Boolean}
     */
    isScheduled() {
        return (
            (this.status === status.ongoing || this.status === status.scheduled) &&
            this.startTime > Date.now()
        )
    }

    /**
     * Indicates whether the recording is available to watch
     * @returns {Boolean}
     */
    isWatchable() {
        return this.isCompleted() || this.isOngoing()
    }
}
