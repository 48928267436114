/**
 * Takes seconds as parameter and returns hours and minutes.
 *
 * @param   {?number} seconds
 *
 * @returns {object} hours and minutes or "null" if parameter is not defined
 */
function getHoursAndMinutes(seconds) {
    if (isNaN(seconds) || seconds === 0) return null

    const hours = Math.floor(seconds / 3600)

    return {
        hours,
        minutes: Math.floor((seconds - hours * 3600) / 60),
    }
}

export default getHoursAndMinutes
