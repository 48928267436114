import Constants from 'api-constants'
import config from 'services/proxy/config'
const { avOutput } = config

/**
 * Returns video format
 * @param {Object} format
 * @return {Constants.videoFormat}
 */
export const videoFormatFactory = (format) => {
    if (format & avOutput.V_4K) return Constants.videoFormat.V_4K
    if (format & avOutput.V_UHD) return Constants.videoFormat.V_UHD
    if (format & avOutput.V_HD) return Constants.videoFormat.V_HD
    if (format & avOutput.V_SD) return Constants.videoFormat.V_SD
    if (format & avOutput.V_ADAPTIVE) return Constants.videoFormat.V_ADAPTATIVE

    return Constants.videoFormat.V_UNKNOWN
}

/**
 * Returns video format
 * @param {Object} format
 * @return {Constants.videoFormat}
 */
export const audioFormatFactory = (format) => {
    if (format & avOutput.A_DOLBY) return Constants.audioFormat.A_DOLBY
    if (format & avOutput.A_STEREO) return Constants.audioFormat.A_STEREO
    if (format & avOutput.A_HD_7_1) return Constants.audioFormat.A_HD_7_1
    if (format & avOutput.A_HD_5_1) return Constants.audioFormat.A_HD_5_1
    if (format & avOutput.A_MONO) return Constants.audioFormat.A_MONO
    if (format & avOutput.A_DTS) return Constants.audioFormat.A_DTS

    return Constants.audioFormat.A_UNKNOWN
}
