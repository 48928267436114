import {
    LoginStatusV6,
    LoginStatusV2,
    LoginParametersV2,
    LoginParametersV6,
    LoginHeartbeat,
} from '../Models'

/**
 * Parse Login Response
 * @return {Object} data raw data from the backend
 * @return {LoginStatusV2}
 */
export const LoginV2StatusFactory = (data = {}) => {
    return new LoginStatusV2({
        ...data,
    })
}

/**
 * Parse Login Response
 * @return {Object} data raw data from the backend
 * @return {LoginStatusV6}
 */
export const LoginV6StatusFactory = (data = {}) => {
    return new LoginStatusV6({
        ...data,
    })
}

/**
 * Parse Login Parameters
 * @return {Object} data raw data from the backend
 * @return {LoginParametersV2}
 */
export const LoginV2ParametersFactory = (data = {}) => {
    return new LoginParametersV2({
        ...data,
    })
}

/**
 * Parse Login Parameters
 * @return {Object} data raw data from the backend
 * @return {LoginParametersV6}
 */
export const LoginV6ParametersFactory = (data = {}) => {
    return new LoginParametersV6({
        ...data,
    })
}

/**
 * Parse Login Heartbeat
 * @return {Object} data raw data from the backend
 * @return {LoginHeartbeat}
 */
export const LoginHeartbeatFactory = (data = {}) => {
    return new LoginHeartbeat({
        ...data,
    })
}
