import { Dvr } from 'models'
import HuaweiTypes from '../HuaweiTypes'
import { has } from 'lodash'
import { addImageResizingAttributes } from '../Helpers'

export const dvrFactory = (dvr = {}) => {
    if (!dvr) return undefined

    const picture = has(dvr, ['playbill', 'picture', 'posters'])
        ? dvr.playbill.picture.posters[0]
        : undefined

    let playbackId
    const dvrStreams = dvr.files || []
    if (dvrStreams.length > 0) {
        let myStream
        if (dvrStreams.length > 1) {
            // TODO: Handle definition per device / platform
            myStream = dvrStreams.find((x) => x.definition === HuaweiTypes.pvrDefinition._HD)
        }
        if (!myStream) {
            myStream = dvrStreams[0]
        }

        playbackId = myStream.fileID
    }

    return new Dvr({
        id: dvr.ID,
        recordingId: dvr.ID,
        platformChannelId: dvr.channelID,
        seriesId: dvr.seriesID,
        startTime: HuaweiTypes.parseInt(dvr.startTime),
        endTime: HuaweiTypes.parseInt(dvr.endTime),
        status: HuaweiTypes.PVRStatusMapping[dvr.status] || '',
        duration: HuaweiTypes.parseInt(dvr.duration * 1000), // Huawei backend stores this value in seconds, but milliseconds needed
        title: dvr.name,
        description: has(dvr, ['playbill', 'introduce']) ? dvr.playbill.introduce : undefined,
        thumbnail: addImageResizingAttributes(picture, 'thumbnail'),
        backdrop: addImageResizingAttributes(picture, 'backdrop'),
        playbackId,
        eventIds: [dvr.playbillID],
    })
}
