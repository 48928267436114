import { IReplayNode } from '@typings/node'
import { NavigationNode } from './NavigationNode'

/**
 * A ReplayNode is a {@link NavigationNode} found specifically in the Replay universe
 * @property {String|Number} channelId The id of the associated channel
 * @property {Number} rollingBufferDuration The duration in hours of the channel's rollingbuffer
 */
export class ReplayNode extends NavigationNode {
    channelId: string | number
    rollingBufferDuration: number

    constructor(props: IReplayNode) {
        super(props)

        this.channelId = props.channelId
        this.rollingBufferDuration = props.rollingBufferDuration
    }

    /** Checks if the Node is a ReplayNode (always `true`) */
    isReplayNode() {
        return true
    }
}
