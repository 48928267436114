import { isPlainObject } from 'lodash'
import { HashMap } from '@typings/generic'

/**
 * An Abstract class to rule them all !
 *
 * Offer the ability to track default property and a cleaver update() method to avoid
 * unwanted property reset.
 */
export class Abstract {
    _defaultedProperties: string[]

    constructor() {
        /**
         * Contains the properties name of props which their default
         * value were assign the "this".
         */
        this._defaultedProperties = []
    }

    /**
     * Returns an array of Property Name which must be merged during udate.
     *
     * Note: Only `Array` and `Object` values could be merged.
     *
     */
    getPropsToMerge(): string[] {
        // Default behavior: nothing to merge
        return []
    }

    /**
     * Default hasValueResolver used by the curried function of `_makeComputePropValue`.
     *
     * @param {*} propValue The property value
     * @param {String} propName The property name
     * @param {Object} props Hash of all properties we are dealing with to assign
     */
    _hasValue(propValue: any, propName: string, props: object): boolean {
        return propValue !== undefined
    }

    /**
     * Return a function which will be responsible to :
     * - Compute property value to return current value (if any) or return the default value
     * - Keep track of defaulted properties in internal instance attribute
     *
     * @todo Invoke provided `hasValueResolver` only if our `this._hasValue` return `true` (ie !== `undefined`)
     *
     * @param {Object} props Hash of props to deal with during assign
     * @return {(propName, defaultValue, hasValueResolver) => string} An assign function
     * @param {String} propName {String} - The property name to check
     * @param {*} defaultValue - The value to use if hasValueResolver return false
     * @param {(propValue: any, propName: string, props: object) => boolean} [hasValueResolver=this._hasValue] - Function to invoke to test if property has a value, it should never returns true if value is undefined
     *                                   since undefined are ALWAYS considered as not a value (cleaned before update)
     */
    _makeComputePropValue(
        props: HashMap<string, any>
    ): <T>(
        propName: string,
        defaultValue: any,
        hasValueResolver?: (propValue: any, propName: string, props: object) => boolean
    ) => T {
        return (propName, defaultValue, hasValueResolver = this._hasValue) => {
            const propValue = props?.[propName]
            if (hasValueResolver(propValue, propName, props)) {
                return propValue
            }

            this._defaultedProperties.push(propName)

            return defaultValue
        }
    }

    /**
     * Simple method which return the merge of `currentProps` and `newProps`.
     *
     * Useful when you need to override this simple logic in a child class.
     *
     */
    _mergePropsForUpdate(
        currentProps: HashMap<string, any>,
        newProps: HashMap<string, any>,
        bypassMerge: string[] = []
    ) {
        const propsToMerge = this.getPropsToMerge().filter(
            (propName) => !bypassMerge.includes(propName)
        )

        // No props to merge, let's simply merge the two hash of properties
        if (!propsToMerge.length) {
            return {
                ...currentProps,
                ...newProps,
            }
        }

        const mergedProps = Object.keys(newProps).reduce(
            (acc, propName) => {
                const propIsArray =
                    Array.isArray(currentProps[propName]) || Array.isArray(newProps[propName])
                const propIsObject =
                    isPlainObject(currentProps[propName]) || isPlainObject(newProps[propName])

                if (propsToMerge.includes(propName) && (propIsArray || propIsObject)) {
                    acc[propName] = propIsArray
                        ? [...(currentProps[propName] || []), ...(newProps[propName] || [])]
                        : {
                              ...(currentProps[propName] || {}),
                              ...(newProps[propName] || {}),
                          }
                } else {
                    acc[propName] = newProps[propName]
                }

                return acc
            },
            { ...currentProps }
        )

        return mergedProps
    }

    /**
     * Instantiate a new children model from current properties and provided `props`.
     *
     * Handle plain object `props` or instance of a children of {@link Abstract}
     *
     * Avoid unwanted property reset if provided instance of Model has property value which comes from default value.
     *
     * @example
     * class Model extends Abstract {
     *      // default this.color set to 'blue' using _makeComputePropValue helper
     * }
     * const model = new Model({ color: 'red' })
     * const otherModel = new Model() // ie: color === 'blue'
     * model.update(otherModel) // color kept : 'red', since 'blue' was the default
     *
     * @throws Error when provided `props` is an instance of another model than `this`
     *
     * @param {Object|Abstract} props A plain object (hash) or a child of {@link Abstract}
     * @param {Object} [options] Update options
     * @param {Array<String>} [options.passthroughProps=[]] Relevant only when props in an instance of the Abstract.
     *        Each contained props will NOT be cleared from new props, even if it is the default value
     * @param {Array<String>} [options.bypassMerge=[]] All contained props will not be merge, event if Model indicate that
     *        prop should be merged
     * @returns {Abstract} An instance of child of {@link Abstract}
     */
    update(
        props: HashMap<string, any>,
        {
            passthroughProps,
            bypassMerge,
        }: { passthroughProps?: string[]; bypassMerge?: string[] } = {
            passthroughProps: [],
            bypassMerge: [],
        }
    ) {
        // currentProps will probably muted, copy current props to avoid mutate original instance
        const currentProps = { ...this }

        // Filter out any provided props with value "undefined"
        const newProps = Object.keys(props).reduce((acc: HashMap<string, any>, propName) => {
            if (props[propName] !== undefined) {
                acc[propName] = props[propName]
            }
            return acc
        }, {})

        // Clear any current defaulted properties to force regeneration of _defaultedProperties to
        // ensure new instance will be up to date
        this._defaultedProperties.forEach((propName) => {
            delete (currentProps as any)[propName]
        })

        // If provided props is not a plain object but an instance of model
        if (props instanceof Abstract) {
            if (!(props instanceof this.constructor)) {
                console.warn('Abstract / update / error /  props: ', props)
                console.warn('Abstract /  update / error /  this: ', this)
                const e = new Error('update() invoked with another instance than current Model')
                console.warn('Abstract / update / error / stack: ', e.stack)
                throw e
            }
            // Browse defaultedProperty to reset them from provided Model
            // except for the one contained in `passthroughProps` argument
            props._defaultedProperties
                .filter((propName) => !passthroughProps?.includes(propName))
                .forEach((propName) => {
                    delete newProps[propName]
                })
        }

        const ctor = Object.getPrototypeOf(this).constructor

        return new ctor(this._mergePropsForUpdate(currentProps, newProps, bypassMerge))
    }
}
