export const canRecord = (program: any, channel: any, canWatch: any) => {
    return (
        program &&
        canWatch &&
        program.isLive?.() &&
        !program.isFiller?.() &&
        !program.isPastLive?.() &&
        channel?.isRecordable?.() &&
        program?.isRecordable?.()
    )
}