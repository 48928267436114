import { useI18n } from "../i18n"

export const getLanguageLabel = (language: string) => {
    if(!language) return 'Unknown'
    const { getI18n } = useI18n()

    const languages: any = {
        'en': 'English',
        'ar': 'العربية',
        'fr': 'Français',
        'es': 'Español',
        'he': 'עברית',
        'ru': 'русский',
        'tg': 'Filipino',
        'zh': '中文',
        'ko': '한국어',
        'id': 'Bahasa Indonesia',
        'ms': 'Melayu',
        'ta': 'தமிழ்',
        'au': 'Audio 1',
        'au1': 'Audio 1',
        'au2': 'Audio 2',
        'au3': 'Audio 3',
        'au4': 'Audio 4',
        'au5': 'Audio 5',
        'auq': 'Audio 1',
        'tx': 'Default',
        'tx1': 'Default 1',
        'tx2': 'Default 2',
        'sp': 'Spanish',
        'cz': 'Czech',
        'pt': 'Portuguese',
        'de': 'German',
        'sl': 'Slovenian',
        'cs': 'Czech',
        'bg': 'Bulgarian',
        'pl': 'Polish',
        'hu': 'Hungarian',
        'hbs-srp': 'Serbian',
        'hbs-hrv': 'Croatian',
        'tr': 'Turkish',
        'ro': 'Romanian',
        'nl': 'Dutch',
        'sv': 'Swedish',
        'da': 'Danish',
        'no': 'Norwegian',
        'ac3': 'Dolby',
        'dlb': 'Dolby',
        'und': 'Unknown',
        'aur': 'Audio 2',
        'zh-hans': '中文',
        'zh-hant': '中文',
        'cmn': '普通话',
        'yue': '广东话',
        'ja': '日本语',
        'none': getI18n()?.formatMessage({id: 'NONE'}),
    }

    return languages[language.toLowerCase()] ?? language
}