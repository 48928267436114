export default (props = {}) => <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"{...props}>
<g id="Player / Subtitles">
<g id="Vector">
<path d="M7.58317 14.0833C6.98486 14.0833 6.49984 14.5684 6.49984 15.1667C6.49984 15.765 6.98486 16.25 7.58317 16.25H8.6665C9.26481 16.25 9.74984 15.765 9.74984 15.1667C9.74984 14.5684 9.26481 14.0833 8.6665 14.0833H7.58317Z" fill="white"/>
<path d="M10.8332 15.1667C10.8332 14.5684 11.3182 14.0833 11.9165 14.0833H18.4165C19.0148 14.0833 19.4998 14.5684 19.4998 15.1667C19.4998 15.765 19.0148 16.25 18.4165 16.25H11.9165C11.3182 16.25 10.8332 15.765 10.8332 15.1667Z" fill="white"/>
<path d="M7.58317 10.8333C6.98486 10.8333 6.49984 11.3184 6.49984 11.9167C6.49984 12.515 6.98486 13 7.58317 13H12.9998C13.5981 13 14.0832 12.515 14.0832 11.9167C14.0832 11.3184 13.5981 10.8333 12.9998 10.8333H7.58317Z" fill="white"/>
<path d="M15.1665 11.9167C15.1665 11.3184 15.6515 10.8333 16.2498 10.8333H18.4165C19.0148 10.8333 19.4998 11.3184 19.4998 11.9167C19.4998 12.515 19.0148 13 18.4165 13H16.2498C15.6515 13 15.1665 12.515 15.1665 11.9167Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.58317 3.25C4.59163 3.25 2.1665 5.67512 2.1665 8.66667V15.1667C2.1665 18.1582 4.59163 20.5833 7.58317 20.5833V22.5256C7.58317 23.9734 9.33352 24.6984 10.3572 23.6747L13.4486 20.5833H18.4165C21.408 20.5833 23.8332 18.1582 23.8332 15.1667V8.66667C23.8332 5.67512 21.408 3.25 18.4165 3.25H7.58317ZM13.0006 18.4167H18.4165C20.2114 18.4167 21.6665 16.9616 21.6665 15.1667V8.66667C21.6665 6.87174 20.2114 5.41667 18.4165 5.41667H7.58317C5.78825 5.41667 4.33317 6.87174 4.33317 8.66667V15.1667C4.33317 16.9616 5.78825 18.4167 7.58317 18.4167H8.6665C9.26481 18.4167 9.74984 18.9017 9.74984 19.5V21.2179L12.2338 18.734C12.4453 18.5224 12.7233 18.4167 13.0006 18.4167C13.0008 18.4167 13.0003 18.4167 13.0006 18.4167Z" fill="white"/>
</g>
</g>
</svg>
