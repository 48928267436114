/**
 * @class Login parameters returned by Port/VSP/V3/Login
 */
export class LoginParametersV2 {
    constructor(props) {
        /**
         * @type {String}
         * Temp token. The value is required by the client to generate Authenticator.
         */
        this.enctytoken = props.enctytoken

        /**
         * @type {String}
         * Encryption type. The options are as follows:
         * • 0002: Huawei MD5
         * • 0003: 163MD5
         * • 0004: SHA256
         * The passwords transmitted between client and EPG server are encrypted by the algorithm.
         * For details about the algorithm, see section "14.1 Encryption Algorithms."
         */
        this.encryptiontype = props.encryptiontype

        /**
         * @type {String}
         * Hybrid Video platform code
         * The options are as follows:
         * • 01xx: carrier A
         * • 0101: carrier B
         * • 0102: carrier C
         * • 0200: Huawei
         * This parameter is reserved.
         */
        this.platformcode = props.platformcode

        /**
         * @type {String} EPG URL
         */
        this.epgurl = props.epgurl

        /**
         * @type {String} EPG URL
         */
        this.epghttpsurl = props.epghttpsurl
    }
}
