import HuaweiTypes from '../HuaweiTypes'

/**
 * @class Huawei Product
 */
export class HuaweiProduct {
    constructor(props) {
        /**
         * @type {String}
         * Product ID.
         */
        this.productID = props.ID
        this.ID = props.ID

        /**
         * @type {String}
         * Product Code.
         */
        this.productCode = props.productCode

        /**
         * @type {String}
         * Product name
         */
        this.name = props.name

        /**
         * @type {HuaweiTypes.productDurationType}
         *
         */
        this.productType = props.productType

        /**
         * @type {String}
         * Product description.
         */
        this.introduce = props.introduce

        /**
         * @type {String}
         * Indicates whether a product is subscribed to.
         */
        this.isSubscribed = HuaweiTypes.parseIBoolean(props.isSubscribed)

        /**
         * @type {String}
         * Indicates whether a product is an EST product.
         */
        this.isEst = HuaweiTypes.parseIBoolean(props.isEst)

        /**
         * @type {String}
         * Indicates whether content in a product can be downloaded. Currently,
         * only VOD content can be downloaded. If this parameter is not set, the value 1 is used.
         * This parameter is returned in the subscribable product list returned by the product query interface
         * and playback and download authentication interface.
         */
        this.isContentDownload = HuaweiTypes.parseIBoolean(props.isContentDownload)

        /**
         * @type {String}
         * 	Amount of money or number of bonus points used to subscribe to a product.
         * If servicePayType is set to CASH, this parameter indicates the amount of money for the subscription, in minimum currency unit.
         * If servicePaytype is set to POINTS, this parameter indicates the number of bonus points for the subscription.
         * When Huawei Billing is not connected and the price cannot be obtained, the value 0 is returned by default.
         */
        this.price = HuaweiTypes.parseInt(props.price)

        /**
         * @type {String}
         * Time when a subscription relationship takes effect. The value is the total number of milliseconds since 1970-01-01 00:00:00.
         */
        this.startTime = HuaweiTypes.parseInt(props.startTime)

        /**
         * @type {String}
         * Time when a subscription relationship expires. The value is the total number of milliseconds since 1970-01-01 00:00:00.
         */
        this.endTime = HuaweiTypes.parseInt(props.endTime)

        /**
         * @type {String}
         * Cycle length of a duration-based product.
         * For example, if chargeMode is set to 13 (indicating a multimonth package product) and periodLength is set to 2 for a product, the subscription cycle of the product is two months.
         * If chargeMode is set to 0, 18, 19 or 21, this parameter has a fixed value of 1.
         */
        this.periodLength = HuaweiTypes.parseInt(props.periodLength)

        /**
         * @type {String}
         * Rental period of a times-based product, in hours.
         */
        this.rentPeriod = HuaweiTypes.parseInt(props.rentPeriod)

        /**
         * @type {String}
         * Indicates whether product subscription can be automatically renewed.
         */
        this.isAutoExtend = HuaweiTypes.parseIBoolean(props.isAutoExtend)

        /**
         * @type {String}
         * Indicates whether a product can be subscribed to online.
         */
        this.isOnlinePurchase = HuaweiTypes.parseIBoolean(props.isOnlinePurchase)

        /**
         * @type {String}
         * Indicates whether a duration-based product is the main package.
         * A client can subscribe to main packages independently but must subscribe
         * to main packages before subscribing to non-main packages.
         */
        this.isMain = HuaweiTypes.parseIBoolean(props.isMain)

        /**
         * @type {String}
         * Indicates whether a product is an A La Carte product.
         */
        this.isAlacarte = HuaweiTypes.parseIBoolean(props.isAlacarte)

        /**
         * @type {String}
         * Indicates whether a product can be shared by the primary and secondary
         * subscribers who share subscriptions (in versions for sites in China).
         */
        this.isShareForAccount = HuaweiTypes.parseIBoolean(props.isShareForAccount)

        /**
         * @type {String}
         *
         */
        this.picture =
            (props.picture && props.picture.posters && props.picture.posters[0]) || undefined

        /**
         * @type {String}
         *
         */
        this.background =
            (props.picture &&
                ((props.picture.backgrounds && props.picture.backgrounds[0]) ||
                    (props.picture.background && props.picture.background[0]))) ||
            this.picture ||
            undefined

        /**
         * @type {HuaweiTypes.chargeMode}
         *
         */
        this.chargeMode = props.chargeMode

        /**
         * Key Pair Values
         * @type {Object}
         */
        this.customFields = (props.customFields || []).reduce((acc, customField) => {
            if (customField.key && customField.values) {
                acc[customField.key] = customField.values
            }
            return acc
        }, {})
    }
}
