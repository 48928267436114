export default {
    matchInfo: {
        id: 'b2sd9w5gzps3y89rid9cd8kd0',
        coverageLevel: '15',
        date: '2022-09-06Z',
        time: '19:00:00Z',
        localDate: '2022-09-06',
        localTime: '21:00:00',
        week: '1',
        numberOfPeriods: 2,
        periodLength: 45,
        lastUpdated: '2022-09-07T03:41:37Z',
        description: 'PSG vs Juventus',
        sport: {
            id: '289u5typ3vp4ifwh5thalohmq',
            name: 'Soccer',
        },
        ruleset: {
            id: '79plas4983031idr6vw83nuel',
            name: 'Men',
        },
        competition: {
            id: '4oogyu6o156iphvdvphwpck10',
            name: 'UEFA Champions League',
            competitionCode: 'UCL',
            competitionFormat: 'International cup',
            country: {
                id: '7yiwjcnhat6jo3f901h266cwl',
                name: 'Europe',
            },
        },
        tournamentCalendar: {
            id: '8t8ofk94zy6ksx1spnfecvpck',
            startDate: '2022-06-21Z',
            endDate: '2023-06-10Z',
            name: '2022/2023',
        },
        stage: {
            id: '8tug861ibh72j04p3ge06e80k',
            formatId: '86p6pz5rcsdbz4cklvfwtzrv9',
            startDate: '2022-09-06Z',
            endDate: '2022-11-02Z',
            name: 'Group Stage',
        },
        series: {
            id: '8u9nt1s77ssd5dp52cdumdwyc',
            formatId: 'cmb6lscxiyticnqnsarymfkr9',
            name: 'Group H',
        },
        contestant: [
            {
                id: '2b3mar72yy8d6uvat1ka6tn3r',
                name: 'PSG',
                shortName: 'PSG',
                officialName: 'Paris Saint-Germain FC',
                code: 'PSG',
                position: 'home',
                country: {
                    id: '7gww28djs405rfga69smki84o',
                    name: 'France',
                },
            },
            {
                id: 'bqbbqm98ud8obe45ds9ohgyrd',
                name: 'Juventus',
                shortName: 'Juventus',
                officialName: 'Juventus FC',
                code: 'JUV',
                position: 'away',
                country: {
                    id: '25f2cmb2r8mk5rj92tzer6kvv',
                    name: 'Italy',
                },
            },
        ],
        venue: {
            id: 'efcoydloex7y04q29pac9s67c',
            neutral: 'no',
            longName: 'Parc des Princes',
            shortName: 'Parc des Princes',
        },
    },
    liveData: {
        matchDetails: {
            periodId: 14,
            matchStatus: 'Played',
            winner: 'home',
            matchLengthMin: 95,
            matchLengthSec: 26,
            period: [
                {
                    id: 1,
                    start: '2022-09-06T19:00:27Z',
                    end: '2022-09-06T19:47:54Z',
                    lengthMin: 47,
                    lengthSec: 27,
                },
                {
                    id: 2,
                    start: '2022-09-06T20:03:45Z',
                    end: '2022-09-06T20:51:44Z',
                    lengthMin: 47,
                    lengthSec: 59,
                },
            ],
            scores: {
                ht: {
                    home: 2,
                    away: 0,
                },
                ft: {
                    home: 2,
                    away: 1,
                },
                total: {
                    home: 2,
                    away: 1,
                },
            },
        },
        event: [
            {
                id: 2456728707,
                eventId: 2,
                typeId: 32,
                periodId: 1,
                timeMin: 0,
                timeSec: 0,
                contestantId: 'bqbbqm98ud8obe45ds9ohgyrd',
                outcome: 1,
                x: 0.0,
                y: 0.0,
                timeStamp: '2022-09-06T19:00:27.660Z',
                lastModified: '2022-09-06T19:00:27Z',
                qualifier: [
                    {
                        id: 3762909333,
                        qualifierId: 127,
                        value: 'Left to Right',
                    },
                ],
            },
            {
                id: 2456728713,
                eventId: 2,
                typeId: 32,
                periodId: 1,
                timeMin: 0,
                timeSec: 0,
                contestantId: '2b3mar72yy8d6uvat1ka6tn3r',
                outcome: 1,
                x: 0.0,
                y: 0.0,
                timeStamp: '2022-09-06T19:00:27.660Z',
                lastModified: '2022-09-06T23:12:26Z',
                qualifier: [
                    {
                        id: 3762909387,
                        qualifierId: 127,
                        value: 'Right to Left',
                    },
                ],
            },
            {
                id: 2456732419,
                eventId: 93,
                typeId: 16,
                periodId: 1,
                timeMin: 4,
                timeSec: 57,
                contestantId: '2b3mar72yy8d6uvat1ka6tn3r',
                playerId: '5e9ilgrz3tzg9kd1gk3yvrahh',
                playerName: 'K. Mbappé',
                outcome: 1,
                x: 90.9,
                y: 67.2,
                timeStamp: '2022-09-06T19:05:25.222Z',
                lastModified: '2022-09-07T02:20:15Z',
                qualifier: [
                    {
                        id: 3762932747,
                        qualifierId: 328,
                    },
                    {
                        id: 3762932737,
                        qualifierId: 55,
                        value: '92',
                    },
                    {
                        id: 3762932741,
                        qualifierId: 102,
                        value: '47.1',
                    },
                    {
                        id: 3762932731,
                        qualifierId: 80,
                    },
                    {
                        id: 3762930471,
                        qualifierId: 375,
                        value: '04:56.690',
                    },
                    {
                        id: 3762930467,
                        qualifierId: 374,
                        value: '2022-09-06 20:05:24.350',
                    },
                    {
                        id: 3762932745,
                        qualifierId: 108,
                    },
                    {
                        id: 3762932735,
                        qualifierId: 154,
                    },
                    {
                        id: 3762930043,
                        qualifierId: 64,
                    },
                    {
                        id: 3762932753,
                        qualifierId: 231,
                        value: '54.3',
                    },
                    {
                        id: 3762930463,
                        qualifierId: 20,
                    },
                    {
                        id: 3762932751,
                        qualifierId: 230,
                        value: '98.2',
                    },
                    {
                        id: 3762930041,
                        qualifierId: 22,
                    },
                    {
                        id: 3763194247,
                        qualifierId: 396,
                        value: '46.3',
                    },
                    {
                        id: 3762932749,
                        qualifierId: 136,
                    },
                    {
                        id: 3763259985,
                        qualifierId: 178,
                    },
                    {
                        id: 3762932733,
                        qualifierId: 29,
                    },
                    {
                        id: 3762930045,
                        qualifierId: 56,
                        value: 'Center',
                    },
                    {
                        id: 3762932743,
                        qualifierId: 103,
                        value: '7.6',
                    },
                    {
                        id: 3763194245,
                        qualifierId: 395,
                        value: '2.9',
                    },
                ],
            },
            {
                id: 2456741901,
                eventId: 137,
                typeId: 15,
                periodId: 1,
                timeMin: 18,
                timeSec: 18,
                contestantId: 'bqbbqm98ud8obe45ds9ohgyrd',
                playerId: '35r56n0i4sbdh7n5arbt7db6d',
                playerName: 'A. Milik',
                outcome: 1,
                x: 93.6,
                y: 53.9,
                timeStamp: '2022-09-06T19:18:46.041Z',
                lastModified: '2022-09-07T02:17:42Z',
                qualifier: [
                    {
                        id: 3762982207,
                        qualifierId: 29,
                    },
                    {
                        id: 3762982221,
                        qualifierId: 147,
                        value: '49.2',
                    },
                    {
                        id: 3762982209,
                        qualifierId: 154,
                    },
                    {
                        id: 3762985347,
                        qualifierId: 214,
                    },
                    {
                        id: 3762982215,
                        qualifierId: 102,
                        value: '50.0',
                    },
                    {
                        id: 3762982003,
                        qualifierId: 233,
                        value: '220',
                    },
                    {
                        id: 3762981993,
                        qualifierId: 328,
                    },
                    {
                        id: 3762981981,
                        qualifierId: 15,
                    },
                    {
                        id: 3762982217,
                        qualifierId: 103,
                        value: '24.7',
                    },
                    {
                        id: 3762980947,
                        qualifierId: 22,
                    },
                    {
                        id: 3762980951,
                        qualifierId: 56,
                        value: 'Center',
                    },
                    {
                        id: 3762982205,
                        qualifierId: 79,
                    },
                    {
                        id: 3763258507,
                        qualifierId: 388,
                    },
                    {
                        id: 3762980949,
                        qualifierId: 17,
                    },
                    {
                        id: 3762982219,
                        qualifierId: 146,
                        value: '98.3',
                    },
                    {
                        id: 3762982211,
                        qualifierId: 55,
                        value: '135',
                    },
                ],
            },
            {
                id: 2456744169,
                eventId: 257,
                typeId: 16,
                periodId: 1,
                timeMin: 21,
                timeSec: 49,
                contestantId: '2b3mar72yy8d6uvat1ka6tn3r',
                playerId: '5e9ilgrz3tzg9kd1gk3yvrahh',
                playerName: 'K. Mbappé',
                outcome: 1,
                x: 85.9,
                y: 43.0,
                timeStamp: '2022-09-06T19:22:16.731Z',
                lastModified: '2022-09-07T02:16:58Z',
                qualifier: [
                    {
                        id: 3762994235,
                        qualifierId: 374,
                        value: '2022-09-06 20:22:16.131',
                    },
                    {
                        id: 3762996285,
                        qualifierId: 230,
                        value: '97.7',
                    },
                    {
                        id: 3762992897,
                        qualifierId: 22,
                    },
                    {
                        id: 3762996279,
                        qualifierId: 214,
                    },
                    {
                        id: 3762996287,
                        qualifierId: 231,
                        value: '47',
                    },
                    {
                        id: 3762994241,
                        qualifierId: 375,
                        value: '21:48.471',
                    },
                    {
                        id: 3762996269,
                        qualifierId: 154,
                    },
                    {
                        id: 3762996281,
                        qualifierId: 108,
                    },
                    {
                        id: 3762994229,
                        qualifierId: 20,
                    },
                    {
                        id: 3762996275,
                        qualifierId: 102,
                        value: '52.6',
                    },
                    {
                        id: 3763207257,
                        qualifierId: 396,
                        value: '51.8',
                    },
                    {
                        id: 3762996283,
                        qualifierId: 328,
                    },
                    {
                        id: 3762996265,
                        qualifierId: 76,
                    },
                    {
                        id: 3762996267,
                        qualifierId: 29,
                    },
                    {
                        id: 3762996271,
                        qualifierId: 55,
                        value: '256',
                    },
                    {
                        id: 3763263387,
                        qualifierId: 178,
                    },
                    {
                        id: 3762992901,
                        qualifierId: 56,
                        value: 'Center',
                    },
                    {
                        id: 3763207255,
                        qualifierId: 395,
                        value: '2.2',
                    },
                    {
                        id: 3762996277,
                        qualifierId: 103,
                        value: '5.1',
                    },
                    {
                        id: 3762992899,
                        qualifierId: 17,
                    },
                ],
            },
            {
                id: 2456745701,
                eventId: 175,
                typeId: 17,
                periodId: 1,
                timeMin: 24,
                timeSec: 41,
                contestantId: 'bqbbqm98ud8obe45ds9ohgyrd',
                playerId: 'd196vatyvq6ycn1q7d477d2i1',
                playerName: 'Bremer',
                outcome: 1,
                x: 0.0,
                y: 0.0,
                timeStamp: '2022-09-06T19:25:09.597Z',
                lastModified: '2022-09-07T00:04:58Z',
                qualifier: [
                    {
                        id: 3763000851,
                        qualifierId: 13,
                        value: '243',
                    },
                    {
                        id: 3763000841,
                        qualifierId: 31,
                    },
                    {
                        id: 3763000845,
                        qualifierId: 392,
                    },
                ],
            },
            {
                id: 2456745827,
                eventId: 276,
                typeId: 17,
                periodId: 1,
                timeMin: 24,
                timeSec: 55,
                contestantId: '2b3mar72yy8d6uvat1ka6tn3r',
                playerId: '9adbv1dl7dp7vze3b9sarerbp',
                playerName: 'Sergio Ramos',
                outcome: 1,
                x: 0.0,
                y: 0.0,
                timeStamp: '2022-09-06T19:25:23.527Z',
                lastModified: '2022-09-07T00:04:07Z',
                qualifier: [
                    {
                        id: 3763001463,
                        qualifierId: 31,
                    },
                    {
                        id: 3763001465,
                        qualifierId: 35,
                    },
                ],
            },
            {
                id: 2456753533,
                eventId: 317,
                typeId: 6,
                periodId: 1,
                timeMin: 41,
                timeSec: 35,
                contestantId: 'bqbbqm98ud8obe45ds9ohgyrd',
                playerId: '3nnjep6bxxb6xwhanvqvjt39x',
                playerName: 'L. Bonucci',
                outcome: 0,
                x: 1.9,
                y: 22.2,
                timeStamp: '2022-09-06T19:42:03.258Z',
                lastModified: '2022-09-07T00:10:42Z',
                qualifier: [
                    {
                        id: 3763041807,
                        qualifierId: 233,
                        value: '433',
                    },
                    {
                        id: 3763041765,
                        qualifierId: 73,
                    },
                    {
                        id: 3763041763,
                        qualifierId: 56,
                        value: 'Back',
                    },
                ],
            },
            {
                id: 2456753537,
                eventId: 433,
                typeId: 6,
                periodId: 1,
                timeMin: 41,
                timeSec: 35,
                contestantId: '2b3mar72yy8d6uvat1ka6tn3r',
                playerId: '5e9ilgrz3tzg9kd1gk3yvrahh',
                playerName: 'K. Mbappé',
                outcome: 1,
                x: 98.1,
                y: 77.8,
                timeStamp: '2022-09-06T19:42:03.258Z',
                lastModified: '2022-09-07T00:10:42Z',
                qualifier: [
                    {
                        id: 3763041803,
                        qualifierId: 233,
                        value: '317',
                    },
                    {
                        id: 3763041781,
                        qualifierId: 56,
                        value: 'Center',
                    },
                    {
                        id: 3763041783,
                        qualifierId: 73,
                    },
                ],
            },
            {
                id: 2456755105,
                eventId: 334,
                typeId: 17,
                periodId: 1,
                timeMin: 45,
                timeSec: 44,
                contestantId: 'bqbbqm98ud8obe45ds9ohgyrd',
                playerId: 'bjob8tftnxojnytcj7juo0ga2',
                playerName: 'F. Miretti',
                outcome: 1,
                x: 0.0,
                y: 0.0,
                timeStamp: '2022-09-06T19:46:11.907Z',
                lastModified: '2022-09-07T00:16:35Z',
                qualifier: [
                    {
                        id: 3763050289,
                        qualifierId: 392,
                    },
                    {
                        id: 3763050287,
                        qualifierId: 31,
                    },
                    {
                        id: 3763050291,
                        qualifierId: 13,
                        value: '243',
                    },
                ],
            },
            {
                id: 2456755497,
                eventId: 337,
                typeId: 30,
                periodId: 1,
                timeMin: 47,
                timeSec: 27,
                contestantId: 'bqbbqm98ud8obe45ds9ohgyrd',
                outcome: 1,
                x: 0.0,
                y: 0.0,
                timeStamp: '2022-09-06T19:47:54.667Z',
                lastModified: '2022-09-07T00:16:51Z',
                qualifier: [
                    {
                        id: 3763052195,
                        qualifierId: 57,
                        value: '0',
                    },
                ],
            },
            {
                id: 2456755499,
                eventId: 489,
                typeId: 30,
                periodId: 1,
                timeMin: 47,
                timeSec: 27,
                contestantId: '2b3mar72yy8d6uvat1ka6tn3r',
                outcome: 1,
                x: 0.0,
                y: 0.0,
                timeStamp: '2022-09-06T19:47:54.667Z',
                lastModified: '2022-09-07T00:16:51Z',
                qualifier: [
                    {
                        id: 3763052197,
                        qualifierId: 57,
                        value: '0',
                    },
                ],
            },
            {
                id: 2456759535,
                eventId: 338,
                typeId: 18,
                periodId: 2,
                timeMin: 45,
                timeSec: 0,
                contestantId: 'bqbbqm98ud8obe45ds9ohgyrd',
                playerId: 'bjob8tftnxojnytcj7juo0ga2',
                playerName: 'F. Miretti',
                outcome: 1,
                x: 0.0,
                y: 0.0,
                timeStamp: '2022-09-06T20:03:03.724Z',
                lastModified: '2022-09-06T20:03:04Z',
                qualifier: [
                    {
                        id: 3763072635,
                        qualifierId: 59,
                        value: '20',
                    },
                    {
                        id: 3763072631,
                        qualifierId: 42,
                    },
                    {
                        id: 3763072633,
                        qualifierId: 44,
                        value: 'Midfielder',
                    },
                    {
                        id: 3763072667,
                        qualifierId: 55,
                        value: '339',
                    },
                ],
            },
            {
                id: 2456759801,
                eventId: 340,
                typeId: 32,
                periodId: 2,
                timeMin: 45,
                timeSec: 0,
                contestantId: 'bqbbqm98ud8obe45ds9ohgyrd',
                outcome: 1,
                x: 0.0,
                y: 0.0,
                timeStamp: '2022-09-06T20:03:45.272Z',
                lastModified: '2022-09-06T20:03:46Z',
                qualifier: [
                    {
                        id: 3763074079,
                        qualifierId: 127,
                        value: 'Right to Left',
                    },
                ],
            },
            {
                id: 2456759795,
                eventId: 491,
                typeId: 32,
                periodId: 2,
                timeMin: 45,
                timeSec: 0,
                contestantId: '2b3mar72yy8d6uvat1ka6tn3r',
                outcome: 1,
                x: 0.0,
                y: 0.0,
                timeStamp: '2022-09-06T20:03:45.272Z',
                lastModified: '2022-09-06T23:12:56Z',
                qualifier: [
                    {
                        id: 3763074053,
                        qualifierId: 127,
                        value: 'Left to Right',
                    },
                ],
            },
            {
                id: 2456760287,
                eventId: 346,
                typeId: 15,
                periodId: 2,
                timeMin: 46,
                timeSec: 5,
                contestantId: 'bqbbqm98ud8obe45ds9ohgyrd',
                playerId: '555z9bynvux7oruirg67r8i51',
                playerName: 'F. Kostić',
                outcome: 1,
                x: 74.8,
                y: 61.7,
                timeStamp: '2022-09-06T20:04:51.079Z',
                lastModified: '2022-09-07T02:12:37Z',
                qualifier: [
                    {
                        id: 3763077205,
                        qualifierId: 72,
                    },
                    {
                        id: 3763077523,
                        qualifierId: 78,
                    },
                    {
                        id: 3763077531,
                        qualifierId: 108,
                    },
                    {
                        id: 3763076737,
                        qualifierId: 56,
                        value: 'Center',
                    },
                    {
                        id: 3763077525,
                        qualifierId: 102,
                        value: '48.3',
                    },
                    {
                        id: 3763077203,
                        qualifierId: 24,
                    },
                    {
                        id: 3763077537,
                        qualifierId: 147,
                        value: '51.4',
                    },
                    {
                        id: 3763077527,
                        qualifierId: 103,
                        value: '15.2',
                    },
                    {
                        id: 3763077535,
                        qualifierId: 146,
                        value: '98',
                    },
                    {
                        id: 3763077529,
                        qualifierId: 215,
                    },
                    {
                        id: 3763077533,
                        qualifierId: 458,
                    },
                    {
                        id: 3763077221,
                        qualifierId: 233,
                        value: '501',
                    },
                    {
                        id: 3763076735,
                        qualifierId: 18,
                    },
                    {
                        id: 3763258819,
                        qualifierId: 178,
                    },
                ],
            },
            {
                id: 2456761203,
                eventId: 533,
                typeId: 15,
                periodId: 2,
                timeMin: 48,
                timeSec: 0,
                contestantId: '2b3mar72yy8d6uvat1ka6tn3r',
                playerId: '779rrkp47akkd5usq53begatx',
                playerName: 'Neymar',
                outcome: 1,
                x: 86.2,
                y: 57.3,
                timeStamp: '2022-09-06T20:06:45.517Z',
                lastModified: '2022-09-07T02:12:07Z',
                qualifier: [
                    {
                        id: 3763082467,
                        qualifierId: 154,
                    },
                    {
                        id: 3763081797,
                        qualifierId: 56,
                        value: 'Center',
                    },
                    {
                        id: 3763081901,
                        qualifierId: 20,
                    },
                    {
                        id: 3763082477,
                        qualifierId: 146,
                        value: '97.1',
                    },
                    {
                        id: 3763082475,
                        qualifierId: 103,
                        value: '3.2',
                    },
                    {
                        id: 3763082465,
                        qualifierId: 29,
                    },
                    {
                        id: 3763081795,
                        qualifierId: 17,
                    },
                    {
                        id: 3763082479,
                        qualifierId: 147,
                        value: '50.6',
                    },
                    {
                        id: 3763081909,
                        qualifierId: 233,
                        value: '358',
                    },
                    {
                        id: 3763082469,
                        qualifierId: 55,
                        value: '532',
                    },
                    {
                        id: 3763260677,
                        qualifierId: 178,
                    },
                    {
                        id: 3763081793,
                        qualifierId: 22,
                    },
                    {
                        id: 3763082463,
                        qualifierId: 76,
                    },
                    {
                        id: 3763082473,
                        qualifierId: 102,
                        value: '53.1',
                    },
                ],
            },
            {
                id: 2456763341,
                eventId: 423,
                typeId: 6,
                periodId: 2,
                timeMin: 52,
                timeSec: 32,
                contestantId: 'bqbbqm98ud8obe45ds9ohgyrd',
                playerId: '555z9bynvux7oruirg67r8i51',
                playerName: 'F. Kostić',
                outcome: 1,
                x: 99.4,
                y: 69.4,
                timeStamp: '2022-09-06T20:11:17.762Z',
                lastModified: '2022-09-07T00:07:13Z',
                qualifier: [
                    {
                        id: 3763093593,
                        qualifierId: 56,
                        value: 'Center',
                    },
                    {
                        id: 3763093687,
                        qualifierId: 233,
                        value: '572',
                    },
                    {
                        id: 3763093595,
                        qualifierId: 73,
                    },
                ],
            },
            {
                id: 2456763351,
                eventId: 572,
                typeId: 6,
                periodId: 2,
                timeMin: 52,
                timeSec: 32,
                contestantId: '2b3mar72yy8d6uvat1ka6tn3r',
                playerId: '9adbv1dl7dp7vze3b9sarerbp',
                playerName: 'Sergio Ramos',
                outcome: 0,
                x: 0.6,
                y: 30.6,
                timeStamp: '2022-09-06T20:11:17.762Z',
                lastModified: '2022-09-07T00:07:12Z',
                qualifier: [
                    {
                        id: 3763093639,
                        qualifierId: 73,
                    },
                    {
                        id: 3763093733,
                        qualifierId: 233,
                        value: '423',
                    },
                    {
                        id: 3763093637,
                        qualifierId: 56,
                        value: 'Back',
                    },
                ],
            },
            {
                id: 2456763589,
                eventId: 427,
                typeId: 16,
                periodId: 2,
                timeMin: 52,
                timeSec: 55,
                contestantId: 'bqbbqm98ud8obe45ds9ohgyrd',
                playerId: '288nn7pbq5ewriiyb9o7da86h',
                playerName: 'W. McKennie',
                outcome: 1,
                x: 94.0,
                y: 44.1,
                timeStamp: '2022-09-06T20:11:41.063Z',
                lastModified: '2022-09-07T02:40:24Z',
                qualifier: [
                    {
                        id: 3763095605,
                        qualifierId: 374,
                        value: '2022-09-06 21:11:40.317',
                    },
                    {
                        id: 3763096843,
                        qualifierId: 103,
                        value: '8.2',
                    },
                    {
                        id: 3763188883,
                        qualifierId: 396,
                        value: '56',
                    },
                    {
                        id: 3763096833,
                        qualifierId: 29,
                    },
                    {
                        id: 3763094843,
                        qualifierId: 56,
                        value: 'Center',
                    },
                    {
                        id: 3763095597,
                        qualifierId: 25,
                    },
                    {
                        id: 3763096835,
                        qualifierId: 154,
                    },
                    {
                        id: 3763095603,
                        qualifierId: 328,
                    },
                    {
                        id: 3763094841,
                        qualifierId: 17,
                    },
                    {
                        id: 3763096845,
                        qualifierId: 230,
                        value: '95.3',
                    },
                    {
                        id: 3763268607,
                        qualifierId: 388,
                    },
                    {
                        id: 3763188881,
                        qualifierId: 395,
                        value: '4.7',
                    },
                    {
                        id: 3763096831,
                        qualifierId: 78,
                    },
                    {
                        id: 3763096841,
                        qualifierId: 102,
                        value: '49.1',
                    },
                    {
                        id: 3763095599,
                        qualifierId: 15,
                    },
                    {
                        id: 3763095609,
                        qualifierId: 375,
                        value: '52:55.045',
                    },
                    {
                        id: 3763096837,
                        qualifierId: 55,
                        value: '425',
                    },
                    {
                        id: 3763096847,
                        qualifierId: 231,
                        value: '45.3',
                    },
                ],
            },
            {
                id: 2456764689,
                eventId: 442,
                typeId: 15,
                periodId: 2,
                timeMin: 55,
                timeSec: 32,
                contestantId: 'bqbbqm98ud8obe45ds9ohgyrd',
                playerId: '3ua8gs8tzx1dkdi9z88kjk9nt',
                playerName: 'D. Vlahović',
                outcome: 1,
                x: 93.4,
                y: 44.0,
                timeStamp: '2022-09-06T20:14:17.642Z',
                lastModified: '2022-09-07T02:10:24Z',
                qualifier: [
                    {
                        id: 3763101049,
                        qualifierId: 22,
                    },
                    {
                        id: 3763103875,
                        qualifierId: 146,
                        value: '99',
                    },
                    {
                        id: 3763103655,
                        qualifierId: 328,
                    },
                    {
                        id: 3763103885,
                        qualifierId: 233,
                        value: '596',
                    },
                    {
                        id: 3763101051,
                        qualifierId: 17,
                    },
                    {
                        id: 3763103873,
                        qualifierId: 458,
                    },
                    {
                        id: 3763103871,
                        qualifierId: 103,
                        value: '7.6',
                    },
                    {
                        id: 3763101053,
                        qualifierId: 56,
                        value: 'Center',
                    },
                    {
                        id: 3763103867,
                        qualifierId: 78,
                    },
                    {
                        id: 3763103651,
                        qualifierId: 15,
                    },
                    {
                        id: 3763103869,
                        qualifierId: 102,
                        value: '51.3',
                    },
                    {
                        id: 3763103877,
                        qualifierId: 147,
                        value: '48.3',
                    },
                    {
                        id: 3763270879,
                        qualifierId: 388,
                    },
                ],
            },
            {
                id: 2456767605,
                eventId: 663,
                typeId: 15,
                periodId: 2,
                timeMin: 62,
                timeSec: 25,
                contestantId: '2b3mar72yy8d6uvat1ka6tn3r',
                playerId: '7fmhqt5geme4yv9cfnsp5lec5',
                playerName: 'M. Verratti',
                outcome: 1,
                x: 76.8,
                y: 55.8,
                timeStamp: '2022-09-06T20:21:10.637Z',
                lastModified: '2022-09-07T02:09:00Z',
                qualifier: [
                    {
                        id: 3763264807,
                        qualifierId: 178,
                    },
                    {
                        id: 3763118169,
                        qualifierId: 147,
                        value: '54.5',
                    },
                    {
                        id: 3763118153,
                        qualifierId: 29,
                    },
                    {
                        id: 3763117957,
                        qualifierId: 20,
                    },
                    {
                        id: 3763117479,
                        qualifierId: 56,
                        value: 'Center',
                    },
                    {
                        id: 3763118163,
                        qualifierId: 103,
                        value: '19',
                    },
                    {
                        id: 3763117955,
                        qualifierId: 24,
                    },
                    {
                        id: 3763117961,
                        qualifierId: 82,
                    },
                    {
                        id: 3763118173,
                        qualifierId: 231,
                        value: '49.3',
                    },
                    {
                        id: 3763118171,
                        qualifierId: 230,
                        value: '98.1',
                    },
                    {
                        id: 3763118167,
                        qualifierId: 146,
                        value: '77.3',
                    },
                    {
                        id: 3763117973,
                        qualifierId: 233,
                        value: '503',
                    },
                    {
                        id: 3763118161,
                        qualifierId: 102,
                        value: '52.1',
                    },
                    {
                        id: 3763127375,
                        qualifierId: 76,
                    },
                    {
                        id: 3763118157,
                        qualifierId: 55,
                        value: '662',
                    },
                    {
                        id: 3763118165,
                        qualifierId: 215,
                    },
                    {
                        id: 3763117477,
                        qualifierId: 18,
                    },
                ],
            },
            {
                id: 2456768343,
                eventId: 684,
                typeId: 17,
                periodId: 2,
                timeMin: 64,
                timeSec: 7,
                contestantId: '2b3mar72yy8d6uvat1ka6tn3r',
                playerId: '7fmhqt5geme4yv9cfnsp5lec5',
                playerName: 'M. Verratti',
                outcome: 1,
                x: 0.0,
                y: 0.0,
                timeStamp: '2022-09-06T20:22:52.870Z',
                lastModified: '2022-09-07T00:07:35Z',
                qualifier: [
                    {
                        id: 3763121741,
                        qualifierId: 13,
                        value: '243',
                    },
                    {
                        id: 3763121739,
                        qualifierId: 393,
                    },
                    {
                        id: 3763121737,
                        qualifierId: 31,
                    },
                ],
            },
            {
                id: 2456769729,
                eventId: 528,
                typeId: 18,
                periodId: 2,
                timeMin: 67,
                timeSec: 26,
                contestantId: 'bqbbqm98ud8obe45ds9ohgyrd',
                playerId: '35r56n0i4sbdh7n5arbt7db6d',
                playerName: 'A. Milik',
                outcome: 1,
                x: 0.0,
                y: 0.0,
                timeStamp: '2022-09-06T20:26:12.233Z',
                lastModified: '2022-09-07T03:27:56Z',
                qualifier: [
                    {
                        id: 3763129653,
                        qualifierId: 44,
                        value: 'Forward',
                    },
                    {
                        id: 3763129655,
                        qualifierId: 59,
                        value: '14',
                    },
                    {
                        id: 3763129683,
                        qualifierId: 55,
                        value: '529',
                    },
                    {
                        id: 3763129651,
                        qualifierId: 42,
                    },
                ],
            },
            {
                id: 2456771359,
                eventId: 790,
                typeId: 15,
                periodId: 2,
                timeMin: 71,
                timeSec: 32,
                contestantId: '2b3mar72yy8d6uvat1ka6tn3r',
                playerId: 'c5ryhn04g9goikd0blmh83aol',
                playerName: 'L. Messi',
                outcome: 1,
                x: 79.4,
                y: 62.3,
                timeStamp: '2022-09-06T20:30:17.446Z',
                lastModified: '2022-09-07T02:06:42Z',
                qualifier: [
                    {
                        id: 3763289187,
                        qualifierId: 178,
                    },
                    {
                        id: 3763139271,
                        qualifierId: 29,
                    },
                    {
                        id: 3763138659,
                        qualifierId: 22,
                    },
                    {
                        id: 3763138663,
                        qualifierId: 56,
                        value: 'Center',
                    },
                    {
                        id: 3763139275,
                        qualifierId: 55,
                        value: '789',
                    },
                    {
                        id: 3763139283,
                        qualifierId: 215,
                    },
                    {
                        id: 3763139287,
                        qualifierId: 147,
                        value: '51.4',
                    },
                    {
                        id: 3763139019,
                        qualifierId: 72,
                    },
                    {
                        id: 3763139279,
                        qualifierId: 102,
                        value: '52.3',
                    },
                    {
                        id: 3763139281,
                        qualifierId: 103,
                        value: '10.8',
                    },
                    {
                        id: 3763139269,
                        qualifierId: 76,
                    },
                    {
                        id: 3763138661,
                        qualifierId: 18,
                    },
                    {
                        id: 3763139273,
                        qualifierId: 154,
                    },
                    {
                        id: 3763139285,
                        qualifierId: 146,
                        value: '96.6',
                    },
                    {
                        id: 3763139615,
                        qualifierId: 233,
                        value: '576',
                    },
                    {
                        id: 3763142299,
                        qualifierId: 133,
                    },
                ],
            },
            {
                id: 2456771801,
                eventId: 587,
                typeId: 17,
                periodId: 2,
                timeMin: 72,
                timeSec: 30,
                contestantId: 'bqbbqm98ud8obe45ds9ohgyrd',
                playerId: '8yv9xuzriaecvvssfm8dlmz85',
                playerName: 'Danilo',
                outcome: 1,
                x: 0.0,
                y: 0.0,
                timeStamp: '2022-09-06T20:31:15.721Z',
                lastModified: '2022-09-07T00:29:35Z',
                qualifier: [
                    {
                        id: 3763141067,
                        qualifierId: 13,
                        value: '243',
                    },
                    {
                        id: 3763141065,
                        qualifierId: 393,
                    },
                    {
                        id: 3763141063,
                        qualifierId: 31,
                    },
                ],
            },
            {
                id: 2456772069,
                eventId: 588,
                typeId: 18,
                periodId: 2,
                timeMin: 73,
                timeSec: 5,
                contestantId: 'bqbbqm98ud8obe45ds9ohgyrd',
                playerId: 'pconfrmsdy9aikioqz9s9lg5',
                playerName: 'J. Cuadrado',
                outcome: 1,
                x: 0.0,
                y: 0.0,
                timeStamp: '2022-09-06T20:31:51.042Z',
                lastModified: '2022-09-07T03:28:21Z',
                qualifier: [
                    {
                        id: 3763142579,
                        qualifierId: 42,
                    },
                    {
                        id: 3763142633,
                        qualifierId: 55,
                        value: '589',
                    },
                    {
                        id: 3763142583,
                        qualifierId: 59,
                        value: '11',
                    },
                    {
                        id: 3763142581,
                        qualifierId: 44,
                        value: 'Midfielder',
                    },
                ],
            },
            {
                id: 2456773495,
                eventId: 823,
                typeId: 18,
                periodId: 2,
                timeMin: 77,
                timeSec: 20,
                contestantId: '2b3mar72yy8d6uvat1ka6tn3r',
                playerId: '1vr896w03yky9kmu8x5tjkicp',
                playerName: 'A. Hakimi',
                outcome: 1,
                x: 0.0,
                y: 0.0,
                timeStamp: '2022-09-06T20:36:05.409Z',
                lastModified: '2022-09-06T23:56:13Z',
                qualifier: [
                    {
                        id: 3763150517,
                        qualifierId: 42,
                    },
                    {
                        id: 3763150521,
                        qualifierId: 59,
                        value: '2',
                    },
                    {
                        id: 3763150519,
                        qualifierId: 44,
                        value: 'Defender',
                    },
                    {
                        id: 3763150569,
                        qualifierId: 55,
                        value: '824',
                    },
                ],
            },
            {
                id: 2456773607,
                eventId: 825,
                typeId: 18,
                periodId: 2,
                timeMin: 77,
                timeSec: 32,
                contestantId: '2b3mar72yy8d6uvat1ka6tn3r',
                playerId: 'cionsifzpx5rhc8es29wv6i95',
                playerName: 'Vitinha',
                outcome: 1,
                x: 0.0,
                y: 0.0,
                timeStamp: '2022-09-06T20:36:17.595Z',
                lastModified: '2022-09-06T23:56:17Z',
                qualifier: [
                    {
                        id: 3763151125,
                        qualifierId: 59,
                        value: '17',
                    },
                    {
                        id: 3763151121,
                        qualifierId: 42,
                    },
                    {
                        id: 3763151149,
                        qualifierId: 55,
                        value: '826',
                    },
                    {
                        id: 3763151123,
                        qualifierId: 44,
                        value: 'Midfielder',
                    },
                ],
            },
            {
                id: 2456774619,
                eventId: 647,
                typeId: 15,
                periodId: 2,
                timeMin: 80,
                timeSec: 40,
                contestantId: 'bqbbqm98ud8obe45ds9ohgyrd',
                playerId: 'ahz2npxe4geixkaz25f2pc82d',
                playerName: 'M. Locatelli',
                outcome: 1,
                x: 93.1,
                y: 60.8,
                timeStamp: '2022-09-06T20:39:25.796Z',
                lastModified: '2022-09-07T02:05:00Z',
                qualifier: [
                    {
                        id: 3763157735,
                        qualifierId: 233,
                        value: '854',
                    },
                    {
                        id: 3763157931,
                        qualifierId: 231,
                        value: '54',
                    },
                    {
                        id: 3763157919,
                        qualifierId: 102,
                        value: '46.8',
                    },
                    {
                        id: 3763157925,
                        qualifierId: 146,
                        value: '95.6',
                    },
                    {
                        id: 3763157707,
                        qualifierId: 20,
                    },
                    {
                        id: 3763156657,
                        qualifierId: 56,
                        value: 'Center',
                    },
                    {
                        id: 3763157927,
                        qualifierId: 147,
                        value: '52.0',
                    },
                    {
                        id: 3763156655,
                        qualifierId: 17,
                    },
                    {
                        id: 3763157921,
                        qualifierId: 103,
                        value: '19',
                    },
                    {
                        id: 3763157913,
                        qualifierId: 154,
                    },
                    {
                        id: 3763264057,
                        qualifierId: 178,
                    },
                    {
                        id: 3763157911,
                        qualifierId: 29,
                    },
                    {
                        id: 3763157923,
                        qualifierId: 100,
                    },
                    {
                        id: 3763157909,
                        qualifierId: 80,
                    },
                    {
                        id: 3763157915,
                        qualifierId: 55,
                        value: '646',
                    },
                    {
                        id: 3763157717,
                        qualifierId: 82,
                    },
                    {
                        id: 3763156653,
                        qualifierId: 22,
                    },
                    {
                        id: 3763157929,
                        qualifierId: 230,
                        value: '97.7',
                    },
                ],
            },
            {
                id: 2456775437,
                eventId: 885,
                typeId: 18,
                periodId: 2,
                timeMin: 83,
                timeSec: 47,
                contestantId: '2b3mar72yy8d6uvat1ka6tn3r',
                playerId: 'c5ryhn04g9goikd0blmh83aol',
                playerName: 'L. Messi',
                outcome: 1,
                x: 0.0,
                y: 0.0,
                timeStamp: '2022-09-06T20:42:32.785Z',
                lastModified: '2022-09-07T00:07:18Z',
                qualifier: [
                    {
                        id: 3763161337,
                        qualifierId: 59,
                        value: '30',
                    },
                    {
                        id: 3763161333,
                        qualifierId: 42,
                    },
                    {
                        id: 3763161335,
                        qualifierId: 44,
                        value: 'Forward',
                    },
                    {
                        id: 3763161361,
                        qualifierId: 55,
                        value: '886',
                    },
                ],
            },
            {
                id: 2456776145,
                eventId: 911,
                typeId: 18,
                periodId: 2,
                timeMin: 86,
                timeSec: 34,
                contestantId: '2b3mar72yy8d6uvat1ka6tn3r',
                playerId: '7fmhqt5geme4yv9cfnsp5lec5',
                playerName: 'M. Verratti',
                outcome: 1,
                x: 0.0,
                y: 0.0,
                timeStamp: '2022-09-06T20:45:19.655Z',
                lastModified: '2022-09-07T00:10:06Z',
                qualifier: [
                    {
                        id: 3763165221,
                        qualifierId: 55,
                        value: '912',
                    },
                    {
                        id: 3763165201,
                        qualifierId: 59,
                        value: '6',
                    },
                    {
                        id: 3763165199,
                        qualifierId: 44,
                        value: 'Midfielder',
                    },
                    {
                        id: 3763165197,
                        qualifierId: 42,
                    },
                ],
            },
            {
                id: 2456776213,
                eventId: 689,
                typeId: 18,
                periodId: 2,
                timeMin: 86,
                timeSec: 54,
                contestantId: 'bqbbqm98ud8obe45ds9ohgyrd',
                playerId: '2nddcty9qris2mmeldon0ogd1',
                playerName: 'A. Rabiot',
                outcome: 1,
                x: 0.0,
                y: 0.0,
                timeStamp: '2022-09-06T20:45:39.775Z',
                lastModified: '2022-09-07T00:10:11Z',
                qualifier: [
                    {
                        id: 3763165765,
                        qualifierId: 55,
                        value: '690',
                    },
                    {
                        id: 3763165715,
                        qualifierId: 44,
                        value: 'Midfielder',
                    },
                    {
                        id: 3763165713,
                        qualifierId: 42,
                    },
                    {
                        id: 3763165717,
                        qualifierId: 59,
                        value: '25',
                    },
                ],
            },
            {
                id: 2456776753,
                eventId: 929,
                typeId: 15,
                periodId: 2,
                timeMin: 88,
                timeSec: 48,
                contestantId: '2b3mar72yy8d6uvat1ka6tn3r',
                playerId: '779rrkp47akkd5usq53begatx',
                playerName: 'Neymar',
                outcome: 1,
                x: 87.0,
                y: 46.6,
                timeStamp: '2022-09-06T20:47:34.226Z',
                lastModified: '2022-09-07T02:03:20Z',
                qualifier: [
                    {
                        id: 3763169337,
                        qualifierId: 458,
                    },
                    {
                        id: 3763169401,
                        qualifierId: 233,
                        value: '724',
                    },
                    {
                        id: 3763277839,
                        qualifierId: 178,
                    },
                    {
                        id: 3763168961,
                        qualifierId: 17,
                    },
                    {
                        id: 3763169331,
                        qualifierId: 102,
                        value: '50.3',
                    },
                    {
                        id: 3763169339,
                        qualifierId: 146,
                        value: '98.4',
                    },
                    {
                        id: 3763169217,
                        qualifierId: 20,
                    },
                    {
                        id: 3763169341,
                        qualifierId: 147,
                        value: '47.9',
                    },
                    {
                        id: 3763168959,
                        qualifierId: 22,
                    },
                    {
                        id: 3763169329,
                        qualifierId: 78,
                    },
                    {
                        id: 3763169333,
                        qualifierId: 103,
                        value: '5.1',
                    },
                    {
                        id: 3763168963,
                        qualifierId: 56,
                        value: 'Center',
                    },
                    {
                        id: 3763169335,
                        qualifierId: 108,
                    },
                    {
                        id: 3763170801,
                        qualifierId: 328,
                    },
                ],
            },
            {
                id: 2456776977,
                eventId: 937,
                typeId: 15,
                periodId: 2,
                timeMin: 89,
                timeSec: 40,
                contestantId: '2b3mar72yy8d6uvat1ka6tn3r',
                playerId: '5e9ilgrz3tzg9kd1gk3yvrahh',
                playerName: 'K. Mbappé',
                outcome: 1,
                x: 88.2,
                y: 67.8,
                timeStamp: '2022-09-06T20:48:25.877Z',
                lastModified: '2022-09-07T02:02:59Z',
                qualifier: [
                    {
                        id: 3763170581,
                        qualifierId: 23,
                    },
                    {
                        id: 3763170595,
                        qualifierId: 103,
                        value: '19.6',
                    },
                    {
                        id: 3763170587,
                        qualifierId: 154,
                    },
                    {
                        id: 3763170601,
                        qualifierId: 147,
                        value: '53.6',
                    },
                    {
                        id: 3763170585,
                        qualifierId: 29,
                    },
                    {
                        id: 3763170597,
                        qualifierId: 214,
                    },
                    {
                        id: 3763170331,
                        qualifierId: 64,
                    },
                    {
                        id: 3763170333,
                        qualifierId: 56,
                        value: 'Center',
                    },
                    {
                        id: 3763170593,
                        qualifierId: 102,
                        value: '49.2',
                    },
                    {
                        id: 3763170589,
                        qualifierId: 55,
                        value: '936',
                    },
                    {
                        id: 3763170425,
                        qualifierId: 20,
                    },
                    {
                        id: 3763170609,
                        qualifierId: 233,
                        value: '738',
                    },
                    {
                        id: 3763281789,
                        qualifierId: 178,
                    },
                    {
                        id: 3763170583,
                        qualifierId: 78,
                    },
                    {
                        id: 3763170599,
                        qualifierId: 146,
                        value: '96',
                    },
                ],
            },
            {
                id: 2456777747,
                eventId: 771,
                typeId: 30,
                periodId: 2,
                timeMin: 92,
                timeSec: 58,
                contestantId: 'bqbbqm98ud8obe45ds9ohgyrd',
                outcome: 1,
                x: 0.0,
                y: 0.0,
                timeStamp: '2022-09-06T20:51:44.045Z',
                lastModified: '2022-09-07T00:24:10Z',
                qualifier: [
                    {
                        id: 3763174559,
                        qualifierId: 209,
                    },
                    {
                        id: 3763174557,
                        qualifierId: 57,
                        value: '0',
                    },
                ],
            },
            {
                id: 2456777745,
                eventId: 957,
                typeId: 30,
                periodId: 2,
                timeMin: 92,
                timeSec: 58,
                contestantId: '2b3mar72yy8d6uvat1ka6tn3r',
                outcome: 1,
                x: 0.0,
                y: 0.0,
                timeStamp: '2022-09-06T20:51:44.045Z',
                lastModified: '2022-09-07T00:24:10Z',
                qualifier: [
                    {
                        id: 3763174553,
                        qualifierId: 57,
                        value: '0',
                    },
                    {
                        id: 3763174555,
                        qualifierId: 209,
                    },
                ],
            },
            {
                id: 2456777759,
                eventId: 958,
                typeId: 30,
                periodId: 14,
                timeMin: 0,
                timeSec: 0,
                contestantId: '2b3mar72yy8d6uvat1ka6tn3r',
                outcome: 1,
                x: 0.0,
                y: 0.0,
                timeStamp: '2022-09-06T20:52:05.592Z',
                lastModified: '2022-09-06T20:52:06Z',
                qualifier: [
                    {
                        id: 3763174719,
                        qualifierId: 57,
                        value: '1',
                    },
                    {
                        id: 3763174721,
                        qualifierId: 209,
                    },
                ],
            },
            {
                id: 2456777763,
                eventId: 772,
                typeId: 30,
                periodId: 14,
                timeMin: 0,
                timeSec: 0,
                contestantId: 'bqbbqm98ud8obe45ds9ohgyrd',
                outcome: 1,
                x: 0.0,
                y: 0.0,
                timeStamp: '2022-09-06T20:52:05.817Z',
                lastModified: '2022-09-06T20:52:06Z',
                qualifier: [
                    {
                        id: 3763174727,
                        qualifierId: 57,
                        value: '1',
                    },
                    {
                        id: 3763174729,
                        qualifierId: 209,
                    },
                ],
            },
        ],
    },
}
