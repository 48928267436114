import toIsoDateTimeString from './toIsoDateTimeString'

/**
 * See documentation for toIsoDateTimeString
 *
 * @param {String|Number|Date} input
 * @param {Boolean} [utc=false]
 * @return {String|false}
 */
const toIsoDateString = (input, utc = false) => {
    const datetime = toIsoDateTimeString(input, utc)

    return datetime && datetime.split('T')[0]
}

export default toIsoDateString
