import { Program } from './Program'
import { EmiRequest } from './EmiRequest'
import Constants from 'api-constants'
import { IVod, OptaDetails } from '@typings/tile'
import { SubscriptionDetail } from 'models/SubscriptionDetail'

export class Vod extends Program {
    year: number
    genre: string
    contentUri: string
    trailer: string
    youtubeTrailer: string
    purchaseType: string
    sourceId: string
    providerLogo: string
    optaDetails: OptaDetails
    subscriptionIds: string[]
    subscriptionDetails: SubscriptionDetail[]
    dataRequests: EmiRequest[]
    proxyNodes: object
    requestIds: object
    producers: string[]
    altSourceID: string
    command: string
    param: string
    sidecarSubtitles: []
    vodType: string
    modelType: string
    studioDisplay: string
    startDate?: number
    endDate?: number
    nodesPlatformIds?: string[]

    constructor(props: IVod) {
        super(props)

        this.year = props.year
        this.genre = props.genre

        this.contentUri = props.contentUri
        this.trailer = props.trailer
        this.youtubeTrailer = props.youtubeTrailer
        this.purchaseType = props.purchaseType

        this.sourceId = props.sourceId

        this.startDate = props.startDate
        this.endDate = props.endDate

        /** url of the provider logo */
        this.providerLogo = props.providerLogo

        const computePropValue = this._makeComputePropValue(props)

        this.optaDetails = computePropValue('optaDetails', {})
        this.subscriptionIds = computePropValue('subscriptionIds', [])

        this.subscriptionDetails = computePropValue('subscriptionDetails', [])

        // TODO remove this.dataRequests and adapt volt-tv
        this.dataRequests = computePropValue('dataRequests', [
            new EmiRequest({
                value: 'get_favourite_status',
                args: [this?.id?.toString()],
                id: 'get_favourite_status_' + this.id,
                labels: { default: '' },
            }),
        ])

        this.proxyNodes = computePropValue('proxyNodes', {})
        this.requestIds = computePropValue('requestIds', {})
        this.producers = computePropValue('producers', [])
        this.altSourceID = props.altSourceID
        this.format = 'portrait'

        this.command = props.command
        this.param = props.param

        this.sidecarSubtitles = computePropValue('sidecarSubtitles', [])

        this.modelType = Constants.programType.vod
        this.vodType = Constants.programType.movie
        this.studioDisplay = computePropValue('studioDisplay', '')

        this.nodesPlatformIds = props.nodesPlatformIds
    }

    // Is the VOD related to a game
    isGame() {
        return (
            !!this.optaDetails &&
            (!!this.optaDetails.optaFixtureId ||
                !!this.optaDetails.optaSport ||
                !!this.optaDetails.optaTeamId)
        )
    }

    isVod() {
        return true
    }

    hasContentUri() {
        return !!this.contentUri
    }

    hasTrailer() {
        return !!this.trailer
    }

    hasYoutubeTrailer() {
        return !!this.youtubeTrailer
    }

    isDeeplink() {
        return !!this.command || !!this.param
    }

    getSubscriptionDetail(subscriptionId: string) {
        return this.subscriptionDetails.find((elt) => elt && elt.subscriptionId === subscriptionId)
    }
}
