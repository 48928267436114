import { IVirtualContent } from '@typings/tile'
import { Tile } from './Tile'

/**
 * @typedef {Object} VirtualContentProps
 * @property {String} type define content type (application, vod  ...)
 * @property {String} thumbnail image
 * @property {String} shortDescription short description
 * @property {String} action indicates action to be used to run this content
 * @property {String} param extra data used to run action
 * @property {String} channelId associated channel
 */

/**
 * Class VirtualContent description
 * This class is used to describe content to be associated to a virtual channel. Those content does not have any duration or startTime, they
 * can be seen has place holders to be used with channel
 */
export class VirtualContent extends Tile {
    type: string
    shortDescription: string
    command: string
    param: string
    channelId: string

    constructor(props: IVirtualContent) {
        super(props)

        this.type = props.type
        this.shortDescription = props.shortDescription
        this.command = props.action || props.command
        this.param = props.param
        this.channelId = props.channelId
    }

    isVirtualContent() {
        return true
    }

    /**
     * In order to pass some certification, application need to be launch directly
     * As such, UIs need to consider it an app or a deeplink -> virtual content can launch the player in some cases
     */
    isDeeplink() {
        return !!this.command || !!this.param
    }
}
