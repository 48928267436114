import { Subscription, TVODProduct, Offer } from 'models'
import { HuaweiSubscribedProduct, HuaweiProduct } from '../Models'
import ConfigHelper from 'framework/helpers/config'
import HuaweiTypes from '../HuaweiTypes'
import { addImageResizingAttributes } from '../Helpers'
import Constants from 'api-constants'

/**
 * Parse Huawei Subscriptions
 * @return {Object} data raw data from the backend
 * @return {HuaweiSubscribedProduct}
 */
export const huaweiSubscribedProductsFactory = (data = {}) => {
    return new HuaweiSubscribedProduct({
        ...data,
    })
}

/**
 * Parse Huawei Products
 * @return {Object} data raw data from the backend
 * @return {HuaweiProduct}
 */
export const huaweiProductsFactory = (data = {}) => {
    return new HuaweiProduct({
        ...data,
    })
}

/**
 * Parse Login Heartbeat
 * @return {HuaweiProduct} data raw data from the backend
 * @return {Subscription|TVODProduct}
 */
export const productsFactory = (huaweiProduct = {}) => {
    const {
        productID,
        name,
        introduce,
        price,
        startTime,
        endTime,
        picture,
        background,
        periodLength,
        rentPeriod,
        chargeMode,
        isSubscribed,
        productType,
        productCode,
        isEst,
        customFields,
        isOnlinePurchase,
    } = huaweiProduct
    const isTimeBasedProduct = productType === HuaweiTypes.productDurationType.TIME_BASED
    const { defaultCurrency = 'QAR', nextGenCurrency = 'Credit' } =
        ConfigHelper.getInstance().getConfig('huawei')
    let periodUnit = Offer.UNIT.HOUR
    if (isTimeBasedProduct) {
        periodUnit = Offer.UNIT.HOUR
    } else {
        switch (chargeMode) {
            case HuaweiTypes.chargeMode.monthly:
                periodUnit = Offer.UNIT.MONTH
                break
            case HuaweiTypes.chargeMode.multimonth:
                periodUnit = Offer.UNIT.MONTH
                break
            case HuaweiTypes.chargeMode.multiday:
                periodUnit = Offer.UNIT.DAY
                break
            case HuaweiTypes.chargeMode.daily:
                periodUnit = Offer.UNIT.DAY
                break
            case HuaweiTypes.chargeMode.weekly:
                periodUnit = Offer.UNIT.WEEK
                break
            case HuaweiTypes.chargeMode.multiweek:
                periodUnit = Offer.UNIT.WEEK
                break
            case HuaweiTypes.chargeMode.yearly:
                periodUnit = Offer.UNIT.YEAR
                break
            default:
                periodUnit = Offer.UNIT.MONTH
                break
        }
    }
    const isNextGenProduct = HuaweiTypes.isNewGenProduct(customFields)

    const currency = isTimeBasedProduct || !isNextGenProduct ? defaultCurrency : nextGenCurrency
    const _price = price / 100 // Price is in penny
    const props = {
        id: productID,
        programId: productID,
        name: name,
        title: name,
        description: introduce,
        currency: currency,
        validityStart: startTime,
        validityEnd: endTime,
        isAvailable: true,
        thumbnail: addImageResizingAttributes(picture, 'thumbnail'),
        backdrop: addImageResizingAttributes(background, 'backdrop'),
        category:
            (customFields[HuaweiTypes.ooredooProductCustomFields.GENRE] &&
                customFields[HuaweiTypes.ooredooProductCustomFields.GENRE].join(',')) ||
            '',
        offers: [
            new Offer({
                offerId: productCode,
                title: name,
                isPermanent: isTimeBasedProduct ? isEst : false,
                offerType: productType,
                periodUnit: periodUnit,
                cycles: 0,
                period: isTimeBasedProduct ? rentPeriod : periodLength,
                price: _price,
                originalPrice: _price,
                currency: currency,
            }),
        ],
        isEntitled: isSubscribed,
        // ---------------------------------------
        // -- The entitlemend end date is defined by updateProductsEntitlements
        // ---------------------------------------
        entitlementStart: isSubscribed ? startTime : undefined,
        entitlementEnd: isSubscribed ? endTime : undefined,
        displayable: !!isOnlinePurchase,
    }

    if (isTimeBasedProduct) {
        return new TVODProduct({
            ...props,
            /**
             * Ooredoo Specific to be moved outside later
             * VOD Rently is eligible only on B2B, not B2B2C/B2B
             */
            purchaseRestrictionByBusiness: [
                Constants.businessType.B2B2C,
                Constants.businessType.B2B,
            ],
        })
    } else {
        return new Subscription({
            ...props,
            productGeneration: isNextGenProduct
                ? Subscription.PRODUCT_GENERATION.NEW_GENERATION
                : Subscription.PRODUCT_GENERATION.LEGACY,
            /**
             * Ooredoo Specific to be moved outside later
             * Pack purchase is NOT eligible for B2B2C/B2B
             * Pack purchase is eligible for B2C for NEW_GENERATION packages
             */
            purchaseRestrictionByBusiness: [
                isNextGenProduct ? undefined : Constants.businessType.B2C,
                Constants.businessType.B2B2C,
                Constants.businessType.B2B,
            ].filter(Boolean),
        })
    }
}

/**
 * Update entitlements into Products
 * @return {Object} data raw data from the backend
 * @param {Array<TVODProduct|Subscription>} products  TVOD Products or Subscriptions
 * @param {Array<TVODProduct|Subscription>} Products updated with entitlements
 */
export const updateProductsEntitlements = (products = [], userEntitlements = []) => {
    // To reduce computation complexity, create an intermediary object
    const _userEntitlements = (userEntitlements || []).reduce((acc, entitlement) => {
        if (entitlement && entitlement.productID) {
            acc[entitlement.productID] = entitlement
        }
        return acc
    }, {})

    const productsWithEntitlements = (products || []).reduce((acc, product) => {
        if (product && product.id) {
            let props = {
                /**
                 * As entitlement state is returned also by Product API, try to keep the same value to handle the case where entitlement API does not work
                 */
                isEntitled: product.isEntitled || false,
            }
            if (_userEntitlements[product.id]) {
                const { orderState, startTime, endTime, productOrderKey, transactionalContentId } =
                    _userEntitlements[product.id]
                props = {
                    isEntitled: HuaweiTypes.orderState.subscribed === orderState,
                    entitlementStart: startTime,
                    entitlementEnd: endTime,
                    transactionId: productOrderKey,
                    // Only for TVOD
                    programId: transactionalContentId,
                }
            }
            acc.push(product.update(props))
        }

        return acc
    }, [])

    return productsWithEntitlements
}

export const hasEntitledProducts = (products) => {
    return (products || []).find((x) => x && x.isEntitled) !== undefined
}
