import {createEffect, on, onCleanup} from 'solid-js'
import {createStore, reconcile} from 'solid-js/store'
import ReduxContext from './ReduxContext'
import {getStore} from '../appStore'
import {useConfig} from '../../components'

export interface StoreOptions {
    configureStoreFactory: () => any
    voltApi: any
}

export interface ReduxProviderProps extends StoreOptions {
    children: any
}

export const ReduxProvider = (props: ReduxProviderProps) => {
    const config = useConfig()

    const _store = getStore(props.configureStoreFactory, config.getConfig(), props.voltApi)

    const {subscribe, getState} = _store
    const [state, setState] = createStore(getState())
    const unsubscribe = subscribe(() => {
        setState(reconcile(getState()))
    })
    onCleanup(() => unsubscribe())

    createEffect(
        on(
            () => _store,
            () => {
                setState(getState())
            }
        )
    )

    return (
        <ReduxContext.Provider
            value={{
                store: _store,
                state,
            }}
        >
            {props.children}
        </ReduxContext.Provider>
    )
}
