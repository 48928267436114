import { KJUR } from 'jsrsasign'
import isEmpty from 'lodash/isEmpty'
import has from 'lodash/has'
import { JwtHeader, JwtPayload } from '@typings/jwt'

/**
 * A class for generating JWT (JSON Web Token) with a given data
 * @class
 */
export default class Jwt {
    header: JwtHeader
    payload: JwtPayload
    privateKey: string

    /**
     * Class constructor
     * @param {JwtHeader} header - object with parameters for JWT header
     * @param {JwtPayload} payload - object with JWT claims
     * @param {String} privateKey - RSA private key
     */
    constructor(header: JwtHeader, payload: JwtPayload, privateKey: string) {
        this.header = header
        this.payload = payload
        this.privateKey = privateKey
    }

    /** Generate, sign and return a JWT */
    generateJwt() {
        if (!this._isValidJwtHeader() || !this._isValidJwtPayload()) return ''
        return KJUR.jws.JWS.sign(this.header.alg, this.header, this.payload, this.privateKey)
    }

    /** Check if header is valid. Header should not be empty or undefined.  Header should consist of alg and typ not empty or undefined keys.
     */
    private _isValidJwtHeader() {
        if (isEmpty(this.header) || !this.header) return false
        const isHeaderHasAlg = has(this.header, 'alg') && !isEmpty(this.header.alg)
        const isHeaderHasTyp = has(this.header, 'typ') && !isEmpty(this.header.typ)
        return isHeaderHasAlg && isHeaderHasTyp
    }

    /**
     * Check if payload is valid.
     * Payload should not be empty or undefined
     * @returns {Boolean}
     */
    private _isValidJwtPayload() {
        return !isEmpty(this.payload) || !this.payload
    }
}
