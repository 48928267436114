import { IPlaybackAttributes } from '../..'
import { Abstract } from './Abstract'
import { Channel } from './Channel'

/**
 * @class PlaybackAttributes
 * @property {String} url playback url
 * @property {Object} drm This object contains the licenseUrl and the headers
 * @property {'Widevine'|'Fairplay'|'Playready'|'Other'|'Unknown'} drmType @link PlaybackAttributes.DRM_TYPE
 * @property {String} drmProvider Could be native or external DRM provider (example: mediacorp)
 * @property {String} [assetId] Asset ID
 * @property {String} [programId] Program ID
 * @property {Number} keepAlive Duration in milliseconds for keeping session alive
 * @property {String} sessionId Session ID
 * @property {String} sessionIdEtag Session ID Etag (Used by Vubiquity Backend)
 * @property {Boolean} isTimeshiftedLive Sometine the backend does not support Timeshift from Live Manifest and requires to restart a LIVE Timeshifted session
 * @property {Boolean} isRollingBuffer
 * @property {Boolean} isStartOver
 * @property {Boolean} isTrailer
 * @property {Number} offset
 * @property {String} resolution
 * @property {String} gpsPosition GPS possition needed by vubiquity
 * @property {Object} subtitles Sidecar subtiltes
 * @property {String} network Needed by some backend for tracking (Mediacorp)
 * @property {String} trackingId Needed by some backend for tracking (Mediacorp)
 * @property {String} trackingSegment Needed by some backend for tracking (Mediacorp)
 * @property {String} backdropUrl
 */

export class PlaybackAttributes extends Abstract {
    url: string
    drm: object
    drmType: string
    drmProvider: string
    assetId?: string
    programId?: string
    sessionId: string
    keepAlive?: number
    sessionIdEtag?: string
    isRollingBuffer?: boolean
    isStartOver?: boolean
    isTrailer?: boolean
    offset: number
    resolution?: string
    gpsPosition?: string
    subtitles?: object
    network?: string
    trackingId?: string
    trackingSegment?: string
    backdropUrl?: string
    isTimeshiftedLive?: boolean

    constructor(props: IPlaybackAttributes) {
        super()
        this.url = props.url
        this.drm = props.drm
        this.drmType = props.drmType || PlaybackAttributes.DRM_TYPE.UNKNOWN
        this.drmProvider = props.drmProvider || Channel.DRM_PROVIDER.NATIVE
        this.assetId = props.assetId
        this.programId = props.programId
        this.sessionId = props.sessionId
        this.keepAlive = props.keepAlive
        this.sessionIdEtag = props.sessionIdEtag
        this.isTimeshiftedLive = props.isTimeshiftedLive
        this.isRollingBuffer = props.isRollingBuffer
        this.isStartOver = props.isStartOver
        this.isTrailer = props.isTrailer
        this.offset = props.offset || 0
        this.resolution = props.resolution
        this.gpsPosition = props.gpsPosition
        this.subtitles = props.subtitles
        this.network = props.network
        this.trackingId = props.trackingId
        this.trackingSegment = props.trackingSegment
        this.backdropUrl = props.backdropUrl
    }

    static DRM_TYPE = {
        WIDEVINE: 'Widevine',
        FAIRPLAY: 'Fairplay',
        PLAYREADY: 'Playready',
        OTHER: 'Other',
        UNKNOWN: 'Unknown',
    }
}
