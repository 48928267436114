/**
 * Takes a timestamp as parameter and returns his start time.
 *
 * @param   {?number} timestamp or Date.now() timestamp value by default
 *
 * @returns {number} start time timestamp
 */
function getStartTime(timestamp = new Date()) {
    const date = new Date(timestamp)

    return new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        0,
        0,
        0,
    ).getTime()
}

export default getStartTime
