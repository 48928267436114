import HuaweiTypes from '../HuaweiTypes'

/**
 * @class Login Heartbeat returned by Port/VSP/V3/OnLineHeartbeat
 */
export class LoginHeartbeat {
    constructor(props) {
        /**
         * @type {String}
         * Indicates whether a subscriber is valid. The options are as follows:
         * true: yes
         * false: no
         */
        this.userValid = props.userValid

        /**
         * @type {String}
         * Heartbeat interval, in seconds.
         * A client must invoke the heartbeat interface at this interval. If the client invokes the interface at a higher frequency,
         * the VSP performance will be severely affected.
         * The default value is 900, that is, 15 minutes.
         * If the VSP does not receive the heartbeat messages from the client for two consecutive intervals,
         * it regards that the subscriber is offline and releases the session.
         */
        this.nextCallInterval = HuaweiTypes.parseInt(props.nextCallInterval)
        this.nextCallIntervalMs = this.nextCallInterval * 1000

        /**
         * @type {String}
         * Token of the current login subscriber, which is automatically generated after the subscriber is authenticated in the VSP. The token automatically updates upon expiration.
         */
        this.userToken = props.userToken

        /**
         * @type {Object}
         * Version number of personalized data.
         */
        this.personalDataVersions = props.personalDataVersions

        /**
         * @type {String}
         * External ID of a subscriber area.
         */
        this.areaCode = props.areaCode

        /**
         * @type {String}
         * Subscriber group ID.
         */
        this.userGroup = props.userGroup

        /**
         * @type {String}
         * Subscriber ID.
         */
        this.subscriberID = props.subscriberID

        /**
         * @type {HuaweiTypes.opt}
         * Indicates whether a subscriber allows the VSP to collect the subscriber's behavior data.
         * The options are as follows:
         * 0: opt in
         * 1: opt out
         * NOTE:This parameter is not recommended. You are advised to use the QueryPrivacyStatement and SignPrivacyStatement interfaces
         * to query and set the privacy protection policy for subscriber data collection.
         */
        this.opt = HuaweiTypes.parseInt(props.opt)

        /**
         * @type {String}
         * Latest update time of the EPG template. The value is the total number of milliseconds since 1970-01-01 00:00:00.
         */
        this.templateTimeStamp = props.templateTimeStamp

        /**
         * @type {Boolean}
         * Indicates whether to support real-time collection of subscribers' page view tracks.
         * The options are as follows:
         * 0: no
         * 1: yes
         */
        this.isSupportedUserLogCollect = HuaweiTypes.parseIBoolean(props.isSupportedUserLogCollect)

        /**
         * @type {String}
         * Channel list filtering criterion, which is a hash value containing the following information: regional node where a subscriber belongs,
         * language, area, group, domain, channel namespace, channel version number, device type, content provider, and device capabilities
         * such as the content frame rate.
         * The EPG server generates personalized subscriber information for filtering the channel list and returns the information to the client,
         * and the client caches information during subscriber login. This parameter helps improve server performance when the channel list
         * query interface of the EPG server is invoked.
         * This parameter is returned upon successful authentication.
         */
        this.userFilter = props.userFilter

        /**
         * @type {String}
         * 	VOD list filtering criterion, which is a hash value containing the following information: regional node where a subscriber belongs,
         * language, area, group, domain, parental control level, content provider, and device capabilities such as the content frame rate.
         * The EPG server generates personalized subscriber information for filtering the VOD list and returns the information to the client,
         * and the client caches information during subscriber login. This parameter helps improve server performance when the VOD list
         * query interface of the EPG server is invoked.
         * This parameter is returned upon successful authentication.
         */
        this.userVODListFilter = props.userVODListFilter

        /**
         * @type {String}
         * VOD details filtering criterion, which is a hash value containing the following information: regional node where a subscriber belongs,
         * language, area, group, domain, parental control level, content provider, and device capabilities such as the content frame rate.
         * The EPG server generates personalized subscriber information for filtering VOD details and returns the information to the client,
         * and the client caches information during the subscriber login. This parameter helps improve server performance when the VOD details query
         * interface of the EPG server is invoked.
         * This parameter is returned upon successful authentication.
         */
        this.userVODDetailFilter = props.userVODDetailFilter

        /**
         * @type {String}
         * 	Program list filtering criterion, which is a hash value containing the following information: regional node where a subscriber belongs,
         * language, area, group, domain, channel namespace, parental control level, device type, content provider, and device capabilities
         * such as the content frame rate.
         * The EPG server generates personalized subscriber information for filtering the program list and returns the information to the client,
         * and the client caches information during subscriber login. This parameter helps improve server performance when the program list
         * query interface of the EPG server is invoked.
         * This parameter is returned upon successful authentication.
         */
        this.userPlaybillListFilter = props.userPlaybillListFilter

        /**
         * @type {String}
         * Program details filtering criterion, which is a hash value containing the following information: regional node where a subscriber belongs, language, area, group, domain, channel namespace, parental control level, device type, content provider, and device capabilities such as the content frame rate.
         * The EPG server generates personalized subscriber information for filtering program details and returns the information to the client, and the client caches information during subscriber login. This parameter helps improve server performance when the program details query interface of the EPG server is invoked.
         * This parameter is returned upon successful authentication.
         */
        this.userPlaybillDetailFilter = props.userPlaybillDetailFilter

        /**
         * @type {Boolean}
         * Indicates whether privacy statements need to be signed.
         * The options are as follows:
         * 0: no
         * 1: yes
         */
        this.needSignPrivacyStatement = HuaweiTypes.parseIBoolean(props.needSignPrivacyStatement)
    }
}
