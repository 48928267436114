export const getSlot = (startTimeProp: string, endTimeProp: string) => {
    const startTime = formatTimestamp(startTimeProp)
    const endTime = formatTimestamp(endTimeProp)

    return `${startTime} - ${endTime}`;
}

const formatTimestamp = (timestamp: string) => {
    const date = new Date(timestamp)
    let hours = date.getHours()
    let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()

    let newformat = hours >= 12 ? 'pm' : 'am'
    hours = hours % 12
    hours = hours ? hours : 12
    return hours + ':' + minutes + newformat
}