import { IProgramDetails } from '@typings/tile'
import { Abstract } from './Abstract'

/**
 * @class ProgramDetails
 * @property {Object} cast - Casting for a given program
 * @property {Object} producers - producers for a given program
 * @property {Object} directors - directors for a given program
 */

export class ProgramDetails extends Abstract {
    cast: object
    producers: object
    directors: object

    constructor(props: IProgramDetails) {
        super()
        const computePropValue = this._makeComputePropValue(props)
        this.cast = computePropValue('cast', [])
        this.producers = computePropValue('producers', [])
        this.directors = computePropValue('directors', [])
    }
}
