import GatewayApi from './GatewayApi'
import OoredooPurchaseApi from './PurchaseApi'
import OoredooAuthApi from './AuthApi'

// Huawei service APIs
import ChannelApi from '../huawei/ChannelApi'
import DeviceApi from '../huawei/DeviceApi'
import FavouritesApi from '../huawei/FavouritesApi'
import ImageApi from '../huawei/ImageApi'
import PasswordApi from '../huawei/PasswordApi'
import PlayerApi from '../huawei/PlayerApi'
import ProgramApi from '../huawei/ProgramApi'
import RecordingsApi from '../huawei/RecordingsApi'
import SettingsApi from '../huawei/SettingsApi'
import UserPreferencesApi from '../huawei/UserPreferencesApi'
import ConfigurationApi from '../huawei/ConfigurationApi'

export default class OoredooApi {
    constructor(config, otherApis) {
        this.deviceApi = new DeviceApi(config, otherApis)

        // --------- Ooredoo APIGEE methods ---------
        this.gatewayApi = new GatewayApi(config, otherApis)
        this.purchaseApi = new OoredooPurchaseApi(config, {
            ...otherApis,
            gatewayApi: this.gatewayApi,
        })
        this.authApi = new OoredooAuthApi(config, {
            ...otherApis,
            deviceApi: this.deviceApi,
            gatewayApi: this.gatewayApi,
        })
        // -------------------------------------------

        this.passwordApi = new PasswordApi(config)
        this.userPreferencesApi = new UserPreferencesApi(config)
        this.recordingsApi = new RecordingsApi(config, otherApis)

        this.configurationApi = new ConfigurationApi(config)

        this.channelApi = new ChannelApi(config, {
            ...otherApis,
            userPreferencesApi: this.userPreferencesApi,
        })
        this.programApi = new ProgramApi(config, {
            ...otherApis,
            userPreferencesApi: this.userPreferencesApi,
        })
        this.favouritesApi = new FavouritesApi(config, {
            ...otherApis,
            userPreferencesApi: this.userPreferencesApi,
        })
        this.settingsApi = new SettingsApi(config, { ...otherApis, passwordApi: this.passwordApi })

        this.playerApi = new PlayerApi(config, {
            ...otherApis,
            authApi: this.authApi,
            programApi: this.programApi,
            recordingsApi: this.recordingsApi,
        })
        this.imageApi = new ImageApi(config)
    }
}
