import {createEffect, createSignal, on, onMount, Show} from 'solid-js'
import {authenticate, getTvServices, loginTvService, saveOauthData, setLocale} from '@ifs/volt-store/lib/actions'
import {Spinner, useConfig} from '../components'
import {AuthorizationError} from '@openid/appauth'
import {profileSelector, useDispatch, useSelector} from '../store'
import {AuthorizationHandler, AuthorizationType, AuthorizerOptions} from './authorizationHandler'
import {useNavigate} from '@solidjs/router'
import {SubscriptionSelector} from '../components/subscription-selector/SubscriptionSelector'

//TODO: TO be injected correctly based on the client
import * as voltAPI from '@ifs/volt-api/build/ifeelsmart.bundle.js'
import {getDeviceData} from '../utils'

export const AuthRedirect = () => {
    const {getConfig} = useConfig()
    const profile = useSelector(profileSelector)
    const authenticationStatus = useSelector((s: any) => s.auth.authenticationStatus)
    const services = useSelector((s: any) => s.auth.tvServices)

    const navigate = useNavigate()

    const authConfig: AuthorizerOptions = {
        allowGuest: getConfig().allowGuest,
        clientId: getConfig().clientId,
        clientSecret: getConfig().clientSecret,
        redirectUri: window.location.origin + '/oauthredirect',
        authorizationType: AuthorizationType.REDIRECT,
        responseType: AuthorizationType.TOKEN,
        grant_type: getConfig().grant_type,
        scope: '',
        authorityConfiguration: {
            authorization_endpoint: getConfig().urls.authUrl + getConfig().OAuthParam?.endpoint?.auth,
            token_endpoint: getConfig().OAuthParam?.endpoint?.fullTokenUrl ? getConfig().OAuthParam?.endpoint?.fullTokenUrl : getConfig().urls.authUrl + getConfig().OAuthParam?.endpoint?.token,
            revocation_endpoint: '',
            registration_endpoint: '',
        },
    }

    const _authzHandler = new AuthorizationHandler(authConfig)

    const dispatch = useDispatch()

    const isAuthPending = useSelector((state: {auth: {isPending: any}}) => state.auth.isPending)

    createEffect(() => {
        if (authenticationStatus() === voltAPI.Constants.authenticationStatus.SUCCEEDED_REQUIRE_LOGIN_TV_SERVICE) {
            dispatch(getTvServices())
        }
    })

    createEffect(
        on(
            () => services(),
            () => {
                if (
                    services() &&
                    authenticationStatus() === voltAPI.Constants.authenticationStatus.SUCCEEDED_REQUIRE_LOGIN_TV_SERVICE
                ) {
                    if (services().length === 1) {
                        dispatch(loginTvService({serviceId: services()[0].serviceId}))
                    }
                }
            }
        )
    )

    const [success, setSuccess] = createSignal(false)
    const [errorMessage, setErrorMessage] = createSignal('')

    createEffect(
        on(
            () => profile(),
            () => {
                if (profile()) {
                    navigate(_authzHandler?.getCbRedirectUrl?.() || 'home')
                }
            }
        )
    )

    onMount(async () => {
        _authzHandler.onSuccessTokenResponse = async tokenResponse => {
            if (tokenResponse) {
                dispatch(
                    saveOauthData({
                        token: tokenResponse.accessToken,
                        refreshToken: tokenResponse.refreshToken ? tokenResponse.refreshToken : undefined,
                        expirationDate: tokenResponse.issuedAt + (tokenResponse?.expiresIn || 0),
                    })
                )

                dispatch(
                    authenticate({
                        oauthToken: tokenResponse.accessToken,
                        oauthTokenExpirationDate: tokenResponse.issuedAt + (tokenResponse?.expiresIn || 0),
                        oauthRefreshToken: tokenResponse.refreshToken ? tokenResponse.refreshToken : undefined,
                        deviceData: getDeviceData(),
                    })
                )
            }
        }
        _authzHandler.onFailureTokenResponse = async (error: AuthorizationError | null) => {}

        _authzHandler.checkForAuthorizationResponse()
    })

    return (
        <div style={{display: 'flex', 'justify-content': 'center', height: '100%', 'align-items': 'center'}}>
            <Show
                when={
                    services()?.length > 1 &&
                    authenticationStatus() === voltAPI.Constants.authenticationStatus.SUCCEEDED_REQUIRE_LOGIN_TV_SERVICE
                }
            >
                <SubscriptionSelector services={services()} />
            </Show>
            <Show when={isAuthPending()} fallback={!success() && <div>{errorMessage()}</div>}>
                <Spinner />
            </Show>
        </div>
    )
}
