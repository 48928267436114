import GenericFetch from 'framework/genericfetch'
import { of, throwError } from 'rxjs'
import { mergeMap } from 'rxjs/operators'
import { ERROR_BACKEND_MAPPING } from './errors'

/**
 * Fetching API class.
 */
export default class Fetch extends GenericFetch {
    constructor(config, otherApis = {}) {
        super(config, 'ooredooapigee')
        this.registerErrors(ERROR_BACKEND_MAPPING)
    }

    /**
     * Fetching Method call with ajax API. Output of the function is then automatically parsed
     * @override
     *
     * @param {Object} options
     * @param {String} options.url
     * @param {Object} options.body : HTTP URL
     * @param {Object} options.headers : HTTP Header
     * @param {String} options.method : GET, PUT, POST, DELETE, HEAD, OPTIONS
     * @param {Number} options.timeout
     * @param {String} [options.log=''] Extra message for Debug
     * @param {Boolean} [options.retrieveResponseHeaders=false] False by default to same CPU as not always needed
     * @param {VoltError} [options.defaultError=undefined] Default error to return when UNKNOWN_API_ERROR is thrown
     * @returns { response, totalCount }
     */
    fetch(args) {
        let headers = args.headers ? args.headers : GenericFetch.defaultHeaders

        return super
            .fetch({
                ...args,
                headers,
                retrieveResponseHeaders: args.retrieveResponseHeaders,
            })
            .pipe(
                mergeMap(({ response, url, log, method, headers: responseHeaders } = {}) => {
                    if (this.isHttp2xxErrorResponse(response)) {
                        const error = this._parseError(response)

                        // This is used to extract Phone number for some use cases
                        error.setHttpResponseBody(response)

                        this.logger.error(`[${log}] HttpRequestRaw [ERROR]:`, {
                            url: this._filterUrlParams(url),
                            method,
                            error,
                        })
                        return throwError(error)
                    }
                    return of({ response, headers: responseHeaders })
                })
            )
    }

    /**
     * Error extraction. Inherited from fetch
     * @override
     *
     * @param {Object} response
     * @returns {Object} error object
     */
    extractError(response = {}) {
        if (response.StatusMsg) {
            return {
                error: response.StatusMsg,
                errorCode: response.StatusMsg,
                description: response.StatusMsg,
            }
        }

        if (this._isCommonOoredooError(response)) {
            return {
                error: response.operationCode,
                errorCode: response.operationCode,
                description: response.alertMessage,
            }
        }

        if (this._isLoginOoredooError(response)) {
            return {
                error: response.Data.ResponseCode,
                errorCode: response.Data.ResponseCode,
                description: response.Data.ResponseStatus,
            }
        }

        if (this._isServiceSpecificOoredooError(response)) {
            return {
                error: response.ServiceSpecifics.BECode,
                errorCode: response.ServiceSpecifics.BECode,
                description: response.ServiceSpecifics.Description,
            }
        }

        if (this._isOoredooMiscError(response)) {
            return {
                error: (response.fault.detail && response.fault.detail.errorcode) || undefined,
                errorCode: (response.fault.detail && response.fault.detail.errorcode) || undefined,
                description: response.fault.faultstring,
            }
        }

        return { error: 'unknown', errorCode: 'unknown', description: 'unknown' }
    }

    /**
     * Check if there is an error in the response with 200 OK response
     * @override
     *
     * @param {Object} response
     * @returns {Boolean}
     */
    isHttp2xxErrorResponse(response = {}) {
        return (
            this._isCommonOoredooError(response) ||
            this._isLoginOoredooError(response) ||
            this._isServiceSpecificOoredooError(response)
            // The following one is not fired by the backend via Http 2xx
            // || this._isOoredooMiscError(response)
        )
    }

    /**
     * Check if there is a common APIGEE error
     *
     * @param {Object} response
     * @returns {Boolean}
     */
    _isCommonOoredooError(response) {
        if (!response) return false
        const { operationResult, result } = response
        return (
            operationResult === 'FAILED' ||
            operationResult === 'FAILURE' ||
            result === false ||
            result === 'false'
        )
    }

    /**
     * Check errors with `ServiceSpecifics` parameter in response
     *
     * @param {Object} response
     * @returns {Boolean}
     */
    _isServiceSpecificOoredooError(response) {
        if (!response || !response.ServiceSpecifics) return false
        const { BECode: code } = response.ServiceSpecifics
        return code !== '000'
    }

    /**
     * Check if there is a login process APIGEE error
     *
     * @param {Object} response
     * @returns {Boolean}
     */
    _isLoginOoredooError(response) {
        if (!response || !response.Data) return false
        const responseCode = response.Data && response.Data.ResponseCode
        return !!responseCode && responseCode !== '000' && responseCode !== '200'
    }

    /**
     * Check if there is a login process APIGEE error
     *
     * @param {Object} response
     * @returns {Boolean}
     */
    _isOoredooMiscError(response) {
        if (!response || !response.fault) return false
        const { faultstring, detail = {} } = response.fault
        return !!faultstring || detail.errorcode
    }
}
