import { IInternalLink } from '@typings/tile'
import { Tile } from './Tile'

/**
 * @typedef {Object} InternalLinkProps
 
 */

/**
 * Class InternalLink description
 * This class is used mainly for non-mobile products (stb)
 * It contains all data necessary to display and run an internal Link to a specific screen/position in UI
 * @property {String} path destination of this internalLink
 */
export class InternalLink extends Tile {
    path: string
    thumbnailSize: string

    constructor(props: IInternalLink) {
        super(props)

        const _computePropValue = this._makeComputePropValue(props)

        this.path = props.path
        this.thumbnailSize = _computePropValue('thumbnailSize', InternalLink.THUMBNAIL.LARGE)
    }

    static THUMBNAIL = {
        SMALL: 'small',
        LARGE: 'large',
    }

    isInternalLink() {
        return true
    }

    isRestricted() {
        return this.path === 'adult'
    }
}
