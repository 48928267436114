import { isWeb } from '@volt-libs/foundation'
import config from './production'

const conf = {
    ...config,
    urls: {
        ...config.urls,
        proxyUrl: 'https://ooredoo-api-stg.ifeelsmart.net/maculosa/v3.1',
        emi: {
            baseUrl: 'https://ooredoo-api-stg.ifeelsmart.net/emi',
            homePath: `3.0/{population}/${isWeb ? 1 : 3}`,
            defaultPopulation: 'ifs_dev',
        },
    },
    encryption: {
        enabled: false,
    },
    agama: {
        // Agama analytics status
        enable: true,
        //Console URI
        _configuration:"emp_service=https://aggregation.castling.uk/report;report_interval=240;id_report_interval=4800;operator_id=Ooredoo_0x5E01_VLTN;",
         // player name
        playerName: 'Shaka Player',
        playerVersion: '4.3.8-nmp-2',
        caSystem: 'verimatrix',
        cdn: 'Huawei',
        //DEBUG for development and INFO for deployment.
        verbose:'Agama.LogLevel.DEBUG'
    },
    env: 'development'
}

export default conf
