import { VirtualChannel } from 'models/VirtualChannel'
import { virtualContentFactory } from './emiVirtualContent'
import { emiRequestFactory } from './emiRequest'

/**
 * Return an instance of Channel from given raw data from emi
 * @param {Object} c
 * @return {Channel}
 */
export const virtualChannelFactory = (c, appConfig) => {
    c.largeLogo = c.logo
    c.smallLogo = c.logo
    c.imgFullscreen = c.img_fullscreen

    const channelId = 'virtualchannel' + c.sourceChannelId

    const { programs, requests } = c.contents.reduce(
        (acc, content) => {
            if (content.type === 'collection')
                acc.requests.push(emiRequestFactory(content, appConfig))
            else acc.programs.push(virtualContentFactory({ ...content, channelId }))
            return acc
        },
        {
            programs: [],
            requests: [],
        }
    )

    return new VirtualChannel({
        ...c,
        sourceId: c.sourceChannelId,
        platformId: c.sourceChannelId,
        // subscription_id could be "null", in this case we should use "undefined" as value
        // to ensure channel won't be instantiated with a [null] productIds
        productIds: undefined,
        title: c.name,
        channelNumber: c.lcn,
        format: 'landscape',
        id: channelId,
        videoUrl: c.video_url,
        programs,
        requests,
    })
}
