import {useContext} from 'solid-js'
import {ToastProviderBehavior} from "./ToastProvider";
import {ToastContext} from "./ToastContext";

export function useToast() {
    const contextValue: ToastProviderBehavior = useContext(ToastContext)
    if (!contextValue) {
        throw new Error(
            'could not find redux context value; please ensure the component is wrapped in a <ReduxProvider>'
        )
    }
    return contextValue
}
