import { IYouTube } from '@typings/tile'
import { Deeplink } from './Deeplink'
import { Tile } from './Tile'

/**
 * Class Youtube description
 * This class is used mainly for Youtube rendering
 * A youtube content is a Deeplink specialized with some attributes
 * @property {String} youtubeType {@link CONTENT_TYPE} (i.e 'YOUTUBE_VIDEO', 'YOUTUBE_PLAYLIST', 'YOUTUBE_PLAYLIST_ITEM', 'YOUTUBE_SUBSCRIPTION', 'YOUTUBE_CHANNEL')
 * @property {String} genre : {@link CONTENT_TYPE} converted to intelligible genre : 'Youtube Playlist', 'Youtube Video', 'Youtube Channel'
 * @property {String} channelID : Youtube Channel ID (Not displayed but for later use)
 * @property {String} channelTitle : Youtube Channel Title
 * @property {String} publicationDate : Publication date of the video format= YYYY-MM-DD (Date String for display not for algorithms)
 * @property {Boolean} isPrivateVideo : Indicate if the video is private or not (private means not playable. Could be equal to undefined which means we don't know) {@doc https://developers.google.com/youtube/v3/docs/videos#status.privacyStatus}
 * @property {Boolean} embeddable : Indicate if the video is embeddable which means playable using embedded player (undefined means we don't now) {@doc https://developers.google.com/youtube/v3/docs/videos#status.embeddable}
 * @property {Boolean} madeForKids : Indicate if the video concerns kids (undefined means we don't know) {@doc https://developers.google.com/youtube/v3/docs/videos#status.madeForKids}
 */

export class Youtube extends Deeplink {
    channelID: string
    channelTitle: string
    publicationDate: string
    isPrivateVideo: boolean
    embeddable: boolean
    madeForKids: boolean
    youtubeType: string
    genre: string

    constructor(props: IYouTube) {
        super(props)

        this.channelID = props.channelID
        this.channelTitle = props.channelTitle
        this.publicationDate = props.publicationDate
        this.isPrivateVideo = props.isPrivateVideo
        this.embeddable = props.embeddable
        this.madeForKids = props.madeForKids

        const computePropValue = this._makeComputePropValue(props)

        this.format = computePropValue('format', Tile.FORMAT.LANDSCAPE)
        this.youtubeType = computePropValue('youtubeType', Youtube.CONTENT_TYPE.YOUTUBE_VIDEO)
        this.genre = computePropValue('genre', 'Youtube Video')
    }

    /**
     * YOUTUBE CONTENT TYPE, used for different rendering
     */
    static CONTENT_TYPE = {
        YOUTUBE_VIDEO: 'YOUTUBE_VIDEO',
        YOUTUBE_PLAYLIST: 'YOUTUBE_PLAYLIST',
        YOUTUBE_PLAYLIST_ITEM: 'YOUTUBE_PLAYLIST_ITEM',
        YOUTUBE_CHANNEL: 'YOUTUBE_CHANNEL', // Not yet supported
        YOUTUBE_SUBSCRIPTION: 'YOUTUBE_SUBSCRIPTION', // Not yet supported
    }

    isYoutube() {
        return true
    }
}
