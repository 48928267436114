import { Node } from './Node'

/**
 * LayoutNode is a 'leaf' node used for accessing a Layout page (example: the Profiles page)
 */
export class LayoutNode extends Node {
    /**
     * Checks if the Node is a LayoutNode (always `true`)
     * */
    isLayoutNode() {
        return true
    }
}
