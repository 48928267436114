import { uniq } from 'lodash'

import { Tile } from './Tile'
import { ILiveTvShow } from '@typings/tile'

/**
 * A series of {@link LiveSeason} containing {@link Live} programs
 * @property {String|Number} channelId Proxy/Maculosa channel identifier which the Live belongs to
 * @property {Array<String>} [seasons=[]] Identifiers of the series' seasons
 * @property {Array<String>} [categories=[]] Categories associated with the series' programs
 * @property {String} format Format of the series' tile
 */
export class LiveTvShow extends Tile {
    channelId: string | number
    seasons: string[]
    categories: string[]

    constructor(props: ILiveTvShow) {
        super(props)

        this.channelId = props.channelId
        this.format = 'landscape'

        const computePropValue = this._makeComputePropValue(props)

        this.seasons = computePropValue('seasons', [])
        this.categories = computePropValue('categories', [])
    }

    getPropsToMerge() {
        return ['seasons', 'categories', ...super.getPropsToMerge()]
    }

    /** Checks if the Tile is a LiveTvShow (always `true`) */
    isLiveTvShow() {
        return true
    }

    /**
     * Override parent method to apply an `uniq` on merged `seasons` & `categories`
     *
     * @param {Object} currentProps
     * @param {Object} newProps
     * @param {Array<String>} bypassMerge
     */
    _mergePropsForUpdate(currentProps: object, newProps: object, bypassMerge: string[]) {
        // Keep original merge behaviour
        const mergedProps = super._mergePropsForUpdate(currentProps, newProps, bypassMerge)

        // Apply lodash `uniq` function on seasons and categories properties
        mergedProps.seasons = mergedProps.seasons && uniq(mergedProps.seasons)
        mergedProps.categories = mergedProps.categories && uniq(mergedProps.categories)

        return mergedProps
    }
}
