import VoltError from 'VoltError'

export const ERROR_BACKEND_MAPPING = {
    // |------------------------------------------------------------------------------------------------------|
    // |-------------------------------               API V6                    ------------------------------|
    // |------------------------------------------------------------------------------------------------------|
    // "ERR- 157022003: deviceModel does not exist, [deviceModel=NoneDevice]",
    157022003: () => VoltError.codes.AUTH_DEVICE_NOT_REGISTERED,
    // "ERR- 157011001: The AuthToken or authTerminalID is blank when the auth type is 2!
    157011001: () => VoltError.codes.USER_SESSION_NOT_FOUND_OR_TIMEOUT,
    // ERR- 157022007: device has arrive at the upper limit because no available logical device or mac address not match.
    157022007: () => VoltError.codes.MAX_DEVICES_REACHED,
    // OnLineHeartbeat failed occurs when device is not connected
    // 157022109: () => VoltError.codes.BACKEND_MAX_DEVICES_REACHED,
    // ERR- 157021009: Username or password is error,please try again
    157021009: () => VoltError.codes.INVALID_PASSWORD,
    // ERR- 157021001: Username or password is error,please try again
    157021001: () => VoltError.codes.INVALID_PASSWORD,
    // ERR- 110021018: new password is not the same as confirm password
    110021018: () => VoltError.codes.PASSWORD_MODIFICATION_FAILED,
    // 125010001 Validate input parameter error. value [null] of oldPwd is null
    125010001: () => VoltError.codes.INVALID_REQUEST_PAYLOAD,
    // 154020056 Modify Password invalid input parameters
    154020056: () => VoltError.codes.PASSWORD_MODIFICATION_FAILED,
    //ERR- 154020071: device has already bound. current device id is <some id here>
    154020071: () => VoltError.codes.DEVICE_ALREADY_BOUND,

    ///OOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOO
    ///OO                   PLAYBACK CATEGORY                           OO
    ///OOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOO
    ///-------------------------------------
    ///-- Errors from 'PlayChannel        --
    ///-------------------------------------
    // ERR- 146020012: channel is not exist.
    146020012: () => VoltError.codes.CHANNELS_UNAVAILABLE,
    // The media asset to be authenticated does not exist.
    146020013: () => VoltError.codes.CHANNELS_UNAVAILABLE,
    // The program to be authenticated does not exist.
    146020011: () => VoltError.codes.PLAYER_CONTENT_ID_ERROR, // To be changed later
    // ERR- 146021010: playbill is overtime
    146021010: () => VoltError.codes.RECORDING_NOT_ALLOWED, // To be changed
    // The subscriber has not subscribed to the channel or program. There are no products available for subscription.
    146021000: () => VoltError.codes.PLAYER_ENTITLEMENT_REVOKED, // To be changed by another error
    // The subscriber has subscribed to the channel or program, but the channel or program does not support the model of the device used by the subscriber.
    146021001: () => VoltError.codes.PLAYER_DEVICE_NOT_SUPPORTED,
    // The subscriber has no available device to play the media asset, causing authentication failure and failure to obtain the products available for subscription.
    146021002: () => VoltError.codes.PLAYER_DEVICE_INVALID,
    // The subscriber attributes do not match the restrictions on the product of the channel or program to which the subscriber has subscribed.
    146021003: () => VoltError.codes.PLAYER_CONTENT_ID_ERROR,
    // Limited number of playback sessions
    146021004: () => VoltError.codes.PLAYER_SESSION_MAX_STREAM_REACHED,
    // The subscriber has not subscribed to the channel or program. There are products available for subscription.
    146021006: () => VoltError.codes.PLAYER_ENTITLEMENT_REVOKED,
    // The channel is under control.
    146021007: () => VoltError.codes.PLAYBACK_ERROR, // Find a better mapping
    // The program is under control.
    146021008: () => VoltError.codes.PLAYBACK_ERROR, // Find a better mapping
    // The number of concurrent playback requests has reached the upper limit.
    146021009: () => VoltError.codes.PLAYER_SESSION_MAX_STREAM_REACHED,
    // The ISP information of the subscriber's geographic location does not match the ISP information of the channel's geographic location.
    146021018: () => VoltError.codes.PLAYER_CONTENT_UNAVAILABLE_FOR_LOCATION,
    // The service use time does not match the validity period of the channel content's geographic location.
    146021019: () => VoltError.codes.PLAYER_CONTENT_UNAVAILABLE_FOR_LOCATION,
    // The authorized area of the channel does not match the geographical location of the subscriber.
    146020014: () => VoltError.codes.PLAYER_CONTENT_UNAVAILABLE_FOR_LOCATION,
    // The number of OTT devices under the subscriber has exceeded the upper limit.
    157022015: () => VoltError.codes.PLAYER_SESSION_MAX_STREAM_REACHED,
    // Live preview exceeded
    146020016: () => VoltError.codes.PLAYER_ENTITLEMENT_REVOKED,
    114020008: () => VoltError.codes.PLAYER_ENTITLEMENT_REVOKED,
    // The channel or schedule is not subscribed but can be previewed.
    146021017: () => VoltError.codes.PLAYER_ENTITLEMENT_REVOKED,
    // The subscriber status is abnormal.
    157021044: () => VoltError.codes.PLAYER_ENTITLEMENT_REVOKED,
    // The number of OTT devices under the subscriber has exceeded the upper limit.
    114040009: () => VoltError.codes.MAX_DEVICES_EXCEEDED,
    // Request parameter is illegal [*** (field name)]
    114010000: () => VoltError.codes.PLAYER_SESSION_ERROR_DUE_TO_INVALID_PARAMETER,
    159011001: () => VoltError.codes.PLAYER_SESSION_ERROR_DUE_TO_INVALID_PARAMETER,
    // Bandwidth request failed
    159000002: () => VoltError.codes.PLAYBACK_ERROR, // TODO: Fine a better error
    // Internal error (An exception such as null pointer, out-of-bounds array, data acquisition failure, or quick system bypass for a failure occurs.)
    114099999: () => VoltError.codes.PLAYER_SESSION_ERROR_DUE_TO_INVALID_PARAMETER,
    // Product does not exist or status is unavailable
    501: () => VoltError.codes.PLAYER_OFFER_IS_NOT_AVAILABLE,
    // Service does not exist or status is unavailable
    502: () => VoltError.codes.PLAYER_OFFER_IS_NOT_AVAILABLE,
    // The ordered product is not in use
    503: () => VoltError.codes.PLAYER_OFFER_IS_NOT_AVAILABLE,
    // The user has not ordered the corresponding product or the subscription relationship has expired or is not in effect, returning to the list of available products.
    504: () => VoltError.codes.PLAYER_OFFER_IS_NOT_AVAILABLE,
    // ERR- 154020058: user insufficient permissions(non-administrator account),can't modify rating
    154020058: () => VoltError.codes.SERVICE_NOT_ALLOWED,
    // No right to modify master profile password
    157021036: () => VoltError.codes.SERVICE_NOT_ALLOWED,
    // Session does not exist
    125023001: () => VoltError.codes.USER_SESSION_NOT_FOUND_OR_TIMEOUT,
    // The number of sessions that the subscriber can log in at the same time reaches the upper limit and the session is invalid.
    125023009: () => VoltError.codes.PLAYER_SESSION_MAX_STREAM_REACHED,
    // The device is kicked off by other devices under the subscriber.
    125023010: () => VoltError.codes.PLAYER_DEVICE_NOT_FOUND,
    // The device is unbundled and the profile is kicked off.
    125023011: () => VoltError.codes.PLAYER_DEVICE_NOT_FOUND,
    // The device is deleted and the profile is kicked off.
    125023012: () => VoltError.codes.PLAYER_DEVICE_NOT_FOUND,
    // Subscriber deleted, Profile is kicked off.
    125023013: () => VoltError.codes.ACCOUNT_NOT_FOUND,
    // The subscriber is suspended and the profile is kicked off.
    125023014: () => VoltError.codes.USER_IS_BARRED,
    // Subscriber area or group change, Profile is kicked off.
    125023015: () => VoltError.codes.USER_IS_BARRED,
    // The subscriber ID is changed, and the profile corresponding to the original subscriber ID is kicked off.
    125023016: () => VoltError.codes.USER_IS_BARRED,
    // Profile deleted, profile is kicked off.
    125023017: () => VoltError.codes.USER_IS_BARRED,
    // IP change, Profile is kicked off.
    125023018: () => VoltError.codes.USER_IS_BARRED,
    // Session has expired due to logout
    125023020: () => VoltError.codes.USER_SESSION_NOT_FOUND_OR_TIMEOUT,
    // The content does not exist.
    114020007: () => VoltError.codes.CHANNELS_UNAVAILABLE, // To be changed later
    // The number of devices used by the subscriber on the home network to play the content has reached the upper limit.
    114030000: () => VoltError.codes.MAX_DEVICES_EXCEEDED,
    // The number of playback sessions has reached the upper limit for the regional node of the subscriber.
    114030001: () => VoltError.codes.PLAYER_SESSION_MAX_STREAM_REACHED,
    // The number of playback sessions of the OTT device of the CP is exceeded.
    114030002: () => VoltError.codes.PLAYER_SESSION_MAX_STREAM_REACHED,
    // The number of devices that can play this channel media asset at the same time reaches the maximum.
    114040010: () => VoltError.codes.PLAYER_SESSION_MAX_STREAM_REACHED,
    // The content has not been subscribed to and has no product available for subscription.
    114020000: () => VoltError.codes.PLAYER_ENTITLEMENT_REVOKED,
    // The subscriber has subscribed to the content but the content does not support the model of the device used by the subscriber.
    114020001: () => VoltError.codes.PLAYER_ENTITLEMENT_REVOKED,
    // TODO: continue PlayChannel Mapping from 114020001
    // ...
    ///-------------------------------------
    ///-- Errors from 'PlayVOD            --
    ///-------------------------------------
    // The subscriber has not subscribed to the VOD program. There are no products available for subscription.
    144020000: () => VoltError.codes.PLAYER_ENTITLEMENT_REVOKED,
    // ERR- 144020012: Vod do not exist
    144020012: () => VoltError.codes.PLAYER_CONTENT_ID_ERROR, // To be changed later
    // The VOD media asset does not exist.
    144020013: () => VoltError.codes.PLAYER_CONTENT_ID_ERROR, // To be changed later
    // The VOD authorized geographical location does not match the subscriber's geographical location.
    144020004: () => VoltError.codes.PLAYER_ENTITLEMENT_REVOKED,
    /// TODO: continue PlayVOD Mapping
    ///OOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOO
    ///OO                       DVR CATEGORY                            OO
    ///OOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOO
    // The channel media asset does not support nPVR.
    147020000: () => VoltError.codes.RECORDING_NOT_ALLOWED_CHANNEL,
    // The channel is not associated with media assets supporting nPVR.
    147020001: () => VoltError.codes.RECORDING_NOT_ALLOWED_CHANNEL,
    // The program does not support nPVR.
    147020002: () => VoltError.codes.RECORDING_NOT_ALLOWED_PROGRAM,
    // The time segment-based PVR task involves programs that do not support nPVR.
    147020003: () => VoltError.codes.RECORDING_NOT_ALLOWED_PROGRAM,
    // Some concurrent nPVR requests conflict.
    147020004: () => VoltError.codes.RECORDING_FAILED_DUE_TO_CONFLICT,
    // The nPVR recording space is insufficient.
    147020005: () => VoltError.codes.EXCEEDED_QUOTA,
    // nPVR space query fails.
    // 147020006: () => VoltError.codes.RECORDING_FAILED,
    // The channel media asset does not support cPVR.
    147020011: () => VoltError.codes.RECORDING_NOT_ALLOWED_CHANNEL,
    // The program does not support cPVR.
    147020012: () => VoltError.codes.RECORDING_NOT_ALLOWED_PROGRAM,
    // The time segment-based PVR task involves programs that do not support cPVR.
    147020013: () => VoltError.codes.RECORDING_NOT_ALLOWED_PROGRAM,
    // The maximum number of concurrent PVR tasks executed by the STB fails to be queried.
    147020015: () => VoltError.codes.RECORDING_FAILED_DUE_TO_CONFLICT,
    // cPVR resources conflict with each other.
    147020016: () => VoltError.codes.RECORDING_FAILED_DUE_TO_CONFLICT,
    // The channel does not exist.
    147020020: () => VoltError.codes.CHANNELS_UNAVAILABLE,
    // The channel media asset does not exist.
    147020021: () => VoltError.codes.CHANNELS_UNAVAILABLE,
    // The nPVR space is not subscribed to.
    147020025: () => VoltError.codes.RECORDING_NO_QUOTA_SUBSCRIPTION,
    // The nPVR space is insufficient. However, the nPVR task is still added. The subscriber can delete other nPVR tasks to release space.
    147020026: () => VoltError.codes.EXCEEDED_QUOTA,
    // The number of single PVR tasks exceeds the maximum.
    147020030: () => VoltError.codes.EXCEEDED_QUOTA,
    // The channel media asset does not support the recording of expired programs
    147020045: () => VoltError.codes.RECORDING_NOT_ALLOWED_CHANNEL,
    // |------------------------------------------------------------------------------------------------------|
    // |-------------------------------               API V2                    ------------------------------|
    // |------------------------------------------------------------------------------------------------------|
    ///OOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOO
    ///OO  API v2 -             AUTHENTICATE                            OO
    ///OOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOO
    // When a subscriber uses a device to log in to the system, the system restricts the access of the device because of the device vendor or the device operating system.
    85983384: () => VoltError.codes.DEVICE_TYPE_NOT_SUPPORTED,
    // A subscriber uses a suspended account to log in to the system.
    33619970: () => VoltError.codes.USER_IS_BARRED,
    // When a subscriber attempts to log in to the system, the entered account or password is incorrect.
    33620481: () => VoltError.codes.LOGIN_INVALID_CREDENTIALS,
    // The IP address of the device used by a subscriber is listed in the system blacklist.
    85983373: () => VoltError.codes.USER_IP_BLACKLISTED,
    // A subscriber uses an unregistered account to log in to the system.
    85983377: () => VoltError.codes.INVALID_PASSWORD,
    // After the number of consecutive login failures reaches the upper limit, the system locks the subscriber account.
    33620231: () => VoltError.codes.MAXIMUM_LOGIN_ATTEMPTS_REACHED,
    33620232: () => VoltError.codes.MAXIMUM_LOGIN_ATTEMPTS_REACHED,
    // When a subscriber subscribes to a product on the EPG, the EPG sends a subscription request to the MEM. The MEM fails to process the subscription request because the MEM database is abnormal, and the subscription fails.
    117637376: () => VoltError.codes.LOGIN_FAILED,
    // The STB used by a subscriber to log in to the system has been bound to another subscriber account and therefore the login is not allowed.
    33620483: () => VoltError.codes.ACCOUNT_REGISTERED_WITH_OTHER_DEVICE_CANNOT_LOGIN,
    // A subscriber selects a device that does not support eh IPTV service.
    85983240: () => VoltError.codes.DEVICE_TYPE_NOT_SUPPORTED,
    // When the system authenticates a subscriber's login request, the CA is abnormal.
    85983265: () => VoltError.codes.LOGIN_FAILED,
    // The EPG server that processes the subscriber's login request is overloaded. As a result, the EPG server rejects the login request.
    85983303: () => VoltError.codes.LOGIN_FAILED,
    // When a subscriber uses a device to log in to the system, the CA type of the device is different from the CA type that the device is supposed to match.
    85983375: () => VoltError.codes.LOGIN_FAILED,
    // During profile authentication, the profile is not bound to any subscribers.
    85983378: () => VoltError.codes.USER_PROFILE_RETRIEVAL_FAILED,
    // The carrier has set the number of concurrent online devices in the device group to 0.
    85983391: () => VoltError.codes.BACKEND_MAX_DEVICES_REACHED,
    // When a subscriber uses a device to log in to the system, the concurrent online sessions of the device group have exceeded the upper limit.
    85983392: () => VoltError.codes.MAX_DEVICES_REACHED,
    // The ACS is abnormal and the system cannot process the service requests.
    87097345: () => VoltError.codes.LOGIN_FAILED,
    // When a subscriber subscribes to a product on the EPG, the EPG fails to send the subscription request to the MEM due to the IB fault, and the subscription fails.
    87031811: () => VoltError.codes.LOGIN_FAILED,
    // When a subscriber attempts to log in, the system checks the device status and detects that the device is in the suspended state.
    85983570: () => VoltError.codes.DEVICE_IS_SUSPENDED,
    // When a subscriber attempts to log in, the system detects that the subscriber has not subscribed to the Login service.
    85983588: () => VoltError.codes.ACCOUNT_NOT_FOUND,
    // The number of devices bound to the subscriber has reached the maximum.
    33619984: () => VoltError.codes.MAX_DEVICES_REACHED,
    // The number of HLS OTT devices bound to the subscriber account has reached the upper limit.
    85983545: () => VoltError.codes.MAX_DEVICES_REACHED,
    // Failed to verify the broadband account.
    33620484: () => VoltError.codes.LOGIN_FAILED,
    // Incorrect parameter. The UMS failed to authenticate terminalType.
    117440517: () => VoltError.codes.DEVICE_TYPE_NOT_SUPPORTED,
    // The user device type reported during authentication is invalid.
    85983374: () => VoltError.codes.DEVICE_TYPE_NOT_SUPPORTED,
    // Online device binding is not allowed.
    85983414: () => VoltError.codes.DEVICE_TYPE_NOT_SUPPORTED,
    // The channel naming space does not exist.
    85983445: () => VoltError.codes.CANNOT_LOGIN_TO_CHANNEL_NAMESPACE,
    // Information about the PlayReady server is not configured.
    85983454: () => VoltError.codes.LOGIN_FAILED,
    // Cannot request the third-party BOSS to perform operations due to incorrect configuration.
    85983497: () => VoltError.codes.LOGIN_FAILED,
    // Failed to bind the third-party BOSS.
    85983502: () => VoltError.codes.LOGIN_FAILED,
    // Third-party BOSS bound successfully. Failed to bind the MDSP.
    85983503: () => VoltError.codes.LOGIN_FAILED,
    // cadeviceId signature verification failed.
    33620737: () => VoltError.codes.LOGIN_FAILED,
    // The device corresponding to stbsn is not unique.
    85983768: () => VoltError.codes.SERIAL_NUMBER_NOT_UNIQUE,
    // The device corresponding to stbsn does not exist.
    85983769: () => VoltError.codes.SERIAL_NUMBER_INVALID,
    // The reported STBSN is inconsistent with that in the database.
    85983770: () => VoltError.codes.SERIAL_NUMBER_INVALID,
    /**
     *
     */
    '-1': (errorCode, description) => {
        if (description) {
            if (description.includes('ERR- 154020084')) {
                /* ERR- 154020084: adddevice Send CA message failed ERR- 124042002: VmxCa addDomainDevices failed.*/
                return VoltError.codes.SERVER_CANNOT_ADD_DRM_DEVICE
            }
        }

        return VoltError.codes.UNKNOWN_API_ERROR
    },
}
