import { Node } from './Node'

/**
 * SearchNode is a 'leaf' node used for accessing the Search page
 */
export class SearchNode extends Node {
    /**
     * Checks if the Node is a SearchNode (always `true`)
     * */
    isSearchNode() {
        return true
    }
}
