import { ITournament, TournamentCalendars } from '@typings/opta'
import { Abstract } from './Abstract'

export class Tournament extends Abstract {
    id: string
    type: string
    countryCode: string
    competitionCode: string
    tournamentCalendar: TournamentCalendars[]
    /**
     * @param {TournamentProps} props
     */

    constructor(props: ITournament) {
        super()

        this.id = props.id
        this.type = props.type
        this.countryCode = props.countryCode
        this.competitionCode = props.competitionCode
        this.tournamentCalendar = props.tournamentCalendar
    }
}
