import { Abstract } from './Abstract'
import { IEmiRequest, IRequestFilter } from '@typings/emi'
import { IntlKey } from '@typings/node'
import { HashMap } from '@typings/generic'

/**
 * An EmiRequest contains all the data necessary for retrieving the contents of an SPZ or PPZ of an EMI config
 * @property {String} id EmiRequest ID
 * @property {String|Number} emiUniqueId The unique content selector ID taken from the EMI config (the request name)
 * @property {String} value The request value which is the concatenation of an EMI provider (e.g VODContent)
 * and a content selector value (when created from an EMI config)
 * @property {"SPZ"|"PPZ"} context Indicates where the request is located in the UI (SPZ or PPZ)
 * @property {Boolean} translateLabel Indicates whether the label comes from translations/i18n
 * @property {Array<*>} args List of arguments used when executing the request
 * @property {Object<String,String>} labels Labels mapped by language code (with a possible default value)
 * @property {Boolean} pending Indicates whether the execution of the request is ongoing
 * @property {Boolean} refreshing Indicates whether the request is being refreshed (i.e executed from page 0)
 * @property {Boolean} temporary Indicates whether the request should only exist temporarily
 * @property {Number} lastExecution Timestamp of the request's last execution
 * @property {Number} validity The validity duration (in ms) of the request after its last execution
 * @property {Number} page Page number of the request execution
 * @property {Boolean} done Indicates whether the request execution has completed
 * @property {String} icon Uri of the icon, mainly used for pre-request. Yet not provided by EMI, but generated internally (i.e Android Channel Apps category)
 * @property {String} layout EMI has the capability to define a layout for the entire request (portrait or landscape). But yet it is not consistent for all EMI Requests (use it now only for VOD pending consistency from EMI)
 */

export class EmiRequest extends Abstract {
    id: string
    value: string
    args: string[]
    labels: object
    context?: string
    translateLabel?: boolean
    intlKey?: IntlKey
    lastExecution?: number
    validity?: number
    pending?: boolean
    refreshing?: boolean
    temporary?: boolean
    page?: number
    done?: boolean
    emiUniqueId?: string | number
    icon?: string
    layout?: boolean
    requestFilter?: IRequestFilter;

    // for mutate func
    [key: string]: unknown

    constructor(props: IEmiRequest) {
        super()

        this.id = props.id
        this.value = props.value
        this.context = props.context
        this.translateLabel = !!props.translateLabel
        this.intlKey = props.intlKey

        this.lastExecution = props.lastExecution
        this.validity = props.validity

        const computePropValue = this._makeComputePropValue(props)

        this.args = computePropValue('args', [])
        /**
         * TODO should it be an Object as default ?
         * @type {Array}
         */
        this.labels = computePropValue('labels', { default: '' })
        this.pending = computePropValue('pending', false)
        this.refreshing = computePropValue('refreshing', false)
        this.temporary = computePropValue('temporary', false)
        this.page = computePropValue('page', 0)
        /** When true, the EmiRequest stops executing */
        this.done = computePropValue('done', false)

        this.emiUniqueId = computePropValue('emiUniqueId', this.id)
        this.icon = props.icon
        this.layout = props.layout
        this.requestFilter = props.requestFilter
    }

    static CONTEXT = {
        PPZ: 'PPZ',
        SPZ: 'SPZ',
    }

    static LAYOUT = {
        LANDSCAPE: 'landscape',
        PORTRAIT: 'portrait',
        SQUARE: 'square',
    }

    mutate(newProps: HashMap<string, unknown>) {
        for (const key in newProps) {
            this[key] = newProps[key]
        }

        // Keep only defaultedProperty not mutated above (missing from newProps)
        this._defaultedProperties = this._defaultedProperties.filter(
            (propName) => !(propName in newProps)
        )

        return this
    }
}
