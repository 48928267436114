import { IActionNode } from '@typings/node'
import { Node } from './Node'

export class ActionNode extends Node {
    handleAction: () => object | object[]
    isDispatcher: boolean

    constructor(props: IActionNode) {
        super(props)
        const computePropValue = this._makeComputePropValue(props)

        /**
         * Function invoked when node is selected.
         * When isDispatcher is `true`, this function is assumed as a Redux Action generator and
         * must return :
         * - an Action (`Object` with `type` attr)
         * - or an `Array` of Actions.
         */
        this.handleAction = props.handleAction
        this.isDispatcher = !!computePropValue('isDispatcher', false)
    }

    isActionNode() {
        return true
    }
}
