export interface LoadingProps {
    width?: string
    height?: string
}

export const Spinner = (props: LoadingProps) => {
    const {width = '100px', height = '100px'} = props

    return <div class="loader" style={{width, height}}></div>
}
