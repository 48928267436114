import { ISoccerTeamRanking } from '@typings/opta'
import { Abstract } from './Abstract'

export class SoccerTeamRanking extends Abstract {
    name: string
    teamLogo?: string
    matchesPlayed: number
    matchesWon: number
    matchesDrawn: number
    matchesLost: number
    goalFor: number
    goalAgainst: number
    goaldifference: string
    points: number
    lastSix: string
    constructor(props: ISoccerTeamRanking) {
        super()

        this.name = props.name
        this.teamLogo = props.teamLogo
        this.matchesPlayed = props.matchesPlayed
        this.matchesWon = props.matchesWon
        this.matchesDrawn = props.matchesDrawn
        this.matchesLost = props.matchesLost
        this.matchesPlayed = props.matchesPlayed
        this.goalFor = props.goalFor
        this.goalAgainst = props.goalAgainst
        this.goaldifference = props.goaldifference
        this.points = props.points
        this.lastSix = props.lastSix
    }
}
