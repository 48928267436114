import GenericFetch from 'framework/genericfetch'
import { ERROR_BACKEND_MAPPING } from './errors'
import CookiesHandler from './cookies'
import { of, throwError, EMPTY } from 'rxjs'
import { mergeMap, catchError, expand, reduce } from 'rxjs/operators'
import VoltError from 'VoltError'
import DataHelper from 'framework/helpers/data'
import ConfigHelper from 'framework/helpers/config'

/**
 * Fetching API class.
 */
export default class Fetch extends GenericFetch {
    /**
     * Easier to do it in that way to manipulate constructors
     */
    static _AUTH_API_INSTANCE = undefined

    /**
     * Sets the last timestamp when tried to recover a session
     */
    static LAST_SESSION_RECOVER_MS = {}

    constructor(config, otherApis = {}) {
        super(config, 'huawei')
        this.registerErrors(ERROR_BACKEND_MAPPING)
        const { forceSessionCookieHeader = true } = ConfigHelper.getInstance().getConfig('huawei')
        this._forceSessionCookieHeader = forceSessionCookieHeader
    }

    /**
     * Fetching Method call with ajax API. Output of the function is then automatically parsed
     *
     * @param {String} url
     * @param {Object} body : HTTP URL
     * @param {Object} headers : HTTP Header
     * @param {String} method : GET, PUT, POST, DELETE, HEAD, OPTIONS
     * @param {Boolean} [allowReloginWhenSessionEnded=true] Allow to relogin when user faces a session timeout issue
     * @param {Boolean} [forceReloginWhenSessionEnded=false] Force/Bypass algorithm to allow an auto login when timeout session occurs. Applicable only when forceReloginWhenSessionEnded=true
     * @param {Number} timeout
     * @param {String} [log=''] Extra message for Debug
     * @param {Boolean} [cookiesLessQuery=false] Enforce that specific call does not need cookies because it could create some issue for relogin
     * @param {Boolean} [retrieveResponseHeaders=false] False by default to same CPU as not always needed
     * @param {VoltError} [defaultError=undefined] Default error to return when UNKNOWN_API_ERROR is thrown
     * @returns { response, totalCount }
     */
    fetch(args) {
        let {
            headers = GenericFetch.defaultHeaders,
            allowReloginWhenSessionEnded = true,
            forceReloginWhenSessionEnded = false,
            cookiesLessQuery = false,
            log,
        } = args || {}
        /**
         * Temporary trick to find a better solution for managing session cookie on react native app
         * Cannot be enabled on Browser app otherwise it will be detected as unsafe operation as cookies are protected
         * The configuration parameter 'forceSessionCookieHeader' needs to be used on react native app
         */
        let withCredentials
        const areCookiesManuallyhandled = this._forceSessionCookieHeader
        if (!cookiesLessQuery) {
            if (this._forceSessionCookieHeader) {
                const sessionCookie =
                    CookiesHandler.getCookies() ||
                    DataHelper.getInstance().getData(DataHelper.STORE_KEY.SESSION_COOKIE)
                if (sessionCookie) {
                    headers = {
                        ...headers,
                        Cookie: sessionCookie,
                    }
                }
            } else {
                // Letting the browser managing the cookie
                withCredentials = true
            }
        }

        return super
            .fetch({
                ...args,
                withCredentials,
                headers,
                retrieveResponseHeaders: areCookiesManuallyhandled,
            })
            .pipe(
                mergeMap(({ response, url, log, method, headers: responseHeaders } = {}) => {
                    // Parse Cookie whether the backend returns an error or not to be able to deal with some API
                    // That can be used subsequently in case of failure like the FIFO mechanism after login failure
                    responseHeaders && CookiesHandler.parseCookies(responseHeaders)
                    if (this.isErrorResponse(response)) {
                        const error = this._parseError(response)

                        this.logger.error(`[${log}] HttpRequestRaw [ERROR]:`, {
                            url: this._filterUrlParams(url),
                            method,
                            error,
                        })
                        return throwError(error)
                    }
                    return of({ response, headers: responseHeaders })
                }),
                catchError((error) => {
                    const errorCode = error.code
                    if (
                        errorCode === VoltError.codes.USER_SESSION_NOT_FOUND_OR_TIMEOUT.code ||
                        errorCode === VoltError.codes.REQUEST_TIMEOUT.code
                    ) {
                        if (allowReloginWhenSessionEnded) {
                            this.logger.warn(
                                `${log} cannot be sent due to ${errorCode}, try to recover the session first... forceReloginWhenSessionEnded=${forceReloginWhenSessionEnded}`
                            )
                            /**
                             * This Algorithm is to try to recover the login by using recursivity on the fly
                             * As on react native application, when the app is in background all the timers are freezed
                             * which prevents VOLT API to refresh the session (timeout 15min) which can lead to disconnection
                             */
                            if (Fetch.getAuthApi()) {
                                if (
                                    this._canTryToRecoverSession(errorCode) ||
                                    forceReloginWhenSessionEnded
                                ) {
                                    this._saveSessionRecoveryTry(errorCode)
                                    return (
                                        errorCode === VoltError.codes.REQUEST_TIMEOUT.code
                                            ? Fetch.getAuthApi()._tryToRecoverSessionUsingRelogin({
                                                  // Renew the server url in case of timeout because the URL may have been changed
                                                  renewServerUrl: true,
                                              })
                                            : Fetch.getAuthApi().refreshLoginHeartbeat({
                                                  forceReloginInCaseOfFailure: true,
                                              })
                                    ).pipe(
                                        mergeMap(() => {
                                            this.logger.warn(
                                                `Session/Login recovered on ${errorCode}, Can retry ${log}...`
                                            )
                                            return this.fetch({
                                                ...args,
                                                log: `RETRY => ${log}`,
                                                allowReloginWhenSessionEnded: false,
                                            })
                                        })
                                    )
                                }
                                this.logger.warn(
                                    `Cannot try to recover session/relogin (wait next attempt or not configured)... for ${errorCode}`
                                )
                            } else {
                                this.logger.warn('Cannot try to relogin on session timeout ')
                            }
                        }
                    }

                    return throwError(error)
                })
            )
    }

    /**
     * Recursively executes an api call until all results have been retrieved
     *
     * @param {function} req The function called to fetch elements from the backend
     * @param {Object} opts
     * @param {number} opts.count The number of elements to be retrieved per fetch
     * @param {number} opts.offset The offset of the first element of next backend call
     * @param {number} opts.responseResource Resources array to be parsed
     *
     * @returns {Array<object>} An array of raw elements
     */
    recursiveFetch(req, opts = {}) {
        const { offset: initialOffset = 0, count = 50, responseResource } = opts

        let offset = initialOffset
        return req(null, count, offset).pipe(
            expand(({ response = {} }) => {
                const { total, countTotal, counttotal } = response // countTotal - v6 | counttotal - v2
                offset += count

                let continueParsing = false
                if (total) {
                    const records = total
                    continueParsing = records && offset < records
                } else if (countTotal) {
                    const contentRetrieved = countTotal
                    continueParsing = contentRetrieved >= count
                } else if (counttotal) {
                    continueParsing = counttotal >= offset
                }
                return continueParsing
                    ? req(response, count, offset)
                    : of(response).pipe(mergeMap(() => EMPTY))
            }),
            reduce((acc, { response }) => {
                const result = responseResource ? response[responseResource] : response
                result && acc.push(...result)
                return acc
            }, [])
        )
    }

    /**
     * This function returns if the response from the backend is an error or not as Huawei always returns
     * http 2xx whether it is an error or not
     * @param {Object} response Raw Response
     * @returns {Boolean} true if result is an error
     */
    isErrorResponse(response = {}) {
        if (response.result) {
            /**
             * Huawei V6 API template response
             */
            const { retMsg = '', retCode = '' } = response.result
            if (retCode === '000000000') return false
            if (retMsg === 'Successfully') return false
            if (retMsg.includes('dispatch success')) return false
            if (parseInt(retCode) === 0) return false
            return true
        } else if (response.retcode || response.retmsg) {
            /**
             * Huawei V2 API template response
             */
            const { retmsg = '', retcode = '' } = response
            if (retcode === '0') return false
            if (retmsg === 'success') return false
            if (parseInt(retcode) === 0) return false
            return true
        }
        return false
    }

    /**
     * Error extraction. Inherited from fetch
     * @param {Object} response
     * @returns
     */
    extractError(response = {}) {
        if (response.result) {
            /**
             * Huawei V6 API template response
             */
            return {
                error: response.result.retCode,
                errorCode: response.result.retCode,
                description: response.result.retMsg,
            }
        }

        if (response.retcode || response.retmsg) {
            /**
             * Huawei V2 API template response
             */
            return {
                error: response.retcode,
                errorCode: response.retcode,
                description: response.retmsg,
            }
        }

        return { error: 'unknown', errorCode: 'unknown', description: 'unknown' }
    }

    /**
     * Save
     * @param {String} errorCode
     */
    _saveSessionRecoveryTry(errorCode) {
        Fetch.LAST_SESSION_RECOVER_MS[errorCode] = Date.now()
    }

    _canTryToRecoverSession(errorCode) {
        const {
            recoverSessionFromFetchDurationMs = 900000,
            reloginWhenTimeoutDurationMs = 60000,
            canRecoverSessionFromFetch = true,
        } = ConfigHelper.getInstance().getConfig('huawei')
        if (!canRecoverSessionFromFetch) return false
        if (!Fetch.LAST_SESSION_RECOVER_MS?.[errorCode]) return true

        const recoveryDuration =
            VoltError.codes.REQUEST_TIMEOUT.code === errorCode
                ? reloginWhenTimeoutDurationMs
                : recoverSessionFromFetchDurationMs

        return Date.now() > Fetch.LAST_SESSION_RECOVER_MS[errorCode] + recoveryDuration
    }

    static getAuthApi() {
        return Fetch._AUTH_API_INSTANCE
    }
    static setAuthApi(instance) {
        Fetch._AUTH_API_INSTANCE = instance
    }
}
