import { ILiveSeason } from '@typings/tile'
import { Tile } from './Tile'

/**
 * @typedef {Object} LiveSeasonProps
 * @property {String} seriesId Identifier of the {@link LiveTvShow} the season belongs to
 * @property {Number} [number=-1] The season number
 * @property {Array<String>} [episodes=[]] Identifiers of the season's episodes
 */

/**
 * A collection of {@link Live} episodes linked to a particular {@link LiveTvShow}
 */

export class LiveSeason extends Tile {
    seriesId: string
    number: number
    episodes: string[]

    constructor(props: ILiveSeason) {
        super(props)

        this.seriesId = props.seriesId

        const computePropValue = this._makeComputePropValue(props)

        this.number = computePropValue('number', -1)
        this.episodes = computePropValue('episodes', [])
    }

    /**
     * Checks if the Tile is a LiveSeason (always `true`)
     * @return {Boolean}
     */
    isLiveSeason() {
        return true
    }
}
