export enum AUTH_TYPE {
    NO_AUTH = 'NO_AUTH',
    API_KEY = 'API_KEY',
    BEARER = 'BEARER',
    BASIC_AUTH = 'BASIC_AUTH',
    DIGEST_AUTH = 'DIGEST_AUTH',
    AWS_SIGNATURE = 'AWS_SIGNATURE',
}

export enum CACHED_LIST_NAMES {
    FAVORITE_RECORDINGS = 'FAVORITE_RECORDINGS',
    RESTRICTED_CHANNELS = 'RESTRICTED_CHANNELS',
}

export enum ENCTYPE {
    AES = 'aes', // Only AES supported for the moment
}

export enum DEVICES {
    iosPhone = 12,
    iosTablet = 42,
    androidPhone = 11,
    androidTablet = 41,
}

export enum COMPARATOR {
    eq = '', // Equal -- On .key == val
    gt = 'gt', // Great than -- On .key > val
    lt = 'lt', // Less than -- On .key < val
    gte = 'gte', // Great than equal -- On .key >= val
    lte = 'lte', // Less than equal -- On .key <= val
    like = 'like', // Live -- On .key TSQL LIKE val
    ilike = 'ilike', // Insensitive like Live -- On .key TSQL LIKE val
    in = 'in', // in --- On .key in [val1,val2,val3]
    notin = 'notin', // notin --- On .key not in [val1,val2,val3]
}

export enum SORTING_DIRECTION {
    ASCENDANT = 'asc',
    DESCENDANT = 'desc',
}

export enum FILTERING_KEY {
    CATEGORIES = 'categories',
    CATEGORY = 'category',
}

export enum LAYOUT {
    LANDSCAPE = 'landscape',
    PORTRAIT = 'portrait',
    SQUARE = 'square',
}

export enum SETTING_TYPES {
    MENU = 'MENU', // Used on VoltMobile, act like the "Home Settings" to present all kind of settings
    PIN = 'PIN',
    PC_LEVEL = 'PC_LEVEL',
    PLAYBACK_LANGUAGE = 'PLAYBACK_LANGUAGE',
    APP_LANGUAGE = 'APP_LANGUAGE',
}

export enum MATCH_STATUSES {
    fixture = 'Fixture',
    playing = 'Playing',
    played = 'Played',
    cancelled = 'Cancelled',
    postponed = 'Postponed',
    suspended = 'Suspended',
    awarded = 'Awarded',
}

export enum FORMAT {
    PORTRAIT = 'portrait',
    LANDSCAPE = 'landscape',
    SQUARE = 'square',
}

export enum SOURCE_MODE {
    CENSORED_CHANNEL = 'censored',
    UNCENSORED_CHANNEL = 'uncensored',
}

export enum PAYMENT_METHOD_TYPE {
    PAY_ON_BILL = 'PAY_ON_BILL',
    CARRIER_BILLING = 'CARRIER_BILLING',
    CREDIT_CARD = 'CREDIT_CARD',
    APPSTORE_IAP = 'APPSTORE_IAP',
    CREDIT_CARD_USING_SECONDARY_DEVICE = 'CREDIT_CARD_USING_SECONDARY_DEVICE',
    PAYPAL = 'PAYPAL',
    UNKNOWN = 'UNKNOWN',
}

export enum PERIOD_UNIT {
    YEAR = 'year',
    MONTH = 'month',
    WEEK = 'week',
    DAY = 'day',
    HOUR = 'hour',
    MINUTE = 'minute',
    SECOND = 'second',
    MS = 'ms',
}

export enum DRM_TYPE {
    WIDEVINE = 'Widevine',
    FAIRPLAY = 'Fairplay',
    PLAYREADY = 'Playready',
    OTHER = 'Other',
    UNKNOWN = 'Unknown',
}

export enum SERVICE_TYPE {
    // Purchasable Pack in app, and offer in app contents (Channel, VOD. Most of Carriers Packs)
    IN_APP = 0,
    // Purchasable Pack in app, but offer service in external app (Could be Netflix)
    PARTNER_APP = 1,
    // Pack is an application that must be purchased directly inside the application (Ex: Amazon Prime, Disney+)
    PARTNER_APP_EXTERNAL = 2,
    // A Bundle gather several packs
    BUNDLE = 3,
}

export enum ACTIVATION_MODE {
    // It means user need to activate his subscription by entering a Redeem CODE OR launching a Deeplink
    DEEPLINK = 'DEEPLINK',
    // After a purchase, the user needs to activate his subscription clicking in the link send to user's email
    EMAIL = 'EMAIL',
    // Means of activation not specified by the backend
    UNDEFINED = 'UNDEFINED',
}

export enum ACTIVATION_STATUS {
    // User has 'already' activated his subscription
    ACTIVATED = 'ACTIVE',
    // User need to activate his subscription
    ACTIVATION_REQUIRED = 'ACTIVATION_REQUIRED',
    // Activation is not required for this packages (Most of our customers except M1)
    ACTIVATION_NOT_REQUIRED = 'ACTIVATION_NOT_REQUIRED',
    // This package needs to be activated but waiting backend response to offer activation to the user
    WAITING_ACTIVATION_LINK = 'WAITING_ACTIVATION_LINK',
    // This package was activated but has been cancelled or being cancelled
    CANCELLED = 'CANCELLED',
}

export enum PRODUCT_GENERATION {
    UNDEFINED = 'UNDEFINED',
    NEW_GENERATION = 'NEW_GENERATION',
    LEGACY = 'LEGACY',
}

export enum NODE_TYPE {
    SERIESNODE = 'SeriesNode',
}

export enum CHANNELS_REVISION_STATUS {
    CHANNELS_REVISION_OK = 'ok',
    CHANNELS_REVISION_EMPTY = 'error-empty-data',
    CHANNELS_REVISION_INVALID = 'error-invalid-data',
}
