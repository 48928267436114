import { ISoccerContestant, ISoccerFixture, Score } from '@typings/opta'
import { MATCH_STATUSES } from 'enums'
import { Tile } from 'models'

export class SoccerFixture extends Tile {
    competitionName: string
    contestant: ISoccerContestant[]
    week: string
    startDate: Date
    startTime: string
    scores?: Score
    matchStatus?: `${MATCH_STATUSES}`

    constructor(props: ISoccerFixture) {
        super(props)
        this.competitionName = props.competitionName
        this.contestant = props.contestant
        this.week = props.week
        this.startDate = props.date
        this.startTime = props.time
        this.title = props.title
        this.description = props.description
        this.format = 'landscape'
        this.scores = props.scores
        this.matchStatus = props.matchStatus
    }

    isSoccerFixture() {
        return true
    }
}
