import { IDeeplink } from '@typings/tile'
import { Tile } from './Tile'

/**
 * Class Deeplink description
 * This class is used mainly for non-mobile products (stb)
 * It contains all data necessary to display and run a deeplink to an app
 * @property {String} command package name ot the app
 * @property {String} param extra params used to specify deeplink
 */

export class Deeplink extends Tile {
    command: string
    param: string

    constructor(props: IDeeplink) {
        super(props)

        this.command = props.command
        this.param = props.param
    }

    isDeeplink() {
        return true
    }
}
