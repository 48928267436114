import Constants from 'api-constants'
import { ChannelsRevision } from 'models'

export const channelsRevisionsErrFactory = (err) => ({ status: `${err?.code || 'unknown err'}` })

const Y2K_SEC_EPOCH = 946684800

export const channelsRevisionsFactory = (channnelsRevs, lastChannelsRevisions) => {
    let props = {}
    if (!channnelsRevs?.resources?.epg_revision) {
        props = { status: Constants.revisionStatus.CHANNELS_REVISION_EMPTY }
    } else {
        let maxEpgGlobalRevSec = Y2K_SEC_EPOCH
        const data = channnelsRevs.resources
        const epgRevisions = data.epg_revision.reduce((acc, chanEpgRev) => {
            const chanId = chanEpgRev?.id || ''
            if (!chanId) {
                return acc
            }
            const globalRev = chanEpgRev?.global_revision || 0
            maxEpgGlobalRevSec = Math.max(maxEpgGlobalRevSec, globalRev)
            const _shortTermRevision = chanEpgRev?.short_term_revision || globalRev
            const _longTermRevision = chanEpgRev?.long_term_revision || globalRev
            if (_shortTermRevision > Y2K_SEC_EPOCH && _longTermRevision > Y2K_SEC_EPOCH)
                acc[chanId] = {
                    shortTermRevision: _shortTermRevision * 1000,
                    longTermRevision: _longTermRevision * 1000,
                }
            return acc
        }, {})

        if (
            Object.keys(epgRevisions).length &&
            Number.isFinite(data.channel_revision) &&
            Number.isFinite(data.short_long_overlap_day)
        ) {
            const channelListRevision = data.channel_revision * 1000
            const maxEpgGlobalRevMS = maxEpgGlobalRevSec * 1000
            props = {
                status: Constants.revisionStatus.CHANNELS_REVISION_OK,
                hasChannelListUpdate: channelListRevision > lastChannelsRevisions,
                hasEPGUpdate: maxEpgGlobalRevMS > lastChannelsRevisions,

                channelListRevision,
                shortLongOverlapDay: data.short_long_overlap_day,
                channelEpgRevision: epgRevisions,
            }
        } else {
            props = { status: Constants.revisionStatus.CHANNELS_REVISION_INVALID }
        }
    }

    return new ChannelsRevision(props)
}
