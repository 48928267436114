export default (props = {}) => <svg width="60" height="120" viewBox="0 0 60 120" fill="none" xmlns="http://www.w3.org/2000/svg"{...props}>
<g id="Arrow">
<g id="Header" filter="url(#filter0_b_122_286)">
<rect width="50" height="50" rx="25" transform="matrix(-1 0 0 1 55 35)" fill="#21262A" fill-opacity="0.5"/>
</g>
<g id="Arrows / ArrowLeft" opacity="0.5">
<path id="Vector" d="M23.8211 49.4867C23.1704 48.8416 23.1704 47.7956 23.8211 47.1505C24.4717 46.5053 25.5267 46.5054 26.1773 47.1505L35.6024 56.4956C37.5544 58.431 37.5544 61.5689 35.6024 63.5044L26.1773 72.8494C25.5267 73.4946 24.4717 73.4945 23.8211 72.8494C23.1704 72.2043 23.1704 71.1583 23.8211 70.5132L33.2461 61.1681C33.8968 60.5229 33.8968 59.477 33.2461 58.8318L23.8211 49.4867Z" fill="white"/>
</g>
</g>
<defs>
<filter id="filter0_b_122_286" x="-55" y="-25" width="170" height="170" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feGaussianBlur in="BackgroundImageFix" stdDeviation="30"/>
<feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_122_286"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_122_286" result="shape"/>
</filter>
</defs>
</svg>
