/**
 * Takes seconds as parameter and returns hours, minutes and seconds.
 *
 * @param   {?number} seconds
 *
 * @returns {object} hours, minutes and seconds
 */
function getHMS(_secs) {
    const vals = {
        hours: undefined,
        minutes: undefined,
        seconds: undefined,
    }

    if (isNaN(_secs) || _secs === 0) return vals

    const secs = Math.abs(_secs)

    let hours = Math.floor(secs / 3600)
    let minutes = Math.floor((secs - hours * 3600) / 60)
    let seconds = Math.floor(secs - hours * 3600 - minutes * 60)

    vals.hours = String(hours).padStart(2, '0')
    vals.minutes = String(minutes).padStart(2, '0')
    vals.seconds = String(seconds).padStart(2, '0')

    return vals
}

export default getHMS
