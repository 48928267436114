import Constants from 'api-constants'
import ConfigHelper from 'framework/helpers/config'
import { has } from 'lodash'

export const isServiceConfigured = () => {
    const { clientId, clientKey, clientSecret } = ConfigHelper.getInstance().getConfig('ooredoo')
    const { apigeeUrl } = ConfigHelper.getInstance().getConfig('urls')
    const isServiceConfigured = !!clientId && !!clientKey && !!clientSecret && !!apigeeUrl

    return isServiceConfigured
}

export const getHeaders = () => {
    const { clientId, clientKey, clientSecret } = ConfigHelper.getInstance().getConfig('ooredoo')

    return {
        'Client-ID': clientId,
        'Client-Key': clientKey,
        'Client-Secret': clientSecret,
        'Content-Type': Constants.httpHeader.ContentType.APPLICATION_JSON,
        // 'User-Agent': userAgent, // TODO: can't set user-agent manually due to security reasons
    }
}

/**
 * Returns user's obfuscated phone number
 * Get 987654321 and return *****4321 according to `phoneNumberVisibleDigits` setting in the config
 *
 * @param {string | number} phoneNumber phone number to obfuscate
 * @returns {string} obfuscated phone number
 */
export const obfuscatePhoneNumber = (phoneNumber = '') => {
    if (!phoneNumber) return

    const { phoneNumberVisibleDigits } = ConfigHelper.getInstance().getConfig('ooredoo')

    const stringNumber = typeof phoneNumber !== 'string' ? phoneNumber.toString() : phoneNumber

    const unencryptedPartOfNumber = stringNumber.substring(
        stringNumber.length - phoneNumberVisibleDigits,
        stringNumber.length
    )

    let encryptedPartOfNumber = stringNumber
        .substring(0, stringNumber.length - phoneNumberVisibleDigits)
        .replace(/./g, '*')

    return encryptedPartOfNumber + unencryptedPartOfNumber
}

/**
 * Helper for extracting landline number from the response
 *
 * @param {Object} response response object from 'getUserPhoneNumber()'
 * @returns {string|undefined}
 */
export const extractPhoneNumber = (response = {}) => {
    if (!has(response, 'Data.Parameter')) return

    const USER_PHONE_NUMBER_PARAMETER_NAME = 'PrimaryContact'

    const userPhoneNumber = response.Data.Parameter.find(
        (parameter) => parameter && parameter.Name === USER_PHONE_NUMBER_PARAMETER_NAME
    )

    if (userPhoneNumber) return userPhoneNumber.Value

    const LAND_LINE_PARAMETER_NAME = 'ServiceIdentifier'

    const landLineNumber = response.Data.Parameter.find(
        (parameter) => parameter && parameter.Name === LAND_LINE_PARAMETER_NAME
    )

    if (landLineNumber) return landLineNumber.Value

    return undefined
}
