import { GameStats, IMatchStats, LineUp, Score, Team } from '@typings/opta'
import { MATCH_STATUSES } from 'enums'
import { Abstract } from './Abstract'

export class MatchStats extends Abstract {
    id: string
    status: `${MATCH_STATUSES}`
    winner: string
    minutesPlayed: number
    secondsPlayed: number
    date: string
    scores: Score
    teams: Team[]
    gameStats: GameStats[]
    lineUp: LineUp[]

    constructor(props: IMatchStats) {
        super()
        const computePropValue = this._makeComputePropValue(props)

        this.id = props.id
        this.status = computePropValue('status', 'Fixture')
        this.winner = props.winner
        this.minutesPlayed = props.minutesPlayed
        this.secondsPlayed = props.secondsPlayed
        this.date = props.date
        this.scores = computePropValue('scores', { away: 0, home: 0 })
        this.teams = computePropValue('teams', [])
        this.gameStats = computePropValue('gameStats', [])
        this.lineUp = computePropValue('lineUp', [])
    }

    static MATCH_STATUSES = MATCH_STATUSES
}
