import { has } from 'lodash'
import { UserWallet } from 'models'

export const userWalletFactory = (creditSummary = {}) => {
    if (!creditSummary) return {}

    const {
        WalletName,
        EarnedCredits,
        PurchasedCredits,
        UsedCredits,
        ReservedCredits,
        AvailableCredits,
    } = creditSummary

    return new UserWallet({
        walletName: WalletName,
        // According to Ooredoo: Total Credits = Earned Credits + Purchased Credits
        totalCredits: +EarnedCredits + +PurchasedCredits,
        purchasedCredits: +PurchasedCredits,
        usedCredits: +UsedCredits,
        reservedCredits: +ReservedCredits,
        availableCredits: +AvailableCredits,
        phoneNumbersList: {
            phoneNumbers: has(creditSummary, 'ServiceNumbersList.ServiceNumbers')
                ? creditSummary.ServiceNumbersList.ServiceNumbers
                : '',
        },
    })
}
