/**
 * Iterate through the object keys to clear them it associate value match
 * the clear options asked.
 *
 * **important:** `obj` is modified in place (by reference).
 *
 * @param {Object} obj - The object where keys should be removed
 * @param {Object} [options]
 * @param {Boolean} [options.clearUndefined=false] - Remove keys when value is `undefined`
 * @param {Boolean} [options.clearNull=false] - Remove keys when value is `null`
 * @param {Boolean} [options.clearEmpty=false] - Remove keys when value is an empty string `''`
 */
export const removeKeys = (obj, options = {}) => {
    const { clearUndefined, clearNull, clearEmpty } = options

    // abort process if all clear options are falsy (nothing to do)
    if (!clearUndefined && !clearNull && !clearEmpty) return obj

    Object.keys(obj).forEach((key) => {
        const value = obj[key]
        const isUndefined = value === undefined
        const isNull = value === null
        const isEmpty = typeof value === 'string' && value.length === 0

        if ((clearUndefined && isUndefined) || (clearNull && isNull) || (clearEmpty && isEmpty)) {
            delete obj[key]
        }
    })

    return obj
}

/**
 * capitalize the first letter
 * @method capFirstLetter
 * @param  {string} str - the string to capitalize
 * @return {string} the capitalized string
 */
export const capFirstLetter = (str) => str[0].toUpperCase() + str.slice(1)

/**
 * capitalize first letter of each word
 * @param {String} str the sting to capitalize
 * @return {String} The string where each first letter was capitalized
 */
export const capFirstLetterEveryWord = (str) => {
    if (!str) return null
    return str
        .trim()
        .split(/\s/)
        .filter((x) => !!x && x.length > 0)
        .map(capFirstLetter)
        .join(' ')
}
