// import {logActionsMiddleware} from './middlewares/logActionsMiddleware'
import {Firebase, getBrowserName, getDeviceData} from '../utils'
import storage from '../utils/storage'
import {logActionsMiddleware} from './middlewares/logActionsMiddleware'
import {AnalyticsStoreWrapper} from '../utils/analyticsStoreWrapper'

declare global {
    interface Window {
        newrelic: {
            setUserId: (userId: any) => {},
            setCustomAttribute: (key:any, value:any) => {},
        }
    }
}

export const getStore = (configureStoreFactory: (params: any) => any, config: any, voltApi: any) => {
    const {ConfigurationApi, Constants, Models, VoltError, VoltApi} = voltApi

    const configureStore = configureStoreFactory({
        setUserStorageKey: (userId: string) => {},
        initialState: {},
        config,
        Constants,
        ConfigurationApi,
        env: {},
        // eventListenergetter: client.helpers.getPlayerEventListener,
        isTablet: false,
        // licenseServerUrlGetter: client.helpers.getLicenseServerUrl,
        Models,
        OS: getBrowserName(voltApi),
        // streamUrlConverter: streamUrl => new Promise(() => streamUrl),
        // streamUrlConverter: client.helpers.convertStreamUrl,
        VoltError,
        assets: {
            images: {},
        },
        // customNodes: customNodes(),
    })

    const getFirebaseAnalyticsInstance = () => {
        const firebase = Firebase.getInstance(config?.firebaseConfig)
        if (firebase?.analytics) {
            return new AnalyticsStoreWrapper(firebase?.analytics, true, firebase.emit, false)
        }
    }
    return configureStore({
        deviceProperties: {
            platform: getBrowserName(voltApi),
        },
        isMobile: false,
        initialState: {},
        getApiInstance: () =>
            new VoltApi({
                ...config,
                platform: getBrowserName(voltApi),
                analyticsFirebaseInstance: getFirebaseAnalyticsInstance(),
            }),
        crashLogger: {
            registerUser: ({
                customerId,
                id,
                profileId,
                analyticsUserId,
                hashedUserId,
                hashedCustomerId,
                deviceData
            }: any) => {
                if(!config?.isNewRelicDisabled) {
                    analyticsUserId && window.newrelic.setUserId(analyticsUserId)
                    hashedUserId && window.newrelic.setCustomAttribute('analytics_id', hashedUserId)
                    hashedCustomerId && window.newrelic.setCustomAttribute('analytics_id2', hashedCustomerId)
                    window.newrelic.setCustomAttribute('analytics_platform', getBrowserName(voltApi))
                }
            },
            unregisterUser: () => {},
            log: () => {},
            recordError: () => {},
            crash: () => {},
        },
        errorLogger: undefined,
        isInternetReachable: () => Promise.resolve(true),
        OS: getBrowserName(voltApi),
        isTablet: false,
        exitApp: () => {},
        NavigationService: () => {},
        storage,
        screenDimensions: {
            width: window.innerWidth,
            height: window.innerHeight,
        },
        debug: {
            recordReduxChanges: true,
        },
        getDeviceData: () => new Promise((resolve, reject) => resolve(getDeviceData())),
        extraMiddlewares: [logActionsMiddleware],
        // enableReduxLogger: true, // for dev only})
    })
}
