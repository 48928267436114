import HuaweiTypes from '../HuaweiTypes'
import { Subscription } from 'models'

/**
 * @class Huawei Product
 */
export class HuaweiSubscribedProduct {
    constructor(props) {
        /**
         * @type {String}
         * Product ID.
         */
        this.productID = props.productID

        /**
         * @type {String}
         * Product Code.
         */
        this.productCode = props.productCode

        /**
         * @type {String}
         * Product name
         */
        this.productName = props.productName

        /**
         * @type {HuaweiTypes.productDurationType}
         *
         */
        this.productType = props.productType

        /**
         * @type {String}
         * Product description.
         */
        this.productDesc = props.productDesc

        /**
         * @type {String}
         * Primary key of a subscription relationship.
         */
        this.productOrderKey = props.productOrderKey

        /**
         * @type {String}
         * Indicates whether a product is an EST product.
         */
        this.isEst = HuaweiTypes.parseIBoolean(props.isEst)

        /**
         * @type {HuaweiTypes.servicePayType}
         *
         */
        this.servicePayType = props.servicePayType

        /**
         * @type {String}
         * 	Amount of money or number of bonus points used to subscribe to a product.
         * If servicePayType is set to CASH, this parameter indicates the amount of money for the subscription, in minimum currency unit.
         * If servicePaytype is set to POINTS, this parameter indicates the number of bonus points for the subscription.
         * When Huawei Billing is not connected and the price cannot be obtained, the value 0 is returned by default.
         */
        this.price = HuaweiTypes.parseInt(props.price)

        /**
         * @type {String}
         * Time when a subscription relationship takes effect. The value is the total number of milliseconds since 1970-01-01 00:00:00.
         */
        this.startTime = HuaweiTypes.parseInt(props.startTime)

        /**
         * @type {String}
         * Time when a subscription relationship expires. The value is the total number of milliseconds since 1970-01-01 00:00:00.
         */
        this.orderTime = HuaweiTypes.parseInt(props.orderTime)

        /**
         * @type {String}
         * Time when a subscription relationship expires. The value is the total number of milliseconds since 1970-01-01 00:00:00.
         */
        this.endTime = HuaweiTypes.parseInt(props.endTime)

        /**
         * @type {String}
         * Indicates whether product subscription can be automatically renewed.
         */
        this.isAutoExtend = HuaweiTypes.parseIBoolean(props.isAutoExtend)

        /**
         * @type {String}
         *
         */
        this.residualChooseNum = props.residualChooseNum

        /**
         * @type {HuaweiTypes.orderState}
         *
         */
        this.orderState = props.orderState

        /**
         * @type {String}
         * Indicates whether a package is a main package.
         */
        this.isMainPackage = HuaweiTypes.parseIBoolean(props.isMainPackage)

        /**
         * @type {String}
         * Indicates whether a product is an A La Carte product.
         */
        this.isAlacarte = HuaweiTypes.parseIBoolean(props.isAlacarte)

        /**
         * @type {String}
         * Subscriber sequence number.
         */
        this.subscriberSn = props.subscriberSn

        /**
         * @type {String}
         *
         */
        this.thumbnail =
            (props.picture && props.picture.posters && props.picture.posters[0]) || undefined

        /**
         * @type {String}
         * Indicates whether the EST product is used.
         */
        this.isUsed = HuaweiTypes.parseIBoolean(props.isUsed)

        /**
         * @type {HuaweiTypes.chargeMode}
         *
         */
        this.chargeMode = props.chargeMode

        /**
         * @type {String}
         * Cycle length of a duration-based product.
         * For example, if chargeMode is set to 13 (indicating a multimonth package product) and periodLength is set to 2 for a product, the subscription cycle of the product is two months.
         * If chargeMode is set to 0, 18, 19 or 21, this parameter has a fixed value of 1.
         */
        this.periodLength = HuaweiTypes.parseInt(props.periodLength)

        /**
         * @type {String}
         * Content ID related to that product. Available only for TVOD Purchase...
         */
        this.transactionalContentId = props.priceObjectDetail && props.priceObjectDetail.ID
        /**
         * @type {HuaweiTypes.contentTypes}
         * Content ID related to that product. Available only for TVOD Purchase...
         */
        this.transactionalContentType =
            props.priceObjectDetail && props.priceObjectDetail.contentType

        /**
         * @type {Boolean} Ooredoo specific indicates that it is ooredoo next generation
         */
        this.productGeneration = HuaweiTypes.isNewGenProduct(props.customFields)
            ? Subscription.PRODUCT_GENERATION.NEW_GENERATION
            : HuaweiTypes.isOldGenProduct(props.customFields)
            ? Subscription.PRODUCT_GENERATION.LEGACY
            : Subscription.PRODUCT_GENERATION.UNDEFINED
    }
}
