export default {
    matchInfo: {
        id: '7c2f1r3fe7code6ijoi1xzh1w',
        coverageLevel: '15',
        date: '2022-08-28Z',
        time: '15:30:00Z',
        localDate: '2022-08-28',
        localTime: '16:30:00',
        week: '4',
        numberOfPeriods: 2,
        periodLength: 45,
        lastUpdated: '2022-08-28T15:38:51Z',
        description: 'Nottingham Forest vs Tottenham Hotspur',
        sport: {
            id: '289u5typ3vp4ifwh5thalohmq',
            name: 'Soccer',
        },
        ruleset: {
            id: '79plas4983031idr6vw83nuel',
            name: 'Men',
        },
        competition: {
            id: '2kwbbcootiqqgmrzs6o5inle5',
            name: 'Premier League',
            competitionCode: 'EPL',
            competitionFormat: 'Domestic league',
            country: {
                id: '1fk5l4hkqk12i7zske6mcqju6',
                name: 'England',
            },
        },
        tournamentCalendar: {
            id: '80foo89mm28qjvyhjzlpwj28k',
            startDate: '2022-08-05Z',
            endDate: '2023-05-28Z',
            name: '2022/2023',
        },
        stage: {
            id: '80qbeanalyj5cvxikkq351iqc',
            formatId: 'e2q01r9o9jwiq1fls93d1sslx',
            startDate: '2022-08-05Z',
            endDate: '2023-05-28Z',
            name: 'Regular Season',
        },
        contestant: [
            {
                id: '1qtaiy11gswx327s0vkibf70n',
                name: 'Nottingham Forest',
                shortName: 'Nottm Forest',
                officialName: 'Nottingham Forest FC',
                code: 'NFO',
                position: 'home',
                country: {
                    id: '1fk5l4hkqk12i7zske6mcqju6',
                    name: 'England',
                },
            },
            {
                id: '22doj4sgsocqpxw45h607udje',
                name: 'Tottenham Hotspur',
                shortName: 'Tottenham',
                officialName: 'Tottenham Hotspur FC',
                code: 'TOT',
                position: 'away',
                country: {
                    id: '1fk5l4hkqk12i7zske6mcqju6',
                    name: 'England',
                },
            },
        ],
        venue: {
            id: '6ccyj8bk3iv2mc01xe5rtch3r',
            neutral: 'no',
            longName: 'The City Ground',
            shortName: 'The City Ground',
        },
    },
    liveData: {
        matchDetails: {
            matchTime: 9,
            periodId: 1,
            matchStatus: 'Playing',
            period: [
                {
                    id: 1,
                    start: '2022-08-28T15:30:17Z',
                },
            ],
            scores: {
                ft: {
                    home: 0,
                    away: 1,
                },
                total: {
                    home: 0,
                    away: 1,
                },
            },
        },
        goal: [
            {
                contestantId: '22doj4sgsocqpxw45h607udje',
                periodId: 1,
                timeMin: 5,
                timeMinSec: '4:58',
                lastUpdated: '2022-08-28T15:36:23Z',
                timestamp: '2022-08-28T15:35:15Z',
                type: 'G',
                scorerId: 'b9g4qurpll4wizajo95c406hh',
                scorerName: 'H. Kane',
                assistPlayerId: 'dlf31m2lrfl0biop1demgvh7t',
                assistPlayerName: 'D. Kulusevski',
                optaEventId: '2452502131',
                homeScore: 0,
                awayScore: 1,
            },
        ],
        lineUp: [
            {
                contestantId: '1qtaiy11gswx327s0vkibf70n',
                formationUsed: '3421',
                player: [
                    {
                        playerId: '5z8ycnd0smdkumi5fgtzf7c9h',
                        firstName: 'Dean Bradley',
                        lastName: 'Henderson',
                        shortFirstName: 'Dean',
                        shortLastName: 'Henderson',
                        matchName: 'D. Henderson',
                        shirtNumber: 1,
                        position: 'Goalkeeper',
                        positionSide: 'Centre',
                        formationPlace: '1',
                        stat: [
                            {
                                type: 'accurateChippedPass',
                                value: '2',
                            },
                            {
                                type: 'accurateFwdZonePass',
                                value: '2',
                            },
                            {
                                type: 'accuratePass',
                                value: '2',
                            },
                            {
                                type: 'attemptsConcededObox',
                                value: '1',
                            },
                            {
                                type: 'longPassOwnToOpp',
                                value: '3',
                            },
                            {
                                type: 'fwdPass',
                                value: '3',
                            },
                            {
                                type: 'goalsConcededObox',
                                value: '1',
                            },
                            {
                                type: 'possLostAll',
                                value: '1',
                            },
                            {
                                type: 'touches',
                                value: '3',
                            },
                            {
                                type: 'totalChippedPass',
                                value: '3',
                            },
                            {
                                type: 'longPassOwnToOppSuccess',
                                value: '2',
                            },
                            {
                                type: 'totalFwdZonePass',
                                value: '3',
                            },
                            {
                                type: 'totalLongBalls',
                                value: '3',
                            },
                            {
                                type: 'openPlayPass',
                                value: '3',
                            },
                            {
                                type: 'totalPass',
                                value: '3',
                            },
                            {
                                type: 'successfulOpenPlayPass',
                                value: '2',
                            },
                            {
                                type: 'gameStarted',
                                value: '1',
                            },
                            {
                                type: 'accurateLongBalls',
                                value: '2',
                            },
                            {
                                type: 'goalsConceded',
                                value: '1',
                            },
                            {
                                type: 'passesLeft',
                                value: '2',
                            },
                            {
                                type: 'possLostCtrl',
                                value: '1',
                            },
                            {
                                type: 'winningGoal',
                                value: '0',
                            },
                            {
                                type: 'timesTackled',
                                value: '0',
                            },
                            {
                                type: 'formationPlace',
                                value: '1',
                            },
                        ],
                    },
                    {
                        playerId: '12cgiy0t282ywdbr5j78hrxsl',
                        firstName: 'Scott',
                        lastName: 'Fraser McKenna',
                        shortFirstName: 'Scott',
                        shortLastName: 'McKenna',
                        matchName: 'S. McKenna',
                        shirtNumber: 26,
                        position: 'Defender',
                        positionSide: 'Left/Centre',
                        formationPlace: '4',
                        stat: [
                            {
                                type: 'goalsConceded',
                                value: '1',
                            },
                            {
                                type: 'passesLeft',
                                value: '1',
                            },
                            {
                                type: 'possWonDef3rd',
                                value: '2',
                            },
                            {
                                type: 'ballRecovery',
                                value: '2',
                            },
                            {
                                type: 'totalBackZonePass',
                                value: '4',
                            },
                            {
                                type: 'rightsidePass',
                                value: '3',
                            },
                            {
                                type: 'totalFwdZonePass',
                                value: '1',
                            },
                            {
                                type: 'openPlayPass',
                                value: '5',
                            },
                            {
                                type: 'gameStarted',
                                value: '1',
                            },
                            {
                                type: 'successfulOpenPlayPass',
                                value: '5',
                            },
                            {
                                type: 'totalPass',
                                value: '5',
                            },
                            {
                                type: 'accurateBackZonePass',
                                value: '4',
                            },
                            {
                                type: 'fwdPass',
                                value: '1',
                            },
                            {
                                type: 'goalsConcededObox',
                                value: '1',
                            },
                            {
                                type: 'touches',
                                value: '6',
                            },
                            {
                                type: 'accurateFwdZonePass',
                                value: '1',
                            },
                            {
                                type: 'accuratePass',
                                value: '5',
                            },
                            {
                                type: 'backwardPass',
                                value: '1',
                            },
                            {
                                type: 'attemptsConcededObox',
                                value: '1',
                            },
                            {
                                type: 'accurateThrows',
                                value: '1',
                            },
                            {
                                type: 'totalThrows',
                                value: '1',
                            },
                            {
                                type: 'winningGoal',
                                value: '0',
                            },
                            {
                                type: 'timesTackled',
                                value: '0',
                            },
                            {
                                type: 'formationPlace',
                                value: '4',
                            },
                        ],
                    },
                    {
                        playerId: 'av7e2wk5ub7qnh3sqcvssvh79',
                        firstName: 'Steve Anthony',
                        lastName: 'Cook',
                        shortFirstName: 'Steve',
                        shortLastName: 'Cook',
                        matchName: 'S. Cook',
                        shirtNumber: 3,
                        position: 'Defender',
                        positionSide: 'Centre',
                        formationPlace: '5',
                        stat: [
                            {
                                type: 'openPlayPass',
                                value: '6',
                            },
                            {
                                type: 'totalLongBalls',
                                value: '2',
                            },
                            {
                                type: 'rightsidePass',
                                value: '3',
                            },
                            {
                                type: 'totalFwdZonePass',
                                value: '2',
                            },
                            {
                                type: 'accurateLongBalls',
                                value: '2',
                            },
                            {
                                type: 'interception',
                                value: '1',
                            },
                            {
                                type: 'gameStarted',
                                value: '1',
                            },
                            {
                                type: 'totalPass',
                                value: '6',
                            },
                            {
                                type: 'successfulOpenPlayPass',
                                value: '6',
                            },
                            {
                                type: 'longPassOwnToOppSuccess',
                                value: '2',
                            },
                            {
                                type: 'totalChippedPass',
                                value: '1',
                            },
                            {
                                type: 'totalBackZonePass',
                                value: '4',
                            },
                            {
                                type: 'passesLeft',
                                value: '1',
                            },
                            {
                                type: 'goalsConceded',
                                value: '1',
                            },
                            {
                                type: 'attemptsConcededObox',
                                value: '1',
                            },
                            {
                                type: 'accurateFwdZonePass',
                                value: '2',
                            },
                            {
                                type: 'accurateChippedPass',
                                value: '1',
                            },
                            {
                                type: 'accuratePass',
                                value: '6',
                            },
                            {
                                type: 'goalsConcededObox',
                                value: '1',
                            },
                            {
                                type: 'leftsidePass',
                                value: '1',
                            },
                            {
                                type: 'touches',
                                value: '9',
                            },
                            {
                                type: 'longPassOwnToOpp',
                                value: '2',
                            },
                            {
                                type: 'interceptionWon',
                                value: '1',
                            },
                            {
                                type: 'fwdPass',
                                value: '2',
                            },
                            {
                                type: 'accurateBackZonePass',
                                value: '4',
                            },
                            {
                                type: 'winningGoal',
                                value: '0',
                            },
                            {
                                type: 'timesTackled',
                                value: '0',
                            },
                            {
                                type: 'formationPlace',
                                value: '5',
                            },
                        ],
                    },
                    {
                        playerId: '1tuivl8zdnsaz8vbed2z582qx',
                        firstName: 'Joseph Adrian',
                        lastName: 'Worrall',
                        shortFirstName: 'Joe',
                        shortLastName: 'Worrall',
                        matchName: 'J. Worrall',
                        shirtNumber: 4,
                        position: 'Defender',
                        positionSide: 'Centre/Right',
                        formationPlace: '6',
                        captain: 'yes',
                        stat: [
                            {
                                type: 'successfulFinalThirdPasses',
                                value: '1',
                            },
                            {
                                type: 'passesLeft',
                                value: '1',
                            },
                            {
                                type: 'goalsConceded',
                                value: '1',
                            },
                            {
                                type: 'finalThirdEntries',
                                value: '1',
                            },
                            {
                                type: 'possLostCtrl',
                                value: '2',
                            },
                            {
                                type: 'totalFinalThirdPasses',
                                value: '2',
                            },
                            {
                                type: 'totalBackZonePass',
                                value: '5',
                            },
                            {
                                type: 'ballRecovery',
                                value: '1',
                            },
                            {
                                type: 'totalChippedPass',
                                value: '1',
                            },
                            {
                                type: 'openPlayPass',
                                value: '8',
                            },
                            {
                                type: 'totalFwdZonePass',
                                value: '3',
                            },
                            {
                                type: 'rightsidePass',
                                value: '3',
                            },
                            {
                                type: 'successfulOpenPlayPass',
                                value: '6',
                            },
                            {
                                type: 'totalPass',
                                value: '8',
                            },
                            {
                                type: 'gameStarted',
                                value: '1',
                            },
                            {
                                type: 'longPassOwnToOpp',
                                value: '1',
                            },
                            {
                                type: 'fwdPass',
                                value: '1',
                            },
                            {
                                type: 'accurateBackZonePass',
                                value: '5',
                            },
                            {
                                type: 'possWonAtt3rd',
                                value: '1',
                            },
                            {
                                type: 'goalsConcededObox',
                                value: '1',
                            },
                            {
                                type: 'touches',
                                value: '8',
                            },
                            {
                                type: 'possLostAll',
                                value: '2',
                            },
                            {
                                type: 'headPass',
                                value: '1',
                            },
                            {
                                type: 'leftsidePass',
                                value: '3',
                            },
                            {
                                type: 'accurateChippedPass',
                                value: '1',
                            },
                            {
                                type: 'accurateFwdZonePass',
                                value: '1',
                            },
                            {
                                type: 'backwardPass',
                                value: '1',
                            },
                            {
                                type: 'accuratePass',
                                value: '6',
                            },
                            {
                                type: 'passesRight',
                                value: '1',
                            },
                            {
                                type: 'attemptsConcededObox',
                                value: '1',
                            },
                            {
                                type: 'winningGoal',
                                value: '0',
                            },
                            {
                                type: 'timesTackled',
                                value: '0',
                            },
                            {
                                type: 'formationPlace',
                                value: '6',
                            },
                        ],
                    },
                    {
                        playerId: '1vklxifs8qpj71s4reu3pkw5x',
                        firstName: 'Harry Stefano',
                        lastName: 'Toffolo',
                        shortFirstName: 'Harry',
                        shortLastName: 'Toffolo',
                        matchName: 'H. Toffolo',
                        shirtNumber: 15,
                        position: 'Midfielder',
                        positionSide: 'Left',
                        formationPlace: '3',
                        stat: [
                            {
                                type: 'possLostCtrl',
                                value: '3',
                            },
                            {
                                type: 'goalsConceded',
                                value: '1',
                            },
                            {
                                type: 'passesLeft',
                                value: '4',
                            },
                            {
                                type: 'successfulFinalThirdPasses',
                                value: '1',
                            },
                            {
                                type: 'successfulOpenPlayPass',
                                value: '2',
                            },
                            {
                                type: 'totalPass',
                                value: '4',
                            },
                            {
                                type: 'gameStarted',
                                value: '1',
                            },
                            {
                                type: 'totalFwdZonePass',
                                value: '5',
                            },
                            {
                                type: 'rightsidePass',
                                value: '1',
                            },
                            {
                                type: 'openPlayPass',
                                value: '4',
                            },
                            {
                                type: 'crosses18yardplus',
                                value: '1',
                            },
                            {
                                type: 'totalFinalThirdPasses',
                                value: '1',
                            },
                            {
                                type: 'touches',
                                value: '7',
                            },
                            {
                                type: 'possLostAll',
                                value: '3',
                            },
                            {
                                type: 'headPass',
                                value: '2',
                            },
                            {
                                type: 'leftsidePass',
                                value: '1',
                            },
                            {
                                type: 'fouledFinalThird',
                                value: '1',
                            },
                            {
                                type: 'goalsConcededObox',
                                value: '1',
                            },
                            {
                                type: 'fwdPass',
                                value: '2',
                            },
                            {
                                type: 'aerialWon',
                                value: '2',
                            },
                            {
                                type: 'wasFouled',
                                value: '1',
                            },
                            {
                                type: 'accurateThrows',
                                value: '1',
                            },
                            {
                                type: 'totalCrossNocorner',
                                value: '1',
                            },
                            {
                                type: 'totalThrows',
                                value: '1',
                            },
                            {
                                type: 'attemptsConcededObox',
                                value: '1',
                            },
                            {
                                type: 'accuratePass',
                                value: '2',
                            },
                            {
                                type: 'duelWon',
                                value: '3',
                            },
                            {
                                type: 'totalCross',
                                value: '1',
                            },
                            {
                                type: 'accurateFwdZonePass',
                                value: '2',
                            },
                            {
                                type: 'winningGoal',
                                value: '0',
                            },
                            {
                                type: 'timesTackled',
                                value: '0',
                            },
                            {
                                type: 'formationPlace',
                                value: '3',
                            },
                        ],
                    },
                    {
                        playerId: 'dcq0nu7mmecab5djjwg0n8ktm',
                        firstName: 'Lewis John',
                        lastName: "O'Brien",
                        shortFirstName: 'Lewis',
                        shortLastName: "O'Brien",
                        matchName: "L. O'Brien",
                        shirtNumber: 14,
                        position: 'Midfielder',
                        positionSide: 'Left/Centre',
                        formationPlace: '8',
                        stat: [
                            {
                                type: 'openPlayPass',
                                value: '7',
                            },
                            {
                                type: 'rightsidePass',
                                value: '2',
                            },
                            {
                                type: 'totalFwdZonePass',
                                value: '4',
                            },
                            {
                                type: 'gameStarted',
                                value: '1',
                            },
                            {
                                type: 'successfulOpenPlayPass',
                                value: '6',
                            },
                            {
                                type: 'totalPass',
                                value: '7',
                            },
                            {
                                type: 'totalFinalThirdPasses',
                                value: '3',
                            },
                            {
                                type: 'totalBackZonePass',
                                value: '3',
                            },
                            {
                                type: 'unsuccessfulTouch',
                                value: '1',
                            },
                            {
                                type: 'finalThirdEntries',
                                value: '2',
                            },
                            {
                                type: 'possLostCtrl',
                                value: '2',
                            },
                            {
                                type: 'successfulFinalThirdPasses',
                                value: '2',
                            },
                            {
                                type: 'passesLeft',
                                value: '3',
                            },
                            {
                                type: 'goalsConceded',
                                value: '1',
                            },
                            {
                                type: 'attemptsConcededObox',
                                value: '1',
                            },
                            {
                                type: 'accurateFwdZonePass',
                                value: '3',
                            },
                            {
                                type: 'backwardPass',
                                value: '1',
                            },
                            {
                                type: 'accuratePass',
                                value: '6',
                            },
                            {
                                type: 'goalsConcededObox',
                                value: '1',
                            },
                            {
                                type: 'leftsidePass',
                                value: '2',
                            },
                            {
                                type: 'touches',
                                value: '9',
                            },
                            {
                                type: 'possLostAll',
                                value: '2',
                            },
                            {
                                type: 'fwdPass',
                                value: '2',
                            },
                            {
                                type: 'turnover',
                                value: '1',
                            },
                            {
                                type: 'accurateBackZonePass',
                                value: '3',
                            },
                            {
                                type: 'winningGoal',
                                value: '0',
                            },
                            {
                                type: 'timesTackled',
                                value: '0',
                            },
                            {
                                type: 'formationPlace',
                                value: '8',
                            },
                        ],
                    },
                    {
                        playerId: 'ece7xph4emgawvh7mr56319ex',
                        firstName: 'Ryan James',
                        lastName: 'Yates',
                        shortFirstName: 'Ryan',
                        shortLastName: 'Yates',
                        matchName: 'R. Yates',
                        shirtNumber: 22,
                        position: 'Midfielder',
                        positionSide: 'Centre/Right',
                        formationPlace: '7',
                        stat: [
                            {
                                type: 'longPassOwnToOppSuccess',
                                value: '1',
                            },
                            {
                                type: 'totalFinalThirdPasses',
                                value: '1',
                            },
                            {
                                type: 'ballRecovery',
                                value: '2',
                            },
                            {
                                type: 'totalBackZonePass',
                                value: '3',
                            },
                            {
                                type: 'gameStarted',
                                value: '1',
                            },
                            {
                                type: 'totalPass',
                                value: '8',
                            },
                            {
                                type: 'successfulOpenPlayPass',
                                value: '5',
                            },
                            {
                                type: 'openPlayPass',
                                value: '6',
                            },
                            {
                                type: 'rightsidePass',
                                value: '1',
                            },
                            {
                                type: 'totalFwdZonePass',
                                value: '5',
                            },
                            {
                                type: 'goalsConceded',
                                value: '1',
                            },
                            {
                                type: 'possWonMid3rd',
                                value: '2',
                            },
                            {
                                type: 'possLostCtrl',
                                value: '1',
                            },
                            {
                                type: 'finalThirdEntries',
                                value: '1',
                            },
                            {
                                type: 'backwardPass',
                                value: '2',
                            },
                            {
                                type: 'accuratePass',
                                value: '7',
                            },
                            {
                                type: 'accurateFwdZonePass',
                                value: '4',
                            },
                            {
                                type: 'passesRight',
                                value: '1',
                            },
                            {
                                type: 'attemptsConcededObox',
                                value: '1',
                            },
                            {
                                type: 'fwdPass',
                                value: '2',
                            },
                            {
                                type: 'accurateBackZonePass',
                                value: '3',
                            },
                            {
                                type: 'longPassOwnToOpp',
                                value: '1',
                            },
                            {
                                type: 'leftsidePass',
                                value: '3',
                            },
                            {
                                type: 'possLostAll',
                                value: '1',
                            },
                            {
                                type: 'touches',
                                value: '8',
                            },
                            {
                                type: 'goalsConcededObox',
                                value: '1',
                            },
                            {
                                type: 'winningGoal',
                                value: '0',
                            },
                            {
                                type: 'timesTackled',
                                value: '0',
                            },
                            {
                                type: 'formationPlace',
                                value: '7',
                            },
                        ],
                    },
                    {
                        playerId: '3mgjcyaseuexxlssgatjgsand',
                        firstName: 'Neco Shay',
                        lastName: 'Williams',
                        shortFirstName: 'Neco',
                        shortLastName: 'Williams',
                        matchName: 'N. Williams',
                        shirtNumber: 7,
                        position: 'Midfielder',
                        positionSide: 'Right',
                        formationPlace: '2',
                        stat: [
                            {
                                type: 'attemptsConcededObox',
                                value: '1',
                            },
                            {
                                type: 'passesRight',
                                value: '1',
                            },
                            {
                                type: 'accuratePass',
                                value: '6',
                            },
                            {
                                type: 'backwardPass',
                                value: '2',
                            },
                            {
                                type: 'accurateFwdZonePass',
                                value: '3',
                            },
                            {
                                type: 'duelWon',
                                value: '1',
                            },
                            {
                                type: 'leftsidePass',
                                value: '5',
                            },
                            {
                                type: 'totalTackle',
                                value: '1',
                            },
                            {
                                type: 'touches',
                                value: '9',
                            },
                            {
                                type: 'possLostAll',
                                value: '2',
                            },
                            {
                                type: 'goalsConcededObox',
                                value: '1',
                            },
                            {
                                type: 'accurateBackZonePass',
                                value: '3',
                            },
                            {
                                type: 'wonTackle',
                                value: '1',
                            },
                            {
                                type: 'longPassOwnToOpp',
                                value: '1',
                            },
                            {
                                type: 'gameStarted',
                                value: '1',
                            },
                            {
                                type: 'successfulOpenPlayPass',
                                value: '6',
                            },
                            {
                                type: 'totalPass',
                                value: '7',
                            },
                            {
                                type: 'totalLongBalls',
                                value: '1',
                            },
                            {
                                type: 'totalFwdZonePass',
                                value: '4',
                            },
                            {
                                type: 'openPlayPass',
                                value: '7',
                            },
                            {
                                type: 'ballRecovery',
                                value: '2',
                            },
                            {
                                type: 'totalChippedPass',
                                value: '1',
                            },
                            {
                                type: 'totalBackZonePass',
                                value: '3',
                            },
                            {
                                type: 'totalFinalThirdPasses',
                                value: '2',
                            },
                            {
                                type: 'possLostCtrl',
                                value: '2',
                            },
                            {
                                type: 'finalThirdEntries',
                                value: '1',
                            },
                            {
                                type: 'goalsConceded',
                                value: '1',
                            },
                            {
                                type: 'successfulFinalThirdPasses',
                                value: '1',
                            },
                            {
                                type: 'possWonMid3rd',
                                value: '2',
                            },
                            {
                                type: 'winningGoal',
                                value: '0',
                            },
                            {
                                type: 'timesTackled',
                                value: '0',
                            },
                            {
                                type: 'formationPlace',
                                value: '2',
                            },
                        ],
                    },
                    {
                        playerId: '55yy0p6johtu9nxk90zsfctt5',
                        firstName: 'Morgan Anthony',
                        lastName: 'Gibbs-White',
                        shortFirstName: 'Morgan',
                        shortLastName: 'Gibbs-White',
                        matchName: 'M. Gibbs-White',
                        shirtNumber: 10,
                        position: 'Attacking Midfielder',
                        positionSide: 'Left/Centre',
                        formationPlace: '11',
                        stat: [
                            {
                                type: 'freekickCross',
                                value: '1',
                            },
                            {
                                type: 'goalsConceded',
                                value: '1',
                            },
                            {
                                type: 'passesLeft',
                                value: '1',
                            },
                            {
                                type: 'attemptsObox',
                                value: '1',
                            },
                            {
                                type: 'successfulFinalThirdPasses',
                                value: '3',
                            },
                            {
                                type: 'possLostCtrl',
                                value: '3',
                            },
                            {
                                type: 'finalThirdEntries',
                                value: '1',
                            },
                            {
                                type: 'duelLost',
                                value: '1',
                            },
                            {
                                type: 'totalBackZonePass',
                                value: '2',
                            },
                            {
                                type: 'attObxCentre',
                                value: '1',
                            },
                            {
                                type: 'attOpenplay',
                                value: '1',
                            },
                            {
                                type: 'totalFinalThirdPasses',
                                value: '3',
                            },
                            {
                                type: 'successfulOpenPlayPass',
                                value: '4',
                            },
                            {
                                type: 'totalPass',
                                value: '5',
                            },
                            {
                                type: 'gameStarted',
                                value: '1',
                            },
                            {
                                type: 'totalContest',
                                value: '1',
                            },
                            {
                                type: 'totalFwdZonePass',
                                value: '4',
                            },
                            {
                                type: 'openPlayPass',
                                value: '5',
                            },
                            {
                                type: 'penAreaEntries',
                                value: '3',
                            },
                            {
                                type: 'accurateBackZonePass',
                                value: '1',
                            },
                            {
                                type: 'fwdPass',
                                value: '2',
                            },
                            {
                                type: 'attRfTotal',
                                value: '1',
                            },
                            {
                                type: 'shotOffTarget',
                                value: '1',
                            },
                            {
                                type: 'aerialWon',
                                value: '1',
                            },
                            {
                                type: 'touches',
                                value: '8',
                            },
                            {
                                type: 'possLostAll',
                                value: '3',
                            },
                            {
                                type: 'headPass',
                                value: '1',
                            },
                            {
                                type: 'leftsidePass',
                                value: '2',
                            },
                            {
                                type: 'goalsConcededObox',
                                value: '1',
                            },
                            {
                                type: 'accuratePass',
                                value: '4',
                            },
                            {
                                type: 'backwardPass',
                                value: '1',
                            },
                            {
                                type: 'duelWon',
                                value: '1',
                            },
                            {
                                type: 'attOboxMiss',
                                value: '1',
                            },
                            {
                                type: 'totalCross',
                                value: '1',
                            },
                            {
                                type: 'accurateFwdZonePass',
                                value: '3',
                            },
                            {
                                type: 'attMissHigh',
                                value: '1',
                            },
                            {
                                type: 'totalCrossNocorner',
                                value: '1',
                            },
                            {
                                type: 'attemptsConcededObox',
                                value: '1',
                            },
                            {
                                type: 'totalScoringAtt',
                                value: '1',
                            },
                            {
                                type: 'winningGoal',
                                value: '0',
                            },
                            {
                                type: 'timesTackled',
                                value: '1',
                            },
                            {
                                type: 'formationPlace',
                                value: '11',
                            },
                        ],
                    },
                    {
                        playerId: '1l228p52d8wdf8qhfxqu8rca',
                        firstName: 'Brennan Price',
                        lastName: 'Johnson',
                        shortFirstName: 'Brennan',
                        shortLastName: 'Johnson',
                        matchName: 'B. Johnson',
                        shirtNumber: 20,
                        position: 'Attacking Midfielder',
                        positionSide: 'Centre/Right',
                        formationPlace: '10',
                        stat: [
                            {
                                type: 'attemptsConcededObox',
                                value: '1',
                            },
                            {
                                type: 'totalOffside',
                                value: '1',
                            },
                            {
                                type: 'goalsConcededObox',
                                value: '1',
                            },
                            {
                                type: 'leftsidePass',
                                value: '1',
                            },
                            {
                                type: 'possLostAll',
                                value: '2',
                            },
                            {
                                type: 'touches',
                                value: '2',
                            },
                            {
                                type: 'turnover',
                                value: '1',
                            },
                            {
                                type: 'openPlayPass',
                                value: '1',
                            },
                            {
                                type: 'totalFwdZonePass',
                                value: '1',
                            },
                            {
                                type: 'gameStarted',
                                value: '1',
                            },
                            {
                                type: 'totalPass',
                                value: '1',
                            },
                            {
                                type: 'totalFinalThirdPasses',
                                value: '1',
                            },
                            {
                                type: 'unsuccessfulTouch',
                                value: '1',
                            },
                            {
                                type: 'possLostCtrl',
                                value: '2',
                            },
                            {
                                type: 'touchesInOppBox',
                                value: '1',
                            },
                            {
                                type: 'goalsConceded',
                                value: '1',
                            },
                            {
                                type: 'winningGoal',
                                value: '0',
                            },
                            {
                                type: 'timesTackled',
                                value: '0',
                            },
                            {
                                type: 'formationPlace',
                                value: '10',
                            },
                        ],
                    },
                    {
                        playerId: '5f72vvw62zdlyrj3mwux2udat',
                        firstName: 'Jesse Ellis',
                        lastName: 'Lingard',
                        shortFirstName: 'Jesse',
                        shortLastName: 'Lingard',
                        matchName: 'J. Lingard',
                        shirtNumber: 11,
                        position: 'Striker',
                        positionSide: 'Centre',
                        formationPlace: '9',
                        stat: [
                            {
                                type: 'totalFinalThirdPasses',
                                value: '2',
                            },
                            {
                                type: 'duelLost',
                                value: '1',
                            },
                            {
                                type: 'rightsidePass',
                                value: '1',
                            },
                            {
                                type: 'totalFwdZonePass',
                                value: '3',
                            },
                            {
                                type: 'openPlayPass',
                                value: '2',
                            },
                            {
                                type: 'crosses18yardplus',
                                value: '1',
                            },
                            {
                                type: 'gameStarted',
                                value: '1',
                            },
                            {
                                type: 'successfulOpenPlayPass',
                                value: '2',
                            },
                            {
                                type: 'totalPass',
                                value: '2',
                            },
                            {
                                type: 'totalContest',
                                value: '1',
                            },
                            {
                                type: 'successfulFinalThirdPasses',
                                value: '2',
                            },
                            {
                                type: 'goalsConceded',
                                value: '1',
                            },
                            {
                                type: 'touchesInOppBox',
                                value: '1',
                            },
                            {
                                type: 'possLostCtrl',
                                value: '2',
                            },
                            {
                                type: 'accurateFwdZonePass',
                                value: '2',
                            },
                            {
                                type: 'duelWon',
                                value: '1',
                            },
                            {
                                type: 'totalCross',
                                value: '1',
                            },
                            {
                                type: 'accuratePass',
                                value: '2',
                            },
                            {
                                type: 'backwardPass',
                                value: '1',
                            },
                            {
                                type: 'attemptsConcededObox',
                                value: '1',
                            },
                            {
                                type: 'totalCrossNocorner',
                                value: '1',
                            },
                            {
                                type: 'wasFouled',
                                value: '1',
                            },
                            {
                                type: 'penAreaEntries',
                                value: '1',
                            },
                            {
                                type: 'fouledFinalThird',
                                value: '1',
                            },
                            {
                                type: 'goalsConcededObox',
                                value: '1',
                            },
                            {
                                type: 'touches',
                                value: '5',
                            },
                            {
                                type: 'possLostAll',
                                value: '2',
                            },
                            {
                                type: 'winningGoal',
                                value: '0',
                            },
                            {
                                type: 'timesTackled',
                                value: '1',
                            },
                            {
                                type: 'formationPlace',
                                value: '9',
                            },
                        ],
                    },
                    {
                        playerId: '55eiicx10oqok1601fqyovt1x',
                        firstName: 'Taiwo Micheal',
                        lastName: 'Awoniyi',
                        shortFirstName: 'Taiwo',
                        shortLastName: 'Awoniyi',
                        matchName: 'T. Awoniyi',
                        shirtNumber: 9,
                        position: 'Substitute',
                        subPosition: 'Attacker',
                        stat: [
                            {
                                type: 'formationPlace',
                                value: '0',
                            },
                        ],
                    },
                    {
                        playerId: 'esb4b21x729db8od4c4xt8ciy',
                        firstName: 'Giulian',
                        lastName: 'Biancone',
                        shortFirstName: 'Giulian',
                        shortLastName: 'Biancone',
                        matchName: 'G. Biancone',
                        shirtNumber: 2,
                        position: 'Substitute',
                        subPosition: 'Defender',
                        stat: [
                            {
                                type: 'formationPlace',
                                value: '0',
                            },
                        ],
                    },
                    {
                        playerId: '25kl6261u9xft9cfvot8aezf9',
                        firstName: 'Jack Raymond',
                        lastName: 'Colback',
                        shortFirstName: 'Jack',
                        shortLastName: 'Colback',
                        matchName: 'J. Colback',
                        shirtNumber: 8,
                        position: 'Substitute',
                        subPosition: 'Midfielder',
                        stat: [
                            {
                                type: 'formationPlace',
                                value: '0',
                            },
                        ],
                    },
                    {
                        playerId: '3ztcz2dgfniqv141w7xsky7zd',
                        firstName: 'Emmanuel Bonaventure',
                        lastName: 'Dennis',
                        shortFirstName: 'Emmanuel',
                        shortLastName: 'Dennis',
                        matchName: 'E. Dennis',
                        shirtNumber: 25,
                        position: 'Substitute',
                        subPosition: 'Attacker',
                        stat: [
                            {
                                type: 'formationPlace',
                                value: '0',
                            },
                        ],
                    },
                    {
                        playerId: '19nlw3sa0khwz0el4wqc7zrp',
                        firstName: 'Remo Marco',
                        lastName: 'Freuler',
                        shortFirstName: 'Remo',
                        shortLastName: 'Freuler',
                        matchName: 'R. Freuler',
                        shirtNumber: 23,
                        position: 'Substitute',
                        subPosition: 'Midfielder',
                        stat: [
                            {
                                type: 'formationPlace',
                                value: '0',
                            },
                        ],
                    },
                    {
                        playerId: '8bhdwi4p824s82bbzkh2vy6t',
                        firstName: 'Wayne Robert',
                        lastName: 'Hennessey',
                        shortFirstName: 'Wayne',
                        shortLastName: 'Hennessey',
                        matchName: 'W. Hennessey',
                        shirtNumber: 13,
                        position: 'Substitute',
                        subPosition: 'Goalkeeper',
                        stat: [
                            {
                                type: 'formationPlace',
                                value: '0',
                            },
                        ],
                    },
                    {
                        playerId: 'a6m9jcx0e4l6krv1u0pbfuoyd',
                        firstName: 'Cheikhou',
                        lastName: 'Kouyaté',
                        shortFirstName: 'Cheikhou',
                        shortLastName: 'Kouyaté',
                        matchName: 'C. Kouyaté',
                        shirtNumber: 21,
                        position: 'Substitute',
                        subPosition: 'Midfielder',
                        stat: [
                            {
                                type: 'formationPlace',
                                value: '0',
                            },
                        ],
                    },
                    {
                        playerId: 'mpzi06x1j85ahwkrbsa20kmi',
                        firstName: 'Alexander Cole',
                        lastName: 'Mighten',
                        shortFirstName: 'Alex',
                        shortLastName: 'Mighten',
                        matchName: 'A. Mighten',
                        shirtNumber: 17,
                        position: 'Substitute',
                        subPosition: 'Attacker',
                        stat: [
                            {
                                type: 'formationPlace',
                                value: '0',
                            },
                        ],
                    },
                    {
                        playerId: '8kfciybd6filv663ebckuerjd',
                        firstName: 'Samuel William',
                        lastName: 'Surridge',
                        shortFirstName: 'Sam',
                        shortLastName: 'Surridge',
                        matchName: 'S. Surridge',
                        shirtNumber: 16,
                        position: 'Substitute',
                        subPosition: 'Attacker',
                        stat: [
                            {
                                type: 'formationPlace',
                                value: '0',
                            },
                        ],
                    },
                ],
                teamOfficial: {
                    id: '7qb4jzx7wrt2syeio63jpjtgp',
                    firstName: 'Steven David',
                    lastName: 'Cooper',
                    type: 'manager',
                },
                stat: [
                    {
                        fh: '24',
                        type: 'totalBackZonePass',
                        value: '24',
                    },
                    {
                        fh: '1',
                        type: 'attObxCentre',
                        value: '1',
                    },
                    {
                        fh: '7',
                        type: 'ballRecovery',
                        value: '7',
                    },
                    {
                        fh: '1',
                        type: 'attOpenplay',
                        value: '1',
                    },
                    {
                        fh: '6',
                        type: 'totalChippedPass',
                        value: '6',
                    },
                    {
                        fh: '15',
                        type: 'totalFinalThirdPasses',
                        value: '15',
                    },
                    {
                        fh: '5',
                        type: 'longPassOwnToOppSuccess',
                        value: '5',
                    },
                    {
                        fh: '2',
                        type: 'duelLost',
                        value: '2',
                    },
                    {
                        fh: '2',
                        type: 'fkFoulWon',
                        value: '2',
                    },
                    {
                        fh: '35',
                        type: 'totalFwdZonePass',
                        value: '35',
                    },
                    {
                        fh: '14',
                        type: 'rightsidePass',
                        value: '14',
                    },
                    {
                        fh: '6',
                        type: 'totalLongBalls',
                        value: '6',
                    },
                    {
                        fh: '54',
                        type: 'openPlayPass',
                        value: '54',
                    },
                    {
                        fh: '2',
                        type: 'crosses18yardplus',
                        value: '2',
                    },
                    {
                        fh: '44',
                        type: 'successfulOpenPlayPass',
                        value: '44',
                    },
                    {
                        fh: '56',
                        type: 'totalPass',
                        value: '56',
                    },
                    {
                        fh: '2',
                        type: 'totalContest',
                        value: '2',
                    },
                    {
                        fh: '1',
                        type: 'interception',
                        value: '1',
                    },
                    {
                        fh: '4',
                        type: 'accurateLongBalls',
                        value: '4',
                    },
                    {
                        fh: '10',
                        type: 'successfulFinalThirdPasses',
                        value: '10',
                    },
                    {
                        fh: '4',
                        type: 'possWonMid3rd',
                        value: '4',
                    },
                    {
                        fh: '1',
                        type: 'freekickCross',
                        value: '1',
                    },
                    {
                        fh: '1',
                        type: 'goalsConceded',
                        value: '1',
                    },
                    {
                        fh: '13',
                        type: 'passesLeft',
                        value: '13',
                    },
                    {
                        fh: '1',
                        type: 'attemptsObox',
                        value: '1',
                    },
                    {
                        fh: '2',
                        type: 'touchesInOppBox',
                        value: '2',
                    },
                    {
                        fh: '2',
                        type: 'possWonDef3rd',
                        value: '2',
                    },
                    {
                        fh: '6',
                        type: 'finalThirdEntries',
                        value: '6',
                    },
                    {
                        fh: '2',
                        type: 'unsuccessfulTouch',
                        value: '2',
                    },
                    {
                        fh: '18',
                        type: 'possLostCtrl',
                        value: '18',
                    },
                    {
                        fh: '6',
                        type: 'duelWon',
                        value: '6',
                    },
                    {
                        fh: '4',
                        type: 'accurateChippedPass',
                        value: '4',
                    },
                    {
                        fh: '1',
                        type: 'attOboxMiss',
                        value: '1',
                    },
                    {
                        fh: '3',
                        type: 'totalCross',
                        value: '3',
                    },
                    {
                        fh: '23',
                        type: 'accurateFwdZonePass',
                        value: '23',
                    },
                    {
                        fh: '1',
                        type: 'attMissHigh',
                        value: '1',
                    },
                    {
                        fh: '46',
                        type: 'accuratePass',
                        value: '46',
                    },
                    {
                        fh: '70.2',
                        type: 'possessionPercentage',
                        value: '70.2',
                    },
                    {
                        fh: '9',
                        type: 'backwardPass',
                        value: '9',
                    },
                    {
                        fh: '1',
                        type: 'attemptsConcededObox',
                        value: '1',
                    },
                    {
                        fh: '1',
                        type: 'totalScoringAtt',
                        value: '1',
                    },
                    {
                        fh: '3',
                        type: 'passesRight',
                        value: '3',
                    },
                    {
                        fh: '2',
                        type: 'accurateThrows',
                        value: '2',
                    },
                    {
                        fh: '3',
                        type: 'totalCrossNocorner',
                        value: '3',
                    },
                    {
                        fh: '1',
                        type: 'totalOffside',
                        value: '1',
                    },
                    {
                        fh: '2',
                        type: 'totalThrows',
                        value: '2',
                    },
                    {
                        fh: '1',
                        type: 'interceptionWon',
                        value: '1',
                    },
                    {
                        fh: '3',
                        type: 'aerialWon',
                        value: '3',
                    },
                    {
                        fh: '8',
                        type: 'longPassOwnToOpp',
                        value: '8',
                    },
                    {
                        fh: '1',
                        type: 'wonTackle',
                        value: '1',
                    },
                    {
                        fh: '4',
                        type: 'penAreaEntries',
                        value: '4',
                    },
                    {
                        fh: '23',
                        type: 'accurateBackZonePass',
                        value: '23',
                    },
                    {
                        fh: '15',
                        type: 'fwdPass',
                        value: '15',
                    },
                    {
                        fh: '1',
                        type: 'attRfTotal',
                        value: '1',
                    },
                    {
                        fh: '1',
                        type: 'shotOffTarget',
                        value: '1',
                    },
                    {
                        fh: '2',
                        type: 'fouledFinalThird',
                        value: '2',
                    },
                    {
                        fh: '1',
                        type: 'possWonAtt3rd',
                        value: '1',
                    },
                    {
                        fh: '1',
                        type: 'goalsConcededObox',
                        value: '1',
                    },
                    {
                        fh: '74',
                        type: 'touches',
                        value: '74',
                    },
                    {
                        fh: '18',
                        type: 'possLostAll',
                        value: '18',
                    },
                    {
                        fh: '1',
                        type: 'totalTackle',
                        value: '1',
                    },
                    {
                        fh: '18',
                        type: 'leftsidePass',
                        value: '18',
                    },
                    {
                        type: 'formationUsed',
                        value: '3421',
                    },
                ],
                kit: {
                    id: '4782',
                    colour1: '#FF0000',
                    colour2: '#FFFFFF',
                    type: 'home',
                },
            },
            {
                contestantId: '22doj4sgsocqpxw45h607udje',
                formationUsed: '3421',
                player: [
                    {
                        playerId: 'ombvyo6p3p5khkn91vubvpat',
                        firstName: 'Hugo Hadrien Dominique',
                        lastName: 'Lloris',
                        shortFirstName: 'Hugo',
                        shortLastName: 'Lloris',
                        matchName: 'H. Lloris',
                        shirtNumber: 1,
                        position: 'Goalkeeper',
                        positionSide: 'Centre',
                        formationPlace: '1',
                        captain: 'yes',
                        stat: [
                            {
                                type: 'attemptsConcededObox',
                                value: '1',
                            },
                            {
                                type: 'accuratePass',
                                value: '4',
                            },
                            {
                                type: 'accurateFwdZonePass',
                                value: '1',
                            },
                            {
                                type: 'accurateChippedPass',
                                value: '2',
                            },
                            {
                                type: 'possLostAll',
                                value: '1',
                            },
                            {
                                type: 'touches',
                                value: '6',
                            },
                            {
                                type: 'keeperThrows',
                                value: '1',
                            },
                            {
                                type: 'accurateBackZonePass',
                                value: '3',
                            },
                            {
                                type: 'fwdPass',
                                value: '2',
                            },
                            {
                                type: 'longPassOwnToOpp',
                                value: '1',
                            },
                            {
                                type: 'accurateGoalKicks',
                                value: '1',
                            },
                            {
                                type: 'gameStarted',
                                value: '1',
                            },
                            {
                                type: 'totalPass',
                                value: '4',
                            },
                            {
                                type: 'successfulOpenPlayPass',
                                value: '3',
                            },
                            {
                                type: 'accurateLongBalls',
                                value: '3',
                            },
                            {
                                type: 'totalLongBalls',
                                value: '3',
                            },
                            {
                                type: 'rightsidePass',
                                value: '2',
                            },
                            {
                                type: 'totalFwdZonePass',
                                value: '1',
                            },
                            {
                                type: 'openPlayPass',
                                value: '3',
                            },
                            {
                                type: 'goalKicks',
                                value: '1',
                            },
                            {
                                type: 'totalClearance',
                                value: '1',
                            },
                            {
                                type: 'totalChippedPass',
                                value: '2',
                            },
                            {
                                type: 'ballRecovery',
                                value: '2',
                            },
                            {
                                type: 'totalBackZonePass',
                                value: '3',
                            },
                            {
                                type: 'effectiveClearance',
                                value: '1',
                            },
                            {
                                type: 'longPassOwnToOppSuccess',
                                value: '1',
                            },
                            {
                                type: 'keeperPickUp',
                                value: '1',
                            },
                            {
                                type: 'punches',
                                value: '1',
                            },
                            {
                                type: 'possLostCtrl',
                                value: '1',
                            },
                            {
                                type: 'possWonDef3rd',
                                value: '1',
                            },
                            {
                                type: 'winningGoal',
                                value: '0',
                            },
                            {
                                type: 'timesTackled',
                                value: '0',
                            },
                            {
                                type: 'formationPlace',
                                value: '1',
                            },
                        ],
                    },
                    {
                        playerId: '7qy1hiitksyuaa9l0irxg240l',
                        firstName: 'Benjamin',
                        lastName: 'Thomas Davies',
                        shortFirstName: 'Ben',
                        shortLastName: 'Davies',
                        matchName: 'B. Davies',
                        shirtNumber: 33,
                        position: 'Defender',
                        positionSide: 'Left/Centre',
                        formationPlace: '4',
                        stat: [
                            {
                                type: 'accuratePass',
                                value: '1',
                            },
                            {
                                type: 'accurateFwdZonePass',
                                value: '1',
                            },
                            {
                                type: 'attemptsConcededObox',
                                value: '1',
                            },
                            {
                                type: 'fwdPass',
                                value: '1',
                            },
                            {
                                type: 'longPassOwnToOpp',
                                value: '1',
                            },
                            {
                                type: 'touches',
                                value: '1',
                            },
                            {
                                type: 'longPassOwnToOppSuccess',
                                value: '1',
                            },
                            {
                                type: 'gameStarted',
                                value: '1',
                            },
                            {
                                type: 'totalPass',
                                value: '1',
                            },
                            {
                                type: 'successfulOpenPlayPass',
                                value: '1',
                            },
                            {
                                type: 'openPlayPass',
                                value: '1',
                            },
                            {
                                type: 'totalFwdZonePass',
                                value: '1',
                            },
                            {
                                type: 'passesLeft',
                                value: '1',
                            },
                            {
                                type: 'winningGoal',
                                value: '0',
                            },
                            {
                                type: 'timesTackled',
                                value: '0',
                            },
                            {
                                type: 'formationPlace',
                                value: '4',
                            },
                        ],
                    },
                    {
                        playerId: 'az7wa6km1zhvsb8y4mndlogyd',
                        firstName: 'Eric Jeremy Edgar',
                        lastName: 'Dier',
                        shortFirstName: 'Eric',
                        shortLastName: 'Dier',
                        matchName: 'E. Dier',
                        shirtNumber: 15,
                        position: 'Defender',
                        positionSide: 'Centre',
                        formationPlace: '5',
                        stat: [
                            {
                                type: 'attemptsConcededObox',
                                value: '1',
                            },
                            {
                                type: 'accuratePass',
                                value: '3',
                            },
                            {
                                type: 'touches',
                                value: '3',
                            },
                            {
                                type: 'leftsidePass',
                                value: '2',
                            },
                            {
                                type: 'accurateBackZonePass',
                                value: '3',
                            },
                            {
                                type: 'openPlayPass',
                                value: '2',
                            },
                            {
                                type: 'rightsidePass',
                                value: '1',
                            },
                            {
                                type: 'totalPass',
                                value: '3',
                            },
                            {
                                type: 'successfulOpenPlayPass',
                                value: '2',
                            },
                            {
                                type: 'gameStarted',
                                value: '1',
                            },
                            {
                                type: 'totalBackZonePass',
                                value: '3',
                            },
                            {
                                type: 'winningGoal',
                                value: '0',
                            },
                            {
                                type: 'timesTackled',
                                value: '0',
                            },
                            {
                                type: 'formationPlace',
                                value: '5',
                            },
                        ],
                    },
                    {
                        playerId: '4k0ni039sbjs4sqipk4v7k79x',
                        firstName: 'Davinson',
                        lastName: 'Sánchez Mina',
                        shortFirstName: 'Davinson',
                        shortLastName: 'Sánchez',
                        matchName: 'D. Sánchez',
                        shirtNumber: 6,
                        position: 'Defender',
                        positionSide: 'Centre/Right',
                        formationPlace: '6',
                        stat: [
                            {
                                type: 'possLostAll',
                                value: '1',
                            },
                            {
                                type: 'touches',
                                value: '4',
                            },
                            {
                                type: 'totalTackle',
                                value: '1',
                            },
                            {
                                type: 'accurateBackZonePass',
                                value: '1',
                            },
                            {
                                type: 'fwdPass',
                                value: '1',
                            },
                            {
                                type: 'attemptsConcededObox',
                                value: '1',
                            },
                            {
                                type: 'totalLaunches',
                                value: '1',
                            },
                            {
                                type: 'accuratePass',
                                value: '1',
                            },
                            {
                                type: 'duelWon',
                                value: '1',
                            },
                            {
                                type: 'possLostCtrl',
                                value: '1',
                            },
                            {
                                type: 'totalPass',
                                value: '2',
                            },
                            {
                                type: 'successfulOpenPlayPass',
                                value: '1',
                            },
                            {
                                type: 'gameStarted',
                                value: '1',
                            },
                            {
                                type: 'totalLongBalls',
                                value: '1',
                            },
                            {
                                type: 'rightsidePass',
                                value: '1',
                            },
                            {
                                type: 'openPlayPass',
                                value: '2',
                            },
                            {
                                type: 'totalClearance',
                                value: '1',
                            },
                            {
                                type: 'totalBackZonePass',
                                value: '2',
                            },
                            {
                                type: 'effectiveClearance',
                                value: '1',
                            },
                            {
                                type: 'winningGoal',
                                value: '0',
                            },
                            {
                                type: 'timesTackled',
                                value: '0',
                            },
                            {
                                type: 'formationPlace',
                                value: '6',
                            },
                        ],
                    },
                    {
                        playerId: '2gsq05yyxwe4f8t79j6d49q6t',
                        firstName: 'Ivan',
                        lastName: 'Perišić',
                        shortFirstName: 'Ivan',
                        shortLastName: 'Perisic',
                        matchName: 'I. Perišić',
                        shirtNumber: 14,
                        position: 'Midfielder',
                        positionSide: 'Left',
                        formationPlace: '3',
                        stat: [
                            {
                                type: 'attemptsConcededObox',
                                value: '1',
                            },
                            {
                                type: 'touches',
                                value: '2',
                            },
                            {
                                type: 'possLostAll',
                                value: '1',
                            },
                            {
                                type: 'openPlayPass',
                                value: '1',
                            },
                            {
                                type: 'rightsidePass',
                                value: '1',
                            },
                            {
                                type: 'totalFwdZonePass',
                                value: '1',
                            },
                            {
                                type: 'gameStarted',
                                value: '1',
                            },
                            {
                                type: 'totalPass',
                                value: '1',
                            },
                            {
                                type: 'possLostCtrl',
                                value: '1',
                            },
                            {
                                type: 'winningGoal',
                                value: '0',
                            },
                            {
                                type: 'timesTackled',
                                value: '0',
                            },
                            {
                                type: 'formationPlace',
                                value: '3',
                            },
                        ],
                    },
                    {
                        playerId: '3wp8fuk07m1ph1fpmbqe7tf55',
                        firstName: 'Rodrigo',
                        lastName: 'Bentancur Colmán',
                        shortFirstName: 'Rodrigo',
                        shortLastName: 'Bentancur',
                        matchName: 'R. Bentancur',
                        shirtNumber: 30,
                        position: 'Midfielder',
                        positionSide: 'Left/Centre',
                        formationPlace: '8',
                        stat: [
                            {
                                type: 'possWonDef3rd',
                                value: '1',
                            },
                            {
                                type: 'possLostCtrl',
                                value: '1',
                            },
                            {
                                type: 'totalBackZonePass',
                                value: '1',
                            },
                            {
                                type: 'ballRecovery',
                                value: '1',
                            },
                            {
                                type: 'openPlayPass',
                                value: '1',
                            },
                            {
                                type: 'totalPass',
                                value: '1',
                            },
                            {
                                type: 'gameStarted',
                                value: '1',
                            },
                            {
                                type: 'interception',
                                value: '1',
                            },
                            {
                                type: 'interceptionWon',
                                value: '1',
                            },
                            {
                                type: 'possLostAll',
                                value: '1',
                            },
                            {
                                type: 'touches',
                                value: '2',
                            },
                            {
                                type: 'leftsidePass',
                                value: '1',
                            },
                            {
                                type: 'attemptsConcededObox',
                                value: '1',
                            },
                            {
                                type: 'winningGoal',
                                value: '0',
                            },
                            {
                                type: 'timesTackled',
                                value: '0',
                            },
                            {
                                type: 'formationPlace',
                                value: '8',
                            },
                        ],
                    },
                    {
                        playerId: '4zxrdmwphkxvkvo6bt30uanrp',
                        firstName: 'Pierre-Emile',
                        lastName: 'Kordt Højbjerg',
                        shortFirstName: 'Pierre-Emile',
                        shortLastName: 'Højbjerg',
                        matchName: 'P. Højbjerg',
                        shirtNumber: 5,
                        position: 'Midfielder',
                        positionSide: 'Centre/Right',
                        formationPlace: '7',
                        stat: [
                            {
                                type: 'possWonDef3rd',
                                value: '1',
                            },
                            {
                                type: 'possLostCtrl',
                                value: '1',
                            },
                            {
                                type: 'openPlayPass',
                                value: '3',
                            },
                            {
                                type: 'totalLongBalls',
                                value: '1',
                            },
                            {
                                type: 'interception',
                                value: '1',
                            },
                            {
                                type: 'accurateLongBalls',
                                value: '1',
                            },
                            {
                                type: 'successfulOpenPlayPass',
                                value: '2',
                            },
                            {
                                type: 'totalPass',
                                value: '3',
                            },
                            {
                                type: 'gameStarted',
                                value: '1',
                            },
                            {
                                type: 'totalBackZonePass',
                                value: '3',
                            },
                            {
                                type: 'ballRecovery',
                                value: '1',
                            },
                            {
                                type: 'touches',
                                value: '5',
                            },
                            {
                                type: 'possLostAll',
                                value: '1',
                            },
                            {
                                type: 'totalTackle',
                                value: '1',
                            },
                            {
                                type: 'leftsidePass',
                                value: '2',
                            },
                            {
                                type: 'interceptionWon',
                                value: '1',
                            },
                            {
                                type: 'fwdPass',
                                value: '1',
                            },
                            {
                                type: 'accurateBackZonePass',
                                value: '2',
                            },
                            {
                                type: 'attemptsConcededObox',
                                value: '1',
                            },
                            {
                                type: 'duelWon',
                                value: '1',
                            },
                            {
                                type: 'accuratePass',
                                value: '2',
                            },
                            {
                                type: 'winningGoal',
                                value: '0',
                            },
                            {
                                type: 'timesTackled',
                                value: '0',
                            },
                            {
                                type: 'formationPlace',
                                value: '7',
                            },
                        ],
                    },
                    {
                        playerId: 'anxby26pfxpu4u5bfy9nbuvah',
                        firstName: 'Emerson Aparecido',
                        lastName: 'Leite de Souza Junior',
                        shortFirstName: 'Emerson Aparecido',
                        shortLastName: 'Leite de Souza Junior',
                        knownName: 'Emerson Royal',
                        matchName: 'Emerson',
                        shirtNumber: 12,
                        position: 'Midfielder',
                        positionSide: 'Right',
                        formationPlace: '2',
                        stat: [
                            {
                                type: 'accurateThrows',
                                value: '2',
                            },
                            {
                                type: 'totalThrows',
                                value: '2',
                            },
                            {
                                type: 'attemptsConcededObox',
                                value: '1',
                            },
                            {
                                type: 'accuratePass',
                                value: '4',
                            },
                            {
                                type: 'aerialLost',
                                value: '3',
                            },
                            {
                                type: 'backwardPass',
                                value: '1',
                            },
                            {
                                type: 'attemptedTackleFoul',
                                value: '2',
                            },
                            {
                                type: 'leftsidePass',
                                value: '2',
                            },
                            {
                                type: 'touches',
                                value: '9',
                            },
                            {
                                type: 'accurateBackZonePass',
                                value: '4',
                            },
                            {
                                type: 'fwdPass',
                                value: '1',
                            },
                            {
                                type: 'offsideProvoked',
                                value: '1',
                            },
                            {
                                type: 'gameStarted',
                                value: '1',
                            },
                            {
                                type: 'totalPass',
                                value: '4',
                            },
                            {
                                type: 'successfulOpenPlayPass',
                                value: '4',
                            },
                            {
                                type: 'openPlayPass',
                                value: '4',
                            },
                            {
                                type: 'duelLost',
                                value: '5',
                            },
                            {
                                type: 'totalClearance',
                                value: '1',
                            },
                            {
                                type: 'fouls',
                                value: '2',
                            },
                            {
                                type: 'ballRecovery',
                                value: '1',
                            },
                            {
                                type: 'totalBackZonePass',
                                value: '4',
                            },
                            {
                                type: 'effectiveClearance',
                                value: '1',
                            },
                            {
                                type: 'possWonDef3rd',
                                value: '1',
                            },
                            {
                                type: 'winningGoal',
                                value: '0',
                            },
                            {
                                type: 'timesTackled',
                                value: '0',
                            },
                            {
                                type: 'formationPlace',
                                value: '2',
                            },
                        ],
                    },
                    {
                        playerId: '12ekwo6gbza410blv4b4r7ydx',
                        firstName: 'Heung-Min',
                        lastName: 'Son',
                        shortFirstName: 'Heung-Min',
                        shortLastName: 'Son',
                        knownName: 'Son Heung-Min',
                        matchName: 'Son Heung-Min',
                        shirtNumber: 7,
                        position: 'Attacking Midfielder',
                        positionSide: 'Left/Centre',
                        formationPlace: '11',
                        stat: [
                            {
                                type: 'possLostCtrl',
                                value: '3',
                            },
                            {
                                type: 'unsuccessfulTouch',
                                value: '1',
                            },
                            {
                                type: 'duelLost',
                                value: '1',
                            },
                            {
                                type: 'totalBackZonePass',
                                value: '1',
                            },
                            {
                                type: 'gameStarted',
                                value: '1',
                            },
                            {
                                type: 'totalPass',
                                value: '1',
                            },
                            {
                                type: 'openPlayPass',
                                value: '1',
                            },
                            {
                                type: 'rightsidePass',
                                value: '1',
                            },
                            {
                                type: 'turnover',
                                value: '1',
                            },
                            {
                                type: 'headPass',
                                value: '1',
                            },
                            {
                                type: 'possLostAll',
                                value: '3',
                            },
                            {
                                type: 'touches',
                                value: '3',
                            },
                            {
                                type: 'dispossessed',
                                value: '1',
                            },
                            {
                                type: 'attemptsConcededObox',
                                value: '1',
                            },
                            {
                                type: 'winningGoal',
                                value: '0',
                            },
                            {
                                type: 'timesTackled',
                                value: '1',
                            },
                            {
                                type: 'formationPlace',
                                value: '11',
                            },
                        ],
                    },
                    {
                        playerId: 'dlf31m2lrfl0biop1demgvh7t',
                        firstName: 'Dejan',
                        lastName: 'Kulusevski',
                        shortFirstName: 'Dejan',
                        shortLastName: 'Kulusevski',
                        matchName: 'D. Kulusevski',
                        shirtNumber: 21,
                        position: 'Attacking Midfielder',
                        positionSide: 'Centre/Right',
                        formationPlace: '10',
                        stat: [
                            {
                                type: 'accuratePass',
                                value: '2',
                            },
                            {
                                type: 'backwardPass',
                                value: '1',
                            },
                            {
                                type: 'totalAttAssist',
                                value: '1',
                            },
                            {
                                type: 'accurateFwdZonePass',
                                value: '2',
                            },
                            {
                                type: 'attemptsConcededObox',
                                value: '1',
                            },
                            {
                                type: 'passesRight',
                                value: '1',
                            },
                            {
                                type: 'ontargetAttAssist',
                                value: '1',
                            },
                            {
                                type: 'touches',
                                value: '3',
                            },
                            {
                                type: 'goalAssistOpenplay',
                                value: '1',
                            },
                            {
                                type: 'totalFinalThirdPasses',
                                value: '1',
                            },
                            {
                                type: 'totalPass',
                                value: '2',
                            },
                            {
                                type: 'successfulOpenPlayPass',
                                value: '2',
                            },
                            {
                                type: 'goalAssist',
                                value: '1',
                            },
                            {
                                type: 'gameStarted',
                                value: '1',
                            },
                            {
                                type: 'totalFwdZonePass',
                                value: '2',
                            },
                            {
                                type: 'rightsidePass',
                                value: '1',
                            },
                            {
                                type: 'openPlayPass',
                                value: '2',
                            },
                            {
                                type: 'successfulFinalThirdPasses',
                                value: '1',
                            },
                            {
                                type: 'winningGoal',
                                value: '0',
                            },
                            {
                                type: 'timesTackled',
                                value: '0',
                            },
                            {
                                type: 'formationPlace',
                                value: '10',
                            },
                        ],
                    },
                    {
                        playerId: 'b9g4qurpll4wizajo95c406hh',
                        firstName: 'Harry Edward',
                        lastName: 'Kane',
                        shortFirstName: 'Harry',
                        shortLastName: 'Kane',
                        matchName: 'H. Kane',
                        shirtNumber: 10,
                        position: 'Striker',
                        positionSide: 'Centre',
                        formationPlace: '9',
                        stat: [
                            {
                                type: 'possLostAll',
                                value: '2',
                            },
                            {
                                type: 'touches',
                                value: '3',
                            },
                            {
                                type: 'leftsidePass',
                                value: '1',
                            },
                            {
                                type: 'headPass',
                                value: '1',
                            },
                            {
                                type: 'longPassOwnToOpp',
                                value: '1',
                            },
                            {
                                type: 'ontargetScoringAtt',
                                value: '1',
                            },
                            {
                                type: 'attRfTotal',
                                value: '1',
                            },
                            {
                                type: 'fwdPass',
                                value: '1',
                            },
                            {
                                type: 'attemptsConcededObox',
                                value: '1',
                            },
                            {
                                type: 'totalScoringAtt',
                                value: '1',
                            },
                            {
                                type: 'attOboxGoal',
                                value: '1',
                            },
                            {
                                type: 'attGoalLowLeft',
                                value: '1',
                            },
                            {
                                type: 'finalThirdEntries',
                                value: '1',
                            },
                            {
                                type: 'goals',
                                value: '1',
                            },
                            {
                                type: 'possLostCtrl',
                                value: '2',
                            },
                            {
                                type: 'totalFlickOn',
                                value: '1',
                            },
                            {
                                type: 'attRfGoal',
                                value: '1',
                            },
                            {
                                type: 'attemptsObox',
                                value: '1',
                            },
                            {
                                type: 'openPlayPass',
                                value: '2',
                            },
                            {
                                type: 'totalFwdZonePass',
                                value: '2',
                            },
                            {
                                type: 'totalLongBalls',
                                value: '1',
                            },
                            {
                                type: 'totalPass',
                                value: '2',
                            },
                            {
                                type: 'gameStarted',
                                value: '1',
                            },
                            {
                                type: 'totalFinalThirdPasses',
                                value: '1',
                            },
                            {
                                type: 'goalsOpenplay',
                                value: '1',
                            },
                            {
                                type: 'attObxCentre',
                                value: '1',
                            },
                            {
                                type: 'attOpenplay',
                                value: '1',
                            },
                            {
                                type: 'winningGoal',
                                value: '1',
                            },
                            {
                                type: 'timesTackled',
                                value: '0',
                            },
                            {
                                type: 'formationPlace',
                                value: '9',
                            },
                        ],
                    },
                    {
                        playerId: '1g15ehziupxnsy1kvl3pqmbc9',
                        firstName: 'Yves',
                        lastName: 'Bissouma',
                        shortFirstName: 'Yves',
                        shortLastName: 'Bissouma',
                        matchName: 'Y. Bissouma',
                        shirtNumber: 38,
                        position: 'Substitute',
                        subPosition: 'Midfielder',
                        stat: [
                            {
                                type: 'formationPlace',
                                value: '0',
                            },
                        ],
                    },
                    {
                        playerId: '9irgn82qm11logbhup51rg5wp',
                        firstName: 'Diop Tehuti',
                        lastName: 'Djed-Hotep Spence',
                        shortFirstName: 'Djed',
                        shortLastName: 'Spence',
                        matchName: 'D. Spence',
                        shirtNumber: 24,
                        position: 'Substitute',
                        subPosition: 'Defender',
                        stat: [
                            {
                                type: 'formationPlace',
                                value: '0',
                            },
                        ],
                    },
                    {
                        playerId: '31wf775s975qy3il33oora8k5',
                        firstName: 'Matthew James',
                        lastName: 'Doherty',
                        shortFirstName: 'Matt',
                        shortLastName: 'Doherty',
                        matchName: 'M. Doherty',
                        shirtNumber: 2,
                        position: 'Substitute',
                        subPosition: 'Defender',
                        stat: [
                            {
                                type: 'formationPlace',
                                value: '0',
                            },
                        ],
                    },
                    {
                        playerId: '8wz5pdydepr5kzcichz17pv4l',
                        firstName: 'Fraser Gerard',
                        lastName: 'Forster',
                        shortFirstName: 'Fraser',
                        shortLastName: 'Forster',
                        matchName: 'F. Forster',
                        shirtNumber: 20,
                        position: 'Substitute',
                        subPosition: 'Goalkeeper',
                        stat: [
                            {
                                type: 'formationPlace',
                                value: '0',
                            },
                        ],
                    },
                    {
                        playerId: '3gsprcdyogabeb8lr2y0bmytx',
                        firstName: 'Clément Nicolas Laurent',
                        lastName: 'Lenglet',
                        shortFirstName: 'Clément',
                        shortLastName: 'Lenglet',
                        matchName: 'C. Lenglet',
                        shirtNumber: 34,
                        position: 'Substitute',
                        subPosition: 'Defender',
                        stat: [
                            {
                                type: 'formationPlace',
                                value: '0',
                            },
                        ],
                    },
                    {
                        playerId: 'btf0dch35aww78bzillh2jgx6',
                        firstName: 'Pape Matar',
                        lastName: 'Sarr',
                        shortFirstName: 'Pape',
                        shortLastName: 'Sarr',
                        matchName: 'P. Sarr',
                        shirtNumber: 29,
                        position: 'Substitute',
                        subPosition: 'Midfielder',
                        stat: [
                            {
                                type: 'formationPlace',
                                value: '0',
                            },
                        ],
                    },
                    {
                        playerId: 'cag3gzjcv8erx3zpp2lidzcvd',
                        firstName: 'Kouassi Ryan',
                        lastName: 'Sessegnon',
                        shortFirstName: 'Ryan',
                        shortLastName: 'Sessegnon',
                        matchName: 'R. Sessegnon',
                        shirtNumber: 19,
                        position: 'Substitute',
                        subPosition: 'Midfielder',
                        stat: [
                            {
                                type: 'formationPlace',
                                value: '0',
                            },
                        ],
                    },
                    {
                        playerId: '5gb7v3m0qisn24c7h49xn250p',
                        firstName: 'Japhet Manzambi',
                        lastName: 'Tanganga',
                        shortFirstName: 'Japhet',
                        shortLastName: 'Tanganga',
                        matchName: 'J. Tanganga',
                        shirtNumber: 25,
                        position: 'Substitute',
                        subPosition: 'Defender',
                        stat: [
                            {
                                type: 'formationPlace',
                                value: '0',
                            },
                        ],
                    },
                    {
                        playerId: 'c5zmr0m17riujelm4up44etih',
                        firstName: 'Richarlison',
                        lastName: 'de Andrade',
                        shortFirstName: 'Richarlison',
                        shortLastName: 'de Andrade',
                        knownName: 'Richarlison',
                        matchName: 'Richarlison',
                        shirtNumber: 9,
                        position: 'Substitute',
                        subPosition: 'Attacker',
                        stat: [
                            {
                                type: 'formationPlace',
                                value: '0',
                            },
                        ],
                    },
                ],
                teamOfficial: {
                    id: '2t14kex330qljt5vlmssvo6ad',
                    firstName: 'Antonio',
                    lastName: 'Conte Cavaliere',
                    type: 'manager',
                },
                stat: [
                    {
                        fh: '1',
                        type: 'passesLeft',
                        value: '1',
                    },
                    {
                        fh: '1',
                        type: 'attemptsObox',
                        value: '1',
                    },
                    {
                        fh: '1',
                        type: 'totalFlickOn',
                        value: '1',
                    },
                    {
                        fh: '1',
                        type: 'attRfGoal',
                        value: '1',
                    },
                    {
                        fh: '1',
                        type: 'successfulFinalThirdPasses',
                        value: '1',
                    },
                    {
                        fh: '10',
                        type: 'possLostCtrl',
                        value: '10',
                    },
                    {
                        fh: '1',
                        type: 'punches',
                        value: '1',
                    },
                    {
                        fh: '1',
                        type: 'unsuccessfulTouch',
                        value: '1',
                    },
                    {
                        fh: '4',
                        type: 'possWonDef3rd',
                        value: '4',
                    },
                    {
                        fh: '1',
                        type: 'goals',
                        value: '1',
                    },
                    {
                        fh: '1',
                        type: 'finalThirdEntries',
                        value: '1',
                    },
                    {
                        fh: '1',
                        type: 'forwardGoals',
                        value: '1',
                    },
                    {
                        fh: '6',
                        type: 'duelLost',
                        value: '6',
                    },
                    {
                        fh: '3',
                        type: 'totalClearance',
                        value: '3',
                    },
                    {
                        fh: '1',
                        type: 'goalKicks',
                        value: '1',
                    },
                    {
                        fh: '2',
                        type: 'totalFinalThirdPasses',
                        value: '2',
                    },
                    {
                        fh: '1',
                        type: 'goalsOpenplay',
                        value: '1',
                    },
                    {
                        fh: '2',
                        type: 'longPassOwnToOppSuccess',
                        value: '2',
                    },
                    {
                        fh: '2',
                        type: 'fkFoulLost',
                        value: '2',
                    },
                    {
                        fh: '3',
                        type: 'effectiveClearance',
                        value: '3',
                    },
                    {
                        fh: '17',
                        type: 'totalBackZonePass',
                        value: '17',
                    },
                    {
                        fh: '1',
                        type: 'attObxCentre',
                        value: '1',
                    },
                    {
                        fh: '5',
                        type: 'ballRecovery',
                        value: '5',
                    },
                    {
                        fh: '1',
                        type: 'attOpenplay',
                        value: '1',
                    },
                    {
                        fh: '2',
                        type: 'totalChippedPass',
                        value: '2',
                    },
                    {
                        fh: '2',
                        type: 'interception',
                        value: '2',
                    },
                    {
                        fh: '4',
                        type: 'accurateLongBalls',
                        value: '4',
                    },
                    {
                        fh: '15',
                        type: 'successfulOpenPlayPass',
                        value: '15',
                    },
                    {
                        fh: '1',
                        type: 'goalAssist',
                        value: '1',
                    },
                    {
                        fh: '24',
                        type: 'totalPass',
                        value: '24',
                    },
                    {
                        fh: '22',
                        type: 'openPlayPass',
                        value: '22',
                    },
                    {
                        fh: '7',
                        type: 'totalFwdZonePass',
                        value: '7',
                    },
                    {
                        fh: '7',
                        type: 'rightsidePass',
                        value: '7',
                    },
                    {
                        fh: '6',
                        type: 'totalLongBalls',
                        value: '6',
                    },
                    {
                        fh: '7',
                        type: 'fwdPass',
                        value: '7',
                    },
                    {
                        fh: '1',
                        type: 'attRfTotal',
                        value: '1',
                    },
                    {
                        fh: '13',
                        type: 'accurateBackZonePass',
                        value: '13',
                    },
                    {
                        fh: '1',
                        type: 'accurateGoalKicks',
                        value: '1',
                    },
                    {
                        fh: '3',
                        type: 'longPassOwnToOpp',
                        value: '3',
                    },
                    {
                        fh: '1',
                        type: 'ontargetScoringAtt',
                        value: '1',
                    },
                    {
                        fh: '2',
                        type: 'interceptionWon',
                        value: '2',
                    },
                    {
                        fh: '41',
                        type: 'touches',
                        value: '41',
                    },
                    {
                        fh: '1',
                        type: 'ontargetAttAssist',
                        value: '1',
                    },
                    {
                        fh: '10',
                        type: 'possLostAll',
                        value: '10',
                    },
                    {
                        type: 'firstHalfGoals',
                        value: '1',
                    },
                    {
                        fh: '8',
                        type: 'leftsidePass',
                        value: '8',
                    },
                    {
                        fh: '2',
                        type: 'totalTackle',
                        value: '2',
                    },
                    {
                        fh: '2',
                        type: 'attemptedTackleFoul',
                        value: '2',
                    },
                    {
                        fh: '1',
                        type: 'goalAssistOpenplay',
                        value: '1',
                    },
                    {
                        fh: '1',
                        type: 'keeperThrows',
                        value: '1',
                    },
                    {
                        fh: '3',
                        type: 'aerialLost',
                        value: '3',
                    },
                    {
                        fh: '2',
                        type: 'backwardPass',
                        value: '2',
                    },
                    {
                        fh: '17',
                        type: 'accuratePass',
                        value: '17',
                    },
                    {
                        fh: '29.8',
                        type: 'possessionPercentage',
                        value: '29.8',
                    },
                    {
                        fh: '1',
                        type: 'attGoalLowLeft',
                        value: '1',
                    },
                    {
                        fh: '2',
                        type: 'duelWon',
                        value: '2',
                    },
                    {
                        fh: '1',
                        type: 'totalAttAssist',
                        value: '1',
                    },
                    {
                        fh: '2',
                        type: 'accurateChippedPass',
                        value: '2',
                    },
                    {
                        fh: '4',
                        type: 'accurateFwdZonePass',
                        value: '4',
                    },
                    {
                        fh: '2',
                        type: 'totalThrows',
                        value: '2',
                    },
                    {
                        fh: '1',
                        type: 'dispossessed',
                        value: '1',
                    },
                    {
                        fh: '2',
                        type: 'accurateThrows',
                        value: '2',
                    },
                    {
                        fh: '1',
                        type: 'attOboxGoal',
                        value: '1',
                    },
                    {
                        fh: '1',
                        type: 'totalLaunches',
                        value: '1',
                    },
                    {
                        fh: '1',
                        type: 'passesRight',
                        value: '1',
                    },
                    {
                        fh: '1',
                        type: 'attemptsConcededObox',
                        value: '1',
                    },
                    {
                        fh: '1',
                        type: 'totalScoringAtt',
                        value: '1',
                    },
                    {
                        type: 'formationUsed',
                        value: '3421',
                    },
                ],
                kit: {
                    id: '4',
                    colour1: '#FFFFFF',
                    colour2: '#132257',
                    type: 'home',
                },
            },
        ],
        matchDetailsExtra: {
            matchOfficial: [
                {
                    id: '3oe7osb8o3cy0dgex37asng0l',
                    type: 'Main',
                    firstName: 'Craig',
                    lastName: 'Pawson',
                },
                {
                    id: 'f1g3337l5j65736o54oswmxud',
                    type: 'Lineman 1',
                    firstName: 'Ian',
                    lastName: 'Hussin',
                },
                {
                    id: '9fqj6o3vtcziwj0xpzy4e6j85',
                    type: 'Lineman 2',
                    firstName: 'Scott',
                    lastName: 'Ledger',
                },
                {
                    id: '6l3ie5htdymg13u2ui69wo251',
                    type: 'Fourth official',
                    firstName: 'Darren',
                    lastName: 'England',
                },
            ],
        },
    },
}
