import Jwt from '../../framework/helpers/jwt'
import { MosaicChannel } from 'models/MosaicChannel'
import { of, throwError } from 'rxjs'
import { mergeMap, timeoutWith } from 'rxjs/operators'
import { ajax } from 'rxjs/ajax'
import VoltError from 'VoltError'

/**
 * Common service available for all implementations/customers
 * @note Although this service has been created initially to manage locale for the Proxy API only
 * Can be easily used by other services (Seachange, Kaltura, Youtube, etc..) to retrieve the locale
 * before to execute the request
 */
export default class CommonApi {
    static DEFAULT_LOCALE = 'en_US'
    static _instance = undefined

    /**
     * Constructor
     */
    constructor() {
        this.setLocale(CommonApi.DEFAULT_LOCALE)
    }

    /**
     * Use to retrieve the instance of this class
     * @returns instance
     */
    static getInstance() {
        if (!CommonApi._instance) {
            CommonApi._instance = new CommonApi()
        }
        return CommonApi._instance
    }

    /**
     * Set Locale
     * @param {String} locale Can be either locale code (ex: en_US) or language code (ex: en)
     * The proxy has implemented an automatic association of the language code to locale code
     * @returns {Observable}
     */
    setLocale(locale) {
        this.locale = locale
        return of(locale)
    }

    /**
     * Get Locale
     * @returns Can be either locale code (ex: en_US) or language code (ex: en)
     */
    getLocale() {
        return this.locale
    }

    /**
     * Set the current profile (used for easy access from the api)
     * @param {Profile} profile User Profile
     */
    setCurrentProfile(profile) {
        this.profile = profile
    }

    /**
     * Returns the current profile (used for easy access from the api)
     * @returns Profile
     */
    getCurrentProfile() {
        return this.profile || {}
    }

    /**
     * @warn This feature should be used for non critical feature
     * @param {String} [provider='ipify'] Public Provider of Public IP
     * @param {String} [providerUrl='https://api.ipify.org?format=json'] Public Provider of Public IP
     * @returns {Observable<}
     */
    fetchPublicIp({
        provider = 'ipify',
        providerUrl = 'https://api.ipify.org?format=json',
        timeout = 5000,
    }) {
        const supportedProvider = ['ipify']
        if (!supportedProvider.includes(provider)) {
            return throwError(
                new VoltError(VoltError.codes.FEATURE_NOT_AVAILABLE, {
                    extraLog: `platform error: Invalid Check GeoIP Service: ${providerUrl}`,
                    backend: provider,
                })
            )
        }

        return ajax({
            url: providerUrl,
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'GET',
        }).pipe(
            timeoutWith(
                timeout,
                throwError(
                    new VoltError(VoltError.codes.REQUEST_TIMEOUT, {
                        extraLog: `platform error: Timeout detected for getting the WAN IP Address url: ${providerUrl}`,
                        backend: provider,
                    })
                )
            ),
            mergeMap(({ response = {} } = {}) => {
                let publicIp
                switch (provider) {
                    case 'ipify':
                        publicIp = response.ip
                        break
                    default:
                        // Do nothing
                        break
                }
                this.setPublicIp(publicIp)
                return of(publicIp)
            })
        )
    }

    /**
     * Getter
     * @param {String} IP Address
     */
    setPublicIp(publicIp) {
        this._publicIp = publicIp
    }

    /**
     * Getter
     * @returns {String} IP Address
     */
    getPublicIp() {
        return this._publicIp
    }

    /**
     * Returns the current profile (used for easy access from the api)
     * @returns Profile
     */
    getPaymentMethodsTypes() {
        if (!this.profile || !this.profile.paymentMethods) return []
        return this.profile.paymentMethods.map((method) => method.paymentType)
    }

    getJWTToken(header, payload, privateKey) {
        const jwt = new Jwt(header, payload, privateKey).generateJwt()
        return jwt
    }

    createMosaicChannel(params) {
        const { channelNumber, title } = params
        return new MosaicChannel({
            id: 'mosaicChannel',
            platformId: `mosaicChannel-${channelNumber}`,
            name: title,
            ...params,
        })
    }
}
