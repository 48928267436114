import { Program } from './Program'
import { getStartTime } from 'helpers/date'
import Constants from 'api-constants'
import { ILive, OptaDetails } from '@typings/tile'
import { Actor, Channel } from 'models'

/**
 * A Live program (~ EPG item)
 * @property {String} logo
 * @property {String|Number} channelId Proxy/Maculosa channel identifier which the Live belongs to
 * @property {String|Number} platformChannelId Platform channel identifier which the Live belongs to
 * @property {Number} [startTime] JS Timestamp (ms) of Live start time
 * @property {Number} [endTime] JS Timestamp (ms) of Live end time
 * @property {Number} [originalBroadcastTime] JS Timestamp (ms) of Live original broadcast start time
 * @property {Number} [duration] Duration, in ms, of the Live program
 * @property {Channel} [channel]
 * @property {String|Number} [nextProgramId] Platform identifier of next program
 * @property {String|Number} [season] Season identifier (Maculosa ? Platform ?)
 * @property {*} externalContentId DEPRECATED ?
 * @property {String} episodeTitle Episode title if Live related to a serie
 * @property {Number} episodeNumber Episode number if Live related to a serie
 * @property {String|Number} seasonNumber Season number
 * @property {String|Number} serieId Platform identifier of related serie
 * @property {String} eventType Type of the live (~category)
 * @property {Array<String>} [categories=[]] Categories of the program
 * @property {String} categoryAsString Program category/genre as a string (as it comes from the proxy)
 * @property {Number} [rollingBufferDuration=0] rollingBuffer retention duration, in hours
 * @property {Boolean} [recordable=true] Program is recordable
 * @property {Boolean} [isRecording=false]
 * @property {Boolean} [isLauncherProgram=false] Is program a fake one to launch the live for related channel
 * @property {Boolean} [isFillerProgram=false] Is program a filler, due to a gap in EPG programmation
 * @property {OptaDetails} optaDetails
 * @property {Boolean} supportMulticam Does program supports multicam
 */
export class Live extends Program {
    logo: string
    channelId: string | number
    platformChannelId: string | number
    sourceId: string
    scheduleType: string
    startTime?: number
    startDate?: number
    endTime?: number
    originalBroadcastTime?: number
    channel?: Channel
    nextProgramId?: string | number
    season?: number
    serieTitle?: string
    externalContentId: number
    episodeTitle: string
    episodeNumber: number
    seasonNumber: string | number
    serieId: string | number
    eventType: string
    categoryAsString: string
    optaDetails?: OptaDetails
    categories?: string[]
    rollingBufferDuration?: number
    recordable?: boolean
    isRecording?: boolean
    isLauncherProgram?: boolean
    isFillerProgram?: boolean
    dataRequests?: string[]
    requestIds?: object
    cast?: Actor[]
    producers?: string
    directors?: string[]
    splitCategories: string[]
    modelType: string
    revision?: number
    supportMulticam?: boolean

    constructor(props: ILive) {
        super(props)

        this.logo = props.logo
        this.channelId = props.channelId
        this.platformChannelId = props.platformChannelId
        this.sourceId = props.sourceId

        this.scheduleType = props.scheduleType

        if (props.startTime) {
            this.startTime = props.startTime
            this.startDate = props.startTime
        }
        if (props.endTime) {
            this.endTime = props.endTime
        }

        if (props.originalBroadcastTime) {
            this.originalBroadcastTime = props.originalBroadcastTime
        }

        if (!this.duration && this.startTime && this.endTime) {
            this.duration = this.endTime - this.startTime
        }

        if (props.channel) {
            this.channel = props.channel
        }
        if (props.nextProgramId) {
            this.nextProgramId = props.nextProgramId
        }

        if (props.season) {
            this.season = parseInt(props.season.toString(), 10)
        }

        if (props.serieTitle) {
            this.serieTitle = props.serieTitle
        }

        this.externalContentId = props.externalContentId
        this.episodeTitle = props.episodeTitle
        this.episodeNumber = props.episodeNumber
        this.seasonNumber = props.seasonNumber
        //this.seasonId = props.seasonId
        this.serieId = props.serieId
        this.eventType = props.eventType
        this.format = 'landscape'
        this.revision = props.revision
        this.categoryAsString = props.categoryAsString

        const computePropValue = this._makeComputePropValue(props)

        /**
         * @typedef {Object} OptaDetails Parameters from opta API
         * @property {Array<String>|String} optaFixtureId The name of game ID or Fixture ID from Opta Server to be able to query detail for a particular Match from the content (EPG/VOD)
         * @property {String} optaSport The name of the sport when content is associated to Opta Statistics Event (soccer, volley, etc...). Only one sport supported for now: soccer
         * @property {Array<String>} optaTeamId The ID of the Team where the sport event is attached (any game involved multiple teams. Example for Game 1 Arsenal and Manchester)
         * @property {Constants.optaCompetitionCode} optaCompetitionCode The ID of the competition (EPL = EPL / Bundesliga = BUN / Champion League = UCL / WORLD CUP: WOC)
         */
        this.optaDetails = computePropValue('optaDetails', {})
        this.categories = computePropValue('categories', [])
        this.rollingBufferDuration = computePropValue('rollingBufferDuration', 0)
        this.recordable = computePropValue('recordable', true)
        this.isRecording = computePropValue('isRecording', false)
        this.isLauncherProgram = computePropValue('isLauncherProgram', false)
        this.isFillerProgram = computePropValue('isFillerProgram', false)

        this.dataRequests = computePropValue('dataRequests', [])

        this.requestIds = computePropValue('requestIds', {})

        this.cast = computePropValue('cast', [])
        this.producers = computePropValue('producers', [])
        this.directors = computePropValue('directors', [])

        this.splitCategories = this.categories
            ? [
                  ...new Set(
                      this.categories.reduce((acc: string[], c: string) => {
                          return [...acc, ...c.split(/[\s+&]+/g)]
                      }, [])
                  ),
              ]
            : []

        this.modelType = Constants.programType.live
        this.supportMulticam = props.supportMulticam || false
    }

    // Is the program related to a game
    isGame() {
        return (
            !!this.optaDetails &&
            (!!this.optaDetails.optaFixtureId ||
                !!this.optaDetails.optaSport ||
                !!this.optaDetails.optaTeamId)
        )
    }

    /**
     * Is Live UI representation uses Landscape format (always `true`)
     * @return {Boolean}
     */
    isLandscape() {
        return true
    }

    /**
     * Is the live watchable by current user
     * @return {Boolean}
     */
    isWatchable() {
        return !!this.canWatch
    }

    /**
     * @return {Boolean}
     */
    isRecordable() {
        return !!this.recordable
    }

    /**
     * Is this program a Live (always `true`)
     * @return {Boolean}
     */
    isLive() {
        return true
    }

    /**
     * A launcher Live (placeholder to launch live from the channel) is always
     * considered as liveNow.
     *
     * @returns {Boolean} true if program is LiveNow, else false
     */
    isLiveNow() {
        if (this.startTime && this.endTime) {
            return this.isLauncher() || (this.startTime <= Date.now() && this.endTime > Date.now())
        } else {
            return this.isLauncher()
        }
    }

    /**
     * Is this Live program from the future.
     *
     * @returns {Boolean} true if program is LiveNow, else false
     */
    isFutureLive() {
        if (this.startTime) {
            return !this.isLauncher() && this.startTime > Date.now()
        } else {
            return false
        }
    }

    /**
     * Is this Live program already ended.
     *
     * *Note:* A launcher Live (placeholder to launch live from the channel) is always
     * considered as NOT pastLive.
     *
     * @returns {Boolean}
     */
    isPastLive() {
        if (this.endTime) {
            return !this.isLauncher() && this.endTime <= Date.now()
        } else {
            return false
        }
    }

    /**
     * Is this Live a launcher program.
     * A launcher program is used as a placeholder program to lanch live playback of a channel.
     * @return {Boolean}
     */
    isLauncher() {
        return !!this.isLauncherProgram
    }

    /**
     * Is this Live a filler program.
     * A filler program is used when a gap is found between to program in EPG programmation.
     * @return {Boolean}
     */
    isFiller() {
        return !!this.isFillerProgram
    }

    /**
     * Determine if program is available in the rollingBuffer, regarding its
     * `startTime`, the `rollingBufferDuration` and current datetime.
     *
     * @return {Boolean}
     */
    isInRollingBuffer() {
        if (this.rollingBufferDuration && this.startTime) {
            const rollingBufferCutOff = Date.now() - this.rollingBufferDuration * 60 * 60 * 1000
            const startTime = this.isLauncher() ? getStartTime() : this.startTime

            return startTime >= rollingBufferCutOff && startTime <= Date.now()
        }
    }

    /**
     * Is record of thie live program scheduled
     * @return {Boolean}
     */
    isRecordingScheduled() {
        if (this.endTime) {
            return this.endTime > Date.now() && !!this.isRecording
        }
        return false
    }

    /**
     * Return the duration of the Program in seconds
     * @return {Number}
     */
    durationInSec() {
        return this.duration / 1000
    }

    isRestricted(param: number | { userPcLevel: number; restrictedChannels: (string | number)[] }) {
        // Workaround to keep other plateform usage
        let userPcLevel = -1,
            restrictedChannels
        if (typeof param === 'number') userPcLevel = param
        else if (typeof param === 'object') {
            userPcLevel = param.userPcLevel
            restrictedChannels = param.restrictedChannels
        }

        if (restrictedChannels && restrictedChannels.includes(this.channelId)) {
            return true
        }
        if (this.pcValue === undefined || userPcLevel < 0) {
            return false
        }
        return this.pcValue >= userPcLevel
    }

    isMultiCamSupported() {
        return !!this.supportMulticam
    }
}
