export default {
    // Same URL for all clients, not necessary to externalize this config
    apiUrl: 'https://www.googleapis.com/youtube/v3/',

    // Enable to retrieve status of each video for Youtube category
    // It allows to retrieve if the video is playable of not (for later use like playing video directly inside the UI)
    enableStatusYoutubeVideo: false, // Deactivate yet to avoid server computation

    // This parameter is for filtering Youtube video (not necessary to move it in Project Config)
    filterPrivateVideo: true, // Application yet only for Playlist video as there are at ton of private videos

    // Youtube description are huge and consume a lot of memory. Crop them instead of consuming RAM memory without being able to display them BTW
    descriptionMaxString: 200,

    youtubeCategoryID: {
        YOUTUBE_ID_FILM_AND_ANIMATION: 1,
        YOUTUBE_ID_AUTO_AND_VEHICULES: 2,
        YOUTUBE_ID_MUSIC: 10,
        YOUTUBE_ID_PETS_AND_ANIMAL: 15,
        YOUTUBE_ID_SPORTS: 17,
        YOUTUBE_ID_SHORT_MOVIES: 18,
        YOUTUBE_ID_TRAVEL_AND_EVENTS: 19,
        YOUTUBE_ID_GAMING: 20,
        YOUTUBE_ID_VIDEO_BLOGGING: 21,
        YOUTUBE_ID_PEOPLE_AND_BLOGS: 22,
        YOUTUBE_ID_COMEDY: 23,
        YOUTUBE_ID_ENTERTAINMENT: 24,
        YOUTUBE_ID_NEWS_AND_POLITICS: 25,
        YOUTUBE_ID_HOW_TO_AND_STYLE: 26,
        YOUTUBE_ID_EDUCATION: 27,
        YOUTUBE_ID_SCIENCE_AND_TECHNOLOGY: 28,
        YOUTUBE_ID_NON_PROFITS_AND_ACTIVISM: 29,
        /**
         * Not supported now
         */
        YOUTUBE_ID_MOVIES: 30,
        YOUTUBE_ID_ANIME_AND_ANIMATION: 31,
        YOUTUBE_ID_ACTION_AVENTURE: 32,
        YOUTUBE_ID_CLASSICS: 33,
        YOUTUBE_ID_COMEDY_2: 34,
        YOUTUBE_ID_DOCUMENTARY: 35,
        YOUTUBE_ID_DRAMA: 36,
        YOUTUBE_ID_FAMILY: 37,
        YOUTUBE_ID_FOREIGN: 38,
        YOUTUBE_ID_HORROR: 39,
        YOUTUBE_ID_SCI_FI_FANTASY: 40,
        YOUTUBE_ID_THRILLER: 41,
        YOUTUBE_ID_SHORTS: 42,
        YOUTUBE_ID_SHOWS: 43,
        YOUTUBE_ID_TRAILERS: 44,
        YOUTUBE_ID_UNKNOWN: 99,
    },
    kind: {
        video: 'youtube#video',
        playlist: 'youtube#playlist',
        playlistItem: 'youtube#playlistItem',
        channel: 'youtube#channel',
        subscription: 'youtube#subscription',
        unknown: 'unknownListResponse',
    },
    genres: {
        video: 'Youtube Video',
        playlist: 'Youtube Playlist',
        channel: 'Youtube Channel',
    },
    youtube_base_url: {
        video: 'http://www.youtube.com/watch?v=',
        playlist: 'http://www.youtube.com/playlist?list=',
        channel: 'http://www.youtube.com/channel/',
        default: 'http://www.youtube.com/',
    },
    // MRI: Youtube Android package name differs according to the platform
    // There is no need to provide to package name of the application for launching an android intent.
    // The URI of the content is resolved by Android according to a scheme to resolve in real time the package targeted
    // Except if the application is not installed. URI cannot lead Android to fallback to Youtube Play Store
    // @Todo : So Package name could be mandatory to provide for intent launching but this will be resolved later
    youtubeCommandName: {
        android: 'com.google.android.youtube',
        androidtv: 'com.google.android.youtube.tv',
        amazonFire: 'com.amazon.firetv.youtube',
        web: '', // Security
    },
}
