import { IIaProduct } from '@typings/tile'
import { Abstract } from './Abstract'

/**
 * An IapProduct refers to In App Purchase Products for external Store (Google, Apple, Roku, Amazon)
 * @property {String} id IAP Product
 * @property {String} appStore Refers to external store (Google, Apple, Roku, Amazon)
 * @property {String} type Iap Type: Product, Subscription
 */
export class IapProduct extends Abstract {
    id: string
    appStore: string
    type: string

    constructor(props: IIaProduct) {
        super()
        const computePropValue = this._makeComputePropValue(props)

        this.id = props.id
        this.appStore = computePropValue('appStore', IapProduct.IAP_APP_STORE.UNKNOWN)
        this.type = computePropValue('type', IapProduct.IAP_TYPE.SUBSCRIPTION)
    }

    isSubscription() {
        return this.type === IapProduct.IAP_TYPE.SUBSCRIPTION
    }

    isProduct() {
        return this.type === IapProduct.IAP_TYPE.PRODUCT
    }

    static IAP_TYPE = {
        PRODUCT: 'product',
        SUBSCRIPTION: 'subscription',
    }

    static IAP_APP_STORE = {
        GOOGLE: 'google',
        APPLE: 'apple',
        AMAZON: 'amazon',
        ROKU: 'roku',
        UNKNOWN: 'unknown',
    }
}
