/**
 * Warning: Currying function
 *
 * Return a Function which check if provided value could be parsed as a Float.
 * If yes: invoke convertFn by providing original value
 * If no : return undefined
 *
 * @param {Function} convertFn A conversion function. Value will be provided as argument and return value will be returned
 * @return {Function} The built function with Cast check
 */
const makeWithCastCheck = convertFn => value => {
    value = parseFloat(value)

    if (isNaN(value)) return

    return convertFn(value)
}

const fromSeconds = makeWithCastCheck(v => v * 1000)
const fromMinutes = makeWithCastCheck(v => fromSeconds(v) * 60)
const fromHours = makeWithCastCheck(v => fromMinutes(v) * 60)
const fromDays = makeWithCastCheck(v => fromHours(v) * 24)

export default {
    fromSeconds,
    fromMinutes,
    fromHours,
    fromDays,
}
