export const commonKeyCode  = {
    "Enter": 13,
    "Left": 37,
    "Up": 38,
    "Right": 39,
    "Down": 40
}

export const webOsKeyCode = {
    "Back": 461,
}

export const tizenKeyCode = {
    "Back": 10009,
}


