import HuaweiTypes from '../HuaweiTypes'
import ConfigHelper from 'framework/helpers/config'
import Constants from 'api-constants'
import CryptoJS from 'generated-libs/crypto-js'
import { DEVICE_DEFAULT_CONFIG, LOCALE_MAPPING_API_V2 } from '../Config'

/**
 * Resolve device attributes
 * @param {Object} args Arguments
 * @param {Object} args.deviceData Device Data
 * @returns {Object} Device Attributes
 */
export const resolveDeviceAttributes = ({ deviceData } = {}) => {
    const { platform = Constants.platform.androidTvStb } = ConfigHelper.getInstance().getConfig()

    const { deviceMapping, forceMacAddressPattern = false } =
        ConfigHelper.getInstance().getConfig('huawei')
    const config = { ...DEVICE_DEFAULT_CONFIG, ...deviceMapping }

    if (config && config[platform]) {
        const {
            deviceModel,
            CADeviceType,
            CADeviceTypeApiV2,
            requireMacAddressLogin = false,
            defaultDisplayedName,
        } = config[platform]

        let deviceId
        let deviceName
        const {
            serialNumber,
            deviceNickName,
            manufacturer,
            macAddress,
            ipAddress,
            uniqueDeviceId,
        } = deviceData || {}
        if (deviceData) {
            deviceId = uniqueDeviceId || ConfigHelper.getInstance().getConfig().uniqueDeviceId
            if (useApiLoginV2()) {
                if (platform === Constants.platform.androidTvStb) {
                    deviceId = serialNumber || filterMacAddress(macAddress) || deviceId
                }
            } else {
                if (requireMacAddressLogin) {
                    if (macAddress) {
                        deviceId = filterMacAddress(macAddress)
                    } else if (forceMacAddressPattern) {
                        /**
                         * If the mac address is not provided and debug mode enabled, then generate a MAC address from UUID
                         */
                        deviceId = debugGenerateMacAddress(deviceId)
                    }
                }
            }

            if (manufacturer && deviceNickName) {
                deviceName = `${manufacturer} - ${deviceNickName}`
            } else if (manufacturer) {
                deviceName = `${manufacturer} - ${defaultDisplayedName}`
            } else if (deviceNickName) {
                deviceName = `${defaultDisplayedName} - ${deviceNickName}`
            } else {
                deviceName = defaultDisplayedName
            }
        }

        return {
            CADeviceType,
            CADeviceTypeApiV2,
            deviceModel,
            deviceId,
            deviceName,
            serialNumber,
            macAddress:
                filterMacAddress(macAddress) ||
                (forceMacAddressPattern ? debugGenerateMacAddress(deviceId) : undefined),
            ipAddress,
        }
    }

    return {}
}

/**
 * Retrieves the device Config
 * @returns {String} Device Modeil
 */
export const getDeviceConfig = () => {
    const { platform = Constants.platform.androidTvStb } = ConfigHelper.getInstance().getConfig()
    const { deviceMapping } = ConfigHelper.getInstance().getConfig('huawei')
    const config = { ...DEVICE_DEFAULT_CONFIG, ...deviceMapping }
    if (config && config[platform]) {
        return config[platform]
    }

    return {}
}

/**
 * Retrieves the device Model
 * @returns {String} Device Modeil
 */
export const getDeviceModel = () => {
    const { deviceModel } = getDeviceConfig()
    return deviceModel
}

/**
 * Encrypt Password using MD5 (Needed for Login via API v2)
 * @param {String} password
 * @returns Password encrypted using MD5
 */
export const encryptPassword = (password = HuaweiTypes.defaultPassword) => {
    if (password === '1111') {
        // To save performance for default password, it is always equal to the value below
        // No need to consume performance for that
        return '94de8e73'
    }

    var pwdHw = password + '99991231'
    var key = CryptoJS.MD5(pwdHw)
    key = key.toString()

    var _loc3_ = ''
    var _loc4_ = 0
    while (_loc3_.length !== 8 && key.length > 0) {
        _loc4_ = _loc4_ + 1
        var _loc2_ = key.substr(0, 1)
        key = key.substr(1, key.length)
        if (_loc2_ !== '0' || (_loc2_ === '0' && _loc4_ % 2 === 0)) {
            _loc3_ = _loc3_ + _loc2_
        }
    }
    return _loc3_
}

/**
 * Generate Authenticator. This is needed for Login via API V2
 * @param {Object} args
 * @param {String} args.userId
 * @param {String} args.password
 * @param {String} args.encryptToken
 * @param {String} args.terminalId
 * @param {String} args.addressIp
 * @param {String} args.macAddress
 * @returns
 */
export const generateAuthenticator = ({
    userId,
    password = HuaweiTypes.defaultPassword,
    encryptToken,
    terminalId,
    addressIp = '192.168.1.1',
    macAddress = '',
}) => {
    const random = Math.floor(Math.random() * 99999999)

    let rawAuthenticator =
        random +
        '$' +
        encryptToken +
        '$' +
        userId +
        '$' +
        terminalId +
        '$' +
        addressIp +
        '$' +
        macAddress +
        '$' +
        'Reserved' +
        '$' +
        'CTC'

    const key = encryptPassword(password)
    const keyHex = CryptoJS.enc.Utf8.parse(key)
    const authenticatorEncrypted = CryptoJS.TripleDES.encrypt(rawAuthenticator, keyHex, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
    })

    return authenticatorEncrypted.ciphertext.toString()
}

/**
 * Request Language mapping
 * @param {String} language
 * @returns Equivalence of Language
 */
export const getLanguageMappingApiV2 = (language) => {
    return LOCALE_MAPPING_API_V2[language] || LOCALE_MAPPING_API_V2.en
}

/**
 * Request Language mapping
 * @param {String} langCode
 * @returns {String} language
 */
export const convertLanguageMappingApiV2 = (langCode) => {
    return (
        (langCode &&
            Object.keys(LOCALE_MAPPING_API_V2).find(
                (x) => LOCALE_MAPPING_API_V2[x] === langCode
            )) ||
        LOCALE_MAPPING_API_V2.en
    )
}

/**
 * This method filters the MAC Address by removing ':'
 */
export const filterMacAddress = (macAddress) => {
    if (!macAddress) return
    return macAddress.replace(/[^a-z\d\s]+/gi, '').replace(/ /g, '')
}

/**
 * This method filters the MAC Address by removing ':'
 */
export const debugGenerateMacAddress = (uuid) => {
    let myUUID = uuid ? uuid : `$${Math.random().toString(36).slice(2, 14)}`
    let mac = filterMacAddress(myUUID)
    mac = mac.length > 12 ? mac.substring(0, 12) : mac.padStart(12, '0')

    let result = ''
    for (let idx = 0; idx < mac.length; idx++) {
        const c = mac.charAt(idx)
        if ((c > 'F' && c <= 'Z') || (c > 'f' && c <= 'z')) {
            result += String.fromCharCode(65 + (c.charCodeAt(0) % 5))
        } else {
            result += c
        }
    }

    return result
}

/**
 * Resolve rating mapping between the backend and IFS
 * @param {String} backendRatingId Rating ID from the backend
 * @returns {Number} Parental Control level
 */
export const resolvePcLevel = (backendRatingId) => {
    const userPcLevels = ConfigHelper.getInstance().getConfig().userPcLevels

    let ratingObject = Array.isArray(userPcLevels)
        ? (userPcLevels || []).find((x) => backendRatingId === x.backendRatingId)
        : Object.values(userPcLevels).find((x) => backendRatingId === x.backendRatingId) || {}

    const { level } = ratingObject || {}
    return level
}

/**
 * Resolve rating mapping between the backend and IFS
 * @param {Number} internalPcLevel Rating ID from the backend
 * @returns {String} Backend Rating Id
 */
export const resolveBackendRatingId = (internalPcLevel) => {
    const userPcLevels = ConfigHelper.getInstance().getConfig().userPcLevels

    let ratingObject = Array.isArray(userPcLevels)
        ? (userPcLevels || []).find((x) => internalPcLevel === x.level)
        : Object.values(userPcLevels).find((x) => internalPcLevel === x.level) || {}

    const { backendRatingId = '0' } = ratingObject || {}
    return backendRatingId
}

/**
 * Resolve the family role
 * @param {Boolean} isKidsProfile=false Indicate whether this is a kids profile or not
 * @returns {HuaweiTypes.ooredooFamilyRole} Family Role
 */
export const resolveFamilyRole = (isKidsProfile = false) => {
    return isKidsProfile
        ? // Do the same as Legacy application which creates a Kids profile using OTHER type rather than SON
          // HuaweiTypes.ooredooFamilyRole.SON
          HuaweiTypes.ooredooFamilyRole.OTHER
        : HuaweiTypes.ooredooFamilyRole.DAD
}

export const useApiLoginV2 = () => {
    return ConfigHelper.getInstance().getConfig('huawei').useLoginApiV2
}

/**
 * Mapping between ifs bookmark type (@link HuaweiTypes.bookmarkType) and ifs program type (@link onstants.programType)
 * @param {String} programType (@link onstants.programType)
 * @returns {String} bookmark type (@link HuaweiTypes.bookmarkType)
 */
export const resolveHuaweiBookmarkType = (programType) => {
    switch (programType) {
        case Constants.programType.vod:
        case Constants.programType.movie:
        case Constants.programType.season:
        case Constants.programType.episode:
        case Constants.programType.orphanEpisode:
        case Constants.programType.vodCatchup:
            return HuaweiTypes.bookmarkType.VOD

        case Constants.programType.series:
            return HuaweiTypes.bookmarkType.SERIES

        case Constants.programType.live:
        case Constants.programType.replay:
            return HuaweiTypes.bookmarkType.PROGRAM

        case Constants.programType.dvr:
            return HuaweiTypes.bookmarkType.NPVR

        default:
            return undefined
    }
}

/**
 * Mapping between ifs bookmark type (@link HuaweiTypes.bookmarkType) and ifs program type (@link onstants.programType)
 * @param {String} bookmark type (@link HuaweiTypes.bookmarkType)
 * @returns {String} programType (@link onstants.programType)
 */
export const resolveBookmarkType = (huwaiBookmarkType) => {
    switch (huwaiBookmarkType) {
        case HuaweiTypes.bookmarkType.VOD:
            return Constants.programType.vod

        case HuaweiTypes.bookmarkType.SERIES:
            return Constants.programType.series

        case HuaweiTypes.bookmarkType.PROGRAM:
            return Constants.programType.live

        case HuaweiTypes.bookmarkType.NPVR:
            return Constants.programType.dvr

        default:
            return undefined
    }
}

/**
 * Add image resizing attributes
 * @param {String} picture
 * @param {'thumbnail'|'backdground'} imageType
 * @param {'portrait'|'landscape'} layout
 * @returns
 */
export const addImageResizingAttributes = (
    picture,
    imageType = 'thumbnail',
    layout = 'landscape'
) => {
    switch (imageType) {
        case 'thumbnail':
            return picture
                ? layout === 'layout'
                    ? `${picture}?x=341&y=192`
                    : `${picture}?x=341&y=192`
                : undefined

        case 'backdrop':
        case 'backdground':
            return picture ? `${picture}?x=960&y=540` : undefined

        default:
            return picture
    }
}
