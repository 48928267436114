import get from 'lodash.get'

/**
 * Check if two array of object is equal
 *
 * @param   {?array} arr1 source array
 * @param   {?array} arr2 destination array
 * @param   {array} properties properties to compare
 *
 * @returns {boolean} true | false
 */
function isEqual(arr1, arr2, properties) {
    if (
        !Array.isArray(arr1) ||
        !Array.isArray(arr2) ||
        arr1.length !== arr2.length
    )
        return false

    if (!Array.isArray(properties)) {
        return arr1.every((elem1, i) => {
            return elem1 === arr2[i]
        })
    } else {
        return arr1.every((elem1, i) => {
            const elem2 = arr2[i]
            return properties.every(k =>
                Array.isArray(k)
                    ? get(elem1, k) === get(elem2, k)
                    : elem1[k] === elem2[k],
            )
        })
    }
}

export default isEqual
