import { IPlayerRanking } from '@typings/opta'
import { Abstract } from './Abstract'

export class SoccerPlayerRanking extends Abstract {
    clubName: string
    teamLogo?: string
    name: string
    totalAssists?: number
    totalGoals?: number

    constructor(props: IPlayerRanking) {
        super()

        this.clubName = props.clubName
        this.teamLogo = props.teamLogo
        this.name = props.name
        this.totalAssists = props.totalAssists
        this.totalGoals = props.totalGoals
    }
}
