import Constants from 'api-constants'

const {
    GET_LIVE_NOW_ALL,
    GET_LIVE_NOW_FAVORITES,
    GET_LIVE_NOW_BY_GENRE,
    GET_AVAILABLE_RECORDINGS_BY_DATE,
    GET_SCHEDULED_RECORDINGS_BY_DATE,
    GET_ALL_RECORDINGS_BY_NAME,
    GET_ALL_SERIES_RECORDINGS_BY_NAME,
    GET_ALL_VODS_SERIES,
    GET_VODS_EPISODES_OF_SEASON,
    GET_VODS_SEASONS_OF_SERIES,
    GET_VODS_EPISODES_BY_SERIE,
    CONTINUE_WATCHING_VOD_TVSHOWS,
    GET_VODS_OF_CATEGORY,
    GET_VODS_ADULT_OF_CATEGORY,
    GET_VODS_FROM_NODE_PLATFORM_ID,
    GET_YOUTUBE_PLAYLIST,
    GET_UPCOMING_GAMES,
    GENERATE_ANDROID_CHANNEL_APPS_REQUESTS_ALIAS,
    GENERATE_ANDROID_CHANNEL_APPS_REQUESTS,
    GET_FIXTURE_BY_DATE,
    GET_FIXTURE_BY_TEAM,
    GET_TEAM_SQUAD,
    GET_PLAYER_RANKING,
    GET_ALL_RANKING,
    GET_LEAGUE_RANKING,
    GET_ALL_VODS,
    GET_ALL_VODS_MOVIES,
    CONTINUE_WATCHING_VOD_ALL,
    CONTINUE_WATCHING_VOD_MOVIES,
    GET_FAVORITES_ALL,
    GET_FAVORITE_RECORDINGS,
    GET_PURCHASED_TVODS_MOVIES,
    GET_PURCHASED_TVODS_MOVIES_EST,
    GET_PURCHASED_TVODS_MOVIES_TVOD,
    GET_PURCHASED_TVODS_SERIES,
    GET_PACKAGES_ALL,
    GET_PACKAGES_SUBSCRIBED,
    GET_ALL_APPS_AND_GAMES_BY_MOST_RECENT,
    GET_ALL_APPS_BY_MOST_RECENT,
    GET_ALL_GAMES_BY_MOST_RECENT,
    ANDROID_CHANNEL_APPS_GET_CONTENT_WATCH_NEXT,
    GET_CONTINUE_WATCHING_RECORDINGS,
} = Constants.emiRequests

const MAPPING_LEGACY_REQUEST = {
    // -------------------------------------
    // -- EPGProgram
    // -------------------------------------
    EPGProgram_content_now_channels_all: GET_LIVE_NOW_ALL,
    EPGProgram_content_now_favorites_all: GET_LIVE_NOW_FAVORITES,
    EPGProgram_content_now_channels_genre: GET_LIVE_NOW_BY_GENRE,
    // -------------------------------------
    // -- VODContent
    // -------------------------------------
    VODContent_content_all_tvshow: GET_ALL_VODS_SERIES,
    VODContent_content_continue_watching_series: CONTINUE_WATCHING_VOD_TVSHOWS,
    VODContent_vod_all: GET_ALL_VODS,
    VODContent_vod_content: GET_ALL_VODS,
    VODContent_vod_movies: GET_ALL_VODS_MOVIES,
    VODContent_vod_series: GET_ALL_VODS_SERIES,
    VODContent_vod_continue_watching: CONTINUE_WATCHING_VOD_ALL,
    VODContent_vod_movies_continue_watching: CONTINUE_WATCHING_VOD_MOVIES,
    VODContent_vod_series_continue_watching: CONTINUE_WATCHING_VOD_TVSHOWS,
    VODContent_vod_favorites: GET_FAVORITES_ALL,
    VODContent_vod_rental: GET_PURCHASED_TVODS_MOVIES,
    VODContent_vod_purchase: GET_PURCHASED_TVODS_MOVIES_EST,
    VODContent_vod_movies_rental: GET_PURCHASED_TVODS_MOVIES_TVOD,
    VODContent_vod_series_rental: GET_PURCHASED_TVODS_SERIES,
    // -------------------------------------
    // -- PVRContent
    // -------------------------------------
    PVRContent_content_all_programming: GET_ALL_RECORDINGS_BY_NAME,
    PVRContent_all_recording_by_alpha: GET_ALL_RECORDINGS_BY_NAME,
    PVRContent_content_recurrent_programming: GET_ALL_SERIES_RECORDINGS_BY_NAME,
    PVRContent_dvr_continue_watching: GET_CONTINUE_WATCHING_RECORDINGS,
    PVRContent_dvr_favorites: GET_FAVORITE_RECORDINGS,
    PVRContent_dvr_recorded: GET_AVAILABLE_RECORDINGS_BY_DATE,
    PVRContent_dvr_scheduled: GET_SCHEDULED_RECORDINGS_BY_DATE,

    // -------------------------------------
    // -- Subscription
    // -------------------------------------
    SubscriptionOffers_all_pack: GET_PACKAGES_ALL,
    SubscriptionOffers_all_packs: GET_PACKAGES_ALL,
    SubscriptionOffers_my_pack: GET_PACKAGES_SUBSCRIBED,
    SubscriptionOffers_my_packs: GET_PACKAGES_SUBSCRIBED,
    // -------------------------------------
    // -- APPContent
    // -------------------------------------
    APPContent_apps_games: GET_ALL_APPS_AND_GAMES_BY_MOST_RECENT,
    APPContent_apps: GET_ALL_APPS_BY_MOST_RECENT,
    APPContent_games: GET_ALL_GAMES_BY_MOST_RECENT,

    // -------------------------------------
    // -- Channel Apps
    // -------------------------------------
    ChannelApplication_channel_api_watch_next: ANDROID_CHANNEL_APPS_GET_CONTENT_WATCH_NEXT,
    // -------------------------------------
    // -- Youtube
    // -------------------------------------
    YouTube_Playlist: GET_YOUTUBE_PLAYLIST,
    Youtube_playlist: GET_YOUTUBE_PLAYLIST,
}

export const resolveTemplateType = (template) => {
    if (template === 'carousel' || template === 'info' || template === 'fullscreen') {
        return template
    }

    return 'row'
}

export const deduplicateEmiRequests = (requestName) => {
    if (MAPPING_LEGACY_REQUEST[requestName]) {
        return MAPPING_LEGACY_REQUEST[requestName]
    }
    return requestName
}

/**
 * Determines the ID of a predefined EMI request
 * The ID is usually the content selector value (so that SPZs/PPZs with the same value
 * share the same request).
 * Example: for VOD By Genre request : By using this function it allows to trigger two
 * different calls for VOD By Genre 'Action' and VOD By Genre 'Family'. Without this function
 * the second call will use cache rather than triggering the query
 *
 * @param {EmiRequest} request
 */
export const resolveEmiRequestId = (request) => {
    let requestId
    switch (request.value) {
        case GET_YOUTUBE_PLAYLIST:
        case GET_VODS_ADULT_OF_CATEGORY:
        case GET_VODS_OF_CATEGORY:
            {
                const { category, dataset, type } = request.args[0] || {}
                requestId = [request.value, category, dataset, type].filter(Boolean).join('_')
            }
            break
        case GET_LIVE_NOW_BY_GENRE:
            requestId = [request.value, (request.args[0] || {}).category].filter(Boolean).join('_')
            break
        case GET_VODS_EPISODES_OF_SEASON:
        case GET_VODS_EPISODES_BY_SERIE:
        case GET_VODS_SEASONS_OF_SERIES:
            {
                const { id } = request.args[0] || {}
                requestId = [request.value, id].filter(Boolean).join('_')
            }
            break
        case GET_UPCOMING_GAMES:
            {
                const { opta_competition_code } = request.args[0] || {}
                requestId = [request.value, opta_competition_code].filter(Boolean).join('_')
            }
            break
        case GET_VODS_FROM_NODE_PLATFORM_ID:
            {
                const {
                    node,
                    node_type,
                    dataset,
                    category,
                    content_type,
                    opta_team_id,
                    opta_competition_code,
                } = request.args[0] || {}

                requestId = [
                    request.value,
                    node,
                    node_type,
                    dataset,
                    category,
                    content_type,
                    opta_team_id,
                    opta_competition_code,
                ]
                    .filter(Boolean)
                    .join('_')
            }
            break
        case GENERATE_ANDROID_CHANNEL_APPS_REQUESTS_ALIAS:
        case GENERATE_ANDROID_CHANNEL_APPS_REQUESTS:
            {
                const { context, package_name } = request.args[0] || {}

                requestId = `${request.value}_${package_name || 'all_packages'}_${
                    context || 'default'
                }`
            }
            break
        case GET_FIXTURE_BY_DATE:
            {
                const { matchday, opta_competition_code } = request.args[0] || {}
                requestId = [request.value, matchday, opta_competition_code]
                    .filter(Boolean)
                    .join('_')
            }
            break
        case GET_FIXTURE_BY_TEAM:
        case GET_TEAM_SQUAD:
            {
                const { opta_team_id, opta_competition_code } = request.args[0] || {}
                requestId = [request.value, opta_team_id, opta_competition_code]
                    .filter(Boolean)
                    .join('_')
            }
            break

        case GET_ALL_RANKING:
        case GET_LEAGUE_RANKING:
        case GET_PLAYER_RANKING:
            {
                const { opta_competition_code } = request.args[0] || {}
                requestId = [request.value, opta_competition_code].filter(Boolean).join('_')
            }
            break
        default:
            requestId = request.value
            break
    }
    if (request.requestFilter) {
        requestId += request.requestFilter.getRequestFilterUuid()
    }

    return requestId
}

/**
 * Coerce any 'Language-Region' in a single 'Language' Volt locale
 * Because EMI provide translation indexed by their locale like 'en_us'
 * And the translation files currently define a locale on 2 chars in volt: 'en', 'fr', 'es', ...
 *
 * @param {Object} labels
 */
export const getLabelsInTwoChar = (labels) => {
    const newLabels = {}
    Object.keys(labels).forEach((key) => {
        newLabels[key.split('_')[0]] = labels[key]
    })
    return newLabels
}

/**
 * Coerce any 'Language-Region' in a single 'Language' Volt locale
 * Because EMI provide translation indexed by their locale like 'en_us'
 * And the translation files currently define a locale on 2 chars in volt: 'en', 'fr', 'es', ...
 *
 * @param {Object} labels
 */
export const adaptPredefinedPrequest = (predefinedRequest) => {
    if (!predefinedRequest) return
    // Backward compatibility
    if (predefinedRequest.provider === 'DVRContent') {
        predefinedRequest.provider = 'PVRContent'
    }

    // Handle old movie requests by genre. ex: content_genre_indie_available
    if (
        predefinedRequest.provider === 'VODContent' &&
        predefinedRequest.data.name &&
        predefinedRequest.data.name.startsWith('content_genre') &&
        predefinedRequest.data.name.endsWith('_available')
    ) {
        const splitName = predefinedRequest.data.name.split('_')
        predefinedRequest.data.name = 'content_genre'
        predefinedRequest.data.category =
            splitName.length > 1 ? splitName[splitName.length - 2] : ''
        predefinedRequest.data.type = 0 //vodTypes.movie
    }

    // Handle old tvshows requests by genre. ex: content_shows_home_lifestyle_available
    if (
        predefinedRequest.provider === 'VODContent' &&
        predefinedRequest.data.name &&
        predefinedRequest.data.name.startsWith('content_shows') &&
        predefinedRequest.data.name.endsWith('_available')
    ) {
        const splitName = predefinedRequest.data.name.split('_')
        predefinedRequest.data.name = 'content_genre'
        predefinedRequest.data.category =
            splitName.length > 1 ? splitName[splitName.length - 2] : ''
        predefinedRequest.data.type = 1 //vodTypes.serie
    }

    // This is a dirty hack to handle old request all_movies
    if (
        predefinedRequest.data.name &&
        predefinedRequest.data.name === 'content_all_movies_available_a_z'
    ) {
        predefinedRequest.data.name = 'content_all_movies'
    }
    // This is another dirty hack to handle old request all_tvshows
    if (
        predefinedRequest.data.name &&
        predefinedRequest.data.name === 'content_all_tvshows_available_a_z'
    ) {
        predefinedRequest.data.name = 'content_all_tvshows'
    }
    //// HANDLE EMI LEGACY - END

    if (predefinedRequest.provider_type === 'suggestion') {
        predefinedRequest.provider = 'Suggestion'
    }

    // ExternalVOD provider was used by smartui to make the difference between displaying content
    // from catalog in database and content from Proxy API. But it is not necessary for VOLT products
    // to inherits this because VOLT in exclusively based on the Proxy API.
    // Better to add this adaptation here for instances using smartui & VOLT during the handover
    if (predefinedRequest.provider === 'ExternalVOD') {
        predefinedRequest.provider = 'VODContent'
    }
    // Pending fix from EMI. Unfortunately, this feature is on production.
    // Can be removed when XL production will include EMI-699
    if (!predefinedRequest.data.name && predefinedRequest.data.node) {
        predefinedRequest.data.name = 'content_node'
    }
    // Can be removed when XL production will include EMI-699
    if (!predefinedRequest.data.name && predefinedRequest.data.category) {
        predefinedRequest.data.name = 'content_genre'
    }

    const requestId = predefinedRequest.provider + '_' + predefinedRequest.data.name
    // Layout from EMI is not yet consistent for all providers and it will imply a huge impact for ALL product
    // if we enable this prop for all provider. As we need it for the moment only for VOD Content to support
    // multiple layout, enable it only for VOD pending stabilization from EMI
    // OR
    // PredefinedRequest has mixed content, so the layout can not be determined
    if (
        (predefinedRequest.provider !== 'VODContent' ||
            Constants.MixedContentRequestsIds.includes(requestId)) &&
        predefinedRequest.layout
    ) {
        predefinedRequest.layout = undefined
    }

    // Layout from EMI is not yet relevant for opta tables
    // Let's hardcode it for now
    if (
        predefinedRequest.data &&
        predefinedRequest.data.name &&
        predefinedRequest.provider === 'OPTA' &&
        predefinedRequest.data.name.includes('table')
    ) {
        predefinedRequest.layout = 'table'
    }

    /// ---------------- DEMO to be removed once feature is developped ----------------
    if (
        predefinedRequest.provider === 'EPGProgram' &&
        predefinedRequest.data.name &&
        predefinedRequest.data.name.startsWith('content_now_channels_genre_')
    ) {
        predefinedRequest.data.category = predefinedRequest.data.name.substring(
            'content_now_channels_genre_'.length
        )
        predefinedRequest.data.name = 'content_now_channels_genre'
    }
    /// END Demo

    return predefinedRequest
}
