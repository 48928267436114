import { Dvr } from 'models'
import HuaweiTypes from '../HuaweiTypes'
import { addImageResizingAttributes } from '../Helpers'
import { dvrSeriesFactory } from './dvrSeriesFactory'

/**
 * Returns parsed DVR metadata from the backend response
 *
 * Note: Huawei backend V2 used as it provides more metadata for series dvr
 */
export const dvrFactoryV2 = ({ dvr, config }) => {
    if (!dvr) return

    if (dvr.pvrList) {
        return dvrSeriesFactory({ dvr, config })
    }

    const picture = dvr.pictures?.[0]?.href

    return new Dvr({
        id: dvr.pvrId || dvr.periodPVRTaskId,
        recordingId: dvr.pvrId || dvr.periodPVRTaskId,
        platformChannelId: dvr.channelId || dvr.channelID,
        seriesId: dvr?.seriesID,
        seriesName: dvr?.periodPVRTaskName,
        seasonNumber: dvr?.seasonNum,
        episodeTitle: dvr?.subName,
        startTime: parseDateTimeString(dvr?.beginTime, config)?.getTime(),
        endTime: parseDateTimeString(dvr?.endTime, config)?.getTime(),
        status: HuaweiTypes.PVRStatusMappingV2[dvr.status] || '',
        duration: HuaweiTypes.parseInt(dvr.realRecordLength * 1000), // Huawei backend stores this value in seconds, but milliseconds needed
        title: dvr.pvrName || dvr.periodPVRTaskName,
        description: dvr?.introduce,
        thumbnail: addImageResizingAttributes(picture, 'thumbnail'),
        backdrop: addImageResizingAttributes(picture, 'backdrop'),
        eventIds: [dvr.programId || dvr.seriesId],
    })
}

/**
 * Parse `yyyyMMddHHmmss` date/time string as it's what Huawei V2 API returns
 *
 * @param {string} dateTimeString `yyyyMMddHHmmss` string
 * @returns {Date | undefined}
 */
export const parseDateTimeString = (dateTimeString, config) => {
    if (!dateTimeString) {
        config.logger.error('Date/Time string from Huawei API v2 is empty!')
        return
    }
    const year = dateTimeString.substring(4, 0)
    const month = dateTimeString.substring(6, 4)
    const day = dateTimeString.substring(8, 6)
    const hour = dateTimeString.substring(10, 8)
    const minute = dateTimeString.substring(12, 10)
    const sec = dateTimeString.substring(14, 12)

    // Convert date to ISO and add 'Z' to force UTC0 date otherwise it relies on user's locale
    return new Date(`${year}-${month}-${day}T${hour}:${minute}:${sec}Z`)
}
