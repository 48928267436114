import { CardType, IMatchEvent } from '@typings/opta'
import { Abstract } from './Abstract'

export class MatchEvent extends Abstract {
    id: number
    /**
     * 6 - Indicates the ball has gone out of play for a corner kick
     * 15 - A shot which is saved by the goalkeeper or blocked by an opposition player
     * 16 - Goal
     * 17 - A player is given a card by the referee
     * 18 - Player is substituted off
     * 30 - End of a match period
     * 32 - Start of a match period
     * 58 - Goalkeeper faces an opposition penalty
     * */
    typeId: number
    periodId: number
    timestamp: string
    timeMin: number
    timeSec: number
    cardType?: CardType

    constructor(props: IMatchEvent) {
        super()

        this.id = props.id
        this.typeId = props.typeId
        this.periodId = props.periodId
        this.timestamp = props.timestamp
        this.timeMin = props.timeMin
        this.timeSec = props.timeSec
        this.cardType = props.cardType
    }

    static EVENT_TYPE = {
        corner: 6,
        save: 15,
        goal: 16,
        card: 17,
        substitute: 18,
        endOfPeriod: 30,
        startOfPeriod: 32,
        penaltyFaced: 58,
    }

    static QUALIFIER_TYPE = {
        redCard: [33],
        yellowCard: [31, 32],
    }
}
