import Constants from 'api-constants'
import { of } from 'rxjs'
import { mergeMap, switchMap } from 'rxjs/operators'
import AuthApi from '../../huawei/AuthApi'
import HuaweiTypes from 'services/huawei/HuaweiTypes'

/**
 * Ooredoo APIGEE authentication APIs
 */
export default class OoredooAuthApi extends AuthApi {
    constructor(config, otherApis = {}) {
        super(config, otherApis)
        this.gatewayApi = otherApis.gatewayApi
        this.deviceData = ''
        this.language = ''
    }

    static username = ''
    static password = ''
    static deviceId = ''

    /**
     * Login orchestrator as we have different login methods
     *
     * @override
     * @param {Object} options
     * @param {string} options.username user name
     * @param {string} options.password user password
     * @param {string} options.language language
     * @param {Object} options.deviceData device parameters
     * @param {Constants.loginType} options.loginType login type
     * @returns Observable{boolean}
     */
    login({
        username,
        password,
        language,
        deviceData,
        loginType = Constants.loginType.USERNAME_AND_PASSWORD,
    }) {
        OoredooAuthApi.username = username
        OoredooAuthApi.password = password
        OoredooAuthApi.deviceId =
            !!deviceData && (deviceData.serialNumber || deviceData.uniqueDeviceId)
        this.deviceData = deviceData
        this.language = language

        switch (loginType) {
            case Constants.loginType.PHONE_NUMBER:
                return this.gatewayApi
                    .sendOTP({ phoneNumber: username })
                    .pipe(switchMap(() => of(Constants.authenticationStatus.AUTH_2FA_VALIDATE_OTP)))
            case Constants.loginType.ALTERNATIVE_USERNAME_AND_PASSWORD:
                return this.gatewayApi
                    .getIPTVAccountByUsernameAndPassword({ username, password })
                    .pipe(
                        mergeMap((accountNumber) =>
                            super.login({
                                username: accountNumber,
                                password: HuaweiTypes.defaultPassword,
                                loginType,
                                language,
                                deviceData,
                            })
                        )
                    )
            case Constants.loginType.USERNAME_AND_PASSWORD:
            default:
                return super.login({ username, password, language, loginType, deviceData })
        }
    }

    /**
     * Validation of the OTP using the login API (Workaround from CC)
     *
     * @param {Object} data
     * @param {String} data.code One Time Password
     * @returns {Observable<Boolean>} Return any observable means OTP has been validated. Any exception means it failed.
     */
    validateOTP(authType = Constants.authenticationType.LOGIN, { code } = {}) {
        const phoneNumber = OoredooAuthApi.username // previously cached during first step of the login
        return this.gatewayApi.validateOTP({ code, phoneNumber }).pipe(
            switchMap(() =>
                this.gatewayApi.getIPTVAccountByPhoneNumber({
                    phoneNumber,
                })
            ),
            switchMap((accountNumber) =>
                super.login({
                    username: accountNumber,
                    password: OoredooAuthApi.password || HuaweiTypes.defaultPassword,
                    loginType: Constants.loginType.PHONE_NUMBER,
                    language: this.language,
                    deviceData: this.deviceData,
                })
            )
        )
    }
}
