import AddImport from "./Add.tsx";
import ArrowDownImport from "./ArrowDown.tsx";
import ArrowDownFilledImport from "./ArrowDownFilled.tsx";
import ArrowUpImport from "./ArrowUp.tsx";
import BackgroundImageImport from "./BackgroundImage.tsx";
import BadgeAndoidImport from "./BadgeAndoid.tsx";
import BadgeAppleImport from "./BadgeApple.tsx";
import BadgeGoogleImport from "./BadgeGoogle.png";
import BellImport from "./Bell.tsx";
import BlackImport from "./Black.jpeg";
import BookmarkImport from "./Bookmark.tsx";
import BookmarkFilledImport from "./BookmarkFilled.tsx";
import BookmarkWhiteImport from "./BookmarkWhite.tsx";
import ChannelsGreenImport from "./ChannelsGreen.tsx";
import ChannelsWhiteImport from "./ChannelsWhite.tsx";
import CheckImport from "./Check.tsx";
import CheckboxSelectImport from "./CheckboxSelect.tsx";
import CheckboxUnselectImport from "./CheckboxUnselect.tsx";
import CloseImport from "./Close.tsx";
import CloseCrossImport from "./CloseCross.tsx";
import CrownImport from "./Crown.tsx";
import DevicesImport from "./Devices.png";
import DropDownSelectedImport from "./DropDownSelected.tsx";
import ErrorImport from "./Error.tsx";
import FacebookImport from "./Facebook.tsx";
import FavoriteImport from "./Favorite.tsx";
import FavoriteFillImport from "./FavoriteFill.tsx";
import FilmImport from "./Film.tsx";
import FooterVectorImport from "./FooterVector.png";
import ForwardImport from "./Forward.tsx";
import FullScreenImport from "./FullScreen.tsx";
import HeartFillImport from "./HeartFill.tsx";
import InformationImport from "./Information.tsx";
import InstagramImport from "./Instagram.tsx";
import LargePlayImport from "./LargePlay.tsx";
import LeftArrowImport from "./LeftArrow.tsx";
import LeftRightImport from "./LeftRight.tsx";
import LockImport from "./Lock.tsx";
import LoginBackdropImport from "./LoginBackdrop.png";
import LogoChannelImport from "./LogoChannel.tsx";
import MessengerImport from "./Messenger.tsx";
import PictoPauseImport from "./PictoPause.tsx";
import PictoPlayImport from "./PictoPlay.tsx";
import PictoSoundImport from "./PictoSound.tsx";
import PlatformsImport from "./Platforms.png";
import PlayImport from "./Play.tsx";
import PlayBlackImport from "./PlayBlack.tsx";
import PlayWhiteImport from "./PlayWhite.tsx";
import PlayerCloseImport from "./PlayerClose.tsx";
import PlayerExitImport from "./PlayerExit.tsx";
import RatingImport from "./Rating.tsx";
import RecordImport from "./Record.tsx";
import RecordingBackDropImport from "./RecordingBackDrop.png";
import RemiderImport from "./Remider.tsx";
import ReminderImport from "./Reminder.tsx";
import RewindImport from "./Rewind.tsx";
import RightArrowImport from "./RightArrow.tsx";
import SearchImport from "./Search.tsx";
import SettingsImport from "./Settings.tsx";
import SettingsGreenImport from "./SettingsGreen.tsx";
import SettingsPinkImport from "./SettingsPink.tsx";
import ShrinkScreenImport from "./ShrinkScreen.tsx";
import SoundImport from "./Sound.tsx";
import StartOverImport from "./StartOver.tsx";
import SubtitlesImport from "./Subtitles.tsx";
import SubtitlesGreenImport from "./SubtitlesGreen.tsx";
import SubtitlesRedImport from "./SubtitlesRed.tsx";
import SubtitlesWhiteImport from "./SubtitlesWhite.tsx";
import SuccessImport from "./Success.tsx";
import SynopsisImport from "./Synopsis.tsx";
import SynopsisGreenImport from "./SynopsisGreen.tsx";
import TickImport from "./Tick.tsx";
import ViewMoreArrowImport from "./ViewMoreArrow.tsx";
import WarningImport from "./Warning.tsx";
import XImport from "./X.tsx";
import YoutubeImport from "./Youtube.tsx";
import arrowleftImport from "./arrow_left.tsx";
import attemptSavedImport from "./attemptSaved.tsx";
import backdropImport from "./backdrop.jpg";
import bellRingsImport from "./bellRings.tsx";
import bellRingsOffImport from "./bellRingsOff.tsx";
import bellSnoozeImport from "./bellSnooze.tsx";
import burgerImport from "./burger.tsx";
import cardRedImport from "./cardRed.tsx";
import cardYellowImport from "./cardYellow.tsx";
import cardsImport from "./cards.tsx";
import chevronDownImport from "./chevronDown.png";
import chevronUpImport from "./chevronUp.png";
import cornerAwardedImport from "./cornerAwarded.tsx";
import facebookImport from "./facebook.png";
import faviconImport from "./favicon.ico";
import goalImport from "./goal.tsx";
import instagramImport from "./instagram.png";
import joinLiveImport from "./joinLive.tsx";
import landscapeImport from "./landscape.jpg";
import logoImport from "./logo.png";
import logoSVGImport from "./logoSVG.tsx";
import messengerImport from "./messenger.png";
import penaltyFacedImport from "./penaltyFaced.tsx";
import periodEndImport from "./periodEnd.tsx";
import periodStartImport from "./periodStart.tsx";
import playerOffImport from "./playerOff.tsx";
import posterImport from "./poster.jpg";
import xImport from "./x.png";
import youtubeImport from "./youtube.png";

const clientAssets = {
    'Add': AddImport,
    'ArrowDown': ArrowDownImport,
    'ArrowDownFilled': ArrowDownFilledImport,
    'ArrowUp': ArrowUpImport,
    'BackgroundImage': BackgroundImageImport,
    'BadgeAndoid': BadgeAndoidImport,
    'BadgeApple': BadgeAppleImport,
    'BadgeGoogle': BadgeGoogleImport,
    'Bell': BellImport,
    'Black': BlackImport,
    'Bookmark': BookmarkImport,
    'BookmarkFilled': BookmarkFilledImport,
    'BookmarkWhite': BookmarkWhiteImport,
    'ChannelsGreen': ChannelsGreenImport,
    'ChannelsWhite': ChannelsWhiteImport,
    'Check': CheckImport,
    'CheckboxSelect': CheckboxSelectImport,
    'CheckboxUnselect': CheckboxUnselectImport,
    'Close': CloseImport,
    'CloseCross': CloseCrossImport,
    'Crown': CrownImport,
    'Devices': DevicesImport,
    'DropDownSelected': DropDownSelectedImport,
    'Error': ErrorImport,
    'Facebook': FacebookImport,
    'Favorite': FavoriteImport,
    'FavoriteFill': FavoriteFillImport,
    'Film': FilmImport,
    'FooterVector': FooterVectorImport,
    'Forward': ForwardImport,
    'FullScreen': FullScreenImport,
    'HeartFill': HeartFillImport,
    'Information': InformationImport,
    'Instagram': InstagramImport,
    'LargePlay': LargePlayImport,
    'LeftArrow': LeftArrowImport,
    'LeftRight': LeftRightImport,
    'Lock': LockImport,
    'LoginBackdrop': LoginBackdropImport,
    'LogoChannel': LogoChannelImport,
    'Messenger': MessengerImport,
    'PictoPause': PictoPauseImport,
    'PictoPlay': PictoPlayImport,
    'PictoSound': PictoSoundImport,
    'Platforms': PlatformsImport,
    'Play': PlayImport,
    'PlayBlack': PlayBlackImport,
    'PlayWhite': PlayWhiteImport,
    'PlayerClose': PlayerCloseImport,
    'PlayerExit': PlayerExitImport,
    'Rating': RatingImport,
    'Record': RecordImport,
    'RecordingBackDrop': RecordingBackDropImport,
    'Remider': RemiderImport,
    'Reminder': ReminderImport,
    'Rewind': RewindImport,
    'RightArrow': RightArrowImport,
    'Search': SearchImport,
    'Settings': SettingsImport,
    'SettingsGreen': SettingsGreenImport,
    'SettingsPink': SettingsPinkImport,
    'ShrinkScreen': ShrinkScreenImport,
    'Sound': SoundImport,
    'StartOver': StartOverImport,
    'Subtitles': SubtitlesImport,
    'SubtitlesGreen': SubtitlesGreenImport,
    'SubtitlesRed': SubtitlesRedImport,
    'SubtitlesWhite': SubtitlesWhiteImport,
    'Success': SuccessImport,
    'Synopsis': SynopsisImport,
    'SynopsisGreen': SynopsisGreenImport,
    'Tick': TickImport,
    'ViewMoreArrow': ViewMoreArrowImport,
    'Warning': WarningImport,
    'X': XImport,
    'Youtube': YoutubeImport,
    'arrow_left': arrowleftImport,
    'attemptSaved': attemptSavedImport,
    'backdrop': backdropImport,
    'bellRings': bellRingsImport,
    'bellRingsOff': bellRingsOffImport,
    'bellSnooze': bellSnoozeImport,
    'burger': burgerImport,
    'cardRed': cardRedImport,
    'cardYellow': cardYellowImport,
    'cards': cardsImport,
    'chevronDown': chevronDownImport,
    'chevronUp': chevronUpImport,
    'cornerAwarded': cornerAwardedImport,
    'facebook': facebookImport,
    'favicon': faviconImport,
    'goal': goalImport,
    'instagram': instagramImport,
    'joinLive': joinLiveImport,
    'landscape': landscapeImport,
    'logo': logoImport,
    'logoSVG': logoSVGImport,
    'messenger': messengerImport,
    'penaltyFaced': penaltyFacedImport,
    'periodEnd': periodEndImport,
    'periodStart': periodStartImport,
    'playerOff': playerOffImport,
    'poster': posterImport,
    'x': xImport,
    'youtube': youtubeImport,
}
export default clientAssets
