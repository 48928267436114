import { Actor } from 'models'

/**
 * Parse Actors
 * @param {Array<Object>} cast
 * @param {Number} proxyVersion By default proxyVersion = 1
 * @returns {Array<Actor>}
 */
export const castingFactory = (cast, proxyVersion = 1) => {
    if (proxyVersion >= 3) {
        return (cast || [])
            .filter((c) => c.name && c.name.length > 0)
            .map((actor) => {
                if (actor) {
                    let fullName = actor.name
                    if (fullName && actor.middle) {
                        fullName = `${fullName} ${actor.middle}`
                    }
                    const title = fullName && actor.last ? `${fullName} ${actor.last}` : fullName
                    return new Actor({
                        title,
                        id: title,
                        thumbnail:
                            Array.isArray(actor.pictures) && actor.pictures.length > 0
                                ? actor.pictures[0]
                                : '',
                    })
                }
                return null
            })
            .filter(Boolean)
    } else {
        const filteredCast = cast && cast.filter((c) => c && c.length > 0)
        return filteredCast.length > 0
            ? filteredCast.map((actor) => {
                  return new Actor({ title: actor, id: actor, thumbnail: '' })
              })
            : []
    }
}

/**
 * Parse Producers
 * @todo Uniformize the Producers using Actor Model. Do not do it to avoid breaking change with the UI
 * @param {Array<Object>} producers
 * @param {Number} proxyVersion
 * @returns {Array<String>}
 */
export const producerFactory = (producers, proxyVersion = 3) => {
    if (proxyVersion >= 3) {
        return (
            (
                producers &&
                producers.map((producer) => {
                    if (producer) {
                        let fullName = producer.name
                        if (fullName && producer.middle) {
                            fullName = fullName + ' ' + producer.middle
                        }
                        return fullName && producer.last ? fullName + ' ' + producer.last : fullName
                    }
                    return null
                })
            ).filter(Boolean) || []
        )
    }

    return []
}

/**
 * Parse directors
 * @todo Uniformize the Producers using Actor Model. Do not do it to avoid breaking change with the UI
 * @param {Array<Object>} directors
 * @param {Number} proxyVersion
 * @returns {Array<String>}
 */
export const directorFactory = (directors, proxyVersion = 3) => {
    if (proxyVersion >= 3) {
        return (
            (
                directors &&
                directors.map((director) => {
                    if (director) {
                        let fullName = director.name
                        if (fullName && director.middle) {
                            fullName = fullName + ' ' + director.middle
                        }
                        return fullName && director.last ? fullName + ' ' + director.last : fullName
                    }
                    return null
                })
            ).filter(Boolean) || []
        )
    }

    return []
}
