import { ILayoutContentNode } from '@typings/node'
import { ContentNode } from './ContentNode'

/**
 * A LayoutContentNode is a contentNode
 */
export class LayoutContentNode extends ContentNode {
    layout: string

    constructor(props: ILayoutContentNode) {
        super(props)

        this.layout = props.layout
    }

    /**
     * Checks if the Node is a LayoutContentNode (always `true`)
     * */
    isLayoutContentNode() {
        return true
    }

    /**
     * Checks if the Node is a ProfilesContentNode
     * */
    isProfilesContentNode() {
        return this.layout === 'profiles'
    }
}
