import { INode, IntlKey } from '@typings/node'
import { Abstract } from './Abstract'

/**
 * Node is an abstract model representing an item in the navigation tree
 * @property {String|Number} id The Node's identifier
 * @property {String|Number} parent The identifier of the Node's parent
 * @property {Object} labels The displayed label/name of the Node indexed by locale (en, fr, de, etc...)
 *                           intlKey is an extra child object which contains a tag to build the final text to display
 * @property {Boolean} [translateLabel=false] Does the label should be translated on project side
 * @property {String|Number} icon Icon which represent the Node, could be a string (used as fonticon class) or a number (Asset Identifier from React Native)
 * @property {Boolean} isShortcut Is this node a shortcut node ? (with potential dedicated UI/behaviour associated)
 * @property {Boolean} temporary Indicates (when true) whether the Node exists only temporarily
 * Instantiate one :
 * - {@link NavigationNode}
 * - {@link ContentNode}
 * - {@link EPGNode}
 * - {@link ActionNode}
 */
export class Node extends Abstract {
    id: string | number
    parent: string | number
    labels: object
    intlKey: IntlKey
    translateLabel: boolean
    icon: string | number
    isShortcut: boolean
    temporary: boolean

    constructor(props: INode) {
        super()

        this.id = props.id
        this.parent = props.parent
        this.labels = props.labels
        this.intlKey = props.intlKey
        // this.type = props.type
        this.translateLabel = !!props.translateLabel
        this.icon = props.icon
        this.isShortcut = props.isShortcut
        this.temporary = props.temporary
    }

    /**
     * Checks if the Node is a NavigationNode (always `false` for the abstract Node class)
     * */
    isNavigationNode() {
        return false
    }

    /**
     * Checks if the Node is a ContentNode (always `false` for the abstract Node class)
     * */
    isContentNode() {
        return false
    }

    /**
     * Checks if the Node is a EPGNode (always `false` for the abstract Node class)
     * */
    isEPGNode() {
        return false
    }

    /**
     * Checks if the Node is a PortalNode (always `false` for the abstract Node class)
     * */
    isPortalNode() {
        return false
    }

    /**
     * Checks if the Node is a SettingsNode (always `false` for the abstract Node class)
     * */
    isSettingsNode() {
        return false
    }

    /**
     * Checks if the Node is a ActionNode (always `false` for the abstract Node class)
     * */
    isActionNode() {
        return false
    }

    /**
     * Checks if the Node is a SearchNode (always `false` for the abstract Node class)
     * */
    isSearchNode() {
        return false
    }

    /**
     * Checks if the Node is a LayoutNode (always `false` for the abstract Node class)
     * */
    isLayoutNode() {
        return false
    }

    /**
     * Checks if the Node is a ReplayNode (always `false` for the abstract Node class)
     * */
    isReplayNode() {
        return false
    }
}
