
// import { mapRequestNameToSelector } from '@ifs/volt-store/lib/modules'

export const selectEmiState = (state: any) => state.emi

export const selectRequestMap = (state: any) => {
    const emi = selectEmiState(state)

    return emi.requests
}

export const selectNavigationState = (state: any) => state.navigation

export const selectCustomId = (state: any) => {
    const data = selectNavigationState(state)

    return data.customId
}

export const selectCustomCollectionId = (state: any) => {
    const data = selectNavigationState(state)

    return data.customCollectionId
}

export const selectRequestDataFromId = (state: any) => {
    const requests = selectRequestMap(state)
    const customId = selectCustomId(state)
    const customCollectionId = selectCustomCollectionId(state)

    const request = requests[customId]
    // console.log('===============mapRequestNameToSelector', mapRequestNameToSelector)
    // const selector = request && mapRequestNameToSelector(request.value)

    return selector ? selector(request, { id: customCollectionId })(state) : undefined
}