/*
This module was put in a `api-constants` directory to avoid conflicting
with Node native `constants` module, not documentend but present.
*/

const Constants = {
    session: {
        endReasons: {
            // 2019-02-13: list was built from Partner 5j session management endCode cases
            unknown: 'unknown',
            success: 'success',
            terminated: 'terminated',
            connectionLost: 'connectionLost',
            abortReceived: 'abortReceived',
            serverError: 'serverError',
            notFound: 'notFound',
            badFormat: 'badFormat',
        },
    },
    platform: {
        /**
         * Mobile/Tablet Devices
         */
        android: 'android',
        ios: 'ios',
        /**
         * Android TV Devices
         */
        androidTvStb: 'androidTvStb',
        androidTv: 'androidTv',
        amazonTv: 'amazonTv',
        /**
         * Apple TV
         */
        tvos: 'tvos',
        /**
         * Exotic TV OS: Tizen OS, Web OS etc..
         */
        smartTvOs: 'smartTvOs',
        /**
         * Browsers
         */
        chrome: 'chrome',
        firefox: 'firefox',
        edge: 'edge',
        edgeChromium: 'edgeChromium',
        safari: 'safari',
        /**
         * Game Console
         */
        playstation: 'playstation',
        xbox: 'xbox',
        /**
         * Casting Server
         */
        chromecast: 'chromecast',
    },
    businessType: {
        // Business to Consumer
        B2C: 'B2C',
        // Business to Business To Consumer
        B2B2C: 'B2B2C',
        // Business to Business
        B2B: 'B2B',
    },
    emiDevices: {
        pc: 1,
        common: 2,
        tv: 3,
        roku: 4,
        // phone: 10,
        androidPhone: 11,
        iosPhone: 12,
        windowsPhone: 13,
        // dongle: 30,
        dongleLinux: 31,
        dongleChromecast: 32,
        amazonFire: 33,
        // tablet: 40,
        androidTablet: 41,
        iosTablet: 42,
        windowsTablet: 43,
        // stb: 60,
        stbLinux: 61,
        stb: 62,
        androidTv: 63,
        appleTv: 64,
        androidAosp: 65,
    },
    device: {
        phone: 'phone',
        tablet: 'tablet',
    },
    programType: {
        /**
         * LIVE
         */
        // This type refers to EPG content. Current layout supported : LANDSCAPE
        live: 'live',
        // This type refers to channel content. Current layout supported : LANDSCAPE
        channel: 'channel',
        /**
         * VOD Section
         */
        // This type refers to vod undistinctly defined. A vod can be : movie, episode, serie, season, orphanSeason, orphanEpisode, episodicShow, vodCatchup
        vod: 'vod',
        // VOD Movie. Current layout supported : POSTER
        movie: 'movie',
        // VOD Serie. Current layout supported : POSTER
        series: 'series',
        // VOD Season. Current layout supported : POSTER
        season: 'season',
        // VOD Episode. Current layout supported : LANDSCAPE
        episode: 'episode',
        // VOD Collection. Current layout supported : POSTER (Close/Similar as collection)
        collection: 'collection',
        // VOD Episodic Season. Current layout supported : POSTER
        episodicShow: 'episodicShow',
        // VOD Orphan season( season not attached to a Serie). Current layout supported : POSTER
        orphanSeason: 'orphanSeason',
        // VOD Orphan episode (episode not attached to either a Serie or Seson). Current layout supported : LANDSCAPE
        orphanEpisode: 'orphanEpisode',
        // VOD recorded from an EPG. Current layout supported : LANDSCAPE
        vodCatchup: 'vodCatchup',
        /**
         * DVR. Current layout supported : LANDSCAPE
         */
        dvr: 'dvr',
        dvrSeries: 'dvrSeries',
        /**
         * REPLAY. Current layout supported : LANDSCAPE
         */
        replay: 'replay',
        /**
         * MISC
         */
        channelAppProgram: 'channelAppProgram',
        subscription: 'subscription',
        unknown: 'unknown',
    },
    playbackType: {
        vod: 'vod',
        trailer: 'trailer',
        liveNow: 'liveNow',
        liveStartOver: 'liveStartOver',
        replay: 'replay',
        dvrPast: 'dvrPast',
        dvrOngoing: 'dvrOngoing',
    },
    authentication: {
        errors: {
            failed: 'failed',
            userUnknown: 'userUnknown',
            invalidCredentials: 'invalidCredentials',
        },
    },
    // As of 3/11/2021 -> Contexts are simply defined by 3 categories
    // To be enhanced in the future with more error categories (Delivery/Software Archi TODO)
    errorsContext: {
        PLAYER_BACKEND: 'PLB',
        PLAYER_REACT: 'PLA', // React Native Video Errors
        NAVIGATION: 'NAV',
    },
    customRequests: {
        GET_RELATED_CONTENT: 'GET_RELATED_CONTENT',
        GET_CHANNEL_PROGRAMS_OF_DATE: 'GET_CHANNEL_PROGRAMS_OF_DATE',
        GET_VOD_NODE_CONTENT: 'GET_VOD_NODE_CONTENT',
        GET_CONTENTS_FROM_IDS: 'GET_CONTENTS_FROM_IDS',
        GET_SUBSCRIPTIONS_FROM_IDS: 'GET_SUBSCRIPTIONS_FROM_IDS',
        CHANNELS: 'CHANNELS', // Used when EMI provide a row with a list of channels
        REPLAY_CHANNELS: 'REPLAY_CHANNELS',
        REPLAY_BY_DATE_REQUEST_CREATOR: 'REPLAY_BY_DATE_REQUEST_CREATOR',
        REPLAY_GET_CHANNEL_GENRES_OF_DATE: 'REPLAY_GET_CHANNEL_GENRES_OF_DATE',
        REPLAY_GET_PROGRAMS_OF_GENRE: 'REPLAY_GET_PROGRAMS_OF_GENRE',
        GET_PURCHASED_TVODS: 'GET_PURCHASED_TVODS',
        GET_PURCHASED_TVODS_RENTAL_ONLY: 'GET_PURCHASED_TVODS_RENTAL_ONLY',
        GET_PURCHASED_TVODS_PURCHASE_ONLY: 'GET_PURCHASED_TVODS_PURCHASE_ONLY',

        GENERATE_AVAILABLE_RECORDINGS_BY_MONTH_REQUESTS: 'GENERATE_RECORDINGS_BY_MONTH_REQUESTS',
        GET_AVAILABLE_RECORDINGS_BY_MONTH: 'GET_AVAILABLE_RECORDINGS_BY_MONTH',
        GET_AVAILABLE_RECORDINGS_BY_NAME: 'GET_AVAILABLE_RECORDINGS_BY_NAME',

        GET_SCHEDULED_STANDALONE_EVENT_RECORDINGS_BY_DATE:
            'GET_SCHEDULED_STANDALONE_EVENT_RECORDINGS_BY_DATE',
        GET_SCHEDULED_SERIES_RECORDINGS_BY_NAME: 'GET_SCHEDULED_SERIES_RECORDINGS_BY_NAME',

        ANDROID_CHANNEL_APPS_GET_PREVIEW_PROGRAM_BY_CHANNEL_ID:
            'ANDROID_CHANNEL_APPS_GET_PREVIEW_PROGRAM_BY_CHANNEL_ID',
    },
    actions: {
        REFRESH_CHANNELS: 'REFRESH_CHANNELS',
        GET_BATCH_OF_EPG_DATA: 'GET_BATCH_OF_EPG_DATA',
        GET_LIVE_NOW: 'GET_LIVE_NOW',
    },
    emiRequests: {
        // Concatenations of EMI "Content Selectors" values and their "provider" name
        // -------------------------------------
        // -- EPGProgram
        // -------------------------------------
        GET_LIVE_NOW_ALL: 'EPGProgram_live_now_channels',
        GET_LIVE_TONIGHT_ALL: 'EPGProgram_live_tonight_channels',
        GET_LIVE_NOW_FAVORITES: 'EPGProgram_live_now_favorites',
        GET_LIVE_TONIGHT_FAVORITES: 'EPGProgram_live_tonight_favorites',
        GET_LIVE_TODAY: 'EPGProgram_live_today',
        GET_LIVE_NOW_BY_GENRE: 'EPGProgram_content_now_channels_genre',
        GET_CATCHUP_BY_PROGRAM: 'EPGProgram_catch_up_program',
        GET_CATCHUP_BY_CHANNEL: 'EPGProgram_catch_up_channel',
        GET_CATCHUP_CONTINUE_WATCHING: 'EPGProgram_catch_up_continue_watching',

        // -------------------------------------
        // -- VODContent
        // -------------------------------------
        CONTINUE_WATCHING_VOD_ALL: 'VODContent_content_continue_watching',
        CONTINUE_WATCHING_VOD_MOVIES: 'VODContent_content_continue_watching_movies',
        CONTINUE_WATCHING_VOD_TVSHOWS: 'VODContent_content_continue_watching_tvshows',
        CONTINUE_WATCHING_VOD_ADULT: 'VODContentAdult_content_adult_continue_watching',
        GET_FAVORITES_ALL: 'VODContent_content_favorites',
        GET_FAVORITES_ADULT: 'VODContentAdult_content_adult_favorites_movies',
        GET_FAVORITES_MOVIES: 'VODContent_content_favorites_movies',
        GET_FAVORITES_SERIES: 'VODContent_content_favorites_tv_shows',
        GET_VODS_OF_CATEGORY: 'VODContent_content_genre',
        GET_VODS_ADULT_OF_CATEGORY: 'VODContentAdult_content_all_catalog',
        GET_VODS_FROM_NODE_PLATFORM_ID: 'VODContent_content_node',
        GET_PURCHASED_TVODS_MOVIES: 'VODContent_content_purchased_movies',
        GET_PURCHASED_TVODS_ADULT_MOVIES: 'VODContentAdult_content_adult_purchased_movies',
        GET_PURCHASED_TVODS_MOVIES_EST: 'VODContent_content_purchased_movies_est',
        GET_PURCHASED_TVODS_MOVIES_TVOD: 'VODContent_content_purchased_movies_tvod',
        GET_PURCHASED_TVODS_SERIES: 'VODContent_content_purchased_tvshows',
        GET_ALL_VODS: 'VODContent_content_all_vods',
        GET_ALL_VODS_MOVIES: 'VODContent_content_all_movies',
        GET_ALL_VODS_SERIES: 'VODContent_content_all_tvshows',
        GET_VODS_EPISODES_OF_SEASON: 'VODSeason_content_season',
        GET_VODS_SEASONS_OF_SERIES: 'VODSerie_content_serie',
        GET_VODS_EPISODES_BY_SERIE: 'VODSerie_content_serie_rows',

        GET_TRENDING_VOD_ALL: 'VODContent_trending_vod_all', // TODO replace with real value
        GET_TRENDING_VOD_MOVIES: 'VODContent_trending_vod_movies', // TODO replace with real value
        GET_TRENDING_VOD_SERIES: 'VODContent_trending_vod_series', // TODO replace with real value
        GET_RECOMMENDED_VOD: 'VODContent_recommended_movies', // request has 'movies' in its name but appears as 'Suggestion - VOD' in EMI editor...

        // -------------------------------------
        // -- Mixed content VOD and CATCHUP
        // -------------------------------------
        GET_CONTINUE_WATCHING_VOD_AND_CATCHUP: 'VODContent_all_continue_watching',

        // -------------------------------------
        // -- PVRContent
        // -------------------------------------
        GET_CONTINUE_WATCHING_RECORDINGS: 'PVRContent_content_continue_watching',
        GET_CONTINUE_WATCHING_EPISODE_RECORDINGS: 'PVRContent_content_continue_watching_episodes',
        GET_AVAILABLE_RECORDINGS_BY_DATE: 'PVRContent_content_most_recent',
        GET_SCHEDULED_RECORDINGS_BY_DATE: 'PVRContent_scheduled_most_recent',
        GET_ALL_RECORDINGS_BY_NAME: 'PVRContent_content_all_recording',
        GET_ALL_SERIES_RECORDINGS_BY_NAME: 'PVRContent_content_recurrent_recording',
        GET_AVAILABLE_SERIES_RECORDINGS_BY_NAME: 'PVRContent_recorded_series_by_alpha',
        GET_AVAILABLE_EPISODE_RECORDINGS_BY_DATE: 'PVRContent_serie_episodes_available',
        GET_SCHEDULED_EPISODE_RECORDINGS_BY_DATE: 'PVRContent_serie_episodes_scheduled',
        GET_FAVORITE_RECORDINGS: 'PVRContent_content_favorites',
        GET_FAVORITE_AVAILABLE_RECORDINGS: 'PVRContent_content_favorites_pvr_recorded',
        GET_FAVORITE_SCHEDULED_RECORDINGS: 'PVRContent_content_favorites_pvr_scheduled',
        GET_FAVORITE_EPISODE_RECORDINGS: 'PVRContent_content_favorites_pvr_episodes',

        // -------------------------------------
        // -- Search
        // -------------------------------------
        GET_SEARCH_ALL_VODS_MOVIES: 'Search_content_all_movies',
        GET_SEARCH_ALL_VODS_SERIES: 'Search_content_all_tvshows',
        GET_SEARCH_ALL_VODS_ORPHAN_EPISODES: 'Search_content_all_orphan_episodes',
        GET_SEARCH_ALL_LIVE_NOW: 'Search_content_now_epg_all',
        GET_SEARCH_ALL_PAST_EPG: 'Search_content_past_epg_all',
        GET_SEARCH_ALL_FUTURE_EPG: 'Search_content_future_epg_all',

        // -------------------------------------
        // -- APPContent
        // -------------------------------------
        GET_ALL_APPS_BY_NAME: 'APPContent_content_app_all',
        GET_ALL_GAMES_BY_NAME: 'APPContent_content_game_all',
        GET_ALL_APPS_BY_MOST_RECENT: 'APPContent_content_installed_apps_most_recent',
        GET_ALL_GAMES_BY_MOST_RECENT: 'APPContent_content_installed_games_most_recent',
        GET_ALL_APPS_AND_GAMES_BY_NAME: 'APPContent_content_installed_apps_and_games_A_to_Z',
        GET_ALL_APPS_AND_GAMES_BY_MOST_RECENT:
            'APPContent_content_installed_apps_and_games_most_recent',
        // -------------------------------------
        // -- Subscription
        // -------------------------------------
        GET_PACKAGES_ALL: 'SubscriptionOffers_all_bouquets',
        GET_PACKAGES_SUBSCRIBED: 'SubscriptionOffers_my_bouquets',
        GET_PACKAGES_UNSUBSCRIBED: 'SubscriptionOffers_bouquets_not_purchased',

        // -------------------------------------
        // -- Youtube
        // -------------------------------------
        GET_YOUTUBE_PLAYLIST: 'YouTube_Playlist',

        // List of categories can be retrieved here :
        // https://www.googleapis.com/youtube/v3/videoCategories?key=AIzaSyBfsLSva1I1c5DIo-k6Wa6MhY-gNlaQO6k&regionCode=US
        GET_YOUTUBE_CATEGORY_FILM_AND_ANIMATION: 'YouTube_FilmAndAnimation',
        GET_YOUTUBE_CATEGORY_AUTO_AND_VEHICULES: 'YouTube_AutoAndVehicules',
        GET_YOUTUBE_CATEGORY_MUSIC: 'YouTube_Music',
        GET_YOUTUBE_CATEGORY_PETS_AND_ANIMALS: 'YouTube_PetsAndAnimals',
        GET_YOUTUBE_CATEGORY_SPORTS: 'YouTube_sports',
        GET_YOUTUBE_CATEGORY_SHORT_MOVIES: 'YouTube_ShortMovies',
        GET_YOUTUBE_CATEGORY_TRAVEL_AND_EVENTS: 'YouTube_TravelAndEvents',
        GET_YOUTUBE_CATEGORY_GAMING: 'YouTube_gaming',
        GET_YOUTUBE_CATEGORY_VIDEO_BLOGGING: 'YouTube_Videoblogging',
        GET_YOUTUBE_CATEGORY_PEOPLE_AND_BLOGS: 'YouTube_PeopleAndBlogs',
        GET_YOUTUBE_CATEGORY_COMEDY: 'YouTube_Comedy',
        GET_YOUTUBE_CATEGORY_ENTERTAINMENT: 'YouTube_Entertainment',
        GET_YOUTUBE_CATEGORY_NEWS_AND_POLITICS: 'YouTube_NewsAndPolitics',
        GET_YOUTUBE_CATEGORY_HOW_TO_AND_STYLE: 'YouTube_HowToAndStyle',
        GET_YOUTUBE_CATEGORY_EDUCATION: 'YouTube_Education',
        GET_YOUTUBE_CATEGORY_SCIENCE_AND_TECHNOLOGY: 'YouTube_ScienceAndTechnology',
        GET_YOUTUBE_CATEGORY_NON_PROFITS_AND_ACTIVISM: 'YouTube_NonprofitsAndActivism',

        // -------------------------------------
        // -- Channel Apps
        // -------------------------------------
        ANDROID_CHANNEL_APPS_GET_CONTENT_WATCH_NEXT:
            'ChannelApplication_get_channel_applications_watch_next_contents',
        ANDROID_CHANNEL_APPS_GET_CONTENT: 'ChannelApplication_get_channel_applications_contents',
        GENERATE_ANDROID_CHANNEL_APPS_REQUESTS:
            'ChannelApplication_get_all_applications_channel_list_ids', // EMI Request used to generate a list of request per channel
        GENERATE_ANDROID_CHANNEL_APPS_REQUESTS_ALIAS:
            'ChannelApplication_get_application_channel_list_ids', // From VOLT design point of view, it is just an alias of the previous one

        // -------------------------------------
        // -- OPTA / Sport
        // -------------------------------------
        GET_UPCOMING_GAMES: 'EPGProgram_upcoming_matches', // Uses for requesting EPL (English Premier League) games
        GET_TEAM_SQUAD: 'OPTA_squad',
        GET_FIXTURE_BY_DATE: 'OPTA_fixture',
        GET_FIXTURE_BY_TEAM: 'OPTA_fixture_by_team',
        GET_ALL_RANKING: 'OPTA_table',
        GET_LEAGUE_RANKING: 'OPTA_table_league',
        GET_PLAYER_RANKING: 'OPTA_table_player',
    },

    // Constants used when fetching module configuration from EMI, take care if you update it
    // see in services/emi/Fetch#_getEmiUrl()
    emiModules: {
        HOME: 'home', //deprecated, universe module should be used,  TODO:UNIVERSE_REFACTOR: remove this line
        VOD: 'vod', //deprecated, universe module should be used,  TODO:UNIVERSE_REFACTOR: remove this line
        UNIVERSE: 'universe',
        THEME: 'theme',
        PARAMETER: 'parameter',
        APPDOCK: 'appdock',
        VIRTUALCHANNEL: 'virtualchannel',
    },

    requestIds: {
        VOD_NODE: 'VOD_NODE',
        DEFAULT_COACH_CONTINUE_WATCHING_MOVIES: 'DEFAULT_COACH_CONTINUE_WATCHING_MOVIES',
        DEFAULT_COACH_CONTINUE_WATCHING_TVSHOWS: 'DEFAULT_COACH_CONTINUE_WATCHING_TVSHOWS',
        DEFAULT_COACH_GET_LIVE_NOW_ALL: 'DEFAULT_COACH_GET_LIVE_NOW_ALL',
        DEFAULT_VOD_CONTINUE_WATCHING_ALL: 'DEFAULT_VOD_GET_CONTINUE_WATCHING_ALL',
        DEFAULT_VOD_GET_FAVORITES: 'DEFAULT_VOD_GET_FAVORITES',
        DEFAULT_VOD_GET_PURCHASED_TVODS: 'DEFAULT_VOD_GET_PURCHASED_TVODS',
        DEFAULT_VOD_GET_PURCHASED_TVODS_RENTAL_ONLY: 'DEFAULT_VOD_GET_PURCHASED_TVODS_RENTAL_ONLY',
        DEFAULT_VOD_GET_PURCHASED_TVODS_PURCHASE_ONLY:
            'DEFAULT_VOD_GET_PURCHASED_TVODS_PURCHASE_ONLY',

        GENERATE_AVAILABLE_RECORDINGS_BY_MONTH_REQUESTS:
            'GENERATE_AVAILABLE_RECORDINGS_BY_MONTH_REQUESTS',
        AVAILABLE_RECORDINGS_BY_NAME: 'AVAILABLE_RECORDINGS_BY_NAME',
        AVAILABLE_SERIES_RECORDINGS_BY_NAME: 'AVAILABLE_SERIES_RECORDINGS_BY_NAME',
        CONTINUE_WATCHING_RECORDINGS: 'CONTINUE_WATCHING_RECORDINGS',
        FAVORITE_RECORDINGS: 'FAVORITE_RECORDINGS',
        SCHEDULED_STANDALONE_EVENT_RECORDINGS_BY_DATE:
            'SCHEDULED_STANDALONE_EVENT_RECORDINGS_BY_DATE',
        SCHEDULED_SERIES_RECORDINGS_BY_NAME: 'SCHEDULED_SERIES_RECORDINGS_BY_NAME',
    },

    nodeIds: {
        RECORDINGS_AVAILABLE_EVENTS_BY_DATE: 'RECORDINGS_AVAILABLE_EVENTS_BY_DATE',
        RECORDINGS_AVAILABLE_BY_NAME: 'RECORDINGS_AVAILABLE_BY_NAME',
        RECORDINGS_AVAILABLE_SERIES_BY_NAME: 'RECORDINGS_AVAILABLE_SERIES_BY_NAME',
        RECORDINGS_CONTINUE_WATCHING: 'RECORDINGS_CONTINUE_WATCHING',
        RECORDINGS_FAVORITES: 'RECORDINGS_FAVORITES',
        RECORDINGS_SCHEDULED: 'RECORDINGS_SCHEDULED',
        VOD_CONTINUE_WATCHING: 'VOD_CONTINUE_WATCHING',
        VOD_FAVORITES: 'VOD_FAVORITES',
        VOD_PURCHASED: 'VOD_PURCHASED',
        VOD_PURCHASED_RENTAL_ONLY: 'VOD_PURCHASED_RENTAL_ONLY',
        VOD_PURCHASED_PURCHASE_ONLY: 'VOD_PURCHASED_PURCHASE_ONLY',
    },

    eligibility: {
        OTT: 'OTT',
        IPTV: 'IPTV',
        IPTV_HD: 'IPTV_HD',
        IPTV_SD: 'IPTV_SD',
    },

    purchaseType: {
        TVOD: 'TVOD',
        SVOD: 'SVOD',
        FVOD: 'FVOD',
        AVOD: 'AVOD',
        PVOD: 'PVOD',
        NVOD: 'NVOD',
        CUPTV: 'CUPTV',
    },

    productTypes: {
        SUBSCRIPTION: 'subscription',
        TRANSACTIONAL: 'transactional',
        ALL: 'ALL',
    },

    recordingStatus: {
        scheduled: 'scheduled',
        completed: 'completed',
        ongoing: 'ongoing',
        failed: 'failed',
    },

    authenticationType: {
        LOGIN: 'LOGIN',
        SIGN_UP: 'SIGN_UP',
        FORGOT_PASSWORD: 'FORGOT_PASSWORD',
    },

    loginType: {
        USERNAME_AND_PASSWORD: 'USERNAME_AND_PASSWORD',
        PHONE_NUMBER: 'PHONE_NUMBER',
        ALTERNATIVE_USERNAME_AND_PASSWORD: 'ALTERNATIVE_USERNAME_AND_PASSWORD',
        OTP_CODE: 'OTP_CODE',
    },

    videoFormat: {
        V_UNKNOWN: 'UNKNOWN',
        V_ADAPTATIVE: 'ADAPTATIVE',
        V_SD: 'SD',
        V_HD: 'HD',
        V_UHD: 'UHD',
        V_4K: '4K',
    },

    audioFormat: {
        A_UNKNOWN: 'UNKNOWN',
        A_STEREO: 'STEREO',
        A_DOLBY: 'DOLBY',
        A_HD_5_1: 'HD_5_1',
        A_HD_7_1: 'HD_7_1',
        A_DTS: 'DTS',
        A_MONO: 'MONO',
    },

    authenticationFactor: {
        // The user needs only one action either to login or Sign Up
        SIMPLE_FACTOR: 'SIMPLE_FACTOR',
        // The user needs two actions to login or Sign Up. The second action could be OTP validation, an Authenticator
        DOUBLE_FACTOR: 'DOUBLE_FACTOR',
    },

    /**
     * This status is used for the state machine of following authentication:
     * Sign Up
     * Login (Sign In)
     * Custom Login
     * Implicit Login with Secure Id
     * Login With Tv Service
     * Forgot Password
     */
    authenticationStatus: {
        // Means the user is starting the main SignUp/Login step (probably filling a form)
        NOT_STARTED: 'NOT_STARTED',
        // Means the user is starting the main SignUp/Login step (probably filling a form)
        STARTING: 'STARTING',
        // Means the user has succeeded to SignUp/Login and IMPORTANT he is automatically LOGGED IN
        SUCCEEDED: 'SUCCEEDED', // + AUTO LOGIN when it deals with the sign up
        // For SignUp/Forgot Password: Means the user has succeeded to make the operation but need to LOGIN AFTER (NO AUTO LOGIN)
        SUCCEEDED_REQUIRE_LOGIN: 'SUCCEEDED_REQUIRE_LOGIN',
        // Means the user has succeeded to Login but need to Select TV Service
        SUCCEEDED_REQUIRE_LOGIN_TV_SERVICE: 'SUCCEEDED_REQUIRE_LOGIN_TV_SERVICE',
        // 2FA: DOUBLE FACTOR AUTHENTICATION : After the first SignUp/Login/Forgot Password, the user need to validate a One Time Password
        AUTH_2FA_VALIDATE_OTP: 'AUTH_2FA_VALIDATE_OTP',
        // 2FA: DOUBLE FACTOR AUTHENTICATION : After the first SignUp/Login, the user need to validate authentication through authenticator
        AUTH_2FA_VALIDATE_AUTHENTICATOR: 'AUTH_2FA_VALIDATE_AUTHENTICATOR',
        // SignUp/Login failed
        FAILED: 'FAILED',
    },

    tokenStatus: {
        VALID_TOKEN: 'valid_token',
        EXPIRED_TOKEN: 'expired_token',
        INVALID_TOKEN: 'invalid_token',
        UNKNOWN_TOKEN: 'unknown_token',
    },

    // Fallback timeout to use if timeout config is not defined
    fallbackTimeout: {
        auth: 60000,
        configuration: 60000,
        emi: 60000,
        platform: 30000,
        proxy: 30000,
        youtube: 30000,
        default: 10000,
    },
    screenDimensions: {
        phone: {
            screenWidth: 1024,
            screenHeight: 768,
        },
        stb: {
            screenWidth: 1920,
            screenHeight: 1080,
        },
    },
    // A client can use one or two services and some call has to be adjusted (ex: Nickelodeon use Vubiquity and MarketOne)
    mode: {
        COMPLETE: 'COMPLETE', // For example, used by the client MTN which use only Vubiquity
        HYBRID: 'HYBRID', // Used by the client Nickelodeon which use Vubiquity for some calls and marketOne for the others
    },
    // Analytics constants
    analytics: {
        NATIVE_EVENT: {
            SCREEN: 'screen',
            LOGIN: 'login',
            SIGN_UP: 'sign_up',
            FORGOT_PASSWORD: 'forgot_password',
            APP_OPEN: 'app_open',
            STARTER_KIT: 'starter_kit',
            SELECT_CONTENT: 'select_content',
            SELECT_ITEM_FROM_LIST: 'select_item_from_list',
            SELECT_PROMOTION: 'select_promotion',
            PURCHASE: 'purchase',
            SEARCH: 'search',
        },
        CUSTOM_EVENT: {
            BOOTSTRAP_DATA: 'bootstrap_data',
            EMI: 'emi',
            LOGOUT: 'logout',
            UNSUBSCRIBE: 'unsubscribe',
            PLAYBACK_START: 'playback_start',
            PLAYBACK_PAUSE: 'playback_pause',
            PLAYBACK_RESUME: 'playback_resume',
            PLAYBACK_STOP: 'playback_stop',
            PLAYBACK_ERROR: 'playback_error',
            ACTIVATE_SUBSCRIPTION: 'activate_subscription',
            IAP_VALIDATE_RECEIPT: 'iap_validate_receipt',
            FAVORITES_ADD_CONTENT: 'favorites_add_content',
            FAVORITES_REMOVE_CONTENT: 'favorites_remove_content',
            ADD_REMINDER: 'add_reminder',
            REMOVE_REMINDER: 'remove_reminder',
            LAUNCHER_APP_INSTALLED: 'launcher_app_installed',
            LAUNCHER_APP_REMOVED: 'launcher_app_removed',
            RECORD_DVR_PROGRAM: 'record_dvr_program',
            RECORD_DVR_SERIE: 'record_dvr_serie',
            CANCEL_DVR: 'cancel_dvr',
            DELETE_DVR: 'delete_dvr',
            MODIFY_DVR: 'modify_dvr',
            DELETE_DEVICE: 'delete_device',
            MODIFY_DEVICE: 'modify_device',
            ADD_PROFILE: 'add_profile',
            DELETE_PROFILE: 'delete_profile',
            SWITCH_PROFILE: 'switch_profile',
            SETTING_UPDATE_PARENTAL_CONTROL: 'setting_update_parental_control',
            SETTING_UPDATE_PIN: 'setting_update_pin',
            SETTING_RESET_PIN: 'setting_reset_pin',
            SETTING_UPDATE_PASSWORD: 'setting_update_password',
            APP_ERROR: 'app_error',
            VALIDATE_PURCHASE_OTP: 'validate_purchase_otp',
            SEND_PURCHASE_OTP: 'send_purchase_otp',
        },
        USER_CONSENT: {
            YES: 'YES',
            NO: 'NO',
            PENDING: 'PENDING',
        },
        USER_PROPERTY: {
            UI_VERSION: 'ui_version',
            UI_LANGUAGE: 'ui_language',
            EMI_POPULATION: 'emi_population',
            DEVICE_TYPE: 'device_type',
            DEVICE_UUID: 'device_uuid',
            GUEST_MODE: 'guest_mode',
            PUBLIC_IP: 'public_ip',
            PLAYER_VERSION: 'player_version',
            USER_ID: 'user_id',
            USER_ID_2: 'user_id_2',
            USER_EMAIL: 'user_email',
        },
    },

    dvrSeriesTypes: {
        OriginalBroadcast: 'OriginalBroadcast',
        SERIES: 'SERIES',
    },

    // To Move in HTTP helper is needed in the future
    httpHeader: {
        ContentType: {
            APPLICATION_JSON: 'application/json',
            APPLICATION_WWW_FORM_URLENCODED: 'application/x-www-form-urlencoded',
        },
    },

    scheduleType: {
        original: 'original',
        rerun: 'rerun',
    },

    optaCompetitionCode: {
        ENGLISH_PREMIER_LEAGUE: 'EPL',
        GERMAN_BUNDESLIGA: 'BUN',
        UEFA_CHAMPIONS_LEAGUE: 'UCL',
        FIFA_WORLD_CUP: 'WOC',
    },

    /**
     * MixedContentRequestsIds is an array with the ids of all predefined requests with mixed content
     * TODO: update this array each time there is a new predefined request with mixed content
     */
    MixedContentRequestsIds: ['VODContent_all_continue_watching'],

    revisionStatus: {
        CHANNELS_REVISION_OK: 'ok',
        CHANNELS_REVISION_EMPTY: 'error-empty-data',
        CHANNELS_REVISION_INVALID: 'error-invalid-data',
    },
}

/**
 * @typedef {Oject} Constants
 * @property {ConstantsSession} session
 * @property {ConstantsPlatform} platform
 * @property {ConstantsProgramType} programType
 */

/**
 * @typedef {Oject} ConstantsSession
 * @property {ConstantsEndReasons} endReasons
 */

/**
 * @typedef {Oject} ConstantsEndReasons
 * @property {String} unknown The session was ended for an unknown reason
 * @property {String} success The session was successfully delivered to completion
 * @property {String} terminated The session was terminated by the customer
 * @property {String} connectionLost After beginning a stream, the connection with the client timed out or was lost
 * @property {String} abortReceived The session was terminated because it received an Abort instruction from the session manager
 * @property {String} serverError An error on the server caused the stream to fail
 * @property {String} notFound The server was unable to locate a resource it needed to fulfill the stream request
 * @property {String} badFormat The server encountered a media format that it does not support
 */

/**
 * @typedef {Oject} ConstantsPlatform
 * @property {String} android
 * @property {String} ios
 * @property {String} chrome
 */

/**
 * @typedef {Oject} ConstantsProgramType
 * @property {String} live
 * @property {String} vod
 * @property {String} dvr Unused right now (DVR model does not exists yet)
 */

export default Constants
