export default (props = {}) => <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"{...props}>
<g id="Player / SideLeft">
<g id="Vector">
<path d="M6.49992 6.50033C5.90161 6.50033 5.41658 6.98535 5.41658 7.58366V8.66699C5.41658 9.2653 5.90161 9.75033 6.49992 9.75033H7.58325C8.18156 9.75033 8.66658 9.2653 8.66658 8.66699V7.58366C8.66658 6.98535 8.18156 6.50033 7.58325 6.50033H6.49992Z" fill="#03F182"/>
<path d="M5.41658 12.4587C5.41658 11.8604 5.90161 11.3753 6.49992 11.3753H7.58325C8.18156 11.3753 8.66658 11.8604 8.66658 12.4587V13.542C8.66658 14.1403 8.18156 14.6253 7.58325 14.6253H6.49992C5.90161 14.6253 5.41658 14.1403 5.41658 13.542V12.4587Z" fill="#03F182"/>
<path d="M6.49992 16.2503C5.90161 16.2503 5.41658 16.7354 5.41658 17.3337V18.417C5.41658 19.0153 5.90161 19.5003 6.49992 19.5003H7.58325C8.18156 19.5003 8.66658 19.0153 8.66658 18.417V17.3337C8.66658 16.7354 8.18156 16.2503 7.58325 16.2503H6.49992Z" fill="#03F182"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M20.5833 2.16699H5.41658C3.02335 2.16699 1.08325 4.10709 1.08325 6.50033V19.5003C1.08325 21.8936 3.02335 23.8337 5.41658 23.8337H20.5833C22.9765 23.8337 24.9166 21.8936 24.9166 19.5003V6.50033C24.9166 4.10709 22.9765 2.16699 20.5833 2.16699ZM20.5833 4.33366H12.4202C12.7889 4.97103 12.9999 5.71104 12.9999 6.50033V19.5003C12.9999 20.2896 12.7889 21.0296 12.4202 21.667H20.5833C21.7799 21.667 22.7499 20.6969 22.7499 19.5003V6.50033C22.7499 5.30371 21.7799 4.33366 20.5833 4.33366ZM5.41658 4.33366H8.66658C9.8632 4.33366 10.8333 5.30371 10.8333 6.50033V19.5003C10.8333 20.6969 9.8632 21.667 8.66658 21.667H5.41658C4.21997 21.667 3.24992 20.6969 3.24992 19.5003V6.50033C3.24992 5.30371 4.21997 4.33366 5.41658 4.33366Z" fill="#03F182"/>
</g>
</g>
</svg>
