import Constants from 'api-constants'
import HuaweiTypes from '../HuaweiTypes'

const OOREDOO_DEVICE_MODEL = {
    STB: 'OAndroidSTB',
    VSTB: 'TPAndroidSTB',
    VSTB_SOLO: 'TPAndroidSTB_SOLO',
    TVOS: 'TVOSApple',
    TVOS_SOLO: 'TVOSApple_SOLO',
    AndroidPhone: 'Android_Phone',
    AndroidPhone_SOLO: 'Android_Phone_SOLO',
    AndroidTablet: 'Android_TAB',
    AndroidTablet_SOLO: 'Android_TAB_SOLO',
    IosPhone: 'IOS_iPhone',
    IosPhone_SOLO: 'IOS_iPhone_SOLO',
    IosTablet: 'IOS_PAD',
    IosTablet_SOLO: 'IOS_PAD_SOLO',
    Console: 'GameConsole',
    Console_SOLO: 'GameConsole_SOLO',
    WebBrowser: 'WebBrowser',
    WebBrowser_SOLO: 'WebBrowser_SOLO',
    WebBrowser_Safari_SOLO: 'WebBrowser_Safari_SOLO',
    WebBrowser_Edge_SOLO: 'WebBrowser_Edge_SOLO',
    SmartTV: 'SmartTVWeb',
    SmartTV_SOLO: 'SmartTVWeb_SOLO',
}
const projectDeviceConfig = OOREDOO_DEVICE_MODEL // To be loaded dynamically if there is multiple projects involving Huawei

export const DEVICE_DEFAULT_CONFIG = {
    [Constants.platform.androidTvStb]: {
        requireMacAddressLogin: true,
        deviceModel: projectDeviceConfig.STB,
        CADeviceType: HuaweiTypes.CADeviceType.WIDEWINE_OTT_CLIENT,
        CADeviceTypeApiV2: 8,
        defaultDisplayedName: 'Set Top Box',
        deviceType: HuaweiTypes.deviceType.IPTV_STB,
        deviceDeletionAllowed: false,
    },
    [Constants.platform.androidTv]: {
        requireMacAddressLogin: false,
        deviceModel: projectDeviceConfig.VSTB_SOLO,
        CADeviceType: HuaweiTypes.CADeviceType.WIDEWINE_OTT_CLIENT,
        CADeviceTypeApiV2: 8,
        defaultDisplayedName: 'Android TV',
        deviceType: HuaweiTypes.deviceType.SOLO_DEVICE,
        deviceDeletionAllowed: true,
    },
    [Constants.platform.amazonTv]: {
        requireMacAddressLogin: false,
        deviceModel: projectDeviceConfig.VSTB_SOLO,
        CADeviceType: HuaweiTypes.CADeviceType.WIDEWINE_OTT_CLIENT,
        CADeviceTypeApiV2: 8,
        defaultDisplayedName: 'Amazon Fire',
        deviceType: HuaweiTypes.deviceType.SOLO_DEVICE,
        deviceDeletionAllowed: true,
    },
    [Constants.platform.tvos]: {
        requireMacAddressLogin: false,
        deviceModel: projectDeviceConfig.TVOS_SOLO,
        CADeviceType: HuaweiTypes.CADeviceType.FAIRPLAY_OTT_CLIENT,
        CADeviceTypeApiV2: 9,
        defaultDisplayedName: 'Apple TV',
        deviceType: HuaweiTypes.deviceType.SOLO_DEVICE,
        deviceDeletionAllowed: true,
    },
    [Constants.platform.android]: {
        requireMacAddressLogin: false,
        deviceModel: projectDeviceConfig.AndroidPhone_SOLO,
        CADeviceType: HuaweiTypes.CADeviceType.WIDEWINE_OTT_CLIENT,
        CADeviceTypeApiV2: 8,
        defaultDisplayedName: 'Android Mobile/Tablet',
        deviceType: HuaweiTypes.deviceType.SOLO_DEVICE,
        deviceDeletionAllowed: true,
    },
    [Constants.platform.ios]: {
        requireMacAddressLogin: false,
        deviceModel: projectDeviceConfig.IosPhone_SOLO,
        CADeviceType: HuaweiTypes.CADeviceType.FAIRPLAY_OTT_CLIENT,
        CADeviceTypeApiV2: 9,
        defaultDisplayedName: 'IOS Mobile/Tablet',
        deviceType: HuaweiTypes.deviceType.SOLO_DEVICE,
        deviceDeletionAllowed: true,
    },
    [Constants.platform.chrome]: {
        requireMacAddressLogin: false,
        deviceModel: projectDeviceConfig.WebBrowser_SOLO,
        CADeviceType: HuaweiTypes.CADeviceType.WIDEWINE_OTT_CLIENT,
        CADeviceTypeApiV2: 8,
        defaultDisplayedName: 'Browser Application',
        deviceType: HuaweiTypes.deviceType.SOLO_DEVICE,
        deviceDeletionAllowed: true,
    },
    [Constants.platform.firefox]: {
        requireMacAddressLogin: false,
        deviceModel: projectDeviceConfig.WebBrowser_SOLO,
        CADeviceType: HuaweiTypes.CADeviceType.WIDEWINE_OTT_CLIENT,
        CADeviceTypeApiV2: 8,
        defaultDisplayedName: 'Browser Application',
        deviceType: HuaweiTypes.deviceType.SOLO_DEVICE,
        deviceDeletionAllowed: true,
    },
    [Constants.platform.edge]: {
        requireMacAddressLogin: false,
        deviceModel: projectDeviceConfig.WebBrowser_Edge_SOLO,
        CADeviceType: HuaweiTypes.CADeviceType.WIDEWINE_OTT_CLIENT,
        CADeviceTypeApiV2: 6,
        defaultDisplayedName: 'Browser Application',
        deviceType: HuaweiTypes.deviceType.SOLO_DEVICE,
        deviceDeletionAllowed: true,
    },
    [Constants.platform.edgeChromium]: {
        requireMacAddressLogin: false,
        deviceModel: projectDeviceConfig.WebBrowser_Edge_SOLO,
        CADeviceType: HuaweiTypes.CADeviceType.WIDEWINE_OTT_CLIENT,
        CADeviceTypeApiV2: 6,
        defaultDisplayedName: 'Browser Application',
        deviceType: HuaweiTypes.deviceType.SOLO_DEVICE,
        deviceDeletionAllowed: true,
    },
    [Constants.platform.safari]: {
        requireMacAddressLogin: false,
        deviceModel: projectDeviceConfig.WebBrowser_Safari_SOLO,
        CADeviceType: HuaweiTypes.CADeviceType.FAIRPLAY_OTT_CLIENT,
        CADeviceTypeApiV2: 9,
        defaultDisplayedName: 'Browser Application',
        deviceType: HuaweiTypes.deviceType.SOLO_DEVICE,
        deviceDeletionAllowed: true,
    },
    [Constants.platform.smartTvOs]: {
        requireMacAddressLogin: false,
        deviceModel: projectDeviceConfig.SmartTV_SOLO,
        CADeviceType: HuaweiTypes.CADeviceType.WIDEWINE_OTT_CLIENT,
        CADeviceTypeApiV2: 6,
        defaultDisplayedName: 'Smart TV',
        deviceType: HuaweiTypes.deviceType.SOLO_DEVICE,
        deviceDeletionAllowed: true,
    },
    [Constants.platform.playstation]: {
        requireMacAddressLogin: false,
        deviceModel: projectDeviceConfig.Console_SOLO,
        CADeviceType: HuaweiTypes.CADeviceType.PLAYREADY_OTT_CLIENT,
        CADeviceTypeApiV2: 6,
        defaultDisplayedName: 'Playstation',
        deviceType: HuaweiTypes.deviceType.SOLO_DEVICE,
        deviceDeletionAllowed: true,
    },
    [Constants.platform.xbox]: {
        requireMacAddressLogin: false,
        deviceModel: projectDeviceConfig.Console_SOLO,
        CADeviceType: HuaweiTypes.CADeviceType.PLAYREADY_OTT_CLIENT,
        CADeviceTypeApiV2: 6,
        defaultDisplayedName: 'XBOX',
        deviceType: HuaweiTypes.deviceType.SOLO_DEVICE,
        deviceDeletionAllowed: true,
    },
    [Constants.platform.chromecast]: {
        requireMacAddressLogin: false,
        deviceModel: projectDeviceConfig.AndroidPhone_SOLO,
        CADeviceType: HuaweiTypes.CADeviceType.WIDEWINE_OTT_CLIENT,
        CADeviceTypeApiV2: 8,
        defaultDisplayedName: 'Chromecast',
        deviceType: HuaweiTypes.deviceType.SOLO_DEVICE,
        deviceDeletionAllowed: true,
    },
}

/**
 * Mapping Local/Language API V2
 */
export const LOCALE_MAPPING_API_V2 = {
    en: '1',
    ar: '2',
}
