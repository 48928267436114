import { of, EMPTY, Observable } from 'rxjs'
import { mergeMap, expand, reduce } from 'rxjs/operators'
import GenericFetch from 'framework/genericfetch'
import Constants from 'api-constants'
import MockLogger from 'MockLogger'
import ConfigHelper from 'framework/helpers/config'
import { serialize } from 'helpers/index.js'
import VoltError from 'VoltError'
import { resolveLegacyId } from './helpers'
import { removeKeys } from 'helpers/utils'
import {
    Config,
    ErrorResponse,
    FetchArgs,
    FetchResponse,
    HashMap,
    IVoltError,
} from '@typings/generic'

/**
 * @description Mapping Error Table from the Backend
 */
const ERROR_MAPPING = {
    // 10xxx
    10000: () => VoltError.codes.OPTA_UNEXPECTED,
    10001: () => VoltError.codes.OPTA_NOT_FOUND,
    10010: () => VoltError.codes.OPTA_INVALID_OUTLET,
    // 101xx
    10100: () => VoltError.codes.OPTA_FEED_UNAVAILABLE,
    // 102xx
    10200: () => VoltError.codes.OPTA_FEED_MISSING_PARAM,
    10201: () => VoltError.codes.OPTA_FEED_UNKNOWN_PARAM,
    10202: () => VoltError.codes.OPTA_FEED_INVALID_PARAM,
    10203: () => VoltError.codes.OPTA_FEED_AMBIGUOUS_PARAM,
    10204: () => VoltError.codes.OPTA_FEED_INVALID_METHOD,
    10210: () => VoltError.codes.OPTA_FEED_UNSUPPORTED_FORMAT,
    10212: () => VoltError.codes.OPTA_FEED_UNSUPPORTED_MODE,
    10213: () => VoltError.codes.OPTA_FEED_INVALID_DELTA,
    10217: () => VoltError.codes.OPTA_FEED_INVALID_PAGINATION,
    10219: () => VoltError.codes.OPTA_FEED_INVALID_REQUEST,
    // 103xx
    10300: () => VoltError.codes.OPTA_ACCESS_DENIED,
    10312: () => VoltError.codes.OPTA_AUTH_EXPIRED,
    10313: () => VoltError.codes.OPTA_UNAUTHORIZED,
    10320: () => VoltError.codes.OPTA_AUTH_LIMIT,
    // 104xx
    10400: () => VoltError.codes.OPTA_FEED_NO_DATA,
    10401: () => VoltError.codes.OPTA_FEED_TIMEOUT,
}

/**
 * Fetching API class.
 */
export default class Fetch extends GenericFetch {
    constructor(config: Config) {
        super(config, 'opta', ERROR_MAPPING)
        this.config = config
        this.logger = (config.logger || MockLogger).createChildInstance('opta')
    }

    public static defaultHttpSettings = {
        HEADERS: {
            'Content-Type': Constants.httpHeader.ContentType.APPLICATION_JSON,
        },
        PARAMS: {
            _rt: 'c', // b – B2B || c – B2C
            _fmt: 'json',
        },
    }

    /**
     * Error extraction. Inherited from fetch
     * @param {Object} response
     * @returns {Object} '{ error: 'CODE', description: 'DESCRIPTION' }'
     */
    extractError(response: ErrorResponse): IVoltError {
        if (response && response.errorCode) {
            return {
                error: response.errorCode,
                description: response.token,
            }
        }
        return { error: undefined, description: undefined }
    }

    buildUrl = (endpoint: string, params = {}, id?: string, type?: string) => {
        const commonParams = Fetch.defaultHttpSettings.PARAMS
        const clearParams = removeKeys(params, {
            clearEmpty: true,
            clearNull: true,
            clearUndefined: true,
        })
        if (id) {
            const {
                id: resolvedId,
                legacy: isLegacyId,
                field = 'fx',
            } = resolveLegacyId(id, type, ConfigHelper.getInstance().getConfig('opta').useLegacyIds)

            const modifiedEndpoint = isLegacyId ? endpoint : endpoint + resolvedId

            return (
                ConfigHelper.getInstance().getConfig('urls').optaUrl +
                modifiedEndpoint +
                serialize({
                    ...commonParams,
                    ...clearParams,
                    ...(isLegacyId && { [field]: resolvedId }),
                })
            )
        }

        return (
            ConfigHelper.getInstance().getConfig('urls').optaUrl +
            endpoint +
            serialize({ ...commonParams, ...clearParams })
        )
    }

    fetch<T>({
        endpoint,
        params = {},
        body,
        id,
        headers = Fetch.defaultHttpSettings.HEADERS,
        method = 'POST',
        type,
        ...rest
    }: FetchArgs & { endpoint: string; params?: object; id?: string; type?: string }): Observable<
        FetchResponse<T>
    > {
        const optaUrl = this.buildUrl(endpoint, params, id, type)
        this.logger.debug(`HttpRequest ${optaUrl}`, { method, headers, body })

        return super
            .fetch<T>({
                body,
                headers: {
                    ...headers,
                    referer: ConfigHelper.getInstance().getConfig('opta').optaReferer,
                },
                method,
                ...rest,
                url: optaUrl,
            })
            .pipe(mergeMap((result) => of(result)))
    }

    recursiveFetch<T>(
        request: (data: T | null, page: number, limit: number) => Observable<FetchResponse<T>>,
        options: { page: number; limit: number; responseResource: string }
    ) {
        const { page: initialPage = 1, limit = 20, responseResource } = options
        let page = initialPage
        return request(null, page, limit).pipe(
            expand(({ response, headers }) => {
                const { 'performfeeds-hits': totalRecords } = headers!
                page += 1
                const pagesCount = Math.ceil(Number(totalRecords) / limit)
                return page <= pagesCount
                    ? request(response as T, page, limit)
                    : of(response).pipe(mergeMap(() => EMPTY))
            }),
            reduce((acc: Array<T>, { response }) => {
                const result: any = responseResource
                    ? (response as HashMap<string, T>)[responseResource]
                    : response

                result && acc.push(...result)
                return acc
            }, [])
        )
    }
}
