export const getErrorMessage = (getI18n:any,errorCode: any,data:any): any => {
    switch (errorCode) {
        case 'successAddFav':
            return '« '+[data.message?.params?.title]+' » ' + getI18n()?.formatMessage({id: 'FAVORITIES_ADDED'})
        case 'successRemoveFav':
            return '« '+[data.message?.params?.title]+' » ' + getI18n()?.formatMessage({id: 'FAVORITIES_REMOVED'})
        case 'addFavoriteChannelSuccess':
            return '« '+[data.message?.params?.name]+' » ' + getI18n()?.formatMessage({id: 'FAVORITIES_ADDED'})
        case 'removeFavoriteChannelSuccess':
            return '« '+[data.message?.params?.name]+' » ' + getI18n()?.formatMessage({id: 'FAVORITIES_REMOVED'})
        case 'deleteBackendDeviceSuccess':
            return getI18n()?.formatMessage({id: 'IS_REMOVED_SUCCESSFULLY'}, {title: data.message?.params?.title})
        case 'deleteBackendDeviceFailed':
            return getI18n()?.formatMessage({id: 'CANNOT_BE_REMOVED'}, {title: data.message?.params?.title})
        case 'deleteBackendDeviceError':
            return getI18n()?.formatMessage({id: 'CANNOT_BE_REMOVED'}, {title: data.message?.params?.title})
        case 'pinCodeUpdatedSuccess':
            return getI18n()?.formatMessage({id: 'PARENTAL_PIN_UPDATE_SUCCESS_MSG'})
        case 'addFavoriteSuccess':
            return '« '+[data.message?.params?.title]+' » ' + getI18n()?.formatMessage({id: 'FAVORITIES_ADDED'})
        case 'deleteFavoriteSuccess':
            return '« '+[data.message?.params?.title]+' » ' + getI18n()?.formatMessage({id: 'FAVORITIES_REMOVED'})
        case 'cancelRecordingSuccess':
            return  getI18n()?.formatMessage({id: 'CANCEL_RECORDING'}, {title: data.message?.params?.title})
        case 'cancelRecordingError':
            return  getI18n()?.formatMessage({id: 'CANCEL_RECORDING_ERROR'}, {title: data.message?.params?.title})
        case 'deleteRecordingSuccess':
            return  getI18n()?.formatMessage({id: 'DELETE_RECORDING_SUCCESS'}, {title: data.message?.params?.title})
        case 'deleteRecordingError':
            return  getI18n()?.formatMessage({id: 'DELETE_RECORDING_ERROR'}, {title: data.message?.params?.title})
        case 'addRecordingSuccess':
            return  getI18n()?.formatMessage({id: 'RECORDING_STARTED'}, {title: data.message?.params?.title})
        case 'addRecordingTvSuccess':
            return  getI18n()?.formatMessage({id: 'TV_RECORDING_STARTED'}, {title: data.message?.params?.title})
        case 'addRecordingError':
            return  getI18n()?.formatMessage({id: 'RECORDING_CAN_NOT_STARTED'})
        case 'setReminderSuccess':
            return getI18n()?.formatMessage({ id: 'notification.setReminder.success' }, {title: data.message?.params?.title})
        case 'setReminderError':
            return getI18n()?.formatMessage({ id: 'notification.setReminder.error' }, {title: data.message?.params?.title})
        case 'unsetReminderSuccess':
            return getI18n()?.formatMessage({ id: 'notification.unsetReminder.success' }, {title: data.message?.params?.title})
        case 'unsetReminderError':
            return getI18n()?.formatMessage({ id: 'notification.unsetReminder.error' }, {title: data.message?.params?.title})
        case 'EXCEEDED_QUOTA, SET_EVENT_RECORDING_FAILED, NAV-810-NAG, ':
            return getI18n()?.formatMessage({id: 'RECORDING_EXCEEDED_QUOTA'})
        default:
            return errorCode
    }
}