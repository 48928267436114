import { of } from 'rxjs'
import { catchError, map, tap } from 'rxjs/operators'
import Constants from 'api-constants'

import Fetch from '../fetch'

import nodeIds from '../config/nodeIds'
import defaultConfig from './defaultConfig'

const { emiModules } = Constants

export default class VodModule extends Fetch {
    constructor(config, metaApi) {
        super(config, emiModules.VOD, defaultConfig)
        this.metaApi = metaApi
    }

    getVodNodes = () => {
        return this.metaApi.getVodNodes().pipe(
            tap(({ navMap }) => {
                navMap[nodeIds.vod].translateLabel = true
            }),
            map((data) => {
                // Simulate that config is active since 'now'
                return {
                    programmings: {
                        [Date.now()]: {
                            programmingId: 0,
                            ...data,
                        },
                    },
                    // No `revision` or `polling` attribute since config does not comes from EMI
                }
            }),
            catchError(() => of({}))
        )
    }

    /**
     * @param {String} population Targeted population
     * @returns {AjaxObservable}
     */
    getConfig = (population) => {
        return this.config.emiModules.vod ? this.getEmiConfig(population) : this.getVodNodes()
    }
}
