import { AsyncSubject, Subscription, timer } from 'rxjs'
import { mergeMap } from 'rxjs/operators'
import DataHelper from 'framework/helpers/data'
import MockLogger from 'MockLogger'
import { Logger } from '@typings/generic'

/**
 * Attempt to refresh Token in case of failure
 */
const DEFAULT_RETRY_PERIOD_MS = 50000

/**
 * Default refresh period if expiration date unknown
 */
const DEFAULT_REFRESH_PERIOD_MS = 15 * 60 * 1000

/**
 * Maximum refresh token duration used internally to prevent rxjs timer overflow
 * (7 days)
 */
const DEFAULT_MAX_TOKEN_REFRESH_MS = 7 * 24 * 60 * 60 * 1000

/**
 * Toolbox class to handle the Device Authorization Grant
 * https://auth0.com/docs/flows/device-authorization-flow
 */
export default class TokenHelper {
    static TAG = 'TokenHelper'

    logger: Logger
    _tokenType: string
    _withRefreshToken: boolean
    _tokenRefresh$: AsyncSubject<unknown>
    _isRefreshingToken: boolean
    _isRefreshTokenTimer$: undefined | Subscription
    _refreshTokenFunc: any
    config: {
        retryPeriodMs: number
        maxRefreshPeriodMs: number
        securityMarginRefreshMs: number
    }

    /**
     * Configure the helper class
     * @param {Function} refreshTokenFunc Callback to be called externally to refresh the token
     * @param {Object} logger Logger
     * @param {Boolean} [refreshToken=true] Enable refresh token
     * @param {Number} [retryPeriodMs=DEFAULT_RETRY_PERIOD_MS] Retry Period in milliseconds in case of failure
     * @param {Number} [maxRefreshPeriodMs=DEFAULT_MAX_TOKEN_REFRESH_MS] Maximum Period in milliseconds where a Refresh Token is forced
     * @param {Number} [securityMarginRefreshMs=20000] Value in ms to apply to refresh the token earlier than planned
     */
    constructor(
        refreshTokenFunc: () => void,
        tokenType = DataHelper.STORE_KEY.PRIMARY_ACCESS_TOKEN,
        logger: Logger,
        refreshToken = true,
        retryPeriodMs = DEFAULT_RETRY_PERIOD_MS,
        maxRefreshPeriodMs = DEFAULT_MAX_TOKEN_REFRESH_MS,
        securityMarginRefreshMs = 20000
    ) {
        // Logger
        this.logger = (logger || MockLogger).createChildInstance(
            (tokenType && tokenType.replace(/[^a-zA-Z0-9]+/g, '')).toLowerCase() || 'refreshtoken'
        )
        // For enabling functionality
        this._tokenType = tokenType
        this._withRefreshToken = refreshToken
        // Nested members
        this._tokenRefresh$ = new AsyncSubject()
        this._isRefreshingToken = false
        this._isRefreshTokenTimer$ = undefined
        this.config = {
            retryPeriodMs: 0,
            maxRefreshPeriodMs: 0,
            securityMarginRefreshMs: 0,
        }
        this.configure(refreshTokenFunc, retryPeriodMs, maxRefreshPeriodMs, securityMarginRefreshMs)
    }

    /**
     * Configure the helper class
     * @param {Function} refreshTokenFunc Callback to be called externally to refresh the token
     * @param {Number} [retryPeriodMs=DEFAULT_RETRY_PERIOD_MS] Retry Period in milliseconds in case of failure
     * @param {Number} [maxRefreshPeriodMs=DEFAULT_MAX_TOKEN_REFRESH_MS] Maximum Period in milliseconds where a Refresh Token is forced
     * @param {Number} [securityMarginRefreshMs=20000] Value in ms to apply to refresh the token earlier than planned
     */
    configure(
        refreshTokenFunc: any,
        retryPeriodMs = DEFAULT_RETRY_PERIOD_MS,
        maxRefreshPeriodMs = DEFAULT_MAX_TOKEN_REFRESH_MS,
        securityMarginRefreshMs = 20000
    ) {
        this._refreshTokenFunc = refreshTokenFunc
        this.config = {
            retryPeriodMs: retryPeriodMs,
            maxRefreshPeriodMs: maxRefreshPeriodMs,
            securityMarginRefreshMs: securityMarginRefreshMs,
        }
    }

    /** Start timer for the refresh of the Token asynchronously */
    startTokenRefreshTimer(nextExpirationMs?: number) {
        if (!this._withRefreshToken) {
            this.logger.warn(
                TokenHelper.TAG,
                `.. Not necessary to start a refresh as feature disabled`
            )
            return
        }
        if (this._isRefreshTokenTimer$) {
            this.stopTokenRefreshTimer()
            this.logger.info(TokenHelper.TAG, `.. stopped before starting.`)
        }

        const _NextExpirationMs = nextExpirationMs
            ? nextExpirationMs
            : this._getExpirationFromCache()
            ? (this._getExpirationFromCache() as number) - Date.now()
            : DEFAULT_REFRESH_PERIOD_MS
        const iNextRefreshTokenTimeMsecsWithMargin =
            _NextExpirationMs - this.config.securityMarginRefreshMs

        let iNextRefreshTokenTimeMsecs =
            iNextRefreshTokenTimeMsecsWithMargin > this.config.securityMarginRefreshMs
                ? iNextRefreshTokenTimeMsecsWithMargin
                : _NextExpirationMs

        this.logger.info(
            TokenHelper.TAG,
            `Next ${this._tokenType} Refresh planned in ${iNextRefreshTokenTimeMsecs} msecs`
        )
        if (iNextRefreshTokenTimeMsecs > this.config.maxRefreshPeriodMs) {
            // When iNextRefreshTokenTimeMsecs is higher than a predefined value (per example one year, then it creates an overflow for rxjs timer)
            iNextRefreshTokenTimeMsecs = this.config.maxRefreshPeriodMs
            this.logger.info(
                TokenHelper.TAG,
                `Next ${this._tokenType} Refresh is higher than maximum value, use ${iNextRefreshTokenTimeMsecs} msecs`
            )
        }

        this._isRefreshTokenTimer$ = timer(iNextRefreshTokenTimeMsecs)
            .pipe(
                mergeMap(() => {
                    this._isRefreshTokenTimer$ = undefined
                    return this.refreshToken()
                })
            )
            .subscribe()
    }

    /**
     * Stop the token refresh timer (can be used after a logout)
     */
    stopTokenRefreshTimer() {
        if (!this._withRefreshToken) {
            this.logger.warn(
                TokenHelper.TAG,
                `.. Not necessary to stop a refresh as feature disabled`
            )
            return
        }
        if (this._isRefreshTokenTimer$) {
            this.logger.info(TokenHelper.TAG, `Timer to refresh Token stopped`)

            this._isRefreshTokenTimer$.unsubscribe()
            this._isRefreshTokenTimer$ = undefined
        }
    }

    /**
     * Refresh Token wrapper
     * @param {Number} [retry=true] Retry to refresh token in case of failure
     */
    refreshToken = (retry = true) => {
        if (!this._refreshTokenFunc) {
            this.logger.error(TokenHelper.TAG, 'Refreshing function undefined')
            return this._tokenRefresh$
        }

        if (!this._isRefreshingToken) {
            this._tokenRefresh$ = new AsyncSubject()
            this._isRefreshingToken = true

            this._refreshTokenFunc().subscribe(
                () => {
                    this._isRefreshingToken = false
                    this._tokenRefresh$?.next(true)
                    this._tokenRefresh$?.complete()
                },
                () => {
                    this.logger.error(TokenHelper.TAG, `Refresh ${this._tokenType} Token FAILED`)
                    if (retry) {
                        this.logger.info(TokenHelper.TAG, 'Triggering a retry attempt...')
                        this.startTokenRefreshTimer(this.config.retryPeriodMs)
                    }

                    // Do not throw error but use complete as it is not necesary to throw an error
                    this._isRefreshingToken = false
                    this._tokenRefresh$?.next(false)
                    this._tokenRefresh$?.complete()
                }
            )
        } else {
            this.logger.warn(TokenHelper.TAG, 'Refreshing in progress...')
        }

        return this._tokenRefresh$
    }

    /**
     * Checks the existence of the token from cache memory (only)
     * @returns {boolean}
     */
    hasToken = () => {
        switch (this._tokenType) {
            case DataHelper.STORE_KEY.SECONDARY_ACCESS_TOKEN:
                return (
                    !!DataHelper.getInstance().getSecondaryAccessToken() &&
                    (!this._withRefreshToken ||
                        !!DataHelper.getInstance().getSecondaryRefreshToken())
                )
            case DataHelper.STORE_KEY.PRIMARY_ACCESS_TOKEN:
                return (
                    !!DataHelper.getInstance().getPrimaryAccessToken() &&
                    (!this._withRefreshToken || !!DataHelper.getInstance().getPrimaryRefreshToken())
                )
            case DataHelper.STORE_KEY.SESSION_TOKEN:
            default:
                return !!DataHelper.getInstance().getData(this._tokenType)
        }
    }

    /** Checks the existence and validity of the acc token */
    isTokenValid = () => {
        if (!this.hasToken()) return false
        if (!this._isExpirationTimeKnownByClientApp()) return true

        const tokenExpirationTimeMsecs = this._getExpirationFromCache() as number
        return !!tokenExpirationTimeMsecs && tokenExpirationTimeMsecs > Date.now()
    }

    /** Return expiration value from cache in ms */
    _getExpirationFromCache() {
        let tokenExpirationTimeMsecs
        switch (this._tokenType) {
            case DataHelper.STORE_KEY.SECONDARY_ACCESS_TOKEN:
                tokenExpirationTimeMsecs = DataHelper.getInstance().getData(
                    DataHelper.STORE_KEY.SECONDARY_ACCESS_TOKEN_EXPIRATION_TIME_MS
                )
                break
            case DataHelper.STORE_KEY.PRIMARY_ACCESS_TOKEN:
                tokenExpirationTimeMsecs = DataHelper.getInstance().getData(
                    DataHelper.STORE_KEY.PRIMARY_ACCESS_TOKEN_EXPIRATION_TIME_MS
                )
                break
            default:
                tokenExpirationTimeMsecs = undefined
                break
        }
        return typeof tokenExpirationTimeMsecs === 'string'
            ? parseInt(tokenExpirationTimeMsecs)
            : tokenExpirationTimeMsecs
    }

    /** Indicate if the token has expiration model */
    _isExpirationTimeKnownByClientApp() {
        switch (this._tokenType) {
            case DataHelper.STORE_KEY.SECONDARY_ACCESS_TOKEN:
            case DataHelper.STORE_KEY.PRIMARY_ACCESS_TOKEN:
                return true
            case DataHelper.STORE_KEY.SESSION_TOKEN:
                return false
            default:
                return false
        }
    }
}
