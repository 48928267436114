export default {
    matchInfo: {
        id: '7c2f1r3fe7code6ijoi1xzh1w',
        coverageLevel: '15',
        date: '2022-08-28Z',
        time: '15:30:00Z',
        localDate: '2022-08-28',
        localTime: '16:30:00',
        week: '4',
        numberOfPeriods: 2,
        periodLength: 45,
        lastUpdated: '2022-08-28T16:06:33Z',
        description: 'Nottingham Forest vs Tottenham Hotspur',
        sport: {
            id: '289u5typ3vp4ifwh5thalohmq',
            name: 'Soccer',
        },
        ruleset: {
            id: '79plas4983031idr6vw83nuel',
            name: 'Men',
        },
        competition: {
            id: '2kwbbcootiqqgmrzs6o5inle5',
            name: 'Premier League',
            competitionCode: 'EPL',
            competitionFormat: 'Domestic league',
            country: {
                id: '1fk5l4hkqk12i7zske6mcqju6',
                name: 'England',
            },
        },
        tournamentCalendar: {
            id: '80foo89mm28qjvyhjzlpwj28k',
            startDate: '2022-08-05Z',
            endDate: '2023-05-28Z',
            name: '2022/2023',
        },
        stage: {
            id: '80qbeanalyj5cvxikkq351iqc',
            formatId: 'e2q01r9o9jwiq1fls93d1sslx',
            startDate: '2022-08-05Z',
            endDate: '2023-05-28Z',
            name: 'Regular Season',
        },
        contestant: [
            {
                id: '1qtaiy11gswx327s0vkibf70n',
                name: 'Nottingham Forest',
                shortName: 'Nottm Forest',
                officialName: 'Nottingham Forest FC',
                code: 'NFO',
                position: 'home',
                country: {
                    id: '1fk5l4hkqk12i7zske6mcqju6',
                    name: 'England',
                },
            },
            {
                id: '22doj4sgsocqpxw45h607udje',
                name: 'Tottenham Hotspur',
                shortName: 'Tottenham',
                officialName: 'Tottenham Hotspur FC',
                code: 'TOT',
                position: 'away',
                country: {
                    id: '1fk5l4hkqk12i7zske6mcqju6',
                    name: 'England',
                },
            },
        ],
        venue: {
            id: '6ccyj8bk3iv2mc01xe5rtch3r',
            neutral: 'no',
            longName: 'The City Ground',
            shortName: 'The City Ground',
        },
    },
    liveData: {
        matchDetails: {
            matchTime: 37,
            periodId: 1,
            matchStatus: 'Playing',
            period: [
                {
                    id: 1,
                    start: '2022-08-28T15:30:17Z',
                },
            ],
            scores: {
                ft: {
                    home: 0,
                    away: 1,
                },
                total: {
                    home: 0,
                    away: 1,
                },
            },
        },
        goal: [
            {
                contestantId: '22doj4sgsocqpxw45h607udje',
                periodId: 1,
                timeMin: 5,
                timeMinSec: '4:58',
                lastUpdated: '2022-08-28T15:36:23Z',
                timestamp: '2022-08-28T15:35:15Z',
                type: 'G',
                scorerId: 'b9g4qurpll4wizajo95c406hh',
                scorerName: 'H. Kane',
                assistPlayerId: 'dlf31m2lrfl0biop1demgvh7t',
                assistPlayerName: 'D. Kulusevski',
                optaEventId: '2452502131',
                homeScore: 0,
                awayScore: 1,
            },
        ],
        lineUp: [
            {
                contestantId: '1qtaiy11gswx327s0vkibf70n',
                formationUsed: '3421',
                teamOfficial: {
                    id: '7qb4jzx7wrt2syeio63jpjtgp',
                    firstName: 'Steven David',
                    lastName: 'Cooper',
                    type: 'manager',
                },
                stat: [
                    {
                        fh: '1',
                        type: 'attemptsConcededIbox',
                        value: '1',
                    },
                    {
                        fh: '70',
                        type: 'possLostAll',
                        value: '70',
                    },
                    {
                        fh: '3',
                        type: 'ontargetAttAssist',
                        value: '3',
                    },
                    {
                        fh: '339',
                        type: 'touches',
                        value: '339',
                    },
                    {
                        fh: '84',
                        type: 'leftsidePass',
                        value: '84',
                    },
                    {
                        fh: '6',
                        type: 'totalTackle',
                        value: '6',
                    },
                    {
                        fh: '2',
                        type: 'fouledFinalThird',
                        value: '2',
                    },
                    {
                        fh: '7',
                        type: 'crosses18yard',
                        value: '7',
                    },
                    {
                        fh: '9',
                        type: 'possWonAtt3rd',
                        value: '9',
                    },
                    {
                        fh: '1',
                        type: 'attCorner',
                        value: '1',
                    },
                    {
                        fh: '1',
                        type: 'goalsConcededObox',
                        value: '1',
                    },
                    {
                        fh: '1',
                        type: 'attOboxTarget',
                        value: '1',
                    },
                    {
                        fh: '22',
                        type: 'penAreaEntries',
                        value: '22',
                    },
                    {
                        fh: '90',
                        type: 'accurateBackZonePass',
                        value: '90',
                    },
                    {
                        fh: '73',
                        type: 'fwdPass',
                        value: '73',
                    },
                    {
                        fh: '5',
                        type: 'attRfTotal',
                        value: '5',
                    },
                    {
                        fh: '3',
                        type: 'shotOffTarget',
                        value: '3',
                    },
                    {
                        fh: '1',
                        type: 'ontargetScoringAtt',
                        value: '1',
                    },
                    {
                        fh: '5',
                        type: 'interceptionWon',
                        value: '5',
                    },
                    {
                        fh: '4',
                        type: 'aerialWon',
                        value: '4',
                    },
                    {
                        fh: '3',
                        type: 'wonTackle',
                        value: '3',
                    },
                    {
                        fh: '36',
                        type: 'longPassOwnToOpp',
                        value: '36',
                    },
                    {
                        fh: '8',
                        type: 'accurateThrows',
                        value: '8',
                    },
                    {
                        fh: '8',
                        type: 'totalThrows',
                        value: '8',
                    },
                    {
                        fh: '1',
                        type: 'dispossessed',
                        value: '1',
                    },
                    {
                        fh: '10',
                        type: 'totalCrossNocorner',
                        value: '10',
                    },
                    {
                        fh: '5',
                        type: 'putThrough',
                        value: '5',
                    },
                    {
                        fh: '1',
                        type: 'totalOffside',
                        value: '1',
                    },
                    {
                        fh: '1',
                        type: 'totalKeeperSweeper',
                        value: '1',
                    },
                    {
                        fh: '4',
                        type: 'attemptsConcededObox',
                        value: '4',
                    },
                    {
                        fh: '1',
                        type: 'attMissRight',
                        value: '1',
                    },
                    {
                        fh: '8',
                        type: 'totalScoringAtt',
                        value: '8',
                    },
                    {
                        fh: '6',
                        type: 'totalLaunches',
                        value: '6',
                    },
                    {
                        fh: '2',
                        type: 'effectiveHeadClearance',
                        value: '2',
                    },
                    {
                        fh: '32',
                        type: 'passesRight',
                        value: '32',
                    },
                    {
                        fh: '220',
                        type: 'accuratePass',
                        value: '220',
                    },
                    {
                        fh: '60',
                        type: 'possessionPercentage',
                        value: '60',
                    },
                    {
                        fh: '7',
                        type: 'aerialLost',
                        value: '7',
                    },
                    {
                        fh: '38',
                        type: 'backwardPass',
                        value: '38',
                    },
                    {
                        fh: '12',
                        type: 'totalCross',
                        value: '12',
                    },
                    {
                        fh: '8',
                        type: 'accurateChippedPass',
                        value: '8',
                    },
                    {
                        fh: '5',
                        type: 'totalAttAssist',
                        value: '5',
                    },
                    {
                        fh: '1',
                        type: 'attOboxMiss',
                        value: '1',
                    },
                    {
                        fh: '14',
                        type: 'duelWon',
                        value: '14',
                    },
                    {
                        fh: '1',
                        type: 'attMissHigh',
                        value: '1',
                    },
                    {
                        fh: '4',
                        type: 'successfulPutThrough',
                        value: '4',
                    },
                    {
                        fh: '131',
                        type: 'accurateFwdZonePass',
                        value: '131',
                    },
                    {
                        fh: '1',
                        type: 'accurateCrossNocorner',
                        value: '1',
                    },
                    {
                        fh: '1',
                        type: 'attBxCentre',
                        value: '1',
                    },
                    {
                        fh: '70',
                        type: 'possLostCtrl',
                        value: '70',
                    },
                    {
                        fh: '1',
                        type: 'wonContest',
                        value: '1',
                    },
                    {
                        fh: '1',
                        type: 'challengeLost',
                        value: '1',
                    },
                    {
                        fh: '31',
                        type: 'finalThirdEntries',
                        value: '31',
                    },
                    {
                        fh: '1',
                        type: 'attHdMiss',
                        value: '1',
                    },
                    {
                        fh: '8',
                        type: 'possWonDef3rd',
                        value: '8',
                    },
                    {
                        fh: '1',
                        type: 'accurateKeeperSweeper',
                        value: '1',
                    },
                    {
                        fh: '1',
                        type: 'attMissLeft',
                        value: '1',
                    },
                    {
                        fh: '6',
                        type: 'unsuccessfulTouch',
                        value: '6',
                    },
                    {
                        fh: '1',
                        type: 'freekickCross',
                        value: '1',
                    },
                    {
                        fh: '2',
                        type: 'attIboxMiss',
                        value: '2',
                    },
                    {
                        fh: '2',
                        type: 'offtargetAttAssist',
                        value: '2',
                    },
                    {
                        fh: '1',
                        type: 'goalsConceded',
                        value: '1',
                    },
                    {
                        fh: '1',
                        type: 'attBxRight',
                        value: '1',
                    },
                    {
                        fh: '43',
                        type: 'passesLeft',
                        value: '43',
                    },
                    {
                        fh: '6',
                        type: 'attemptsObox',
                        value: '6',
                    },
                    {
                        fh: '19',
                        type: 'touchesInOppBox',
                        value: '19',
                    },
                    {
                        fh: '1',
                        type: 'accurateCross',
                        value: '1',
                    },
                    {
                        fh: '5',
                        type: 'blockedPass',
                        value: '5',
                    },
                    {
                        fh: '69',
                        type: 'successfulFinalThirdPasses',
                        value: '69',
                    },
                    {
                        fh: '5',
                        type: 'attAssistOpenplay',
                        value: '5',
                    },
                    {
                        fh: '14',
                        type: 'possWonMid3rd',
                        value: '14',
                    },
                    {
                        fh: '2',
                        type: 'attemptsIbox',
                        value: '2',
                    },
                    {
                        fh: '266',
                        type: 'totalPass',
                        value: '266',
                    },
                    {
                        fh: '4',
                        type: 'attOboxBlocked',
                        value: '4',
                    },
                    {
                        fh: '215',
                        type: 'successfulOpenPlayPass',
                        value: '215',
                    },
                    {
                        fh: '2',
                        type: 'totalCornersIntobox',
                        value: '2',
                    },
                    {
                        fh: '1',
                        type: 'attLfTarget',
                        value: '1',
                    },
                    {
                        fh: '6',
                        type: 'totalContest',
                        value: '6',
                    },
                    {
                        fh: '10',
                        type: 'accurateLongBalls',
                        value: '10',
                    },
                    {
                        fh: '5',
                        type: 'interception',
                        value: '5',
                    },
                    {
                        fh: '184',
                        type: 'totalFwdZonePass',
                        value: '184',
                    },
                    {
                        fh: '4',
                        type: 'blockedScoringAtt',
                        value: '4',
                    },
                    {
                        fh: '26',
                        type: 'totalLongBalls',
                        value: '26',
                    },
                    {
                        fh: '2',
                        type: 'cornerTaken',
                        value: '2',
                    },
                    {
                        fh: '71',
                        type: 'rightsidePass',
                        value: '71',
                    },
                    {
                        fh: '1',
                        type: 'lastManTackle',
                        value: '1',
                    },
                    {
                        fh: '2',
                        type: 'crosses18yardplus',
                        value: '2',
                    },
                    {
                        fh: '258',
                        type: 'openPlayPass',
                        value: '258',
                    },
                    {
                        fh: '1',
                        type: 'outfielderBlock',
                        value: '1',
                    },
                    {
                        fh: '2',
                        type: 'attLfTotal',
                        value: '2',
                    },
                    {
                        fh: '2',
                        type: 'goalKicks',
                        value: '2',
                    },
                    {
                        fh: '1',
                        type: 'attHdTotal',
                        value: '1',
                    },
                    {
                        fh: '14',
                        type: 'duelLost',
                        value: '14',
                    },
                    {
                        fh: '3',
                        type: 'fkFoulWon',
                        value: '3',
                    },
                    {
                        fh: '4',
                        type: 'totalClearance',
                        value: '4',
                    },
                    {
                        fh: '1',
                        type: 'attSvLowCentre',
                        value: '1',
                    },
                    {
                        fh: '2',
                        type: 'headClearance',
                        value: '2',
                    },
                    {
                        fh: '6',
                        type: 'attObxCentre',
                        value: '6',
                    },
                    {
                        fh: '94',
                        type: 'totalBackZonePass',
                        value: '94',
                    },
                    {
                        fh: '7',
                        type: 'attOpenplay',
                        value: '7',
                    },
                    {
                        fh: '18',
                        type: 'totalChippedPass',
                        value: '18',
                    },
                    {
                        fh: '31',
                        type: 'ballRecovery',
                        value: '31',
                    },
                    {
                        fh: '100',
                        type: 'totalFinalThirdPasses',
                        value: '100',
                    },
                    {
                        fh: '2',
                        type: 'wonCorners',
                        value: '2',
                    },
                    {
                        fh: '4',
                        type: 'effectiveClearance',
                        value: '4',
                    },
                    {
                        fh: '20',
                        type: 'longPassOwnToOppSuccess',
                        value: '20',
                    },
                    {
                        type: 'formationUsed',
                        value: '3421',
                    },
                ],
                kit: {
                    id: '4782',
                    colour1: '#FF0000',
                    colour2: '#FFFFFF',
                    type: 'home',
                },
            },
            {
                contestantId: '22doj4sgsocqpxw45h607udje',
                formationUsed: '3421',
                teamOfficial: {
                    id: '2t14kex330qljt5vlmssvo6ad',
                    firstName: 'Antonio',
                    lastName: 'Conte Cavaliere',
                    type: 'manager',
                },
                stat: [
                    {
                        fh: '1',
                        type: 'divingSave',
                        value: '1',
                    },
                    {
                        fh: '24',
                        type: 'totalFinalThirdPasses',
                        value: '24',
                    },
                    {
                        fh: '4',
                        type: 'fkFoulLost',
                        value: '4',
                    },
                    {
                        fh: '1',
                        type: 'goalsOpenplay',
                        value: '1',
                    },
                    {
                        fh: '11',
                        type: 'longPassOwnToOppSuccess',
                        value: '11',
                    },
                    {
                        fh: '15',
                        type: 'effectiveClearance',
                        value: '15',
                    },
                    {
                        fh: '130',
                        type: 'totalBackZonePass',
                        value: '130',
                    },
                    {
                        fh: '4',
                        type: 'attObxCentre',
                        value: '4',
                    },
                    {
                        fh: '32',
                        type: 'ballRecovery',
                        value: '32',
                    },
                    {
                        fh: '11',
                        type: 'totalChippedPass',
                        value: '11',
                    },
                    {
                        fh: '5',
                        type: 'attOpenplay',
                        value: '5',
                    },
                    {
                        fh: '7',
                        type: 'headClearance',
                        value: '7',
                    },
                    {
                        fh: '15',
                        type: 'totalClearance',
                        value: '15',
                    },
                    {
                        fh: '14',
                        type: 'duelLost',
                        value: '14',
                    },
                    {
                        fh: '2',
                        type: 'lostCorners',
                        value: '2',
                    },
                    {
                        fh: '1',
                        type: 'attLfTotal',
                        value: '1',
                    },
                    {
                        fh: '3',
                        type: 'goalKicks',
                        value: '3',
                    },
                    {
                        fh: '175',
                        type: 'openPlayPass',
                        value: '175',
                    },
                    {
                        fh: '4',
                        type: 'outfielderBlock',
                        value: '4',
                    },
                    {
                        fh: '52',
                        type: 'totalFwdZonePass',
                        value: '52',
                    },
                    {
                        fh: '40',
                        type: 'rightsidePass',
                        value: '40',
                    },
                    {
                        fh: '20',
                        type: 'totalLongBalls',
                        value: '20',
                    },
                    {
                        fh: '1',
                        type: 'blockedScoringAtt',
                        value: '1',
                    },
                    {
                        fh: '3',
                        type: 'totalContest',
                        value: '3',
                    },
                    {
                        fh: '1',
                        type: 'saves',
                        value: '1',
                    },
                    {
                        fh: '11',
                        type: 'interception',
                        value: '11',
                    },
                    {
                        fh: '7',
                        type: 'accurateLongBalls',
                        value: '7',
                    },
                    {
                        fh: '1',
                        type: 'goalAssist',
                        value: '1',
                    },
                    {
                        fh: '135',
                        type: 'successfulOpenPlayPass',
                        value: '135',
                    },
                    {
                        fh: '1',
                        type: 'attOboxBlocked',
                        value: '1',
                    },
                    {
                        fh: '180',
                        type: 'totalPass',
                        value: '180',
                    },
                    {
                        fh: '7',
                        type: 'possWonMid3rd',
                        value: '7',
                    },
                    {
                        fh: '2',
                        type: 'attAssistOpenplay',
                        value: '2',
                    },
                    {
                        fh: '1',
                        type: 'attemptsIbox',
                        value: '1',
                    },
                    {
                        fh: '1',
                        type: 'totalFlickOn',
                        value: '1',
                    },
                    {
                        fh: '5',
                        type: 'blockedPass',
                        value: '5',
                    },
                    {
                        fh: '1',
                        type: 'attRfGoal',
                        value: '1',
                    },
                    {
                        fh: '1',
                        type: 'accurateKeeperThrows',
                        value: '1',
                    },
                    {
                        fh: '17',
                        type: 'successfulFinalThirdPasses',
                        value: '17',
                    },
                    {
                        fh: '4',
                        type: 'attemptsObox',
                        value: '4',
                    },
                    {
                        fh: '5',
                        type: 'passesLeft',
                        value: '5',
                    },
                    {
                        fh: '1',
                        type: 'accurateCross',
                        value: '1',
                    },
                    {
                        fh: '5',
                        type: 'touchesInOppBox',
                        value: '5',
                    },
                    {
                        fh: '1',
                        type: 'offtargetAttAssist',
                        value: '1',
                    },
                    {
                        fh: '1',
                        type: 'attIboxMiss',
                        value: '1',
                    },
                    {
                        fh: '6',
                        type: 'unsuccessfulTouch',
                        value: '6',
                    },
                    {
                        fh: '20',
                        type: 'possWonDef3rd',
                        value: '20',
                    },
                    {
                        fh: '1',
                        type: 'goals',
                        value: '1',
                    },
                    {
                        fh: '8',
                        type: 'finalThirdEntries',
                        value: '8',
                    },
                    {
                        fh: '1',
                        type: 'challengeLost',
                        value: '1',
                    },
                    {
                        fh: '1',
                        type: 'forwardGoals',
                        value: '1',
                    },
                    {
                        fh: '1',
                        type: 'wonContest',
                        value: '1',
                    },
                    {
                        fh: '57',
                        type: 'possLostCtrl',
                        value: '57',
                    },
                    {
                        fh: '1',
                        type: 'accurateCrossNocorner',
                        value: '1',
                    },
                    {
                        fh: '1',
                        type: 'savedObox',
                        value: '1',
                    },
                    {
                        fh: '1',
                        type: 'punches',
                        value: '1',
                    },
                    {
                        fh: '1',
                        type: 'attGoalLowLeft',
                        value: '1',
                    },
                    {
                        fh: '14',
                        type: 'duelWon',
                        value: '14',
                    },
                    {
                        fh: '3',
                        type: 'totalAttAssist',
                        value: '3',
                    },
                    {
                        fh: '2',
                        type: 'attOboxMiss',
                        value: '2',
                    },
                    {
                        fh: '4',
                        type: 'accurateChippedPass',
                        value: '4',
                    },
                    {
                        fh: '2',
                        type: 'totalCross',
                        value: '2',
                    },
                    {
                        fh: '35',
                        type: 'accurateFwdZonePass',
                        value: '35',
                    },
                    {
                        fh: '1',
                        type: 'successfulPutThrough',
                        value: '1',
                    },
                    {
                        fh: '1',
                        type: 'attMissHigh',
                        value: '1',
                    },
                    {
                        fh: '26',
                        type: 'backwardPass',
                        value: '26',
                    },
                    {
                        fh: '4',
                        type: 'aerialLost',
                        value: '4',
                    },
                    {
                        fh: '1',
                        type: 'attCmissRight',
                        value: '1',
                    },
                    {
                        fh: '40',
                        type: 'possessionPercentage',
                        value: '40',
                    },
                    {
                        fh: '139',
                        type: 'accuratePass',
                        value: '139',
                    },
                    {
                        fh: '1',
                        type: 'totalFastbreak',
                        value: '1',
                    },
                    {
                        fh: '4',
                        type: 'totalLaunches',
                        value: '4',
                    },
                    {
                        fh: '1',
                        type: 'shotFastbreak',
                        value: '1',
                    },
                    {
                        fh: '11',
                        type: 'passesRight',
                        value: '11',
                    },
                    {
                        fh: '7',
                        type: 'effectiveHeadClearance',
                        value: '7',
                    },
                    {
                        fh: '1',
                        type: 'attBxLeft',
                        value: '1',
                    },
                    {
                        fh: '2',
                        type: 'attMissRight',
                        value: '2',
                    },
                    {
                        fh: '6',
                        type: 'attemptsConcededObox',
                        value: '6',
                    },
                    {
                        fh: '5',
                        type: 'totalScoringAtt',
                        value: '5',
                    },
                    {
                        fh: '1',
                        type: 'totalOffside',
                        value: '1',
                    },
                    {
                        fh: '5',
                        type: 'putThrough',
                        value: '5',
                    },
                    {
                        fh: '2',
                        type: 'totalCrossNocorner',
                        value: '2',
                    },
                    {
                        fh: '1',
                        type: 'attCmissHigh',
                        value: '1',
                    },
                    {
                        fh: '4',
                        type: 'dispossessed',
                        value: '4',
                    },
                    {
                        fh: '6',
                        type: 'totalThrows',
                        value: '6',
                    },
                    {
                        fh: '6',
                        type: 'accurateThrows',
                        value: '6',
                    },
                    {
                        fh: '1',
                        type: 'attOboxGoal',
                        value: '1',
                    },
                    {
                        fh: '7',
                        type: 'aerialWon',
                        value: '7',
                    },
                    {
                        fh: '3',
                        type: 'wonTackle',
                        value: '3',
                    },
                    {
                        fh: '21',
                        type: 'longPassOwnToOpp',
                        value: '21',
                    },
                    {
                        fh: '3',
                        type: 'accurateGoalKicks',
                        value: '3',
                    },
                    {
                        fh: '1',
                        type: 'ontargetScoringAtt',
                        value: '1',
                    },
                    {
                        fh: '11',
                        type: 'interceptionWon',
                        value: '11',
                    },
                    {
                        fh: '66',
                        type: 'fwdPass',
                        value: '66',
                    },
                    {
                        fh: '4',
                        type: 'attRfTotal',
                        value: '4',
                    },
                    {
                        fh: '3',
                        type: 'shotOffTarget',
                        value: '3',
                    },
                    {
                        fh: '1',
                        type: 'penAreaEntries',
                        value: '1',
                    },
                    {
                        fh: '105',
                        type: 'accurateBackZonePass',
                        value: '105',
                    },
                    {
                        fh: '1',
                        type: 'goalAssistOpenplay',
                        value: '1',
                    },
                    {
                        fh: '2',
                        type: 'interceptionsInBox',
                        value: '2',
                    },
                    {
                        fh: '1',
                        type: 'possWonAtt3rd',
                        value: '1',
                    },
                    {
                        fh: '2',
                        type: 'crosses18yard',
                        value: '2',
                    },
                    {
                        fh: '3',
                        type: 'keeperThrows',
                        value: '3',
                    },
                    {
                        fh: '1',
                        type: 'handBall',
                        value: '1',
                    },
                    {
                        fh: '2',
                        type: 'ontargetAttAssist',
                        value: '2',
                    },
                    {
                        fh: '256',
                        type: 'touches',
                        value: '256',
                    },
                    {
                        type: 'firstHalfGoals',
                        value: '1',
                    },
                    {
                        fh: '57',
                        type: 'possLostAll',
                        value: '57',
                    },
                    {
                        fh: '6',
                        type: 'totalTackle',
                        value: '6',
                    },
                    {
                        fh: '1',
                        type: 'attFastbreak',
                        value: '1',
                    },
                    {
                        fh: '48',
                        type: 'leftsidePass',
                        value: '48',
                    },
                    {
                        fh: '2',
                        type: 'attemptsConcededIbox',
                        value: '2',
                    },
                    {
                        fh: '2',
                        type: 'attemptedTackleFoul',
                        value: '2',
                    },
                    {
                        type: 'formationUsed',
                        value: '3421',
                    },
                ],
                kit: {
                    id: '4',
                    colour1: '#FFFFFF',
                    colour2: '#132257',
                    type: 'home',
                },
            },
        ],
        matchDetailsExtra: {
            matchOfficial: [
                {
                    id: '3oe7osb8o3cy0dgex37asng0l',
                    type: 'Main',
                    firstName: 'Craig',
                    lastName: 'Pawson',
                },
                {
                    id: 'f1g3337l5j65736o54oswmxud',
                    type: 'Lineman 1',
                    firstName: 'Ian',
                    lastName: 'Hussin',
                },
                {
                    id: '9fqj6o3vtcziwj0xpzy4e6j85',
                    type: 'Lineman 2',
                    firstName: 'Scott',
                    lastName: 'Ledger',
                },
                {
                    id: '6l3ie5htdymg13u2ui69wo251',
                    type: 'Fourth official',
                    firstName: 'Darren',
                    lastName: 'England',
                },
            ],
        },
    },
}
