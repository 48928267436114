import { Vod } from './Vod'
import { IOrphanEpisode } from '@typings/tile'
import Constants from 'api-constants'

/**
 * @typedef {Object} EpisodeProps
 * @property {Number} format Tile format
 * @property {Number} number Episode number
 * @property {String|Number} seasonNumber Season number
 * @property {String|Number} seriesTitle Series title
 */

export class OrphanEpisode extends Vod {
    number: number
    seasonNumber: number
    seriesTitle: string

    constructor(props: IOrphanEpisode) {
        super(props)
        this.number = props.number
        this.seasonNumber = props.seasonNumber
        this.seriesTitle = props.seriesTitle

        this.vodType = Constants.programType.orphanEpisode
        this.format = 'landscape'
    }

    isOrphanEpisode() {
        return true
    }
}
