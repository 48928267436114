import HuaweiTypes from '../HuaweiTypes'

/**
 * @class Login regsponse returned by VSP/V3/Authenticate
 */
export class LoginStatusV6 {
    constructor(props) {
        /**
         * @type {String}
         * Token of the current login user, which is automatically generated after the user is authenticated in the VSP.
         */
        this.userToken = props.userToken

        /**
         * @type {String}
         * Authentication token required in the login process without passwords.
         * If authType is set to 1, 2, or 11 in authenticateBasic in the request and the login authentication is successful, this parameter is returned.
         */
        this.authToken = props.authToken

        /**
         * @type {String}
         * Device logical ID. This parameter is valid for cPVR tasks.
         */
        this.deviceID = props.deviceID

        /**
         * @type {String}
         * 	Subscriber ID.
         * The value is returned when the login is successful, the number of bound devices reaches the maximum,
         * or a subscriber logs in without a password but the subscriber does not exist.
         */
        this.subscriberID = props.subscriberID

        /**
         * @type {String}
         * If the value of userType in the request is 1 or profile automatic login is supported, the profileID value corresponding
         * to the subscriber is returned.
         * NOTE: Automatic login of a profile can be implemented in the following scenarios: the current device has a default profile;
         * the subscriber logins in as a guest; the subscriber has only one profile.
         */
        this.profileID = props.profileID

        /**
         * @type {Number}
         * If the value of userType in the request is 1 or profile automatic login is supported, the profileSn value corresponding to the subscriber is returned.
         * NOTE:Automatic login of a profile can be implemented in the following scenarios: the current device has a default profile;
         * the subscriber logins in as a guest; the subscriber has only one profile.
         */
        this.profileSN = HuaweiTypes.parseInt(props.profileSN)

        /**
         * @type {Array<Object>}
         * 	Information about all profiles of a subscriber.
         */
        this.profiles = props.profiles

        /**
         * @type {Object}
         * The Envision Video Platform can connect to the CAs provided by different vendors.
         * Different CA vendors deliver different parameters to the client.
         * The response contains this parameter when the authentication is successful.
         * For details, see CAInfo.
         */
        this.CA = props.CA

        /**
         * @type {Boolean}
         * If the userId parameter indicates the subscriber or device ID, this parameter indicates whether the subscriber logs in
         * to the Envision Video Platform for the first time; if the userId parameter indicates the login name of the profile,
         * this parameter indicates whether the profile logs in to the Envision Video Platform for the first time.
         * The options are as follows: 0: no 1: yes
         */
        this.isFirstLogin = HuaweiTypes.parseIBoolean(props.isFirstLogin)

        /**
         * @type {String}
         * Subscriber login status. The response contains this parameter when the authentication is successful.
         * If the bypass is supported by the system and the database or the VSP is abnormal, the VSC server bypasses the login request.
         * The options are as follows:
         * 0: normal
         * 1: login request bypassed
         */
        this.loginOccasion = props.loginOccasion

        /**
         * @type {String}
         * Time zone of a client. Format: GMT+/-N
         * In some areas, N is in the format of hh:ss. For example, the time zone is GMT+03:30.
         * NOTE: The client and VSP support the UTC and local time zones. For details, see the UTCEnable field of the authentication interface.
         * If the local time zone is used, the client can report the time zone specified by the subscriber. If the subscriber does not specify the time zone, the VSP queries the local time zone belonging to the home area of the subscriber.
         * The home area is specified during subscriber registration.
         */
        this.timeZone = props.timeZone

        /**
         * @type {String}
         * 	Area ID of a subscriber. This parameter is returned upon authentication success.
         * The parameter needs to be written to the client when the authentication is successful.
         */
        this.areaCode = props.areaCode

        /**
         * @type {String}
         * 	For profile authentication, the VSP queries the name of the profile template set for the profile. If no template is set for the profile,
         * the VSP returns the template name based on the template matching policy.
         * For subscriber/device authentication, the VSP queries the name of the profile template set for the super profile. If no template is set for
         * the profile, the VSP returns the template name based on the template matching policy.
         * For a subscriber who logs in in disaster recovery scenarios, if templateName is reported in the device authentication request,
         * the VSP returns the value reported by the device. If templateName is not reported in the device authentication request,
         * the VSC obtains the template used in the last successful login from the cache. If the template cannot be obtained from the cache,
         * the VSC returns the default template.
         *
         */
        this.templateName = props.templateName

        /**
         * @type {String}
         * Subscriber group ID.
         * The value of this parameter must be written to the client after successful subscriber authentication.
         * The client sends usergroup to the VSC server when sending an authentication request. This enables the Envision Video Platform
         * to provide services for subscribers upon disaster recovery.
         */
        this.userGroup = props.userGroup

        /**
         * @type {String}
         * ID of the regional node carrier to which the subscriber belongs. After the subscriber authentication is successful,
         * the parameter is written to the device. The client sends the parameter to the VSP upon next login. This enables the EVP
         * to provide services for subscribers upon disaster recovery.
         */
        this.subnetID = props.subnetID

        /**
         * @type {String}
         * 	ID of the BOSS to which a subscriber belongs.
         */
        this.bossID = props.bossID

        /**
         * @type {Number}
         * Bandwidth available to a subscriber when the authentication is successful. The value of Integer.MAX_VALUE
         * is returned for forcible bypass. Unit: kbit/s
         */
        this.bandWidth = HuaweiTypes.parseInt(props.bandWidth)

        /**
         * @type {Array<String>}
         * 	Type of a subscribed service, which is returned after successful authentication.
         * A device supports multiple service types separated by commas (,). The value Hybrid-IP is used to be compatible with Hybrid-IP at the OTE site. The value is IPTV,DVB, indicating that both the IPTV and DVB are supported.
         * The options are as follows:
         * IPTV: IPTV
         * SAT-only: SAT-only
         * Hybrid-IP: IPTV and DVB (compatible with the Hybrid-IP mode at the OTE site)
         * OTT: OTT
         */
        this.provisioningType = props.provisioningType

        /**
         * @type {String}
         * Address of an RRS at an edge POP. The format is http://rrsip:rrsport.
         */
        this.RRSAddr = props.RRSAddr

        /**
         * @type {Object} Anti-tamper check interface. The key is an interface name, for example, Play or Authorization.
         * The value is the attribute names whose data consistency is to be verified. Input parameters are separated from output parameters
         * by semicolons (;), and multiple input/output parameters are separated by commas (,). If the interface does not need to verify
         * input or output parameters, the value is a blank character string.*/
        this.antiTamperURI = props.antiTamperURI

        /**
         * @type {Boolean}
         * Indicates whether a subscriber is a triple-play subscriber.
         * Whether the parameter is contained depends on the site requirement.
         * The options are as follows:
         * 0: no
         * 1: yes
         */
        this.isTriplePlay = HuaweiTypes.parseIBoolean(props.isTriplePlay)

        /**
         * @type {HuaweiTypes.opt}
         * Indicates whether a subscriber allows the VSP to collect the subscriber's behavior data.
         * The options are as follows:
         * 0: opt in
         * 1: opt out
         * NOTE:This parameter is not recommended. You are advised to use the QueryPrivacyStatement and SignPrivacyStatement interfaces
         * to query and set the privacy protection policy for subscriber data collection.
         */
        this.opt = HuaweiTypes.parseInt(props.opt)

        /**
         * @type {Boolean}
         * Indicates whether to provide the initial subscriber preference feedback function.
         * The options are as follows:
         * 0: no
         * 1: yes
         */
        this.hasCollectUserPreference = HuaweiTypes.parseIBoolean(props.hasCollectUserPreference)

        /**
         * @type {Object}
         * Historical authentication log information and other effective login information (if any).
         */
        this.userLoginHistoryInfo = props.userLoginHistoryInfo

        /**
         * @type {String}
         * The response contains this parameter when the authentication is successful.
         */
        this.jSessionID = props.jSessionID

        /**
         * @type {String}
         * HTTPS related session ID. The response contains this parameter when the authentication is successful.
         */
        this.cSessionId = props.cSessionId

        /**
         * @type {String}
         * Channel list filtering criterion, which is a hash value containing the following information: regional node where a subscriber belongs,
         * language, area, group, domain, channel namespace, channel version number, device type, content provider, and device capabilities
         * such as the content frame rate.
         * The EPG server generates personalized subscriber information for filtering the channel list and returns the information to the client,
         * and the client caches information during subscriber login. This parameter helps improve server performance when the channel list
         * query interface of the EPG server is invoked.
         * This parameter is returned upon successful authentication.
         */
        this.userFilter = props.userFilter

        /**
         * @type {String}
         * 	VOD list filtering criterion, which is a hash value containing the following information: regional node where a subscriber belongs,
         * language, area, group, domain, parental control level, content provider, and device capabilities such as the content frame rate.
         * The EPG server generates personalized subscriber information for filtering the VOD list and returns the information to the client,
         * and the client caches information during subscriber login. This parameter helps improve server performance when the VOD list
         * query interface of the EPG server is invoked.
         * This parameter is returned upon successful authentication.
         */
        this.userVODListFilter = props.userVODListFilter

        /**
         * @type {String}
         * VOD details filtering criterion, which is a hash value containing the following information: regional node where a subscriber belongs,
         * language, area, group, domain, parental control level, content provider, and device capabilities such as the content frame rate.
         * The EPG server generates personalized subscriber information for filtering VOD details and returns the information to the client,
         * and the client caches information during the subscriber login. This parameter helps improve server performance when the VOD details query
         * interface of the EPG server is invoked.
         * This parameter is returned upon successful authentication.
         */
        this.userVODDetailFilter = props.userVODDetailFilter

        /**
         * @type {String}
         * 	Program list filtering criterion, which is a hash value containing the following information: regional node where a subscriber belongs,
         * language, area, group, domain, channel namespace, parental control level, device type, content provider, and device capabilities
         * such as the content frame rate.
         * The EPG server generates personalized subscriber information for filtering the program list and returns the information to the client,
         * and the client caches information during subscriber login. This parameter helps improve server performance when the program list
         * query interface of the EPG server is invoked.
         * This parameter is returned upon successful authentication.
         */
        this.userPlaybillListFilter = props.userPlaybillListFilter

        /**
         * @type {String}
         * Program details filtering criterion, which is a hash value containing the following information: regional node where a subscriber belongs, language, area, group, domain, channel namespace, parental control level, device type, content provider, and device capabilities such as the content frame rate.
         * The EPG server generates personalized subscriber information for filtering program details and returns the information to the client, and the client caches information during subscriber login. This parameter helps improve server performance when the program details query interface of the EPG server is invoked.
         * This parameter is returned upon successful authentication.
         */
        this.userPlaybillDetailFilter = props.userPlaybillDetailFilter

        /**
         * @type {String}
         * 	User device PIN when the authentication is successful. This parameter is valid only in scenarios where the Irdeto CA is connected.
         */
        this.cardPin = props.cardPin

        /**
         * @type {String}
         * ID of the channel namespace.
         */
        this.channelNamespace = props.channelNamespace

        /**
         * @type {Boolean}
         * Indicates whether to sign a privacy statement.
         * The options are as follows:
         * 0: no
         * 1: yes
         */
        this.needSignPrivacyStatement = HuaweiTypes.parseIBoolean(props.needSignPrivacyStatement)

        /**
         * @type {String}
         * Subscriber's registration number.
         */
        this.registerNo = props.registerNo

        /**
         * @type {Number}
         * Indicates whether a subscriber logs in to the current device for the first time. The default value is 0.
         * Note 1: This parameter is returned only when the function specified by ReturnDeviceFirstLoginFlag is enabled.
         * Note 2: The invalid value –1 is returned for this parameter in the following scenarios:
         * a. The function specified by ReturnDeviceFirstLoginFlag parameter is disabled.
         * b. Guest login or DR scenario
         * The options are as follows:
         * 0: no
         * 1: yes
         * -1: invalid
         */
        this.isDeviceFirstLogin = HuaweiTypes.parseIBoolean(props.isDeviceFirstLogin)

        /** @type {String} */
        this.returnJSessionIdInBody = HuaweiTypes.parseIBoolean(props.returnJSessionIdInBody)

        /** @type {String} */
        this.returnCSessionIdInBody = props.returnCSessionIdInBody

        /**
         * @type {String}
         * Verimatrix device ID
         */
        this.VUID = props.VUID
    }

    static CA = {
        verimatrix: 'verimatrix',
        playReady: 'playReady',
        nagra: 'nagra',
        irdeto: 'irdeto',
        novel: 'novel',
        chinaDRM: 'chinaDRM',
    }

    parseDate(ts) {
        if (ts && typeof ts === 'string') {
            return new Date(ts)
        } else if (ts && typeof ts === 'number') {
            return new Date(ts * 1000)
        }
        return ts
    }
}
