import { HashMap } from '@typings/generic'
import DataHelper from '../data'
import { CACHED_LIST_NAMES } from 'enums'
/**
 * A class-helper for managing lists of id in the local storage
 *
 * It will be better if you would you the name for your list from static LISTS_NAMES
 * to be more consistent in the local storage
 * @example
 * In the local storage you will have the key CACHED_IDS_LISTS
 * In this key you will have the object with such structure:
 * CACHED_IDS_LISTS: {
 *  FAVORITE_RECORDINGS: ['id1', 'id2']
 *  RANDOM_NAME: ['id1', 'id2']
 *  ...
 * }
 * @class
 */
export class CachedIdsListHelper {
    listName: string

    /** Class constructor */
    constructor(listName: `${CACHED_LIST_NAMES}`) {
        this.listName = listName
    }

    /** The list of the names where an array of ids should be stored */
    static LISTS_NAMES = CACHED_LIST_NAMES

    /** Adds id in the local storage */
    addIdToList(id: string) {
        const lists = this.getLists()
        return DataHelper.getInstance().storeData([
            [
                DataHelper.STORE_KEY.CACHED_IDS_LISTS,
                JSON.stringify({
                    ...lists,
                    [this.listName]: [...lists[this.listName], id],
                }),
            ],
        ])
    }

    /** Gets the ids from the list */
    getIdsFromList() {
        const lists = this.getLists()
        return lists[this.listName]
    }

    /** Gets all the lists from the local storage */
    getLists(): HashMap<string, string[]> {
        const lists = DataHelper.getInstance().getData(
            DataHelper.STORE_KEY.CACHED_IDS_LISTS
        ) as string
        if (!lists) return { [this.listName]: [] }

        const parsedLists = JSON.parse(lists)
        if (!parsedLists[this.listName]) return { ...parsedLists, [this.listName]: [] }
        return parsedLists
    }

    /** Deletes an id from the list */
    removeIdFromList(id: string) {
        const lists = this.getLists()
        return DataHelper.getInstance().storeData([
            [
                DataHelper.STORE_KEY.CACHED_IDS_LISTS,
                JSON.stringify({
                    ...lists,
                    [this.listName]: lists[this.listName].filter(
                        (existingId: string) => existingId !== id
                    ),
                }),
            ],
        ])
    }

    /** Deletes all the ids from the list */
    clearList() {
        const lists = this.getLists()
        lists[this.listName] = []
        return DataHelper.getInstance().storeData([
            [DataHelper.STORE_KEY.CACHED_IDS_LISTS, JSON.stringify(lists)],
        ])
    }
}
