import { Subscription } from 'models'

/**
 * Deduct the subscription the user is eligible
 * @param {Array<Subscription>} subscriptions
 */
export const filterProductByEligibility = (
    subscriptions = [],
    subscriptionGenerationEligibility = Subscription.PRODUCT_GENERATION.UNDEFINED
) => {
    if (subscriptionGenerationEligibility === Subscription.PRODUCT_GENERATION.UNDEFINED) {
        return subscriptions
    }

    return subscriptions.filter(
        (subscription) => subscription.productGeneration === subscriptionGenerationEligibility
    )
}
