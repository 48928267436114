import {useIcon} from '../icon/useIcon'
import {Show} from 'solid-js'
import './logintvtab.css'
import {useConfig} from '../config/useConfig'
import {FocusGroupProvider, FocusableProvider} from '../focus/index';
import { useSpatialNavigation } from '../../spatialNavigation';
import { onMount } from 'solid-js';
import { isPlatform } from '../../utils';
import { useI18n } from '../../i18n';

interface LoginTVTabProps {
    onLoginTVTab: () => void
    onLoginSettingsTVTab: () => void
    failed: boolean
}

export const LoginTVTab = (props: LoginTVTabProps) => {
    const {getConfig} = useConfig()
    const {getI18n} = useI18n()
    const icons = useIcon()
    const {setFocus} = useSpatialNavigation()

    onMount(() => {
        if(!isPlatform('laptop')) {
            setFocus(`loginsbutton`)
        }
    })
    
    return (
        <FocusGroupProvider
                id={`logintvtab-screen`}
                focusKey={`logintvtab-screen`}
                onFocus={() => {
                }}
        >
            <div
                style={{
                    display: 'flex',
                    'justify-content': 'center',
                    'align-items': 'center',
                    height: '100vh',
                    'background-image': `url(${icons.get('LoginBackdrop')})`,
                    'background-size': 'cover',
                }}
            >
                <div class="login-content-tab">
                    <div class="client_logo_loading_logout_screen"></div>
                    <div class="login-content-info">{getI18n()?.formatMessage({id: 'LOGIN_FOR_FULL_EXP_MSG'})}</div>
                    <div id="loginsettingbutton">
                        <FocusableProvider
                            id={'loginsbutton'}
                            focusKey={`loginsbutton`}
                            onEnterPress={() => {
                                props.onLoginTVTab()
                            }}
                            onFocus={() => {}}
                        >
                        {(focused: any) => (
                            <button 
                            class={`loginlang-content-button ${focused ? 'loginlang-content-button-focus' : ''}`}  
                            onClick={props.onLoginTVTab}
                            tabIndex={focused ? 0 : -1}
                            >
                                {getI18n()?.formatMessage({id: 'LOG_IN'})}
                            </button>
                        )}
                        </FocusableProvider>
                    </div>
                    <Show when={getConfig().allowGuest}>
                        <button class="login-content-button-tv" onClick={() => console.log('Guest Continue clicked')}>
                            {getI18n()?.formatMessage({id: 'CONTINUE_AS_GUEST'})}
                        </button>
                    </Show>
                    <div class="login-content-footer">
                        {/*TODO: feed this from config?*/}
                        <Show when={false}>
                        {getI18n()?.formatMessage({id: 'NEW_TO'})}{getConfig()?.client}? <a href="#"> {getI18n()?.formatMessage({id: 'CREATE_ACCOUNT'})}</a>
                        </Show>
                    </div>
                </div>
            </div>
        </FocusGroupProvider>
    )
}
