import { SoccerContestant, SoccerFixture } from '../models'
import { get, has, orderBy } from 'lodash'
import {
    SoccerSquadPerson,
    SoccerPlayerRanking,
    SoccerTeamRanking,
    MatchEvent,
    MatchStats,
} from 'models'
import ConfigHelper from 'framework/helpers/config'
import {
    IMatchStats,
    IPlayerRanking,
    ISoccerContestant,
    ISoccerFixture,
    ISoccerSquadPerson,
    TeamRanking,
} from '@typings/opta'

import {
    MatchEventResponse,
    MatchStatsResponse,
    RankingResponse,
    ShortContestantResponse,
    TeamResponse,
} from '../responses'

export const soccerSquadPersonFactory = (personDetails: ISoccerSquadPerson) => {
    const posterServerUrl = ConfigHelper.getInstance().getConfig('opta').posterServerUrl
    personDetails.thumbnail = `${posterServerUrl}/poster/${personDetails.id}.png`

    return new SoccerSquadPerson(personDetails)
}

const soccerMatchEventFactory = (matchEvent: MatchEventResponse) => {
    // check if match end and end of period time is valid
    if (matchEvent.typeId === MatchEvent.EVENT_TYPE.endOfPeriod && matchEvent.timeMin === 0) {
        return undefined
    }

    // determine cardType 'red' || 'yellow' || null
    if (matchEvent.typeId === MatchEvent.EVENT_TYPE.card) {
        const matchQualifiers = matchEvent.qualifier || []
        if (
            matchQualifiers.find(({ qualifierId }) =>
                MatchEvent.QUALIFIER_TYPE.redCard.includes(qualifierId)
            )
        ) {
            matchEvent.cardType = 'red'
        } else if (
            matchQualifiers.find(({ qualifierId }) =>
                MatchEvent.QUALIFIER_TYPE.yellowCard.includes(qualifierId)
            )
        ) {
            matchEvent.cardType = 'yellow'
        }
    }

    matchEvent.timestamp = matchEvent.timeStamp

    return new MatchEvent(matchEvent)
}

function isDefined<T>(arg: T | undefined): arg is T {
    return arg !== undefined
}

export const soccerMatchEventsFactory = (matchEvents: MatchEventResponse[]) =>
    matchEvents.map((e) => soccerMatchEventFactory(e)).filter(isDefined)

export const soccerMatchStatsFactory = (matchStats: IMatchStats) => {
    const posterServerUrl = ConfigHelper.getInstance().getConfig('opta').posterServerUrl
    matchStats.teams = matchStats.teams.map((t) => ({
        ...t,
        logoUrl: posterServerUrl ? `${posterServerUrl}/poster/${t.teamId}.png` : undefined,
    }))
    return new MatchStats(matchStats)
}

export const soccerFixtureFactory = ({ matchInfo, liveData }: MatchStatsResponse) => {
    const { week, time, id } = matchInfo
    const newFixture: ISoccerFixture = {
        id,
        week,
        date: new Date(),
        time,
        contestant: matchInfo.contestant.map((team) =>
            soccerContestantFactory(team as ShortContestantResponse)
        ),
        title: `${(matchInfo.contestant[0] as ShortContestantResponse).shortName} - ${
            (matchInfo.contestant[1] as ShortContestantResponse).shortName
        }`,
        competitionName: matchInfo.competition.name,
    }

    if (liveData && liveData.matchDetails) {
        if (liveData.matchDetails.scores) {
            newFixture.scores = liveData.matchDetails.scores.total
        }
        if (liveData.matchDetails.matchStatus) {
            newFixture.matchStatus = liveData.matchDetails.matchStatus
        }
    }

    if (newFixture.time) {
        newFixture.date = new Date(matchInfo.date.replace('Z', 'T') + matchInfo.time)
    } else {
        newFixture.date = new Date(matchInfo.date.slice(0, -1))
    }

    return new SoccerFixture(newFixture)
}

export const soccerContestantFactory = (contestantDetails: ISoccerContestant) => {
    const posterServerUrl = ConfigHelper.getInstance().getConfig('opta').posterServerUrl
    contestantDetails.logoUrl = `${posterServerUrl}/poster/${contestantDetails.id}.png`
    return new SoccerContestant(contestantDetails)
}

type PlayerRanking = { clubName: string; players: IPlayerRanking[] }

export const soccerPlayersRanking = ({
    data,
    field = 'totalGoals',
    maxPlayerRanking = 20,
}: {
    data: RankingResponse
    field: 'totalAssists' | 'totalGoals'
    maxPlayerRanking: number
}) => {
    const posterServerUrl = ConfigHelper.getInstance().getConfig('opta').posterServerUrl
    let parsedRankingData: PlayerRanking[] = []

    if (has(data, 'team')) {
        parsedRankingData = data.team.reduce(
            (teamAcc: PlayerRanking[], teamVal: TeamResponse) => [
                ...teamAcc,
                {
                    clubName: teamVal.name,
                    players: teamVal.player.reduce(
                        (acc: IPlayerRanking[], val) => [
                            ...acc,
                            {
                                clubName: teamVal.name,
                                teamLogo: posterServerUrl
                                    ? `${posterServerUrl}/poster/${teamVal.id}.png`
                                    : undefined,
                                name: val.name,
                                totalAssists: has(
                                    val.stat.find((stat) => stat.type === 'total assists'),
                                    'value'
                                )
                                    ? Number(
                                          val.stat.find((stat) => stat.type === 'total assists')
                                              ?.value
                                      )
                                    : undefined,
                                totalGoals: has(
                                    val.stat.find((stat) => stat.type === 'total goals'),
                                    'value'
                                )
                                    ? Number(
                                          val.stat.find((stat) => stat.type === 'total goals')
                                              ?.value
                                      )
                                    : undefined,
                            },
                        ],
                        []
                    ),
                },
            ],
            []
        )
    }

    return orderBy(
        parsedRankingData.reduce<IPlayerRanking[]>(
            (acc, val) => [...acc, ...val.players.filter((i) => i[field])],
            []
        ),
        [field],
        ['desc']
    )
        .slice(0, maxPlayerRanking)
        .map((player) => new SoccerPlayerRanking(player))
}

export const soccerTournamentTeamRanking = ({ data = {} }) => {
    const posterServerUrl = ConfigHelper.getInstance().getConfig('opta').posterServerUrl
    const parsedTeamData: TeamRanking[] = has(data, ['stage', 0, 'division'])
        ? get(data, ['stage', 0, 'division']).find((i: { type: string }) => i.type === 'total')
              ?.ranking || []
        : []

    return orderBy(
        parsedTeamData.map(
            (team) =>
                new SoccerTeamRanking({
                    name: team.contestantClubName || '',
                    teamLogo: posterServerUrl
                        ? `${posterServerUrl}/poster/${team.contestantId}.png`
                        : undefined,
                    matchesPlayed: team.matchesPlayed || 0,
                    matchesWon: team.matchesWon || 0,
                    matchesDrawn: team.matchesDrawn || 0,
                    matchesLost: team.matchesLost || 0,
                    goalFor: team.goalsFor || 0,
                    goalAgainst: team.goalsAgainst || 0,
                    goaldifference: team.goaldifference || '0',
                    points: team.points || 0,
                    lastSix: team.lastSix || '------',
                })
        ),
        ['points'],
        ['desc']
    )
}
