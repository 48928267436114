import { SmartLib } from '@broadpeak/smartlib';

let isSmartLibInit = false
let streamingSession: any;

export const createSession = (broadpeakConfig: any, player: any) => {
    if(!broadpeakConfig.enable) {
        return {}
    }

    if(!isSmartLibInit) {
        SmartLib.getInstance().init(broadpeakConfig.analyticsAddress, '', broadpeakConfig.broadpeakDomainNames);
        isSmartLibInit = true;
    }

    streamingSession = SmartLib.getInstance().createStreamingSession()

    streamingSession.attachPlayer(player)

    return streamingSession;
}

export const getSessionUrl = async (broadpeakConfig: any, source: any) => {
    if(!broadpeakConfig.enable) {
        return source;
    }

    const result = await streamingSession.getURL(source)
    
    if(result.url) {
        return result.url
    }else {
        stopStreamingSession()

        return null;
    }
}

export const isSessionRunning = () => Boolean(streamingSession)

export const stopStreamingSession = () => {
    if (!streamingSession) {
        return null
    }

    streamingSession.stopStreamingSession()
    streamingSession = null
}
