export const foucsOnElement = (el: any): void => {
    el?.scrollIntoView({ block: 'center', inline: 'nearest'});
};

export const focusOnElementEnd = (el: any): void => {
    el?.scrollIntoView({ block: 'end', inline: 'start'});
};

export const focusOnElementStart = (el: any): void => {
    el?.scrollIntoView({ block: 'start', inline: 'start'});
};