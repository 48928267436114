import Fetch from '../fetch'
import { of } from 'rxjs'
import { catchError, mergeMap } from 'rxjs/operators'
import DataHelper from 'framework/helpers/data'
import HuaweiTypes from '../HuaweiTypes'
import { CachedIdsListHelper } from 'framework/helpers/cacheIdList'

const DEFAULT_PAGE_SIZE = 50

export default class UserPreferencesApi extends Fetch {
    constructor(config, otherApis = {}) {
        super(config, otherApis)
        this.favoriteRecordingsHelper = new CachedIdsListHelper(
            CachedIdsListHelper.LISTS_NAMES.FAVORITE_RECORDINGS
        )
    }

    // #region ********** FAVORITES **********
    /**
     * Add a content to favorites
     *
     * @param {Object} args
     * @param {string} args.contentId content id
     * @param {string} args.contentType content type, one of {@link HuaweiTypes.contentTypes}
     * @returns {Observable}
     */
    addContentToFavorites = ({ contentId, contentType, catalogId } = {}) => {
        return this.fetch({
            url: `${DataHelper.getInstance().getData(
                DataHelper.STORE_KEY.BACKEND_API_URL
            )}/VSP/V3/CreateFavorite`,
            method: 'POST',
            body: {
                favorites: [
                    {
                        contentID: contentId,
                        contentType: contentType,
                        catalogID: catalogId,
                    },
                ],
            },
            log: `ADD CONTENT TO FAVORITES`,
        }).pipe(mergeMap(({ response } = {}) => of(response)))
    }

    /**
     * Remove content from favorites
     *
     * @param {Object} args
     * @param {Array<string>} args.contentIds content ids to remove from favorites
     * @returns {Observable}
     */
    removeContentFromFavorites = ({ contentIds, contentTypes, catalogId } = {}) => {
        return this.fetch({
            url: `${DataHelper.getInstance().getData(
                DataHelper.STORE_KEY.BACKEND_API_URL
            )}/VSP/V3/DeleteFavorite`,
            method: 'POST',
            body: {
                contentIDs: contentIds,
                contentTypes,
                catalogID: catalogId,
            },
            log: `REMOVE CONTENT FROM FAVORITES`,
        }).pipe(mergeMap(({ response } = {}) => of(response)))
    }

    /**
     * Retrieve all favorites
     *
     * @param {Object} args
     * @param {number} args.count number of records obtained in one query
     * @param {number} args.offset start position for the query
     * @param {Array<string>} args.contentTypes type of content to be queried in favorites categories
     * @param {string} args.catalogId ID of a favorites folder in which content is to be queried
     * @returns {Observable<Array>}
     */
    getAllFavorites = ({ count = DEFAULT_PAGE_SIZE, offset = 0, contentTypes, catalogId } = {}) => {
        return this.recursiveFetch(
            (_, count, offset) => {
                return this.fetch({
                    url: `${DataHelper.getInstance().getData(
                        DataHelper.STORE_KEY.BACKEND_API_URL
                    )}/VSP/V3/QueryFavorite`,
                    method: 'POST',
                    body: {
                        count,
                        sortType: HuaweiTypes.favoritesSortOptions.SORT_NO_ASC,
                        offset,
                        contentTypes,
                        catalogID: catalogId,
                    },
                    log: `GET ALL FAVORITES`,
                })
            },
            { count, offset, responseResource: 'favorites' }
        ).pipe(mergeMap((favorites) => of(favorites)))
    }
    // #endregion

    // #region ********** BOOKMARKS **********
    /**
     * Create a new bookmark
     *
     * @param {Object} args
     * @param {string} args.itemId id of bookmarked content
     * @param {string} args.bookmarkType bookmark type
     * @param {number} args.rangeTime bookmark time, namely, number of seconds between the playback exit time and program start
     * @returns {Observable}
     */
    createBookmark = ({ itemId, bookmarkType, rangeTime } = {}) => {
        return this.fetch({
            url: `${DataHelper.getInstance().getData(
                DataHelper.STORE_KEY.BACKEND_API_URL
            )}/VSP/V3/CreateBookmark`,
            method: 'POST',
            body: {
                bookmarks: [
                    {
                        itemID: itemId,
                        bookmarkType,
                        rangeTime,
                    },
                ],
            },
            log: `CREATE A NEW BOOKMARK`,
        }).pipe(mergeMap(({ response } = {}) => of(response)))
    }

    /**
     * Delete a bookmark
     *
     * @param {Object} args
     * @param {Array<string>} args.bookmarkTypes bookmark type
     * @param {Array<string>} args.itemIds ids of bookmarked content
     * @returns {Observable}
     */
    deleteBookmark = ({ bookmarkTypes, itemIds } = {}) => {
        return this.fetch({
            url: `${DataHelper.getInstance().getData(
                DataHelper.STORE_KEY.BACKEND_API_URL
            )}/VSP/V3/DeleteBookmark`,
            method: 'POST',
            body: {
                bookmarkTypes,
                itemIDs: itemIds,
            },
            log: `DELETE A BOOKMARK`,
        }).pipe(mergeMap(({ response } = {}) => of(response)))
    }

    /**
     * Retrieve all bookmarks
     *
     * @param {Object} args
     * @param {number} args.count number of records obtained in one query
     * @param {number} args.offset start position for the query
     * @returns {Observable<Array>}
     */
    getAllBookmarks = ({ count = DEFAULT_PAGE_SIZE, offset = 0 } = {}) => {
        return this.recursiveFetch(
            (_, count, offset) => {
                return this.fetch({
                    url: `${DataHelper.getInstance().getData(
                        DataHelper.STORE_KEY.BACKEND_API_URL
                    )}/VSP/V3/QueryBookmark`,
                    method: 'POST',
                    body: {
                        count,
                        sortType: HuaweiTypes.bookmarksSortOptions.UPDATE_TIME_DESC,
                        offset,
                    },
                    log: `GET ALL BOOKMARKS`,
                })
            },
            { count, offset, responseResource: 'bookmarks' }
        ).pipe(mergeMap((bookmarks) => of(bookmarks)))
    }
    // #endregion

    // #region ********** REMINDERS **********
    /**
     * Create a reminder
     * @param {Object} args
     * @param {string} args.contentId content id
     * @param {'PROGRAM'|'VOD'} args.contentType content type
     * @param {number} args.reminderTime Reminder time. For a channel program, the value is the total number of milliseconds since 1970-01-01 00:00:00. For a VOD program, the value is a timestamp in yyyyMMddHHmmss format
     * @returns {Observable}
     */
    createReminder = ({ contentId, contentType, reminderTime } = {}) => {
        return this.fetch({
            url: `${DataHelper.getInstance().getData(
                DataHelper.STORE_KEY.BACKEND_API_URL
            )}/VSP/V3/CreateReminder`,
            method: 'POST',
            body: {
                reminders: [
                    {
                        contentID: contentId,
                        contentType,
                        reminderTime,
                    },
                ],
            },
            log: `CREATE A NEW REMINDER`,
        }).pipe(mergeMap(({ response } = {}) => of(response)))
    }

    /**
     * Delete a reminder
     *
     * @param {Object} args
     * @param {Array<string>} args.contentIds content id of the reminder to be deleted
     * @param {Array<string>} args.contentTypes content type of the reminder to be deleted
     * @returns {Observable}
     */
    deleteReminder = ({ contentIds, contentTypes } = {}) => {
        return this.fetch({
            url: `${DataHelper.getInstance().getData(
                DataHelper.STORE_KEY.BACKEND_API_URL
            )}/VSP/V3/DeleteReminder`,
            method: 'POST',
            body: {
                contentIDs: contentIds,
                contentTypes,
            },
            log: `DELETE A REMINDER`,
        }).pipe(mergeMap(({ response } = {}) => of(response)))
    }

    /**
     * Retrieve all reminders
     *
     * @param {Object} args
     * @param {number} args.count number of records obtained in one query
     * @param {number} args.offset start position for the query
     * @returns {Observable<Array>}
     */
    getAllReminders = ({ count = DEFAULT_PAGE_SIZE, offset = 0 } = {}) => {
        return this.recursiveFetch(
            (_, count, offset) => {
                return this.fetch({
                    url: `${DataHelper.getInstance().getData(
                        DataHelper.STORE_KEY.BACKEND_API_URL
                    )}/VSP/V3/QueryReminder`,
                    method: 'POST',
                    body: {
                        count,
                        offset,
                    },
                    log: `GET ALL REMINDERS`,
                })
            },
            { count, offset, responseResource: 'reminders' }
        ).pipe(mergeMap((reminders) => of(reminders)))
    }
    // #endregion

    // #region ********** RECORDINGS **********
    /**
     * Adds recording to the local storage favorites
     *
     * @param {string} recordingId The id of the recording to be added
     * @returns {Observable<boolean>}
     */
    addRecordingToFavorites = (recordingId) => {
        return this.favoriteRecordingsHelper.addIdToList(recordingId).pipe(
            mergeMap(() => of(true)),
            catchError(() => of(false))
        )
    }

    /**
     * Retrieve all recordings from the local storage favorites
     *
     * @returns {Observable<Array>}
     */
    getAllRecordings = () => {
        try {
            return of(this.favoriteRecordingsHelper.getIdsFromList())
        } catch {
            return of([])
        }
    }

    /**
     * Deletes a recording from the local storage favorites by its id
     *
     * @param {string} recordingId The id of the recording to be removed
     * @returns {Observable<boolean>}
     */
    deleteFavoriteRecording = (recordingId) => {
        return this.favoriteRecordingsHelper.removeIdFromList(recordingId).pipe(
            mergeMap(() => of(true)),
            catchError(() => of(false))
        )
    }
    // #endregion

    // #region ********** FAVORITE CATALOGS **********

    /**
     * Create favorite catalog with a specified name
     *
     * @param {string} name catalog name
     * @returns {Observable}
     */
    createFavoriteCatalog = (name) =>
        this.fetch({
            url: `${DataHelper.getInstance().getData(
                DataHelper.STORE_KEY.BACKEND_API_URL
            )}/VSP/V3/AddFavoCatalog`,
            method: 'POST',
            body: {
                catalog: {
                    catalogName: name,
                },
            },
            log: `CREATE FAVORITE CATALOG ${name}`,
        }).pipe(mergeMap(({ response } = {}) => of(response)))

    /**
     *  Retrieve all favorite catalogs
     *
     * @param {Object} options request options
     * @param {Object} options.count limit of items per request
     * @param {Object} options.offset start position for the query
     * @returns
     */
    getFavoriteCatalogs = ({ count = 15, offset = 0 } = {}) =>
        this.fetch({
            url: `${DataHelper.getInstance().getData(
                DataHelper.STORE_KEY.BACKEND_API_URL
            )}/VSP/V3/QueryFavoCatalog`,
            method: 'POST',
            body: {
                count,
                offset,
            },
            log: `GET FAVORITE CATALOGS`,
        }).pipe(mergeMap(({ response } = {}) => of(response)))

    /**
     * Retrieve restrictions catalog id by its name
     *
     * @param {string} name catalog name
     * @returns {Observable<string>}
     */
    getFavoriteCatalogIdByName = (name) => {
        const catalogsListHelper = new CachedIdsListHelper(name)
        const catalogId = catalogsListHelper.getIdsFromList()[0]
        if (catalogId) return of(catalogId)

        return this.getFavoriteCatalogs().pipe(
            mergeMap(({ catalogs = [] }) => {
                const matchingCatalog = catalogs.filter(({ catalogName }) => catalogName === name)

                if (!matchingCatalog.length) {
                    return this.createFavoriteCatalog(name).pipe(
                        mergeMap(({ catalogID }) => {
                            catalogsListHelper.addIdToList(catalogID)
                            return of(catalogID)
                        })
                    )
                }

                catalogsListHelper.addIdToList(matchingCatalog[0].catalogID)
                return of(matchingCatalog[0].catalogID)
            })
        )
    }
    // #endregion

    // #region ********** LOCKS **********
    // !warning!
    // Here is some trick: we are using favorites api to store locked content.
    // It was done this way to avoid backend restrictions in case if playbacks won't be available
    // for restricted on the backend side content

    /**
     * Create a lock
     *
     * @param {Object} itemOptions data of item to lock
     * @param {string} itemOptions.contentId item id
     * @param {string} itemOptions.contentType item content type
     * @param {string} catalogName name of the catalog for restricted content
     * @returns {Observable<boolean>}
     */
    setLock = ({ contentId, contentType }, catalogName) =>
        this.getFavoriteCatalogIdByName(catalogName).pipe(
            mergeMap((catalogId) => {
                return this.addContentToFavorites({ contentId, contentType, catalogId })
            })
        )

    /**
     * Delete a lock
     *
     * @param {Object} itemOptions data of item to lock
     * @param {string} itemOptions.contentId item id
     * @param {string} itemOptions.contentType item content type
     * @param {string} catalogName name of the catalog for restricted content
     * @returns {Observable}
     */
    deleteLock = ({ contentId, contentType }, catalogName) =>
        this.getFavoriteCatalogIdByName(catalogName).pipe(
            mergeMap((catalogId) => {
                return this.removeContentFromFavorites({
                    contentIds: contentId,
                    contentTypes: contentType,
                    catalogId,
                })
            })
        )

    /**
     * Retrieve all locks
     *
     * @param {string} catalogName name of the catalog for restricted content
     * @param {HuaweiTypes.contentTypes} contentType item content type
     * @returns {Observable}
     */
    getLocks = (catalogName, contentType) =>
        this.getFavoriteCatalogIdByName(catalogName).pipe(
            mergeMap((catalogId) => {
                return this.getAllFavorites({ catalogId, contentTypes: contentType }).pipe(
                    mergeMap((favorites) => of(favorites))
                )
            })
        )
    // #endregion
}
