import { IVirtualChannel } from '@typings/tile'
import { Channel } from './Channel'
import { EmiRequest } from './EmiRequest'

/**
 * A VirtualChannel
 * @property {String} imgFullscreen path of the image ti be sidplayed as a background in player
 * @property {Boolean} [includeChannelInEPGGrid] show ths channel in EPG Grid
 * @property {Number} [epgProgramCountInLiveBanner] how many programs should be displayed in live banner
 * @property {Number} [epgProgramCountInGrid] how many programs should be displayed in epg grid
 * @property {Boolean} [zappingFromChannelList] when pressing ok in channel list, should it open app or zapp to channel
 * @property {String} [videoUrl] video to be played, if empty imgFullscreen is displayed
 * @property {EmiRequests} [requests] list of request to be used to retrieve associated contents
 */

export class VirtualChannel extends Channel {
    imgFullscreen: string
    includeChannelInEPGGrid: boolean
    epgProgramCountInLiveBanner: number
    epgProgramCountInGrid: number
    zappingFromChannelList: boolean
    videoUrl: string
    requests: EmiRequest[]

    constructor(props: IVirtualChannel) {
        super(props)

        this.imgFullscreen = props.imgFullscreen

        const computePropValue = this._makeComputePropValue(props)

        this.includeChannelInEPGGrid = computePropValue('includeChannelInEPGGrid', false)
        this.epgProgramCountInLiveBanner = computePropValue('epgProgramCountInLiveBanner', 0)
        this.epgProgramCountInGrid = computePropValue('epgProgramCountInGrid', 0)
        this.zappingFromChannelList = computePropValue('zappingFromChannelList', false)
        this.videoUrl = computePropValue('videoUrl', '')
        this.requests = computePropValue('requests', [])
    }

    /**
     * @return {Boolean}
     */
    isVirtualChannel() {
        return true
    }
}
