import { ContentNode } from './ContentNode'

/**
 * A SearchContentNode is a contentNode
 */
export class SearchContentNode extends ContentNode {
    /** Checks if the Node is a SearchContentNode (always `true`) */
    isSearchContentNode() {
        return true
    }
}
