import {Toast, ToastPosition, ToastProps, ToastType} from './Toast'
import {ToastContext} from './ToastContext'
import {createSignal, For} from 'solid-js'

export interface ToastProviderProps {
    toastPosition?: ToastPosition
    children: any
}

export const ToastProvider = (props: ToastProviderProps) => {
    const [autoClosed, setAutoClosed] = createSignal<boolean>(false)
    const [toastDetails, setToastDetails] = createSignal<ToastProps[]>([])
    const [toastsMarkedForDeletion, setToastsMarkedForDeletion] = createSignal<number[]>([])

    const autoCloseToast = (id: number, life: number = 5000, isReminder: boolean) => {
        setTimeout(() => {
            onToastClose(id)
            if(isReminder) {
                setAutoClosed(true)
            }
        }, life)
    }

    const showToast = (toast: ToastProps | ToastProps[]) => {
        if (Array.isArray(toast)) {
            const newToasts: ToastProps[] = toast.map((t: ToastProps) => {
                const id = new Date().getTime()
                !t.sticky && autoCloseToast(id, t.life, false)
                return {...t, description: t.description, id}
            })

            setToastDetails([...toastDetails(), ...newToasts])
        } else {
            const id = new Date().getTime()
            !toast.sticky && autoCloseToast(id, toast.life, toast?.type === ToastType.REMINDER)
            setToastDetails([...toastDetails(), {...toast, description: toast.description, id}])
        }
    }

    const onToastClose = (id: number) => {
        setToastsMarkedForDeletion([...toastsMarkedForDeletion(), id])
        setTimeout(() => {
            const toasts = [...toastDetails()]
            toasts.splice(
                toastDetails().findIndex(t => t.id === id),
                1
            )
            setToastDetails(toasts)

            const toastDeletion = [...toastsMarkedForDeletion()]
            toastDeletion.splice(
                toastDeletion.findIndex(t => t === id),
                1
            )
            setToastsMarkedForDeletion(toastDeletion)
        }, 300)
    }

    return (
        <ToastContext.Provider value={{show: showToast}}>
            {props.children}
            <For each={toastDetails()}>
                {(toast: ToastProps, index) => {
                    return (
                        <Toast
                            {...toast}
                            position={props.toastPosition}
                            index={index()}
                            className={toastsMarkedForDeletion().includes(toast.id) ? 'deletion' : ''}
                            onClose={() => onToastClose(toast.id)}
                            autoClosed={autoClosed()}
                            setAutoClosed={setAutoClosed}
                        />
                    )
                }}
            </For>
        </ToastContext.Provider>
    )
}

export interface ToastProviderBehavior {
    show: (toast: ToastProps) => any
}
