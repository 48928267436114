import Fetch from '../fetch'
import { of } from 'rxjs'
import HuaweiTypes from '../HuaweiTypes'
import { mergeMap } from 'rxjs/operators'

/**
 * Deal with Favourites action (add/remove/get)
 */
export default class FavouritesApi extends Fetch {
    constructor(config, otherApis = {}) {
        super(config, otherApis)
        this.userPreferencesApi = otherApis.userPreferencesApi
    }
    /**
     * @param {Object} program
     * @param {String} program.id
     * @param {Boolean} program.isSeries
     * @return {Observable}
     */
    addFavouriteVOD({ id }) {
        return this.userPreferencesApi
            .addContentToFavorites({
                contentId: id,
                contentType: HuaweiTypes.contentTypes.VOD,
            })
            .pipe(mergeMap(() => of(true)))
    }

    /**
     *
     * @param {Object} program
     * @param {String} program.id
     * @param {Boolean} program.isSeries
     * @return {Observable}
     */
    removeFavouriteVOD({ id }) {
        return this.userPreferencesApi.removeContentFromFavorites({
            contentIds: [id],
            contentTypes: [HuaweiTypes.contentTypes.VOD],
        })
    }

    /**
     * Get All favourites (both Movies/Titles and Series)
     *
     * @return {Observable<Array<String>>} result list of program IDs
     */
    getAllVODs() {
        return this.userPreferencesApi
            .getAllFavorites({
                contentTypes: [HuaweiTypes.contentTypes.VOD],
            })
            .pipe(
                mergeMap((favoriteVods = []) => {
                    if (!favoriteVods.length) return of([])
                    return of(
                        favoriteVods
                            .map((favorite) => favorite && favorite.VOD && favorite.VOD.ID)
                            .filter(Boolean)
                    )
                })
            )
    }
    /**
     * Recursively fetches all favorite recordings
     *
     * @param {Object} params
     * @param {Number} [params.limit=20] The maximum number of results per fetch
     * @returns {Observable<Array<Dvr>>}
     */
    getFavoriteRecordings({ limit = 20 } = {}) {
        return this.userPreferencesApi.getAllRecordings()
    }

    /**
     * Adds a recording to favourites
     *
     * @param {Object} params
     * @param {Boolean} params.id Recording ID
     * @returns {Observable}
     */
    addFavoriteRecording({ id }) {
        return this.userPreferencesApi.addRecordingToFavorites(id)
    }

    /**
     * Removes a recording from favourites
     *
     * @param {Object} params
     * @param {Boolean} params.id Recording ID
     * @returns {Observable}
     */
    deleteFavoriteRecording({ id }) {
        return this.userPreferencesApi.deleteFavoriteRecording(id)
    }
}
