import ConfigHelper from 'framework/helpers/config'
import { of, throwError } from 'rxjs'
import { catchError, mergeMap } from 'rxjs/operators'
import VoltError from 'VoltError'
import { userWalletFactory } from '../Factories'
import Fetch from '../fetch'
import {
    extractPhoneNumber,
    getHeaders,
    isServiceConfigured,
    obfuscatePhoneNumber,
} from '../Helpers'
import DataHelper from 'framework/helpers/data'
import Constants from 'api-constants'

export default class GatewayApi extends Fetch {
    constructor(config, otherApis = {}) {
        super(config, otherApis)
        this.apiToken = ''
        this.apiTokenExpiration = 0
        this.otpToken = ''
        this.otpTokenExpiration = 0

        this.lmsEnvironment = ConfigHelper.getInstance().getConfig('ooredoo').lmsEnvironment
        this.apigeeUrl = ConfigHelper.getInstance().getConfig('urls').apigeeUrl
        this.userPhoneNumber = ''

        this.userIp = '192.168.1.1'
    }

    static RESERVATION_OPERATION = {
        confirm: 'ConfirmReservation',
        cancel: 'CancelReservation',
    }

    //#region SETTERS/GETTERS
    get apiToken() {
        const isCachedTokenValid = !!this._apiToken && this.apiTokenExpiration >= Date.now()
        if (isCachedTokenValid) return this._apiToken
        return ''
    }

    get otpToken() {
        const isCachedTokenValid = !!this._otpToken && this.otpTokenExpiration >= Date.now()
        if (isCachedTokenValid) return this._otpToken
        return ''
    }

    set apiToken(token) {
        this._apiToken = token
    }

    set otpToken(token) {
        this._otpToken = token
    }

    set apiTokenExpiration(value) {
        if (typeof value === 'string') {
            this._apiTokenExpiration = Number(value)
        } else {
            this._apiTokenExpiration = value
        }
    }

    set otpTokenExpiration(value) {
        if (typeof value === 'string') {
            this._otpTokenExpiration = Number(value)
        } else {
            this._otpTokenExpiration = value
        }
    }

    get apiTokenExpiration() {
        return this._apiTokenExpiration
    }

    get otpTokenExpiration() {
        return this._otpTokenExpiration
    }
    //#endregion

    //#region TOKEN FLOW
    /**
     * Generate access token for `Access-Token` header
     *
     * @param {Object} options login options
     * @param {string} options.username user id
     * @param {string} options.password user password
     * @returns Observable{string} access token
     */
    getAccessToken({ username, password } = {}) {
        const cachedToken = this.apiToken
        if (cachedToken) return of(cachedToken)

        return this.fetch({
            url: `${this.apigeeUrl}/mcrosrvc/v2/iptv/authenticate`,
            method: 'POST',
            headers: getHeaders(),
            retrieveResponseHeaders: true,
            body: {
                userId: username,
                password,
            },
            log: 'GENERATE ACCESS TOKEN',
        }).pipe(
            mergeMap(({ headers }) => {
                const token = headers['access-token']
                const tokenExpiration = headers['expiry_timestamp']

                if (!token)
                    return throwError(
                        new VoltError(VoltError.codes.TOKEN_RETRIEVAL_ERROR, {
                            extraLog: 'Missing API GEE access token',
                            backend: this.backendName,
                        })
                    )

                this.apiToken = token
                this.apiTokenExpiration = tokenExpiration

                return of(token)
            })
        )
    }
    //#endregion TOKEN FLOW

    //#region LOGIN FLOW
    /**
     * Login by user's phone  number
     *
     * @param {string} phoneNumber user's phone number
     * @returns Observable{boolean}
     */
    getIPTVAccountByPhoneNumber({ phoneNumber } = {}) {
        if (!isServiceConfigured()) {
            return throwError(
                new VoltError(VoltError.codes.SERVICE_NOT_AVAILABLE, {
                    extraLog: 'The service is not configured',
                    backend: this.backendName,
                })
            )
        }

        const cachedOtpToken = this.otpToken
        if (!cachedOtpToken)
            return throwError(
                new VoltError(VoltError.codes.INVALID_AUTH_TOKEN, {
                    extraLog: 'Missing Token, cannot call the API getaccountsbymsisdn',
                    backend: this.backendName,
                })
            )

        return this.fetch({
            url: `${this.apigeeUrl}/mcrosrvc/iptv/accounts/getaccountsbymsisdn`,
            method: 'POST',
            headers: { ...getHeaders(), 'Access-Token': cachedOtpToken },
            body: {
                serviceNumber: phoneNumber,
            },
            log: 'GET ACCOUNTS BY MSIDN',
        }).pipe(mergeMap(({ response } = {}) => of(this._getAccountNumber(response))))
    }

    /**
     * Alternative method to login by username and password
     *
     * @returns Observable{boolean}
     */
    getIPTVAccountByUsernameAndPassword({ username, password } = {}) {
        if (!isServiceConfigured()) {
            return throwError(
                new VoltError(VoltError.codes.SERVICE_NOT_AVAILABLE, {
                    extraLog: 'The service is not configured',
                    backend: this.backendName,
                })
            )
        }

        return this.getAccessToken({ username, password }).pipe(
            mergeMap((token) => {
                return this.fetch({
                    url: `${this.apigeeUrl}/mcrosrvc/iptv/accounts/getaccountsbyqid`,
                    method: 'POST',
                    headers: { ...getHeaders(), 'Access-Token': token },
                    body: {},
                    log: 'GET ACCOUNTS BY QID',
                }).pipe(mergeMap(({ response } = {}) => of(this._getAccountNumber(response))))
            })
        )
    }

    /**
     * Parse response object from `getaccountsbyqid` or `getaccountsbymsisdn` and retrieve first
     * number from the numbers list
     *
     * @param {Object} response response object from `getaccountsbyqid` or `getaccountsbymsisdn`
     * @returns {VoltError|Observable<string>}
     */
    _getAccountNumber(response = {}) {
        if (!response)
            return throwError(
                new VoltError(VoltError.codes.LOGIN_FAILED, {
                    extraLog: 'Missing phone number',
                    backend: this.backendName,
                })
            )

        const accounts = response.accountNumberList || response.listOfAccNumbers
        if (!accounts || !accounts.length)
            return throwError(
                new VoltError(VoltError.codes.NO_ACCOUNT_IDS_FOR_CURRENT_USER, {
                    extraLog: 'Missing Account ID',
                    backend: this.backendName,
                })
            )

        // According to Ooredoo process we should not offer selection of accounts to a user
        // so we take the first one
        return accounts[0].accountNumber
    }
    //#endregion

    //#region OTP FLOW
    /**
     * Send OTP to a user
     * @param {Object} [options]
     * @param {String} [options.phoneNumber] Defined for the login only, otherwise an api is used to deduct the phone number before sending OTP
     * @returns {Observable<boolean>}
     */
    sendOTP({ phoneNumber } = {}) {
        if (!isServiceConfigured()) {
            return throwError(
                new VoltError(VoltError.codes.SERVICE_NOT_AVAILABLE, {
                    extraLog: 'The service is not configured',
                    backend: this.backendName,
                })
            )
        }

        return (phoneNumber ? of(phoneNumber) : this.getUserPhoneNumber()).pipe(
            mergeMap((serviceNumber) => {
                if (!serviceNumber) {
                    return throwError(
                        new VoltError(VoltError.codes.ACCOUNT_NUMBER_RETRIEVING_ERROR, {
                            extraLog: 'No phone number associated to the account',
                            backend: this.backendName,
                        })
                    )
                }

                return this.fetch({
                    url: `${this.apigeeUrl}/mcrosrvc/v2/generateotpbyserviceno`,
                    method: 'POST',
                    headers: getHeaders(),
                    body: {
                        serviceNumber,
                    },
                    log: 'GENERATE OTP',
                }).pipe(
                    mergeMap(({ response }) => {
                        if (!response) {
                            this.logger.error('Gateway token generation error...')
                            return throwError(
                                new VoltError(VoltError.codes.PAYMENT_TOKEN_ERROR, {
                                    extraLog: 'Cannot send the OTP',
                                    backend: this.backendName,
                                })
                            )
                        }
                        const { otpTimeoutSecs = 59 } =
                            ConfigHelper.getInstance().getConfig('ooredoo')
                        return of({
                            displayedPhoneNumber: obfuscatePhoneNumber(serviceNumber),
                            otpTimeoutSecs,
                        })
                    }),
                    catchError((error) => {
                        const { debugValidateOTP } = ConfigHelper.getInstance().getConfig('ooredoo')
                        if (debugValidateOTP) {
                            this.logger.warn(
                                `DEBUG MODE --> This should not be production build. Error while trying to generate an OTP, but force debug OTP ${debugValidateOTP}`
                            )
                            return of({
                                displayedPhoneNumber: '0123456789',
                            })
                        }

                        return throwError(
                            new VoltError(
                                [
                                    VoltError.codes.UNKNOWN_API_ERROR.code,
                                    VoltError.codes.UNKNOWN_ERROR.code,
                                ].includes(error.code)
                                    ? VoltError.codes.OTP_GENERATION_FAILED
                                    : error,
                                {
                                    inheritedError: error,
                                }
                            )
                        )
                    })
                )
            })
        )
    }

    /**
     * Validates user's service number and pin
     *
     * @param {Object} options
     * @param {string} options.code user's one time password
     * @param {string} [options.phoneNumber] user's phone number
     * @returns {Observable<boolean>}
     */
    validateOTP({ code, phoneNumber } = {}) {
        if (!isServiceConfigured()) {
            return throwError(
                new VoltError(VoltError.codes.SERVICE_NOT_AVAILABLE, {
                    extraLog: 'The service is not configured',
                    backend: this.backendName,
                })
            )
        }

        const { debugValidateOTP } = ConfigHelper.getInstance().getConfig('ooredoo')
        if (debugValidateOTP) {
            this.logger.warn(
                `DEBUG MODE --> This should not be production build. Internal Hack of OTP enalbed with the following code ${debugValidateOTP}`
            )
            if (code === debugValidateOTP) {
                return of(true)
            }
        }

        return (phoneNumber ? of(phoneNumber) : this.getUserPhoneNumber()).pipe(
            mergeMap((serviceNumber) => {
                if (!serviceNumber) {
                    return throwError(
                        new VoltError(VoltError.codes.ACCOUNT_NUMBER_RETRIEVING_ERROR, {
                            extraLog: 'User phone number is missing, cannot validate the OTP',
                            backend: this.backendName,
                        })
                    )
                }

                return this.fetch({
                    url: `${this.apigeeUrl}/mcrosrvc/v2/validateotpbyserviceno`,
                    method: 'POST',
                    headers: getHeaders(),
                    retrieveResponseHeaders: true,
                    body: {
                        serviceNumber,
                        pin: code,
                    },
                    log: 'VALIDATE OTP',
                }).pipe(
                    mergeMap(({ response, headers }) => {
                        if (!response) {
                            this.logger.error('OTP verification error...')
                            return of(false)
                        }

                        const token = headers['access-token']
                        const tokenExpiration = headers['expiry_timestamp']

                        this.otpToken = token
                        this.otpTokenExpiration = tokenExpiration

                        return of(true)
                    }),
                    catchError((error) => {
                        return throwError(
                            new VoltError(
                                error.code === VoltError.codes.UNKNOWN_API_ERROR.code
                                    ? VoltError.codes.OTP_VALIDATION_FAILED
                                    : error,
                                {
                                    inheritedError: error,
                                }
                            )
                        )
                    })
                )
            })
        )
    }
    //#endregion

    /**
     * Method fot getting related to iptv number phone number
     *
     * ! There is a trick here. Due to APIGEE implementation we getting a phone number by using
     * the same API as for getting user's wallet data in `getUserWallet()`. By the difference
     * with account types: ONG or not, we getting different result in `ngcreditsinquiry`.
     * @returns {Observable<string>}
     */
    getUserPhoneNumber() {
        const cachedUserPhoneNumber = this.userPhoneNumber
        if (cachedUserPhoneNumber) return of(cachedUserPhoneNumber)

        return this.fetch({
            url: `${this.apigeeUrl}/mcrosrvc/iptv/ngcreditsinquiry`,
            method: 'POST',
            headers: getHeaders(),
            body: {
                iptvAccountNumber: this._getIptvAccountNumber(),
                offset: '0',
                limit: '10',
                sourceIPAddress: this.userIp,
                lmsEnvironment: this.lmsEnvironment,
            },
            log: 'GET USER PHONE NUMBER',
        }).pipe(
            mergeMap(({ response }) => {
                if (!response) {
                    return throwError(new Error('Get user phone number response is empty'))
                }

                const phoneNumber = extractPhoneNumber(response)
                if (!phoneNumber) {
                    return throwError(
                        new VoltError(VoltError.codes.ACCOUNT_NUMBER_RETRIEVING_ERROR, {
                            extraLog: 'User phone number is missing, cannot validate the OTP',
                            backend: this.backendName,
                        })
                    )
                }

                this.userPhoneNumber = phoneNumber

                return of(this.userPhoneNumber)
            }),
            catchError((err) => {
                // It's a tricky part. As Ooredoo said sometimes when we try to retrieve
                // user's phone number and some error occurred it can contain a phone number
                const phoneNumber =
                    err && err.httpResponseBody && extractPhoneNumber(err.httpResponseBody)
                if (phoneNumber) {
                    this.logger.error(
                        'Error while retrieving user phone number but able to retrieve the Phone number from error response'
                    )

                    this.userPhoneNumber = phoneNumber

                    return of(this.userPhoneNumber)
                }

                this.logger.error('Error while retrieving user phone number: ', err)
                return of('')
            })
        )
    }

    /**
     * Get user's wallet data: available credits, spent credits and all related data
     *
     * @returns {Observable<object>}
     */
    getUserWallet() {
        if (!isServiceConfigured()) {
            this.logger.error('The service is not configured yet')
            return of({})
        }

        return this.fetch({
            url: `${this.apigeeUrl}/mcrosrvc/iptv/ngcreditsinquiry`,
            method: 'POST',
            headers: getHeaders(),
            body: {
                iptvAccountNumber: this._getIptvAccountNumber(),
                offset: '0',
                limit: '10',
                sourceIPAddress: this.userIp,
                lmsEnvironment: this.lmsEnvironment,
            },
            log: 'GET USER CREDITS',
        }).pipe(
            mergeMap(({ response }) => {
                if (!response) {
                    this.logger.error('Error while retrieve user credits')
                    return of({})
                }
                return of(userWalletFactory(response.Data.CreditSummary))
            }),
            catchError(() => of({}))
        )
    }

    /**
     * Subscribe on product by its id
     *
     * @param {string} productId product's id to subscribe
     * @returns {Observable<boolean>}
     */
    subscribe({ productId } = {}) {
        if (!isServiceConfigured()) {
            return throwError(
                new VoltError(VoltError.codes.SERVICE_NOT_AVAILABLE, {
                    extraLog: 'The service is not configured',
                    backend: this.backendName,
                })
            )
        }

        const cachedOtpToken = this.otpToken
        if (!cachedOtpToken)
            return throwError(
                new VoltError(VoltError.codes.PAYMENT_TOKEN_ERROR, {
                    extraLog: 'Missing API GEE access token',
                    backend: this.backendName,
                })
            )

        let body = {
            landLineNumber: this.userPhoneNumber,
            iptvAccountNumber: this._getIptvAccountNumber(),
            bmsProductId: productId,
            sourceIPAddress: this.userIp,
            lmsEnvironment: this.lmsEnvironment,
        }

        const serialNo = DataHelper.getInstance().getData(DataHelper.STORE_KEY.SERIAL_NUMBER)
        if (
            this.config.platform === Constants.platform.androidTvStb &&
            serialNo &&
            serialNo !== 'unknown'
        ) {
            body = {
                ...body,
                stbNumber: serialNo,
            }
        } else {
            this.logger.warn(
                `Call NGSubscribe API without Serial Number, Please ensure you are using a real STB with appropriate FW and permission or Non STB device`
            )
        }

        return this.fetch({
            url: `${this.apigeeUrl}/mcrosrvc/iptv/ngsubscribe`,
            method: 'POST',
            headers: { ...getHeaders(), 'Access-Token': cachedOtpToken },
            body,
            log: 'SUBSCRIBE',
        }).pipe(
            mergeMap(({ response }) => {
                if (!response) {
                    throw new Error('Subscription failed')
                }

                return of(response?.Data?.LMSReservationID)
            }),
            catchError((error) => {
                // Ooredoo wants to display systematically the same error message
                return throwError(
                    new VoltError(VoltError.codes.PURCHASE_FAILED, {
                        inheritedError: error,
                        backendDisplayMessage: error.backendErrorMessage,
                    })
                )
            })
        )
    }

    /**
     * This API will be invoked by Ooredoo TV app to manage reservation after subscription
     *
     * @param {Object} options
     * @param {string} options.reservationId
     * @param {GatewayApi.RESERVATION_OPERATION} options.operation
     * @returns {Observable<boolean>}
     */
    manageReservation({ reservationId, operation }) {
        if (!isServiceConfigured()) {
            this.logger.error('The service is not configured yet')
            return of(false)
        }

        const cachedOtpToken = this.otpToken
        if (!cachedOtpToken)
            return throwError(
                new VoltError(VoltError.codes.PAYMENT_TOKEN_ERROR, {
                    extraLog: 'Missing API GEE access token',
                    backend: this.backendName,
                })
            )

        return this.fetch({
            url: `${this.apigeeUrl}/mcrosrvc/iptv/managereservation`,
            method: 'POST',
            headers: { ...getHeaders(), 'Access-Token': cachedOtpToken },
            body: {
                lmsEnvironment: this.lmsEnvironment,
                reservationId,
                operation,
            },
            log: `MANAGE RESERVATION ${reservationId}: ${operation}`,
        }).pipe(
            mergeMap(() => {
                this.logger.info('Manage reservation request succeeded')
                return of(true)
            }),
            catchError(() => {
                this.logger.error('Manage reservation request failed')
                return of(false)
            })
        )
    }

    _getIptvAccountNumber() {
        const { forceIptvAccountNumber } = ConfigHelper.getInstance().getConfig('ooredoo')
        const iptvAccountNumber = DataHelper.getInstance().getData(
            DataHelper.STORE_KEY.SUBSCRIBER_ID
        )
        if (forceIptvAccountNumber) {
            this.logger.warn(
                `[DEBUG MODE] Force IPTV Account number with ${forceIptvAccountNumber} instead of ${forceIptvAccountNumber}...  This should not be activated for production build`
            )
            return forceIptvAccountNumber
        }

        return iptvAccountNumber
    }
}
