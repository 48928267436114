/**
 * Get a uniq id from a string will never reach the maxRange parameter provided
 *
 * @param   {string} str source string
 * @param   {number} maxRange maxRange
 *
 * @returns {number} a number or 0 when the parameters are not correctly assigned
 */
function reduceToDigit(str, maxRange) {
    if (typeof str !== 'string' || typeof maxRange !== 'number') return 0

    return (
        str.split('').reduce((acc, char) => acc + char.charCodeAt(), 0) %
        maxRange
    )
}

export default reduceToDigit
