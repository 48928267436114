import {useContext} from 'solid-js'
import {AuthorizationContext} from './AuthorizationContext'
import {AuthorizationProviderBehavior} from './AuthorizationProvider'

export function useAuth() {
    const contextValue: AuthorizationProviderBehavior = useContext(AuthorizationContext)
    if (!contextValue) {
        throw new Error(
            'could not find redux context value; please ensure the component is wrapped in a <ReduxProvider>'
        )
    }
    return contextValue
}
