import config from 'services/proxy/config'
import {
    Episode,
    OrphanEpisode,
    Movie,
    ProgramDetails,
    Season,
    SidecarSubtitle,
    SubscriptionDetail,
    TvShow,
} from 'models'
import Constants from 'api-constants'
import { IMAGE_TYPE, getImage } from './helpers'
import { castingFactory, producerFactory, directorFactory } from './casting'
import { videoFormatFactory, audioFormatFactory } from './videoAudioFormat'
import { hasMinimumVersion } from '../helpers/index.js'
import { vodStreamFactory } from './stream'
import { deeplinksFactory } from './deeplink'

const { vodTypes: subtypes, purchaseTypes, streamTypes, deeplinkTypes } = config
const { movie, series, episode, season, episodicShow, orphanEpisode, orphanSeason, vodCatchup } =
    Constants.programType

export const vodFactory = (program, clientConfig = {}) => {
    const { permitOutOfHomeEligibility, proxyVersion, proxyRevision = {}, platform } = clientConfig
    program.proxyId = program.id
    program.sourceId = program.source_id || program.alt_source_id
    program.id = program.platform_id || program.sourceId
    program.platformId = program.platform_id || program.sourceId || program.id

    program.seasonId = program.season_id
    program.seasonNumber = program.season_number

    program.providerLogo = program.content_provider_logo

    if (program.serie_platform_id) {
        program.seriesPlatformId = program.serie_platform_id
    }

    // available when proxy is requested with the `extended_data` attribute (since v1.7.x)
    if (program.serie) {
        program.seriesTitle = program.serie.title
        if (program.serie.pictures) {
            if (Array.isArray(program.serie.pictures.thumbnails)) {
                program.seriesThumbnail = program.serie.pictures.thumbnails[0]
            }

            if (Array.isArray(program.serie.pictures.backdrops)) {
                program.seriesBackdrop = program.serie.pictures.backdrops[0]
            }
        }
        program.seriesPlatformId = program.serie.platform_id
    }
    program.serieId = program.serie_id
    program.duration = program.runtime && program.runtime * 1000

    program.pcValue = (clientConfig.pcLevels || {})[program.rating_id] || 0
    program.ratingId = program.rating_id

    program.isAdult = clientConfig.adultRating && program.ratingId === clientConfig.adultRating

    if (program.availability && program.availability.start) {
        program.startDate = new Date(program.availability.start).getTime()
    }

    if (program.start_validity) {
        program.startDate = program.start_validity * 1000
    }

    if (program.availability && program.availability.end) {
        program.endDate = new Date(program.availability.end).getTime()
    }

    if (program.end_validity) {
        program.endDate = program.end_validity * 1000
    }

    if (program.purchase) {
        program.currency = program.purchase.currency
        program.price = program.purchase.price
        program.purchaseEnd = program.purchase.end
        program.purchaseStart = program.purchase.start
        program.purchaseType = purchaseTypes[program.purchase.type]
    }

    if (program.pictures) {
        if (Array.isArray(program.pictures.backdrops))
            program.backdrop = program.pictures.backdrops[0]
        if (Array.isArray(program.pictures.thumbnails))
            program.thumbnail = program.pictures.thumbnails[0]
    }

    if (program.format) {
        program.videoFormat = videoFormatFactory(program.format)
        program.audioFormat = audioFormatFactory(program.format)
    }

    program.nodesId = program.nodes_id

    program.nodesPlatformIds = program.nodes?.map((node) => node.platform_id) || []

    program.playbacks = program.streams

    program.proxyNodes = program.nodes
        ? program.nodes.reduce((acc, node) => {
              acc[node.id] = node.position
              return acc
          }, {})
        : {}

    let hasAndroidDeeplinkFromStream = false // To be backward compatible. To be removed later
    const hasStreams = !!program.streams && !!program.streams.length
    if (hasStreams) {
        const trailer = vodStreamFactory(program.streams, streamTypes.TRAILER)
        if (trailer) {
            // Seachange: crid://schange.com/vubiquity.co.uk/TITL0000000001372099-preview
            // Kaltura: vod://kaltura?ContentID=123456&IsTrailer
            program.trailer = trailer.uri
        } else {
            // If there is no trailer from the proxy try to use Youtube trailer as fallback
            const youtubeTrailerStream = vodStreamFactory(
                program.streams,
                streamTypes.YOUTUBE_TRAILER
            )
            if (youtubeTrailerStream) {
                program.youtubeTrailer = youtubeTrailerStream.uri
            }
        }

        const contentUri = vodStreamFactory(program.streams, streamTypes.MAIN)
        if (contentUri) {
            program.contentUri = contentUri.uri
        }

        const androidURIs = vodStreamFactory(program.streams, streamTypes.ANDROID)
        hasAndroidDeeplinkFromStream = !!androidURIs
        if (hasAndroidDeeplinkFromStream) {
            const { uri = '' } = androidURIs
            const lIntent = uri.split('|')
            program.command = lIntent.length > 1 ? lIntent[0] : ''
            program.param = lIntent.length > 1 ? lIntent[1] : lIntent[0]
        }

        const subtitleStreams = vodStreamFactory(program.streams, streamTypes.SUBTITLE)
        if (subtitleStreams && subtitleStreams.length > 0) {
            program.sidecarSubtitles = subtitleStreams.map((value) => {
                const { uri, language, file: subtitleType } = value || {}
                return new SidecarSubtitle({
                    uri,
                    language,
                    subtitleType,
                })
            })
        }
    }

    const hasDeeplinks = !!program.deeplinks && !!program.deeplinks.length
    if (hasDeeplinks) {
        const deeplink = deeplinksFactory(program.deeplinks, platform)
        if (deeplink) {
            const { command, param, fallback, platformDeeplinkType } = deeplink
            if (
                fallback &&
                hasAndroidDeeplinkFromStream &&
                platformDeeplinkType === deeplinkTypes.ANDROID_TV
            ) {
                // Do nothing to be compatible with legacy integration getting the deeplink from stream.
                // Do not fallback using WEB deeplink
            } else {
                program.command = command
                program.param = param
            }
        }
    }

    program.childrenCount = program.children_count

    program.subscriptionIds = program.subscription_id

    program.subscriptionDetails = (program.subscription_details || []).map((value) => {
        const { subscription_id, entitlement_id } = value || {}
        return new SubscriptionDetail({
            subscriptionId: subscription_id,
            entitlementId: entitlement_id,
        })
    })

    // program is flagged as "permitted out of home" if there is no eligibility key to compare with
    // or the program eligibility match the eligibility known as "permitted out of home"
    program.permitOutOfHome =
        !permitOutOfHomeEligibility || permitOutOfHomeEligibility === program.eligibility

    program.altSourceID = program.alt_source_id

    program.details = new ProgramDetails({})

    if (proxyVersion >= 3) {
        program.year = program.production_year && parseInt(program.production_year)
        program.genre =
            program.genres && !!program.genres.length
                ? program.genres.reduce((acc, val) => {
                      acc ? (acc = acc + ',' + val) : (acc = val)
                      return acc
                  }, '')
                : ''
        program.purchaseType = purchaseTypes[program.purchase_type]

        program.thumbnail = getImage(program.pictures, IMAGE_TYPE.THUMBNAIL)
        program.backdrop = getImage(program.pictures, IMAGE_TYPE.BACKDROP)
        program.altSourceID = program.source_id
        program.description = program.description || program.short_description
    } else {
        program.description = program.summary || program.short_summary
    }

    const casting = program.casts || program.cast

    if (casting) {
        program.details.cast = castingFactory(casting, proxyVersion)
    }
    if (program.producers) {
        program.details.producers = producerFactory(program.producers, proxyVersion)
    }
    if (program.directors) {
        program.details.directors = directorFactory(program.directors, proxyVersion)
    }

    program.seasonPlatformId = program.season_platform_id

    program.optaDetails = {
        optaFixtureId: program.opta_fixture_id,
        optaSport: program.opta_sport,
        optaTeamId: program.opta_team_id,
        optaFirstHalf: program.opta_first_half,
        optaSecondHalf: program.opta_second_half,
        optaCompetitionCode: program.opta_competition_code,
    }
    // Debug mode; force game ID
    if (
        program.optaDetails.optaFixtureId &&
        clientConfig.opta &&
        clientConfig.opta.forceFixtureId
    ) {
        program.optaDetails.optaFixtureId = clientConfig.opta.forceFixtureId
    }

    if (program.type === subtypes[series]) {
        program.episodesList = []
        program.seasons &&
            program.seasons.forEach((season) => {
                if (season && season.episodes)
                    season.episodes.forEach(
                        (episode) =>
                            episode &&
                            program.episodesList.push({
                                episodeId: episode.platform_id,
                                episodeNumber: episode.number,
                                seasonId: season.platform_id,
                                seasonProxyId: season.id,
                                seasonNumber: season.number,
                            })
                    )
            })

        /**
         * TODO: To move minimum version check outside the loop as this can be resolve one time,
         *  no need to call this function multiple times for all series
         */
        const seasonsProxySupport = hasMinimumVersion(
            proxyVersion >= 3
                ? {
                      major: 3,
                      minor: 1,
                      patch: 5,
                  }
                : {
                      major: 1,
                      minor: 8,
                      patch: 21,
                  },
            proxyRevision
        )

        if (seasonsProxySupport) {
            program.hasSeasonsTree = seasonsProxySupport
        }
    }

    program.studioDisplay = program.studio_display

    switch (program.type) {
        case subtypes[series]:
            return new TvShow(program)
        case subtypes[season]:
        case subtypes[episodicShow]:
        case subtypes[orphanSeason]:
            return new Season(program)
        case subtypes[episode]:
            return new Episode(program)
        case subtypes[orphanEpisode]:
        case subtypes[vodCatchup]: // TODO will be ingested as a VOD catchup later - Just anticipating migration issue
            return new OrphanEpisode(program)
        case subtypes[movie]:
        default:
            return new Movie(program)
    }
}
