import Constants from 'api-constants'
import { PLATFORM } from '@typings/platform'

export class Platform {
    platform: PLATFORM
    constructor(platform: PLATFORM) {
        this.platform = platform || Constants.platform.android
    }

    isAndroid() {
        switch (this.platform) {
            case Constants.platform.android:
            case Constants.platform.androidTvStb:
            case Constants.platform.androidTv:
            case Constants.platform.amazonTv:
                return true
            default:
                return false
        }
    }

    isApple() {
        return this.platform === Constants.platform.ios || this.platform === Constants.platform.tvos
    }

    isAmazonTv() {
        return this.platform === Constants.platform.amazonTv
    }

    isAndroidTv() {
        return (
            this.platform === Constants.platform.androidTvStb ||
            this.platform === Constants.platform.androidTv
        )
    }

    isTv() {
        return (
            this.platform === Constants.platform.androidTvStb ||
            this.platform === Constants.platform.androidTv ||
            this.platform === Constants.platform.tvos
        )
    }

    isSmartTv() {
        return this.platform === Constants.platform.smartTvOs
    }

    isMobile() {
        return (
            this.platform === Constants.platform.android || this.platform === Constants.platform.ios
        )
    }

    isBrowser() {
        switch (this.platform) {
            case Constants.platform.chrome:
            case Constants.platform.firefox:
            case Constants.platform.edge:
            case Constants.platform.edgeChromium:
            case Constants.platform.safari:
                return true
            default:
                return false
        }
    }

    isChromecast() {
        return this.platform === Constants.platform.chromecast
    }

    isConsole() {
        switch (this.platform) {
            case Constants.platform.playstation:
            case Constants.platform.xbox:
                return true
            default:
                return false
        }
    }

    supportHlsStream() {
        switch (this.platform) {
            case Constants.platform.android:
            case Constants.platform.ios:
            case Constants.platform.androidTvStb:
            case Constants.platform.androidTv:
            case Constants.platform.amazonTv:
            case Constants.platform.tvos:
            case Constants.platform.smartTvOs:
            case Constants.platform.chrome:
            case Constants.platform.firefox:
            case Constants.platform.edge:
            case Constants.platform.edgeChromium:
            case Constants.platform.safari:
            case Constants.platform.playstation:
            case Constants.platform.xbox:
            case Constants.platform.chromecast:
                return true
            default:
                return false
        }
    }

    supportDashStream() {
        switch (this.platform) {
            case Constants.platform.android:
            case Constants.platform.androidTvStb:
            case Constants.platform.androidTv:
            case Constants.platform.amazonTv:
            case Constants.platform.smartTvOs:
            case Constants.platform.chrome:
            case Constants.platform.firefox:
            case Constants.platform.edgeChromium:
            case Constants.platform.chromecast:
                return true
            default:
                return false
        }
    }

    isDrmWidewine() {
        switch (this.platform) {
            case Constants.platform.android:
            case Constants.platform.androidTvStb:
            case Constants.platform.androidTv:
            case Constants.platform.amazonTv:
            case Constants.platform.smartTvOs:
            case Constants.platform.chrome:
            case Constants.platform.firefox:
            case Constants.platform.edgeChromium:
            case Constants.platform.chromecast:
                return true
            default:
                return false
        }
    }

    isDrmFairplay() {
        switch (this.platform) {
            case Constants.platform.ios:
            case Constants.platform.tvos:
            case Constants.platform.safari:
                return true
            default:
                return false
        }
    }

    isDrmPlayReady() {
        // Also supported by Chromium Edge, Chromecast, Web OS and Tizen OS but prefer to use Widewin
        switch (this.platform) {
            case Constants.platform.edge:
            case Constants.platform.playstation:
            case Constants.platform.xbox:
                return true
            default:
                return false
        }
    }
}
