/**
 * add n days to a given timestamp
 * @method addDays
 * @param  {number} days - the number of days to add
 * @param  {number} timestamp - the timestamp
 * @return {number} - the new timestamp
 */
function addDays(days, timestamp) {
    const result = new Date(timestamp)
    result.setDate(result.getDate() + days)
    return result.getTime()
}

export default addDays
