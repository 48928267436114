export default {
    matchInfo: {
        id: '7c2f1r3fe7code6ijoi1xzh1w',
        coverageLevel: '15',
        date: '2022-08-28Z',
        time: '15:30:00Z',
        localDate: '2022-08-28',
        localTime: '16:30:00',
        week: '4',
        numberOfPeriods: 2,
        periodLength: 45,
        lastUpdated: '2022-08-28T15:27:58Z',
        description: 'Nottingham Forest vs Tottenham Hotspur',
        sport: {
            id: '289u5typ3vp4ifwh5thalohmq',
            name: 'Soccer',
        },
        ruleset: {
            id: '79plas4983031idr6vw83nuel',
            name: 'Men',
        },
        competition: {
            id: '2kwbbcootiqqgmrzs6o5inle5',
            name: 'Premier League',
            competitionCode: 'EPL',
            competitionFormat: 'Domestic league',
            country: {
                id: '1fk5l4hkqk12i7zske6mcqju6',
                name: 'England',
            },
        },
        tournamentCalendar: {
            id: '80foo89mm28qjvyhjzlpwj28k',
            startDate: '2022-08-05Z',
            endDate: '2023-05-28Z',
            name: '2022/2023',
        },
        stage: {
            id: '80qbeanalyj5cvxikkq351iqc',
            formatId: 'e2q01r9o9jwiq1fls93d1sslx',
            startDate: '2022-08-05Z',
            endDate: '2023-05-28Z',
            name: 'Regular Season',
        },
        contestant: [
            {
                id: '1qtaiy11gswx327s0vkibf70n',
                name: 'Nottingham Forest',
                shortName: 'Nottm Forest',
                officialName: 'Nottingham Forest FC',
                code: 'NFO',
                position: 'home',
                country: {
                    id: '1fk5l4hkqk12i7zske6mcqju6',
                    name: 'England',
                },
            },
            {
                id: '22doj4sgsocqpxw45h607udje',
                name: 'Tottenham Hotspur',
                shortName: 'Tottenham',
                officialName: 'Tottenham Hotspur FC',
                code: 'TOT',
                position: 'away',
                country: {
                    id: '1fk5l4hkqk12i7zske6mcqju6',
                    name: 'England',
                },
            },
        ],
        venue: {
            id: '6ccyj8bk3iv2mc01xe5rtch3r',
            neutral: 'no',
            longName: 'The City Ground',
            shortName: 'The City Ground',
        },
    },
    liveData: {
        matchDetails: {
            periodId: 16,
            matchStatus: 'Fixture',
        },
        lineUp: [
            {
                contestantId: '1qtaiy11gswx327s0vkibf70n',
                formationUsed: '3421',
                teamOfficial: {
                    id: '7qb4jzx7wrt2syeio63jpjtgp',
                    firstName: 'Steven David',
                    lastName: 'Cooper',
                    type: 'manager',
                },
                stat: [
                    {
                        type: 'formationUsed',
                        value: '3421',
                    },
                ],
                kit: {
                    id: '4782',
                    colour1: '#FF0000',
                    colour2: '#FFFFFF',
                    type: 'home',
                },
            },
            {
                contestantId: '22doj4sgsocqpxw45h607udje',
                formationUsed: '3421',
                teamOfficial: {
                    id: '2t14kex330qljt5vlmssvo6ad',
                    firstName: 'Antonio',
                    lastName: 'Conte Cavaliere',
                    type: 'manager',
                },
                stat: [
                    {
                        type: 'formationUsed',
                        value: '3421',
                    },
                ],
                kit: {
                    id: '4',
                    colour1: '#FFFFFF',
                    colour2: '#132257',
                    type: 'home',
                },
            },
        ],
        matchDetailsExtra: {
            matchOfficial: [
                {
                    id: '3oe7osb8o3cy0dgex37asng0l',
                    type: 'Main',
                    firstName: 'Craig',
                    lastName: 'Pawson',
                },
                {
                    id: 'f1g3337l5j65736o54oswmxud',
                    type: 'Lineman 1',
                    firstName: 'Ian',
                    lastName: 'Hussin',
                },
                {
                    id: '9fqj6o3vtcziwj0xpzy4e6j85',
                    type: 'Lineman 2',
                    firstName: 'Scott',
                    lastName: 'Ledger',
                },
                {
                    id: '6l3ie5htdymg13u2ui69wo251',
                    type: 'Fourth official',
                    firstName: 'Darren',
                    lastName: 'England',
                },
            ],
        },
    },
}
