function mapFunctions(r) {
    const filePaths = r.keys()
    const dirContent = filePaths.map(r)
    const mapValidKeys = filePaths.map(path => {
        const pathSplitted = path.split('/')
        return pathSplitted.length > 2 ? pathSplitted[1] : null
    })

    return mapValidKeys.reduce(function(acc, val, index) {
        if (val) {
            const func = dirContent[index].default
            const matches = filePaths[index].match(/\/([a-zA-Z0-9]+)\.js$/)

            if (matches) {
                acc[val] = Object.assign({}, acc[val], { [matches[1]]: func })
            }
        }
        return acc
    }, {})
}

export default mapFunctions(
    require.context('./', true, /^((?!(test|index)).)*\.js$/),
)
