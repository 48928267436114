/**
 * Convert a given value to a DateTime String. Optionnaly usig UTC TimeZone and
 * optionnaly with the TimeZone indication.
 *
 * @example
 * var ts = 1557208800000 // Tue May 07 2019 08:00:00 GMT+0200 CEST
 * toIsoDateTimeString(ts, { utc: false, withTimezone: false}) // 2019-05-07T08:00:00
 * toIsoDateTimeString(ts, { utc: false, withTimezone: true}) // 2019-05-07T08:00:00+02:00
 * toIsoDateTimeString(ts, { utc: true, withTimezone: false }) // 2019-05-07T06:00:00
 * toIsoDateTimeString(ts, { utc: true, withTimezone: true }) // 2019-05-07T06:00:00Z
 *
 * @param {Number|String|Date} input value to convert to ISO DateTime string (a timestamp or a textual representation of the date understood by Date())
 * @param {Object} options
 * @param {Boolean} [options.utc=false] `true` DateTime return use UTC timezone, `false` keep local timezone
 * @param {Boolean} [options.withTimezone=true] `true` timezone indication is kept, `false` timezone indication is removed
 * @return {String|false} Formated DateTime or `false` if input is not a valid date
 */
const toIsoDateTimeString = (
    input,
    options = { utc: false, withTimezone: true },
) => {
    const { utc, withTimezone } = options
    const date = new Date(input)
    const pad = v => v.toString().padStart(2, '0')

    if (isNaN(date)) {
        return false
    }

    if (utc) {
        // Use native method & remove the milliseconds part
        let datetime = date.toISOString().replace(/\.[0-9]{3}/, '')

        if (withTimezone === false) {
            datetime = datetime.replace(/Z|(\+[0-9]{2}:[0-9]{2})/, '')
        }

        return datetime
    }

    const year = date.getFullYear()
    const month = pad(date.getMonth() + 1)
    const day = pad(date.getDate())
    const hours = pad(date.getHours())
    const minutes = pad(date.getMinutes())
    const seconds = pad(date.getSeconds())

    let datetime = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`

    if (withTimezone) {
        // getTimezoneOffset returns -120 for a CEST timezone (GMT+0200)
        // so let's invert the sign
        const tzOffset = (date.getTimezoneOffset() / 60) * -1
        let timezone

        if (tzOffset === 0) {
            timezone = 'Z'
        } else {
            timezone = tzOffset < 0 ? '-' : '+'
            const absTzOffset = Math.abs(tzOffset)
            timezone +=
                absTzOffset % 1 === 0
                    ? pad(absTzOffset) + ':00'
                    : pad(Math.floor(absTzOffset)) +
                      ':' +
                      Math.round((absTzOffset % 1) * 60)
        }

        datetime = `${datetime}${timezone}`
    }

    return datetime
}

export default toIsoDateTimeString
