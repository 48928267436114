/**
 * Cast `value` as an array but :
 * - Return original value if `value` is already an array
 * - Return an empty array if value is `undefined` or `null`
 *
 * @example
 * asArray(1337) //[1337]
 * asArray(['yes']) //['yes']
 * asArray(null) //[]
 *
 * @param {*} value Value to cast as an array
 * @return {Array}
 */
const asArray = value => {
    if (value === undefined || value === null) {
        return []
    }

    if (!Array.isArray(value)) {
        return [value]
    }

    return value
}

export default asArray
