import { Node } from './Node'
import { INavigationNode } from '@typings/node'

/**
 * A NavigationNode is a link to a number of child nodes in the navigation tree
 * @property {Array<String|Number>} children The list of identifiers of child nodes
 * @property {Number} [entryFilter] Index in the `children` array of the child selected upon entering the Node
 * @property {Number} [defaultEntryFilter=0] The default value of entryFilter
 */
export class NavigationNode extends Node {
    children: (string | number)[]
    defaultEntryFilter: number
    entryFilter: number

    constructor(props: INavigationNode) {
        super(props)

        const computePropValue = this._makeComputePropValue(props)

        this.children = computePropValue('children', [])
        this.defaultEntryFilter = computePropValue('defaultEntryFilter', 0)
        this.entryFilter = computePropValue('entryFilter', this.defaultEntryFilter, Number.isFinite)
    }

    /** Checks if the Node is a NavigationNode (always `true`) */
    isNavigationNode() {
        return true
    }
}
