import { Abstract } from './Abstract'
import { IReminder } from '@typings/tile'

/**
 * @class Reminder
 * @property {String|Number} id Platform identifier of the reminder
 * @property {String|Number} eventId Platform identifier of the program
 * @property {String|Number} channelId Platform identifier channels associated to the program
 * @property {Boolean} isAvailable
 * @property {Number} availabilityStart Timestamp representing startTime of related program
 * @property {Number} availabilityEnd Timestamp representing endTime of related program
 * @property {Number} programId Proxy program identifier
 * @property {Number} reminderTime Timestamp when reminder should be display to the user (computed in consumer project)
 */

export class Reminder extends Abstract {
    id: string | number
    eventId: string | number
    channelId: string | number
    isAvailable: boolean
    availabilityStart: number
    availabilityEnd: number
    programId: number
    reminderTime: number

    constructor(props: IReminder) {
        super()
        this.id = props.id
        this.eventId = props.eventId
        this.channelId = props.channelId
        this.isAvailable = props.isAvailable
        this.availabilityStart = props.availabilityStart
        this.availabilityEnd = props.availabilityEnd
        this.programId = props.programId
        this.reminderTime = props.reminderTime
    }
}
