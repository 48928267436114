import DataHelper from 'framework/helpers/data'
import { ParsedUrl, parseUrl } from './helpers'
import Fetch from '../fetch'
import { Config, HashMap } from '@typings/generic'

export default class ImageApi extends Fetch {
    private _huaweiVspParsedUrl: ParsedUrl
    _huaweiVspRawURL: string
    _hasHuaweiCachedUrl: boolean
    mappedURL: HashMap<string, string>

    constructor(config: Config) {
        super(config)

        this._hasHuaweiCachedUrl = false
        this._huaweiVspRawURL = ''
        this._huaweiVspParsedUrl = {}
        this.mappedURL = {}
    }

    getImageUrl({ src }: { src: string }) {
        if (!src) return { src: undefined }

        this._handleVspUrlCaching()

        if (!this._hasHuaweiCachedUrl) {
            // if no cached vsp url change return the raw one
            return { src }
        }

        const existingHost = Object.keys(this.mappedURL).find((host) => src.includes(host))

        if (src && existingHost) {
            const { pathname, search } = new URL(src)
            return { src: `${this.mappedURL[existingHost]}${pathname}${search}` }
        }

        const { protocol, host, port, pathname, query } = parseUrl(src)

        if (host && this.mappedURL[host]) {
            return { src: `${this.mappedURL[host]}${pathname}${query}` }
        }

        const {
            protocol: vspURLProtocol,
            host: vspURLHost,
            port: vspURLPort,
        } = this._getHuaweiParsedUrl()

        if (
            vspURLHost?.endsWith(
                host
                    ?.split('.')
                    .filter((i, idx) => idx !== 0)
                    .join('.') ?? ''
            ) &&
            host &&
            vspURLHost
        ) {
            this.mappedURL[host] = `${vspURLProtocol}${vspURLHost}:${vspURLPort}`

            return { src: `${this.mappedURL[host]}${pathname}${query}` }
        } else if (host) {
            this.mappedURL[host] = `${protocol}${host}${port ? ':' + port : ''}`

            return { src: `${this.mappedURL[host]}${pathname}${query}` }
        }

        return { src: undefined }
    }

    /**
     * Cache vsp parsed url
     */
    _cacheHuaweiParsedUrl(vspURL: ParsedUrl) {
        this._huaweiVspParsedUrl = vspURL
    }

    /**
     * Retrieve VSP parsed URL
     */
    _getHuaweiParsedUrl() {
        return this._huaweiVspParsedUrl
    }

    /**
     * Handle Huwaei caching
     */
    _handleVspUrlCaching() {
        const vspURL = DataHelper.getInstance().getData(
            DataHelper.STORE_KEY.BACKEND_API_URL
        ) as string

        if (!this._huaweiVspRawURL || this._huaweiVspRawURL !== vspURL) {
            if (vspURL) {
                if (this._huaweiVspRawURL) {
                    Object.keys(this.mappedURL).forEach((key) => {
                        if (this.mappedURL?.[key] === this._huaweiVspRawURL) {
                            this.mappedURL[key] = vspURL
                        }
                    })
                }
                this._huaweiVspRawURL = vspURL
                this._cacheHuaweiParsedUrl(parseUrl(vspURL))
            }
            // Switch previous Huawei URL from the cache if the VSC URL change dynamically

            this._hasHuaweiCachedUrl = !!vspURL
        }
    }
}
