const storage = {
  getItem: (key) => {
    return Promise.resolve(localStorage.getItem(key));
  },

  setItem: (key, value) => {
    localStorage.setItem(key, value);
    return Promise.resolve();
  },

  removeItem: (key) => {
    localStorage.removeItem(key);
    return Promise.resolve();
  },

  multiGet: (keys) => {
    return Promise.resolve(keys.map((k) => [k, localStorage.getItem(k)]));
  },

  multiSet: (keyValuePairs) => {
    keyValuePairs.forEach(([k, v]) => {
      localStorage.setItem(k, v);
    });

    return Promise.resolve();
  },

  multiRemove: (keys) => {
    keys.forEach((k) => localStorage.removeItem(k));
    return Promise.resolve();
  },
};

export default storage;
