import { IPerson } from '@typings/tile'
import { Tile } from './Tile'

export class Person extends Tile {
    firstName: string
    lastName: string

    constructor(props: IPerson) {
        super(props)
        this.id = props.id
        this.firstName = props.firstName
        this.lastName = props.lastName
    }

    isPerson() {
        return true
    }
}
