import { IChannelAppProgram } from '@typings/tile'
import { Deeplink } from './Deeplink'
import Constants from 'api-constants'

/**
 * Class ChannelAppProgram description
 * Preview : https://developer.android.com/training/tv/discovery
 * @property {String} programType {@link PROGRAM_TYPE} Type of Program : Preview Program, Watch Next
 * @property {String} contentType {@link CONTENT_TYPE} Type of Content : Movie, Serie, Season, Episode, Clip, Event, Channel, Track, Album, Artist, Playlist, Station, Game
 * @property {String} command : Application package name (Inherited from Deeplink)
 * @property {String} param : Content URI (Inherited from Deeplink)
 * @property {String} channelID : Unique Channel ID
 * @property {String} applicationIcon : URI to access to the Icon of the application in the system
 * @property {String} applicationLogo : URI to access to the Logo of the application in the system
 * @property {String} applicationName : Application Name
 * @property {String} episodeTitle : The episode title of this TV program for episodic TV shows. Can be empty.
 * @property {String} episodeTitleNumber : The episode display number of this TV program for episodic TV shows.This is used to indicate the episode number. (e.g. 1, 2 or 3) Note that the value does not necessarily be numeric. (e.g. 12B). Can be empty.
 * @property {String} seasonTitle : The title of the season for this TV program for episodic TV shows. Doc : https://developer.android.com/reference/androidx/tvprovider/media/tv/TvContractCompat.PreviewPrograms#COLUMN_SEASON_TITLE
 * @property {String} seasonTitleNumber : The title of the season for this TV program for episodic TV shows. Doc : https://developer.android.com/reference/androidx/tvprovider/media/tv/TvContractCompat.PreviewPrograms#COLUMN_SEASON_TITLE
 * @property {Number} duration : Duration of the content in ms
 * @property {String} offerPrice : The offer price of this TV program. This is the promotional cost of the content.
 * @property {String} releaseDate : The release date of this TV program. The value should be in one of the following formats: "yyyy", "yyyy-MM-dd"
 * @property {String} availability : {@link AVAILABILITY_TYPE} The availability of this TV program.
 * @property {Number} transientContent : A value of 1 indicates that the channel will be automatically removed by the system on reboot, and a value of 0 indicates that the channel is persistent across reboot. If not specified, this value is set to 0 (not transient) by default.
 * @property {Number} contentWeight : The weight of the preview program within the channel. The UI may choose to show this item in a different position in the channel row. A larger weight value means the program is more important than other programs having smaller weight values.
 * @property {String} genre : Genre (frequently not provided)
 * @property {String} author : Author (frequently not provided)
 * @property {String} previewVideoUri : The URI for the preview video.
 * @property {String} previewAudioUri : The URI for the preview audio.
 * @property {Boolean} liveOnTheAir : TV program is live or not. A value of 1 indicates that the content is airing and should be consumed now, a value of 0 indicates that the content is off the air and does not need to be consumed at the present time. If not specified, the value is set to 0 (not live) by default.
 * @property {Boolean} searchable : The flag indicating whether this TV program is searchable or not.
 * @property {Boolean} browsable : The flag indicating whether this TV program is browsable or not.
 * @property {String} reviewRating : The review rating score for this program.The format of the value is dependent on COLUMN_REVIEW_RATING_STYLE. If the style is REVIEW_RATING_STYLE_STARS, the value should be a real number between 0.0 and 5.0. (e.g. "4.5")
 * If the style is REVIEW_RATING_STYLE_THUMBS_UP_DOWN, the value should be two integers, one for thumbs-up count and the other for thumbs-down count, with a comma between them. (e.g. "200,40") If the style is REVIEW_RATING_STYLE_PERCENTAGE,
 * the value should be a real number between 0 and 100. (e.g. "99.9")
 * @property {Number} reviewRatingStyle : The offer price of this TV program. This is the promotional cost of the content.
 * @property {Number} lastPlaybackPosition: for Watch Next Program only (in Ms)
 * @property {Number} thumbnailAspectRatio: Will be amended while the channel manager will be move to volt-api
 */

export class ChannelAppProgram extends Deeplink {
    programType: string
    contentType: number
    channelID: string
    applicationIcon: string
    applicationLogo: string
    applicationName: string
    episodeTitle: string
    episodeTitleNumber: string
    seasonTitle: string
    seasonTitleNumber: string
    duration: number
    offerPrice: string
    releaseDate: string | boolean
    availability: boolean
    transientContent: number
    contentWeight: number
    genre: string
    author: string
    previewVideoUri: string
    previewAudioUri: string
    liveOnTheAir: string
    searchable: boolean
    browsable: boolean
    reviewRating: string
    reviewRatingStyle: string
    lastPlaybackPosition: number
    thumbnailAspectRatio: number
    modelType: string

    constructor(props: IChannelAppProgram) {
        /** Note :
         * ratio, thumbnail, backdrop, description props are handled here
         */
        super(props)

        const computePropValue = this._makeComputePropValue(props)

        this.id = computePropValue('id', props.command + '/' + props.param)
        this.title = computePropValue('title', props.applicationName)
        this.programType = computePropValue(
            'programType',
            ChannelAppProgram.PROGRAM_TYPE.PREVIEW_PROGRAM
        )
        this.contentType = props.contentType
        this.command = props.command
        this.param = props.param
        this.channelID = props.channelID
        this.applicationIcon = props.applicationIcon
        this.applicationLogo = props.applicationLogo
        this.applicationName = props.applicationName
        this.episodeTitle = props.episodeTitle
        this.episodeTitleNumber = props.episodeTitleNumber
        this.seasonTitle = props.seasonTitle
        this.seasonTitleNumber = props.seasonTitleNumber
        this.duration = props.duration
        this.offerPrice = props.offerPrice
        this.releaseDate =
            props.releaseDate && props.releaseDate !== '0' && props.releaseDate.split('T')[0] // For display user, not necessary to convert as already ISO
        this.availability = computePropValue(
            'availability',
            ChannelAppProgram.AVAILABILITY_TYPE.AVAILABLE
        )
        this.transientContent = computePropValue('transientContent', 0)
        this.contentWeight = props.contentWeight
        this.genre = props.genre
        this.author = props.author
        this.previewVideoUri = props.previewVideoUri
        this.previewAudioUri = props.previewAudioUri
        this.liveOnTheAir = computePropValue('liveOnTheAir', 0)
        this.searchable = props.searchable
        this.browsable = props.browsable
        this.reviewRating = props.reviewRating
        this.reviewRatingStyle = computePropValue(
            'reviewRatingStyle',
            ChannelAppProgram.REVIEW_RATING_STYLE.STYLE_STARS
        )
        this.lastPlaybackPosition = props.lastPlaybackPosition

        // TODOMRI Handle it externally with a factory when the channel app API will be moved in API
        this.thumbnailAspectRatio =
            props.thumbnailAspectRatio || ChannelAppProgram.ASPECT_RATIO.ASPECT_RATIO_16_9

        switch (this.thumbnailAspectRatio) {
            case ChannelAppProgram.ASPECT_RATIO.ASPECT_RATIO_MOVIE_POSTER:
                this.format = 'portrait'
                break
            case ChannelAppProgram.ASPECT_RATIO.ASPECT_RATIO_2_3:
                this.format = 'portrait'
                this.ratio = 2 / 3
                break
            case ChannelAppProgram.ASPECT_RATIO.ASPECT_RATIO_16_9:
                this.format = 'landscape'
                this.ratio = 16 / 9
                break
            case ChannelAppProgram.ASPECT_RATIO.ASPECT_RATIO_3_2:
                this.format = 'landscape'
                this.ratio = 3 / 2
                break
            case ChannelAppProgram.ASPECT_RATIO.ASPECT_RATIO_4_3:
                this.format = 'landscape'
                this.ratio = 4 / 3
                break
            case ChannelAppProgram.ASPECT_RATIO.ASPECT_RATIO_1_1:
                this.format = 'portrait'
                this.ratio = 1
                break
            default:
                this.format = 'landscape'
                break
        }

        this.modelType = Constants.programType.channelAppProgram
    }

    /**
     * PROGRAM_TYPE TYPE
     */
    static PROGRAM_TYPE = {
        PREVIEW_PROGRAM: 'CHANNEL_APP_PREVIEW_PROGRAM',
        WATCH_NEXT_PROGRAM: 'CHANNEL_APP_WATCH_NEXT_PROGRAM',
    }

    /**
     * CONTENT_TYPE
     */
    static CONTENT_TYPE = {
        TYPE_MOVIE: 0,
        TYPE_TV_SERIES: 1,
        TYPE_TV_SEASON: 2,
        TYPE_TV_EPISODE: 3,
        TYPE_CLIP: 4,
        TYPE_EVENT: 5,
        TYPE_CHANNEL: 6,
        TYPE_TRACK: 7,
        TYPE_ALBUM: 8,
        TYPE_ARTIST: 9,
        TYPE_PLAYLIST: 10,
        TYPE_STATION: 11,
        TYPE_GAME: 12,
    }

    /**
     * ASPECT_RATIO, (can be) used for different rendering
     */
    static ASPECT_RATIO = {
        ASPECT_RATIO_16_9: 0, // https://developer.android.com/reference/androidx/tvprovider/media/tv/TvContractCompat.PreviewPrograms#ASPECT_RATIO_16_9
        ASPECT_RATIO_3_2: 1, // https://developer.android.com/reference/androidx/tvprovider/media/tv/TvContractCompat.PreviewPrograms#ASPECT_RATIO_3_2
        ASPECT_RATIO_4_3: 2, // https://developer.android.com/reference/androidx/tvprovider/media/tv/TvContractCompat.PreviewPrograms#ASPECT_RATIO_4_3
        ASPECT_RATIO_1_1: 3, // https://developer.android.com/reference/androidx/tvprovider/media/tv/TvContractCompat.PreviewPrograms#ASPECT_RATIO_1_1
        ASPECT_RATIO_2_3: 4, // https://developer.android.com/reference/androidx/tvprovider/media/tv/TvContractCompat.PreviewPrograms#ASPECT_RATIO_2_3
        ASPECT_RATIO_MOVIE_POSTER: 5, // https://developer.android.com/reference/androidx/tvprovider/media/tv/TvContractCompat.PreviewPrograms#ASPECT_RATIO_MOVIE_POSTER
    }

    /**
     * Availabilty Type
     */
    static AVAILABILITY_TYPE = {
        AVAILABLE: 0, // The availability for "available to this user".
        FREE_WITH_SUBSCRIPTION: 1, // The availability for "free with subscription".
        PAID_CONTENT: 2, // The availability for "paid content", either to-own or rental (user has not purchased/rented).
        PURCHASED: 3, // The availability for content already purchased by the user.
        FREE: 4, // The availability for free content.
    }

    static REVIEW_RATING_STYLE = {
        STYLE_STARS: 0, // The review rating style for five star rating.
        STYLE_PERCENTAGE: 1, // The review rating style for 0 to 100 point system.
        STYLE_THUMBS_UP_DOWN: 2, // The review rating style for thumbs-up and thumbs-down rating.
    }

    isChannelAppProgram() {
        return true
    }
}
