import { of } from 'rxjs'
import { catchError, mergeMap, timeout } from 'rxjs/operators'
import Fetch from '../fetch'

export default class ConfigurationApi extends Fetch {
    constructor(config, otherApis = {}) {
        super(config, otherApis)
        this.config = config
    }

    getConfig() {
        return of({})
    }
    shouldUpgrade(appVersion) {
        // simply return of(false) since this service/platform does not offer forceUpgrade capabilities
        return of(false)
    }

    /**
     * Returns custom nodes and requests for the navigation map
     *
     * @returns {Observable<EmiModuleConfiguration>}
     */
    getCustomNavigationMap() {
        return of({
            navMap: {},
            requests: {},
        })
    }

    /**
     * Get Sources of data provided by IFS proxy for this backend
     * @returns {Object}
     */
    getProxyMetadataSourceConfig() {
        return {
            channels: false,
            programs: true,
            subscriptions: true,
            dvr: false,
        }
    }

    isConnectedInOperatorNetwork() {
        const edsServer = this.config.urls.authUrl
        this.logger.info(
            'Checking EDS service availability which is available only in operator network'
        )

        return this.fetch({
            url: `${edsServer}/EDS/V3/LoginRoute`,
            method: 'POST',
            body: {},
            allowReloginWhenSessionEnded: false, // As this API is used when user is not loggued in
            // cookiesLessQuery: true,
            log: 'CHECK_EDS_AVAILABILITY',
        }).pipe(
            timeout(this.config.huawei.operatorConnectionCheckTimeout || 3000),
            mergeMap(() => {
                this.logger.info('EDS service is available - operator network is available')
                return of(true)
            }),
            catchError((error) => {
                this.logger.error(
                    'Error occurred while checking EDS - operator network is unavailable:',
                    error
                )
                return of(false)
            })
        )
    }
}
