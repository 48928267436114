/**
 * Takes a maxRange as parameter and returns a random number between 0 and
 * maxRange
 *
 * @param   {?number} maxRange length
 *
 * @returns {number} random number or 0 when the value is not defined
 */
function getRandomInt(maxRange = null) {
    return Math.floor(Math.random() * Math.floor(maxRange))
}

export default getRandomInt
