import { of, throwError } from 'rxjs'
import PurchaseApi from '../../huawei/PurchaseApi'
import { mergeMap, catchError } from 'rxjs/operators'
import Constants from 'api-constants'
import { filterProductByEligibility } from '../Factories'
import { Subscription } from 'models'
import VoltError from 'VoltError'
import GatewayApi from '../GatewayApi'

/**
 * Deal with Purchase (data retrieval, purchase and subscription actions)
 */
export default class OoredooPurchaseApi extends PurchaseApi {
    constructor(config, otherApis = {}) {
        super(config, otherApis)
        this.gatewayApi = otherApis.gatewayApi
    }

    /**
     * Retrieves all Subscription products
     *
     * @param {Number} [pageSize=15] The page size of each api request
     *
     * @returns {Observable<SubscriptionsList>}
     */
    getSubscriptions(pageSize = 50) {
        return super.getSubscriptions(pageSize).pipe(
            mergeMap(({ products, isComplete }) =>
                of({
                    products: this._filterSubscriptionsByGeneration(products),
                    isComplete,
                })
            )
        )
    }

    /**
     * Retrieves Subscriptions
     * @returns {Observable<SubscriptionsList>}
     */
    getSubscriptionsData(productIds) {
        return super
            .getSubscriptionsData(productIds)
            .pipe(mergeMap((products) => of(this._filterSubscriptionsByGeneration(products))))
    }

    /**
     * Retrieves Products entitlements
     * @param {Number} count Page size
     * @returns {Observable<Array<HuaweiSubscribedProduct>>}
     */
    _getUserProductEntitlements(productType, count) {
        return super._getUserProductEntitlements(productType, count).pipe(
            mergeMap((entitlements) => {
                if (Constants.productTypes.SUBSCRIPTION === productType) {
                    this._setUserSubscriptionEligibility(entitlements)
                }
                return of(entitlements)
            })
        )
    }

    /**
     * Send purchase OTP for validation
     * @returns {Observable<boolean>}
     */
    sendPurchaseOTP() {
        return this.gatewayApi.sendOTP()
    }

    /**
     * Validate typed by a user OTP
     *
     * @param {Object} [options]
     * @param {string} options.otp one time password filled in by a user
     * @returns {Observable<boolean>}
     */
    validatePurchaseOTP({ otp } = {}) {
        return this.gatewayApi.validateOTP({ code: otp })
    }

    /**
     * Subscribe on product by its id
     *
     * @param {String} productId
     * @returns {Observable<boolean>}
     */
    subscribe(productId) {
        /**
         * Purchase on Ooredoo is a two step purchase as follow:
         * If APIGEE NGsubscribe APi returns success, then IFS app will call Huawei subscribeProduct API
         *         - If huawei subscribeProduct returns success, the UI displays a notification accordingly
         *         - If huawei subscribeProduct returns failure, the UI displays an error message. No retry mechanism or any silent retry.
         *         - Consequence: if the user retry by pressing Subscribe, the UI will display something close than "You are already entitled to this content , please call the customer service if you still can't use this content". (Because from CRM user is entitled not from Huawei). This status will require to be solved offline by Ooredoo Team.
         * If APIGEE NGsubscribe APi returns failure, the UI displays an error message accordingly
         */
        return this.gatewayApi.subscribe({ productId }).pipe(
            mergeMap((reservationId) => {
                this.logger.info(
                    'Subscription on APIGEE succeeded, subscribing on Huawei as well...'
                )

                if (!reservationId) {
                    this.logger.warn(
                        'LMSReservationID is empty - manage reservation request will not be sent'
                    )
                }

                return super.subscribe(productId).pipe(
                    mergeMap(() => {
                        if (reservationId) {
                            return this.gatewayApi.manageReservation({
                                reservationId,
                                operation: GatewayApi.RESERVATION_OPERATION.confirm,
                            })
                        }
                        return of(true)
                    }),
                    catchError((huaweiError) => {
                        this.logger.warn(
                            'An error occurs during the process of purchase, send manageReservation cancel to let the APIGEE managing the refund of the usercredits'
                        )
                        return (
                            reservationId
                                ? this.gatewayApi.manageReservation({
                                      reservationId,
                                      operation: GatewayApi.RESERVATION_OPERATION.cancel,
                                  })
                                : of(true)
                        ).pipe(
                            mergeMap(() =>
                                throwError(
                                    // Ooredoo wants to display systematically the same error message
                                    new VoltError(VoltError.codes.PURCHASE_FAILED, {
                                        inheritedError: huaweiError,
                                        backendDisplayMessage: huaweiError.backendErrorMessage,
                                    })
                                )
                            )
                        )
                    })
                )
            })
        )
    }

    /**
     * Get user's wallet data: available credits, spent credits and all related data
     *
     * @returns {Observable<object>}
     */
    getUserWallet() {
        return this.gatewayApi.getUserWallet()
    }

    /**
     * Deduct the subscription the user is eligible
     * @param {Array<HuaweiSubscribedProduct>} subscribedProducts
     */
    _resolveUserSubscriptionsGenerationEligibility(subscribedProducts) {
        if (subscribedProducts.length === 0) {
            this.logger.info(
                '[PRODUCT_GENERATION_ELIGIBILITY] No user subscription. NO Filtering applied...'
            )
            return Subscription.PRODUCT_GENERATION.UNDEFINED
        }

        const productGenerations = [...new Set(subscribedProducts.map((x) => x.productGeneration))]

        this.logger.info(
            `[PRODUCT_GENERATION_ELIGIBILITY] User has ${productGenerations.join(
                ','
            )} product Types from ${subscribedProducts.length} products`
        )

        const hasOldGenProduct = productGenerations.includes(Subscription.PRODUCT_GENERATION.LEGACY)
        const hasNextGenProduct = productGenerations.includes(
            Subscription.PRODUCT_GENERATION.NEW_GENERATION
        )
        if (hasOldGenProduct && hasNextGenProduct) {
            this.logger.info(
                '[PRODUCT_GENERATION_ELIGIBILITY] Both packs owned. No Filtering applied...'
            )
            return Subscription.PRODUCT_GENERATION.UNDEFINED
        }

        if (!hasOldGenProduct && !hasNextGenProduct) {
            this.logger.info(
                '[PRODUCT_GENERATION_ELIGIBILITY] None packs owned. No Filtering applied...'
            )
            return Subscription.PRODUCT_GENERATION.UNDEFINED
        }

        if (hasOldGenProduct) {
            this.logger.info(
                '[PRODUCT_GENERATION_ELIGIBILITY] Only OLD GEN Pack owned. Filtering applied using only OLD Gen...'
            )
            return Subscription.PRODUCT_GENERATION.LEGACY
        }

        this.logger.info(
            '[PRODUCT_GENERATION_ELIGIBILITY] Only NEXT GEN Pack owned. Filtering applied using only NEXT Gen...'
        )
        return Subscription.PRODUCT_GENERATION.NEW_GENERATION
    }

    /**
     * This method filter the list of subscription based on Ooredoo business rule
     * @param {Array<Subscriptions>} subscriptions
     */
    _filterSubscriptionsByGeneration(subscriptions) {
        const userEligibility = this._getUserSubscriptionEligibility()
        const eligibleProducts = filterProductByEligibility(subscriptions, userEligibility)

        this.logger.info(
            `Product Filtering by Eligibility ${userEligibility}. From ${subscriptions.length} to ${eligibleProducts.length} products`
        )

        return eligibleProducts
    }

    /**
     * Save user product generation eligibility
     * @param {Array<HuaweiSubscribedProduct>} subscribedHuaweiProducts
     */
    _setUserSubscriptionEligibility(subscribedHuaweiProducts) {
        this._userSubscriptionEligibility =
            this._resolveUserSubscriptionsGenerationEligibility(subscribedHuaweiProducts) ||
            Subscription.PRODUCT_GENERATION.UNDEFINED
    }

    /**
     * Get user Product Eligibility
     * @returns {Subscription.PRODUCT_GENERATION} subscriptionEligibility
     */
    _getUserSubscriptionEligibility() {
        return this._userSubscriptionEligibility || Subscription.PRODUCT_GENERATION.UNDEFINED
    }
}
