import { ConfigProvider } from './config/ConfigProvider'
import { HashRouter, RouteDefinition, Router } from '@solidjs/router'
import { Show } from 'solid-js'
import { AuthorizationProvider } from '../authorization'
import { ReduxProvider, StoreOptions, useSelector } from '../store'
import { ToastProvider } from './toast/ToastProvider'
import { IconProvider } from './icon/IconProvider'
import { ToastPosition } from './toast/Toast'
import { ErrorHandling } from './error-handling/ErrorHandling'
import { GenericWrapper } from './GenericWrapper'
import { ThemeProvider } from './theme/ThemeProvider'
import { isPlatform } from '../utils'
import { I18nProvider } from '../i18n'

export interface AppProvidersProps {
    children: any
    storeOptions: StoreOptions
    defaultConfig: any
    routes: RouteDefinition[]
    icons?: { [k: string]: any }
    toastPosition?: ToastPosition
    ignoredNotification: string[]
    getLanguage: (locale: string) => {}
}

export const AppProviders = (props: AppProvidersProps) => {
    const state = useSelector((state: any) => state)

    return (
        <ConfigProvider defaultConfig={props.defaultConfig}>
            <ReduxProvider
                voltApi={props.storeOptions.voltApi}
                configureStoreFactory={props.storeOptions.configureStoreFactory}
            >
                <I18nProvider getMessages={props.getLanguage}>
                    <IconProvider icons={props.icons || {}}>
                        <ToastProvider toastPosition={props.toastPosition}>
                            <ErrorHandling
                                ignoredNotification={props.ignoredNotification}
                                errorCodes={props.storeOptions.voltApi.VoltError.codes}
                            >
                                <AuthorizationProvider voltApi={props.storeOptions.voltApi}>
                                    <Show when={state()}>
                                        <ThemeProvider>
                                            <GenericWrapper>
                                                {isPlatform('laptop') ? (
                                                    <Router>{props.routes}</Router>
                                                ) : (
                                                    <HashRouter>{props.routes}</HashRouter>
                                                )}
                                            </GenericWrapper>
                                        </ThemeProvider>
                                    </Show>
                                </AuthorizationProvider>
                            </ErrorHandling>
                        </ToastProvider>
                    </IconProvider>
                </I18nProvider>
            </ReduxProvider>
        </ConfigProvider>
    )
}
