import DataHelper from 'framework/helpers/data'
import { of, throwError } from 'rxjs'
import { mergeMap, catchError } from 'rxjs/operators'
import Fetch from '../fetch'
import VoltError from 'VoltError'
import HuaweiTypes from '../HuaweiTypes'

export default class PinApi extends Fetch {
    constructor(config = {}, otherApis = {}) {
        super(config, otherApis)
        this.passwordApi = otherApis.passwordApi
    }

    /*
     * @type{PinType}
     */
    static TYPE = {
        MASTER: HuaweiTypes.passwordType.SUBSCRIPTION,
        // According to huawei we should use the Super profile PIN
        // ADULT: HuaweiTypes.passwordType.PARENTAL_CONTROL,
        ADULT: HuaweiTypes.passwordType.SUPER_PROFILE,
    }

    /**
     * Checks if a given PIN code of a given type is valid.
     *
     * Stub behavior: always consider pin as valid.
     *
     * @param {String} pin The PIN code that needs validating
     * @param {PinType} type The type of PIN code (ex: purchase, parental control)
     * @return {Observable<Boolean>} The validity of the given PIN code
     */
    validatePin(pin, type) {
        return this.passwordApi
            .checkPassword({
                password: pin,
                passwordType: type,
            })
            .pipe(
                mergeMap(() => {
                    return of(true)
                }),
                catchError((error) => {
                    if (error.code === VoltError.codes.INVALID_PASSWORD.code) {
                        return throwError(
                            new VoltError(VoltError.codes.INVALID_PIN, {
                                inheritedError: error,
                            })
                        )
                    }
                })
            )
    }

    /**
     * Calls proper reset function according to a PIN type
     * @param {PinType} type - user's PIN type
     * @returns {Observable<Boolean>} returns true if resetting was successful
     */
    resetPin(type) {
        return this.passwordApi.resetPassword({
            profileId: DataHelper.getInstance().getData(DataHelper.STORE_KEY.SUBSCRIBER_ID),
            passwordType: type,
        })
    }

    /**
     * Checks if user has a PIN with a given type
     * @param {PinType} type - user's PIN type
     * @returns {Observable<Boolean>|Observable<VoltError>} returns true if resetting was successful
     */
    isPinExist(type) {
        return of(true)
    }
}

/**
 * @typedef {Object} PinType
 * @property {String} MASTER - subscription password
 * @property {String} ADULT - parental control password
 */
