import {useIcon} from '../icon/useIcon'
import './LoadingScreen.css'
import {Spinner} from '../spinner/Spinner'

export const LoadingScreen = () => {
    const icons = useIcon()
    return (
        <div
            style={{
                display: 'flex',
                'justify-content': 'center',
                'align-items': 'center',
                height: '100vh',
                width: '100%',
                'background-image': `url(${icons.get('LoginBackdrop')})`,
                'background-size': 'cover',
            }}
        >
            <div class="loading-content">
            <div class="client_logo_loading"></div>
                {/* <div class="loading-content-heading">
                    <img class="logo-image" src={`${icons.get('logo')}`} />
                </div> */}
                <Spinner />
            </div>
        </div>
    )
}
