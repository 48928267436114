import Constants from 'api-constants'

import Fetch from '../fetch'

import { parseEmiVirtualChannelsConfig } from '../factories'
const { emiModules } = Constants

export default class VirtualChannelModule extends Fetch {
    constructor(config) {
        super(config, emiModules.VIRTUALCHANNEL, {})
    }

    /**
     * @param {String} population Targeted population
     * @returns {AjaxObservable}
     */
    getConfig = (population) => this.getEmiConfig(population)

    _makeConfigParser = (ageLimit) => (config) => {
        return parseEmiVirtualChannelsConfig(config, ageLimit, this.config)
    }
}
